import { useEffect, useCallback, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { EmptyState } from 'dodoc-design-system';

import { InstanceService } from '_common/services';

import { setCurrentAppPage } from 'App/redux/appSlice';
import { listObjects } from '_common/components/Table/TableSlice';

import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import Header from './Header';
import type { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import OriginalLocationCell from './components/Cells/OriginalLocationCell';
import SpaceCell from './components/Cells/SpaceCell';
import UserNameCell from './components/Cells/UserNameCell';
import { ThunksTable } from '_common/components/Table2';
import Cell from '_common/components/Table2/Cells';
import Footer from '_common/components/Table2/Footer/Footer';
import SelectionCounter from '_common/components/Table2/Footer/SelectionCounter';

import styles from './RecyclePage.module.scss';

const PAGE_IDENTITY = 'recycle';

const RecyclePage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.table.identity.recycle.list);
  const appData = useSelector((state) => state.app.data);

  //#region Table object listing handlers
  const fetchObjects = useCallback((parameters) => {
    return new InstanceService().listRecycle(parameters);
  }, []);

  useEffect(() => {
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, []);

  // No useEffectOnUpdate since fetchObjects doesn't have any dependencies
  // Add if dependencies are added to fetchObjects

  //#endregion

  useEffect(() => {
    dispatch(setCurrentAppPage('/storage/recycle'));
  }, []);

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'storage.browserHeader.name' }),
        orderable: true,
        width: 296,
        flex: true,
      },
      {
        id: 'space',
        header: intl.formatMessage({ id: 'storage.browserHeader.space' }),
        width: 144,
      },
      {
        id: 'originalLocation',
        header: intl.formatMessage({ id: 'storage.browserHeader.originalLocation' }),
        width: 160,
      },
      {
        id: 'date',
        header: intl.formatMessage({ id: 'DATE_DELETED' }),
        orderable: true,
        width: 160,
      },
      {
        id: 'deletedBy',
        header: intl.formatMessage({ id: 'storage.browserHeader.deletedBy' }),
        width: 144,
      },
    ];
  }, []);

  const renderEmptyState = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
        <EmptyState
          size="large"
          icon="EmptyRecycleBin"
          title={intl.formatMessage({ id: 'storage.browserHeader.recycleBinEmpty' })}
          testId="recycle-page-empty-list"
        >
          {intl.formatMessage({ id: 'storage.browserHeader.recycleBinEmptySub' })}
        </EmptyState>
      </div>
    );
  };

  const value = useMemo(() => {
    return list.map((objectId) => {
      const object = appData[objectId];
      return {
        id: object.id,
        name: (
          <Cell testId={`${objectId}-name-column`} ellipsis>
            <Cell.ObjectName object={object} testId={`${objectId}-object-name`} />
          </Cell>
        ),
        space: (
          <Cell testId={`${objectId}-space-column`}>
            <SpaceCell object={object.object} />
          </Cell>
        ),
        originalLocation: (
          <Cell testId={`${objectId}-originalLocation-column`}>
            <OriginalLocationCell object={object.object} />
          </Cell>
        ),
        date: (
          <Cell testId={`${objectId}-date-deleted-column`}>
            <Cell.DateTime date={{ date: object.date }} time={{ time: object.date }} />
          </Cell>
        ),
        deletedBy: (
          <Cell testId={`${objectId}-deletedBy-column`}>
            <UserNameCell userId={object.creator} />
          </Cell>
        ),
      };
    });
  }, [list, appData]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <IntlErrorBoundary size="small" margin="0 0 0 7rem">
          <Header />
        </IntlErrorBoundary>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.message}>
          <FormattedMessage id="storage.browserHeader.recycleDesc" />
        </div>
        <div className={styles.content}>
          <ThunksTable
            identity={PAGE_IDENTITY}
            fetchObjects={fetchObjects}
            columns={columns}
            value={value}
            lazyLoading
            renderFooter={() => (
              <Footer>
                <SelectionCounter identity={PAGE_IDENTITY} />
              </Footer>
            )}
            renderEmptyState={renderEmptyState}
            loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
            testId="recycle"
          />
        </div>
      </div>
    </div>
  );
};

export default RecyclePage;
