import { Logger } from '_common/services';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { BaseTypedEmitter } from '_common/services/Realtime';

class EventsManager extends BaseTypedEmitter<Editor.Events.EventTypes> {
  private static instance: EventsManager;
  private mousedownTarget: EventTarget | null;
  private documentContainer: HTMLElement | undefined;
  private pageListeners: Editor.Events.HTMLElementListenerType;
  private editorRootListeners: Editor.Events.HTMLElementListenerType;
  private documentListeners: Editor.Events.HTMLElementListenerType;

  private DEBUG: boolean = false;

  constructor() {
    super();

    this.mousedownTarget = null;

    this.pageListeners = {
      mouseover: this.handleMouseOver.bind(this),
      mousedown: this.handleMouseDown.bind(this),
      mouseup: this.handleMouseUp.bind(this),
      drag: this.handleDrag.bind(this),
      click: this.handleMouseClick.bind(this),
      keyup: this.handleKeyUp.bind(this),
      keydown: this.handleKeyDown.bind(this),
      keypress: this.handleKeyPress.bind(this),
      copy: this.handleCopy.bind(this),
      paste: this.handlePaste.bind(this),
      cut: this.handleCut.bind(this),
      drop: this.handleDrop.bind(this),
      compositionstart: this.handleCompositionStart.bind(this),
      compositionupdate: this.handleCompositionUpdate.bind(this),
      compositionend: this.handleCompositionEnd.bind(this),
      input: this.handleInput.bind(this),
      beforeinput: this.handleBeforeInput.bind(this),
    };

    this.editorRootListeners = {
      contextmenu: this.handleContextMenu.bind(this),
      wheel: this.handleMouseWheel.bind(this),
    };

    this.documentListeners = {
      keydown: this.handleDocumentKeyDown.bind(this),
      mousemove: this.handleDocumentMouseMove.bind(this),
      mouseup: this.handleDocumentMouseUp.bind(this),
      paste: this.handleDocumentPaste.bind(this),
      selectionchange: this.handleSelectionChange.bind(this),
    };
  }

  static getInstance(): EventsManager {
    if (!EventsManager.instance) {
      EventsManager.instance = new EventsManager();
    }
    return EventsManager.instance;
  }

  startEventsManager(documentContainer: HTMLElement | null) {
    const editorRoot = document.getElementById('EditorRoot');
    if (documentContainer) {
      this.documentContainer = documentContainer;
      Object.keys(this.pageListeners).forEach((event) => {
        this.documentContainer?.addEventListener(event, this.pageListeners[event]);
      });
    }
    if (editorRoot) {
      Object.keys(this.editorRootListeners).forEach((event) => {
        editorRoot.addEventListener(event, this.editorRootListeners[event]);
      });
    }

    Object.keys(this.documentListeners).forEach((event) => {
      document.addEventListener(event, this.documentListeners[event]);
    });
  }

  stopEventsManager() {
    const editorRoot = document.getElementById('EditorRoot');
    if (this.documentContainer) {
      if (Object.keys(this.pageListeners).length) {
        Object.keys(this.pageListeners).forEach((event) => {
          this.documentContainer?.removeEventListener(event, this.pageListeners[event]);
        });
      }
      this.documentContainer = undefined;
    }
    if (editorRoot) {
      if (Object.keys(this.editorRootListeners).length) {
        Object.keys(this.editorRootListeners).forEach((event) => {
          editorRoot.removeEventListener(event, this.editorRootListeners[event]);
        });
      }
    }

    if (Object.keys(this.documentListeners).length) {
      Object.keys(this.documentListeners).forEach((event) => {
        document.removeEventListener(event, this.documentListeners[event]);
      });
    }

    this.removeAllEventEmitterListeners();
  }

  destroy() {
    this.stopEventsManager();
  }

  remove(
    event: Editor.Events.EventNames,
    listener: Editor.Events.EventTypes[Editor.Events.EventNames],
  ) {
    if (listener) {
      this.removeListener(event, listener);
    }
  }

  handleDrag(event: Event) {
    if (this.DEBUG) {
      logger.debug('EventsManager handleDrag', event);
    }
  }

  handleMouseDown(event: Event) {
    if (this.DEBUG) {
      logger.debug('EventsManager handleMouseDown', event);
    }
    this.mousedownTarget = event.target as EventTarget;
    this.emit('MOUSE_DOWN', event);
  }

  handleMouseOver(event: Event) {
    this.emit('MOUSE_OVER', { event, mousedownTarget: this.mousedownTarget });
  }

  handleMouseUp(event: Event) {
    if (this.DEBUG) {
      logger.debug('EventsManager handleMouseUp', event, this.mousedownTarget);
    }
    this.emit('MOUSE_UP', { event, mousedownTarget: this.mousedownTarget });
  }

  handleMouseClick(event: Event) {
    this.emit('click', event);
  }

  handleMouseWheel(event: Event) {
    this.emit('mousewheel DOMMouseScroll', event);
  }

  handleKeyUp(event: Event) {
    this.emit('KEY_UP', event);
  }

  handleKeyDown(event: Event) {
    this.emit('KEY_DOWN', event);
  }

  handleKeyPress(event: Event) {
    this.emit('KEY_PRESS', event);
  }

  handleContextMenu(event: Event) {
    this.emit('contextmenu', event);
  }

  //ClipBoard Events
  handleCopy(event: Event) {
    this.emit('COPY', event);
  }

  handleCut(event: Event) {
    this.emit('CUT', event);
  }

  handlePaste(event: Event) {
    this.emit('PASTE', event);
  }

  handleDrop(event: Event) {
    this.emit('DROP', event);
  }

  handleBeforeInput(event: Event) {
    this.emit('BEFORE_INPUT', event);
  }

  handleInput(event: Event) {
    this.emit('INPUT', event);
  }

  handleCompositionStart(event: Event) {
    this.emit('COMPOSITION_START', event);
  }

  handleCompositionUpdate(event: Event) {
    this.emit('COMPOSITION_UPDATE', event);
  }

  handleCompositionEnd(event: Event) {
    this.emit('COMPOSITION_END', event);
  }

  //Document Related Events
  handleSelectionChange(event: Event) {
    this.emit('DOCUMENT_SELECTION_CHANGE', event);
  }

  handleDocumentMouseMove(event: Event) {
    this.emit('DOCUMENT_MOUSE_MOVE', event);
  }

  handleDocumentMouseUp(event: Event) {
    this.emit('DOCUMENT_MOUSE_UP', event);
  }

  handleDocumentPaste(event: Event) {
    this.emit('DOCUMENT_PASTE', event);
  }

  handleDocumentKeyDown(event: Event) {
    const pageCoontainer = DOMUtils.getPageNode();

    let selection;

    try {
      selection = window.getSelection();
      if (selection != null && pageCoontainer != null) {
        if (selection.type !== 'None') {
          const range = selection.getRangeAt(0);

          if (pageCoontainer.contains(range.commonAncestorContainer)) {
            this.handleKeyDown(event);
          }
        }
      }

      this.emit('DOCUMENT_KEY_DOWN', event);
    } catch (error) {
      Logger.captureException(error);
    }
  }
}

export default EventsManager;
