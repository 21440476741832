import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { getDocumentObject, selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';

import { TabMenu, MenuItem } from '_common/components';

import PageSize from './PageSize/PageSize';
import PageMargins from './PageMargins/PageMargins';
import Sections from './Sections/Sections';
import EditorManager from 'Editor/services/EditorManager';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';

const MODAL = 'PageSetupModal';

export type InputSelectFieldValue = {
  value: string;
  unit: string;
};

export type RadioButtonValue = {
  label: string;
  id: string;
};

export type Values = {
  properties?: Editor.Data.Sections.Properties;
  options?: {
    [key: string]: boolean;
  };
};

export type MarginValues = {
  t: InputSelectFieldValue;
  b: InputSelectFieldValue;
  r: InputSelectFieldValue;
  l: InputSelectFieldValue;
};

export type SizeValues = {
  w: InputSelectFieldValue;
  h: InputSelectFieldValue;
};

const PageSetupModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const manager = EditorManager.getInstance();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const sectionValues = useSelector((state) => state.editor.status.selection.SECTION);
  const document = useSelector(getDocumentObject);

  const isOwner = useMemo(() => {
    return (
      document.user_permissions.includes('owner') || document.user_permissions.includes('admin')
    );
  }, [document]);

  const [disabled, setDisabled] = useState(false);
  const [applyToValue, setApllyToValue] = useState({
    label: intl.formatMessage({ id: 'WHOLE_DOCUMENT' }),
    id: 'wholeDocument',
  });
  const [pageOrientationValue, setPageOrientationValue] = useState({
    label: intl.formatMessage({ id: 'EDITOR_PORTRAIT' }),
    id: 'P',
  });
  const [marginValues, setMarginValues] = useState<MarginValues>({
    t: { value: '2.54', unit: 'cm' },
    b: { value: '2.54', unit: 'cm' },
    r: { value: '2.54', unit: 'cm' },
    l: { value: '2.54', unit: 'cm' },
  });
  const [sizeValues, setSizeValues] = useState<SizeValues>({
    w: { value: '21', unit: 'cm' },
    h: { value: '29.7', unit: 'cm' },
  });
  const [sectionStart, setSectionStart] = useState<'np' | 'c' | 'op' | 'ep'>('np');

  const APPLY_TO_OPTIONS = [
    { label: intl.formatMessage({ id: 'WHOLE_DOCUMENT' }), id: 'wholeDocument' },
    { label: intl.formatMessage({ id: 'THIS_SECTION' }), id: 'thisSection' },
  ];
  const PAGE_ORIENTATION_OPTIONS = [
    { label: intl.formatMessage({ id: 'EDITOR_PORTRAIT' }), id: 'P' },
    { label: intl.formatMessage({ id: 'EDITOR_LANDSCAPE' }), id: 'L' },
  ];

  useEffect(() => {
    if (
      isOpen &&
      sectionValues?.properties &&
      sectionValues?.properties?.sz &&
      sectionValues.properties?.mar
    ) {
      setApllyToValue(
        sectionValues.numberOfSections === 1
          ? {
              label: intl.formatMessage({ id: 'WHOLE_DOCUMENT' }),
              id: 'wholeDocument',
            }
          : { label: intl.formatMessage({ id: 'THIS_SECTION' }), id: 'thisSection' },
      );
      setMarginValues({
        t: {
          value: DOMUtils.convertUnitTo(sectionValues.properties?.mar?.t, 'pt', 'cm', 3),
          unit: 'cm',
        },
        b: {
          value: DOMUtils.convertUnitTo(sectionValues.properties?.mar?.b, 'pt', 'cm', 3),
          unit: 'cm',
        },
        r: {
          value: DOMUtils.convertUnitTo(sectionValues.properties?.mar?.r, 'pt', 'cm', 3),
          unit: 'cm',
        },
        l: {
          value: DOMUtils.convertUnitTo(sectionValues.properties?.mar?.l, 'pt', 'cm', 3),
          unit: 'cm',
        },
      });
      setSizeValues({
        w: {
          value: DOMUtils.convertUnitTo(sectionValues.properties?.sz?.w, 'pt', 'cm', 1),
          unit: 'cm',
        },
        h: {
          value: DOMUtils.convertUnitTo(sectionValues.properties?.sz?.h, 'pt', 'cm', 1),
          unit: 'cm',
        },
      });
      setPageOrientationValue(
        sectionValues.properties.p_o === 'P'
          ? {
              label: intl.formatMessage({ id: 'EDITOR_PORTRAIT' }),
              id: 'P',
            }
          : { label: intl.formatMessage({ id: 'EDITOR_LANDSCAPE' }), id: 'L' },
      );
      //@ts-expect-error
      setSectionStart(sectionValues?.properties?.t ?? 'np');
    }
  }, [isOpen, sectionValues]);

  const close = () => {
    dispatch(closeAndResetModal('PageSetupModal'));
  };

  const handleSave = () => {
    if (sectionValues?.id) {
      const properties = {
        sz: {
          w:
            sizeValues.w.unit !== 'pt'
              ? DOMUtils.convertUnitTo(sizeValues.w.value, sizeValues.w.unit, 'pt', 3)
              : Number(sizeValues.w.value),
          h:
            sizeValues.h.unit !== 'pt'
              ? DOMUtils.convertUnitTo(sizeValues.h.value, sizeValues.h.unit, 'pt', 3)
              : Number(sizeValues.h.value),
        },
        mar: {
          t:
            marginValues.t.unit !== 'pt'
              ? DOMUtils.convertUnitTo(marginValues.t.value, marginValues.t.unit, 'pt', 3)
              : Number(marginValues.t.value),
          b:
            marginValues.b.unit !== 'pt'
              ? DOMUtils.convertUnitTo(marginValues.b.value, marginValues.b.unit, 'pt', 3)
              : Number(marginValues.b.value),
          r:
            marginValues.r.unit !== 'pt'
              ? DOMUtils.convertUnitTo(marginValues.r.value, marginValues.r.unit, 'pt', 3)
              : Number(marginValues.r.value),
          l:
            marginValues.l.unit !== 'pt'
              ? DOMUtils.convertUnitTo(marginValues.l.value, marginValues.l.unit, 'pt', 3)
              : Number(marginValues.l.value),
        },
        p_o: pageOrientationValue.id,
        t: sectionStart,
      };
      const options = {
        wholeDocument: applyToValue.id === 'wholeDocument' ? true : false,
      };
      //@ts-expect-error
      manager.saveSectionProperties(sectionValues.id, properties, options);
      notify({
        type: 'success',
        title: 'PAGE_SETUP_UPDATED',
        message: 'THE_DOCUMENT_PAGE_SETUP_WAS_SUCCESSFULLY_UPDATED',
      });
      close();
    }
  };

  return (
    <Modal open={!!isOpen} width="66rem" onClose={close} testId="page-setup">
      <Modal.Header onClose={close}>
        <FormattedMessage id="PAGE_SETUP" />
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: '63rem' }}>
          <TabMenu menuId="pageSetup">
            <MenuItem
              text={intl.formatMessage({
                id: 'SIZE',
              })}
              id="size"
            >
              <PageSize
                applyTo={{
                  handleChange: setApllyToValue,
                  applyToOptions: APPLY_TO_OPTIONS,
                  applyToValue,
                }}
                pageOrientation={{
                  handleChange: setPageOrientationValue,
                  pageOrientationOptions: PAGE_ORIENTATION_OPTIONS,
                  pageOrientationValue,
                }}
                setDisabled={setDisabled}
                setSizeValues={setSizeValues}
                sizeValues={sizeValues}
                sectionStart={sectionStart}
                setSectionStart={setSectionStart}
                isDisabled={!isOwner}
              />
            </MenuItem>
            <MenuItem
              text={intl.formatMessage({
                id: 'MARGINS',
              })}
              id="margins"
            >
              <PageMargins
                applyTo={{
                  handleChange: setApllyToValue,
                  applyToOptions: APPLY_TO_OPTIONS,
                  applyToValue,
                }}
                setMarginValues={setMarginValues}
                marginValues={marginValues}
                setDisabled={setDisabled}
                isDisabled={!isOwner}
              />
            </MenuItem>
            <MenuItem
              text={intl.formatMessage({
                id: 'SECTIONS',
              })}
              id="sections"
            >
              <Sections
                applyTo={{
                  handleChange: setApllyToValue,
                  applyToOptions: APPLY_TO_OPTIONS,
                  applyToValue,
                }}
                sectionStart={sectionStart}
                setSectionStart={setSectionStart}
                isDisabled={!isOwner}
              />
            </MenuItem>
          </TabMenu>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="page-setup-modal-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          variant="primary"
          size="medium"
          onClick={handleSave}
          disabled={disabled || isReadOnlyMode || !isOwner}
          testId="page-setup-modal-submit-button"
        >
          <FormattedMessage id="UPDATE_PAGE_SETUP" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PageSetupModal;
