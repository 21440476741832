import { FormattedMessage } from 'react-intl';
import SidebarContent from 'Editor/pages/EditorPage/SidebarComponent/SidebarContent';
import SidebarHeader from 'Editor/pages/EditorPage/SidebarComponent/SidebarHeader';
import SidebarPanel from 'Editor/pages/EditorPage/SidebarComponent/SidebarPanel';
import NotesPanel from './NotesPanel/NotesPanel';

const ReferencesTab = () => (
  <SidebarPanel testId="notes-panel">
    <SidebarHeader>
      <FormattedMessage id="NOTES_PANEL_TITLE" />
    </SidebarHeader>
    <SidebarContent>
      <NotesPanel />
    </SidebarContent>
  </SidebarPanel>
);

export default ReferencesTab;
