import { useState, useRef, useEffect, ChangeEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextArea, Modal, Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import { closeModal } from '_common/modals/ModalsSlice';

import EquationsContext from './EquationsContext';
import useEquation from './useEquation';
import Preview from './Preview/Preview';
import { Symbol, Structure } from './ToolbarElements';
import ToolbarGroup from './ToolbarGroup/ToolbarGroup';

import styles from './EquationsModal.module.scss';

const EquationsModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.EquationsModal);
  const isEdit = useSelector((state) => state.modals.EquationsModal.isEdit);
  const equation = useSelector((state) => state.modals.EquationsModal.equation);

  const [text, setText] = useState('');
  const textarea = useRef<HTMLTextAreaElement>(null);
  const onTextChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => setText(e.target.value);
  const [isValid, setIsValid] = useState(true);
  const previewRef = useRef<HTMLDivElement>(null);

  useEquation(text, 'latex', previewRef, setIsValid);

  useEffect(() => {
    setText(equation ? equation.tex : '');
  }, [equation]);

  const onElementInsertion = (
    element: Symbol['elements'][number] | Structure['elements'][number],
  ) => {
    const newText = element['latex'];
    if (textarea.current) {
      const start = textarea.current.selectionStart;
      const end = textarea.current.selectionEnd;
      const before = text.substring(0, start);
      const after = text.substring(end, text.length);
      setText(before + newText + after);
      textarea.current.focus();
      setTimeout(() => {
        if (textarea.current && newText) {
          textarea.current.selectionStart = start + newText.length;
          textarea.current.selectionEnd = start + newText.length;
        }
      }, 0);
    }
  };
  const close = () => {
    dispatch(closeModal('EquationsModal'));
    EditorManager.getInstance().restore();
  };

  const handleButtonClick = () => {
    const mml = MathJax.tex2mml(text);

    if (isEdit) {
      EditorManager.getInstance().updateEquation(equation.elementId, mml);
    } else {
      EditorManager.getInstance().insertEquation(mml);
    }
    close();
  };

  return (
    <Modal width="127rem" open={!!isOpen} onClose={close} testId="equations">
      <Modal.Header onClose={close}>
        <FormattedMessage id="EQUATION_EDITOR" />
      </Modal.Header>
      <Modal.Body>
        <EquationsContext.Provider value={{ onElementInsertion }}>
          <div className={styles.toolbar}>
            <ToolbarGroup type="structures" />
            <div className={styles.separator} />
            <ToolbarGroup type="symbols" />
          </div>
          <Preview value={text} ref={previewRef} isValid={isValid} equation={equation} />
          <TextArea
            ref={textarea}
            value={text}
            onChange={onTextChange}
            size="large"
            placeholder={intl.formatMessage({ id: 'INSERT_YOUR_EQUATION_HERE' })}
            error={!isValid && !!text}
            testId="equations-textarea"
          />
        </EquationsContext.Provider>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="equations-cancel-button">
          <FormattedMessage id="global.close" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleButtonClick}
          disabled={!isValid || text.length === 0}
          testId="equations-submit-button"
        >
          <FormattedMessage id={isEdit ? 'UPDATE' : 'global.insert'} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EquationsModal;
