type SelectionComponentProp = any;

type SelectionComponent = {
  b: SelectionComponentProp;
  a: SelectionComponentProp;
};

type PatchComponent = {
  op: Realtime.Core.RealtimeOps;
  doc: Realtime.Core.RealtimeObject;
};

export class Patch {
  id: string;
  private components: PatchComponent[] = [];
  selection?: SelectionComponent;

  constructor() {
    this.id = Date.now().toString();
  }

  get isEmpty() {
    return this.components.length <= 0;
  }

  add(doc: Realtime.Core.RealtimeObject, op: Realtime.Core.RealtimeOps) {
    if (op.length > 0) {
      this.components.push({
        doc,
        op,
      });
    }
  }

  addSelectionInfo(before?: SelectionComponentProp, after?: SelectionComponentProp) {
    this.selection = {
      a: after || this.selection?.a,
      b: before || this.selection?.b,
    };
  }

  transformPatch(doc: Realtime.Core.RealtimeObject, ops: Realtime.Core.RealtimeOps) {
    const transform = doc.model.type.transform;
    const transformX = doc.model.type.transformX;
    const isNoop = doc.model.type.isNoop;

    let workingOps: Realtime.Core.RealtimeOps = ops;
    const newList: PatchComponent[] = [];
    for (let i = this.components.length - 1; i >= 0; --i) {
      let item = this.components[i];
      if (item.doc !== doc) {
        newList.push(item);
        continue;
      }
      let stackOp = item.op;
      let transformedStackOp;
      let transformedOp;

      if (transformX) {
        let result = transformX(workingOps, stackOp);
        transformedOp = result[0];
        transformedStackOp = result[1];
      } else {
        transformedOp = transform(workingOps, stackOp, 'left');
        transformedStackOp = transform(stackOp, workingOps, 'right');
      }
      if (!isNoop || !isNoop(transformedStackOp)) {
        if (transformedStackOp.length > 0) {
          newList.push({
            doc,
            op: transformedStackOp,
          });
        }
      }
      workingOps = transformedOp;
    }
    this.components = newList.reverse();
    return workingOps;
  }

  revert() {
    for (let index = this.components.length - 1; index >= 0; index--) {
      this.components[index].doc.revert(this.components[index].op, {
        source: 'UNDO',
      });
    }
  }

  apply() {
    for (let index = 0; index < this.components.length; index++) {
      this.components[index].doc.apply(this.components[index].op, {
        source: 'REDO',
      });
    }
  }
}
