import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button } from 'dodoc-design-system';

import { TabMenu, MenuItem } from '_common/components';
import { useDispatch, useSelector } from '_common/hooks';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

import General from './General/General';
import Authoring from './Authoring/Authoring';
import Template from './Template/Template';
import Metadata from './Metadata/Metadata';

const DocumentDetailsModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { data: metadata } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });

  const object = useSelector(getDocumentObject) as Objekt & {
    events: {
      warnings: ISODate[];
      due: ISODate;
    };
  };
  const isOpen = useSelector((state) => state.modals.open.DocumentDetailsModal);

  const [validations, setValidations] = useState<string>();
  const [editMetadata, setEditMetadata] = useState(false);

  const handleToggleEditMode = (editMode: boolean) => {
    setEditMetadata(editMode);
  };

  const close = () => {
    dispatch(closeAndResetModal('DocumentDetailsModal'));
  };

  const handleEditMetadata = () => {
    setEditMetadata(false);
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="78rem" testId="document-details">
      <Modal.Header onClose={close}>
        <FormattedMessage id="DOCUMENT_DETAILS" />
      </Modal.Header>
      <Modal.Body>
        <TabMenu menuId="documentDetails">
          <MenuItem
            text={intl.formatMessage({
              id: 'settings.tabs.general',
            })}
            id="general"
          >
            <General objectId={object.id} />
          </MenuItem>
          <MenuItem
            text={intl.formatMessage({
              id: 'AUTHORING',
            })}
            id="authors"
          >
            <Authoring />
          </MenuItem>
          <MenuItem text="Template" id="template">
            <Template />
          </MenuItem>
          <MenuItem
            text={intl.formatMessage({
              id: 'METADATA',
            })}
            id="metadata"
            hidden={Object.keys(metadata).length <= 0}
          >
            <Metadata
              editMetadata={editMetadata}
              setEditMetadata={handleToggleEditMode}
              validations={validations}
              setValidations={setValidations}
            />
          </MenuItem>
        </TabMenu>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="document-details-modal-close-button">
          <FormattedMessage id="global.close" />
        </Button>
        {editMetadata && (
          <Button
            size="medium"
            variant="primary"
            onClick={handleEditMetadata}
            disabled={!!validations}
            testId="document-details-modal-save-button"
          >
            <FormattedMessage id="SAVE_AND_BACK" />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentDetailsModal;
