import { Mixin } from 'mixwith';
import { ReduxInterface } from 'Editor/services';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class DeleteHandler extends superclass {
      destroy() {
        super.destroy();
      }

      /**
       * handle delete on multi selection
       * @param {ActionContext} actionContext
       */
      handleDeleteOnMultiSelection(actionContext, level0Node) {
        if (level0Node && level0Node.tagName === ELEMENTS.TableElement.TAG) {
          this.removeSelectionContent(actionContext);
          this.selectionManager.collapseToStart();
        } else {
          this.joinSelectionContent(actionContext);
        }
      }

      /**
       * selects a function to handle delete based on baseNode
       * @param {ActionContext} actionContext
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleDeleteOnCollpasedSelection(actionContext, baseNode, anchorNode, anchorOffset) {
        if (!baseNode || DOMUtils.isNodeAContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        }

        if (baseNode) {
          if (anchorNode) {
            if (
              baseNode.tagName === ELEMENTS.ParagraphElement.TAG &&
              this.dataManager.numbering.isListElement(baseNode.id)
            ) {
              // SELECTION IS A LIST
              this.handleDeleteOnListElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (DOMUtils.isNodeEditableTextElement(baseNode)) {
              this.selectionManager.fixCollapsedTextSelection({
                forceNonEditableDirection: 'FORWARD',
                isDelete: true,
              });
              const selection = EditorSelectionUtils.getSelection();
              anchorNode = selection.anchorNode;
              anchorOffset = selection.anchorOffset;
              // SELECTION IS A DEFAULT TEXT ELEMENT
              const selectionCheck = DOMUtils.closest(anchorNode, DOMUtils.BLOCK_TEXT_ELEMENTS);
              this.handleDeleteOnTextElement(
                actionContext,
                baseNode,
                selectionCheck,
                anchorNode,
                anchorOffset,
              );
            } else if (!DOMUtils.isBlockNodeEditable(baseNode)) {
              // SELECTION IS A NON-EDITABLE ELEMENT
              this.handleNonEditableElementDelete(actionContext, baseNode);
            } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
              // SELECTION IS A FIGURE
              this.handleDeleteOnFigureElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
              // SELECTION IS A TABLE
              this.handleDeleteOnTableElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (DOMUtils.isNodeAContainerElement(baseNode)) {
              this.handleDeleteOnContainerElement(
                actionContext,
                baseNode,
                anchorNode,
                anchorOffset,
              );
            }
          }
        }
      }

      handleDeleteOnContainerElement(actionContext, baseNode, anchorNode, anchorOffset) {
        if (baseNode === anchorNode) {
          if (anchorOffset === 0) {
            this.selectionManager.selectNode(anchorNode);
            return;
          } else {
            if (this.selectionManager.fixSelection()) {
              const selection = EditorSelectionUtils.getSelection();
              anchorNode = selection.anchorNode;
              anchorOffset = selection.anchorOffset;
            }
          }
        }

        const subLevel0Node = DOMUtils.findNodeLevel0(baseNode, anchorNode);
        if (subLevel0Node) {
          this.handleDeleteOnCollpasedSelection(
            actionContext,
            subLevel0Node,
            anchorNode,
            anchorOffset,
          );
        }
      }

      /**
       * handle delete event on list elements
       * @param {ActionContext} actionContext
       * @param {Node} anchorNode
       */
      handleDeleteOnListElement(actionContext, listNode, anchorNode, anchorOffset) {
        if (EditorDOMUtils.isEmptyElement(listNode)) {
          this.stylesHandler.removeListStyleFromBlock(actionContext, listNode);
          this.removeBlockElementFromDOM(actionContext, listNode);
        } else {
          this.handleDeleteOnTextElement(
            actionContext,
            listNode,
            listNode,
            anchorNode,
            anchorOffset,
          );
        }
      }

      /**
       * handle delete event on a figure element
       * @param {ActionContext} actionContext
       * @param {Node} figureNode
       * @param {Node} anchorNode
       */
      handleDeleteOnFigureElement(actionContext, figureNode, anchorNode, anchorOffset) {
        const closest = DOMUtils.closest(anchorNode, ['FIGCAPTION', 'IMAGE-ELEMENT']);
        if (closest) {
          if (closest.tagName === 'IMAGE-ELEMENT') {
            if (
              (closest === anchorNode && anchorOffset === 0) ||
              (closest !== anchorNode && !anchorNode.previousSibling)
            ) {
              this.selectionManager.selectLevel0Node(figureNode);
            }
          }
        } else if (
          anchorNode.tagName === ELEMENTS.FigureElement.TAG ||
          anchorNode.tagName === 'IMAGE-ELEMENT' ||
          anchorNode.tagName === 'IMG'
        ) {
          if (anchorOffset === 0) {
            this.selectionManager.selectLevel0Node(figureNode);
          }
        }
      }

      /**
       * handle delete event on table element
       * @param {ActionContext} actionContext
       * @param {Node} anchorNode
       */
      handleDeleteOnTableElement(actionContext, tableNode, anchorNode, anchorOffset) {
        const closest = DOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);
        if (anchorNode.tagName === ELEMENTS.TableElement.TAG) {
          if (anchorOffset === 0) {
            this.selectionManager.selectLevel0Node(anchorNode);
          }
        } else if (closest && !closest.hasAttribute('lock')) {
          if (closest.tagName === ELEMENTS.TableCellElement.TAG) {
            const tdLevel0Node = DOMUtils.findNodeLevel0(closest, anchorNode);
            if (tdLevel0Node) {
              if (tdLevel0Node.tagName !== ELEMENTS.TableElement.TAG) {
                this.handleDeleteOnCollpasedSelection(
                  actionContext,
                  tdLevel0Node,
                  anchorNode,
                  anchorOffset,
                );
              } else if (tdLevel0Node !== tableNode) {
                this.selectionManager.selectNode(tdLevel0Node);
              }
            }
          }
        }
      }

      /**
       * handle delete event on a default text element
       * @param {ActionContext} actionContext
       */
      handleDeleteOnTextElement(
        actionContext,
        level0Node,
        textElementNode,
        anchorNode,
        anchorOffset,
      ) {
        if (textElementNode) {
          if (this.selectionManager.isSelectionAtEnd(textElementNode)) {
            // selection is at end
            let nextSibling;

            if (textElementNode?.nextSibling || level0Node.nextSibling) {
              nextSibling = textElementNode ? textElementNode.nextSibling : level0Node.nextSibling;
            } else if (
              DOMUtils.isNodeAContainerElement(level0Node.parentNode) &&
              level0Node.parentNode.tagName !== ELEMENTS.TableCellElement.TAG
            ) {
              nextSibling = DOMUtils.findNextAncestorSibling(level0Node);
            }

            if (nextSibling) {
              if (
                EditorDOMUtils.isEmptyElement(textElementNode) &&
                !this.isAddParagraphMarker(textElementNode.lastChild) &&
                !this.isDeleteParagraphMarker(textElementNode.lastChild) &&
                !(
                  nextSibling.tagName === ELEMENTS.TableElement.TAG &&
                  textElementNode.previousSibling?.tagName === ELEMENTS.TableElement.TAG
                )
              ) {
                this.selectionManager.setCaret(nextSibling, 'INSIDE_START');
                this.removeBlockElementFromDOM(actionContext, textElementNode);
              } else if (!DOMUtils.isBlockNodeDeletable(nextSibling)) {
                // next sibling is non-deletable

                if (EditorDOMUtils.isEmptyElement(level0Node) && nextSibling.selectableContent) {
                  this.selectionManager.setCaret(nextSibling.selectableContent, 'INSIDE_START');
                  this.removeBlockElementFromDOM(actionContext, level0Node);
                } else {
                  this.selectionManager.selectNodeContents(level0Node);
                }
              } else if (!DOMUtils.isBlockNodeEditable(nextSibling)) {
                // next sibling is non-editable

                this.removeBlockElementFromDOM(actionContext, nextSibling);
              } else if (
                nextSibling.tagName === ELEMENTS.FigureElement.TAG ||
                nextSibling.tagName === ELEMENTS.TableElement.TAG ||
                DOMUtils.isNodeAContainerElement(nextSibling)
              ) {
                this.selectionManager.selectNode(nextSibling);
              } else if (DOMUtils.isNodeEditableTextElement(nextSibling)) {
                // next sibling is a default text element
                this.selectionManager.setRangeStart(
                  textElementNode,
                  'INDEX',
                  textElementNode.childNodes.length,
                );
                this.selectionManager.setRangeEnd(nextSibling, 'INDEX', 0);
                this.joinSelectionContent(actionContext);
              }
            }
          } else {
            // selection is at start or mid
            const closest = DOMUtils.closest(anchorNode, DOMUtils.INLINE_NON_EDITABLE_ELEMENTS);

            let nextClosest;
            if (!closest) {
              let nextSibling;

              if (this.selectionManager.isSelectionAtEnd(anchorNode)) {
                nextSibling = anchorNode.nextSibling;
              } else if (!(anchorNode instanceof Text)) {
                nextSibling = textElementNode.childNodes[anchorOffset];
              }

              if (nextSibling) {
                if (
                  nextSibling.nodeType === Node.ELEMENT_NODE &&
                  (nextSibling.tagName === ELEMENTS.TrackInsertElement.TAG ||
                    nextSibling.tagName === ELEMENTS.TrackDeleteElement.TAG) &&
                  nextSibling.childNodes.length > 0
                ) {
                  nextClosest = DOMUtils.closest(nextSibling.firstChild, [
                    ...DOMUtils.INLINE_NON_EDITABLE_ELEMENTS,
                    ELEMENTS.FieldElement.TAG,
                  ]);
                } else {
                  nextClosest = DOMUtils.closest(nextSibling, [
                    ...DOMUtils.INLINE_NON_EDITABLE_ELEMENTS,
                    ELEMENTS.FieldElement.TAG,
                  ]);
                }
              }
            }

            const nonEditable = closest || nextClosest;

            // handle non-editable text elements
            // citation-element, etc
            if (nonEditable) {
              if (nonEditable.tagName === ELEMENTS.CitationElement.TAG) {
                const citationGroup = DOMUtils.closest(
                  nonEditable,
                  ELEMENTS.CitationsGroupElement.TAG,
                );
                let elementToHandle = nonEditable;

                if (
                  citationGroup &&
                  citationGroup.getCitationsCount &&
                  citationGroup.getCitationsCount() <= 1
                ) {
                  // if parent citatio group has only one child
                  citationGroup.remove();
                  actionContext.addChangeUpdatedNode(level0Node);
                } else {
                  // check if is inserted or deleted citation from multiple users
                  while (DOMUtils.parentContainsNode(citationGroup, elementToHandle.parentNode)) {
                    if (
                      elementToHandle.tagName === ELEMENTS.TrackInsertElement.TAG ||
                      elementToHandle.tagName === ELEMENTS.TrackDeleteElement.TAG
                    ) {
                      actionContext.addReferenceToRefresh(
                        elementToHandle.getAttribute('element_reference'),
                      );
                    }

                    elementToHandle = elementToHandle.parentNode;
                  }

                  if (elementToHandle.nextSibling) {
                    this.selectionManager.setCaret(elementToHandle.nextSibling, 'INSIDE_START');
                  } else {
                    this.selectionManager.setCaret(elementToHandle, 'POST');
                  }
                  elementToHandle.remove();
                  actionContext.addChangeUpdatedNode(level0Node);
                }
              } else if (nonEditable.tagName === ELEMENTS.CitationsGroupElement.TAG) {
                this.selectionManager.selectNode(nonEditable);
              } else if (
                nonEditable.tagName === ELEMENTS.EquationElement.TAG ||
                nonEditable.tagName === ELEMENTS.FieldElement.TAG
              ) {
                nonEditable.selected = true;
                this.selectionManager.selectNode(nonEditable);

                // trigger confirmation modal
                if (nonEditable.isTypeCaption()) {
                  ReduxInterface.openDeleteCaptionConfirmationModal();
                }
              } else {
                this.selectionManager.selectNode(nonEditable);
                this.joinSelectionContent(actionContext);
              }
            } else {
              // handle normal characters
              this.selectionManager.modifySelection('expand', 'forward', 'character');
              this.joinSelectionContent(actionContext);
            }
          }
        }
      }

      /**
       * handle delete on a non-editable element
       * @param {ActionContext} actionContext
       */
      handleNonEditableElementDelete(actionContext, level0Node) {
        // delete

        if (this.hasEditPermissions()) {
          const contentEditable = level0Node && level0Node.selectableContent;

          if (contentEditable) {
            if (this.selectionManager.isSelectionAtEnd(contentEditable)) {
              const nextSibling =
                level0Node.parentNode === DOMUtils.getPageNode()
                  ? level0Node.nextSibling
                  : DOMUtils.findNodeLevel0(DOMUtils.getPageNode(), level0Node).nextSibling;

              if (nextSibling) {
                if (DOMUtils.isBlockNodeEditable(nextSibling)) {
                  if (EditorDOMUtils.isEmptyElement(nextSibling)) {
                    this.selectionManager.setCaret(nextSibling, 'INSIDE_START');
                  } else {
                    this.selectionManager.selectNodeContents(nextSibling);
                  }
                } else {
                  const nodeToSelect = nextSibling.selectableContent
                    ? nextSibling.selectableContent
                    : nextSibling;
                  this.selectionManager.setCaret(nodeToSelect, 'INSIDE_START');
                }
              }
            } else if (this.selectionManager.isSelectionAtStart(contentEditable)) {
              if (DOMUtils.isBlockNodeDeletable(level0Node)) {
                if (level0Node.parentNode === DOMUtils.getPageNode()) {
                  if (level0Node.nextSibling) {
                    const nodeToSelect = level0Node.nextSibling.selectableContent
                      ? level0Node.nextSibling.selectableContent
                      : level0Node.nextSibling;
                    this.selectionManager.setCaret(nodeToSelect, 'INSIDE_START');
                    this.removeBlockElementFromDOM(actionContext, level0Node);
                  } else {
                    const data = NodeDataBuilder.buildParagraph({
                      data: {
                        parent_id: level0Node.parentNode.id,
                      },
                    });
                    this.inserBlockNodeOperation(actionContext, data, level0Node, 'BEFORE');

                    this.removeBlockElementFromDOM(actionContext, level0Node);
                    this.selectionManager.setCaret(DOMUtils.getBlockNode(data.id), 'INSIDE_START');
                  }
                } else if (level0Node.parentNode.tagName === ELEMENTS.TrackInsertElement.TAG) {
                  actionContext.addReferenceToRefresh(
                    level0Node.parentNode.getAttribute('element_reference'),
                  );
                  this.removeBlockElementFromDOM(actionContext, level0Node.parentNode);
                }
              }
            }
          }
        }
      }
    },
);
