import { CSSProperties } from 'react';

import SectionHeading from '_common/components/AuditLog/SectionHeading/SectionHeading';
import Table from '_common/components/AuditLog/Table/Table';
import FilterDisplay from '../Filters/Components/FilterDisplay/FilterDisplay';

import AuditLogContext from './AuditLogContext';

export type AuditLogProps = {
  objectId: ObjectId;
  data: { [x in ObjectId]: Objekt | FileObject } | null;
  noFlex?: boolean;
} & Pick<CSSProperties, 'margin'>;

const AuditLog = ({ objectId, data, margin, noFlex }: AuditLogProps) => {
  if (!data) {
    return null;
  }

  return (
    <AuditLogContext objectId={objectId} objectType={data[objectId].type}>
      <div style={{ margin }}>
        <SectionHeading
          objectId={objectId}
          objectType={data[objectId].type}
          objectCreationDate={data[objectId]?.time.creation}
          margin="0 0 1rem 0"
        />
      </div>
      <FilterDisplay identity="auditLog" />
      <Table noFlex={noFlex} />
    </AuditLogContext>
  );
};

export default AuditLog;
