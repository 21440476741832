import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip, Switch } from 'dodoc-design-system';
import { useParams } from 'react-router';

import { useDispatch, usePublicProfile, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { openModal } from '_common/modals/ModalsSlice';
import {
  selectReadOnlyMode,
  getDocumentObject,
  selectIsPageLayout,
} from 'Editor/redux/EditorStatusSlice';
import { getTrackingState } from 'Editor/redux/TrackingSlice';

import { ToolbarToggleButton } from 'Editor/components';

import styles from './TrackingState.module.scss';

const TrackingState = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { id } = useParams() as { id: string };

  const userId = useSelector((state) => state.auth.userId);
  const tracking = useSelector(getTrackingState);
  const profile = usePublicProfile(tracking.lock_user ?? '');
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const doc = useSelector(getDocumentObject);

  useEffect(() => {
    const key = `${userId}-${id}`;
    // @ts-expect-error Editor not TS
    window.localStorage.setItem(key, tracking.state);
    if (tracking.state) {
      EditorManager.getInstance().turnTrackingOn();
    } else {
      EditorManager.getInstance().turnTrackingOff();
    }
  }, [tracking.state]);

  const toggleTracking = () => {
    EditorManager.getInstance().toggleTrackingState();
  };

  const handleLockButtonClick = () => {
    dispatch(openModal('SuggestionModeLockConfirmationModal'));
  };

  let hasTrackChangesPermissions = false;
  let hasOwnerPermissions = false;
  if (doc.user_permissions) {
    hasTrackChangesPermissions =
      doc.user_permissions.includes('admin') ||
      doc.user_permissions.includes('owner') ||
      doc.user_permissions.includes('access');

    hasOwnerPermissions =
      doc.user_permissions.includes('admin') || doc.user_permissions.includes('owner');
  }

  const handleTrackingTooltip = () => {
    if (tracking.thirdParty) {
      return intl.formatMessage({ id: 'TRACK_CHANGES_LOCKED' });
    }
    return intl.formatMessage({ id: 'TRACK_CHANGES_LOCKED_BY' }, { name: profile?.name });
  };

  return (
    <Tooltip
      placement="bottom"
      disabled={!tracking.lock}
      content={
        <div
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: handleTrackingTooltip(),
          }}
        />
      }
      testId="track-changes-lock-state-tooltip"
    >
      <div
        className={`${styles.trackedActionsContainer} ${
          (isReadOnlyMode || isPageLayout) && styles.readOnlyMode
        }`}
      >
        <span className={`${styles.labelContainer} ${tracking.lock ? styles.disabled : ''}`}>
          <FormattedMessage id="editor.toolbar.trackChanges" />
        </span>
        <div style={{ marginRight: '1rem' }}>
          <Switch
            active={tracking.state}
            onChange={toggleTracking}
            disabled={
              !hasTrackChangesPermissions || tracking.lock || isReadOnlyMode || isPageLayout
            }
            testId="track-changes-switch"
          />
        </div>
        {hasOwnerPermissions && !(tracking.lock && tracking.thirdParty) && (
          <ToolbarToggleButton
            disabled={isReadOnlyMode || isPageLayout}
            isActive={tracking.lock}
            icon={tracking.lock ? 'Locked' : 'Unlocked'}
            toggleButtonClicked={handleLockButtonClick}
            testId="track-changes-lock"
          />
        )}
      </div>
    </Tooltip>
  );
};

export default TrackingState;
