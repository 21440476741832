import NotificationSettingsRow from 'Settings/components/NotificationSettings/NotificationSettingsRow/NotificationSettingsRow';
import Switch from 'Settings/components/NotificationSettings/Switch/Switch';

import styles from './NotificationSettings.module.scss';
import {
  useEditTenantNotificationSettingMutation,
  useGetTenantNotificationSettingsQuery,
} from '_common/services/api/SystemApi';
import { ProgressBar } from 'dodoc-design-system';

export const NOTIFICATIONS_SETTINGS = {
  profile: {
    title: 'USER_PROFILE',
    description: 'USER_PROFILE_DESCRIPTION',
    locked: true,
  },
  obj_deadline: {
    title: 'ELEMENT_DEADLINE',
    description: 'ELEMENT_DEADLINE_DESCRIPTION',
    locked: false,
  },
  obj_status: {
    title: 'ELEMENT_STATUS',
    description: 'ELEMENT_STATUS_DESCRIPTION',
    locked: false,
  },
  obj_permissions: {
    title: 'ELEMENT_PERMISSIONS',
    description: 'ELEMENT_PERMISSIONS_DESCRIPTION',
    locked: false,
  },
  container_updates: {
    title: 'ELEMENT_UPDATES',
    description: 'ELEMENT_UPDATES_DESCRIPTION',
    locked: false,
  },
  mentions: {
    title: 'USER_MENTION',
    description: 'USER_MENTION_DESCRIPTION',
    locked: true,
  },
  node_status: {
    title: 'DOCUMENT_CONTENT_APPROVED_REOPENED',
    description: 'DOCUMENT_CONTENT_APPROVED_REOPENED_DESCRIPTION',
    locked: false,
  },
  comments: {
    title: 'DOCUMENT_COMMENTS',
    description: 'DOCUMENT_COMMENTS_DESCRIPTION',
    locked: false,
  },
  suggestions: {
    title: 'DOCUMENT_SUGGESTIONS',
    description: 'DOCUMENT_SUGGESTIONS_DESCRIPTION',
    locked: false,
  },
  tasks: {
    title: 'DOCUMENT_TASKS',
    description: 'DOCUMENT_TASKS_DESCRIPTION',
    locked: false,
  },
  pdf_annotations: {
    title: 'PDF_COMMENTS',
    description: 'PDF_COMMENTS_DESCRIPTION',
    locked: false,
  },
  pdf_tasks: {
    title: 'PDF_TASKS',
    description: 'PDF_TASKS_DESCRIPTION',
    locked: false,
  },
} as const;

const NotificationSettings = () => {
  const { data, isLoading } = useGetTenantNotificationSettingsQuery();
  const [editNotificationSetting] = useEditTenantNotificationSettingMutation();

  if (isLoading) {
    return <ProgressBar testId="tenant-notification-settings-loading-progressBar" />;
  }

  if (!data) {
    return null;
  }

  return (
    <div className={styles.notificationContainer}>
      {(Object.keys(NOTIFICATIONS_SETTINGS) as Array<keyof typeof NOTIFICATIONS_SETTINGS>).map(
        (notificationId) => (
          <NotificationSettingsRow
            key={notificationId}
            title={NOTIFICATIONS_SETTINGS[notificationId].title}
            description={NOTIFICATIONS_SETTINGS[notificationId].description}
            id={notificationId}
          >
            <Switch
              onChange={() => {
                editNotificationSetting({
                  setting: notificationId,
                  value: !data.notifications[notificationId],
                });
              }}
              active={data.notifications[notificationId]}
              disabled={NOTIFICATIONS_SETTINGS[notificationId].locked}
              testId={`${notificationId}_switch`}
            />
          </NotificationSettingsRow>
        ),
      )}
    </div>
  );
};

export default NotificationSettings;
