import { DOMUtils } from '_common/utils';

export class RangeIterator implements DoDOCSelection.IIterator<Node | null> {
  private workingRange: DoDOCSelection.DoDOCRange;
  private currentNode: Node | null;
  private nextNode: Node | null;
  private preivousNode: Node | null;

  constructor(range: DoDOCSelection.DoDOCRange) {
    this.workingRange = range;

    this.currentNode = null;
    this.nextNode = this.getNext();
    this.preivousNode = this.getPreivous();
  }

  private getNext(): Node | null {
    let workingNode;

    if (this.currentNode === null) {
      if (
        this.workingRange.startContainer === this.workingRange.commonAncestorContainer &&
        !this.workingRange.collapsed
      ) {
        const childNode =
          this.workingRange.commonAncestorContainer.childNodes[this.workingRange.startOffset];

        if (childNode) {
          return childNode;
        } else {
          return this.workingRange.startContainer;
        }
      } else {
        return DOMUtils.findFirstLevelChildNode(
          this.workingRange.commonAncestorContainer,
          this.workingRange.startContainer,
        );
      }
    } else {
      workingNode = this.currentNode;
      let workingOffset = Array.from(workingNode.parentNode?.childNodes || []).indexOf(
        workingNode as ChildNode,
      );
      let nextNode = null;

      if (workingNode === this.workingRange.endContainer && workingNode instanceof Text) {
        nextNode = null;
      } else if (
        workingNode.parentNode === this.workingRange.endContainer &&
        workingOffset === this.workingRange.endOffset - 1 &&
        !workingNode.childNodes.length
      ) {
        nextNode = null;
      } else if (DOMUtils.parentContainsNode(workingNode, this.workingRange.startContainer)) {
        nextNode = DOMUtils.findFirstLevelChildNode(workingNode, this.workingRange.startContainer);
      } else if (
        workingNode === this.workingRange.startContainer &&
        workingNode.childNodes[this.workingRange.startOffset]
      ) {
        nextNode = workingNode.childNodes[this.workingRange.startOffset];
      } else if (
        workingNode !== this.workingRange.startContainer &&
        workingNode.childNodes.length
      ) {
        nextNode = workingNode.childNodes[0];
        for (let i = 0; i < workingNode.childNodes.length; i++) {
          if (
            this.workingRange.containsNode(workingNode.childNodes[i]) ||
            this.workingRange.instersectsNode(workingNode.childNodes[i])
          ) {
            nextNode = workingNode.childNodes[i];
            break;
          }
        }
      } else if (
        workingNode.nextSibling &&
        (this.workingRange.containsNode(workingNode.nextSibling) ||
          this.workingRange.instersectsNode(workingNode.nextSibling))
      ) {
        nextNode = workingNode.nextSibling;
      } else {
        let node: Node | null = workingNode;
        while (node && this.workingRange.commonAncestorContainer.contains(node.parentNode)) {
          node = node.parentNode;
          if (node && node.nextSibling) {
            nextNode = node.nextSibling;
            break;
          }
        }
      }

      if (
        nextNode &&
        (this.workingRange.containsNode(nextNode) || this.workingRange.instersectsNode(nextNode))
      ) {
        return nextNode;
      }
    }

    return null;
  }

  private getPreivous(): Node | null {
    // const workingNode = this.currentNode ? this.currentNode : this.workingRange.startContainer;

    // if (
    //   this.currentNode !== null &&
    //   this.currentNode.contains(this.workingRange.startContainer) &&
    //   DOMUtils.parentContainsNode(
    //     this.workingRange.commonAncestorContainer,
    //     this.currentNode.parentNode,
    //   )
    // ) {
    //   return this.currentNode.parentNode;
    // } else if (workingNode.childNodes.length) {
    //   return workingNode.childNodes[workingNode.childNodes.length - 1];
    // } else if (workingNode.previousSibling) {
    //   return workingNode.previousSibling;
    // } else {
    //   let node: Node | null = workingNode;
    //   while (node && this.workingRange.commonAncestorContainer.contains(node.parentNode)) {
    //     node = node.parentNode;
    //     if (node && node.previousSibling) {
    //       return node.previousSibling;
    //     }
    //   }
    // }

    return null;
  }

  reset() {
    this.currentNode = null;
    this.nextNode = this.getNext();
    this.preivousNode = this.getPreivous();
  }

  hasNext(): boolean {
    return this.nextNode != null;
  }

  next(): Node | null {
    if (this.nextNode != null) {
      this.preivousNode = this.currentNode;
      this.currentNode = this.nextNode;
      this.nextNode = this.getNext();

      return this.currentNode;
    }

    return null;
  }

  hasPrevious(): boolean {
    return this.preivousNode != null;
  }

  previous(): Node | null {
    if (this.preivousNode != null) {
      this.nextNode = this.currentNode;
      this.currentNode = this.preivousNode;
      this.preivousNode = this.getPreivous();

      return this.currentNode;
    }

    return null;
  }
}
