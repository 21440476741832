import { Dropdown, Toggle, Tooltip, usePopper } from 'dodoc-design-system';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';
import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { FormattedMessage, useIntl } from 'react-intl';

type PriorityProps = {
  annotation: PDF.Annotation;
  testId: string;
};

const PRIORITY: {
  [key in PDF.Annotation['priority']]: { icon: IconTypes['24']; labelId: string };
} = {
  High: {
    icon: 'PDFHigh',
    labelId: 'editor.sidebar.review.filter.priority.high',
  },
  Medium: {
    icon: 'PDFMedium',
    labelId: 'editor.sidebar.review.filter.priority.medium',
  },
  Low: {
    icon: 'PDFLow',
    labelId: 'editor.sidebar.review.filter.priority.low',
  },
};

const Priority = ({ annotation, testId }: PriorityProps) => {
  const pdfManager = usePDFContext();
  const intl = useIntl();
  const { canChangePriority } = usePDFPermissions();
  const { referenceProps, popperProps } = usePopper({
    placement: 'bottom-start',
    closeOnReferenceHidden: true,
  });

  const handleChangePriority = (priority: PDF.Annotation['priority']) => {
    if (annotation.priority !== priority) {
      pdfManager.changeAnnotationPriority(annotation.pageNumber, annotation.id, priority);
    }
  };

  return (
    <>
      <Tooltip
        placement="top"
        content={intl.formatMessage({ id: 'global.priority' })}
        testId={`${testId}-priority-tooltip`}
      >
        <Toggle
          variant="link"
          size="medium"
          icon={PRIORITY[annotation.priority]?.icon ?? 'PDFMedium'}
          isToggled={popperProps.isOpen}
          testId={`${testId}-priority-toggle`}
          {...referenceProps}
          onClickCapture={(e) => {
            referenceProps.onClickCapture();
          }}
        />
      </Tooltip>
      <Dropdown {...popperProps} testId={`${testId}-priority-dropdown`}>
        {(Object.keys(PRIORITY) as PDF.Annotation['priority'][]).map((priority) => (
          <Dropdown.Item
            key={priority}
            onClick={() => handleChangePriority(priority)}
            disabled={!canChangePriority(annotation)}
            prefixIcon={PRIORITY[priority].icon}
            testId={`${testId}-priority-${priority}-item`}
          >
            <FormattedMessage id={PRIORITY[priority].labelId} />
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  );
};

export default Priority;
