import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import Sidebar from './Sidebar';
import styles from './Sidebar.module.scss';

const SidebarBoundary = () => (
  <div className={styles.sidebar}>
    <IntlErrorBoundary size="xsmall" collapsed tooltipPlacement="left" margin="auto">
      <Sidebar />
    </IntlErrorBoundary>
  </div>
);

export default SidebarBoundary;
