import { ErrorBuildingDOMElement } from 'Editor/services/_CustomErrors';
import { ParseMapper } from 'Editor/services/Parsers';
import {
  TableElement,
  TableCellElement,
  FieldElement,
  ParagraphElement,
} from 'Editor/services/VisualizerManager';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

const buildNode = (elementOptions: Editor.Elements.BuildElementOptions): HTMLElement | null => {
  if (elementOptions) {
    let tagName: Editor.Elements.SupportedTagNames;
    let options: ElementCreationOptions | undefined;
    if (typeof elementOptions === 'string') {
      tagName = elementOptions;
    } else {
      ({ tagName, ...options } = elementOptions);
    }

    if (tagName) {
      return document.createElement(tagName, options);
    }
  }

  return null;
};

// TODO: needs refactor to view factory
/**
 * @todo needs refactor to ViewFactory
 * @deprecated use ViewFactory.getViewByTag
 */
class DOMElementFactory {
  /**
   * @description build DOM element based on tag
   * @param {*} elementOptions String with element tagName or Object with tagName and options to create element
   * @param {Object} attributes
   * @param {Array} childElements
   * @memberof DOMElementFactory
   */
  static buildElement(
    elementOptions: Editor.Elements.BuildElementOptions,
    attributes?: any,
    childElements?: any[],
  ): HTMLElement {
    let node = buildNode(elementOptions);

    if (!node) {
      throw new ErrorBuildingDOMElement(elementOptions);
    }

    // set element type;
    if (attributes?.element_type == null) {
      if (attributes == null) {
        attributes = {};
      }
      attributes.element_type = ParseMapper.getElementTypeForNode(node);
    }

    if (attributes && typeof attributes === 'object' && Object.keys(attributes).length) {
      node = DOMElementFactory.addAttributesToElement(node, attributes);
    }

    if (childElements && childElements.length) {
      node = DOMElementFactory.addChildsToElement(node, childElements);
    }

    return node;
  }

  /**
   * @description add attributes to node element
   * @param {Node} nodeElement
   * @param {Object} attributes
   * @memberof DOMElementFactory
   */
  static addAttributesToElement(nodeElement: HTMLElement, attributes: any) {
    if (nodeElement && attributes) {
      if (attributes instanceof NamedNodeMap) {
        Array.from(attributes).forEach((attr) => {
          if (attr.value != null) {
            nodeElement.setAttribute(attr.name, attr.value);
          }
        });
      } else if (typeof attributes === 'object' && Object.keys(attributes).length) {
        Object.keys(attributes).forEach((key) => {
          if (attributes[key] != null) {
            if (key === 'style' && typeof attributes[key] === 'object') {
              const styleObject = attributes[key];
              Object.keys(styleObject).forEach((styleKey: any) => {
                nodeElement.style[styleKey] = styleObject[styleKey];
              });
            } else {
              nodeElement.setAttribute(key, attributes[key]);
            }
          }
        });
      }
    }

    return nodeElement;
  }

  /**
   * @description add childs to node element based on tag
   * @param {Node} nodeElement
   * @param {Array} childElements
   * @memberof DOMElementFactory
   */
  static addChildsToElement(nodeElement: HTMLElement, childElements: any[]) {
    if (nodeElement && childElements && childElements.length) {
      Array.from(childElements).forEach((element) => {
        if (element instanceof Node) {
          nodeElement.appendChild(element);
        } else {
          const childElement = DOMElementFactory.buildElement(element);
          nodeElement.appendChild(childElement);
        }
      });
    }

    return nodeElement;
  }

  static createTextNode(text: string): Text {
    return document.createTextNode(text);
  }

  static createNewParagraphElement(
    attributes?: any,
    childNodes?: any[],
    options?: any,
  ): HTMLElement {
    let baseAttributes: any = {
      'data-style-id': ELEMENTS.ParagraphElement.ELEMENT_TYPE,
    };

    if (attributes && attributes instanceof NamedNodeMap) {
      Array.from(attributes).forEach((attr: any) => {
        baseAttributes[attr.name] = attr.value;
      });
    } else if (typeof attributes === 'object') {
      baseAttributes = {
        ...baseAttributes,
        ...attributes,
      };
    }

    // force set element type
    baseAttributes.element_type = ELEMENTS.ParagraphElement.ELEMENT_TYPE;

    return DOMElementFactory.buildElement(
      { ...(options || {}), tagName: ELEMENTS.ParagraphElement.TAG },
      baseAttributes,
      childNodes,
    );
  }

  static createNewTableElement(attributes?: any, childNodes?: any[], options?: any): TableElement {
    const table = DOMElementFactory.buildElement(
      {
        ...(options || {}),
        tagName: ELEMENTS.TableElement.TAG,
        is: ELEMENTS.TableElement.IDENTIFIER,
      },
      attributes,
      childNodes,
    ) as TableElement;

    table.id = EditorDOMUtils.generateRandomNodeId();
    table.setAttribute('contenteditable', 'false');

    // table.setAttribute('element_type', ELEMENTS.TableElement.ELEMENT_TYPE);
    // table.setAttribute('is', TableElement.IDENTIFIER);

    return table;
  }

  static createNewTableCellElement(
    attributes?: any,
    childNodes?: any[],
    options?: any,
  ): TableCellElement {
    const cell = DOMElementFactory.buildElement(
      {
        ...(options || {}),
        tagName: ELEMENTS.TableCellElement.TAG,
        is: ELEMENTS.TableCellElement.IDENTIFIER,
      },
      attributes,
      childNodes,
    ) as TableCellElement;

    cell.id = EditorDOMUtils.generateRandomNodeId();
    // cell.setAttribute('is', TableElement.IDENTIFIER);
    cell.setAttribute('contenteditable', 'true');

    return cell;
  }

  static buildTableHeader(tableId: string, columnsWidth: any[]): HTMLElement {
    const header = DOMElementFactory.buildElement(ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER.TAG, {
      element_type: ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER.ELEMENT_TYPE,
    });

    if (tableId) {
      header.setAttribute('parent_id', tableId);
    }

    const row = DOMElementFactory.buildElement(ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG);

    if (columnsWidth && columnsWidth.length) {
      columnsWidth.forEach((width) => {
        const th = DOMElementFactory.buildElement(
          ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER_CELL.TAG,
        );
        if (width > 0) {
          th.style.width = `${EditorDOMUtils.convertUnitTo(width, 'pt', 'px', 3)}px`;
        } else {
          th.style.width = '100px';
        }
        row.appendChild(th);
      });
    }

    header.appendChild(row);

    header.style.visibility = 'collapse';

    return header;
  }

  static createNewHiddenTableCell(headId: string): TableCellElement {
    return DOMElementFactory.createNewTableCellElement({
      style: { display: 'none' },
      'head-id': headId,
    });
  }

  /**
   *
   * @param rows
   * @param columns
   * @param tableWidth in points
   * @param headerRow
   * @returns
   */
  static buildTable(
    rows: number,
    columns: number,
    tableWidth: number = 516,
    headerRow: boolean = false,
  ): TableElement {
    const columnWidth = +(tableWidth / columns).toFixed(2);
    const height = 48;

    if (!tableWidth) {
      tableWidth = 516;
    }

    const table: TableElement = DOMElementFactory.createNewTableElement();

    table.dataset.width = `abs,${tableWidth}`;
    table.dataset.ar = 'true';

    // build table body
    const tbody = DOMElementFactory.buildElement(
      ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG,
    ) as HTMLTableSectionElement;

    for (let i = 0; i < rows; i += 1) {
      const tr = DOMElementFactory.buildElement(ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG);
      tr.style.height = `${EditorDOMUtils.convertUnitTo(height, 'pt', 'px', 3)}px`;
      tr.dataset.rcs = 'true';
      tr.dataset.rh = `${height}`;

      for (let j = 0; j < columns; j += 1) {
        const td = DOMElementFactory.createNewTableCellElement();

        td.dataset.width = `abs,${columnWidth}`;

        const p = DOMElementFactory.createNewParagraphElement();
        p.appendChild(DOMElementFactory.buildElement('br'));
        td.appendChild(p);
        tr.appendChild(td);
      }

      tbody.appendChild(tr);
    }

    if (headerRow) {
      tbody.rows[0].dataset.hr = 'true';
    }

    table.appendChild(tbody);

    table.preRender();

    return table;
  }

  static buildCaptionElements(options: any = {}): (HTMLElement | Text)[] {
    const { label, text, chapterNumbering } = options;

    const captionElements = [];

    if (label) {
      captionElements.push(DOMElementFactory.createTextNode(`${label} `));

      if (chapterNumbering) {
        if (chapterNumbering.chapterType) {
          captionElements.push(
            DOMElementFactory.buildElement(ELEMENTS.FieldElement.TAG, {
              'data-type': ELEMENTS.FieldElement.TYPES.STYLE_REF,
              'data-ref': chapterNumbering.chapterType,
              'data-cpt': label,
            }),
          );
        }

        if (chapterNumbering.separator) {
          // TODO: get separator
          captionElements.push(DOMElementFactory.createTextNode(chapterNumbering.separator));
        }
      }

      captionElements.push(
        DOMElementFactory.buildElement(ELEMENTS.FieldElement.TAG, {
          'data-type': ELEMENTS.FieldElement.TYPES.CAPTION,
          'data-cpt': label,
        }),
      );

      if (text && text.length > 0) {
        captionElements.push(DOMElementFactory.createTextNode(` ${text}`));
      }
    } else {
      throw new Error('No label definition!');
    }

    return captionElements;
  }

  static buildCaptionBlock(options: any = {}): ParagraphElement {
    const subType = options.ls || ELEMENTS.ParagraphElement.BASE_STYLES.PARAGRAPH;
    const p = DOMElementFactory.createNewParagraphElement(
      {
        'data-style-id': subType,
      },
      DOMElementFactory.buildCaptionElements(options),
    ) as ParagraphElement;

    return p;
  }

  static buildCrossReferenceElement(
    options: Editor.Data.CrossReferences.PresentationTextOptionsType,
  ): FieldElement {
    // options = {
    // label: '',
    // target: '',
    // format: '',
    // link: '',
    // }

    const attributes: any = {
      'data-type': ELEMENTS.FieldElement.TYPES.CROSS_REFERENCE,
    };

    if (options.format) {
      let value = options.format;
      if (typeof options.format === 'object') {
        value = options.format;
      }
      attributes['data-format'] = value;
    }

    if (options.link === true) {
      attributes['data-link'] = options.link;
    }

    if (options.target) {
      attributes['data-ref'] = options.target;
    }

    return DOMElementFactory.buildElement(
      ELEMENTS.FieldElement.TAG,
      attributes,
      [],
    ) as FieldElement;
  }
}

export default DOMElementFactory;
