import { forwardRef, useState, Ref, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Card } from 'dodoc-design-system';

import { stringToRichText, richTextToString } from 'utils';
import { usePDFContext } from 'PDF/PDFContext';
import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector } from '_common/hooks';

import { selectAnnotation } from 'PDF/redux/PDFAnnotationsSlice';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';

import { InteractionController } from '_common/components';

import Header from '../ViewAnnotationCard/Header/Header';
import Body from './Body/Body';
import Footer from './Footer/Footer';

export type EditableAnnotationCardProps = {
  annotation: PDF.Annotation;
  sidebar: boolean;
  editMode?: boolean;
  testId: string;
};

const EditableAnnotationCardContent = forwardRef(
  (
    { annotation, sidebar, editMode, testId }: EditableAnnotationCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const pdfManager = usePDFContext();
    const isSelected = useSelector((state) => state.pdf.annotations.selected === annotation.id);

    const [newContent, setNewContent] = useState<string>(
      richTextToString(annotation.content?.content),
    );

    const ctaRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
      if (ctaRef?.current) {
        dispatch(
          setPulseData({
            annotationCardCtaRect: {
              top: ctaRef.current.offsetTop,
              left: ctaRef.current.offsetLeft,
              height: ctaRef.current.offsetHeight,
              width: ctaRef.current.offsetWidth,
            },
          }),
        );
      }

      return () => {
        dispatch(
          setPulseData({
            annotationCardCtaRect: undefined,
          }),
        );
      };
    }, []);

    const handleEditContent = async () => {
      try {
        await pdfManager.editAnnotationContent(
          annotation.pageNumber,
          annotation.id,
          stringToRichText(newContent),
        );
        notify({
          type: 'success',
          title: intl.formatMessage({ id: 'COMMENT_EDITED' }),
          message: intl.formatMessage({ id: 'THE_COMMENT_WAS_SUCCESSFULLY_EDITED' }),
        });
        dispatch(selectAnnotation(annotation.id));
        if (annotation.subtype === 'Highlight') {
          dispatch(completeAction('pdf_annotations_commentHighlight'));
        } else if (annotation.subtype === 'Note') {
          dispatch(completeAction('pdf_comments_createComment'));
        }
      } catch (e) {
        throw e;
      }
    };

    return (
      <Card
        sidebar={sidebar}
        selected={isSelected}
        width={sidebar ? '100%' : undefined}
        ref={ref}
        id={`annotation-card-${annotation.id}`}
        testId={`annotation-card-${annotation.id}`}
      >
        <Card.Header>
          <Header annotation={annotation} editMode={editMode} testId={testId} />
        </Card.Header>
        <Card.Body>
          <InteractionController
            environment="dopdf"
            rules={[{ interaction: 'pdf_annotation_save' }]}
            style={{ flexDirection: 'column' }}
          >
            <Body
              annotation={annotation}
              onChange={setNewContent}
              newContent={newContent}
              testId={testId}
            />
          </InteractionController>
        </Card.Body>
        <Card.Footer size="large">
          <InteractionController environment="dopdf">
            <Footer onSave={handleEditContent} ref={ctaRef} />
          </InteractionController>
        </Card.Footer>
      </Card>
    );
  },
);

export default EditableAnnotationCardContent;
