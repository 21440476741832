import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { OnboardingEnvironments, startOnboarding } from 'App/redux/onboardingSlice';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

import styles from './WelcomeScreen.module.scss';

type Location = Exclude<OnboardingEnvironments, 'explorer'>;

type WelcomeScreenProps = {
  location: Location;
  onSkip: (skipping: boolean) => void;
};

const LABEL_IDS: {
  [x in Location]: {
    title: string;
    description: string;
    cta: string;
  };
} = {
  editor: {
    title: 'WELCOME_TO_THE_DODOC_EDITOR',
    description: 'WELCOME_EDITOR_DESCRIPTION',
    cta: 'EXPLORE_DODOC_EDITOR',
  },
  dopdf: {
    title: 'WELCOME_TO_THE_PDF_REVIEWER',
    description: 'WELCOME_PDF_DESCRIPTION',
    cta: 'EXPLORE_DODOC_PDF_REVIEWER',
  },
};

const WelcomeScreen = ({ location, onSkip }: WelcomeScreenProps) => {
  const dispatch = useDispatch();
  const { data: currentUser } = useGetCurrentUserQuery();

  const active = useSelector((state) => state.onboarding.active[location]);
  const started = useSelector((state) => state.onboarding.started[location]);

  const toggleContainers = (location: Location, on: boolean) => {
    let container: HTMLElement | null = null;
    const welcomeScreenContainer = document.getElementById('WelcomeScreen');
    switch (location) {
      case 'dopdf': {
        container = document.getElementById('pdfContainer');
        break;
      }
      case 'editor': {
        container = document.getElementById('EditorRoot');
        break;
      }
      default: {
        break;
      }
    }

    if (container) {
      if (on) {
        container.style.display = 'inherit';
        if (welcomeScreenContainer) {
          welcomeScreenContainer.style.width = 'inherit';
          welcomeScreenContainer.style.display = 'none';
        }
      } else {
        container.style.display = 'none';
        if (welcomeScreenContainer) {
          welcomeScreenContainer.style.width = '100%';
          welcomeScreenContainer.style.display = 'inherit';
        }
      }
    }
  };

  const handleStart = () => {
    toggleContainers(location, true);
    if (!started) {
      dispatch(startOnboarding(location));
    }
  };

  const handleSkip = () => {
    onSkip(true);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        e.preventDefault();
      }
    };
    window.document.addEventListener('keydown', handleKeyDown);

    return () => {
      toggleContainers(location, true);
      window.document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    toggleContainers(location, !active || started);
  }, [location, active, started]);

  if (active && !started) {
    if (currentUser) {
      return (
        <div className={styles.root}>
          <div className={styles.overlay}>
            <div className={styles.content}>
              <div className={styles.title}>
                <FormattedMessage id={LABEL_IDS[location].title} />,{' '}
                {currentUser.profile.first_name}! 👋🏼
              </div>
              <div className={styles.description}>
                <FormattedMessage id={LABEL_IDS[location].description} />
              </div>
              <div className={styles.footer}>
                <Button size="large" variant="primary" fullWidth onClick={handleStart} testId={`${LABEL_IDS[location].cta.toLowerCase()}-button`}>
                  <FormattedMessage id={LABEL_IDS[location].cta} />
                </Button>
                <div className={styles.skip}>
                  <FormattedMessage id="NOT_YOUR_FIRST_TIME_USING_DODOC" />
                  <Button size="medium" variant="link" onClick={handleSkip} testId="skip-onboarding-button">
                    <FormattedMessage id="SKIP_ONBOARDING" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return null;
};

export default WelcomeScreen;
