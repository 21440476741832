import { Select } from 'dodoc-design-system';

import type {
  SelectProps,
  SelectOption,
} from 'dodoc-design-system/build/types/Components/Selects/Select';

type ToolbarDropdownOption = SelectOption & {
  supported?: boolean;
  fontFamily?: string;
};
type ToolbarDropdownProps<T extends ToolbarDropdownOption, M extends boolean = false> = SelectProps<
  T,
  M
> & { width: string };

const ToolbarDropdown = <T extends ToolbarDropdownOption, M extends boolean = false>({
  customStyles,
  width,
  creatable,
  isDisabled,
  ...props
}: ToolbarDropdownProps<T, M>) => {
  const styles: ToolbarDropdownProps<T, M>['customStyles'] = {
    menu: (provided) => ({
      ...provided,
      width: '30rem',
      maxHeight: '62rem',
      zIndex: 3,
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: '1rem',
      width: '30rem',
      maxHeight: '62rem',
    }),
    group: (provided) => ({
      ...provided,
      padding: '0',
    }),
    groupHeading: (provided) => ({
      ...provided,
      background: '#F4FAFE',
      lineHeight: '3rem',
      fontSize: '1.25rem',
      color: '#6D6D82',
      paddingLeft: '1rem',
      letterSpacing: '1px',
      margin: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      height: '5rem',
      //@ts-expect-error select styles type its incorrect
      fontFamily: state.data.fontFamily ? `"${state.data.fontFamily}"` : 'Inter',
    }),
  };

  return (
    <Select.Ghost
      {...props}
      size="medium"
      disabled={isDisabled}
      width={`${width}rem`}
      creatable={creatable}
      clearable={false}
      customStyles={{ ...styles, ...customStyles }}
    />
  );
};

export default ToolbarDropdown;
