import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import Title from './Title';
import styles from './Title.module.scss';

const TitleBoundary = () => (
  <div className={styles.title}>
    <IntlErrorBoundary size="small">
      <Title />
    </IntlErrorBoundary>
  </div>
);

export default TitleBoundary;
