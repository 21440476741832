import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip, Toggle, Button } from 'dodoc-design-system';

import { useSelector, useDispatch } from '_common/hooks';
import { navigateToSettings } from 'router/history';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import {
  toggleInfoPanel,
  viewPersonalSpaces,
} from 'Storage/pages/SpacesListPage/redux/spacesListPageSlice';
import { selectUserIsAdmin } from '_common/services/api/authority';

import { ActionsToggle, ActionBar } from '_common/components';

import SearchInput from 'Search/pages/SearchPage/SearchInput/SearchInput';
import { paths } from '_types/api';

type HeaderProps = {
  permissions?: paths['/api/object/space/list']['get']['responses']['200']['content']['application/json']['permissions'];
};

const PAGE_IDENTITY = 'spaces';

const Header = ({ permissions }: HeaderProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const selected = Object.keys(useSelector((state) => state.table.selected));
  const identity = useSelector((state) => state.table.identity);
  const data = useSelector((state) => state.app.data);
  const infoPanelOpen = useSelector((state) => state.spaces.infoPanelOpen);
  const loading = useSelector((state) => state.app.loading.isOpen);
  const error = useSelector((state) => state.app.error);
  const current = useSelector((state) => data[state.storage.current ?? '']);
  const userIsAdmin = useSelector(selectUserIsAdmin);

  const checkErrorStatus = () => {
    return error.status === 400 || error.status === 403 || error.status === 404;
  };

  const handleShareClicked = () => {
    if (selected.length === 1) {
      dispatch(
        openAndUpdateModal({
          modal: 'ShareModal',
          data: {
            view: 'users',
            objectId: selected[0],
            objectType: data[selected[0]].type,
            editor: false,
          },
        }),
      );
    }
  };

  const handleGoToSettings = () => {
    navigateToSettings('space', selected[0]);
  };

  const handleNewSpaceModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'CreateNewObjectModal',
        data: {
          title: intl.formatMessage({ id: 'spaces.createSpace' }),
          nameLabel: intl.formatMessage({ id: 'spaces.spaceName' }),
          namePlaceholder: intl.formatMessage({
            id: 'spaces.placeholderInput',
          }),
          descriptionLabel: intl.formatMessage({
            id: 'spaces.spaceDescription',
          }),
          descriptionPlaceholder: intl.formatMessage(
            { id: 'NEW_OBJECT_DESCRIPTION_PLACEHOLDER' },
            { value: 'space' },
          ),
          action: 'createSpace',
        },
      }),
    );
  };

  const handleDisabledShareButton = () => {
    const conditionsToEnableShare = ['admin', 'owner', 'add_permission', 'remove_permission'];

    if (
      loading ||
      selected.length !== 1 ||
      (data[selected[0]] && data[selected[0]].personal) ||
      !conditionsToEnableShare.some(
        (permission) =>
          data[selected[0]] && data[selected[0]].user_permissions.includes(permission),
      )
    ) {
      return true;
    }

    return false;
  };

  return (
    <ActionBar>
      <ActionBar.Breadcrumb
        path={[
          {
            id: 'spaces',
            name: intl.formatMessage({ id: 'storage.sidebar.spaces' }),
          },
        ]}
        pageLoading={loading}
      />

      {userIsAdmin && (
        <div style={{ marginRight: '3.75rem' }}>
          <Button
            size="medium"
            variant="neutral"
            onClick={() => {
              dispatch(viewPersonalSpaces(true));
            }}
            disabled={loading}
            testId="spaces-page-view-personal-spaces-button"
          >
            <FormattedMessage id="VIEW_PERSONAL_SPACES_BUTTON" />
          </Button>
        </div>
      )}
      <Toggle
        size="medium"
        variant="standard"
        icon="New"
        onClick={handleNewSpaceModal}
        disabled={loading || !permissions?.space}
        testId="create-new-space-button"
      >
        <FormattedMessage id="NEW" />
      </Toggle>
      <Toggle
        size="medium"
        variant="standard"
        icon="Settings"
        onClick={handleGoToSettings}
        disabled={selected.length !== 1 || loading}
        style={{ marginLeft: '0.5rem' }}
        testId="spaces-page-settings-button"
      >
        <FormattedMessage id="global.settings" />
      </Toggle>
      <Toggle
        size="medium"
        variant="standard"
        icon="Share"
        onClick={handleShareClicked}
        disabled={handleDisabledShareButton()}
        style={{ marginLeft: '0.5rem' }}
        testId="spaces-page-share-button"
      >
        <FormattedMessage id="storage.actionBar.actions.share" />
      </Toggle>

      <ActionsToggle
        disabled={selected.length === 0 || checkErrorStatus()}
        selected={selected}
        current={current}
        identity={PAGE_IDENTITY}
      />
      <div style={{ marginLeft: '5.5rem' }}>
        <Tooltip
          placement="bottom"
          content={intl.formatMessage({ id: 'global.information' })}
          testId="spaces-page-information-tooltip"
        >
          <Toggle
            size="medium"
            variant="standard"
            icon="Information"
            isToggled={infoPanelOpen}
            onClick={() => {
              dispatch(toggleInfoPanel());
            }}
            disabled={identity[PAGE_IDENTITY]?.list.length === 0 || loading}
            testId="spaces-page-information-toggle"
          />
        </Tooltip>
      </div>
      <SearchInput disabled={loading} style={{ marginLeft: '2rem' }} />
    </ActionBar>
  );
};

export default Header;
