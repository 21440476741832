import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import VersionHistoryPanel from './VersionHistoryPanel';

const VersionHistoryPanelBoundary = () => (
  <IntlErrorBoundary size="medium" margin="16rem 0 0 0">
    <VersionHistoryPanel />
  </IntlErrorBoundary>
);

export default VersionHistoryPanelBoundary;
