import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { Divider, EmptyState } from 'dodoc-design-system';

import { useEffectOnUpdate } from '_common/hooks';
import { InstanceService, SharedService } from '_common/services';
import { updateModal } from '_common/modals/ModalsSlice';

import { Table } from '_common/components';

import Menu from './Menu/Menu';
import TableRow from './TableRow/TableRow';
import Header from './Header';
import { listObjects } from '../../../components/Table/TableSlice';

import styles from './NavigateToDoDOC.module.scss';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

type NavigateToDoDOCProps = {
  menuOptions: {
    identity?: string;
    label?: string;
    icon: IconTypes['24'];
  }[];
  menuSelected: {
    identity?: string;
    label?: string;
    icon: IconTypes['24'];
  };
  title: string;
};

const NavigateToDoDOC = ({ menuOptions, menuSelected, title }: NavigateToDoDOCProps) => {
  const data = useSelector((state) => state.app.data);
  const intl = useIntl();
  const selected = Object.keys(useSelector((state) => state.table.selected));
  const object = data[selected[0]];
  const current = useSelector((state) => state.modals.CitationsModal.navigation?.current);
  const identity = useSelector((state) => state.modals.CitationsModal.navigation?.identity);
  const dispatch = useDispatch();
  const [parent, setParent] = useState<Objekt | null>(null);
  const baseCondition = useSelector((state) => state.search.baseCondition);
  const document = useSelector(getDocumentObject);

  //#region Table object listing handlers
  const fetchObjects = useCallback(
    async (parameters) => {
      if (identity === 'storage' || identity === 'storage/folder') {
        const instanceService = new InstanceService();
        if (!object && !data[current]) {
          const { data } = await instanceService.getPersonalSpaceInfo();
          dispatch(
            updateModal({
              modal: 'CitationsModal',
              // @ts-expect-error Missing endpoint type "/api/object/space/get"
              data: { navigation: { current: data.id, identity: 'storage' } },
            }),
          );
          // @ts-expect-error Missing endpoint type "/api/object/space/get"
          setParent(data);
          // @ts-expect-error Missing endpoint type "/api/object/space/get"
          const resp = await instanceService.listObject(data.id, data.type, parameters);
          // @ts-expect-error Generic endpoint
          resp.data.nodes.map((node) => {
            if (node.id === document.id) {
              node.citations = document.citations;
              return node;
            } else {
              return node;
            }
          });
          return resp;
        } else {
          const selectedObject = data[current];
          dispatch(
            updateModal({
              modal: 'CitationsModal',
              data: { navigation: { current, identity: 'storage' } },
            }),
          );
          const resp = await instanceService.listObject(
            selectedObject.id,
            selectedObject.type,
            parameters,
          );
          // @ts-expect-error Generic endpoint
          resp.data.nodes.map((node) => {
            if (node.id === document.id) {
              node.citations = document.citations;
              return node;
            } else {
              return node;
            }
          });

          return resp;
        }
      } else if (identity === 'spaces') {
        const res = await new InstanceService().listSpaces(parameters);
        return res;
      } else if (identity === 'shared') {
        const resp = await new SharedService().listShared(parameters);
        // @ts-expect-error Missing endpoint type "/api/object/share/list"
        resp.data.nodes.map((node) => {
          if (node.id === document.id) {
            node.citations = document.citations;
            return node;
          } else {
            return node;
          }
        });
        return resp;
      } else {
        const resp = await new InstanceService().searchObject([baseCondition], parameters);
        // @ts-expect-error Generic endpoint
        resp.data.nodes.map((node) => {
          if (node.id === document.id) {
            node.citations = document.citations;
            return node;
          } else {
            return node;
          }
        });

        return resp;
      }
    },
    [identity, current],
  );

  useEffect(() => {
    dispatch(
      listObjects({
        identity,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, []);

  useEffectOnUpdate(() => {
    dispatch(
      listObjects({
        identity,
        fetch: fetchObjects,
        cause: 'FILTER',
        request: { offset: 0 },
      }),
    );
  }, [fetchObjects]);
  //#endregion

  const renderEmptyView = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
        <EmptyState
          size="large"
          icon="EmptyDocument"
          title={intl.formatMessage({ id: 'storage.browserHeader.spaceEmpty' })}
          testId="empty-folder"
        >
          {intl.formatMessage({ id: 'EMPTY_FOLDER' })}
        </EmptyState>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      <div className={styles.container}>
        <Menu list={menuOptions} selected={menuSelected} />
        <Divider vertical />
        <div>
          <Header data={data} current={current} identity={identity} parent={parent} />
          <div className={styles.list}>
            <Table
              identity={identity}
              header={false}
              RowComponent={TableRow}
              fetchObjects={fetchObjects}
              selectable={false}
              renderEmptyState={renderEmptyView}
              renderFooter={() => <div></div>}
              columns={[]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigateToDoDOC;
