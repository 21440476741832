import { v4 as uuid } from 'uuid';
import { RealtimeObject, RealtimeOpsBuilder } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { TaskData, TaskKeys, Status, Vote, Reply, StatusValue, TaskEditData } from './Task.types';
import { Descendant } from 'slate';

export class Task extends RealtimeObject<TaskData> {
  static get STATUS() {
    return Status;
  }

  static get PROPS() {
    return TaskKeys;
  }

  static getOperationForVote(
    vote: boolean,
    votes: Vote[],
    userId: string,
  ): { op: Realtime.Core.RealtimeOp } {
    const userVoteIndex = votes.findIndex((element) => `${element.user}` === `${userId}`);
    if (userVoteIndex < 0) {
      return {
        op: RealtimeOpsBuilder.listInsert(
          {
            user: userId,
            value: vote ? 1 : 0,
            time: new Date().toISOString(),
          },
          ['v', votes.length],
        ),
      };
    }
    if (votes[userVoteIndex].value === (vote ? 1 : 0)) {
      // same value, remove vote
      return {
        op: RealtimeOpsBuilder.listDelete(votes[userVoteIndex], ['v', userVoteIndex]),
      };
    }
    return {
      op: RealtimeOpsBuilder.objectReplace(votes[userVoteIndex].value, vote ? 1 : 0, [
        'v',
        userVoteIndex,
        'value',
      ]),
    };
  }

  constructor(
    transport: Transport,
    id: Realtime.Core.RealtimeObjectId,
    undoManager?: Realtime.Core.UndoManager,
  ) {
    super(transport, id, 'tasks', undoManager);
  }

  protected selectedData() {
    let data;
    if (this.loadedVersion) {
      data = this.loadedVersion.data ? this.loadedVersion.data : null;
    } else {
      data = this.model.data;
    }

    if (data != null) {
      return JSON.parse(
        JSON.stringify({
          id: this.id,
          ...data,
        }),
      );
    }
    return null;
  }

  get isDeleted() {
    return Boolean(this.get(['t', 'dlt']));
  }

  handleLoad(): void {
    //
  }
  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {}

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    this.emit(
      'UPDATED',
      {
        id: this.id,
        ...this.get(),
      },
      ops,
      source,
    );
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  private getIndexOfReply(replyId: string) {
    const replies: Reply[] = this.get([Task.PROPS.REPLY]);
    if (!replies) {
      return -1;
    }
    return replies.findIndex((reply: Reply) => reply.id === replyId);
  }

  private getIndexOfWatcher(userId: string) {
    const watchers = this.get([Task.PROPS.WATCHERS]);
    if (!watchers) {
      return -1;
    }
    return watchers.findIndex((watcher: string) => watcher === userId);
  }

  async edit(data: TaskEditData) {
    const current = this.get();
    const ops = [];
    if (data.t && data.t.d !== current.t.d) {
      ops.push(RealtimeOpsBuilder.objectReplace(current.t.d, data.t.d, ['t', 'd']));
    }
    if (JSON.stringify(data.d) !== JSON.stringify(current.d)) {
      ops.push(RealtimeOpsBuilder.objectReplace(current.d, data.d, ['d']));
    }
    if (data.asg !== current.asg) {
      ops.push(RealtimeOpsBuilder.objectReplace(current.asg, data.asg, ['asg']));
    }
    if (ops.length) {
      await this.apply(ops);
      return;
    }
  }

  async changeStatus(status: StatusValue) {
    return this.set([Task.PROPS.STATUS], status);
  }

  async changeAssignee(user: string) {
    return this.set([Task.PROPS.ASSIGNEE], user);
  }

  async changeDescription(description: string) {
    return this.set([Task.PROPS.DESCRIPTION], description);
  }

  async reply(content: Descendant[], author: string) {
    const id = uuid();
    const reply: Reply = {
      id,
      c: content,
      u: author,
      t: {
        c: new Date().toISOString(),
      },
      v: [],
    };
    let replies: Reply[] = this.get([Task.PROPS.REPLY]);
    if (!replies) {
      await this.set([Task.PROPS.REPLY], []);
      replies = [];
    }
    return this.listInsert([Task.PROPS.REPLY, replies.length], reply);
  }

  async editReply(replyId: string, newContent: Descendant[]) {
    const replyIndex = this.getIndexOfReply(replyId);
    if (replyIndex < 0) {
      throw new Error(`No task reply found for with id : ${replyId}`);
    }
    return this.set([Task.PROPS.REPLY, replyIndex, 'c'], newContent);
  }

  async voteReply(replyId: string, vote: boolean, userId: string) {
    const replyIndex = this.getIndexOfReply(replyId);
    if (replyIndex < 0) {
      throw new Error(`No task reply found for with id : ${replyId}`);
    }
    const path = [Task.PROPS.REPLY, replyIndex, 'v'];
    const voteOp = Task.getOperationForVote(vote, this.get(path), userId).op;
    return this.apply([
      {
        ...voteOp,
        p: [Task.PROPS.REPLY, replyIndex, ...voteOp.p],
      },
    ]);
  }

  async deleteReply(replyId: string) {
    const replyIndex = this.getIndexOfReply(replyId);
    if (replyIndex < 0) {
      throw new Error(`No task reply found for with id : ${replyId}`);
    }
    const path = [Task.PROPS.REPLY, replyIndex];
    this.apply([RealtimeOpsBuilder.listDelete(this.get(path), [Task.PROPS.REPLY, replyIndex])]);
  }

  async watchTask(userId: string) {
    const watchers = this.get([Task.PROPS.WATCHERS]);
    if (!watchers) {
      await this.set([Task.PROPS.WATCHERS], [userId]);
      return;
    }
    const watcherIndex = this.getIndexOfWatcher(userId);
    if (watcherIndex < 0) {
      await this.listInsert([Task.PROPS.WATCHERS, watchers.length], userId);
      return;
    }
  }

  async removeWatchFromTask(userId: string) {
    const watcherIndex = this.getIndexOfWatcher(userId);
    if (watcherIndex >= 0) {
      this.apply([RealtimeOpsBuilder.listDelete(userId, [Task.PROPS.WATCHERS, watcherIndex])]);
      return;
    }
  }

  async deleteTask() {
    return this.set(['t', 'dlt'], new Date().toISOString());
  }

  async reappend() {
    return this.delete(['t', 'dlt']);
  }
}
