import { ELEMENTS } from 'Editor/services/consts';
import ViewModelValidations from 'Editor/services/VisualizerManager/ViewModels/ViewModelValidations';
import { EditorDOMElements, EditorDOMUtils } from '../../DOM';
import { EditorRange } from '../EditorRange';

export class JsonRange
  implements Editor.Selection.RangeData, Editor.Selection.IAcceptEditorVisitor
{
  start: Editor.Selection.Position;
  end: Editor.Selection.Position;

  static getPositionFromNodeOffset(
    container: Node,
    containerOffset: number,
    ancestorContainer?: Node | null,
  ): Editor.Selection.Position | null {
    if (!ancestorContainer) {
      ancestorContainer = EditorDOMUtils.getContentContainer(container);
    }

    const closestApprovedElement = EditorDOMUtils.closest(container, ELEMENTS.ApprovedElement.TAG);
    if (EditorDOMElements.isApprovedElement(closestApprovedElement)) {
      ancestorContainer = closestApprovedElement.contentContainer;
    }

    let node: Node | null = container;
    let offset: number = containerOffset;

    let closest: Node | null;

    let block: Editor.Visualizer.BaseView | null = null;

    if (node === ancestorContainer) {
      if (containerOffset > 0 && containerOffset <= node.childNodes.length) {
        node = node.childNodes[containerOffset - 1];
        offset = node.childNodes.length;
      } else {
        node = node.childNodes[0];
        offset = 0;
      }
    } else {
      // check if container is a frontend only node
      while (
        (closest = EditorDOMUtils.closest(
          node,
          EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS,
          ancestorContainer,
        ))
      ) {
        if (closest.parentNode) {
          if (EditorDOMUtils.isAtEndOfNode(closest, node, offset)) {
            offset = Array.from(closest.parentNode.childNodes).indexOf(closest as ChildNode) + 1;
          } else {
            offset = Array.from(closest.parentNode.childNodes).indexOf(closest as ChildNode);
          }
          node = closest.parentNode;
        }
      }
    }

    if (
      (node === ancestorContainer || node?.parentNode === ancestorContainer) &&
      node instanceof HTMLElement
    ) {
      block = node;
    } else {
      block = EditorDOMUtils.findFirstLevelChildNode(
        ancestorContainer,
        node,
      ) as Editor.Visualizer.BaseView;
    }

    if (block) {
      let jsonPosition: Editor.Selection.Position = {
        b: block.id,
        p: [],
      };

      if (node != null && node !== block) {
        // push initial offset
        if (node instanceof Text) {
          jsonPosition.p.push(offset);
        } else {
          // adjust offset for sibling frontend only nodes
          const childNodes = node.childNodes;
          let checkOffset = offset;
          for (let i = 0; i < childNodes.length; i++) {
            const child = childNodes[i];
            if (
              child instanceof Element &&
              EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(child.nodeName) &&
              i <= checkOffset &&
              offset > 0
            ) {
              offset -= 1;
            }
          }

          jsonPosition.p.push(offset);
        }

        while (node != null && block.contains(node)) {
          if (node instanceof Text) {
            jsonPosition.p.unshift('content');
          }

          if (node instanceof Element) {
            jsonPosition.p.unshift('childNodes');
          }

          if (node.parentNode && node !== block) {
            const parentChildNodes = node.parentNode.childNodes as NodeListOf<Node>;

            let index = Array.from(parentChildNodes).indexOf(node);
            let checkOffset = index;
            // adjust offset for sibling frontend only nodes
            for (let i = 0; i < parentChildNodes.length; i++) {
              const child = parentChildNodes[i];
              if (
                child instanceof Element &&
                EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(child.nodeName) &&
                i <= checkOffset &&
                index > 0
              ) {
                index -= 1;
              }
            }

            jsonPosition.p.unshift(index);
          }

          node = node.parentNode;
        }
      } else {
        if (offset >= 0 && offset <= block.childNodes.length) {
          let checkOffset = offset;
          // adjust offset for sibling frontend only nodes
          for (let i = 0; i < block.childNodes.length; i++) {
            const child = block.childNodes[i];
            if (
              block.nodeName === ELEMENTS.ParagraphElement.TAG &&
              child instanceof Element &&
              EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(child.nodeName) &&
              i <= checkOffset &&
              offset > 0
            ) {
              offset -= 1;
            }
          }

          jsonPosition.p = ['childNodes', offset];
        }
      }

      // handle view split points
      const viewModel = block.vm;
      if (
        jsonPosition.p.length > 0 &&
        ViewModelValidations.isBlockViewModel(viewModel) &&
        viewModel.hasSplitViews()
      ) {
        let transformedPath = viewModel.transformPathWithSplitPoints(block, jsonPosition.p, false);
        if (transformedPath) {
          jsonPosition.p = transformedPath;
        }
      }

      return jsonPosition;
    }

    return null;
  }

  static getNodeOffsetFromPosition(position: Editor.Selection.Position) {
    const blockNode = document.getElementById(position.b) as Editor.Visualizer.BaseView;

    if (ViewModelValidations.isBlockViewModel(blockNode?.vm)) {
      const splitViews = blockNode.vm.splitViews;
      if (splitViews.length) {
        let data;
        let path: Editor.Selection.Path | null = position.p;
        for (let i = 0; i < splitViews.length; i++) {
          path = JsonRange.transformPath(path, splitViews[i].splitPoint);
          if (path) {
            data = JsonRange.getNodeOffsetFromViewPath(splitViews[i].view, path);
          }

          if (data && data.node != null && data.offset != null) {
            return data;
          }
        }
      } else {
        return JsonRange.getNodeOffsetFromViewPath(blockNode, position.p);
      }
    }

    return {};
  }

  static getNodeOffsetFromViewPath(view: Editor.Visualizer.BaseView, path: Editor.Selection.Path) {
    if (path) {
      let node: Node | null = view;
      let offset: number | null = null;

      if (node) {
        let lastKey: string | number | null = null;

        for (let i = 0; i < path.length; i++) {
          const key = path[i];

          if ((lastKey === 'childNodes' || lastKey === 'content') && !isNaN(+key)) {
            if (lastKey === 'childNodes' && node instanceof Element) {
              if (+key <= node.childNodes.length) {
                let childNodes: NodeListOf<ChildNode> = node.childNodes;

                offset = +key;

                // check if it is last element
                if (
                  (EditorDOMElements.BLOCK_NON_EDITABLE_ELEMENTS.includes(view.nodeName) &&
                    i !== path.length - 1) ||
                  !EditorDOMElements.BLOCK_NON_EDITABLE_ELEMENTS.includes(view.nodeName)
                ) {
                  // adjust offset for frontend only elements
                  for (let j = 0; j < childNodes.length; j++) {
                    const element = childNodes[j] as Node;
                    if (
                      EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(element.nodeName) &&
                      element.parentNode
                    ) {
                      const index = Array.from(element.parentNode.childNodes).indexOf(
                        element as ChildNode,
                      );

                      if (offset < childNodes.length && index <= offset) {
                        offset += 1;
                      }
                    }
                  }
                }

                if (childNodes[offset] != null) {
                  node = childNodes[offset];
                  offset = 0;
                }
              } else {
                offset = null;
                break;
              }
            } else if (lastKey === 'content' && node instanceof Text) {
              if (+key <= node.length) {
                offset = +key;
              } else {
                offset = null;
                break;
              }
            }
          }

          if (key === 'childNodes' || key === 'content') {
            lastKey = key;
          } else {
            lastKey = null;
          }
        }

        return { node, offset };
      }
    }

    return {};
  }

  static buildFromDOMRange(range: Range): JsonRange {
    let start: Editor.Selection.Position | null = JsonRange.getPositionFromNodeOffset(
      range.startContainer,
      range.startOffset,
    );

    let end: Editor.Selection.Position | null = JsonRange.getPositionFromNodeOffset(
      range.endContainer,
      range.endOffset,
    );

    if (start != null && end != null) {
      return new JsonRange(start, end);
    } else {
      throw new Error('Invalid range!');
    }
  }

  static buildFromRangeData(range: Editor.Selection.RangeData): JsonRange {
    return new JsonRange(range.start, range.end);
  }

  constructor(start: Editor.Selection.Position, end?: Editor.Selection.Position | null) {
    this.start = start;
    this.end = end || start;
  }

  accept(visitor: Editor.Selection.Range.IEditorRangeVisitor) {
    visitor.visitJsonRange(this);
  }

  get collapsed() {
    return this.isCollapsed();
  }

  serializeStartToNodeOffset() {
    return JsonRange.getNodeOffsetFromPosition(this.start);
  }

  serializeEndToNodeOffset() {
    return JsonRange.getNodeOffsetFromPosition(this.end);
  }

  isCollapsed(): boolean {
    // check path
    if (this.start.p.length === this.end.p.length) {
      for (let i = 0; i < this.start.p.length; i++) {
        // eslint-disable-next-line eqeqeq
        if (this.start.p[i] != this.end.p[i]) {
          return false;
        }
      }
      return true;
    }

    return false;
  }

  collapse(toStart?: boolean) {
    if (toStart) {
      this.end = JSON.parse(JSON.stringify(this.start));
    } else {
      this.start = JSON.parse(JSON.stringify(this.end));
    }
  }

  collapseToStart() {
    this.collapse(true);
  }

  collapseToEnd() {
    this.collapse(false);
  }

  updateFromDOMRange(range: Range) {
    let start: Editor.Selection.Position | null = JsonRange.getPositionFromNodeOffset(
      range.startContainer,
      range.startOffset,
    );

    let end: Editor.Selection.Position | null = JsonRange.getPositionFromNodeOffset(
      range.endContainer,
      range.endOffset,
    );

    if (start != null && end != null) {
      this.start = start;
      this.end = end;
    } else {
      throw new Error('Invalid range!');
    }
  }

  serializeToDOMRange(): Editor.Selection.EditorRange {
    const range = new EditorRange();

    let start = JsonRange.getNodeOffsetFromPosition(this.start);
    let end = JsonRange.getNodeOffsetFromPosition(this.end);

    if (start.node != null && start.offset != null && end.node != null && end.offset != null) {
      range.setStart(start.node, start.offset);
      range.setEnd(start.node, start.offset);

      if (range.comparePoint(end.node, end.offset) >= 0) {
        range.setEnd(end.node, end.offset);
      } else {
        range.setStart(end.node, end.offset);
      }
    } else {
      throw new Error('Node not found!');
    }

    return range;
  }

  serializeToRangeData(): Editor.Selection.RangeData {
    return {
      start: JSON.parse(JSON.stringify(this.start)),
      end: JSON.parse(JSON.stringify(this.end)),
      collapsed: this.collapsed,
    };
  }

  static transformPath(
    basePath: Editor.Selection.Path | null,
    transformPath: Editor.Selection.Path | null,
    subtract: boolean = true,
  ): Editor.Selection.Path | null {
    let resultPath: Editor.Selection.Path | null = [];

    if (basePath && basePath?.length >= 0 && transformPath && transformPath.length >= 0) {
      if (transformPath.length === 0) {
        resultPath = basePath;
      } else {
        let previousIteration: string | null = null;
        let preivousP1Index: number | null = null;
        let keepTransforming = true;

        const length =
          basePath.length > transformPath.length ? basePath.length : transformPath.length;

        for (let i = 0; i < length; i++) {
          const p1 = basePath[i];
          const p2 = transformPath[i];

          if (p1 != null && p2 != null) {
            if (
              (p1 === 'childNodes' && p2 === 'childNodes') ||
              (p1 === 'content' && p2 === 'content')
            ) {
              previousIteration = p1 as string;
              resultPath.push(p1);
            } else if (previousIteration != null) {
              if (keepTransforming) {
                let index = -1;
                if (subtract) {
                  index = +p1 - +p2;
                  if (index > 0) {
                    keepTransforming = false;
                  }
                } else {
                  index = +p1 + +p2;
                  if (+p1 > 0) {
                    keepTransforming = false;
                  }
                }

                previousIteration = null;
                if (index >= 0) {
                  preivousP1Index = +p1;
                  resultPath.push(index);
                } else {
                  // invalid transform
                  resultPath = null;
                  break;
                }
              } else {
                resultPath.push(p1);
              }
            } else {
              resultPath.push(p1);
            }
          } else if (p1 != null && p2 == null) {
            resultPath.push(p1);
            previousIteration = null;
          } else if (p1 == null && p2 != null && preivousP1Index === 0) {
            resultPath.push(p2);
            previousIteration = null;
          }
        }
      }
    }

    return resultPath;
  }

  static comparePath(path1: Editor.Selection.Path, path2: Editor.Selection.Path): number {
    if (path1?.length >= 0 && path2?.length >= 0) {
      let length: number = 0;
      if (path1.length >= path2.length) {
        length = path1.length;
      } else {
        length = path2.length;
      }

      let previousIteration: string | null = null;

      for (let i = 0; i < length; i++) {
        if (path1[i] != null && path2[i] != null) {
          if (
            (path1[i] === 'childNodes' && path2[i] === 'childNodes') ||
            (path1[i] === 'content' && path2[i] === 'content')
          ) {
            previousIteration = path1[i] as string;
          } else if (previousIteration != null) {
            const index1 = +path1[i];
            const index2 = +path2[i];

            if (index1 < index2) {
              // path1 before path2
              return -1;
            } else if (index1 > index2) {
              // path1 after path2
              return 1;
            }

            previousIteration = null;
          } else {
            throw new Error('Invalid path to compare!');
          }
        } else if (path1[i] == null && path2[i] == null) {
          // paths should be equal
          return 0;
        } else if (path1[i] == null) {
          // path1 before path2
          return -1;
        } else if (path2[i] == null) {
          // path1 after path2
          return 1;
        }
      }
    } else {
      throw new Error('Invalid path to compare!');
    }

    return 0;
  }

  compare(documentNodes: string[], jsonRangeToCompare: JsonRange): number {
    if (documentNodes && documentNodes.length > 0 && jsonRangeToCompare) {
      const thisStartIndex = documentNodes.indexOf(this.start.b);
      const thisEndIndex = documentNodes.indexOf(this.end.b);

      const otherStartIndex = documentNodes.indexOf(jsonRangeToCompare.start.b);
      const otherEndIndex = documentNodes.indexOf(jsonRangeToCompare.end.b);

      if (thisStartIndex >= 0 && thisEndIndex >= 0 && otherStartIndex >= 0 && otherEndIndex >= 0) {
        if (thisStartIndex < otherStartIndex && thisEndIndex < otherEndIndex) {
          // this range is before other range
          return -1;
        } else if (thisStartIndex > otherStartIndex && thisEndIndex > otherEndIndex) {
          // this range is after other range
          return 1;
        } else if (thisStartIndex < otherStartIndex && thisEndIndex > otherEndIndex) {
          // this range contains other range
          return 0;
        } else if (thisStartIndex > otherStartIndex && thisEndIndex < otherEndIndex) {
          // this range is contained by other range
          return 0;
        } else if (thisStartIndex === otherStartIndex && thisEndIndex > otherEndIndex) {
          // this range contains other range with match start
          const result = JsonRange.comparePath(this.start.p, jsonRangeToCompare.start.p);
          return result > 0 ? result : 0;
        } else if (thisStartIndex === otherStartIndex && thisEndIndex < otherEndIndex) {
          // this range is contained by other range with match start
          const result = JsonRange.comparePath(this.start.p, jsonRangeToCompare.start.p);
          return result < 0 ? result : 0;
        } else if (thisStartIndex < otherStartIndex && thisEndIndex === otherEndIndex) {
          // this range contains other range with match end
          const result = JsonRange.comparePath(this.end.p, jsonRangeToCompare.end.p);
          return result < 0 ? result : 0;
        } else if (thisStartIndex > otherStartIndex && thisEndIndex === otherEndIndex) {
          // this range is contained by other range with match end
          const result = JsonRange.comparePath(this.end.p, jsonRangeToCompare.end.p);
          return result > 0 ? result : 0;
        } else if (thisStartIndex === otherStartIndex && thisEndIndex === otherEndIndex) {
          // ranges are within the same block
          const resultStart = JsonRange.comparePath(this.start.p, jsonRangeToCompare.start.p);
          const resultEnd = JsonRange.comparePath(this.end.p, jsonRangeToCompare.end.p);

          if (resultStart < 0 && resultEnd < 0) {
            // this range is before other range
            return -1;
          } else if (resultStart > 0 && resultEnd > 0) {
            // this range is after other range
            return 1;
          } else if ((resultStart <= 0 && resultEnd >= 0) || (resultStart >= 0 && resultEnd <= 0)) {
            // ranges are contained within each other or are equal
            return 0;
          }
        }
      }
    }

    throw new Error('Invalid ranges to compare!');
  }

  static containsPath(path1: Editor.Selection.Path, path2: Editor.Selection.Path) {
    let includes: boolean = false;
    if (path1?.length > 1 && path2?.length > 1) {
      for (let i = 0; i < path1.length; i++) {
        if (path1[i] != null && path2[i] != null) {
          if (path1[i] === path2[i]) {
            includes = true;
          } else {
            includes = false;
            break;
          }
        } else {
          includes = false;
          break;
        }
      }
    }

    return includes;
  }

  static getCommonAncestorPath(path1: Editor.Selection.Path, path2: Editor.Selection.Path) {
    let commonPath = [];

    let i = 0;
    let lastKey = null;
    // eslint-disable-next-line eqeqeq
    while (path1[i] != null && path1[i] == path2[i]) {
      if ((path1[i] === 'childNodes' || path1[i] === 'content') && isNaN(+path1[i])) {
        lastKey = path1[i];
      } else if (lastKey != null) {
        commonPath.push(lastKey);
        commonPath.push(path1[i]);

        lastKey = null;
      }

      i++;
    }

    return commonPath;
  }
}
