import { ELEMENTS } from 'Editor/services/consts';
import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import { DOMUtils } from '_common/utils';
import { EditorDOMElements } from './EditorDOMElements';

export class EditorDOMUtils extends DOMUtils {
  static appendNode(parent: Element, node: Node, normalize = true) {
    if (normalize) {
      DOMNormalizer.normalizeTree(node, parent.id);
    }
    parent.appendChild(node);
  }

  static replaceNode(parent: Element, newNode: Node, node: Node, normalize = true) {
    // TODO: check old node permissions (copy id)
    if (normalize) {
      DOMNormalizer.normalizeTree(newNode, parent.id);
    }
    parent.replaceChild(newNode, node);
  }

  static insertNodeBefore(parent: Element, node: Node, before: Node, normalize = true) {
    if (normalize) {
      DOMNormalizer.normalizeTree(node, parent.id);
    }
    parent.insertBefore(node, before);
  }

  static insertNodeAfter(parent: Element, node: Node, after: Node, normalize = true) {
    if (parent) {
      if (after && after.nextSibling) {
        EditorDOMUtils.insertNodeBefore(parent, node, after.nextSibling, normalize);
      } else {
        EditorDOMUtils.appendNode(parent, node, normalize);
      }
    }
  }

  static getContentContainer(node?: Node): Node | null {
    if (node) {
      const closestSection = EditorDOMUtils.closest(node, ELEMENTS.SectionElement.TAG);
      if (closestSection) {
        return closestSection;
      }
    }

    return document.querySelector('[ispagenode="true"]');
  }

  static isClosestTextElementEditable(node: Node | null) {
    if (node) {
      const container = EditorDOMUtils.getContentContainer(node);
      let closestBlock = EditorDOMUtils.closest(node, EditorDOMElements.BLOCK_TEXT_ELEMENTS);
      if (container && closestBlock) {
        if (container.contains(closestBlock) && closestBlock.parentNode !== container) {
          closestBlock = EditorDOMUtils.findFirstLevelChildNode(container, closestBlock);
        }

        if (EditorDOMElements.isSupportedElement(closestBlock) && closestBlock.isEditable) {
          return true;
        }
      }
    }
    return false;
  }

  static isClosestBlockNodeEditable(node: Node | null) {
    if (node) {
      const container = EditorDOMUtils.getContentContainer(node);
      let closest = EditorDOMUtils.closest(node, EditorDOMElements.EDITABLE_LEVEL0_ELEMENTS);
      if (container && closest) {
        if (container.contains(closest) && closest.parentNode !== container) {
          closest = EditorDOMUtils.findFirstLevelChildNode(container, closest);
        }

        if (EditorDOMElements.isSupportedElement(closest) && closest.isEditable) {
          return true;
        }
      }
    }
    return false;
  }

  static closestContainerElement(node: Node | null) {
    let closesContainer;
    let nodeToCheck = node;
    while (
      (closesContainer = EditorDOMUtils.closest(
        nodeToCheck,
        EditorDOMElements.BLOCK_CONTAINER_ELEMENTS,
      ))
    ) {
      if (
        EditorDOMElements.isSupportedElement(closesContainer) &&
        closesContainer.isContainerElement
      ) {
        return closesContainer;
      }
    }
    return null;
  }

  static closestMultiBlockContainerElement(node: Node | null) {
    let closesContainer;
    let nodeToCheck = node;
    while (
      (closesContainer = EditorDOMUtils.closest(
        nodeToCheck,
        EditorDOMElements.MULTI_BLOCK_CONTAINER_ELEMENTS,
      ))
    ) {
      if (
        EditorDOMElements.isSupportedElement(closesContainer) &&
        closesContainer.isContainerElement
      ) {
        return closesContainer;
      }
      nodeToCheck = closesContainer.parentNode;
    }
    return null;
  }

  static getPreviousSelectableElement(
    blockNode: Node | null,
    selectedElement: Node | null,
    selectedNode: Node | null,
    tableSelectionOption: 'row' | 'cell' = 'cell',
  ): Node | null {
    let selectableContent: Node | null = null;

    if (blockNode) {
      let closestSection = EditorDOMUtils.closest(
        blockNode,
        ELEMENTS.SectionElement.TAG,
      ) as Editor.Elements.SectionElement;
      let closestPage = EditorDOMUtils.closest(
        blockNode,
        ELEMENTS.PageElement.TAG,
      ) as Editor.Elements.PageElement;

      let previousSibling: Node | null = blockNode.previousSibling;
      if (EditorDOMElements.isTableElement(blockNode)) {
        const closestCell = EditorDOMUtils.closest(selectedElement, [
          ELEMENTS.TableCellElement.TAG,
        ]);
        if (closestCell) {
          if (EditorDOMElements.isTableCellElement(closestCell)) {
            // WARN:
            // remove cell selection temporary
            // check before commit a6dfbbd
            selectableContent = EditorDOMUtils.getPreviousSelectableElement(
              EditorDOMUtils.findFirstLevelChildNode(closestCell, selectedNode),
              selectedElement,
              selectedNode,
              tableSelectionOption,
            );

            if (!selectableContent) {
              if (tableSelectionOption === 'cell') {
                // check next cell
                if (closestCell.previousSibling) {
                  previousSibling = closestCell.previousSibling.lastChild;
                } else {
                  const row = closestCell.parentNode;

                  if (row?.previousSibling) {
                    let nextCell =
                      row.previousSibling.childNodes[row.previousSibling.childNodes.length];

                    if (EditorDOMElements.isTableCellElement(nextCell)) {
                      const headCellId = nextCell.getHeadCellId();
                      if (headCellId) {
                        nextCell = document.getElementById(headCellId) as ChildNode;
                      }
                    }
                    if (nextCell) {
                      previousSibling = nextCell.lastChild;
                    }
                  }
                }
              } else {
                // check previous row
                const row = closestCell.parentNode;

                if (row?.previousSibling) {
                  let previousCell = row.previousSibling.childNodes[closestCell.cellIndex];

                  if (EditorDOMElements.isTableCellElement(previousCell)) {
                    const headCellId = previousCell.getHeadCellId();
                    if (headCellId) {
                      previousCell = document.getElementById(headCellId) as ChildNode;
                    }
                  }

                  if (previousCell) {
                    previousSibling = previousCell.lastChild;
                  }
                }
              }
            }
          }
        }
      } else if (EditorDOMElements.isFigureElement(blockNode)) {
        const closestElement = EditorDOMUtils.closest(selectedElement, ['FIGCAPTION']);
        if (closestElement) {
          selectableContent = closestElement.previousSibling;
        }
      } else if (EditorDOMElements.isNodeBlockWrapperElement(blockNode)) {
        // aprove and readonlys
        selectableContent = EditorDOMUtils.getPreviousSelectableElement(
          blockNode.selectableContent,
          selectedElement,
          selectedNode,
          tableSelectionOption,
        );
      } else if (EditorDOMElements.isNodeContainerElement(blockNode)) {
        // check container elements
        selectableContent = EditorDOMUtils.getPreviousSelectableElement(
          EditorDOMUtils.findFirstLevelChildNode(blockNode, selectedNode),
          selectedElement,
          selectedNode,
          tableSelectionOption,
        );
      }

      if (
        previousSibling == null &&
        closestSection &&
        blockNode === closestSection.firstElementChild
      ) {
        if (EditorDOMElements.isSectionElement(closestSection.previousElementSibling)) {
          previousSibling = closestSection.previousElementSibling.lastElementChild;
        } else if (
          closestPage &&
          EditorDOMElements.isPageElement(closestPage.previousElementSibling)
        ) {
          const sectionElement = closestPage.previousElementSibling.lastSectionContainer;
          if (sectionElement) {
            previousSibling = sectionElement.lastElementChild;
          }
        }
      }

      if (!selectableContent && previousSibling) {
        if (EditorDOMUtils.isClosestTextElementEditable(previousSibling)) {
          selectableContent = previousSibling;
        } else if (EditorDOMElements.isNodeBlockWrapperElement(previousSibling)) {
          selectableContent = previousSibling.selectableContent;
        } else if (
          !EditorDOMUtils.isClosestBlockNodeEditable(previousSibling) &&
          EditorDOMElements.isSupportedBlockElement(previousSibling)
        ) {
          selectableContent = previousSibling.selectableContent;
        } else if (EditorDOMElements.isFigureElement(previousSibling)) {
          selectableContent = previousSibling.lastChild;
        } else if (EditorDOMElements.isTableElement(previousSibling)) {
          if (tableSelectionOption === 'cell') {
            const nRows = previousSibling.tBodies[0].rows.length;
            const nCells = previousSibling.tBodies[0].rows[nRows - 1].cells.length;
            let cell = previousSibling.tBodies[0].rows[nRows - 1].cells[
              nCells - 1
            ] as Editor.Elements.TableCellElement;
            const headCellId = cell.getHeadCellId();
            if (headCellId) {
              cell = document.getElementById(headCellId) as Editor.Elements.TableCellElement;
              selectableContent = cell?.lastChild;
            } else {
              selectableContent = cell?.lastChild;
            }
          } else if (tableSelectionOption === 'row') {
            const nRows = previousSibling.tBodies[0].rows.length;
            selectableContent = previousSibling.tBodies[0].rows[nRows - 1];
          } else {
            selectableContent = previousSibling;
          }
        } else if (EditorDOMElements.isNodeContainerElement(previousSibling)) {
          selectableContent = previousSibling.lastChild;
        }
      }

      // check for hidden content
      if (selectableContent instanceof Element) {
        const computedStyles = getComputedStyle(selectableContent);

        if (computedStyles.display === 'none' || computedStyles.height === '0px') {
          selectableContent = EditorDOMUtils.getPreviousSelectableElement(
            selectableContent,
            selectableContent,
            selectableContent,
            tableSelectionOption,
          );
        }
      }
    }

    return selectableContent;
  }

  static getNextSelectableElement(
    blockNode: Node | null,
    selectedElement: Node | null,
    selectedNode: Node | null,
    tableSelectionOption: 'row' | 'cell' = 'cell',
  ): Node | null {
    let selectableContent: Node | null = null;

    if (blockNode) {
      let closestSection = EditorDOMUtils.closest(
        blockNode,
        ELEMENTS.SectionElement.TAG,
      ) as Editor.Elements.SectionElement;
      let closestPage = EditorDOMUtils.closest(
        blockNode,
        ELEMENTS.PageElement.TAG,
      ) as Editor.Elements.PageElement;

      let nextSibling = blockNode.nextSibling;
      if (EditorDOMElements.isTableElement(blockNode)) {
        const closestCell = EditorDOMUtils.closest(selectedElement, [
          ELEMENTS.TableCellElement.TAG,
        ]);

        if (EditorDOMElements.isTableCellElement(closestCell)) {
          // WARN:
          // remove cell selection temporary
          // check before commit a6dfbbd
          selectableContent = EditorDOMUtils.getNextSelectableElement(
            EditorDOMUtils.findFirstLevelChildNode(closestCell, selectedNode),
            selectedElement,
            selectedNode,
            tableSelectionOption,
          );
          if (!selectableContent) {
            if (tableSelectionOption === 'cell') {
              // check next cell
              if (closestCell.nextSibling) {
                nextSibling = closestCell.nextSibling.firstChild;
              } else {
                const row = closestCell.parentNode;

                if (row?.nextSibling) {
                  let nextCell = row.nextSibling.childNodes[0];

                  if (EditorDOMElements.isTableCellElement(nextCell)) {
                    const headCellId = nextCell.getHeadCellId();
                    if (headCellId) {
                      const headCell = document.getElementById(
                        headCellId,
                      ) as Editor.Elements.TableCellElement;
                      nextCell = row.nextSibling.childNodes[headCell.colSpan];
                    }
                  }
                  if (nextCell) {
                    nextSibling = nextCell.lastChild;
                  }
                }
              }
            } else {
              // check next row
              const row = closestCell.parentNode;

              if (row?.nextSibling) {
                let nextCell = row.nextSibling.childNodes[closestCell.cellIndex];

                if (EditorDOMElements.isTableCellElement(nextCell)) {
                  const headCellId = nextCell.getHeadCellId();
                  if (headCellId) {
                    const headCell = document.getElementById(
                      headCellId,
                    ) as Editor.Elements.TableCellElement;
                    nextCell = row.nextSibling.childNodes[closestCell.cellIndex + headCell.rowSpan];
                  }
                }

                if (nextCell) {
                  nextSibling = nextCell.firstChild;
                }
              }
            }
          }
        }
      } else if (EditorDOMElements.isFigureElement(blockNode)) {
        // selectableContent = EditorDOMUtils.findNextElementSibling(selectedElement);
        const closestElement = EditorDOMUtils.closest(selectedElement, ['IMAGE-ELEMENT']);
        if (closestElement) {
          selectableContent = closestElement.nextSibling;
        }
      } else if (EditorDOMElements.isNodeBlockWrapperElement(blockNode)) {
        // aprove and readonlys
        selectableContent = EditorDOMUtils.getNextSelectableElement(
          blockNode.selectableContent,
          selectedElement,
          selectedNode,
          tableSelectionOption,
        );
      } else if (EditorDOMElements.isNodeContainerElement(blockNode)) {
        // check tracked elements
        selectableContent = EditorDOMUtils.getNextSelectableElement(
          EditorDOMUtils.findFirstLevelChildNode(blockNode, selectedNode),
          selectedElement,
          selectedNode,
          tableSelectionOption,
        );
      }

      if (nextSibling == null && closestSection && closestSection.lastElementChild === blockNode) {
        if (EditorDOMElements.isSectionElement(closestSection.nextElementSibling)) {
          nextSibling = closestSection.nextElementSibling.firstChild;
        } else if (EditorDOMElements.isPageElement(closestPage.nextElementSibling)) {
          const sectionElement = closestPage.nextElementSibling.sectionContainerAt(0);
          if (sectionElement) {
            nextSibling = sectionElement.firstChild;
          }
        }
      }

      if (!selectableContent && nextSibling) {
        if (EditorDOMUtils.isClosestTextElementEditable(nextSibling)) {
          selectableContent = nextSibling;
        } else if (EditorDOMElements.isNodeBlockWrapperElement(nextSibling)) {
          selectableContent = nextSibling.selectableContent;
        } else if (
          !EditorDOMUtils.isClosestBlockNodeEditable(nextSibling) &&
          EditorDOMElements.isSupportedBlockElement(nextSibling)
        ) {
          selectableContent = nextSibling.selectableContent;
        } else if (EditorDOMElements.isFigureElement(nextSibling)) {
          selectableContent = nextSibling.firstChild;
        } else if (EditorDOMElements.isTableElement(nextSibling)) {
          if (tableSelectionOption === 'cell') {
            selectableContent = nextSibling.tBodies[0].rows[0].cells[0].firstChild;
          } else if (tableSelectionOption === 'row') {
            selectableContent = nextSibling.tBodies[0].rows[0];
          } else {
            selectableContent = nextSibling;
          }
        } else if (EditorDOMElements.isNodeContainerElement(nextSibling)) {
          selectableContent = nextSibling.firstChild;
        }
      }

      // check for hidden content
      if (selectableContent instanceof Element) {
        const computedStyles = getComputedStyle(selectableContent);

        if (computedStyles.display === 'none' || computedStyles.height === '0px') {
          selectableContent = EditorDOMUtils.getNextSelectableElement(
            selectableContent,
            selectableContent,
            selectableContent,
            tableSelectionOption,
          );
        }
      }
    }

    return selectableContent;
  }

  static getSelectableElementFromBlock(
    blockNode: Node | null,
    selectedNode: Node | null,
  ): Node | null {
    let selectableElement: Node | null = null;
    if (blockNode && selectedNode) {
      if (EditorDOMElements.isNodeContainerElement(blockNode)) {
        selectableElement = EditorDOMUtils.getSelectableElementFromBlock(
          EditorDOMUtils.findFirstLevelChildNode(blockNode, selectedNode),
          selectedNode,
        );
      } else if (EditorDOMElements.isTableElement(blockNode)) {
        if (EditorDOMElements.isTableCellElement(selectedNode)) {
          selectableElement = selectedNode;
        } else {
          const closest = EditorDOMUtils.closest(selectedNode, [ELEMENTS.TableCellElement.TAG]);
          if (closest) {
            if (EditorDOMElements.isTableCellElement(closest)) {
              selectableElement = EditorDOMUtils.getSelectableElementFromBlock(
                EditorDOMUtils.findFirstLevelChildNode(closest, selectedNode),
                selectedNode,
              );
            }
          } else {
            selectableElement = blockNode;
          }
        }
      } else if (EditorDOMElements.isFigureElement(blockNode)) {
        selectableElement = EditorDOMUtils.closest(selectedNode, ['IMAGE-ELEMENT', 'FIGCAPTION']);
      } else if (EditorDOMElements.isSupportedBlockElement(blockNode)) {
        selectableElement = blockNode.selectableContent;
      }
    }
    return selectableElement;
  }

  static scrollIntoXY(px: number, py: number) {
    const editorRoot = document.getElementById('EditorRoot');
    if (editorRoot) {
      const editorRootBoundingRect = editorRoot.getBoundingClientRect();

      const viewMargin = 50;

      let xScroll = 0;
      let yScroll = 0;

      if (py != null) {
        if (py <= editorRootBoundingRect.top + viewMargin) {
          yScroll = py - editorRootBoundingRect.top - viewMargin;
        } else if (py >= editorRootBoundingRect.bottom - viewMargin) {
          yScroll = py - editorRootBoundingRect.bottom + viewMargin;
        }

        editorRoot.scrollTop += yScroll;
      }

      if (px != null) {
        if (px <= editorRootBoundingRect.left + viewMargin) {
          xScroll = px - editorRootBoundingRect.left - viewMargin;
        } else if (px >= editorRootBoundingRect.right - viewMargin) {
          xScroll = px - editorRootBoundingRect.right + viewMargin;
        }

        editorRoot.scrollLeft += xScroll;
      }

      return { x: (px -= xScroll), y: (py -= yScroll) };
    }

    return {};
  }

  static getCaretOffsetFromPoint(x?: number, y?: number) {
    let range;
    let textNode;
    let offset;

    if (typeof x === 'number' && typeof y === 'number') {
      if (document.caretRangeFromPoint) {
        // for chrome and others
        range = document.caretRangeFromPoint(x, y);
        if (range) {
          textNode = range.startContainer;
          offset = range.startOffset;
        }
      }
      //@ts-ignore
      else if (document.caretPositionFromPoint) {
        // for firefox
        //@ts-ignore
        range = document.caretPositionFromPoint(x, y);
        if (range) {
          textNode = range.offsetNode;
          offset = range.offset;
        }
      }
    }

    return { node: textNode, offset };
  }

  static findPreviousAncestorSibling(thisNode: Node, parentNode: Node | null) {
    let previousSibling = null;

    if (!parentNode) {
      parentNode = EditorDOMUtils.getContentContainer(thisNode);
    }

    if (thisNode && EditorDOMUtils.parentContainsNode(parentNode, thisNode)) {
      let node = thisNode;
      previousSibling = node.previousSibling as Node;

      while (
        previousSibling == null &&
        EditorDOMUtils.parentContainsNode(parentNode, node.parentNode)
      ) {
        node = node.parentNode as Node;
        previousSibling = node.previousSibling as Node;
      }
    }

    return previousSibling;
  }

  static findNextAncestorSibling(thisNode: Node, parentNode: Node | null) {
    let nextSibling = null;

    if (!parentNode) {
      parentNode = EditorDOMUtils.getContentContainer(thisNode);
    }

    if (thisNode && EditorDOMUtils.parentContainsNode(parentNode, thisNode)) {
      let node = thisNode;
      nextSibling = node.nextSibling as Node;

      while (
        nextSibling == null &&
        EditorDOMUtils.parentContainsNode(parentNode, node.parentNode)
      ) {
        node = node.parentNode as Node;
        nextSibling = node.nextSibling as Node;
      }
    }

    return nextSibling;
  }

  static findPreviousElementSibling(thisNode: Node, parentNode: Node | null) {
    let previousSibling = null;

    if (!parentNode) {
      parentNode = EditorDOMUtils.getContentContainer(thisNode);
    }

    if (thisNode instanceof Element) {
      let node = thisNode;
      previousSibling = node.previousElementSibling;

      while (
        previousSibling === null &&
        EditorDOMUtils.parentContainsNode(parentNode, node.parentNode)
      ) {
        node = node.parentNode as Element;
        previousSibling = node.previousElementSibling;
      }
    }

    return previousSibling;
  }

  static findNextElementSibling(thisNode: Node, parentNode: Node | null) {
    let nextSibling = null;
    if (!parentNode) {
      parentNode = EditorDOMUtils.getContentContainer(thisNode);
    }

    if (thisNode instanceof Element) {
      let node = thisNode;
      nextSibling = node.nextElementSibling;

      while (
        nextSibling === null &&
        EditorDOMUtils.parentContainsNode(parentNode, node.parentNode)
      ) {
        node = node.parentNode as Element;
        nextSibling = node.nextElementSibling;
      }
    }

    return nextSibling;
  }

  static isNodeNonSelectable(node: Node): node is HTMLElement {
    if (EditorDOMUtils.closest(node, EditorDOMElements.INLINE_NON_SELECTABLE_ELEMENTS)) {
      return true;
    }

    return false;
  }

  static isEmptyElement(node: Node | null) {
    if (!node) {
      return true;
    }
    let hasImages = false;
    if (node instanceof Element) {
      hasImages = !!node.querySelector('img') || node.nodeName === 'IMG';
    }
    return (
      (node.textContent === '' ||
        node.textContent === '\u00B6' ||
        node.textContent === '\uFEFF' ||
        node.textContent === '\u202F' ||
        node.textContent === '\u200B' ||
        EditorDOMElements.isNodeSuggestionParagraphMarker(node) ||
        EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(node.nodeName)) &&
      !EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS.includes(node.nodeName) &&
      !hasImages
    );
  }

  static isAtStartOfNode(parent: Node, anchor: Node, offset: number): boolean {
    if (
      anchor &&
      parent.parentNode &&
      EditorDOMUtils.parentContainsNode(parent.parentNode, anchor)
    ) {
      if (
        anchor !== parent &&
        (offset === 0 ||
          anchor.textContent === '' ||
          anchor.textContent === '\u00B6' ||
          anchor.textContent === '\uFEFF' ||
          anchor.textContent === '\u202F' ||
          anchor.textContent === '\u200B')
      ) {
        while (anchor !== parent) {
          if (anchor instanceof HTMLTableCellElement) {
            let row = anchor.parentElement as HTMLTableRowElement;
            if (anchor.cellIndex === 0 && row.sectionRowIndex === 0) {
              return true;
            } else {
              return false;
            }
          }
          if (anchor !== anchor?.parentNode?.firstChild) {
            let previousSibling = anchor?.previousSibling;
            while (previousSibling) {
              if (!EditorDOMUtils.isEmptyElement(previousSibling)) {
                return false;
              }
              previousSibling = previousSibling.previousSibling;
            }
          }
          if (anchor.parentNode) {
            anchor = anchor?.parentNode;
          }
        }
        return true;
      } else if (anchor === parent) {
        if (offset === 0) {
          return true;
        } else {
          // check previous elements
          let isAtStart = false;
          for (let i = offset - 1; i >= 0; i--) {
            if (parent.childNodes[i]) {
              if (EditorDOMUtils.isEmptyElement(parent.childNodes[i])) {
                isAtStart = true;
              } else {
                isAtStart = false;
                break;
              }
            }
          }
          return isAtStart;
        }
      }
    }
    return false;
  }

  static isAtEndOfNode(parent: Node, anchor: Node, offset: number): boolean {
    if (
      anchor &&
      parent.parentNode &&
      EditorDOMUtils.parentContainsNode(parent.parentNode, anchor)
    ) {
      if (
        parent !== anchor &&
        ((anchor instanceof Text && anchor.length === offset) ||
          (anchor instanceof Element && anchor.childNodes.length === offset) ||
          anchor.textContent === '' ||
          anchor.textContent === '\u00B6' ||
          anchor.textContent === '\uFEFF' ||
          anchor.textContent === '\u202F' ||
          anchor.textContent === '\u200B')
      ) {
        while (parent !== anchor) {
          if (anchor instanceof HTMLTableCellElement) {
            let row = anchor.parentElement as HTMLTableRowElement;
            let tbody = row.parentElement as HTMLTableSectionElement;
            if (
              anchor.cellIndex === row.cells.length - 1 &&
              row.sectionRowIndex === tbody.rows.length - 1
            ) {
              return true;
            } else {
              return false;
            }
          }
          if (anchor !== anchor?.parentNode?.lastChild) {
            let nextSibling = anchor?.nextSibling;
            while (nextSibling) {
              if (!EditorDOMUtils.isEmptyElement(nextSibling)) {
                return false;
              }
              nextSibling = nextSibling.nextSibling;
            }
          }
          if (anchor.parentNode) {
            anchor = anchor.parentNode;
          }
        }
        return true;
      } else if (parent === anchor) {
        if (parent instanceof Element) {
          if (offset === parent.childNodes.length) {
            return true;
          } else {
            // check next elements
            let isAtEnd = false;
            let length = parent.childNodes.length;
            for (let i = offset; i < length; i++) {
              if (parent.childNodes[i]) {
                if (EditorDOMUtils.isEmptyElement(parent.childNodes[i])) {
                  isAtEnd = true;
                } else {
                  isAtEnd = false;
                  break;
                }
              }
            }
            return isAtEnd;
          }
        } else if (parent instanceof Text && offset === parent.length) {
          return true;
        }
      }
    }
    return false;
  }

  static getOffsets(
    reference: Node,
    baseContainer: HTMLElement | null = document.querySelector('[ispagenode="true"]'),
  ): Editor.Common.Rect | null {
    if (reference && baseContainer) {
      if (reference instanceof Text) {
        reference = reference.parentNode as Node;
      }
      if (reference instanceof HTMLElement) {
        let top = reference.offsetTop;
        let left = reference.offsetLeft;
        const bounds = reference.getBoundingClientRect();
        let offsetParentClimber = reference.offsetParent as HTMLElement;

        while (offsetParentClimber) {
          left += offsetParentClimber.offsetLeft;
          if (baseContainer.contains(offsetParentClimber)) {
            top += offsetParentClimber.offsetTop;
          }
          offsetParentClimber = offsetParentClimber.offsetParent as HTMLElement;
        }

        return {
          top,
          left,
          right: 0, // TODO:
          bottom: 0, // TODO:
          width: bounds.width,
          height: bounds.height,
        };
      }
      if (reference instanceof Range) {
        const bounds = reference.getBoundingClientRect();
        const main = document.getElementById('main');

        return {
          top: bounds.top + baseContainer.scrollTop - baseContainer.offsetTop,
          left: bounds.left + (main?.scrollLeft || 0) * -1,
          right: bounds.right, // TODO:
          bottom: bounds.bottom, // TODO:
          width: bounds.width,
          height: bounds.height,
        };
      }
    }
    return null;
  }
}
