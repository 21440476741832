import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import {
  useDispatch,
  useEffectOnUpdate,
  useInfiniteQuery,
  useListParams,
  useSelector,
} from '_common/hooks';
import api from '_common/services/api/ObjectApi';
import useGetActionOptions from './ActionSelect/useGetActionOptions';
import { clearNodesFilters } from './AuditLogSlice';
import { useFilterParamsSelector } from '_common/components/Filters/FilterSlice';

type AuditLogContextType = {
  data: ActionLog[];
  params: Request.FilterParams & Request.OrderParams;
  setParams: (params: Request.FilterParams & Request.OrderParams) => void;
  setSearchActions: (searchActions: string[]) => void;
  objectId: string;
} & Pick<
  ReturnType<typeof useInfiniteQuery>,
  'originalArgs' | 'isFetching' | 'hasNextPage' | 'fetchNextPage' | 'data'
>;

const AuditLogContext = createContext<AuditLogContextType>({
  //@ts-expect-error 'nodes' are required because of 'extra' prop
  data: [],
});

const AuditLogContextProvider = ({
  children,
  objectId,
  objectType,
}: {
  children: ReactNode;
  objectId: ObjectId;
  objectType: ObjectType;
}) => {
  const dispatch = useDispatch();

  const { selectFilterParams } = useFilterParamsSelector();
  const [params, setParams] = useListParams('auditLog');
  const headerFilterParams = useSelector((state) =>
    selectFilterParams(state, state.filters.auditLog),
  );

  const [searchedActions, setSearchActions] = useState<string[]>([]);

  const type = useMemo(() => {
    return objectType ?? '';
  }, [objectType]);

  const id = useMemo(() => {
    return objectId || '';
  }, [objectId]);

  const requestParams = useMemo(() => {
    return { ...params, size: 200, offset: 0, args: { objectType: type, objectId: id } };
  }, [params, type, id]);

  const { data, fetchNextPage, hasNextPage, isFetching, originalArgs } = useInfiniteQuery(
    api.endpoints.listAuditLog,
    {
      params: requestParams,
    },
  );

  const nodesFilters = useSelector((state) => state.auditLog.nodesFilters);

  const actionsValues = useGetActionOptions({ objectType: objectType || 'document' }).map(
    (option) => option.value,
  );
  actionsValues.push('edited');
  actionsValues.push('lock_unlock_suggestions');
  const baseActions = actionsValues.join(',');

  const actionFilters = useMemo(() => {
    return { filter_fields: ['action'], filter_values: [baseActions] };
  }, [baseActions]);

  const nodes = useMemo(() => {
    let filters: Request.FilterParams | undefined = undefined;

    if (nodesFilters) {
      if (nodesFilters.length > 0) {
        filters = {
          filter_fields: ['nodes'],
          filter_values: [decodeURIComponent(nodesFilters.toString())],
        };
      }
    }
    return filters;
  }, [nodesFilters]);

  useEffect(() => {
    setParams({ ...requestParams, ...actionFilters, ...nodes });

    return () => {
      dispatch(clearNodesFilters());
    };
  }, []);

  useEffectOnUpdate(() => {
    const actionFilterIndex = headerFilterParams.filter_fields.findIndex(
      (field) => field === 'action',
    );

    const request: Omit<Request.FilterListParms, 'size'> = {
      offset: 0,
      filter_fields: headerFilterParams.filter_fields.filter(
        (_, index) => index !== actionFilterIndex,
      ),
      filter_values: headerFilterParams.filter_values.filter(
        (_, index) => index !== actionFilterIndex,
      ),
    };

    let filteredActions: string | undefined;
    if (
      (headerFilterParams?.filter_fields.length > 0 &&
        headerFilterParams?.filter_values.length > 0) ||
      searchedActions.length > 0
    ) {
      if (actionFilterIndex === -1) {
        //Action not filtered
        filteredActions = searchedActions.length > 0 ? searchedActions.join(',') : undefined;
      } else {
        //Action is filtered
        const actionValueParams = headerFilterParams.filter_values[actionFilterIndex];
        filteredActions =
          searchedActions.length > 0
            ? `${actionValueParams},${searchedActions.join(',')}`
            : actionValueParams;
      }
    }

    request.filter_fields.push('action');
    request.filter_values.push(filteredActions ?? baseActions);

    if (nodes) {
      request.filter_fields = [...nodes.filter_fields, ...request.filter_fields];
      request.filter_values = [...nodes.filter_values, ...request.filter_values];
    }

    setParams({ ...requestParams, ...request });
  }, [headerFilterParams, actionFilters, nodes, searchedActions]);

  const actionsData = useMemo(() => {
    const acceptedEditFields = ['name', 'description', 'events.due', 'events.warning'];

    return data.nodes?.filter((action: ApiSchemas['ActionSchema']) => {
      if (action.action === 'edited') {
        //Only allow {acceptedEditFields} field edits
        return !!acceptedEditFields.includes(
          (action.extra as ApiSchemas['EditActionExtra']).fields[0]?.field,
        );
      }

      return true;
    });
  }, [data]);

  return (
    <AuditLogContext.Provider
      value={{
        // @ts-expect-error some funky stuff going around
        data: actionsData,
        hasNextPage,
        isFetching,
        originalArgs,
        fetchNextPage,
        params,
        setParams,
        setSearchActions,
        objectId: id,
      }}
    >
      {children}
    </AuditLogContext.Provider>
  );
};

export const useAuditLog = () => {
  return useContext(AuditLogContext);
};

export default AuditLogContextProvider;
