import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'Intl/Intl';
import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';
import { WidgetViewModel } from './WidgetViewModel';
import { ImageOptionsWidgetView } from '../../Views';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export class ImageOptionsWidgetModel extends WidgetViewModel<'imageOptions'> {
  constructor(
    Visualizer: Editor.Visualizer.State,
    refView: Editor.Visualizer.WidgetData['imageOptions']['view'],
    props: Editor.Visualizer.WidgetData['imageOptions']['props'],
  ) {
    super(Visualizer, refView, props);

    this.container.dataset.type = 'imageOptions';
  }

  render() {
    // set widget position
    const offsets = EditorDOMUtils.getOffsets(this.refView);
    if (offsets) {
      this.container.style.position = 'absolute';
      this.container.style.top = `${offsets.top}px`;

      if (this.Visualizer.getLayoutType?.() === 'PAGE') {
        this.container.style.left = `calc(50% - ${offsets.width / 2}px)`;
      } else {
        this.container.style.left = `${offsets.left}px`;
      }

      this.container.style.height = `${offsets.height}px`;
    }

    ReactDOM.unmountComponentAtNode(this.container);

    ReactDOM.render(
      <Provider store={ReduxInterface.getStore()}>
        <IntlProvider locale={ReduxInterface.getLocale()}>
          <ImageOptionsWidgetView
            currentProperty={this.refView.imageWrap}
            offsets={offsets}
            onImageWrapChange={(props: Editor.Styles.ImageProperties) =>
              this.props.onImageWrapChange(props)
            }
          />
        </IntlProvider>
      </Provider>,
      this.container,
    );
  }

  dispose(): void {
    ReactDOM.unmountComponentAtNode(this.container);
    this.container.remove();
  }
}
