//@ts-expect-error needs mixins refactor
import { Mixin } from 'mixwith';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { Logger } from '_common/services';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { ELEMENTS } from 'Editor/services/consts';
import { notify } from '_common/components/ToastSystem';
import { HyperlinkElement } from 'Editor/services/VisualizerManager';

export default Mixin(
  (superclass: any) =>
    class HyperlinksEditionHandler extends superclass {
      destroy() {
        super.destroy();
      }

      handleInsertHyperlink(
        links: any[],
        url: string,
        showTextToDisplay = false,
        textToDisplay = '',
      ) {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          let actionContext;

          try {
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            actionContext = new ActionContext();

            const range = EditorSelectionUtils.getRange();

            if (
              range &&
              DOMUtils.parentContainsNode(
                DOMUtils.getPageNode(range.commonAncestorContainer),
                range.commonAncestorContainer,
              ) &&
              this.selectionManager.isCurrentSelectionEditable()
            ) {
              const link = DOMElementFactory.buildElement(
                ELEMENTS.HyperlinkElement.TAG,
              ) as HyperlinkElement;

              if (url[0] === '/') {
                link.href = url;
              } else if (url.indexOf('http') < 0) {
                link.href = `https://${url}`;
              } else {
                link.href = url;
              }

              if (this.selectionManager.isSelectionCollapsed() || showTextToDisplay) {
                link.textContent = textToDisplay;
                if (links.length === 1) {
                  const linkElement = this.page.querySelector(`*[id="${links[0]}"]`);
                  this.selectionManager.selectNode(linkElement);
                }
              } else if (links.length) {
                const savedMarkers = range.saveRange();
                links.forEach((id) => {
                  const anchor = this.page.querySelector(`*[id="${id}"]`);
                  while (anchor.firstChild) {
                    anchor.parentNode.insertBefore(anchor.firstChild, anchor);
                  }
                  anchor.parentNode.removeChild(anchor);
                });
                range.restoreRange(savedMarkers);
                EditorSelectionUtils.applyRangeToSelection(range);

                link.append(range.cloneContents());
              } else {
                link.append(range.cloneContents());
              }
              this.handleInsertInlineNodeOnMultiSelection(actionContext, link);
              this.changeTracker.saveActionChanges(actionContext);
            }
          } catch (error) {
            Logger.captureException(error);
            this.restoreChanges(actionContext);
          } finally {
            this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
            this.visualizerManager.selection.debounceStartSelectionTracker();
          }
        }
      }

      handleDeleteHyperlink() {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          let actionContext: ActionContext = new ActionContext();

          try {
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            const links: any[] = [];

            const nodes: HTMLElement[] = this.selectionManager.getSelectedNodes();

            nodes.forEach((node) => {
              let anchor: HTMLElement | null = node;
              while (anchor?.tagName !== ELEMENTS.HyperlinkElement.TAG) {
                if (anchor === DOMUtils.getPageNode(anchor)) {
                  anchor = null;
                  break;
                }
                anchor = (anchor?.parentNode as HTMLElement) || null;
              }
              if (anchor && links.indexOf(anchor) < 0) {
                links.push(anchor);
              }
            });

            links.forEach((link) => {
              while (link.firstChild) {
                link.parentNode.insertBefore(link.firstChild, link);
              }

              actionContext.addChangeUpdatedNode(link.parentNode);

              link.parentNode.removeChild(link);
            });

            this.visualizerManager.selection.triggerSelectionChanged();

            this.changeTracker.saveActionChanges(actionContext);

            notify({
              type: 'success',
              title: 'HYPERLINK_REMOVED',
              message: 'THE_HYPERLINK_WAS_SUCCESSFULLY_REMOVED',
            });
          } catch (error) {
            Logger.captureException(error);
            this.restoreChanges(actionContext);
          } finally {
            this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
            this.visualizerManager.selection.debounceStartSelectionTracker();
          }
        }
      }
    },
);
