import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Icon, Popover, Toggle, Tooltip, usePopper } from 'dodoc-design-system';
import cx from 'classnames';

import { useDispatch, useSelector } from '_common/hooks';
import { selectCurrentAnnotation, setCreationSetting } from 'PDF/redux/PDFAnnotationsSlice';
import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';

import styles from './Toggles.module.scss';

export const WIDTHS = [1, 2, 4, 6, 12, 16] as const;

const StrokeWidthDropdown = ({ disabled }: { disabled: boolean }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const manager = usePDFContext();
  const { canCreateAnnotation, canEditAnnotation } = usePDFPermissions();
  const { referenceProps, popperProps, isOpen, close } = usePopper({ disabled });

  const selectedAnnotation = useSelector(selectCurrentAnnotation);
  const strokeWidth = useSelector((state) => state.pdf.annotations.creation.strokeWidth);
  const cursorMode = useSelector((state) => state.pdf.general.cursorMode);

  const tooltipContent = useMemo(() => {
    if (!disabled) {
      return intl.formatMessage({ id: 'STROKE_THICKNESS' });
    }

    const isROIType = (['Highlight', 'Underline', 'StrikeOut'] as PDF.Annotation.Type[]).some(
      (type) => type === selectedAnnotation?.subtype || type === cursorMode,
    );

    if (!canCreateAnnotation || !!(selectedAnnotation && !canEditAnnotation(selectedAnnotation))) {
      return intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' });
    }
    if (isROIType) {
      return intl.formatMessage({ id: 'CANNOT_APPLY_PROPERTY_IN_THE_SELECTED_SHAPE' });
    }
    return '';
  }, [canCreateAnnotation, selectedAnnotation, cursorMode]);

  const setStrokeWidth = (width: (typeof WIDTHS)[number]) => {
    if (selectedAnnotation) {
      manager.editAnnotationBorder(selectedAnnotation.pageNumber, selectedAnnotation.id, {
        ...selectedAnnotation.border,
        width,
      });
    } else {
      dispatch(
        setCreationSetting({
          strokeWidth: width,
        }),
      );
      close();
    }
  };

  const currentStrokeWidth = selectedAnnotation ? selectedAnnotation.border?.width : strokeWidth;

  return (
    <>
      <Tooltip
        disabled={popperProps.isOpen}
        content={tooltipContent}
        placement="bottom"
        testId="menu-stroke-thickness-tooltip"
      >
        <Toggle
          variant="ghost"
          size="medium"
          margin="0 0.5rem 0 0"
          isToggled={isOpen}
          icon={isOpen ? 'StrokeWidthBlue' : 'StrokeWidthGrey'}
          dropdown
          disabled={disabled}
          {...referenceProps}
          testId="menu-stroke-thickness-toggle"
        />
      </Tooltip>
      <Popover {...popperProps} testId="strokeWidth-popper">
        <div className={styles.popover}>
          {WIDTHS.map((width) => (
            <div
              key={width}
              className={cx(styles.item, { [styles.isSelected]: currentStrokeWidth === width })}
              onClick={() => setStrokeWidth(width)}
            >
              <span className={styles.selection}>
                <Icon size={16} icon="Check" />
              </span>
              <span
                className={styles.strokeWidth}
                style={{
                  height: width, // ironic
                }}
              />
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default StrokeWidthDropdown;
