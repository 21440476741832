import api from './api';
import { notify } from '_common/components/ToastSystem';
import { operations } from '_types/api';
import { navigateToSettings } from 'router/history';
import { addData } from 'App/redux/appSlice';
import { addToList } from '_common/components/Table/TableSlice';

type APIError = {
  error: { data: any; status: number };
};

export const ObjectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getObject2: builder.query<
      ApiOperations['get_object']['responses']['200']['content']['application/json'],
      ApiOperations['get_object']['parameters']['path']
    >({
      query: ({ object_id, object_type }) => {
        return {
          url: `/object/${object_type}/get/${object_id}`,
          errorsExpected: [403, 404],
        };
      },
      providesTags: (result, error, arg) => [{ type: arg.object_type, id: arg.object_id }],
    }),
    listSpaces: builder.query<
      operations['list_spaces']['responses']['200']['content']['application/json'],
      Request.AllListParams
    >({
      query: (params) => ({
        url: `/object/space/list`,
        config: {
          params,
        },
      }),
      providesTags: (result, error, arg) =>
        result ? [...result.nodes.map(({ id }) => ({ type: 'Object' as const, id }))] : [],
    }),
    listSpaceDir: builder.query<
      ApiSchemas['DirSpaceResponseSchema'],
      { id: string; params: Request.AllListParams }
    >({
      query: ({ id, params }) => ({
        url: `/api/object/space/${id}/list`,
        config: {
          params,
        },
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.nodes.map(({ id }) => ({ type: 'Object' as const, id })),
              { type: 'Object', id: arg.id },
            ]
          : [{ type: 'Object', id: arg.id }],
    }),
    createSpace: builder.mutation<
      operations['create_space']['responses']['200'],
      { name: string; description: string }
    >({
      query: ({ name, description }) => {
        return {
          url: `/object/space/create`,
          method: 'POST',
          body: { name, description },
          errorsExpected: [400],
        };
      },
      invalidatesTags: () => ['Object'],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(addData({ [data.id]: data }));
          dispatch(addToList({ identity: 'spaces', objectId: data.id }));
          navigateToSettings('space', data.id, '');
          notify({
            type: 'success',
            title: 'NEW_ELEMENT_TYPE_CREATED',
            titleValues: { elementType: data.type },
            message: 'NEW_ELEMENT_TYPE_SUCCESSFULLY_CREATED',
            messageValues: { elementType: data.type },
          });
        } catch (error) {
          // Same as RTK Thunk 'rejected' state
          const typedError = (error as APIError).error;
          if (typedError.status === 400) {
            notify({
              type: 'error',
              title: 'INCORRECT_NAME',
              message: 'SPACE_INCORRECT_NAME_MESSAGE',
            });
          }
        }
      },
    }),
    listFolderDir: builder.query<ApiSchemas['DirFolderResponseSchema'], string>({
      query: (id) => ({
        url: `/api/object/folder/${id}/list`,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.nodes.map(({ id }) => ({ type: 'Object' as const, id })),
              { type: 'Object', id: arg },
            ]
          : [{ type: 'Object', id: arg }],
    }),
    listAuditLog: builder.query<
      operations['list_actions']['responses']['200'],
      Request.AllListParams & { args: { objectType: 'document' | 'dopdf'; objectId: ObjectId } }
    >({
      query: (params) => {
        const apiParams: Partial<typeof params> = { ...params };
        delete apiParams.args;
        return {
          url: `/object/${params.args.objectType}/${params.args.objectId}/actions`,
          config: {
            params: apiParams,
          },
        };
      },
      providesTags: (result) =>
        result ? [...result.nodes.map(({ id }) => ({ type: 'Object' as const, id }))] : [],
    }),
    listRecycle: builder.query<
      operations['list_recycle']['responses']['200']['content']['application/json'],
      Request.AllListParams
    >({
      query: (params) => ({
        url: `/recycle/list`,
        config: {
          params,
        },
      }),
      providesTags: (result, error, arg) =>
        result ? [...result.nodes.map(({ id }) => ({ type: 'Object' as const, id }))] : [],
    }),
    restoreObject: builder.mutation<void, ObjectId>({
      query: (id) => ({
        url: `/recycle/${id}/restore`,
        method: 'POST',
        errorsExpected: [403, 404],
        body: { id },
      }),
      invalidatesTags: () => ['Object'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          notify({
            type: 'success',
            title: 'global.success',
            message: 'storage.notifications.restore.messageSuccess',
          });

          // dispatch(removeFromList({identity: 'recycle', objectId: id}));
          // dispatch(clearSelection());
        } catch (e) {
          const error = (e as APIError).error;
          switch (error?.status) {
            case 403:
              notify({
                type: 'error',
                title: 'global.error',
                message: 'storage.notifications.restore.insufficientPermissions',
              });
              break;
            case 404:
              notify({
                type: 'error',
                title: 'global.error',
                message: 'storage.notifications.restore.missingOrignialLocation',
              });
              break;
            default:
              break;
          }
        }
      },
    }),
    getObjectPath: builder.query<ApiSchemas['PathSchema'], string>({
      query: (id) => ({ url: `/object/${id}/path` }),
      providesTags: (result, error, arg) => [{ type: 'path', id: arg }],
    }),
  }),
});

// Export queries
export const {
  useGetObject2Query,
  useListSpacesQuery,
  useListSpaceDirQuery,
  useListFolderDirQuery,
  useCreateSpaceMutation,
  useLazyListAuditLogQuery,
  useListRecycleQuery,
  useRestoreObjectMutation,
  useGetObjectPathQuery,
} = ObjectApi;

export default ObjectApi;
