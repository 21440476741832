import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ProgressBar } from 'dodoc-design-system';
import EditorManager from 'Editor/services/EditorManager';
import { useDispatch, useSelector } from '_common/hooks';
import { selectFilteredNavigationElements } from 'Editor/redux/NavigationSlice';
import NavigationTree from './NavigationTree';
import NavigationFilters from './NavigationFilters';
import styles from './NavigationTab.module.scss';
import SidebarPanel from '../SidebarPanel';
import SidebarHeader from '../SidebarHeader';
import SidebarContent from '../SidebarContent';
import { selectHasFilters } from '_common/components/Filters/FilterSlice';
import { EmptyFilteredState } from '_common/components';
import { openModal } from '_common/modals/ModalsSlice';
import { setTocLoading } from 'Editor/redux/TocSlice';

const NavigationTab = () => {
  const dispatch = useDispatch();

  const searchValue = useSelector((state) => state.editor.navigation.searchValue);
  const loading = useSelector((state) => state.editor.toc.loading);
  const { list } = useSelector(selectFilteredNavigationElements);
  const hasActiveFilters = useSelector((state) =>
    selectHasFilters(state, state.filters.editorNavPanel),
  );

  useEffect(() => {
    dispatch(setTocLoading(true));
    EditorManager.getInstance().initializeNavigation();
    return () => {
      EditorManager.getInstance().destroyNavigation();
    };
  }, []);

  const handleOpenWordCountModal = () => {
    dispatch(openModal('WordCountModal'));
  };

  const renderEmptyState = () => {
    if (hasActiveFilters) {
      return <EmptyFilteredState identity="editorNavPanel" size="medium" />;
    }

    return (
      <div className={styles.empty_state}>
        <div className={styles.title}>
          <FormattedMessage
            id={
              searchValue === ''
                ? 'NAVIGATION_PANEL_EMPTY_TITLE'
                : 'NO_ELEMENTS_FOUND_TITLE_IN_NAVIGATION_PANEL'
            }
          />
        </div>
        <div className={styles.description}>
          <FormattedMessage
            id={
              searchValue === ''
                ? 'NAVIGATION_PANEL_EMPTY_DESCRIPTION'
                : 'NO_ELEMENTS_FOUND_DESCRIPTION_IN_NAVIGATION_PANEL'
            }
            values={{ searchValue }}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className={styles.loading_state}>
          <ProgressBar size="small" testId="navigation-loading-progressBar" />
          <div className={styles.loading_text}>
            <FormattedMessage id="LOADING_CONTENT" />
          </div>
        </div>
      );
    }

    return list.length === 0 ? renderEmptyState() : <NavigationTree />;
  };

  return (
    <SidebarPanel testId="navigation-panel">
      <SidebarHeader>
        <FormattedMessage id="NAVIGATION_PANEL_TITLE" />
      </SidebarHeader>
      <SidebarContent>
        <Button
          size="medium"
          onClick={handleOpenWordCountModal}
          margin="0 0 1rem 0"
          testId="navigation-pane-open-word-count-button"
        >
          <FormattedMessage id="OPEN_WORD_COUNT" />
        </Button>
        <NavigationFilters />
        {renderContent()}
      </SidebarContent>
    </SidebarPanel>
  );
};

export default NavigationTab;
