import { forwardRef, Ref } from 'react';
import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import ViewTaskCardContent, { ViewTaskCardProps } from './ViewTaskCardContent';

const ViewTaskCard = forwardRef((props: ViewTaskCardProps, ref: Ref<HTMLDivElement>) => {
  return (
    <IntlErrorBoundary fallbackType="card" mockProps={{ sidebar: !!props.sidebar }}>
      <ViewTaskCardContent {...props} ref={ref} />
    </IntlErrorBoundary>
  );
});

export default ViewTaskCard;
