import { useSelector } from '_common/hooks';
import { selectActiveMenu } from '_common/components/Sidebar/SidebarSlice';

import Option from './Option/Option';

import styles from './Options.module.scss';

const Options = () => {
  const activeMenu = useSelector(selectActiveMenu);

  if (!activeMenu) {
    return null;
  }

  return (
    <div className={styles.root}>
      {Object.keys(activeMenu.options).map((key, i) => {
        const option = activeMenu.options[key];
        return <Option key={i} id={option.id} label={option.label} testId={option.id} />;
      })}
    </div>
  );
};

export default Options;
