import { useIntl } from 'react-intl';
import { Tooltip } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';

import { ToolbarToggleButton } from 'Editor/components';
import { setSidebarView, SidebarSliceState } from 'Editor/redux/SidebarSlice';
import { getDocumentObject, selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';

import { InteractionController } from '_common/components';
import { InteractionRule } from '_common/components/OnboardingOverlay/InteractionController';
import { selectIsIEnvision } from 'App/redux/appSlice';

type VIEWSProps = {
  icon:
    | 'ApproveContent'
    | 'BlockPermissions'
    | 'SpellCheck'
    | 'FindAndReplace'
    | 'Tasks'
    | 'Review'
    | 'References'
    | 'Notes'
    | 'CrossReference'
    | 'ParagraphStyles'
    | 'NavigationPane';
  tooltip: string;
  disabled?: boolean;
  id:
    | 'APPROVE_CONTENT'
    | 'CONTENT_PERMISSIONS'
    | 'SPELL_CHECK'
    | 'FIND_AND_REPLACE'
    | 'TASKS'
    | 'REVIEW'
    | 'REFERENCES'
    | 'NOTES'
    | 'CROSS_REFERENCES'
    | 'DOCUMENT_STYLES'
    | 'NAVIGATION';
}[];

const SidebarToggles = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const document = useSelector(getDocumentObject);
  const versionHistory = useSelector((state) => state.editor.status.versionHistory);
  const sidebarView = useSelector((state) => state.editor.sidebar.view);
  const actions = useSelector((state) => state.app.information.actions);
  const isEnvision = useSelector(selectIsIEnvision);
  const initiatedWithTasks = useSelector((state) => state.editor.status.initiatedWithTasks);

  const canAccessTasks = !isEnvision || initiatedWithTasks;

  //@ts-expect-error state.app.information type is wrong
  const canApprove = actions && actions.editor.nodes.approve;
  //@ts-expect-error state.app.information type is wrong
  const canChangePermissions = actions && actions.editor.nodes.permissions;

  const VIEWS: VIEWSProps = [
    {
      icon: 'ApproveContent',
      tooltip:
        actions && !canApprove
          ? 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION'
          : 'editor.menu.review.approveContent',
      id: 'APPROVE_CONTENT',
      disabled:
        versionHistory ||
        document.status === 'approved' ||
        (actions && !canApprove) ||
        document.user_permissions.every(
          (permission: string) => !['admin', 'owner', 'approve'].includes(permission),
        ) ||
        isReadOnlyMode,
    },
    {
      icon: 'BlockPermissions',
      tooltip:
        actions && !canChangePermissions
          ? 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION'
          : 'CONTENT_PERMISSIONS',
      id: 'CONTENT_PERMISSIONS',
      disabled:
        versionHistory ||
        document.status === 'approved' ||
        document.user_permissions.length === 0 ||
        document.user_permissions.every(
          (permission: string) =>
            !['admin', 'owner', 'add_permission', 'remove_permission'].includes(permission),
        ) ||
        (actions && !canChangePermissions),
    },
    {
      icon: 'SpellCheck',
      tooltip: 'SPELL_CHECK',
      id: 'SPELL_CHECK',
      disabled: isReadOnlyMode,
    },
    {
      icon: 'FindAndReplace',
      tooltip: 'FIND_AND_REPLACE',
      id: 'FIND_AND_REPLACE',
    },
    {
      icon: 'Tasks',
      tooltip: 'TASKS',
      id: 'TASKS',
    },
    {
      icon: 'Review',
      tooltip: 'editor.menu.review.label',
      id: 'REVIEW',
    },
    {
      icon: 'References',
      tooltip: 'REFERENCES',
      id: 'REFERENCES',
    },
    {
      icon: 'Notes',
      tooltip: 'NOTES_PANEL_TITLE',
      id: 'NOTES',
    },
    {
      icon: 'CrossReference',
      tooltip: 'CROSS_REFERENCES',
      id: 'CROSS_REFERENCES',
    },
    {
      icon: 'ParagraphStyles',
      tooltip: 'PARAGRAPH_STYLES',
      id: 'DOCUMENT_STYLES',
    },
    {
      icon: 'NavigationPane',
      tooltip: 'NAVIGATION_PANEL_TITLE',
      id: 'NAVIGATION',
    },
  ];

  const handleToggleSidebarView = (view: SidebarSliceState['view']) => {
    dispatch(setSidebarView(sidebarView === view ? null : view));
  };

  const getInteractionRules = (viewId: VIEWSProps[number]['id']): InteractionRule[] | undefined => {
    switch (viewId) {
      case 'REVIEW':
        return [
          {
            interaction: 'editor_sidepanel_review_suggestions',
            actions: ['editor_suggestions_openSidePanel'],
          },
          {
            interaction: 'editor_sidepanel_review_comments',
            actions: ['editor_comments_openSidePanel'],
          },
        ];
      case 'TASKS':
        return [{ interaction: 'editor_sidepanel_tasks', actions: ['editor_tasks_openSidePanel'] }];
    }
  };

  return (
    <>
      {VIEWS.map((view) => (
        <Tooltip
          key={view.id}
          content={intl.formatMessage({ id: view.tooltip })}
          placement="left"
          testId={`${view.id.toLowerCase().replace(/_/g, '-')}-tooltip`}
        >
          <InteractionController environment="editor" rules={getInteractionRules(view.id)}>
            {view.id === 'TASKS' ? (
              <Tooltip
                content={intl.formatMessage({
                  id: 'NO_PERMISSION_TO_PERFORM_ACTION',
                })}
                placement="right"
                disabled={canAccessTasks}
                testId={`${view.id.toLowerCase().replace(/_/g, '-')}-tooltip`}
              >
                <ToolbarToggleButton
                  isActive={sidebarView === view.id}
                  icon={view.icon}
                  toggleButtonClicked={() => handleToggleSidebarView(view.id)}
                  disabled={view.disabled || !canAccessTasks}
                  sidebar
                  testId={view.id.toLowerCase().replace(/_/g, '-')}
                />
              </Tooltip>
            ) : (
              <ToolbarToggleButton
                isActive={sidebarView === view.id}
                icon={view.icon}
                toggleButtonClicked={() => handleToggleSidebarView(view.id)}
                disabled={
                  view.disabled ||
                  false /* Avoid disabled value to be null (DS toggle component doesn't work properly when disabled value is null) */
                }
                sidebar
                testId={view.id.toLowerCase().replace(/_/g, '-')}
              />
            )}
          </InteractionController>
        </Tooltip>
      ))}
      {versionHistory && (
        <>
          <Tooltip
            content={intl.formatMessage({ id: 'VERSIONS_PANEL_TITLE' })}
            placement="left"
            testId="versions-tooltip"
          >
            <ToolbarToggleButton
              margin="0.75rem 0 0.75rem"
              isActive={sidebarView === 'VERSIONS'}
              icon="VHSidebar"
              toggleButtonClicked={() => handleToggleSidebarView('VERSIONS')}
              sidebar
              testId="versions"
            />
          </Tooltip>
        </>
      )}
    </>
  );
};

export default SidebarToggles;
