/* eslint-disable class-methods-use-this */
import { Mixin } from 'mixwith';
import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class InsertElementHelper extends superclass {
      /**
       * @param {ActionContext} actionContext
       * @param {*} node
       */
      insertInlineNode(actionContext, node) {
        // NOTE: Its not suposed to stop and resume selection tracker here
        // its a simple operation to be used in various contexts
        // use stop and resume selection tracker where this function is called

        const workingRange = EditorSelectionUtils.getRange();
        const span = DOMElementFactory.buildElement('span');
        workingRange.insertNode(span);

        // validate if node can be inserted
        if (
          node.nodeType === Node.TEXT_NODE ||
          DOMNormalizer.isAllowedUnder(node.tagName, span.parentNode.tagName)
        ) {
          //? TEMP dependency injection new nodes inserted (specifically image-element)
          if (node.Visualizer == null) {
            node.Visualizer = this.visualizerManager.Visualizer;
          }
          if (node.Data == null) {
            node.Data = this.dataManager;
          }

          // check for tracked enter elements
          if (
            this.isAddParagraphMarker(span.parentNode) ||
            this.isDeleteParagraphMarker(span.parentNode)
          ) {
            this.selectionManager.setCaret(span.parentNode, 'PRE');
            span.remove();
            this.insertInlineNode(actionContext, node);
          }

          const closest = DOMUtils.closest(span, DOMUtils.BLOCK_TEXT_ELEMENTS);
          if (closest) {
            const br = closest.querySelector('br');
            if (br) {
              br.remove();
            }

            // check double state elements, ex: placeholder-element, etc
            const elements = closest.querySelectorAll(DOMUtils.INLINE_DOUBLE_STATE_ELEMENTS);
            for (let i = 0; i < elements.length; i++) {
              elements[i].removeOnlyChildState?.();
            }
          }

          const parentNode = span.parentNode;

          if (parentNode) {
            DOMUtils.replaceNode(parentNode, node, span);

            if (node.nodeType === Node.TEXT_NODE) {
              this.selectionManager.setCaret(node, 'INSIDE_END');

              const range = EditorSelectionUtils.getRange();
              const savedMarkers = range.saveRange();
              DOMNormalizer.normalizeChildren(parentNode);
              range.restoreRange(savedMarkers);
              EditorSelectionUtils.applyRangeToSelection(range);
            } else {
              this.selectionManager.setCaret(node, 'POST');
            }

            // remove unwanted empty text nodes
            parentNode.normalize();

            // register change
            actionContext.addChangeUpdatedNode(parentNode);

            if (
              parentNode.tagName === ELEMENTS.TrackInsertElement.TAG ||
              parentNode.tagName === ELEMENTS.TrackDeleteElement.TAG
            ) {
              actionContext.addReferenceToRefresh(parentNode.getAttribute('element_reference'));
            }
          }
        } else {
          // new element not allowed
          const error = `Invalid insertion inline! Node ${node.tagName} not allowed under ${span.parentNode.tagName}`;
          span.remove();
          throw new Error(error);
        }
      }

      /**
       * insert text node inline
       * @param {ActionContext} actionContext
       * @param {String} textNode
       */
      insertTextInline(actionContext, text) {
        // NOTE: Its not suposed to stop and resume selection tracker here
        // its a simple operation to be used in various contexts
        // use stop and resume selection tracker where this function is called

        if (typeof text === 'string') {
          let insertTextAllowed = false;

          if (this.selectionManager.isCurrentSelectionEditable()) {
            insertTextAllowed = true;
          }

          if (insertTextAllowed) {
            const selection = EditorSelectionUtils.getSelection();
            let anchorNode = selection.anchorNode;
            let anchorOffset = selection.anchorOffset;

            // check for tracked enter elements
            if (this.isAddParagraphMarker(anchorNode) || this.isDeleteParagraphMarker(anchorNode)) {
              this.selectionManager.setCaret(anchorNode, 'PRE');
              this.insertTextInline(actionContext, text);
              return;
            }

            // remove BR if exist
            const closest = DOMUtils.closest(anchorNode, DOMUtils.BLOCK_TEXT_ELEMENTS);
            if (closest) {
              const br = closest.querySelector('br');
              if (br) {
                br.remove();
              }

              // if anchorNode its a DEFAULT_TEXT_ELEMENT and the offset child its a text node
              // fix selection to text node
              if (anchorNode === closest) {
                if (
                  anchorNode.childNodes[anchorOffset] &&
                  anchorNode.childNodes[anchorOffset].nodeType === Node.TEXT_NODE
                ) {
                  anchorNode = anchorNode.childNodes[anchorOffset];
                  anchorOffset = 0;
                }
              }

              // extract paragraph style properties if theres no format element already
              if (
                !DOMUtils.closest(anchorNode, [ELEMENTS.FormatElement.TAG]) &&
                closest.getStyleAttributes &&
                EditorDOMUtils.isEmptyElement(closest)
              ) {
                const styleAttributes = closest.getStyleAttributes();

                const appliers = Object.keys(styleAttributes).reduce((array, attribute) => {
                  if (
                    styleAttributes[attribute] != null &&
                    StylesUtils.ALLOWED_INLINE_ATTRIBUTES_BY_ELEMENT[closest.tagName].includes(
                      attribute,
                    )
                  ) {
                    array.push(
                      this.stylesHandler.buildAttributeApplier(
                        attribute,
                        styleAttributes[attribute],
                      ),
                    );
                  }
                  return array;
                }, []);

                this.stylesHandler.addArrayAppliersToPendingStyles({
                  appliers,
                  skipNextCheckStyles: false,
                  resetStyles: false,
                });
              }
            }

            // insert text inline
            if (anchorNode && anchorNode.nodeType === Node.TEXT_NODE) {
              anchorNode.insertData(anchorOffset, text);

              const closest = DOMUtils.closest(anchorNode, [
                ELEMENTS.TrackInsertElement.TAG,
                ELEMENTS.TrackDeleteElement.TAG,
              ]);
              if (closest) {
                actionContext.addReferenceToRefresh(closest.getAttribute('element_reference'));
              }

              // register change
              actionContext.addChangeUpdatedNode(anchorNode);

              if (this.stylesHandler.areStylesToApply()) {
                // NOTE: for now, applying pending styles will be done in this function
                // if it justifys, please change it to other place

                // select last inserted text
                EditorSelectionUtils.setSelection(
                  anchorNode,
                  anchorOffset,
                  anchorNode,
                  anchorOffset + text.length,
                );

                // apply styles if there are any pending
                this.stylesHandler.togglePendingStyles();
                this.selectionManager.collapseToEnd();
                this.selectionManager.fixCollapsedTextSelection({
                  suggestionMode: this.dataManager.document.getDocumentTracking().state,
                });
              } else {
                EditorSelectionUtils.setCaret(anchorNode, 'INDEX', anchorOffset + text.length);
              }
            } else {
              anchorNode = document.createTextNode(text);
              this.insertInlineNode(actionContext, anchorNode);

              if (this.stylesHandler.areStylesToApply()) {
                // NOTE: for now, applying pending styles will be done in this function
                // if it justifys, please change it to other place

                // select node contents
                EditorSelectionUtils.setSelection(anchorNode, 0, anchorNode, anchorNode.length);

                // apply styles if there are any pending
                this.stylesHandler.togglePendingStyles();
                this.selectionManager.collapseToEnd();
                this.selectionManager.fixCollapsedTextSelection({
                  suggestionMode: this.dataManager.document.getDocumentTracking().state,
                });
              }
            }
          }
        }
      }

      // eslint-disable-next-line class-methods-use-this
      isBlockNodeInsertionAllowed(newNode, baseNode, anchorNode) {
        const closestContainer = DOMUtils.closestMultiBlockContainerElement(anchorNode);
        if (closestContainer) {
          if (DOMNormalizer.isAllowedUnder(newNode.tagName, closestContainer.tagName)) {
            return true;
          }
          return false;
        }

        if (
          baseNode.parentNode === DOMUtils.getPageNode() &&
          DOMNormalizer.isElementAllowedUnderPage(newNode.tagName)
        ) {
          return true;
        }

        return false;
      }

      // #######################################################################
      //                    inline insertion validation
      // #######################################################################
      isInlineInsertionAllowed(baseNode, anchorNode, anchorOffset) {
        if (baseNode && anchorNode) {
          if (DOMUtils.isNodeEditableTextElement(baseNode)) {
            // SELECTION IS A DEFAULT TEXT ELEMENT
            return this._isInlineInsertionAllowedOnText(baseNode, anchorNode, anchorOffset);
          }
          if (!DOMUtils.isBlockNodeEditable(baseNode)) {
            // SELECTION IS A NON-EDITABLE ELEMENT
            return this._isInlineInsertionAllowedOnNonEditable(baseNode, anchorNode, anchorOffset);
          }
          if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
            // SELECTION IS A FIGURE
            return this._isInlineInsertionAllowedOnOnFigure(baseNode, anchorNode, anchorOffset);
          }
          if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
            // SELECTION IS A TABLE
            return this._isInlineInsertionAllowedOnTable(baseNode, anchorNode, anchorOffset);
          }
          if (DOMUtils.isNodeAContainerElement(baseNode)) {
            // SELECTION IS INSIDE A BLOCK TRACKED ELEMENT
            return this._isInlineInsertionAllowedOnContainerElement(
              baseNode,
              anchorNode,
              anchorOffset,
            );
          }
        }

        return false;
      }
      _isInlineInsertionAllowedOnContainerElement(baseNode, anchorNode, anchorOffset) {
        if (DOMUtils.BLOCK_CONTAINER_ELEMENTS.includes(anchorNode.tagName)) {
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        }

        const subLevel0Node = DOMUtils.findNodeLevel0(baseNode, anchorNode);
        if (subLevel0Node) {
          return this.isInlineInsertionAllowed(baseNode, anchorNode, anchorOffset);
        }
        return false;
      }

      _isInlineInsertionAllowedOnText(baseNode, anchorNode) {
        // check non_editable_inline_elements
        const closestNonEditableInline = DOMUtils.closest(anchorNode, [
          ...DOMUtils.INLINE_NON_EDITABLE_ELEMENTS,
        ]);
        if (closestNonEditableInline) {
          // if its a citation check for citation-group
          return false;
        }

        return true;
      }

      _isInlineInsertionAllowedOnNonEditable() {
        return false;
      }

      _isInlineInsertionAllowedOnOnFigure(baseNode, anchorNode, anchorOffset) {
        const closest = DOMUtils.closest(anchorNode, ['FIGCAPTION']);
        if (closest && !closest.hasAttribute('lock')) {
          return this._isInlineInsertionAllowedOnText(baseNode, anchorNode, anchorOffset);
        }
        return false;
      }

      _isInlineInsertionAllowedOnTable(baseNode, anchorNode, anchorOffset) {
        const closest = DOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);
        if (closest && !closest.hasAttribute('lock')) {
          if (closest.tagName === ELEMENTS.TableCellElement.TAG) {
            const tdLevel0Node = DOMUtils.findNodeLevel0(closest, anchorNode);
            if (tdLevel0Node && tdLevel0Node.tagName !== ELEMENTS.TableElement.TAG) {
              return this.isInlineInsertionAllowed(tdLevel0Node, anchorNode, anchorOffset);
            }
          }
        }
        return false;
      }
    },
);
