import { useRef, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { Modal, Button, Input, InputField, TextArea, RadioButton } from 'dodoc-design-system';
import { closeAndResetModal } from '../ModalsSlice';
import { createObject, importFile } from 'Storage/pages/StoragePage/StoragePageSlice';

import Section from './Section/Section';

import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';
import type { TextAreaProps } from 'dodoc-design-system/build/types/Components/TextArea/TextArea';
import type { ButtonProps } from 'dodoc-design-system/build/types/Components/Button/Button';
import { PageLayoutField } from '_common/components';

type CreateObjectParams = Parameters<typeof createObject>[0]['parameters'];

type TypeOfObject<T extends ObjectType> = Record<Uppercase<T>, T>;

export const NEW_TYPES: TypeOfObject<'folder'> & TypeOfObject<'document'> & TypeOfObject<'file'> = {
  FOLDER: 'folder',
  DOCUMENT: 'document',
  FILE: 'file',
};

const MODAL = 'NewFolderDocumentModal';

const NewFolderDocumentModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const newType = useSelector((state) => state.modals[MODAL].newType);
  const fileRedux = useSelector((state) => state.modals[MODAL].file);
  const current = useSelector((state) => state.modals[MODAL].current);
  const id = useSelector((state) => state.modals[MODAL].id);
  const identity = useSelector((state) => state.modals[MODAL].identity);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [validations, setValidations] = useState<{ name?: string; description?: string }>({
    name: '',
    description: '',
  });
  const [file, setFile] = useState<File | undefined>(undefined);
  const [pageSize, setPageSize] = useState<PageSize>('a4');
  const [pageOrientation, setPageOrientation] = useState<PageOrientation>('portrait');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setValidations({});
      setDescription('');
      setFile(fileRedux);
      setName((fileRedux && fileRedux.name && removeNameExtension(fileRedux.name)) || '');

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isOpen, fileRedux]);

  const removeNameExtension = (name: File['name']) => {
    if (!name) {
      return '';
    }
    return name.substring(0, name.lastIndexOf('.'));
  };

  const onNameChanged: InputProps['onChange'] = (e) => {
    const valids: typeof validations = {};

    const inputName = e.target.value;
    setName(inputName);

    if (new RegExp(/[<>/:?*"\\|]/).test(inputName)) {
      valids.name = intl.formatMessage(
        {
          id: 'UNSUPPORTED_CHARACTER_MESSAGE',
        },
        { type: newType },
      );
    } else if (inputName.length === 0) {
      valids.name = intl.formatMessage({
        id: 'validation.name.insertName',
      });
    } else if (newType === 'document' && inputName.length > 146) {
      valids.name = intl.formatMessage({ id: 'validation.name.longName' }, { length: 146 });
    } else if (inputName.length > 150) {
      valids.name = intl.formatMessage({ id: 'validation.name.longName' }, { length: 150 });
    } else {
      valids.name = '';
    }

    if (Object.keys(valids).length > 0) {
      setValidations(valids);
    }
  };

  const onDescriptionChanged: TextAreaProps['onChange'] = (e) => {
    const valids: typeof validations = {};
    const inputDescription = e.target.value;
    setDescription(inputDescription);
    if (inputDescription.length > 1000) {
      valids.description = intl.formatMessage({
        id: 'validation.description.longDescription',
      });
    } else {
      valids.description = '';
    }

    if (Object.keys(valids).length > 0) {
      setValidations(valids);
    }
  };

  const getTranslations = () => {
    switch (newType) {
      case NEW_TYPES.FOLDER:
        return {
          title: intl.formatMessage({
            id: 'storage.modals.createFolder.title',
          }),
          inputTitle: intl.formatMessage({
            id: 'NEW_FOLDER_TITLE',
          }),
          inputPlaceholder: intl.formatMessage({
            id: 'NEW_FOLDER_PLACEHOLDER',
          }),
          description: intl.formatMessage({
            id: 'NEW_FOLDER_DESCRIPTION',
          }),
          descriptionPlaceholder: intl.formatMessage({
            id: 'NEW_FOLDER_DESCRIPTION_PLACEHOLDER',
          }),
          createButton: 'storage.modals.createFolder.createButton',
        };
      case NEW_TYPES.DOCUMENT:
        return {
          title: intl.formatMessage({
            id: 'storage.modals.createDocument.title',
          }),
          inputTitle: intl.formatMessage({
            id: 'NEW_DOCUMENT_TITLE',
          }),
          inputPlaceholder: intl.formatMessage({
            id: 'NEW_DOCUMENT_PLACEHOLDER',
          }),
          description: intl.formatMessage({
            id: 'NEW_DOCUMENT_DESCRIPTION',
          }),
          descriptionPlaceholder: intl.formatMessage({
            id: 'NEW_DOCUMENT_DESCRIPTION_PLACEHOLDER',
          }),
          createButton: 'storage.modals.createDocument.createButton',

          importContentFrom: intl.formatMessage({
            id: 'storage.modals.createDocument.importContentFrom',
          }),
          importOtherContent: 'storage.modals.createDocument.importOtherContent',
        };
      case NEW_TYPES.FILE:
        return {
          title: intl.formatMessage({
            id: 'storage.modals.createFile.title',
          }),
          inputTitle: intl.formatMessage({
            id: 'storage.modals.createDocument.titleInputTitle',
          }),
          description: intl.formatMessage({
            id: 'storage.modals.createFolder.description',
          }),
          createButton: 'storage.modals.createFile.createButton',
        };
      default:
        return {};
    }
  };

  const handleOnClickNew = () => {
    handleNewFolderDocument(newType, {
      name,
      description,
      id,
      file,
    });
  };

  const handleOnChangeImportContent: ButtonProps<'medium'>['onChange'] = (e) => {
    const file = e.target.files?.[0];
    setDescription('');
    setFile(file);
    setName(removeNameExtension(file?.name || ''));
  };

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  const renderTemplateFileElement = () => {
    const translations = getTranslations();

    if (file && file.name) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
          <span style={{ paddingRight: '1rem' }}>{translations.importContentFrom}</span>
          <b>{file.name}</b>
        </div>
      );
    }

    return null;
  };

  const handleNewFolderDocument = (
    type: (typeof NEW_TYPES)[keyof typeof NEW_TYPES],
    values: Pick<CreateObjectParams, 'name' | 'description'> & {
      id: CreateObjectParams['parent'];
      file?: File;
    },
  ) => {
    const currentType = current.type;
    const parameters: CreateObjectParams & Pick<typeof values, 'file'> = {
      name: '',
      description: '',
    };

    if (currentType === 'space') {
      parameters.name = values.name;
      parameters.description = values.description;
      parameters.space = values.id;
    } else {
      parameters.name = values.name;
      parameters.description = values.description;
      parameters.parent = values.id;
    }

    switch (type) {
      case NEW_TYPES.FOLDER: {
        dispatch(createObject({ identity, parameters, type: 'folder' }));
        break;
      }

      case NEW_TYPES.DOCUMENT: {
        // TODO: verify if is an imported a file
        if (values.file && values.file.name) {
          parameters.file = values.file;
          dispatch(
            importFile({
              identity,
              parameters: { ...parameters, file: parameters.file },
              objectType: 'document',
            }),
          );
        } else {
          parameters.pageSize = pageSize;
          parameters.pageOrientation = pageOrientation;
          dispatch(createObject({ identity, parameters, type: 'document' }));
        }
        break;
      }

      case NEW_TYPES.FILE: {
        dispatch(createObject({ identity, parameters, type: 'file' }));
        break;
      }

      default:
        break;
    }

    close();
  };

  const translations = getTranslations();
  return (
    <Modal width="75rem" open={!!isOpen} onClose={close} testId="create-object">
      <Modal.Header onClose={close}>{translations.title}</Modal.Header>
      <Modal.Body>
        <Section
          title={
            newType === NEW_TYPES.DOCUMENT && !file?.name
              ? intl.formatMessage({ id: 'DOCUMENT_GENERAL_INFORMATION' })
              : undefined
          }
        >
          <InputField
            label={translations.inputTitle}
            size="large"
            feedback={validations.name}
            testId="create-object-modal-title"
          >
            <Input
              id="storage.modals.create.input"
              ref={inputRef}
              size="large"
              value={name}
              onChange={onNameChanged}
              placeholder={translations.inputPlaceholder ?? ''}
              error={!!validations.name}
              onEnterKey={validations.name === '' ? handleOnClickNew : undefined}
              testId="create-object-modal-title"
            />
          </InputField>
          <InputField
            label={translations.description}
            feedback={validations.description}
            size="large"
            testId="create-object-modal-description-textarea"
          >
            <TextArea
              size="large"
              placeholder={translations.descriptionPlaceholder}
              value={description}
              onChange={onDescriptionChanged}
              error={!!validations.description}
              testId="create-object-modal-description-textarea"
            />
          </InputField>
        </Section>
        {newType === NEW_TYPES.DOCUMENT && !file?.name && (
          <Section title={intl.formatMessage({ id: 'DOCUMENT_PAGES' })} margin="1rem 0 0 0">
            <PageLayoutField
              type="size"
              fullWidth
              testId={{
                inputField: 'create-object-modal-page-size-field',
                select: 'create-object-modal-page-size-select',
              }}
              onChange={(option) => {
                setPageSize(option.value as PageSize);
              }}
            />
            <InputField
              size="large"
              label={intl.formatMessage({ id: 'PAGE_ORIENTATION' })}
              testId="create-object-modal-page-orientation-field"
            >
              <RadioButton
                size="small"
                onChange={() => {
                  setPageOrientation('portrait');
                }}
                checked={pageOrientation === 'portrait'}
                testId="create-object-modal-radio-button-portrait"
                margin="0 0 1rem 0"
              >
                {intl.formatMessage({ id: 'PORTRAIT' })}
              </RadioButton>
              <RadioButton
                size="small"
                onChange={() => {
                  setPageOrientation('landscape');
                }}
                checked={pageOrientation === 'landscape'}
                testId="create-object-modal-radio-button-landscape"
              >
                {intl.formatMessage({ id: 'LANDSCAPE' })}
              </RadioButton>
            </InputField>
          </Section>
        )}
        {renderTemplateFileElement()}
      </Modal.Body>
      <Modal.Footer alignment={file?.name ? 'space-between' : 'flex-end'}>
        {file && file.name && (
          <Button
            size="medium"
            style={{ position: 'relative' }}
            accept=".docx, .html, .htm"
            onChange={handleOnChangeImportContent}
            upload
            testId="create-object-import-button"
          >
            <FormattedMessage id={translations.importOtherContent} />
          </Button>
        )}
        <Button
          size="medium"
          variant="primary"
          onClick={handleOnClickNew}
          disabled={!!validations.name || !!validations.description || name === ''}
          testId="create-object-submit-button"
        >
          <FormattedMessage id={translations.createButton} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewFolderDocumentModal;
