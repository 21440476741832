import { useState, useRef, useLayoutEffect, useEffect, ChangeEventHandler } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Logo, Tooltip, Button, Input, usePopper, Popover } from 'dodoc-design-system';
import { uniq } from 'lodash';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { renameObject, selectIsIEnvision } from 'App/redux/appSlice';
import {
  selectReadOnlyMode,
  getDocumentObject,
  setRenamingDocumentValue,
  setVersionHistoryValue,
  selectUser,
} from 'Editor/redux/EditorStatusSlice';
import { navigateToMyFiles } from 'router/history';

import { UserPopover, NotificationsCenter, Status } from '_common/components';
import { EditorAvatar } from 'Editor/components';

import styles from './Title.module.scss';

const Title = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isiEnvision = useSelector(selectIsIEnvision);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const objDocument = useSelector(getDocumentObject);
  const user = useSelector(selectUser);
  const usersOnline = useSelector((state) => state.editor.status.usersOnline);
  const isRenamingDocument = useSelector((state) => state.editor.status.renamingDocument);
  const versionHistory = useSelector((state) => state.editor.status.versionHistory);
  const information = useSelector((state) => state.app.information);

  const [name, setName] = useState(objDocument.name);
  const [inputWidth, setInputWidth] = useState(80);
  const [validations, setValidations] = useState<{ name?: string }>({});
  const [hover, setHover] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  //@ts-expect-error ThirdPartyActions schema doesn't have a solid type
  const canExport = information.actions?.editor?.export;
  const { popperProps, referenceProps, close } = usePopper({
    placement: 'bottom-end',
    //@ts-expect-error ThirdPartyActions schema doesn't have a solid type
    disabled: information.actions && !information.actions.editor.profile.access,
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useLayoutEffect(() => {
    const length = objDocument.name.length;
    if (length > 0 && inputRef) {
      if (inputRef.current) {
        if (inputRef.current.scrollWidth > inputRef.current.clientWidth) {
          setInputWidth(inputRef.current.scrollWidth + 2);
          return;
        }
        if (inputRef.current && inputRef.current.style.width !== '10rem') {
          setInputWidth(inputRef.current.scrollWidth - 6);
          return;
        }
      }
      setInputWidth(
        objDocument.name === objDocument.name.toUpperCase() ? (length + 4) * 10 : (length + 3) * 8,
      );
      return;
    }
    setInputWidth(80);
  }, [objDocument.name]);

  const handleDisabledShareButton = () => {
    const conditionsToEnableShare = ['admin', 'owner', 'add_permission', 'remove_permission'];

    if (
      objDocument.user_permissions &&
      !conditionsToEnableShare.some((permission) =>
        objDocument.user_permissions.includes(permission),
      )
    ) {
      return true;
    }

    return false;
  };

  const handleCollaborateClicked = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ShareModal',
        data: {
          view: 'users',
          objectId: objDocument.id,
          objectType: objDocument.type,
          editor: true,
        },
      }),
    );
  };

  const handleOpenExportModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ExportDocumentModal',
        data: {
          exportType: 'simple',
        },
      }),
    );
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const inputName = e.target.value;
    setName(inputName);
    const substring = /[<>"\\:*?/|]/;
    const newValidations: typeof validations = {};

    if (inputName.length === 0) {
      newValidations.name = intl.formatMessage({
        id: 'validation.name.insertName',
      });
    } else if (substring.test(inputName)) {
      newValidations.name = intl.formatMessage({ id: 'RENAME_INPUT_ERROR' });
    } else if (inputName.length > 146) {
      newValidations.name = intl.formatMessage({ id: 'DOCUMENT_TITLE_LIMIT_CHARACTER' });
    }

    if (Object.keys(newValidations).length > 0) {
      setValidations({ ...newValidations });
    } else {
      setValidations({});
    }
  };

  const handleRename = () => {
    if (Object.keys(validations).length > 0) {
      dispatch(setRenamingDocumentValue(false));
      setValidations({});
      setName(objDocument.name);
      setHover(false);
    } else {
      if (name !== objDocument.name) {
        dispatch(
          renameObject({ objectId: objDocument.id, objectType: objDocument.type, newName: name }),
        );
      }
      dispatch(setRenamingDocumentValue(false));
      setValidations({});
      setHover(false);
    }
  };

  const onMouseOver = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  const canRenameDocument = () => {
    if (isReadOnlyMode || isiEnvision) {
      return false;
    }
    return true;
  };

  const closeVersionHistory = () => {
    dispatch(setVersionHistoryValue(false));
    EditorManager.getInstance().backToCurrentVersion();
  };

  const handleOpenRenameDocument = () => {
    if (canRenameDocument()) {
      dispatch(setRenamingDocumentValue(true));
    }
  };

  const handleLogoClick = () => {
    if (!isiEnvision) {
      navigateToMyFiles();
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <Tooltip
          content={intl.formatMessage({ id: 'GO_BACK_EXPLORER' })}
          placement="bottom"
          disabled={isiEnvision}
          testId="go-back-to-tooltip"
        >
          <span
            onClick={handleLogoClick}
            className={isiEnvision ? undefined : styles.clickableLogo}
          >
            <Logo expanded testId="editor-title-logo" />
          </span>
        </Tooltip>
        <div
          className={`${isRenamingDocument ? styles.input : styles.text}`}
          onClick={handleOpenRenameDocument}
        >
          {isRenamingDocument ? (
            <Tooltip
              content={validations.name || ''}
              disabled={!validations.name}
              testId="rename-validations-tooltip"
            >
              <div
                style={{
                  width: `${inputWidth}px`,
                  minWidth: '10rem',
                  maxWidth: '50rem',
                }}
              >
                <Input
                  name="name"
                  ref={inputRef}
                  value={name}
                  disabled={isReadOnlyMode}
                  onChange={handleOnChange}
                  onEnterKey={handleRename}
                  onBlur={handleRename}
                  error={!!validations.name}
                  size="medium"
                  placeholder=""
                  testId="title-name"
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              content={intl.formatMessage({ id: 'RENAME_DOCUMENT' })}
              visible={canRenameDocument() && hover === true}
              testId="rename-document-tooltip"
            >
              <div
                title={objDocument ? objDocument.name : ''}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                className={styles.name}
                style={{ width: `${inputWidth}px`, minWidth: '10rem', maxWidth: '50rem' }}
              >
                {objDocument && objDocument.name}
              </div>
            </Tooltip>
          )}
        </div>
        <Status id={objDocument.status} size="small" testId="editor-status-label" />
        {isiEnvision && (
          <div className={styles.iEnvision}>
            {intl.formatMessage({ id: 'OPENED_THROUGH_IENVISION' })}
          </div>
        )}
      </div>
      {versionHistory ? (
        <div className={styles.buttons}>
          <Button
            size="medium"
            variant="primary"
            onClick={closeVersionHistory}
            icon="GoBackBlue"
            testId="back-to-editor-button"
          >
            <FormattedMessage id="BACK_TO_EDITOR" />
          </Button>
        </div>
      ) : (
        <div className={styles.buttons}>
          <div className={styles.usersOnline}>
            {uniq(usersOnline)
              .filter((userId) => userId && `${userId}` !== `${user.id}`)
              .map((userId) => (
                <EditorAvatar showTooltip key={userId} margin="0 0 0 0.5rem" userId={userId} />
              ))}
          </div>
          <Button
            size="medium"
            variant="primary"
            disabled={handleDisabledShareButton()}
            onClick={handleCollaborateClicked}
            icon="Share"
            testId="share-button"
          >
            <FormattedMessage id="storage.actionBar.actions.share" />
          </Button>
          <Tooltip
            content={intl.formatMessage({
              id: 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION',
            })}
            disabled={!information.actions}
            testId="export-no-permission-tooltip"
          >
            <div>
              <Button
                size="medium"
                style={{ margin: '0 2rem 0 1rem' }}
                onClick={handleOpenExportModal}
                icon="Document"
                disabled={information.actions && !canExport}
                testId="export-button"
              >
                <FormattedMessage id="global.export" />
              </Button>
            </div>
          </Tooltip>
          <NotificationsCenter testId="editor-notifications" />
          <>
            <div {...referenceProps} data-testid="user-popover-toggle">
              <EditorAvatar userId={user.id} />
            </div>
            <Popover {...popperProps} testId="user-profile-popper">
              <UserPopover toggle={close} />
            </Popover>
          </>
        </div>
      )}
    </div>
  );
};

export default Title;
