import { ReactNode } from 'react';
import { ContextMenu as Menu, hideMenu } from 'react-contextmenu';
import styles from './ContextMenu.module.scss';

type ContextMenuProps = {
  id: string;
  className?: string;
  children: ReactNode;
  onShow?: () => void;
  onHide?: () => void;
};

const ContextMenu = ({ children, id, className, onShow, onHide }: ContextMenuProps) => {
  const handleOnShow = () => {
    document.addEventListener('keydown', hideMenu);
    onShow?.();
  };

  const handleOnHide = () => {
    document.removeEventListener('keydown', hideMenu);
    onHide?.();
  };

  return (
    <Menu
      id={id}
      onHide={handleOnHide}
      onShow={handleOnShow}
      className={`${styles.menu} ${className}`}
    >
      <div id={id}>{children}</div>
    </Menu>
  );
};

export default ContextMenu;
