import { Icon } from 'dodoc-design-system';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

export type NodeIconProps = {
  disabled?: boolean;
  object: doDOC.BaseObject | doDOC.Space | Objekt;
};

const FILE_VARIATIONS_EXTENSIONS: { extensions: string[]; icon: IconTypes[32] }[] = [
  {
    extensions: ['.pdf'],
    icon: 'FilePDFGrey',
  },
  {
    extensions: [
      '.ics',
      '.csv',
      '.323',
      '.uls',
      '.txt',
      '.rtx',
      '.sct',
      '.tsv',
      '.htt',
      '.htc',
      '.etx',
      '.vcf',
    ],
    icon: 'FileText',
  },
  {
    extensions: [
      '.zip',
      '.rar',
      '.tar',
      '.sit',
      '.shar',
      '.gz',
      '.gtar',
      '.tgz',
      '.z',
      '.lzh',
      '.lha',
      '.arc',
      '.bz',
      '.bz2',
      '.jar',
      '.7z',
    ],
    icon: 'FileZip',
  },
  {
    extensions: ['.latex', '.tex', '.texi', '.texinfo'],
    icon: 'FileLatex',
  },
  {
    extensions: [
      '.mp2',
      '.mpa',
      '.mpe',
      '.mpeg',
      '.mpg',
      '.mpv2',
      '.mp4',
      '.mov',
      '.qt',
      '.lsf',
      '.lsx',
      '.asf',
      '.asr',
      '.asx',
      '.avi',
      '.movie',
      '.ogg',
      '.ts',
      '.webm',
      '.3gp',
      '.3g2',
    ],
    icon: 'FileVideo',
  },
  {
    extensions: [
      '.avif',
      '.bmp',
      '.cod',
      '.gif',
      '.ief',
      '.jpe',
      '.jpeg',
      '.jpg',
      '.jfif',
      '.svg',
      '.tif',
      '.tiff',
      '.ras',
      '.cmx',
      '.ico',
      '.png',
      '.pnm',
      '.pbm',
      '.pgm',
      '.ppm',
      '.rgb',
      '.webp',
      '.xbm',
      '.xpm',
      '.xwd',
    ],
    icon: 'ImageGrey',
  },
  {
    extensions: ['.css', '.htm', '.html', '.stm', '.xhtml'],
    icon: 'FileHTML',
  },
  {
    extensions: ['.eot', '.ttf', '.woff', '.woff2', '.etx', '.otf'],
    icon: 'FileFont',
  },
  {
    extensions: [
      '.j',
      '.java',
      '.js',
      '.py',
      '.pyc',
      '.php',
      '.c',
      '.cpp',
      '.cc',
      '.matlab',
      '.m',
      '.rb',
      '.SQL',
      '.bas',
      '.c',
      '.h',
    ],
    icon: 'FileCode',
  },
  {
    extensions: ['.dwg', '.dxf', '.dwf', '.dgn', '.stl'],
    icon: 'FileCad',
  },
  {
    extensions: ['.doc', '.dot', '.word', '.odt', '.rtf', '.pages'],
    icon: 'DocumentGrey',
  },
  {
    extensions: ['.xls', '.csv', '.numbers'],
    icon: 'FileSpreadsheet',
  },
  {
    extensions: ['.ppt', '.keynote'],
    icon: 'FilePresentations',
  },
];

const FILE_VARIATIONS_MIME_TYPE: { mimeTypes: string[]; icon: IconTypes[32] }[] = [
  {
    mimeTypes: ['application/pdf'],
    icon: 'FilePDFGrey',
  },
  {
    mimeTypes: [
      'text/calendar',
      'text/csv',
      'text/h323',
      'text/iuls',
      'text/richtext',
      'text/scriptlet',
      'text/tab-separated-values',
      'text/webviewhtml',
      'text/x-component',
      'text/x-setext',
      'text/x-vcard',
    ],
    icon: 'FileText',
  },
  {
    mimeTypes: [
      'application/zip',
      'application/vnd.rar',
      'application/x-tar',
      'application/x-stuffit',
      'application/x-shar',
      'application/x-gzip',
      'application/x-gtar',
      'application/x-compressed',
      'application/x-compress',
      'application/x-freearc',
      'application/x-bzip',
      'application/x-bzip2',
      'application/java-archive',
      'application/x-7z-compressed',
    ],
    icon: 'FileZip',
  },
  {
    mimeTypes: ['application/x-latex', 'application/x-tex', 'application/x-texinfo'],
    icon: 'FileLatex',
  },
  {
    mimeTypes: [
      'video/mpeg',
      'video/mp4',
      'video/quicktime',
      'video/x-la-asf',
      'video/x-ms-asf',
      'video/x-msvideo',
      'video/x-sgi-movie',
      'video/ogg',
      'video/mp2t',
      'video/webm',
      'video/3gpp',
      'video/3gpp2',
    ],
    icon: 'FileVideo',
  },
  {
    mimeTypes: [
      'image/avif',
      'image/bmp',
      'image/cis-cod',
      'image/gif',
      'image/ief',
      'image/jpeg',
      'image/pipeg',
      'image/svg+xml',
      'image/tiff',
      'image/x-cmu-raster',
      'image/x-cmx',
      'image/x-icon',
      'image/png',
      'image/x-portable-anymap',
      'image/x-portable-bitmap',
      'image/x-portable-graymap',
      'image/x-portable-pixmap',
      'image/x-rgb',
      'image/webp',
      'image/x-xbitmap',
      'image/x-xpixmap',
      'image/x-xwindowdump',
    ],
    icon: 'ImageGrey',
  },
  {
    mimeTypes: ['text/css', 'text/html'],
    icon: 'FileHTML',
  },
  {
    mimeTypes: [
      'application/vnd.ms-fontobject',
      'font/ttf',
      'font/woff',
      'font/woff2',
      'text/x-setext',
      'font/otf',
      'application/font-sfnt',
    ],
    icon: 'FileFont',
  },
  {
    mimeTypes: [
      'text/x-java-source',
      'application/x-javascript',
      'text/x-script.phyton',
      'application/x-bytecode.python',
      'application/x-httpd-php',
      'text/x-c',
      'text/x-m',
    ],
    icon: 'FileCode',
  },
  {
    mimeTypes: [
      'image/x-dwg',
      'image/x-dxf',
      'drawing/x-dwf',
      'application/dgn',
      'application/vnd.ms-pki.stl',
    ],
    icon: 'FileCad',
  },
  {
    mimeTypes: [
      'application/msword',
      'application/vnd.oasis.opendocument.text',
      'application/rtf',
      'application/vnd.apple.pages',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    icon: 'DocumentGrey',
  },
  {
    mimeTypes: [
      'application/vnd.ms-excel',
      'text/csv',
      'application/vnd.apple.numbers',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    icon: 'FileSpreadsheet',
  },
  {
    mimeTypes: [
      'vnd.ms-powerpoint',
      'application/vnd.apple.keynote',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    icon: 'FilePresentations',
  },
];

const NodeIcon = ({ object, disabled }: NodeIconProps) => {
  // --------------------------------------
  if (object.type === 'file' && object.wopi?.actions.length) {
    const app = object.wopi.actions[0].app;
    const getIconName = () => {
      if (app === 'Excel') {
        return 'FileExcel';
      } else if (app === 'PowerPoint') {
        return 'FilePowerPoint';
      } else {
        return 'FileWord';
      }
    };

    return <Icon size={32} icon={getIconName()} disabled={disabled} />;
  }

  // --------------------------------------
  // ---------- icon selection ------------
  let nodeMimeType: string;

  if (object.type === 'file' && object.mime) {
    nodeMimeType = object.mime.type;
  }

  const getIcon = (): IconTypes['32'] => {
    switch (object.type) {
      case 'dopdf':
        return 'FilePDFRed';
      case 'space':
        return 'Spaces';
      case 'folder':
        if (object.status === 'broken') {
          return 'BrokenFolder';
        } else if (object.status === 'approved') {
          return 'FolderApproved';
        } else {
          return 'Folder';
        }
      case 'document':
        if (object.status === 'broken') {
          return 'BrokenDocument';
        } else if (object.status === 'approved') {
          return 'Approved';
        } else {
          return 'Document';
        }
      case 'file': {
        switch (nodeMimeType) {
          case 'application/octet-stream':
          case 'text/plain':
            return (
              FILE_VARIATIONS_EXTENSIONS.find((variation) =>
                variation.extensions.some((extension) => object.name.endsWith(extension)),
              )?.icon ?? 'File'
            );
          default:
            return (
              FILE_VARIATIONS_MIME_TYPE.find((variation) =>
                variation.mimeTypes.some((mimeType) => mimeType === nodeMimeType),
              )?.icon ?? 'File'
            );
        }
      }
      default:
        return 'File';
    }
  };

  return <Icon size={32} icon={getIcon()} disabled={disabled} />;
};

export default NodeIcon;
