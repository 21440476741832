import uuid from 'uuid/v4';
import { uniq } from 'lodash';
import { Logger } from '_common/services';
import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseTypedEmitter } from '_common/services/Realtime';
import { EditorDOMUtils, EditorDOMElements } from '../_Common/DOM';
import {
  EditorRange,
  EditorSelectionUtils,
  SelectionFixer,
} from 'Editor/services/_Common/Selection';

const WRAPING_ANCESTORS = [
  'COMMENT-ELEMENT',
  'TEMP-COMMENT-ELEMENT',
  ELEMENTS.HyperlinkElement.TAG,
  ELEMENTS.ParagraphElement.TAG,
  'SPAN',
  'FORMAT-ELEMENT',
  ELEMENTS.FigureElement.TAG,
  // 'CITATION-ELEMENT',
  // 'CITATIONS-GROUP-ELEMENT',
  'CROSS-REFERENCE-ELEMENT',
  'NOTE-ELEMENT',
  'SYMBOL-ELEMENT',
  'EQUATION-ELEMENT',
  // ELEMENTS.TableCellElement.TAG,
];

/**
 * @deprecated
 */
export default class SelectionManager extends BaseTypedEmitter {
  static SELECTION_CONTENT_UPDATED = 'SELECTION_CONTENT_UPDATED';

  constructor(editorContext) {
    super();

    this.editorContext = editorContext;
    this.page = editorContext.documentContainer;

    this.selectionContentUpdateDebounce = null;
    this._mouseDown = false;
    // rangy.init();
    this.trackSelection = true;

    // =============== selection log
    this.markersLimit = 100;
    this.selectionMarkers = [];
    this.selectionSerialized = [];
    this.selectionTimeline = [];

    this._selectionChangeCounts = 0;

    // move selection utilities
    this._modifySelection = {};
  }

  destroy() {
    if (this.selectionContentUpdateDebounce) {
      clearTimeout(this.selectionContentUpdateDebounce);
    }

    super.destroy();
  }

  /**
   * @deprecated
   */
  getRange() {
    // const range = rangy.getSelection().getRangeAt(0);
    const range = EditorSelectionUtils.getRange();
    if (this.page.contains(range.commonAncestorContainer)) {
      return range;
    }
    return this.lastRange;
  }

  get isMouseDown() {
    return this._selectionTrackerRunning && this._mouseDown;
  }

  /**
   * @deprecated
   */
  getSelection() {
    return window.getSelection();
  }

  /**
   * @deprecated
   */
  isSelectionInPage() {
    try {
      if (this.page.contains(document.activeElement)) {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          if (this.page.contains(range?.commonAncestorContainer)) {
            return true;
          }
        }
      }
      return false;
    } catch (error) {
      Logger.captureException(error);
      return false;
    }
  }

  /**
   * @deprecated
   */
  collides(nodeId) {
    const node = DOMUtils.getNode(nodeId, this.page);
    const range = EditorSelectionUtils.getRange();
    if (node && range) {
      return (
        range.containsNode(node) ||
        range.instersectsNode(node) ||
        node.contains(range.commonAncestorContainer)
      );
    }
    return false;
  }

  // ? SELECTION MARKER

  /**
   * @deprecated
   */
  isCurrentSelectionEditable(range = EditorSelectionUtils.getRange()) {
    if (range.collapsed) {
      const pageNode = DOMUtils.getPageNode(range.startContainer);
      const parentLevel0 = DOMUtils.findNodeLevel0(pageNode, range.startContainer);
      // Commented because it broke enters on references
      // BUG-1357
      // const closestNonEditableTextElement = DOMUtils.closest(
      //   node,
      //   DOMUtils.INLINE_NON_EDITABLE_ELEMENTS,
      // );

      if (
        // BUG-1357
        // !closestNonEditableTextElement &&
        parentLevel0 &&
        DOMUtils.isBlockNodeEditable(parentLevel0)
      ) {
        return true;
      }
    } else {
      const nodes = this.getSelectedLevel0Nodes(range);

      for (let i = 0; i < nodes.length; i += 1) {
        if (!DOMUtils.isBlockNodeEditable(nodes[i])) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  /**
   * @deprecated
   */
  isCurrentSelectionDeletable(range = EditorSelectionUtils.getRange()) {
    if (range.collapsed) {
      const pageNode = DOMUtils.getPageNode(range.startContainer);
      const parentLevel0 = DOMUtils.findNodeLevel0(pageNode, range.startContainer);

      if (parentLevel0 && DOMUtils.isBlockNodeDeletable(parentLevel0)) {
        return true;
      }
    } else {
      const nodes = this.getSelectedLevel0Nodes(range);

      for (let i = 0; i < nodes.length; i += 1) {
        if (!DOMUtils.isBlockNodeDeletable(nodes[i])) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  /**
   * @deprecated
   */
  isCurrentSelectionApproved(range = EditorSelectionUtils.getRange()) {
    if (range.collapsed) {
      const pageNode = DOMUtils.getPageNode(range.startContainer);
      const parentLevel0 = DOMUtils.findNodeLevel0(pageNode, range.startContainer);

      if (parentLevel0?.tagName === ELEMENTS.ApprovedElement.TAG) {
        return true;
      }
    } else {
      const nodes = this.getSelectedLevel0Nodes(range);

      for (let i = 0; i < nodes.length; i += 1) {
        if (nodes[i].tagName === ELEMENTS.ApprovedElement.TAG) {
          return true;
        }
      }
    }

    return false;
  }

  // ? CARET METHODS
  /**
   * @deprecated
   */
  setCaretToEnd(node) {
    return this.setCaret(node, 'POST');
  }

  /**
   * @deprecated
   */
  setCaretAtEnd(node) {
    return this.setCaret(node, 'END');
  }

  /**
   * set range start and end to node position
   * @param {Node} node
   * @param {String} position
   * @param {*} offset
   * @deprecated
   */
  setCaret(node, position = 'START', offset = 0) {
    const range = EditorSelectionUtils.getRange();
    if (range.setCaret(node, position, offset)) {
      EditorSelectionUtils.applyRangeToSelection(range);
      return true;
    }
    return false;
  }

  /**
   * set range start to node position
   * @param {Node} node
   * @param {String} position
   * @param {*} offset
   * @deprecated
   */
  setRangeStart(node, position = 'START', offset = 0) {
    const range = EditorSelectionUtils.getRange();
    if (range.setRangeStart(node, position, offset)) {
      EditorSelectionUtils.applyRangeToSelection(range);
      return true;
    }
    return false;
  }

  /**
   * set range end to node position
   * @param {Node} node
   * @param {String} position
   * @param {*} offset
   * @deprecated
   */
  setRangeEnd(node, position = 'START', offset = 0) {
    const range = EditorSelectionUtils.getRange();
    if (range.setRangeEnd(node, position, offset)) {
      EditorSelectionUtils.applyRangeToSelection(range);
      return true;
    }
    return false;
  }

  /**
   * fix page selection
   * @param {Boolean} fixSelectionForward
   * @deprecated
   */
  fixSelection(range = EditorSelectionUtils.getRange(), forceApply = false) {
    const updated = SelectionFixer.fixSelection(range, {});
    if (updated || forceApply) {
      EditorSelectionUtils.applyRangeToSelection(range);
    }
    return updated;
  }

  /**
   * @deprecated
   */
  fixNonCollapsedTextSelection(options = {}) {
    let thisRange = options.range
      ? EditorRange.fromNativeRange(options.range)
      : EditorSelectionUtils.getRange();

    let applyToSelection = options.applyToSelection != null ? options.applyToSelection : true;

    SelectionFixer.nonCollapsedTextSelection(thisRange, options);

    if (applyToSelection) {
      EditorSelectionUtils.applyRangeToSelection(thisRange);
    }

    return thisRange;
  }

  /**
   * fix a collapsed selection to the closest text node from right to left;
   * @param options { suggestionMode }
   * @deprecated
   */
  fixCollapsedTextSelection(options = {}) {
    let thisRange = options.range
      ? EditorRange.fromNativeRange(options.range)
      : EditorSelectionUtils.getRange();

    let applyToSelection = options.applyToSelection != null ? options.applyToSelection : true;

    SelectionFixer.collapsedTextSelection(thisRange, options);

    if (applyToSelection) {
      EditorSelectionUtils.applyRangeToSelection(thisRange);
    }

    return thisRange;
  }

  /**
   * @deprecated
   */
  isSelectionAtStart(node, container = 'start', range = EditorSelectionUtils.getRange()) {
    return EditorRange.fromNativeRange(range).isAtNodeStart(node, container);
  }

  /**
   * @deprecated
   */
  isSelectionAtEnd(node, container = 'end', range = EditorSelectionUtils.getRange()) {
    return EditorRange.fromNativeRange(range).isAtNodeEnd(node, container);
  }

  /**
   * This function selects a node in the doDOC page
   * @param {DOMNode} node
   * @deprecated
   */
  selectNode(node) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNode(node);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  selectLevel0Node(node) {
    const pageNode = DOMUtils.getPageNode(node);
    if (node.parentNode !== pageNode) {
      const closestContainer = DOMUtils.closestMultiBlockContainerElement(node);
      if (closestContainer) {
        if (
          closestContainer.tagName === ELEMENTS.TrackInsertElement.TAG ||
          closestContainer.tagName === ELEMENTS.TrackDeleteElement.TAG
        ) {
          node = closestContainer;
        } else {
          node = DOMUtils.findNodeLevel0(closestContainer, node);
        }
      } else {
        node = DOMUtils.findNodeLevel0(pageNode, node);
      }
    }
    this.selectNode(node);
  }

  isSelectionCollapsed() {
    const selection = window.getSelection();
    return selection.isCollapsed;
  }

  isSelectionBackwards() {
    const sel = window.getSelection();
    const position = sel.anchorNode.compareDocumentPosition(sel.focusNode);
    return (
      (!position && sel.anchorOffset > sel.focusOffset) ||
      position === Node.DOCUMENT_POSITION_PRECEDING
    );
  }

  getSelectedText() {
    return EditorSelectionUtils.getRange().toString();
  }

  /**
   * @deprecated
   */
  isTableSelected() {
    return DOMUtils.closest(
      EditorSelectionUtils.getRange().commonAncestorContainer,
      ELEMENTS.TableElement.TAG,
    );
  }

  getNodes() {
    if (this.isSelectionCollapsed()) {
      return [this.getSelection().anchorNode];
    }
    return EditorSelectionUtils.getRange().getNodes();
  }

  getSelectedNodes(range = EditorSelectionUtils.getRange()) {
    let selectedNodes = [];
    if (!range) {
      return selectedNodes;
    }
    const nodes = Array.prototype.slice.call(
      this.page.querySelectorAll(`${ELEMENTS.TableCellElement.TAG}[data-selected="true"]`),
    );
    if (nodes.length) {
      nodes.forEach((node) => {
        selectedNodes.push(node);
      });
    } else if (range.collapsed) {
      selectedNodes.push(range.startContainer);
    } else {
      const thisRange = range.cloneRange();

      const startTD = DOMUtils.closest(thisRange.startContainer, ELEMENTS.TableCellElement.TAG);
      const endTD = DOMUtils.closest(thisRange.endContainer, ELEMENTS.TableCellElement.TAG);

      if (startTD && endTD && startTD !== endTD && this.isSelectionAtStart(endTD, 'end')) {
        if (endTD.previousSibling) {
          // previous TD
          const targetTD = endTD.previousSibling;
          thisRange.setEnd(targetTD, targetTD.childNodes.length);
        } else if (endTD.parentNode.previousSibling) {
          // previous TR
          const targetTD = endTD.parentNode.previousSibling;
          thisRange.setEnd(targetTD, targetTD.childNodes.length);
        }
      }

      selectedNodes = thisRange.getNodes();
    }
    return selectedNodes;
  }

  /**
   * @deprecated
   */
  getSelectedCells() {
    const nodes = this.getSelectedNodes();
    const cells = [];
    const tables = [];
    nodes.forEach((node) => {
      const pageNode = DOMUtils.getPageNode(node);
      while (node !== pageNode && pageNode.contains(node)) {
        if (node.tagName === ELEMENTS.TableCellElement.TAG && cells.indexOf(node) < 0) {
          cells.push(node);
        }
        if (node.tagName === ELEMENTS.TableElement.TAG && tables.indexOf(node) < 0) {
          tables.push(node);
          break;
        }
        node = node.parentNode;
      }
    });
    if (tables.length > 1) {
      return [];
    }
    return cells;
  }

  /**
   * @deprecated
   */
  getSelectedRows() {
    const nodes = this.getSelectedNodes();
    const rows = [];
    const tables = [];
    nodes.forEach((node) => {
      const pageNode = DOMUtils.getPageNode(node);
      while (node !== pageNode && pageNode.contains(node)) {
        if (
          node.tagName === ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG &&
          rows.indexOf(node) < 0
        ) {
          rows.push(node);
        }
        if (node.tagName === ELEMENTS.TableElement.TAG && tables.indexOf(node) < 0) {
          tables.push(node);
          break;
        }
        node = node.parentNode;
      }
    });
    if (tables.length > 1) {
      return [];
    }
    return rows;
  }

  /**
   * @deprecated use EditorSelectionUtils
   */
  getSelectedTableElements() {
    const elements = {
      selectedTable: null,
      selectedRows: [],
      columnsIndex: [],
      selectedCells: [],
    };
    // Get TD, TR and TABLE from the anchor node of the current selection
    const startContainer = EditorSelectionUtils.getRange().startContainer;
    const td = DOMUtils.closest(startContainer, ELEMENTS.TableCellElement.TAG);
    if (td) {
      const row = td.parentNode;
      const table = row.closest(ELEMENTS.TableElement.TAG);
      if (table) {
        elements.selectedTable = table;
        // Query the table for the selected TDs
        const cells = table.getSelectedCells();
        // If none, it means only one cell is selected and use the original TD
        if (cells.length === 0) {
          elements.selectedRows = [row];
          elements.columnsIndex = [td.cellIndex];
          elements.selectedCells = [td];
          return elements;
        }
        // Use the TDs selected from the data-selected attribute
        cells.forEach((cell) => {
          elements.selectedCells.push(cell);
          if (!elements.selectedRows.includes(cell.parentNode)) {
            elements.selectedRows.push(cell.parentNode);
          }
          if (!elements.columnsIndex.includes(cell.cellIndex)) {
            elements.columnsIndex.push(cell.cellIndex);
          }
        });
        return elements;
      }
    }
    // If no TD and TABLE was found, return null
    return null;
  }

  static getTextNodesIn(thisNode) {
    const textNodes = [];
    if (thisNode.nodeType === 3) {
      textNodes.push(thisNode);
    } else {
      let thisDescendant;
      const allDescendants = Array.from(thisNode.childNodes);
      while (allDescendants.length) {
        thisDescendant = allDescendants.shift();
        if (thisDescendant.nodeType === 1) {
          [].forEach.call(thisDescendant.childNodes, (node) => {
            allDescendants.push(node);
          });
        } else if (thisDescendant.nodeType === 3) {
          textNodes.push(thisDescendant);
        }
      }
    }
    return textNodes;
  }

  getLinkData() {
    const selectedNodes = this.getSelectedNodes();
    let allTextNodes = [];
    selectedNodes.forEach((selectedNode) => {
      const theseTextNodes = SelectionManager.getTextNodesIn(selectedNode);
      allTextNodes = allTextNodes.concat(theseTextNodes);
    });
    const links = [];
    allTextNodes.forEach((node) => {
      const pageNode = DOMUtils.getPageNode(node);
      let anchor = node;
      if (anchor) {
        while (anchor.nodeType !== 1 || anchor.tagName !== ELEMENTS.HyperlinkElement.TAG) {
          if (anchor === pageNode) {
            anchor = null;
            break;
          }
          anchor = anchor.parentNode;
        }
      }

      if (anchor && links.indexOf(anchor) < 0) {
        links.push(anchor);
      }
    });
    let url;
    const linksIds = [];
    if (links.length) {
      for (let i = 0; i < links.length; i += 1) {
        if (links[i].id) {
          linksIds.push(links[i].id);
        } else {
          const id = uuid();
          links[i].id = id;
          linksIds.push(id);
        }
        if (url === undefined) {
          url = links[i].href;
        } else if (url !== links[i].href) {
          url = null;
        }
      }
    }
    const theseBlockNodes = [];
    allTextNodes.forEach((textNode) => {
      let thisBlock = textNode.parentNode;
      while (DOMUtils.isInlineNode(thisBlock)) {
        thisBlock = thisBlock.parentNode;
      }
      if (theseBlockNodes.indexOf(thisBlock) < 0) {
        theseBlockNodes.push(thisBlock);
      }
    });
    let showTextToDisplay = true;
    if (theseBlockNodes.length > 1) {
      showTextToDisplay = false;
    }
    const textToDisplay = links.length > 0 && links[0].innerText ? links[0].innerText : '';
    return {
      url,
      textToDisplay,
      showTextToDisplay,
      links: linksIds,
    };
  }

  getSelectedBlockNodes() {
    const nodes = this.getSelectedNodes();
    const blockNodes = [];
    nodes.forEach((node) => {
      const pageNode = DOMUtils.getPageNode(node);
      let block = node;
      while (DOMUtils.isInlineNode(block) && block.parentNode !== pageNode) {
        block = block.parentNode;
      }
      if (blockNodes.indexOf(block) < 0) {
        blockNodes.push(block);
      }
    });
    return blockNodes;
  }

  getSelectedLevel0NodesId(range = EditorSelectionUtils.getRange()) {
    return this.getSelectedLevel0Nodes(range).map((node) => node.id);
  }

  getSelectedLevel0Nodes(range = EditorSelectionUtils.getRange()) {
    let selectedNodes = [];
    if (range) {
      if (range.collapsed) {
        if (range.startContainer) {
          selectedNodes = SelectionManager.getLevel0NodesFromNodes(
            DOMUtils.getPageNode(range.startContainer),
            [range.startContainer],
          );
        }
      } else {
        const nodes = range.getNodes();
        selectedNodes = SelectionManager.getLevel0NodesFromNodes(
          DOMUtils.getPageNode(nodes[0]),
          nodes,
        );
      }
    }

    return selectedNodes;
  }

  static getLevel0NodesFromNodes(page, nodes) {
    return uniq(nodes.map((node) => DOMUtils.findNodeLevel0(page, node))).filter((node) => !!node);
  }

  getSelectedImage() {
    const nodes = this.getSelectedNodes();
    if (nodes.length === 1) {
      const closest = DOMUtils.closest(nodes[0], 'IMAGE-ELEMENT');
      if (closest) {
        return closest;
      }
    }
    return null;
  }

  /**
   * @deprecated use EditorSelectionUtils
   */
  splitInlineTextElements(baseNode, container, offset) {
    if (
      baseNode != null &&
      container != null &&
      offset != null &&
      DOMUtils.isNodeEditableTextElement(baseNode) &&
      baseNode.contains(container) &&
      !DOMUtils.closest(container, DOMUtils.INLINE_NON_EDITABLE_ELEMENTS) &&
      container !== baseNode
    ) {
      let closestText;
      let anchorNode = container;
      let anchorOffset = offset;

      while (
        (closestText = DOMUtils.closest(anchorNode, DOMUtils.INLINE_TEXT_ELEMENTS)) &&
        DOMUtils.parentContainsNode(baseNode, closestText)
      ) {
        if (this.isSelectionAtStart(closestText)) {
          anchorNode = closestText.parentNode;
          anchorOffset = Array.from(closestText.parentNode.childNodes).indexOf(closestText);
        } else if (this.isSelectionAtEnd(closestText)) {
          anchorNode = closestText.parentNode;
          anchorOffset = Array.from(closestText.parentNode.childNodes).indexOf(closestText) + 1;
        } else {
          const copyingRange = EditorSelectionUtils.createNewRange();
          copyingRange.setStart(anchorNode, anchorOffset);
          copyingRange.setEndAfter(closestText);

          const contents = copyingRange.extractContents();
          const nodes = contents.childNodes;

          let i;
          for (i = 0; i < nodes.length; i += 1) {
            nodes[i].removeAttribute('id');
            nodes[i].removeAttribute('parent_id');
            DOMNormalizer.normalizeTree(nodes[i], closestText.parentNode.id);
            DOMUtils.insertNodeAfter(closestText.parentNode, nodes[i], closestText);
          }

          anchorNode = closestText.parentNode;
          anchorOffset = Array.from(closestText.parentNode.childNodes).indexOf(closestText) + 1;
        }
      }

      return {
        anchorNode,
        anchorOffset,
      };
    }

    return {};
  }

  splitRangeByBlocks(
    originalRange = EditorSelectionUtils.getRange(),
    blocksToFilter = DOMUtils.BLOCK_TEXT_ELEMENTS,
    allowNonDeletable = false,
  ) {
    let rangesToSplit = [originalRange];
    let splitedRangesElements = [];

    // check if is inside a table
    const closestTable = DOMUtils.closest(originalRange.commonAncestorContainer, [
      ELEMENTS.TableElement.TAG,
    ]);
    if (closestTable) {
      const selectedCells = closestTable.getSelectedCells();
      if (selectedCells && selectedCells.length > 1) {
        rangesToSplit = Array.from(selectedCells).reduce((acc, cell) => {
          const cellRange = EditorSelectionUtils.createNewRange();
          cellRange.setStart(cell, 0);
          cellRange.setEnd(cell, cell.childNodes.length);
          acc.push(cellRange);
          return acc;
        }, []);
      }
    }

    rangesToSplit.forEach((range) => {
      const rangeElements = this.filterBlocksFromRange(
        range,
        true,
        blocksToFilter,
        allowNonDeletable,
      ).map((block) => {
        const blockRange = EditorSelectionUtils.createNewRange();

        if (block.contains(range.startContainer)) {
          blockRange.setStart(range.startContainer, range.startOffset);
        } else {
          blockRange.setStart(block, 0);
        }

        if (block.contains(range.endContainer)) {
          blockRange.setEnd(range.endContainer, range.endOffset);
        } else {
          blockRange.setEnd(block, block.childNodes.length);
        }

        return {
          block,
          range: blockRange,
        };
      });

      splitedRangesElements = splitedRangesElements.concat(rangeElements);
    });

    return splitedRangesElements;
  }

  /**
   * get all text block nodes from range
   * @param {Range} range
   * @param {Boolean} forceOwnBlock
   */
  filterBlocksFromRange(
    range = EditorSelectionUtils.getRange(),
    forceOwnBlock = false,
    blocksToFilter = DOMUtils.BLOCK_TEXT_ELEMENTS,
    allowNonDeletable = false,
  ) {
    const nodes = range.getNodes(Node.ELEMENT_NODE, (node) => {
      const pageNode = DOMUtils.getPageNode(node);
      return (
        (node.parentNode === pageNode ||
          (DOMUtils.MULTI_BLOCK_CONTAINER_ELEMENTS.includes(node.parentNode.tagName) &&
            range.commonAncestorContainer !== pageNode)) &&
        (DOMUtils.isBlockNodeDeletable(node) || allowNonDeletable) &&
        blocksToFilter.includes(node.tagName)
      );
    });

    if (nodes.length === 0 && forceOwnBlock) {
      const closest = DOMUtils.closestMultiBlockContainerElement(range.commonAncestorContainer);
      if (closest) {
        const level0 = DOMUtils.findNodeLevel0(closest, range.commonAncestorContainer);
        if (
          (DOMUtils.isBlockNodeDeletable(level0) || allowNonDeletable) &&
          blocksToFilter.includes(level0.tagName)
        ) {
          nodes.push(level0);
        }
      } else {
        const textBlock = DOMUtils.closest(range.commonAncestorContainer, blocksToFilter);
        let level0;

        const pageNode = DOMUtils.getPageNode(textBlock);

        if (textBlock) {
          if (textBlock.parentNode === pageNode) {
            level0 = textBlock;
          } else {
            level0 = DOMUtils.findNodeLevel0(pageNode, textBlock);
          }

          if (level0 && (DOMUtils.isBlockNodeDeletable(level0) || allowNonDeletable)) {
            nodes.push(textBlock);
          }
        }
      }
    }

    return (nodes && nodes.filter((node) => node)) || [];
  }

  splitRangeByTableCells(originalRange = EditorSelectionUtils.getRange()) {
    const nodes = originalRange.getNodes(
      [Node.ELEMENT_NODE],
      (node) => node.tagName === ELEMENTS.TableCellElement.TAG,
    );

    const newRanges = [];

    let range;
    let previousCell;

    let i;
    for (i = 0; i < nodes.length; i++) {
      if (nodes[i].tagName === ELEMENTS.TableCellElement.TAG) {
        if (range && previousCell) {
          range.setEnd(previousCell, previousCell.childNodes.length);
          newRanges.push(range);
        }

        range = EditorSelectionUtils.createNewRange();
        previousCell = nodes[i];
        if (nodes[i].contains(originalRange.startContainer)) {
          range.setStart(originalRange.startContainer, originalRange.startOffset);
        } else {
          range.setStart(nodes[i], 0);
        }
      }
    }

    if (range && previousCell && previousCell.contains(originalRange.endContainer)) {
      range.setEnd(originalRange.endContainer, originalRange.endOffset);
      newRanges.push(range);
    }

    if (newRanges.length === 0) {
      newRanges.push(originalRange);
    }

    return newRanges;
  }

  splitRangeByInlineElements(block, range = EditorSelectionUtils.getRange(), inlineElements = []) {
    const rangeList = [];

    if (block && block.contains(range.commonAncestorContainer)) {
      const nodes = range.getNodes([Node.ELEMENT_NODE], (node) =>
        inlineElements.includes(node.tagName),
      );

      let newRange = EditorSelectionUtils.createNewRange();
      newRange.setStart(range.startContainer, range.startOffset);

      let lastRange;

      let i;
      for (i = 0; i < nodes.length; i++) {
        if (lastRange && lastRange.containsNode(nodes[i])) {
          continue;
        } else if (newRange) {
          if (nodes[i].contains(range.startContainer) && nodes[i].contains(range.endContainer)) {
            newRange.setEnd(range.endContainer, range.endOffset);
            if (!newRange.collapsed) {
              lastRange = newRange;
              rangeList.push({
                range: newRange,
                element: nodes[i],
              });
            }
            newRange = null;
          } else if (nodes[i].contains(range.endContainer)) {
            newRange.setEndBefore(nodes[i]);

            if (!newRange.collapsed) {
              lastRange = newRange;
              rangeList.push({
                range: newRange,
              });
            }

            newRange = EditorSelectionUtils.createNewRange();
            newRange.setStart(nodes[i], 0);
            newRange.setEnd(range.endContainer, range.endOffset);
            if (!newRange.collapsed) {
              lastRange = newRange;
              rangeList.push({
                range: newRange,
                element: nodes[i],
              });
            }

            newRange = null;
          } else if (nodes[i].contains(range.startContainer)) {
            newRange.setEnd(nodes[i], nodes[i].childNodes.length);
            if (!newRange.collapsed) {
              lastRange = newRange;
              rangeList.push({
                range: newRange,
                element: nodes[i],
              });
            }
            newRange = EditorSelectionUtils.createNewRange();

            newRange.setStartAfter(nodes[i]);
          } else {
            let nodeToSelect = nodes[i];

            // check for parents with this element as single child
            while (
              nodeToSelect.parentNode !== block &&
              nodeToSelect.parentNode.childNodes.length === 1
            ) {
              nodeToSelect = nodeToSelect.parentNode;
            }

            newRange.setEndBefore(nodeToSelect);

            if (!newRange.collapsed) {
              lastRange = newRange;
              rangeList.push({
                range: newRange,
              });
            }

            newRange = EditorSelectionUtils.createNewRange();
            newRange.setStartBefore(nodeToSelect);
            newRange.setEndAfter(nodeToSelect);
            if (!newRange.collapsed) {
              lastRange = newRange;
              rangeList.push({
                range: newRange,
                element: nodeToSelect,
              });
            }

            newRange = EditorSelectionUtils.createNewRange();

            newRange.setStartAfter(nodeToSelect);
          }
        }
      }

      if (newRange) {
        const closestElement = DOMUtils.closest(range.endContainer, inlineElements);

        newRange.setEnd(range.endContainer, range.endOffset);
        if (!newRange.collapsed) {
          rangeList.push({
            range: newRange,
            element: closestElement,
          });
        }
      }

      if (rangeList.length === 0) {
        rangeList.push({
          range,
        });
      }
    }

    return rangeList;
  }

  /**
   *
   * @param {Node} node
   * @memberof SelectionManager
   */
  collapseToStart(node) {
    if (node) {
      this.selectNode(node);
    }
    window.getSelection().collapseToStart();
  }

  collapseToEnd(node) {
    if (node) {
      this.selectNode(node);
    }
    window.getSelection().collapseToEnd();
  }

  selectAll() {
    // this.selectNodeContents(this.page);
    this.setRangeStart(this.page.firstChild, 'INDEX', 0);
    this.setRangeEnd(this.page.lastChild, 'INDEX', this.page.lastChild.childNodes.length);
  }

  selectNodeContents(node) {
    if (node) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(node);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  splitRange(whiteList, originalRange) {
    if (!originalRange) {
      originalRange = EditorSelectionUtils.getRange();
    }
    // const saved = this.saveRangySelection();
    // originalRange.commonAncestorContainer.normalize();
    // this.restoreRangySelection(saved);
    // originalRange = selection.getRangeAt(0);
    const nodeValidator = (node) => {
      node = node.parentNode;
      while (node && !node.getAttribute('ispagenode')) {
        if (!whiteList.includes(node.tagName)) {
          return false;
        }
        node = node.parentNode;
      }
      return true;
    };
    const deletableTextNodes = originalRange.getNodes([3], nodeValidator);
    const deletableRanges = deletableTextNodes.map((node) => {
      const range = EditorSelectionUtils.createNewRange();
      range.selectNodeContents(node);
      if (node === originalRange.startContainer) {
        range.setStart(node, originalRange.startOffset);
      }
      if (node === originalRange.endContainer) {
        range.setEnd(node, originalRange.endOffset);
      }
      while (
        node.parentNode.firstChild === node &&
        node.parentNode.lastChild === node &&
        (node !== originalRange.startContainer || originalRange.startOffset === 0) &&
        (node !== originalRange.endContainer || originalRange.endOffset === node.length) &&
        WRAPING_ANCESTORS.includes(node.parentNode.tagName)
      ) {
        // range.selectNodeContents(node.parentNode);
        range.setStartBefore(node.parentNode);
        range.setEndAfter(node.parentNode);
        node = node.parentNode;
      }
      return range;
    });
    return deletableRanges;
  }

  getTrackInsertOnRangeV2(originalRange = EditorSelectionUtils.getRange()) {
    // WARN: has issue with tracked ancestors
    const insertSuggestions = originalRange.getNodes([Node.ELEMENT_NODE], (node) => {
      if (
        node.tagName === 'TRACK-INS-ELEMENT' &&
        !node.hasAttribute('replacewith') &&
        !node.hasAttribute('replacewithsibling')
      ) {
        return true;
      }
      return false;
    });

    const insertRanges = insertSuggestions.map((node) => {
      const range = EditorSelectionUtils.createNewRange();
      const track = node;

      if (node.contains(originalRange.startContainer)) {
        range.setStart(originalRange.startContainer, originalRange.startOffset);
      } else {
        range.setStart(node, 0);
      }

      if (node.contains(originalRange.endContainer)) {
        range.setEnd(originalRange.endContainer, originalRange.endOffset);
      } else {
        range.setEnd(node, node.childNodes.length);
      }

      return {
        track,
        range,
      };
    });

    return insertRanges;
  }

  getTrackInsertOnRange(originalRange = EditorSelectionUtils.getRange()) {
    const insertNodes = originalRange.getNodes([Node.ELEMENT_NODE, Node.TEXT_NODE], (node) => {
      node = node.parentNode;

      const pageNode = DOMUtils.getPageNode(node);

      // if direct parent is a citation group
      if (node.tagName === ELEMENTS.CitationsGroupElement.TAG) {
        return false;
      }

      while (node && node !== pageNode) {
        if (
          node.tagName === 'TRACK-INS-ELEMENT' &&
          !node.hasAttribute('replacewith') &&
          !node.hasAttribute('replacewithsibling')
        ) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    });

    const breaklines = originalRange.getNodes([Node.ELEMENT_NODE], (node) => {
      const pageNode = DOMUtils.getPageNode(node);
      if (node.tagName === 'BREAKLINE-ELEMENT' || node.tagName === 'BR') {
        while (node && node !== pageNode) {
          if (node.tagName === 'TRACK-INS-ELEMENT') {
            return true;
          }
          node = node.parentNode;
        }
      }
      return false;
    });

    const nodes = [...insertNodes, ...breaklines];

    const insertRanges = [];

    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i];

      const range = EditorSelectionUtils.createNewRange();

      const track = DOMUtils.closest(node, 'TRACK-INS-ELEMENT');
      range.selectNodeContents(node);

      if (node === originalRange.startContainer) {
        range.setStart(node, originalRange.startOffset);
      }
      if (node === originalRange.endContainer) {
        range.setEnd(node, originalRange.endOffset);
      }

      const closestNonEditable = EditorDOMUtils.closest(
        node,
        EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS,
      );
      if (closestNonEditable && EditorDOMUtils.parentContainsNode(track, closestNonEditable)) {
        range.setStartBefore(closestNonEditable);
        range.setEndAfter(closestNonEditable);
      } else {
        while (
          node.parentNode.firstChild === node &&
          node.parentNode.lastChild === node &&
          (node !== originalRange.startContainer || originalRange.startOffset === 0) &&
          (node !== originalRange.endContainer || originalRange.endOffset === node.length) &&
          WRAPING_ANCESTORS.includes(node.parentNode.tagName)
        ) {
          // range.selectNodeContents(node.parentNode);
          range.setStartBefore(node.parentNode);
          range.setEndAfter(node.parentNode);
          node = node.parentNode;
        }
      }

      insertRanges.push({
        track,
        range,
      });
    }

    return insertRanges;
  }

  getTrackInsertParagraphOnRange(originalRange = EditorSelectionUtils.getRange()) {
    const nodeValidator = (node) => {
      if (
        node.tagName === 'TRACK-INS-ELEMENT' &&
        (node.hasAttribute('replacewith') || node.hasAttribute('replacewithsibling'))
      ) {
        return true;
      }
      return false;
    };
    const insertNodes = originalRange.getNodes([Node.ELEMENT_NODE], nodeValidator);
    const breaklines = originalRange.getNodes([Node.ELEMENT_NODE], (node) => {
      if (node.tagName === 'BREAKLINE-ELEMENT' || node.tagName === 'BR') {
        while (node && !node.getAttribute('ispagenode')) {
          const parent = node.parentNode;
          if (node.tagName === 'TRACK-INS-ELEMENT') {
            return true;
          }
          node = parent;
        }
      }
      return false;
    });
    const insertRanges = [...insertNodes, ...breaklines].map((node) => {
      const range = EditorSelectionUtils.createNewRange();
      const track = DOMUtils.closest(node, 'TRACK-INS-ELEMENT');
      range.selectNodeContents(node);
      if (node === originalRange.startContainer) {
        range.setStart(node, originalRange.startOffset);
      }
      if (node === originalRange.endContainer) {
        range.setEnd(node, originalRange.endOffset);
      }
      while (
        node.parentNode.firstChild === node &&
        node.parentNode.lastChild === node &&
        (node !== originalRange.startContainer || originalRange.startOffset === 0) &&
        (node !== originalRange.endContainer || originalRange.endOffset === node.length) &&
        WRAPING_ANCESTORS.includes(node.parentNode.tagName)
      ) {
        // range.selectNodeContents(node.parentNode);
        range.setStartBefore(node.parentNode);
        range.setEndAfter(node.parentNode);
        node = node.parentNode;
      }
      return {
        track,
        range,
      };
    });
    return insertRanges;
  }

  /**
   * @deprecated use SelectionUtils
   */
  applyRangeToSelection(range) {
    const sel = EditorSelectionUtils.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  getRangeBasedOnSavedMarkers(savedRange, options = {}) {
    const { setCaretInside = false } = options;
    if (savedRange) {
      const range = EditorSelectionUtils.createNewRange();

      if (savedRange.collapsed) {
        const marker = document.getElementById(savedRange.markerId);
        range.setStartAfter(marker);
        range.setEndAfter(marker);
      } else {
        const startMarker = document.getElementById(savedRange.startMarkerId);
        const endMarker = document.getElementById(savedRange.endMarkerId);

        if (startMarker.nextSibling && setCaretInside) {
          range.setRangeStart(startMarker.nextSibling, 'INSIDE_START');
        } else {
          range.setStartAfter(startMarker);
        }

        if (endMarker.previousSibling && setCaretInside) {
          range.setRangeEnd(endMarker.previousSibling, 'INSIDE_END');
        } else {
          range.setEndBefore(endMarker);
        }
      }
      return range;
    }
    return undefined;
  }

  removeLostMarkers(savedRange) {
    if (savedRange && document) {
      let startMarker;
      let endMarker;
      let collapsedMarker;

      if (savedRange.startMarkerId) {
        startMarker = document.getElementById(savedRange.startMarkerId);
      }

      if (savedRange.endMarkerId) {
        endMarker = document.getElementById(savedRange.endMarkerId);
      }

      if (savedRange.markerId) {
        collapsedMarker = document.getElementById(savedRange.markerId);
      }

      if (startMarker && startMarker.parentNode) {
        startMarker.remove();
      }

      if (endMarker && endMarker.parentNode) {
        endMarker.remove();
      }

      if (collapsedMarker && collapsedMarker.parentNode) {
        collapsedMarker.remove();
      }
    }
  }

  // ########################################################
  //              move selection utilities
  // ########################################################

  static SELECTION_CHANGE_TYPE = {
    move: 'move',
    expand: 'expand',
  };

  static SELECTION_DIRECTION = {
    backward: 'backward',
    forward: 'forward',
  };

  static SELECTION_GRANULARITY = {
    character: 'character',
    word: 'word',
    line: 'line',
  };

  /**
   * modify editor selection
   * @param {SELECTION_DIRECTION} direction
   * @param {SELECTION_GRANULARITY} granularity
   */
  modifySelection(
    changeType = 'move',
    direction = 'forward',
    granularity = 'character',
    incrementOffset = undefined,
  ) {
    // Validate scroll
    this._scrollIntoSelection(direction);

    const range = EditorSelectionUtils.getRange();

    this.editorContext.selection.modifiers.modify(
      range,
      changeType,
      granularity,
      direction,
      incrementOffset,
    );

    EditorSelectionUtils.applyRangeToSelection(range);
  }

  _scrollIntoSelection(direction) {
    const editorRoot = document.getElementById('EditorRoot');
    const editorRootBoundingRect = editorRoot.getBoundingClientRect();

    const selection = EditorSelectionUtils.getSelection();

    const modifiersData = this.editorContext.DataManager.selection?.modifiersData || {};

    let selectedNode;
    if (modifiersData.expandingDirection === SelectionManager.SELECTION_DIRECTION.backward) {
      // expanding direction is backwards
      selectedNode = selection.getRangeAt(0).startContainer;
    } else {
      // expanding direction is forward or selection is collapsed
      selectedNode = selection.getRangeAt(0).endContainer;
    }

    const level0 = DOMUtils.findNodeLevel0(DOMUtils.getPageNode(selectedNode), selectedNode);
    // check for node text element
    const textElement = this.getSelectableElementFromNode(level0, selectedNode);

    if (textElement) {
      const textLineHeight = parseInt(getComputedStyle(textElement).lineHeight, 10);
      const textBoundingRect = textElement.getBoundingClientRect();

      const range = selection.getRangeAt(0);
      const rangeBoundingRect = range.getBoundingClientRect();
      const rangeClientRects = range.getClientRects();

      // verify editor scroll
      let bottom;
      let top;
      let pX;
      if (direction === SelectionManager.SELECTION_DIRECTION.forward) {
        if (modifiersData.expandingDirection === SelectionManager.SELECTION_DIRECTION.backward) {
          top =
            textElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[0].top
              : textBoundingRect.top + parseInt(getComputedStyle(textElement).paddingTop, 10);
          bottom =
            textElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[0].bottom
              : textBoundingRect.bottom - parseInt(getComputedStyle(textElement).paddingBottom, 10);
          pX =
            textElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[0].left
              : textBoundingRect.left + parseInt(getComputedStyle(textElement).paddingLeft, 10);
        } else {
          bottom =
            textElement.textContent && rangeBoundingRect.bottom !== 0
              ? rangeBoundingRect.bottom
              : textBoundingRect.bottom - parseInt(getComputedStyle(textElement).paddingBottom, 10);
          pX =
            textElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[rangeClientRects.length - 1].right
              : textBoundingRect.right - parseInt(getComputedStyle(textElement).paddingRight, 10);
        }
      } else if (direction === SelectionManager.SELECTION_DIRECTION.backward) {
        if (modifiersData.expandingDirection === SelectionManager.SELECTION_DIRECTION.forward) {
          top =
            textElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[rangeClientRects.length - 1].top
              : textBoundingRect.top + parseInt(getComputedStyle(textElement).paddingTop, 10);
          bottom =
            textElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[rangeClientRects.length - 1].bottom
              : textBoundingRect.bottom - parseInt(getComputedStyle(textElement).paddingBottom, 10);
          pX =
            textElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[rangeClientRects.length - 1].right
              : textBoundingRect.right - parseInt(getComputedStyle(textElement).paddingRight, 10);
        } else {
          top =
            textElement.textContent && rangeBoundingRect.top !== 0
              ? rangeBoundingRect.top
              : textBoundingRect.top + parseInt(getComputedStyle(textElement).paddingTop, 10);
          pX =
            textElement.textContent && rangeClientRects.length > 0
              ? rangeClientRects[0].left
              : textBoundingRect.left + parseInt(getComputedStyle(textElement).paddingLeft, 10);
        }
      }

      if (bottom && bottom > editorRootBoundingRect.bottom - textLineHeight - 50) {
        // scroll bottom
        editorRoot.scrollTop += bottom - editorRootBoundingRect.bottom + textLineHeight + 50;
      }

      if (top && top < editorRootBoundingRect.top + textLineHeight + 50) {
        // scroll top
        editorRoot.scrollTop -= editorRootBoundingRect.top - top + textLineHeight + 50;
      }

      if (pX && pX < 50) {
        // scroll left
        window.scrollBy(pX - 50, 0);
      }

      if (pX && pX > window.innerWidth - 50) {
        // scroll right
        window.scrollBy(pX - window.innerWidth + 50, 0);
      }
    }
  }

  /**
   * @deprecated
   */
  getSelectableElementFromNode(level0, selectedNode) {
    let selectableElement;
    if (level0 && selectedNode) {
      if (level0.selectableContent) {
        selectableElement = level0.selectableContent;
      } else if (level0.tagName === ELEMENTS.TableElement.TAG) {
        if (selectedNode.tagName === ELEMENTS.TableCellElement.TAG) {
          selectableElement = selectedNode;
        } else {
          const closest = DOMUtils.closest(selectedNode, [ELEMENTS.TableCellElement.TAG]);
          if (closest) {
            if (closest.tagName === ELEMENTS.TableCellElement.TAG) {
              selectableElement = this.getSelectableElementFromNode(
                DOMUtils.findNodeLevel0(closest, selectedNode),
                selectedNode,
              );
            }
          } else {
            selectableElement = level0;
          }
        }
      } else if (level0.tagName === ELEMENTS.FigureElement.TAG) {
        selectableElement = DOMUtils.closest(selectedNode, ['IMAGE-ELEMENT', 'FIGCAPTION']);
      } else if (level0.tagName === 'TRACK-INS-ELEMENT' || level0.tagName === 'TRACK-DEL-ELEMENT') {
        selectableElement = this.getSelectableElementFromNode(level0.firstChild, selectedNode);
      }
    }
    return selectableElement;
  }

  /**
   * @deprecated use EditorSelectionUtils
   */
  getElementsFromRange(
    range = EditorSelectionUtils.getRange(),
    elementTAGs = DOMUtils.BLOCK_TEXT_ELEMENTS,
    aditionalValidator,
  ) {
    const nodes = range.getNodes([Node.ELEMENT_NODE], (node) => {
      if (aditionalValidator && typeof aditionalValidator === 'function') {
        return elementTAGs.includes(node.tagName) && !!aditionalValidator(node, false);
      }
      return elementTAGs.includes(node.tagName);
    });

    const closestElement = DOMUtils.closest(range.commonAncestorContainer, elementTAGs);

    if (closestElement && !nodes.includes(closestElement)) {
      if (aditionalValidator && typeof aditionalValidator === 'function') {
        if (aditionalValidator(closestElement, true)) {
          nodes.push(closestElement);
        }
      } else {
        nodes.push(closestElement);
      }
    }

    return nodes;
  }
}
