import { Divider } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';

import IntlErrorBoundary from '../IntlErrorBoundary/IntlErrorBoundary';
import LogoContainer from '../LogoContainer/LogoContainer';
import ChangeTenantDropdown from './ChangeTenantDropdown/ChangeTenantDropdown';
import Navigation from './Navigation/Navigation';
import Options from './Options/Options';

const SidebarContent = () => {
  const sidebarExpanded = useSelector((state) => state.sidebar.expanded);

  return (
    <>
      <IntlErrorBoundary
        variant="white"
        size="xsmall"
        margin="4rem auto 1rem auto"
        collapsed={!sidebarExpanded}
      >
        <LogoContainer expanded={sidebarExpanded} color="white" testId="sidebar-logo" />
      </IntlErrorBoundary>
      <IntlErrorBoundary
        variant="white"
        size="xsmall"
        margin="5rem auto 1rem auto"
        collapsed={!sidebarExpanded}
      >
        <ChangeTenantDropdown />
      </IntlErrorBoundary>
      <IntlErrorBoundary
        variant="white"
        size={sidebarExpanded ? 'medium' : 'xsmall'}
        margin="6.5rem auto 0 auto"
        collapsed={!sidebarExpanded}
      >
        <Navigation />
      </IntlErrorBoundary>
      <Divider variant="dark" margin="auto 0 1rem 0" />
      <IntlErrorBoundary
        variant="white"
        size={sidebarExpanded ? 'medium' : 'xsmall'}
        margin="4rem auto"
        collapsed={!sidebarExpanded}
      >
        <Options />
      </IntlErrorBoundary>
    </>
  );
};

export default SidebarContent;
