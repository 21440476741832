import { ExtensionHelper, ReduxInterface } from 'Editor/services';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { Parser } from 'Editor/services/EditionManager/Clipboard';
import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { TableElement } from 'Editor/services/VisualizerManager';
import EditorManager from 'Editor/services/EditorManager';
import { ELEMENTS } from 'Editor/services/consts';
import { Logger } from '_common/services';
import { notify } from '_common/components/ToastSystem';
import KeydownEventValidator from '../KeydownEventValidator';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';

/* eslint-disable class-methods-use-this */
export default class EditionMode {
  constructor(
    page,
    selectionManager,
    navigationManager,
    dataManager,
    changeTracker,
    clipboard,
    stylesHandler,
    documentParser,
    visualizerManager,
  ) {
    if (this.constructor === EditionMode) {
      throw new TypeError('Abstract class "EditionMode" cannot be instantiated directly.');
    }
    this.page = page;
    this.selectionManager = selectionManager;
    this.navigationManager = navigationManager;
    this.dataManager = dataManager;
    this.changeTracker = changeTracker;
    this.clipboard = clipboard;
    this.stylesHandler = stylesHandler;
    this.documentParser = documentParser;
    this.visualizerManager = visualizerManager;

    this.platform = ReduxInterface.getPlatformInfo();

    this.keyDownHandlers = {
      Backspace: this.handleBackspace.bind(this),
      Tab: this.handleTab.bind(this),
      Enter: this.handleEnterKey.bind(this),
      Delete: this.handleDelete.bind(this),
      ArrowLeft: this.handleLeftArrow.bind(this),
      ArrowUp: this.handleUpArrow.bind(this),
      ArrowRight: this.handleRightArrow.bind(this),
      ArrowDown: this.handleDownArrow.bind(this),
      Process: this.handleProcess.bind(this),
    };
    this.handleDefault = this.handleDefault.bind(this);
  }

  destroy() {}

  get user() {
    return { id: ReduxInterface.getCurrentUser() };
  }

  restoreChanges(actionContext) {
    try {
      if (actionContext && actionContext.changes) {
        const changes = actionContext.changes;
        if (changes[ActionContext.CHANGE_TYPE.UPDATED].length > 0) {
          let i;
          for (i = 0; i < changes[ActionContext.CHANGE_TYPE.UPDATED].length; i++) {
            this.visualizerManager.reRenderBlock(changes[ActionContext.CHANGE_TYPE.UPDATED][i]);
          }
        }
      }
    } catch (error) {
      Logger.captureException(error);
    }
  }

  hasEditPermissions() {
    const permissions = ReduxInterface.getLoggedUserDocumentPermissions();
    return permissions && (permissions.includes('edit') || permissions.includes('owner'));
  }

  askUserAboutThis() {
    if (ReduxInterface.isEditorTrackingStateOn()) {
      // eslint-disable-next-line no-alert
      return window.confirm('This action will not be tracked. Proceed?');
    }
    return true;
  }

  // #################################################
  //                   keydown handlers
  // #################################################

  /**
   * handle backspace key
   * @param {Event} event
   */
  handleBackspace(event) {
    if (this.navigationManager.isMarkerRendered()) {
      this.visualizerManager?.selection.stopSelectionTracker();

      let actionContext;
      try {
        this.navigationManager.scrollIntoSelection();

        this.clipboard.removePasteOptions();

        actionContext = new ActionContext(ActionContext.ACTION.BACKSPACE);

        let isEditableContent = true;
        if (event.ctrlKey || event.metaKey) {
          // Avoid selection when ctrlKey is used inside non editable elements.
          if (!this.selectionManager.isCurrentSelectionEditable()) {
            isEditableContent = false;
          } else if (this.selectionManager.isSelectionCollapsed()) {
            // Select previous word.
            this.selectionManager.modifySelection('expand', 'backward', 'word');
          }
        }

        const range = EditorSelectionUtils.getRange();
        const anchorNode = range.startContainer;
        const anchorOffset = range.startOffset;
        const level0Node = DOMUtils.findNodeLevel0(this.page, anchorNode);
        if (isEditableContent) {
          if (this.selectionManager.isSelectionCollapsed()) {
            // selection collapsed
            this.handleBackspaceOnCollapsedSelection(
              actionContext,
              level0Node,
              anchorNode,
              anchorOffset,
            );
          } else {
            // selection not collapsed (multi selection)
            this.handleBackspaceOnMultiSelection(actionContext, level0Node);
          }
        }

        this.visualizerManager.selection.triggerSelectionChanged();

        // save changes to dom
        this.changeTracker.saveActionChanges(actionContext);
      } catch (error) {
        Logger.captureException(error);
        this.restoreChanges(actionContext);
      } finally {
        this.visualizerManager.selection.debounceStartSelectionTracker();

        // WARN: TEMP
        this.visualizerManager.getWidgetsManager()?.rebuildWidgets();
      }
    }
  }

  /**
   *  handle tab key
   * @param {Event} event
   */
  handleTab(event) {
    if (!event.ctrlKey && !event.metaKey) {
      if (this.navigationManager.isMarkerRendered()) {
        this.visualizerManager?.selection.stopSelectionTracker();

        let actionContext;

        try {
          this.navigationManager.scrollIntoSelection();

          this.clipboard.removePasteOptions();

          actionContext = new ActionContext(ActionContext.ACTION.TAB);

          if (this.selectionManager.isSelectionCollapsed()) {
            const range = EditorSelectionUtils.getRange();
            this.handleTabOnCollapsedSelection(
              actionContext,
              event,
              DOMUtils.findNodeLevel0(this.page, range.startContainer),
              range.startContainer,
              range.startOffset,
            );
          } else {
            this.handleTabOnMultiSelection(actionContext, event);
          }

          this.visualizerManager?.selection.triggerSelectionChanged();

          // save changes to dom
          this.changeTracker.saveActionChanges(actionContext);
        } catch (error) {
          Logger.captureException(error);
          this.restoreChanges(actionContext);
        } finally {
          this.visualizerManager.selection.debounceStartSelectionTracker();

          // WARN: TEMP
          this.visualizerManager.getWidgetsManager()?.rebuildWidgets();
        }
      }
    } else {
      // TODO: handle shortcuts
    }
  }

  /**
   * handle enter key
   * @param {Event} event
   */
  handleEnterKey(event) {
    if (!event?.ctrlKey && !event?.metaKey) {
      if (this.navigationManager.isMarkerRendered()) {
        this.visualizerManager?.selection.stopSelectionTracker();

        let actionContext;
        try {
          this.navigationManager.scrollIntoSelection();

          this.clipboard.removePasteOptions();

          actionContext = new ActionContext(ActionContext.ACTION.ENTER);

          const range = EditorSelectionUtils.getRange();
          const anchorNode = range.startContainer;
          const anchorOffset = range.endContainer;

          const level0Node = DOMUtils.findNodeLevel0(this.page, anchorNode);

          if (this.selectionManager.isSelectionCollapsed()) {
            // selection collapsed
            this.handleEnterOnCollapsedSelection(
              actionContext,
              level0Node,
              anchorNode,
              anchorOffset,
            );
          } else {
            // selection not collapsed (multi selection)
            this.handleEnterOnMultiSelection(actionContext);
          }

          this.visualizerManager.selection.triggerSelectionChanged();

          // save changes to dom
          this.changeTracker.saveActionChanges(actionContext);
        } catch (error) {
          Logger.captureException(error);
          this.restoreChanges(actionContext);
        } finally {
          this.visualizerManager.selection.debounceStartSelectionTracker();

          // WARN: TEMP
          this.visualizerManager.getWidgetsManager()?.rebuildWidgets();
        }
      }
    } else {
      // TODO: handle shortcuts
    }
  }

  /**
   * handle delete key
   * @param {Event} event
   */
  handleDelete(event) {
    if (this.navigationManager.isMarkerRendered()) {
      this.visualizerManager?.selection.stopSelectionTracker();

      let actionContext;
      try {
        this.navigationManager.scrollIntoSelection();

        this.clipboard.removePasteOptions();

        actionContext = new ActionContext(ActionContext.ACTION.DELETE);

        let isEditableContent = true;
        if (event.ctrlKey || event.metaKey) {
          // Avoid selection when ctrlKey is used inside non editable elements.
          if (!this.selectionManager.isCurrentSelectionEditable()) {
            isEditableContent = false;
          } else if (this.selectionManager.isSelectionCollapsed()) {
            // Select next word.
            this.selectionManager.modifySelection('expand', 'forward', 'word');
          }
        }

        const range = EditorSelectionUtils.getRange();
        const anchorNode = range.startContainer;
        const anchorOffset = range.endContainer;
        const level0Node = DOMUtils.findNodeLevel0(this.page, anchorNode);
        if (isEditableContent) {
          if (this.selectionManager.isSelectionCollapsed()) {
            // selection collapsed
            this.handleDeleteOnCollpasedSelection(
              actionContext,
              level0Node,
              anchorNode,
              anchorOffset,
            );
          } else {
            // selection not collapsed (multi selection)
            this.handleDeleteOnMultiSelection(actionContext, level0Node);
          }
        }

        this.visualizerManager.selection.triggerSelectionChanged();

        // save changes to dom
        this.changeTracker.saveActionChanges(actionContext);
      } catch (error) {
        Logger.captureException(error);
        this.restoreChanges(actionContext);
      } finally {
        this.visualizerManager.selection.debounceStartSelectionTracker();

        this.visualizerManager.getWidgetsManager()?.rebuildWidgets();
      }
    }
  }

  /**
   * handle default keys
   * @param {Event} event
   */
  handleDefault(event) {
    if (!event.ctrlKey && !event.metaKey) {
      if (KeydownEventValidator.isKeyPrintable(event) && !event.isComposing) {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          let actionContext;
          try {
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            actionContext = new ActionContext(ActionContext.ACTION.DEFAULT);

            // if default key event
            const range = EditorSelectionUtils.getRange();
            const anchorNode = range.startContainer;
            const anchorOffset = range.endContainer;

            const level0Node = DOMUtils.findNodeLevel0(this.page, anchorNode);

            if (this.selectionManager.isSelectionCollapsed()) {
              this.handleDefaultOnCollapsedSelection(
                actionContext,
                event,
                level0Node,
                anchorNode,
                anchorOffset,
              );
            } else {
              this.handleDefaultOnMultiSelection(actionContext, event);
            }

            this.visualizerManager.selection.triggerSelectionChanged();

            // save changes to dom
            this.changeTracker.saveActionChanges(actionContext);
          } catch (error) {
            Logger.captureException(error);
            this.restoreChanges(actionContext);
          } finally {
            this.visualizerManager.selection.debounceStartSelectionTracker();

            // WARN: TEMP
            this.visualizerManager.getWidgetsManager()?.rebuildWidgets();
          }
        }
      }
    } else {
      // handle shortcuts

      // eslint-disable-next-line no-lonely-if
      if (event.code === 'KeyV') {
        // paste - text only
        ExtensionHelper.readClipboardData(this.platform).then((data) => {
          const dataTransfer = new DataTransfer();

          if (data.items.length > 0) {
            let i;
            for (i = 0; i < data.items.length; i++) {
              const item = data.items[i];
              dataTransfer.setData(item.type, item.data);
            }
          }

          this.handlePasteEvent(null, Parser.PLAIN_TEXT, dataTransfer);
        });
      } else if (
        (!this.platform.os.mac && event.ctrlKey) ||
        (this.platform.os.mac && event.metaKey)
      ) {
        if (event.key === 'k') {
          const range = EditorSelectionUtils.getRange();
          const link = DOMUtils.closest(
            range.commonAncestorContainer,
            ELEMENTS.HyperlinkElement.TAG,
          );

          if (link) {
            EditorManager.getInstance().editHyperlink();
          }
        }
      }
    }
  }

  /**
   * handle left arrow
   * @param {Event} event
   */
  handleLeftArrow(event) {
    if (this.navigationManager.isMarkerRendered()) {
      this.visualizerManager?.selection.stopSelectionTracker();
      try {
        // this.navigationManager.scrollIntoSelection();

        const range = EditorSelectionUtils.getRange();

        // try to fix selection if selection is in page
        if (
          range.startContainer === DOMUtils.getPageNode(range.startContainer) ||
          range.endContainer === DOMUtils.getPageNode(range.endContainer)
        ) {
          this.selectionManager.fixSelection();
        }

        if (!event.shiftKey && !event.ctrlKey) {
          // handle arrow without shortcuts
          this.selectionManager.modifySelection('move', 'backward', 'character');
        } else if (event.shiftKey && event.ctrlKey) {
          // handle arrow with shift + ctrl
          this.selectionManager.modifySelection('expand', 'backward', 'word');
        } else if (event.shiftKey) {
          // handle arrow with shift
          this.selectionManager.modifySelection('expand', 'backward', 'character');
        } else if (event.ctrlKey) {
          // handle arrow with ctrl
          this.selectionManager.modifySelection('move', 'backward', 'word');
        }

        this.visualizerManager.selection.triggerSelectionChanged(false);
      } catch (error) {
        Logger.captureException(error);
      } finally {
        this.visualizerManager.selection.debounceStartSelectionTracker();
      }
    }
  }

  /**
   * handle right arrow
   * @param {Event} event
   */
  handleRightArrow(event) {
    if (this.navigationManager.isMarkerRendered()) {
      this.visualizerManager?.selection.stopSelectionTracker();
      try {
        // this.navigationManager.scrollIntoSelection();

        const range = EditorSelectionUtils.getRange();

        // try to fix selection if selection is in page
        if (
          range.startContainer === DOMUtils.getPageNode(range.startContainer) ||
          range.endContainer === DOMUtils.getPageNode(range.endContainer)
        ) {
          this.selectionManager.fixSelection();
        }

        //   if (selectionCheck) {
        if (!event.shiftKey && !event.ctrlKey) {
          // handle arrow without shortcuts
          this.selectionManager.modifySelection('move', 'forward', 'character');
        } else if (event.shiftKey && event.ctrlKey) {
          // handle arrow with shift + ctrl
          this.selectionManager.modifySelection('expand', 'forward', 'word');
        } else if (event.shiftKey) {
          // handle arrow with shift
          this.selectionManager.modifySelection('expand', 'forward', 'character');
        } else if (event.ctrlKey) {
          // handle arrow with ctrl
          this.selectionManager.modifySelection('move', 'forward', 'word');
        }

        this.visualizerManager.selection.triggerSelectionChanged(false);
      } catch (error) {
        Logger.captureException(error);
      } finally {
        this.visualizerManager.selection.debounceStartSelectionTracker();
      }
    }
  }

  /**
   * handle up arrow
   * @param {Event} event
   */
  handleUpArrow(event) {
    if (this.navigationManager.isMarkerRendered()) {
      this.visualizerManager?.selection.stopSelectionTracker();
      try {
        // this.navigationManager.scrollIntoSelection();

        // try to fix selection if selection is in page
        const range = EditorSelectionUtils.getRange();
        if (range.startContainer === DOMUtils.getPageNode(range.startContainer)) {
          this.selectionManager.fixSelection();
        }

        if (range) {
          // TODO: optimize this in edition refactor moving it line modifier
          const closestTR = EditorDOMUtils.closest(
            range.commonAncestorContainer,
            ELEMENTS.TableRowElement.TAG,
          );
          if (closestTR && closestTR.parentNode.firstChild === closestTR) {
            const table = closestTR.parentNode.parentNode;
            this.visualizerManager.getWidgetsManager()?.removeAllWidgetsForView(table.id);
          }
        }

        if (!event.shiftKey && !event.ctrlKey) {
          // handle arrow without shortcuts
          this.selectionManager.modifySelection('move', 'backward', 'line');
        } else if (event.shiftKey) {
          // handle arrow with shift
          this.selectionManager.modifySelection('expand', 'backward', 'line');
        } else if (event.ctrlKey) {
          // handle arrow with ctrl
          // TODO:
          // Example: in vscode it moves the scroll bar
        }

        this.visualizerManager.selection.triggerSelectionChanged(false);
      } catch (error) {
        Logger.captureException(error);
      } finally {
        this.visualizerManager.selection.debounceStartSelectionTracker();
      }
    }
  }

  /**
   * handle down arrow
   * @param {Event} event
   */
  handleDownArrow(event) {
    if (this.navigationManager.isMarkerRendered()) {
      this.visualizerManager?.selection.stopSelectionTracker();
      try {
        // this.navigationManager.scrollIntoSelection();

        // try to fix selection if selection is in page
        const range = EditorSelectionUtils.getRange();
        if (range.startContainer === DOMUtils.getPageNode(range.startContainer)) {
          this.selectionManager.fixSelection();
        }

        if (range) {
          // TODO: optimize this in edition refactor moving it line modifier
          const closest = EditorDOMUtils.closest(
            range.commonAncestorContainer,
            ELEMENTS.ImageElement.TAG,
          );
          if (closest) {
            this.visualizerManager.getWidgetsManager()?.removeAllWidgetsForView(closest.id);
          }
        }

        if (!event.shiftKey && !event.ctrlKey) {
          // handle arrow without shortcuts
          this.selectionManager.modifySelection('move', 'forward', 'line');
        } else if (event.shiftKey) {
          // handle arrow with shift
          this.selectionManager.modifySelection('expand', 'forward', 'line');
        } else if (event.ctrlKey) {
          // handle arrow with ctrl
          // TODO:
          // Example: in vscode it moves the scroll bar
        }

        this.visualizerManager.selection.triggerSelectionChanged(false);
      } catch (error) {
        Logger.captureException(error);
      } finally {
        this.visualizerManager.selection.debounceStartSelectionTracker();
      }
    }
  }

  handleProcess() {
    const range = EditorSelectionUtils.getRange();
    if (!range.collapsed) {
      range.collapse(true);
    }
  }

  // #################################################
  //               insert element handlers
  // #################################################
  /**
   * @description insert node on level 0
   * @param {Node} blockNode
   */
  handleInsertBlockNode(blockNode, actionContext) {
    if (this.navigationManager.isMarkerRendered()) {
      this.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.navigationManager.scrollIntoSelection();

        this.clipboard.removePasteOptions();

        if (!actionContext) {
          actionContext = new ActionContext(ActionContext.ACTION.INSERT_BLOCK);
        }

        const range = EditorSelectionUtils.getRange();
        const anchorNode = range.startContainer;

        const level0Node = DOMUtils.findNodeLevel0(this.page, anchorNode);

        if (this.selectionManager.isSelectionCollapsed()) {
          // selection collapsed
          this.handleInsertBlockNodeOnCollapsedSelection(
            actionContext,
            blockNode,
            level0Node,
            anchorNode,
          );
        } else {
          // selection not collapsed (multi selection)
          this.handleInsertBlockNodeOnMultiSelection(
            actionContext,
            blockNode,
            level0Node,
            anchorNode,
          );
        }

        this.visualizerManager?.selection.triggerSelectionChanged();

        if (actionContext.action === ActionContext.ACTION.INSERT_BLOCK) {
          // save changes to dom
          this.changeTracker.saveActionChanges(actionContext);
        }
      } catch (error) {
        Logger.captureException(error);
        this.restoreChanges(actionContext);
      } finally {
        this.visualizerManager.selection.debounceStartSelectionTracker();

        this.visualizerManager.getWidgetsManager()?.rebuildWidgets();
      }
    }
  }

  /**
   * @description insert node inside a level0 node
   * @param {Node} inlineNode
   */
  handleInsertInlineNode(inlineNode, actionContext, options) {
    if (this.navigationManager.isMarkerRendered()) {
      this.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.navigationManager.scrollIntoSelection();

        this.clipboard.removePasteOptions();

        if (!actionContext) {
          actionContext = new ActionContext(ActionContext.ACTION.INSERT_INLINE);
        }

        const range = EditorSelectionUtils.getRange();
        const anchorNode = range.startContainer;

        const level0Node = DOMUtils.findNodeLevel0(this.page, anchorNode);

        if (this.selectionManager.isSelectionCollapsed()) {
          // selection collapsed
          this.handleInsertInlineNodeOnCollapsedSelection(
            actionContext,
            inlineNode,
            level0Node,
            anchorNode,
            options,
          );
        } else {
          // selection not collapsed (multi selection)
          this.handleInsertInlineNodeOnMultiSelection(actionContext, inlineNode, options);
        }

        this.visualizerManager?.selection.triggerSelectionChanged();

        if (actionContext.action === ActionContext.ACTION.INSERT_INLINE) {
          // save changes to dom
          this.changeTracker.saveActionChanges(actionContext);
        }
      } catch (error) {
        Logger.captureException(error);
        this.restoreChanges(actionContext);
      } finally {
        this.visualizerManager.selection.debounceStartSelectionTracker();

        // WARN: TEMP
        this.visualizerManager.getWidgetsManager()?.rebuildWidgets();
      }
    }
  }

  // #################################################
  //                   Events handlers
  // #################################################

  /**
   * @description Handle key down
   * @author Filipe Assunção
   * @param {Event} event
   * @memberof EditionMode
   */
  handleKeyDown(event) {
    // this if is here just because firefox in linux sometimes is an idiot
    if (!this._lastKeyDown || (this._lastKeyDown !== 'Dead' && this._lastKeyDown !== 'Undifined')) {
      if (this.keyDownHandlers[event.key]) {
        this.keyDownHandlers[event.key](event);
      } else {
        this.handleDefault(event);
      }
    }

    if (this.platform.os.linux && this.platform.browser.firefox) {
      this._lastKeyDown = event.key;
    }
  }

  /**
   *
   * @param {Event} event
   */
  handleKeyPress(event) {
    // const selection = EditorSelectionUtils.getSelection();
    // const level0Node = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);

    // // if selection is a custom non-editable element
    // if (!DOMUtils.isBlockNodeEditable(level0Node)) {
    // }
    event.stopPropagation();
    event.preventDefault();
  }

  /**
   *
   * @param {Event} event
   */
  handleKeyUp() {}

  /**
   *
   * @param {Event} event
   */
  handleMouseUp({ event, mousedownTarget }) {
    // TODO: review this code
    if (mousedownTarget === event.target) {
      const node = DOMUtils.closest(event.target, 'IMG');
      if (node) {
        this.visualizerManager?.selection.stopSelectionTracker();
        try {
          this.selectionManager.selectNode(node);
          this.visualizerManager.selection.triggerSelectionChanged();
        } finally {
          this.visualizerManager.selection.debounceStartSelectionTracker();
        }

        return;
      }
    }

    if ((!this.platform.os.mac && event.ctrlKey) || (this.platform.os.mac && event.metaKey)) {
      const link = DOMUtils.closest(event.target, ELEMENTS.HyperlinkElement.TAG);
      if (link) {
        EditorManager.getInstance().openHyperlink();
      }
    }
  }

  /**
   *
   * @param {Event} event
   */
  handleMouseOver({ event }) {
    event.preventDefault();
  }

  /**
   *
   * @param {Event} event
   */
  handleMouseDown(event) {
    // TODO: review this code
    if (event.target) {
      const tableOptions = event.target.closest('[data-id="table-options-container"]');
      if (!tableOptions) {
        const td = DOMUtils.closest(event.target, ELEMENTS.TableCellElement.TAG);
        if (event.which === 3 && !event.shiftKey) {
          if (td && td.isSelected) {
            return;
          }
        } else if (event.which === 3 && event.shiftKey) {
          const selection = EditorSelectionUtils.getSelection();
          selection.removeAllRanges();
          if (this.platform.os.mac) {
            selection.getRangeAt(0).expand('word');
          }
        }

        this.stylesHandler.deselectTableCells(event, false);
        const closestTable = DOMUtils.closest(td, ELEMENTS.TableElement.TAG);
        const closestApproval = DOMUtils.closest(td, ELEMENTS.ApprovedElement.TAG);
        if (td && closestTable instanceof TableElement && !closestApproval) {
          closestTable.handleCellMouseDown(event, td);
        }
      }
    }
  }

  /**
   * handle copy event
   * @param {Event} event
   */
  handleCopyEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    if (
      this.navigationManager.isMarkerRendered() &&
      !this.selectionManager.isSelectionCollapsed()
    ) {
      this.visualizerManager?.selection.stopSelectionTracker();

      try {
        this.clipboard.removePasteOptions();

        this.clipboard.handleCopy(event.clipboardData);
      } catch (error) {
        Logger.captureException(error);
      } finally {
        this.visualizerManager.selection.debounceStartSelectionTracker();
      }
    }
  }

  /**
   * handle cut event
   * @param {Event} event
   */
  handleCutEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.navigationManager.isMarkerRendered()) {
      this.visualizerManager?.selection.stopSelectionTracker();

      let actionContext;

      try {
        // restore and scroll into selection if needed
        this.navigationManager.scrollIntoSelection();

        this.clipboard.removePasteOptions();

        actionContext = new ActionContext(ActionContext.ACTION.CUT);

        if (!this.selectionManager.isSelectionCollapsed()) {
          this.handleCutOnNonCollapsedSelection(actionContext, event);
        }

        this.visualizerManager?.selection.triggerSelectionChanged();

        // save changes to dom
        this.changeTracker.saveActionChanges(actionContext);
      } catch (error) {
        Logger.captureException(error);
        this.restoreChanges(actionContext);
      } finally {
        this.visualizerManager.selection.debounceStartSelectionTracker();
        this.visualizerManager.getWidgetsManager()?.rebuildWidgets();
      }
    }
  }

  /**
   * handle paste event
   * @param {Event} event
   */
  handlePasteEvent(event, pasteOptionsStyle, clipboardData) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const data = (event && event.clipboardData) || clipboardData;

    // build clipboard parser
    if (data) {
      this.clipboard.handlePaste(data);
    }

    const pasteFunction = (parsedData) => {
      if (this.navigationManager.isMarkerRendered()) {
        this.visualizerManager?.selection.stopSelectionTracker();
        let actionContext;
        try {
          // restore and scroll into selection if needed
          // this.navigationManager.scrollIntoSelection();
          if (!pasteOptionsStyle) {
            this.clipboard.removePasteOptions();
            pasteOptionsStyle = Parser.ORIGINAL_STYLES;
          }

          actionContext = new ActionContext(ActionContext.ACTION.PASTE);
          actionContext.jsonChanges = true;

          const selection = EditorSelectionUtils.getSelection();
          const anchorNode = selection.anchorNode;

          const level0Node = DOMUtils.findNodeLevel0(this.page, anchorNode);

          if (this.selectionManager.isSelectionCollapsed()) {
            this.handlePasteOnCollapsedSelection(
              actionContext,
              parsedData,
              pasteOptionsStyle,
              level0Node,
              anchorNode,
            );
          } else {
            this.handlePasteOnNonCollapsedSelection(
              actionContext,
              parsedData,
              pasteOptionsStyle,
              level0Node,
              anchorNode,
            );
          }

          this.visualizerManager?.selection.triggerSelectionChanged();

          // save changes to dom
          this.changeTracker.saveActionChanges(actionContext);

          setTimeout(() => {
            if (this.navigationManager.isMarkerRendered()) {
              this.navigationManager.scrollIntoSelection();
            }
          }, 0);
        } catch (error) {
          Logger.captureException(error);
          this.restoreChanges(actionContext);
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR.ERROR_PASTE',
          });
        } finally {
          this.visualizerManager.selection.debounceStartSelectionTracker();

          // WARN: TEMP
          this.visualizerManager.getWidgetsManager()?.rebuildWidgets();

          setTimeout(() => {
            ReduxInterface.stopEditorLoading();
          }, 0);
        }
      }
    };

    if (this.clipboard.hasParsersAvailable()) {
      const numbLines = this.clipboard.countAvailableContent();

      if (numbLines != null && numbLines < 50) {
        // get parsed data
        this.clipboard
          .getParsedData(pasteOptionsStyle)
          .then((parsedData) => pasteFunction(parsedData))
          .catch((error) => {
            Logger.captureException(error);
            setTimeout(() => {
              ReduxInterface.stopEditorLoading();
            }, 0);
          });
      } else {
        ReduxInterface.startEditorLoading('LOADING_PASTED_CONTENT');
        setTimeout(() => {
          this.clipboard
            .getParsedData(pasteOptionsStyle)
            .then((parsedData) => pasteFunction(parsedData))
            .catch((error) => {
              Logger.captureException(error);
              setTimeout(() => {
                ReduxInterface.stopEditorLoading();
              }, 0);
            });
        }, 0);
      }
    }
  }

  /**
   * handle drop event
   * @param {Event} event
   */
  handleDropEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    this.handlePasteEvent(event, undefined, event.dataTransfer);
  }

  handleInput(event) {
    if (this.platform.os.linux && this.platform.browser.firefox) {
      this._lastKeyDown = event.key;
    }
    const selection = EditorSelectionUtils.getSelection();
    const anchorNode = selection.anchorNode;
    const anchorOffset = selection.anchorOffset;
    const level0Node = DOMUtils.findNodeLevel0(this.page, anchorNode);

    //* clean unwanted HTML
    let nodeToCheck = anchorNode;
    while (nodeToCheck !== level0Node) {
      if (
        nodeToCheck.nodeType === Node.ELEMENT_NODE &&
        !DOMNormalizer.isAllowedUnder(nodeToCheck.tagName, nodeToCheck.parentNode.tagName)
      ) {
        while (nodeToCheck.firstChild) {
          nodeToCheck.parentNode.insertBefore(nodeToCheck.firstChild, nodeToCheck);
        }
        this.selectionManager.setCaret(anchorNode, 'INDEX', anchorOffset);
        this.visualizerManager?.selection.triggerSelectionChanged();
        const parent = nodeToCheck.parentNode;
        nodeToCheck.remove();
        nodeToCheck = parent;
      } else {
        nodeToCheck = nodeToCheck.parentNode;
      }
    }

    if (!event.isComposing && event.inputType !== 'insertCompositionText') {
      if (this.isInlineInsertionAllowed(level0Node, anchorNode, anchorOffset)) {
        this.handleCompositionEnd(event);
      } else if (event.data) {
        // delete data
        anchorNode.deleteData(anchorOffset - event.data.length, event.data.length);
        DOMNormalizer.normalizeChildren(anchorNode.parentNode);
      }
    }
  }

  handleCompositionEnd(event) {
    if (this.platform.os.linux && this.platform.browser.firefox) {
      this._lastKeyDown = event.key;
    }

    if (this.navigationManager.isMarkerRendered()) {
      this.visualizerManager?.selection.stopSelectionTracker();
      let actionContext;
      try {
        this.navigationManager.scrollIntoSelection();

        this.clipboard.removePasteOptions();

        actionContext = new ActionContext(ActionContext.ACTION.INSERT_INLINE);

        const selection = EditorSelectionUtils.getSelection();

        const level0Node = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);

        this.handleCompositionOnCollapsedSelection(
          actionContext,
          event,
          level0Node,
          selection.anchorNode,
          selection.anchorOffset,
          selection.anchorNode,
          selection.anchorOffset,
        );

        this.visualizerManager?.selection.triggerSelectionChanged();

        // save changes to dom
        this.changeTracker.saveActionChanges(actionContext);
      } catch (error) {
        Logger.captureException(error);
        this.restoreChanges(actionContext);
      } finally {
        this.visualizerManager.selection.debounceStartSelectionTracker();

        // WARN: TEMP
        this.visualizerManager.getWidgetsManager()?.rebuildWidgets();
      }
    }
  }

  // #################################################
  //             Remove Content handlers
  // #################################################
  handleRemoveSelection(actionContext, contextOptions = {}) {
    if (this.navigationManager.isMarkerRendered()) {
      this.visualizerManager?.selection.stopSelectionTracker();
      try {
        this.navigationManager.scrollIntoSelection();

        this.clipboard.removePasteOptions();

        if (!actionContext) {
          actionContext = new ActionContext(ActionContext.ACTION.DELETE, contextOptions);
        } else {
          actionContext.setOptions(contextOptions);
        }

        const selection = EditorSelectionUtils.getSelection();
        const anchorNode = selection.anchorNode;

        const level0Node = EditorDOMUtils.findFirstLevelChildNode(this.page, anchorNode);

        this.handleDeleteOnMultiSelection(actionContext, level0Node);

        this.visualizerManager?.selection.triggerSelectionChanged();

        if (actionContext.action === ActionContext.ACTION.DELETE) {
          // save changes to dom
          this.changeTracker.saveActionChanges(actionContext);
        }
      } catch (error) {
        Logger.captureException(error);
        this.restoreChanges(actionContext);
      } finally {
        this.visualizerManager.selection.debounceStartSelectionTracker();
        this.visualizerManager.getWidgetsManager()?.rebuildWidgets();
      }
    }
  }
}
