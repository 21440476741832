import {
  CollapsedSelectionFixer,
  CollapsedTextSelectionFixer,
  NonCollapsedSelectionFixer,
} from './Visitors/Fixers';

export class SelectionFixer {
  static collapsedTextSelection(
    range: DoDOCSelection.DoDOCRange,
    options: Partial<Editor.Selection.FixerOptions>,
  ) {
    range.accept(new CollapsedTextSelectionFixer(options));
  }

  static nonCollapsedTextSelection(
    range: DoDOCSelection.DoDOCRange,
    options: Partial<Editor.Selection.FixerOptions>,
  ) {
    range.accept(
      new CollapsedTextSelectionFixer({
        ...options,
        containerPosition: 'start',
      }),
    );
    range.accept(
      new CollapsedTextSelectionFixer({
        ...options,
        containerPosition: 'end',
      }),
    );
  }

  static fixSelection(
    range: DoDOCSelection.DoDOCRange,
    options: Partial<Editor.Selection.FixerOptions>,
  ) {
    let fixer: Editor.Selection.Range.IEditorRangeVisitor;
    if (range.collapsed) {
      fixer = new CollapsedSelectionFixer(options);
    } else {
      fixer = new NonCollapsedSelectionFixer(options);
    }
    range.accept(fixer);
    return fixer.updated;
  }
}
