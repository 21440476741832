import { WidgetViewModel } from './WidgetViewModel';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export class TabBarWidgetModel extends WidgetViewModel<'tabulations'> {
  constructor(
    Visualizer: Editor.Visualizer.State,
    refView: Editor.Visualizer.WidgetData['tabulations']['view'],
    props: Editor.Visualizer.WidgetData['tabulations']['props'],
  ) {
    super(Visualizer, refView, props);

    this.container.dataset.type = 'tabulations';
  }

  render(): void {
    let offsets = EditorDOMUtils.getOffsets(this.refView);

    while (this.container.firstChild) {
      this.container.removeChild(this.container.firstChild);
    }

    if (offsets) {
      this.container.style.position = 'absolute';
      this.container.style.top = `${offsets.top}px`;

      if (this.Visualizer.getLayoutType?.() === 'PAGE') {
        this.container.style.left = `calc(50% - ${offsets.width / 2}px)`;
      } else {
        this.container.style.left = `${offsets.left}px`;
      }

      this.container.style.width = `0px`;
      this.container.style.height = `${offsets.height}px`;
      this.container.style.userSelect = 'none';

      for (let i = 0; i < this.props.tabPos.length; i++) {
        const pos = this.props.tabPos[i];

        const div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.left = `${pos}px`;
        div.style.height = `${offsets.height}px`;
        div.style.width = '1px';
        div.style.background = '#000000';
        this.container.appendChild(div);
      }
    }
  }

  dispose(): void {
    this.container.remove();
  }
}
