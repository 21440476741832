import { FormattedMessage } from 'react-intl';
import SidebarContent from 'Editor/pages/EditorPage/SidebarComponent/SidebarContent';
import SidebarHeader from 'Editor/pages/EditorPage/SidebarComponent/SidebarHeader';
import SidebarPanel from 'Editor/pages/EditorPage/SidebarComponent/SidebarPanel';
import { Button, Toggle, ToggleGroup } from 'dodoc-design-system';

import { useSelector, useDispatch } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { setSidebarPanelTab } from 'Editor/redux/SidebarSlice';
import { selectDocumentCitations } from 'Editor/redux/CitationsSlice';

import { setSelectedTab } from '_common/components/TabMenu/TabMenuSlice';

import DocumentCitationsList from './DocumentCitationsList/DocumentCitationsList';
import LibraryCitationsList from './LibraryCitationsList/LibraryCitationsList';

const ReferencesTab = () => {
  const dispatch = useDispatch();

  const tab = useSelector((state) => state.editor.sidebar.tabs.citations);
  const citations = useSelector((state) => state.editor.citations);

  const documentCitations = useSelector(selectDocumentCitations);
  const manager = EditorManager.getInstance();

  const handleOpenManager = () => {
    dispatch(setSelectedTab({ menuId: 'citationsManager', selectedTab: 'searchReferences' }));
    dispatch(
      openAndUpdateModal({
        modal: 'CitationsModal',
        data: {
          documentId: null,
          library: {
            selected: null,
          },
          search: {
            loading: false,
            list: [],
            dict: {},
          },
          import: {
            dropzone: true,
            loading: false,
            list: [],
            dict: {},
          },
          navigation: {
            current: null,
            identity: 'storage',
          },
        },
      }),
    );
  };

  const handleOpenManagerWithSelection = (id: string) => {
    dispatch(setSelectedTab({ menuId: 'citationsManager', selectedTab: 'documentLibrary' }));
    dispatch(
      openAndUpdateModal({
        modal: 'CitationsModal',
        data: {
          library: {
            selected: id,
          },
          search: {
            loading: false,
            list: [],
            dict: {},
          },
          import: {
            dropzone: true,
            loading: false,
            list: [],
            dict: {},
          },
        },
      }),
    );
  };

  const handleInsertCitation = (id: string) => {
    manager.addCitation(id);
  };

  const handleRemoveCitation = (id: string) => {
    manager.removeCitation(id);
  };
  return (
    <SidebarPanel testId="references-panel">
      <SidebarHeader>
        <FormattedMessage id="REFERENCES" />
      </SidebarHeader>
      <SidebarContent>
        <Button
          margin="0 0 1rem"
          size="small"
          onClick={handleOpenManager}
          testId="references-panel-open-references-manager-button"
        >
          <FormattedMessage id="editor.sidebar.citations.openManager" />
        </Button>
        <ToggleGroup>
          <Toggle
            fullWidth
            variant="group"
            size="small"
            isToggled={tab === 'document'}
            onClick={() => dispatch(setSidebarPanelTab({ view: 'citations', tab: 'document' }))}
            testId="references-panel-document-toggle"
          >
            <FormattedMessage id="global.document" /> ({citations.order.length})
          </Toggle>
          <Toggle
            fullWidth
            variant="group"
            size="small"
            isToggled={tab === 'library'}
            onClick={() => dispatch(setSidebarPanelTab({ view: 'citations', tab: 'library' }))}
            testId="references-panel-library-toggle"
          >
            <FormattedMessage id="editor.sidebar.citations.library" /> (
            {documentCitations.list.length})
          </Toggle>
        </ToggleGroup>
        {tab === 'document' ? (
          <DocumentCitationsList
            edit={handleOpenManagerWithSelection}
            remove={handleRemoveCitation}
            insert={handleInsertCitation}
            focusCitation={(id: { level0: string; citation: string }) => manager.focusCitation(id)}
          />
        ) : (
          <LibraryCitationsList
            edit={handleOpenManagerWithSelection}
            insert={handleInsertCitation}
          />
        )}
      </SidebarContent>
    </SidebarPanel>
  );
};

export default ReferencesTab;
