import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import CurrentStyleContent, { CurrentStyleProps } from './CurrentStyleContent';

const CurrentStyle = (props: CurrentStyleProps) => {
  return (
    <IntlErrorBoundary fallbackType="card" mockProps={{ sidebar: true }}>
      <CurrentStyleContent {...props} />
    </IntlErrorBoundary>
  );
};

export default CurrentStyle;
