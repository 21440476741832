import { Mixin } from 'mixwith';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { Logger } from '_common/services';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class EnterHandler extends superclass {
      destroy() {
        super.destroy();
      }

      /**
       * handle enter on multi selection
       * @param {ActionContext} actionContext
       */
      handleEnterOnMultiSelection(actionContext) {
        if (this.joinSelectionContent(actionContext)) {
          const selection = EditorSelectionUtils.getSelection();
          const anchorNode = selection.anchorNode;
          const anchorOffset = selection.anchorOffset;
          const level0Node = DOMUtils.findNodeLevel0(this.page, anchorNode);

          this.handleEnterOnCollapsedSelection(actionContext, level0Node, anchorNode, anchorOffset);
        }
      }

      /**
       * selects a function to handle enter based on baseNode
       * @param {ActionContext} actionContext
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleEnterOnCollapsedSelection(actionContext, baseNode, anchorNode, anchorOffset) {
        if (!baseNode || DOMUtils.isNodeAContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        }

        if (baseNode) {
          if (anchorNode) {
            if (
              baseNode.tagName === ELEMENTS.ParagraphElement.TAG &&
              this.dataManager.numbering.isListElement(baseNode.id)
            ) {
              // SELECTION IS A LIST and we want to handle a specific case before handling on text element
              this.handleEnterOnListElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (DOMUtils.isNodeEditableTextElement(baseNode)) {
              // SELECTION IS A DEFAULT TEXT ELEMENT
              this.handleEnterOnTextElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (!DOMUtils.isBlockNodeEditable(baseNode)) {
              // SELECTION IS A NON-EDITABLE ELEMENT
              this.handleNonEditableElementEnter(actionContext, baseNode);
            } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
              // SELECTION IS A FIGURE
              this.handleEnterOnFigureElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
              // SELECTION IS A TABLE
              this.handleEnterOnTableElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (DOMUtils.isNodeAContainerElement(baseNode)) {
              this.handleEnterOnContainerElement(actionContext, baseNode, anchorNode, anchorOffset);
            }
          }
        }
      }

      /**
       * handle enter on a non-editable element
       * @param {ActionContext} actionContext
       */
      handleNonEditableElementEnter(actionContext, level0Node) {
        // enter
        try {
          if (this.hasEditPermissions()) {
            const contentEditable = level0Node && level0Node.selectableContent;

            if (contentEditable && this.selectionManager.isSelectionAtEnd(contentEditable)) {
              let parentNode = level0Node.parentNode;
              let refNode = level0Node;
              if (
                parentNode.tagName === ELEMENTS.TrackDeleteElement.TAG ||
                parentNode.tagName === ELEMENTS.TrackInsertElement.TAG
              ) {
                refNode = parentNode;
                parentNode = this.page;
              }

              const data = NodeDataBuilder.buildParagraph({
                data: {
                  parent_id: parentNode.id,
                },
              });

              this.inserBlockNodeOperation(actionContext, data, refNode, 'AFTER');

              this.selectionManager.setCaret(DOMUtils.getBlockNode(data.id));
              return;
            }

            if (contentEditable && this.selectionManager.isSelectionAtStart(contentEditable)) {
              let parentNode = level0Node.parentNode;
              let refNode = level0Node;
              if (
                parentNode.tagName === ELEMENTS.TrackDeleteElement.TAG ||
                parentNode.tagName === ELEMENTS.TrackInsertElement.TAG
              ) {
                refNode = parentNode;
                parentNode = this.page;
              }

              const data = NodeDataBuilder.buildParagraph({
                data: {
                  parent_id: parentNode.id,
                },
              });

              this.inserBlockNodeOperation(actionContext, data, refNode, 'Before');
              return;
            }
          }
        } catch (e) {
          Logger.captureException(e);
        }
      }

      /**
       * handle enter event on list elements~
       * @param {ActionContext} actionContext
       * @param {Node} anchorNode
       */
      handleEnterOnListElement(actionContext, listElement, anchorNode, anchorOffset) {
        if (
          listElement.firstChild === listElement.lastChild &&
          (this.isAddParagraphMarker(listElement.firstChild) ||
            this.isDeleteParagraphMarker(listElement.firstChild)) &&
          !this.dataManager.numbering.isBlockInOutlineList(listElement.id)
        ) {
          const listElements = [];
          const pageNode = DOMUtils.getPageNode(listElement);
          if (listElement.parentNode !== pageNode) {
            const level0 = DOMUtils.findNodeLevel0(pageNode, listElement);

            listElements.push({
              blockId: level0.id,
              id: listElement.id,
            });
          } else {
            listElements.push({
              blockId: listElement.id,
              id: listElement.id,
            });
          }
          this.dataManager.numbering.outdentNodes(actionContext, listElements, true);
          if (
            listElement.nextSibling &&
            this.dataManager.numbering.isBulletListElement(listElement.nextSibling.id)
          ) {
            this.dataManager.numbering.restartListNumbering(
              actionContext,
              listElement.nextSibling.id,
            );
          }
        } else if (
          EditorDOMUtils.isEmptyElement(listElement) &&
          !this.dataManager.numbering.isBlockInOutlineList(listElement.id)
        ) {
          this.dataManager.numbering.removeBlocksFromList([listElement.id]);
          actionContext.jsonChanges = true;
        } else {
          this.handleEnterOnTextElement(actionContext, listElement, anchorNode, anchorOffset);

          // const listId = this.persistenceManager.getListIdFromBlock(before.id);
          // const listLevel = this.persistenceManager.getListLevelFromBlock(before.id);
          // this.persistenceManager.addBlocksToList(
          //   actionContext,
          //   [after.id],
          //   listId,
          //   listLevel,
          //   before.id,
          // );
          // if (!before.previousSibling && before.textContent === '') {
          //   this.persistenceManager.removeBlocksFromList([before.id]);
          //   actionContext.jsonChanges = true;
          // }
        }
      }

      /**
       * handle enter event on a figure element
       * @param {ActionContext} actionContext
       * @param {Node} figureNode
       * @param {Node} anchorNode
       */
      handleEnterOnFigureElement(actionContext, figureNode, anchorNode, anchorOffset) {
        const closest = DOMUtils.closest(anchorNode, ['IMAGE-ELEMENT', ELEMENTS.FigureElement.TAG]);
        if (closest) {
          if (
            closest?.tagName === 'IMAGE-ELEMENT' ||
            closest?.tagName === ELEMENTS.FigureElement.TAG
          ) {
            let parentNode = figureNode.parentNode;
            let refNode = figureNode;
            if (
              parentNode.tagName === ELEMENTS.TrackDeleteElement.TAG ||
              parentNode.tagName === ELEMENTS.TrackInsertElement.TAG
            ) {
              refNode = parentNode;
              parentNode = this.page;
            }

            if (
              (closest === anchorNode && anchorOffset === 0) ||
              (closest !== anchorNode && !anchorNode.previousSibling)
            ) {
              const closestContainer = DOMUtils.closest(
                refNode,
                DOMUtils.MULTI_BLOCK_CONTAINER_ELEMENTS,
              );
              if (closestContainer) {
                const p = DOMElementFactory.createNewParagraphElement();
                DOMUtils.insertNodeAfter(refNode.parentNode, p, refNode);
                actionContext.addChangeAddedNode(p);
                this.selectionManager.setCaret(p, 'INSIDE_START');
              } else {
                const data = NodeDataBuilder.buildParagraph({
                  data: {
                    parent_id: parentNode.id,
                  },
                });

                this.inserBlockNodeOperation(actionContext, data, refNode, 'BEFORE');
                this.selectionManager.setCaret(DOMUtils.getBlockNode(data.id));
              }
            } else if (
              (closest === anchorNode && anchorOffset === closest.childNodes.length) ||
              (closest !== anchorNode && !anchorNode.nextSibling)
            ) {
              const closestContainer = DOMUtils.closest(
                refNode,
                DOMUtils.MULTI_BLOCK_CONTAINER_ELEMENTS,
              );
              if (closestContainer) {
                const p = DOMElementFactory.createNewParagraphElement();
                DOMUtils.insertNodeAfter(refNode.parentNode, p, refNode);
                actionContext.addChangeAddedNode(p);
                this.selectionManager.setCaret(p, 'INSIDE_START');
              } else {
                const data = NodeDataBuilder.buildParagraph({
                  data: {
                    parent_id: parentNode.id,
                  },
                });

                this.inserBlockNodeOperation(actionContext, data, refNode, 'AFTER');
                this.selectionManager.setCaret(DOMUtils.getBlockNode(data.id));
              }
            }
          }
        }
      }

      /**
       * handle enter event on table element
       * @param {ActionContext} actionContext
       * @param {Node} anchorNode
       */
      handleEnterOnTableElement(actionContext, baseNode, anchorNode, anchorOffset) {
        let parentNode = baseNode.parentNode;
        let refNode = baseNode;
        if (
          parentNode.tagName === ELEMENTS.TrackDeleteElement.TAG ||
          parentNode.tagName === ELEMENTS.TrackInsertElement.TAG
        ) {
          refNode = parentNode;
          parentNode = this.page;
        }

        const handleAnchorNodeTableCell = (pNode, rNode) => {
          const p = DOMElementFactory.createNewParagraphElement();

          if (anchorOffset === 0) {
            DOMUtils.insertNodeBefore(pNode, p, rNode);
            actionContext.addChangeAddedNode(p);
            this.selectionManager.setCaret(p, 'INSIDE_END');
          } else if (anchorOffset === anchorNode.childNodes.length) {
            DOMUtils.insertNodeAfter(pNode, p, rNode);
            actionContext.addChangeAddedNode(p);
            this.selectionManager.setCaret(p, 'INSIDE_END');
          }
        };

        const closest = DOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);
        if (closest && !closest.hasAttribute('lock')) {
          if (closest.tagName === ELEMENTS.TableCellElement.TAG) {
            const row = closest.parentNode;
            const body = row.parentNode;
            const table = body.parentNode;

            if (
              !table.caption &&
              Array.from(row.childNodes).indexOf(closest) === 0 &&
              Array.from(body.childNodes).indexOf(row) === 0 &&
              this.selectionManager.isSelectionAtStart(closest.firstChild) &&
              (refNode.previousSibling == null ||
                refNode.previousSibling.tagName !== ELEMENTS.ParagraphElement.TAG)
            ) {
              // if no caption and selection it's at start of the first cell and there is no previous sibling
              const data = NodeDataBuilder.buildParagraph({
                data: {
                  parent_id: parentNode.id,
                },
              });

              this.inserBlockNodeOperation(actionContext, data, refNode, 'BEFORE');
              const node = DOMUtils.getNode(data.id);
              this.selectionManager.setCaret(node, 'INSIDE_START');
            } else {
              const tdLevel0Node = DOMUtils.findNodeLevel0(closest, anchorNode);
              if (tdLevel0Node) {
                if (tdLevel0Node.tagName !== ELEMENTS.TableElement.TAG) {
                  this.handleEnterOnCollapsedSelection(
                    actionContext,
                    tdLevel0Node,
                    anchorNode,
                    anchorOffset,
                  );
                } else if (tdLevel0Node !== baseNode) {
                  handleAnchorNodeTableCell(closest, tdLevel0Node);
                }
              }
            }
          }
        } else if (anchorNode.tagName === ELEMENTS.TableElement.TAG) {
          const paragraphData = NodeDataBuilder.buildNodeData({
            data: {
              type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
              parent_id: parentNode.id,
            },
          });
          if (anchorOffset === 0) {
            this.inserBlockNodeOperation(actionContext, paragraphData, refNode, 'BEFORE');
          } else if (anchorOffset === anchorNode.childNodes.length) {
            this.inserBlockNodeOperation(actionContext, paragraphData, refNode, 'AFTER');
            const node = DOMUtils.getNode(paragraphData.id);
            this.selectionManager.setCaret(node, 'INSIDE_START');
          }
        }
      }

      handleEnterOnContainerElement(actionContext, baseNode, anchorNode, anchorOffset) {
        if (this.selectionManager.isSelectionAtStart(baseNode)) {
          const data = NodeDataBuilder.buildParagraph({
            data: {
              parent_id: baseNode.parentNode.id,
            },
          });

          this.inserBlockNodeOperation(actionContext, data, baseNode, 'BEFORE');
          return;
        } else if (this.selectionManager.isSelectionAtEnd(baseNode)) {
          const data = NodeDataBuilder.buildParagraph({
            data: {
              parent_id: baseNode.parentNode.id,
            },
          });

          this.inserBlockNodeOperation(actionContext, data, baseNode, 'AFTER');

          this.selectionManager.setCaret(DOMUtils.getBlockNode(data.id));
          return;
        } else {
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        }

        const subLevel0Node = DOMUtils.findNodeLevel0(baseNode, anchorNode);
        if (subLevel0Node) {
          this.handleEnterOnCollapsedSelection(
            actionContext,
            subLevel0Node,
            anchorNode,
            anchorOffset,
          );
        }
      }

      /**
       * handle enter event on a default text element
       * @param {ActionContext} actionContext
       */
      handleEnterOnTextElement(actionContext) {
        this.selectionManager.fixCollapsedTextSelection();

        return this.splitSelectionContent(actionContext);
      }
    },
);
