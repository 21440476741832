//@ts-expect-error needs mixins refactor
import { Mixin } from 'mixwith';
import { FigureElement, ImageElement } from 'Editor/services/VisualizerManager';
import { NodeModel } from 'Editor/services/DataManager';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

const MAX_IMAGE_WIDTH = 663;

export default Mixin(
  (superclass: any) =>
    class ImageOperations extends superclass {
      destroy() {
        super.destroy();
      }

      private getOpsUpdateImageSize(
        imageData: Editor.Data.Node.ImageData,
        imagePath: Realtime.Core.RealtimePath,
        height: number, // in points
        width: number, // in points
      ) {
        const ops: Realtime.Core.RealtimeOp[] = [];

        if (width >= MAX_IMAGE_WIDTH) {
          height = (height * MAX_IMAGE_WIDTH) / width;
          width = MAX_IMAGE_WIDTH;
        }

        if (imageData && imagePath) {
          const op1 = this.getObjectOperationforPathValue(imageData.properties?.h, height, [
            ...imagePath,
            'properties',
            'h',
          ]);
          if (op1) {
            ops.push(op1);
          }

          const op2 = this.getObjectOperationforPathValue(imageData.properties?.w, width, [
            ...imagePath,
            'properties',
            'w',
          ]);
          if (op2) {
            ops.push(op2);
          }
        }

        return ops;
      }

      updateImageSizeOperation(
        level0Node: HTMLElement,
        image: ImageElement,
        height: number, // in points
        width: number, // in points
      ) {
        if (!level0Node || !image || height == null || width == null) {
          return;
        }

        const ops: Realtime.Core.RealtimeOp[] = [];

        const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

        // check if level0 is a Figure and change to paragraph
        if (level0Node instanceof FigureElement) {
          ops.push(...this.getOpsToParseFigureForParagaph(level0Node, level0Model));
        }

        let imageData = level0Model.getChildDataById(image.id) as Editor.Data.Node.ImageData;
        let imagePath: (string | number)[] = level0Model.findPathToChild(image.id);

        ops.push(...this.getOpsUpdateImageSize(imageData, imagePath, height, width));

        // apply ops
        if (ops.length > 0) {
          level0Model.apply(ops, { source: 'LOCAL_RENDER' });
        }
      }

      updateImageSourceOperation(
        level0Node: HTMLElement,
        image: ImageElement,
        source: string,
        height: number, // in points
        width: number, // in points
      ) {
        if (!level0Node || !image || !source || height == null || width == null) {
          return;
        }

        const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

        let imageData = level0Model.getChildDataById(image.id) as Editor.Data.Node.ImageData;
        let imagePath: (string | number)[] = level0Model.findPathToChild(image.id);

        const ops: Realtime.Core.RealtimeOp[] = [];

        // check if level0 is a Figure and change to paragraph
        if (level0Node instanceof FigureElement) {
          ops.push(...this.getOpsToParseFigureForParagaph(level0Node, level0Model));
        }

        const editorSource = imageData.properties?.es || image.sourceId;

        let op = this.getObjectOperationforPathValue(editorSource, source, [
          ...imagePath,
          level0Model.KEYS.PROPERTIES,
          'es',
        ]);
        if (op) {
          ops.push(op);
        }

        if (imageData.properties?.os != null) {
          op = this.getObjectOperationforPathValue(imageData.properties?.os, null, [
            ...imagePath,
            level0Model.KEYS.PROPERTIES,
            'os',
          ]);
          if (op) {
            ops.push(op);
          }
        }

        if (imageData.properties?.w) {
          // maintain width update height if needed
          const newHeight = (imageData.properties.w * height) / width;

          op = this.getObjectOperationforPathValue(imageData.properties?.h, newHeight, [
            ...imagePath,
            'properties',
            'h',
          ]);
          if (op) {
            ops.push(op);
          }
        } else {
          ops.push(...this.getOpsUpdateImageSize(imageData, imagePath, height, width));
        }

        // apply ops
        if (ops.length > 0) {
          level0Model.apply(ops, { source: 'LOCAL_RENDER' });
        }
      }

      private getOpsUpdateImageWrapInline(
        imageData: Editor.Data.Node.ImageData,
        imagePath: Realtime.Core.RealtimePath,
      ) {
        const ops: Realtime.Core.RealtimeOp[] = [];

        if (imageData && imagePath) {
          if (imageData.properties?.f !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.f, null, [
              ...imagePath,
              'properties',
              'f',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.bd !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.bd, null, [
              ...imagePath,
              'properties',
              'bd',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.lc !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.lc, null, [
              ...imagePath,
              'properties',
              'lc',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.l !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.l, null, [
              ...imagePath,
              'properties',
              'l',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ao !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ao, null, [
              ...imagePath,
              'properties',
              'ao',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ax !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ax, null, [
              ...imagePath,
              'properties',
              'ax',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ay !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ay, null, [
              ...imagePath,
              'properties',
              'ay',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ox !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ox, null, [
              ...imagePath,
              'properties',
              'ox',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.oy !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.oy, null, [
              ...imagePath,
              'properties',
              'oy',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.rfx !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.rfx, null, [
              ...imagePath,
              'properties',
              'rfx',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.rfy !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.rfy, null, [
              ...imagePath,
              'properties',
              'rfy',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.wr !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.wr, null, [
              ...imagePath,
              'properties',
              'wr',
            ]);
            if (op) {
              ops.push(op);
            }
          }
        }

        return ops;
      }

      private getOpsUpdateImageWrapAbsolute(
        image: ImageElement,
        imageData: Editor.Data.Node.ImageData,
        imagePath: Realtime.Core.RealtimePath,
        imageWrap: Extract<Editor.Styles.ImageWrapProperty, 'behindText' | 'inFrontText'>,
        margins: Editor.Styles.ImageProperties['margins'],
      ) {
        const ops: Realtime.Core.RealtimeOp[] = [];

        if (imageData && imagePath) {
          if (imageData.properties?.f !== true) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.f, true, [
              ...imagePath,
              'properties',
              'f',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          let behindDoc;
          if (imageWrap === 'behindText' && imageData.properties?.bd !== true) {
            behindDoc = true;
          } else if (imageWrap === 'inFrontText' && imageData.properties?.bd !== false) {
            behindDoc = false;
          }
          if (behindDoc != null) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.bd, behindDoc, [
              ...imagePath,
              'properties',
              'bd',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.lc !== true) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.lc, true, [
              ...imagePath,
              'properties',
              'lc',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.l !== false) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.l, false, [
              ...imagePath,
              'properties',
              'l',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ao !== true) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ao, true, [
              ...imagePath,
              'properties',
              'ao',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ax !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ax, null, [
              ...imagePath,
              'properties',
              'ax',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ay !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ay, null, [
              ...imagePath,
              'properties',
              'ay',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (image.offsetLeft != null) {
            let op = this.getObjectOperationforPathValue(
              imageData.properties?.ox,
              EditorDOMUtils.convertUnitTo(image.offsetLeft, 'px', 'pt', 3),
              [...imagePath, 'properties', 'ox'],
            );
            if (op) {
              ops.push(op);
            }
          }

          if (image.offsetTop != null) {
            let op = this.getObjectOperationforPathValue(
              imageData.properties?.oy,
              EditorDOMUtils.convertUnitTo(image.offsetTop, 'px', 'pt', 3),
              [...imagePath, 'properties', 'oy'],
            );
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.rfx !== 'c') {
            let op = this.getObjectOperationforPathValue(imageData.properties?.rfx, 'c', [
              ...imagePath,
              'properties',
              'rfx',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.rfy !== 'p') {
            let op = this.getObjectOperationforPathValue(imageData.properties?.rfy, 'p', [
              ...imagePath,
              'properties',
              'rfy',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          let wrapping: Editor.Data.Node.ImageProperties['wr'] = {
            t: 'n',
            td: margins?.top?.value || imageData.properties?.wr?.td || 0,
            bd: margins?.bottom?.value || imageData.properties?.wr?.bd || 0,
            ld: margins?.left?.value || imageData.properties?.wr?.ld || 0,
            rd: margins?.right?.value || imageData.properties?.wr?.rd || 0,
          };

          let op = this.getObjectOperationforPathValue(imageData.properties?.wr, wrapping, [
            ...imagePath,
            'properties',
            'wr',
          ]);
          if (op) {
            ops.push(op);
          }
        }

        return ops;
      }

      private getOpsUpdateImageWrapText(
        image: ImageElement,
        imageData: Editor.Data.Node.ImageData,
        imagePath: Realtime.Core.RealtimePath,
        imageWrap: Extract<Editor.Styles.ImageWrapProperty, 'left' | 'right'>,
        margins: Editor.Styles.ImageProperties['margins'],
      ) {
        const ops: Realtime.Core.RealtimeOp[] = [];

        if (imageData && imagePath) {
          if (imageData.properties?.f !== true) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.f, true, [
              ...imagePath,
              'properties',
              'f',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.bd !== false) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.bd, false, [
              ...imagePath,
              'properties',
              'bd',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.lc !== true) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.lc, true, [
              ...imagePath,
              'properties',
              'lc',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.l !== false) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.l, false, [
              ...imagePath,
              'properties',
              'l',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ao !== true) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ao, true, [
              ...imagePath,
              'properties',
              'ao',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          let align;
          if (imageWrap === 'left') {
            align = 'l';
          } else if (imageWrap === 'right') {
            align = 'r';
          }

          if (align !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ax, align, [
              ...imagePath,
              'properties',
              'ax',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ay !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ay, null, [
              ...imagePath,
              'properties',
              'ay',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ox !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ox, null, [
              ...imagePath,
              'properties',
              'ox',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.oy !== 0) {
            let op = this.getObjectOperationforPathValue(
              imageData.properties?.oy,
              EditorDOMUtils.convertUnitTo(image.offsetTop || 0, 'px', 'pt', 3),
              [...imagePath, 'properties', 'oy'],
            );
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.rfx !== 'c') {
            let op = this.getObjectOperationforPathValue(imageData.properties?.rfx, 'c', [
              ...imagePath,
              'properties',
              'rfx',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.rfy !== 'p') {
            let op = this.getObjectOperationforPathValue(imageData.properties?.rfy, 'p', [
              ...imagePath,
              'properties',
              'rfy',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          let wrapping: Editor.Data.Node.ImageProperties['wr'] = {
            t: 'ti',
            td: imageData.properties?.wr?.td || 0,
            bd: imageData.properties?.wr?.bd || 0,
            ld: imageData.properties?.wr?.ld || 9.07,
            rd: imageData.properties?.wr?.rd || 9.07,
            wt: 'bs',
            wp: {
              lt: [
                { x: 0, y: 1.655 },
                { x: 1.678, y: 1.655 },
                { x: 1.678, y: 0 },
                { x: 0, y: 0 },
              ],
              s: { x: 0, y: 0 },
            },
          };

          if (margins?.top?.value != null) {
            wrapping.td = margins.top.value;
          }

          if (margins?.bottom?.value != null) {
            wrapping.bd = margins.bottom.value;
          }

          if (margins?.left?.value != null) {
            wrapping.ld = margins.left.value;
          }

          if (margins?.right?.value != null) {
            wrapping.rd = margins.right.value;
          }

          let op = this.getObjectOperationforPathValue(imageData.properties?.wr, wrapping, [
            ...imagePath,
            'properties',
            'wr',
          ]);
          if (op) {
            ops.push(op);
          }
        }

        return ops;
      }

      private getOpsUpdateImageWrapTopBottom(
        image: ImageElement,
        imageData: Editor.Data.Node.ImageData,
        imagePath: Realtime.Core.RealtimePath,
        margins: Editor.Styles.ImageProperties['margins'],
      ) {
        const ops: Realtime.Core.RealtimeOp[] = [];

        if (imageData && imagePath) {
          if (imageData.properties?.f !== true) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.f, true, [
              ...imagePath,
              'properties',
              'f',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.bd !== false) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.bd, false, [
              ...imagePath,
              'properties',
              'bd',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.lc !== true) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.lc, true, [
              ...imagePath,
              'properties',
              'lc',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.l !== false) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.l, false, [
              ...imagePath,
              'properties',
              'l',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ao !== true) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ao, true, [
              ...imagePath,
              'properties',
              'ao',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ax !== 'c') {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ax, 'c', [
              ...imagePath,
              'properties',
              'ax',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ay !== undefined) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ay, null, [
              ...imagePath,
              'properties',
              'ay',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.ox) {
            let op = this.getObjectOperationforPathValue(imageData.properties?.ox, null, [
              ...imagePath,
              'properties',
              'ox',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (image.offsetTop) {
            let op = this.getObjectOperationforPathValue(
              imageData.properties?.oy,
              EditorDOMUtils.convertUnitTo(image.offsetTop, 'px', 'pt', 3),
              [...imagePath, 'properties', 'oy'],
            );
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.rfx !== 'c') {
            let op = this.getObjectOperationforPathValue(imageData.properties?.rfx, 'c', [
              ...imagePath,
              'properties',
              'rfx',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          if (imageData.properties?.rfy !== 'p') {
            let op = this.getObjectOperationforPathValue(imageData.properties?.rfy, 'p', [
              ...imagePath,
              'properties',
              'rfy',
            ]);
            if (op) {
              ops.push(op);
            }
          }

          let wrapping: Editor.Data.Node.ImageProperties['wr'] = {
            t: 'tb',
            td: margins?.top?.value || imageData.properties?.wr?.td || 0,
            bd: margins?.bottom?.value || imageData.properties?.wr?.bd || 0,
            ld: margins?.left?.value || imageData.properties?.wr?.ld || 0,
            rd: margins?.right?.value || imageData.properties?.wr?.rd || 0,
            wt: 'bs',
          };

          let op = this.getObjectOperationforPathValue(imageData.properties?.wr, wrapping, [
            ...imagePath,
            'properties',
            'wr',
          ]);
          if (op) {
            ops.push(op);
          }
        }

        return ops;
      }

      private getOpsToParseFigureForParagaph(level0Node: FigureElement, level0Model: NodeModel) {
        const ops = [];

        let level0Data = level0Model.get() as Editor.Data.Node.Data;
        let op = this.getObjectOperationforPathValue(level0Data.type, 'p', ['type']);
        if (op) {
          ops.push(op);
        }

        return ops;
      }

      updateImageProperties(
        level0Node: HTMLElement,
        image: ImageElement,
        properties: Editor.Styles.ImageProperties,
      ) {
        if (!level0Node || !image || !properties) {
          return;
        }

        const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

        let { data, path } = level0Model.getChildInfoById(image.id);

        const ops: Realtime.Core.RealtimeOps = [];

        // TODO:
        // check edtion with inline images
        // check clipboard

        // check if level0 is a Figure and change to paragraph
        if (level0Node instanceof FigureElement) {
          ops.push(...this.getOpsToParseFigureForParagaph(level0Node, level0Model));
        }

        if (level0Model.isImageData(data)) {
          switch (properties.imageWrap?.value) {
            case 'inline':
              ops.push(...this.getOpsUpdateImageWrapInline(data, path));
              break;
            case 'behindText':
            case 'inFrontText':
              ops.push(
                ...this.getOpsUpdateImageWrapAbsolute(
                  image,
                  data,
                  path,
                  properties.imageWrap.value,
                  properties.margins,
                ),
              );
              break;
            case 'left':
            case 'right':
              ops.push(
                ...this.getOpsUpdateImageWrapText(
                  image,
                  data,
                  path,
                  properties.imageWrap.value,
                  properties.margins,
                ),
              );
              break;
            case 'topAndBottom':
              ops.push(
                ...this.getOpsUpdateImageWrapTopBottom(image, data, path, properties.margins),
              );
              break;
          }
        }

        // apply ops
        if (ops.length > 0) {
          level0Model.apply(ops, { source: 'LOCAL_RENDER' });
        }
      }
    },
);
