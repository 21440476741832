/* eslint-disable no-lonely-if, class-methods-use-this */
import { Mixin } from 'mixwith';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import KeydownEventValidator from 'Editor/services/EditionManager/KeydownEventValidator';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';

export default Mixin(
  (superclass) =>
    class DefaultHandler extends superclass {
      destroy() {
        super.destroy();
      }

      /**
       * selects a function to handle default key events based on baseNode
       * @param {ActionContext} actionContext
       * @param {Event} e
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleDefaultOnCollapsedSelection(actionContext, e, baseNode, anchorNode, anchorOffset) {
        if (!baseNode || DOMUtils.isNodeAContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        }

        if (baseNode) {
          if (anchorNode) {
            if (DOMUtils.isNodeEditableTextElement(baseNode)) {
              // SELECTION IS A DEFAULT TEXT ELEMENT
              this.handleDefaultOnTextElement(actionContext, e, baseNode, anchorNode, anchorOffset);
            } else if (!DOMUtils.isBlockNodeEditable(baseNode)) {
              // SELECTION IS A NON-EDITABLE ELEMENT
              this.handleNonEditableDefault(actionContext, e);
            } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
              // SELECTION IS A FIGURE
              this.handleDefaultOnFigureElement(
                actionContext,
                e,
                baseNode,
                anchorNode,
                anchorOffset,
              );
            } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
              // SELECTION IS A TABLE
              this.handleDefaultOnTableElement(
                actionContext,
                e,
                baseNode,
                anchorNode,
                anchorOffset,
              );
            } else if (DOMUtils.isNodeAContainerElement(baseNode)) {
              this.handleDefaultOnContainerElement(
                actionContext,
                e,
                baseNode,
                anchorNode,
                anchorOffset,
              );
            }
          }
        }
      }

      /**
       * handle default key events on multi selection
       * @param {ActionContext} actionContext
       * @param {Event} e
       */
      handleDefaultOnMultiSelection(actionContext, e) {
        actionContext.selectionBackwards = this.selectionManager.isSelectionBackwards();
        actionContext.selectionType = ActionContext.SELECTION.RANGE;
        this.selectionManager.fixNonCollapsedTextSelection({
          suggestionMode: true,
          forceTextAsWrap: true,
        });

        if (this.removeSelectionContent(actionContext)) {
          const selection = EditorSelectionUtils.getSelection();
          const anchorNode = selection.anchorNode;
          const anchorOffset = selection.anchorOffset;
          const level0Node = DOMUtils.findNodeLevel0(this.page, anchorNode);
          this.handleDefaultOnCollapsedSelection(
            actionContext,
            e,
            level0Node,
            anchorNode,
            anchorOffset,
          );
        }
      }

      handleDefaultOnContainerElement(actionContext, e, baseNode, anchorNode, anchorOffset) {
        if (anchorNode === baseNode) {
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        } else if (baseNode.tagName === ELEMENTS.TrackDeleteElement.TAG) {
          if (baseNode.nextSibling) {
            this.selectionManager.setCaret(baseNode.nextSibling, 'INSIDE_START');
          }
          return;
        }

        const subLevel0Node = DOMUtils.findNodeLevel0(baseNode, anchorNode);
        if (subLevel0Node) {
          this.handleDefaultOnCollapsedSelection(
            actionContext,
            e,
            subLevel0Node,
            anchorNode,
            anchorOffset,
          );
        }
      }

      /**
       * handle default key event on a default text element
       * @param {ActionContext} actionContext
       * @param {Event} e
       * @param {Node} level0Node
       * @param {Node} anchorNode
       */
      handleDefaultOnTextElement(actionContext, e, level0Node, anchorNode) {
        if (KeydownEventValidator.isKeyPrintable(e)) {
          this.selectionManager.fixCollapsedTextSelection({ suggestionMode: true });

          anchorNode = EditorSelectionUtils.getSelection().anchorNode;

          const closest = DOMUtils.closest(anchorNode, 'TRACK-INS-ELEMENT');
          if (closest) {
            const isUserAuthor = this.isUserAuthor(closest);
            if (
              closest &&
              isUserAuthor &&
              (this.isDeleteParagraphMarker(closest) || this.isAddParagraphMarker(closest))
            ) {
              const insertSuggestion = this.getTrackInsElement(actionContext.id);
              DOMUtils.insertNodeBefore(closest.parentNode, insertSuggestion, closest);
              actionContext.addReferenceToRefresh(
                insertSuggestion.getAttribute('element_reference'),
              );
              actionContext.addChangeUpdatedNode(insertSuggestion);
              this.selectionManager.setCaret(insertSuggestion, 'INSIDE_END');
            } else if (closest && isUserAuthor) {
              actionContext.addReferenceToRefresh(closest.getAttribute('element_reference'));
            } else if (
              closest &&
              !isUserAuthor &&
              this.selectionManager.isSelectionAtEnd(closest) &&
              !(
                closest.nextSibling &&
                closest.nextSibling.getAttribute?.('element_reference') ===
                  closest.getAttribute('element_reference')
              ) &&
              closest.parentNode !== DOMUtils.getPageNode()
            ) {
              this.selectionManager.setCaret(closest, 'POST');
              const insertSuggestion = this.getTrackInsElement(actionContext.id);
              this.insertInlineNode(actionContext, insertSuggestion);
              this.selectionManager.setCaret(insertSuggestion, 'INSIDE_END');
            } else if (
              this.selectionManager.isSelectionAtStart(level0Node) &&
              level0Node.previousSibling &&
              level0Node.previousSibling.lastChild &&
              (this.isAddParagraphMarker(level0Node.previousSibling.lastChild) ||
                this.isDeleteParagraphMarker(level0Node.previousSibling.lastChild)) &&
              this.isUserAuthor(level0Node.previousSibling.lastChild)
            ) {
              const insertSuggestion = this.getTrackInsElement(
                level0Node.previousSibling.lastChild.getAttribute('element_reference'),
              );
              actionContext.addReferenceToRefresh(
                level0Node.previousSibling.lastChild.getAttribute('element_reference'),
              );
              this.insertInlineNode(actionContext, insertSuggestion);
              this.selectionManager.setCaret(insertSuggestion, 'INSIDE_END');
            } else {
              const insertSuggestion = this.getTrackInsElement(actionContext.id);

              actionContext.addReferenceToRefresh(
                insertSuggestion.getAttribute('element_reference'),
              );
              this.insertInlineNode(actionContext, insertSuggestion);
              this.selectionManager.setCaret(insertSuggestion, 'INSIDE_END');
            }

            actionContext.addReferenceToRefresh(closest.getAttribute('element_reference'));
          } else {
            if (
              this.selectionManager.isSelectionAtStart(level0Node) &&
              level0Node.previousSibling &&
              level0Node.previousSibling.lastChild &&
              (this.isAddParagraphMarker(level0Node.previousSibling.lastChild) ||
                this.isDeleteParagraphMarker(level0Node.previousSibling.lastChild)) &&
              this.isUserAuthor(level0Node.previousSibling.lastChild)
            ) {
              const insertSuggestion = this.getTrackInsElement(
                level0Node.previousSibling.lastChild.getAttribute?.('element_reference'),
              );
              actionContext.addReferenceToRefresh(
                level0Node.previousSibling.lastChild.getAttribute?.('element_reference'),
              );
              this.insertInlineNode(actionContext, insertSuggestion);
              this.selectionManager.setCaret(insertSuggestion, 'INSIDE_END');
            } else if (
              anchorNode !== level0Node &&
              this.selectionManager.isSelectionAtStart(anchorNode) &&
              this.isNodeTrackedAction(anchorNode.previousSibling, 'TRACK-INS-ELEMENT')
            ) {
              this.selectionManager.setCaret(anchorNode.previousSibling, 'END');
              actionContext.addReferenceToRefresh(
                anchorNode.previousSibling.getAttribute('element_reference'),
              );
            } else if (
              anchorNode !== level0Node &&
              this.selectionManager.isSelectionAtEnd(anchorNode) &&
              this.isNodeTrackedAction(anchorNode.nextSibling, 'TRACK-INS-ELEMENT') &&
              !this.isAddParagraphMarker(anchorNode.nextSibling) &&
              !this.isDeleteParagraphMarker(anchorNode.nextSibling)
            ) {
              this.selectionManager.setCaret(anchorNode.nextSibling, 'START');
              actionContext.addReferenceToRefresh(
                anchorNode.nextSibling.getAttribute('element_reference'),
              );
            } else {
              const insertSuggestion = this.getTrackInsElement(actionContext.id);

              actionContext.addReferenceToRefresh(
                insertSuggestion.getAttribute('element_reference'),
              );
              this.insertInlineNode(actionContext, insertSuggestion);
              this.selectionManager.setCaret(insertSuggestion, 'INSIDE_END');
            }
          }

          // insert normal text element
          this.insertTextInline(actionContext, e.key);
        }
      }

      /**
       * handle default key event on a figure element
       * @param {ActionContext} actionContext
       * @param {Event} e
       * @param {Node} figureNode
       * @param {Node} anchorNode
       */
      handleDefaultOnFigureElement() {
        // TODO:
        // const closest = DOMUtils.closest(anchorNode, ['FIGCAPTION']);
        // if (closest && !closest.hasAttribute('lock')) {
        //   this.handleDefaultOnTextElement(actionContext, e, figureNode, anchorNode, anchorOffset);
        // }
      }

      /**
       * handle default key event on table element
       * @param {ActionContext} actionContext
       * @param {Event} e
       * @param {Node} tableNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleDefaultOnTableElement(actionContext, e, tableNode, anchorNode, anchorOffset) {
        const closest = DOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);
        if (closest && !closest.hasAttribute('lock')) {
          if (closest.tagName === ELEMENTS.TableCellElement.TAG) {
            let tdLevel0Node = DOMUtils.findNodeLevel0(closest, anchorNode);

            if (!tdLevel0Node && closest.childNodes.length === 0) {
              tdLevel0Node = DOMElementFactory.createNewParagraphElement();
              DOMUtils.appendNode(closest, tdLevel0Node);
              actionContext.addChangeUpdatedNode(tableNode);
              this.selectionManager.setCaret(tdLevel0Node, 'INSIDE_START');
            }

            if (tdLevel0Node && tdLevel0Node.tagName !== ELEMENTS.TableElement.TAG) {
              this.handleDefaultOnCollapsedSelection(
                actionContext,
                e,
                tdLevel0Node,
                anchorNode,
                anchorOffset,
              );
            }
          }
        }
      }

      /**
       * handle default key events on a non-editable element
       * @param {ActionContext} actionContext
       * @param {Event} e
       */
      handleNonEditableDefault() {
        // e.stopPropagation();
        // e.preventDefault();
      }
    },
);
