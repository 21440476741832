import { Logger } from '_common/services';

import {
  FilterIdentity,
  FILTERS,
  OptionByType,
  SettingByType,
  Settings,
  TYPES,
} from './FilterController';
import UserHandler from './Components/Handlers/UserHandler';
import SelectHandler from './Components/Handlers/SelectHandler';
import DateRangeHandler from './Components/Handlers/DateRangeHandler';
import SwitchHandler from './Components/Handlers/SwitchHandler';
import CheckboxHandler from './Components/Handlers/CheckboxHandler';

export type FiltersProps = {
  identity: FilterIdentity;
  testId?: string;
} & Settings;

const Filters = ({ identity, testId = `${identity}-filters`, ...settings }: FiltersProps) => {
  const renderFilterByType = () => {
    const nonGenericIdentity = (identity.match(/[a-zA-Z]*-/i)?.[0] as FilterIdentity) ?? identity;

    return FILTERS[nonGenericIdentity].map((filterName) => {
      if (TYPES.user.includes(filterName) || TYPES.multiUser.includes(filterName)) {
        return (
          <UserHandler
            key={`filter-${identity}-${filterName}`}
            identity={identity}
            filter={filterName}
            filterQuantity={FILTERS[nonGenericIdentity].length}
            options={settings[filterName]?.options as OptionByType['user']}
            settings={settings[filterName]?.settings as SettingByType['user']}
            testId={`${testId}-${filterName}`}
          />
        );
      } else if (TYPES.select.includes(filterName) || TYPES.multiSelect.includes(filterName)) {
        return (
          <SelectHandler
            key={`filter-${identity}-${filterName}`}
            identity={identity}
            filter={filterName}
            filterQuantity={FILTERS[nonGenericIdentity].length}
            options={settings[filterName]?.options as OptionByType['select']}
            settings={settings[filterName]?.settings as SettingByType['select']}
            testId={`${testId}-${filterName}`}
          />
        );
      } else if (TYPES.dateRange.includes(filterName)) {
        return (
          <DateRangeHandler
            key={`filter-${identity}-${filterName}`}
            identity={identity}
            filter={filterName}
            filterQuantity={FILTERS[nonGenericIdentity].length}
            settings={settings[filterName]?.settings as SettingByType['dateRange']}
            testId={`${testId}-${filterName}`}
          />
        );
      } else if (TYPES.switch.includes(filterName)) {
        return (
          <SwitchHandler
            key={`filter-${identity}-${filterName}`}
            identity={identity}
            filter={filterName}
            filterQuantity={FILTERS[nonGenericIdentity].length}
            options={settings[filterName]?.options as OptionByType['switch']}
            testId={`${testId}-${filterName}`}
          />
        );
      } else if (TYPES.checkbox.includes(filterName)) {
        return (
          <CheckboxHandler
            key={`filter-${identity}-${filterName}`}
            identity={identity}
            filter={filterName}
            filterQuantity={FILTERS[nonGenericIdentity].length}
            options={settings[filterName]?.options as OptionByType['checkbox']}
            testId={`${testId}-${filterName}`}
          />
        );
      }

      Logger.error(`[FilterController] - '${filterName}' not declared on TYPES`);
      return null;
    });
  };

  return <>{renderFilterByType()}</>;
};

export default Filters;
