import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import ViewNoteCardContent, { ViewNoteCardProps } from './ViewNoteCardContent';

const ViewNoteCard = (props: ViewNoteCardProps) => {
  return (
    <IntlErrorBoundary
      fallbackType="card"
      mockProps={{ sidebar: !!props.sidebar, width: props.sidebar ? '100%' : '43rem' }}
    >
      <ViewNoteCardContent {...props} />
    </IntlErrorBoundary>
  );
};

export default ViewNoteCard;
