import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { setTasksFilter } from 'PDF/redux/PDFAnnotationsSlice';
import usePDFCollaborators from 'PDF/hooks/usePDFCollaborators';

import { SearchUser } from '_common/components';

const AssignedUserFilter = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const assignedUser = useSelector((state) => state.pdf.annotations.tasksFilters['assignedUser']);
  const collaborators = usePDFCollaborators();

  const options = useMemo(() => {
    return collaborators.map((id) => ({
      id: id,
      value: id,
      type: 'user',
      label: '',
    }));
  }, [collaborators]);

  return (
    <div style={{ padding: '1rem 3rem' }}>
      <SearchUser
        width="100%"
        size="medium"
        options={options}
        onChange={(value) =>
          dispatch(setTasksFilter({ filter: 'assignedUser', value: value?.value ?? null }))
        }
        placeholder={intl.formatMessage({ id: 'SELECT_USER' })}
        value={options?.find((option) => option.id === assignedUser) ?? null}
        testId="sidebar-tasks-filters-assigned-user"
        searchable
      />
    </div>
  );
};

export default AssignedUserFilter;
