import { useState } from 'react';

import { usePDFContext } from 'PDF/PDFContext';
import { stringToRichText } from 'utils';

import EditableReplyCard from './EditableReplyCard/EditableReplyCard';
import ViewReplyCard from './ViewReplyCard/ViewReplyCard';

type ReplyCardProps = {
  reply: PDF.Annotation.Reply;
  pageNumber: PDF.Annotation['pageNumber'];
  annotationId: PDF.Annotation['id'];
  testId: string;
};

const ReplyCard = ({ reply, pageNumber, annotationId, testId }: ReplyCardProps) => {
  const pdfManager = usePDFContext();

  const [editMode, setEditMode] = useState(false);

  const handleApply = async (newContent: string) => {
    try {
      await pdfManager.editReplyAnnotation(
        pageNumber,
        annotationId,
        reply.id,
        stringToRichText(newContent),
      );
      setEditMode(false);
    } catch (e) {
      throw e;
    }
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleStartEdit = () => {
    setEditMode(true);
  };

  return (
    <span data-testid={testId ? `${testId}-root` : undefined}>
      {editMode ? (
        <EditableReplyCard
          currentContent={reply.content.content ? JSON.stringify(reply.content.content) : ''}
          onApply={handleApply}
          onCancel={handleCancel}
          testId={testId}
        />
      ) : (
        <ViewReplyCard
          reply={reply}
          pageNumber={pageNumber}
          annotationId={annotationId}
          onStartEdit={handleStartEdit}
          testId={testId}
        />
      )}
    </span>
  );
};

export default ReplyCard;
