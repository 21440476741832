import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputField, Modal, Select, Button } from 'dodoc-design-system';
import type { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';

import { useDispatch, useSelector } from '_common/hooks/redux';
import { notify } from '_common/components/ToastSystem';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import {
  useGetElementStatusListQuery,
  useDeleteElementStatusMutation,
  selectOrderedElementStatusList,
} from '_common/services/api/elementStatusApi';

import styles from './DeleteElementStatusModal.module.scss';

const MESSAGES = {
  header: 'DELETE_ELEMENT_STATUS',
  body: 'DELETE_ELEMENT_STATUS_TEXT',
};

const DeleteElementStatusModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useGetElementStatusListQuery();
  const [deleteElementStatus] = useDeleteElementStatusMutation();
  const isOpen = useSelector((state) => state.modals.open.DeleteElementStatusModal);
  const id = useSelector((state) => state.modals.DeleteElementStatusModal.id);
  const list = useSelector(selectOrderedElementStatusList);
  const status = list.find((status) => id === status.id);
  // local state
  const [replacement, setReplacement] = useState<SelectOption | null>(null);

  useEffect(() => {
    if (isOpen && list.length > 0) {
      setReplacement({ value: list[0].id, label: list[0].name });
    }
  }, [isOpen, list]);

  const submit = async () => {
    if (replacement?.value) {
      const payload = { id, status: replacement.value };
      await deleteElementStatus(payload);
      notify({
        type: 'success',
        title: 'ELEMENT_STATUS_DELETED_TITLE',
        message: 'ELEMENT_STATUS_DELETED_DESCRIPTION',
        messageValues: { name: status?.name ?? '' },
      });
      close();
    }
  };
  const close = () => {
    setReplacement(null);
    dispatch(closeAndResetModal('DeleteElementStatusModal'));
  };
  return (
    <Modal
      open={!!isOpen}
      type="error"
      width="63.5rem"
      onClose={close}
      testId="delete-element-status"
    >
      <Modal.Header onClose={close}>
        <FormattedMessage id={MESSAGES.header} />
      </Modal.Header>
      <Modal.Body overflow="visible">
        <FormattedMessage
          id={MESSAGES.body}
          // eslint-disable-next-line
          values={{
            d: (chunks: React.ReactChild | null | undefined) => (
              <div className={styles.text}>{chunks}</div>
            ),
            name: status?.name,
          }}
        />
        <InputField
          size="large"
          width="100%"
          label={intl.formatMessage({ id: 'CHANGE_STATUS_TO' })}
          margin="2rem 0 0 0"
          testId="change-status-to-field"
        >
          <Select
            size="large"
            width="100%"
            options={list.map((status) => ({
              value: status.id,
              label: status.name,
            }))}
            onChange={setReplacement}
            value={replacement}
            filterOption={({ value }: { value: string }) => value !== id}
            testId="change-status-to"
          />
        </InputField>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="delete-element-status-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="danger"
          disabled={!replacement}
          onClick={submit}
          testId="delete-element-status-submit-button"
        >
          <FormattedMessage id={MESSAGES.header} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteElementStatusModal;
