import { FormattedMessage } from 'react-intl';
import PermissionsPanel from './PermissionsPanel/PermissionsPanel';
import SidebarPanel from 'Editor/pages/EditorPage/SidebarComponent/SidebarPanel';
import SidebarHeader from 'Editor/pages/EditorPage/SidebarComponent/SidebarHeader';
import SidebarContent from 'Editor/pages/EditorPage/SidebarComponent/SidebarContent';

const ContentTab = () => {
  return (
    <SidebarPanel testId="content-permissions-panel">
      <SidebarHeader>
        <FormattedMessage id="CONTENT_PERMISSIONS" />
      </SidebarHeader>
      <SidebarContent>
        <PermissionsPanel />
      </SidebarContent>
    </SidebarPanel>
  );
};

export default ContentTab;
