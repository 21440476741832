import { Doc } from 'sharedb';
import _ from 'lodash';
import { IndexerDeltaType, ModelIndexer } from '../Models/ModelIndexer';
import { Transport } from '_common/services/Realtime/Transport';
import { ModelsController } from '..';
import { NodeModel, Structure, Task } from '../../models';

export class TaskList extends ModelIndexer<'NODE'> {
  protected taskIndex: string[] = [];
  protected documentId?: string;
  protected structure?: Structure;
  protected timer?: any;
  protected tasksLocations: { [index: string]: string[] } = {};
  protected tasks: { [index: string]: Task } = {};
  protected loaded: boolean = false;

  constructor(transport: Transport, models: ModelsController) {
    super(transport, models, 'NODE');
    this.taskIndex = [];
    this.handleStructureLoaded = this.handleStructureLoaded.bind(this);
    this.handleStructureUpdated = this.handleStructureUpdated.bind(this);
    this.handleNodeTasksChanged = this.handleNodeTasksChanged.bind(this);
    this.reIndexLogic = this.reIndexLogic.bind(this);
  }

  get locations() {
    return this.version?.index || this.tasksLocations;
  }

  get list() {
    return this.version?.locations || this.taskIndex;
  }

  get data() {
    return this.tasks;
  }

  start(documentId: string) {
    this.documentId = documentId;
    this.qs = {
      parent_id: documentId,
      $and: [
        {
          tasks: { $exists: true },
        },
        {
          tasks: { $not: { $size: 0 }, $exists: true },
        },
      ],
    };
    this.structure = this.models.get(this.models.TYPE_NAME.STRUCTURE, `DS${documentId}`);
    this.structure.on('LOADED', this.handleStructureLoaded);
    this.structure.on('CHILDREN_UPDATE', this.handleStructureUpdated);
    if (this.structure.loaded) {
      super.start(this.qs);
    }
  }

  private handleStructureUpdated(/* data: StructureData | null */) {
    super.start(this.qs);
  }

  private handleStructureLoaded(/* data: StructureData | null */) {
    super.start(this.qs);
  }

  handleQueryReady() {
    super.handleQueryReady();
    this.reIndex();
  }

  handleQueryInsertedElements(docs: Doc[], index: number) {
    //
    let newDocs: NodeModel[] = docs.map((doc) => {
      return this.models.get(this.typeName, doc);
    });
    this.results.splice(index, 0, ...newDocs);
    for (let index = 0; index < newDocs.length; index++) {
      const newNode = newDocs[index];
      newNode.on('LOADED', this.handleNodeTasksChanged);
      newNode.on('UPDATED_TASKS', this.handleNodeTasksChanged);
    }
    this.reIndex();
    this.emit('INSERTED', newDocs);
  }

  handleQueryRemovedElements(docs: Doc[], index: number) {
    //
    let oldDocs: NodeModel[] = this.results.splice(index, docs.length);
    for (let index = 0; index < oldDocs.length; index++) {
      const oldNode = oldDocs[index];
      oldNode.off('LOADED', this.handleNodeTasksChanged);
      oldNode.off('UPDATED_TASKS', this.handleNodeTasksChanged);
    }
    this.reIndex();
    this.emit('REMOVED', oldDocs);
  }

  handleQueryElementsChanged() {}

  handleNodeTasksChanged() {
    this.reIndex();
  }

  protected reIndex() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(this.reIndexLogic, 250);
  }

  private async reIndexLogic() {
    let tempIndex: string[] = [];
    this.tasksLocations = {};
    for (let index = 0, length = this.results.length; index < length; index++) {
      const node: NodeModel = this.results[index];
      if (node?.tasks) {
        tempIndex.push(...node.tasks);
        for (let jIndex = 0; jIndex < node.tasks.length; jIndex++) {
          const task = node.tasks[jIndex];
          if (!this.tasksLocations[task]) {
            this.tasksLocations[task] = [];
          }
          this.tasksLocations[task].push(node.id);
        }
      }
    }
    tempIndex = _.uniq(tempIndex);
    const tasksDiff = TaskList.diffInOut(this.taskIndex, tempIndex);
    this.taskIndex = tempIndex;
    let delta: IndexerDeltaType<string[]> = {
      in: [],
      out: [],
      changedOrder: tasksDiff.changedOrder,
    };
    if (
      tasksDiff.in.length > 0 ||
      tasksDiff.out.length > 0 ||
      tasksDiff.changedOrder ||
      !this.loaded
    ) {
      this.loaded = true;
      for (let index = 0; index < tasksDiff.in.length; index++) {
        const task = this.models.get('TASK', tasksDiff.in[index]);
        if (task.isDeleted) {
          tasksDiff.in.splice(index, 1);
        } else {
          this.tasks[tasksDiff.in[index]] = task;
        }
      }
      for (let index = 0; index < tasksDiff.out.length; index++) {
        this.tasks[tasksDiff.out[index]].destroy();
        this.tasks[tasksDiff.out[index]].removeAllListeners();
        delete this.tasks[tasksDiff.out[index]];
      }
      delta.in = tasksDiff.in.length > 0 ? tasksDiff.in : [];
      delta.out = tasksDiff.out.length > 0 ? tasksDiff.out : [];
      !this.version && this.emit('CHANGED_DELTA', delta);
    }
    this.timer = null;
  }

  task(id: string) {
    return this.tasks[id];
  }

  getNodesWithTask(taskId: string): string[] {
    return this.tasksLocations[taskId] || [];
  }

  setVersionData(
    version: any,
    data?: {
      index: string[];
      locations: {};
    },
  ) {
    //
    let index: string[];
    let oldIndex = this.version?.index || this.taskIndex;
    if (version) {
      this.version = {
        version,
        index: data?.index,
        locations: data?.locations,
      };
      index = this.version.index;
    } else {
      this.version = null;
      index = this.taskIndex;
    }
    const tasksDiff = TaskList.diffInOut(oldIndex, index);
    let delta: IndexerDeltaType<string[]> = {
      in: [],
      out: [],
      changedOrder: tasksDiff.changedOrder,
    };
    if (tasksDiff.in.length > 0 || tasksDiff.out.length > 0 || tasksDiff.changedOrder) {
      for (let index = 0; index < tasksDiff.in.length; index++) {
        this.tasks[tasksDiff.in[index]] = this.models.get('TASK', tasksDiff.in[index]);
      }
      for (let index = 0; index < tasksDiff.out.length; index++) {
        this.tasks[tasksDiff.out[index]].destroy();
        delete this.tasks[tasksDiff.out[index]];
      }
      delta.in = tasksDiff.in.length > 0 ? tasksDiff.in : [];
      delta.out = tasksDiff.out.length > 0 ? tasksDiff.out : [];
      this.emit('CHANGED_DELTA', delta);
    }
  }
}
