import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type PDFFindSliceState = {
  total: number;
  list: PDF.Find.FindHitType['id'][];
  data: Record<PDF.Find.FindHitType['id'], PDF.Find.FindHitType>;
  hits: Record<number, PDF.Find.FindHitType[]>;
  selected: string | null;
  matchCase: boolean;
  findWholeWords: boolean;
};

const SLICE_NAME = 'PDFFind';
const initialState: PDFFindSliceState = {
  total: 0,
  list: [],
  data: {},
  hits: {},
  selected: null,
  matchCase: false,
  findWholeWords: false,
};

// #region Slice
const PDFFindSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setFindData: (state, action: PayloadAction<PDFFindSliceState>) => {
      state = { ...state, ...action.payload };
    },
    setSelectedInstance: (state, action: PayloadAction<PDFFindSliceState['selected']>) => {
      state.selected = action.payload;
    },
    toggleFindMatchCaseOption: (state) => {
      state.matchCase = !state.matchCase;
    },
    toggleFindEntireWordOption: (state) => {
      state.findWholeWords = !state.findWholeWords;
    },
  },
});

export default PDFFindSlice.reducer;
// #endregion

// #region Actions
export const {
  setFindData,
  setSelectedInstance,
  toggleFindMatchCaseOption,
  toggleFindEntireWordOption,
} = PDFFindSlice.actions;
// #endregion
