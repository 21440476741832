import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, Icon, InputField, Select, Tooltip } from 'dodoc-design-system';

import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';

import styles from './EditZone.module.scss';

import SelectableBlock from './SelectableBlock/SelectableBlock';

import type { EditorStatusSlice } from 'Editor/redux/EditorStatusSlice';
import type { SelectableBlockProps } from './SelectableBlock/SelectableBlock';
import type { CheckboxProps } from 'dodoc-design-system/build/types/Components/Checkbox/Checkbox';

export type EditZoneProps = {
  separators: CaptionsModal.SeparatorOption[];
  separatorsValue: EditZoneProps['separators'][number];
  setSeparatorsValue: (newValue: EditZoneProps['separatorsValue']) => void;
  editMode?: boolean;
  isCollapsed?: boolean;
  selection: EditorStatusSlice['selection'];
  chapterStyles: SelectableBlockProps['options'];
  includeChapter: CheckboxProps['checked'];
  setIncludeChapter: (checked: CheckboxProps['checked']) => void;
  forceSeparatorUpdate: CheckboxProps['checked'];
  setForceSeparatorUpdate: (checked: CheckboxProps['checked']) => void;
  numberingTypes: SelectableBlockProps['options'];
  labelValues: SelectableBlockProps['options'];
  captionPositions: SelectableBlockProps['options'];
  captionLabelValue: SelectableBlockProps['value'];
  setCaptionLabelValue: (newValue: { value: string; label: string }) => void;
  captionNumberingValue: SelectableBlockProps['value'];
  setCaptionNumberingValue: (newValue: EditZoneProps['captionNumberingValue']) => void;
  chapterStylesValue: SelectableBlockProps['value'];
  setChapterStylesValue: (newValue: EditZoneProps['chapterStylesValue']) => void;
  captionPositionValue: SelectableBlockProps['value'];
  setCaptionPositionValue: (newValue: EditZoneProps['captionPositionValue']) => void;
};

const EditZone = ({
  editMode,
  isCollapsed,
  selection,
  chapterStyles,
  numberingTypes,
  separators,
  labelValues,
  captionPositions,
  includeChapter,
  setIncludeChapter,
  captionLabelValue,
  setCaptionLabelValue,
  captionNumberingValue,
  setCaptionNumberingValue,
  chapterStylesValue,
  setChapterStylesValue,
  separatorsValue,
  setSeparatorsValue,
  captionPositionValue,
  setCaptionPositionValue,
  setForceSeparatorUpdate,
  forceSeparatorUpdate,
}: EditZoneProps) => {
  const intl = useIntl();

  const handleSelectSetters: SelectableBlockProps['handleSelectSetters'] = (values, identity) => {
    const newValue = { ...values };
    switch (identity) {
      case 'caption_label':
        setCaptionLabelValue(newValue);
        break;
      case 'caption_numbering':
        setCaptionNumberingValue(newValue);
        break;
      case 'chapter_begins_with':
        setChapterStylesValue(newValue);
        break;
      case 'separator':
        setSeparatorsValue(newValue);
        break;
      case 'caption_position':
        setCaptionPositionValue(newValue);
        break;
      default:
        break;
    }
  };

  return (
    <div className={`${styles.rootEdit} ${editMode && styles.editionMode}`}>
      {!editMode && (
        <SelectableBlock
          creatable
          isLabelSelect
          value={captionLabelValue}
          onChange={(values) => handleSelectSetters(values, 'caption_label')}
          label={intl.formatMessage({ id: 'CAPTION_LABEL' })}
          options={labelValues}
          handleSelectSetters={handleSelectSetters}
        />
      )}
      <SelectableBlock
        editMode={editMode}
        value={captionNumberingValue}
        onChange={(values) => handleSelectSetters(values, 'caption_numbering')}
        label={intl.formatMessage({ id: 'CAPTION_NUMBERING' })}
        options={numberingTypes}
      />

      <>
        <div style={{ width: 'fit-content' }}>
          <Checkbox
            checked={includeChapter}
            onChange={() =>
              setIncludeChapter(includeChapter === 'unchecked' ? 'checked' : 'unchecked')
            }
            size="small"
            testId="include-chapter-number-checkbox"
          >
            <FormattedMessage id="INCLUDE_CHAPTER_NUMBER" />
          </Checkbox>
        </div>
        <div className={`${styles.chapterNumber} ${editMode && styles.chapterNumberEdit} `}>
          <InputField
            size="large"
            disabled={includeChapter === 'unchecked'}
            label={intl.formatMessage({ id: 'CHAPTER_BEGINS_WITH' })}
            testId="chapter-begins-with-field"
          >
            <Select
              menuPosition="fixed"
              size="large"
              disabled={includeChapter === 'unchecked'}
              value={chapterStylesValue}
              onChange={(values) => handleSelectSetters(values, 'chapter_begins_with')}
              options={chapterStyles}
              width="28rem"
              testId="chapter-begins-with"
            />
          </InputField>
          <InputField
            size="large"
            disabled={includeChapter === 'unchecked'}
            label={intl.formatMessage({ id: 'SEPARATOR' })}
            testId="separator-field"
          >
            <Select
              menuPosition="fixed"
              size="large"
              disabled={includeChapter === 'unchecked'}
              value={separatorsValue}
              onChange={(values) => handleSelectSetters(values, 'separator')}
              options={separators}
              width="28rem"
              testId="separator"
            />
          </InputField>
        </div>
        {!editMode && (
          <SelectableBlock
            value={captionPositionValue}
            onChange={(values) => handleSelectSetters(values, 'caption_position')}
            label={intl.formatMessage({ id: 'CAPTION_POSITION' })}
            options={captionPositions}
            disabled={
              DOMUtils.BLOCK_TEXT_ELEMENTS.includes(selection.BLOCK_TAG) &&
              isCollapsed &&
              selection.EDITABLE
            }
          />
        )}
        {editMode && (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox
              disabled={includeChapter === 'unchecked'}
              checked={forceSeparatorUpdate}
              onChange={() =>
                setForceSeparatorUpdate(
                  forceSeparatorUpdate === 'unchecked' ? 'checked' : 'unchecked',
                )
              }
              size="small"
              testId="force-separator-update-checkbox"
            >
              <FormattedMessage id="FORCE_SEPARATOR_UPDATE" />
            </Checkbox>
            <div style={{ marginLeft: '1rem' }}>
              <Tooltip
                content={intl.formatMessage({
                  id: 'FORCE_SEPARATOR_UPDATE_TOOLTIP',
                })}
                testId="force-separator-information-tooltip"
              >
                <Icon icon="InformationBlue" size={16} />
              </Tooltip>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default EditZone;
