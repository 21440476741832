import { Logger } from '_common/services';
import { ReduxInterface } from 'Editor/services';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { EditorSelectionUtils, JsonRange } from '../_Common/Selection';
import { SelectionUtils } from '_common/DoDOCSelection';

type FindAndReplaceData = {
  currentMatchIndex: number;
  matches: Editor.Data.FindAndReplace.WordOccurence[];
  editable: boolean;
};

export default class FindAndReplace {
  dataManager?: Editor.Data.API;
  visualizerManager?: Editor.Visualizer.API;
  selectionManager?: any;
  navigationManager?: any;

  currentMatchIndex: number;
  matches: Editor.Data.FindAndReplace.WordOccurence[];
  workingMatch: any;

  constructor(editorContext: Editor.Context) {
    this.dataManager = editorContext.DataManager;
    this.selectionManager = editorContext.selectionManager;
    this.navigationManager = editorContext.navigationManager;
    this.visualizerManager = editorContext.visualizerManager;

    this.matches = [];
    this.currentMatchIndex = -1;
  }

  private findMatchIndex(): boolean {
    this.dataManager?.selection?.restore();
    const range = EditorSelectionUtils.getRange();
    if (range) {
      range.collapse(); // collapse to end

      const selecionJsonRange = JsonRange.buildFromDOMRange(range);

      const docNodes = this.dataManager?.structure?.getDocumentNodes();

      this.currentMatchIndex = this.matches.findIndex((match) => {
        const jsonRangeToCompare = new JsonRange(match.location.start, match.location.end);
        let result = 0;
        if (docNodes) {
          result = selecionJsonRange.compare(docNodes, jsonRangeToCompare);
        }
        return result < 0;
      });

      if (this.currentMatchIndex === -1) {
        this.currentMatchIndex = 0;
      }
      return true;
    }
    return false;
  }

  private replaceMatches(newMatches: Editor.Data.FindAndReplace.WordOccurence[]) {
    const match = newMatches[0];
    if (match) {
      let start = -1;
      let finish = -1;
      for (let index = 0; index < this.matches.length; index++) {
        if (start < 0 && this.matches[index].location.start.b === match.location.start.b) {
          start = index;
        }
        if (start >= 0 && this.matches[index].location.start.b === match.location.start.b) {
          finish = index;
        }
      }
      if (start >= 0 && finish >= 0) {
        this.matches.splice(start, finish - start + 1, ...newMatches);
      }
    }
  }

  async findInDocument(
    textToFind: string,
    matchCase: boolean = false,
    wholeWords: boolean = false,
  ): Promise<FindAndReplaceData> {
    const data: FindAndReplaceData = {
      currentMatchIndex: -1,
      matches: [],
      editable: false,
    };
    this.matches = [];

    this.visualizerManager?.selection.stopSelectionTracker();
    try {
      const results = await this.dataManager?.findAndReplace?.findInDocument(textToFind, {
        matchCase,
        wholeWords,
      });

      if (results && results?.length > 0) {
        this.matches = results;

        data.matches = JSON.parse(JSON.stringify(this.matches));

        if (this.findMatchIndex() && this.currentMatchIndex >= 0) {
          const nextMatch = results[this.currentMatchIndex];

          if (nextMatch) {
            data.currentMatchIndex = this.currentMatchIndex;
            const resultJsonRange = new JsonRange(nextMatch.location.start, nextMatch.location.end);

            await this.navigationManager.renderDocumentAtId(resultJsonRange.start.b);

            const resultRange = resultJsonRange.serializeToDOMRange();
            SelectionUtils.applyRangeToSelection(resultRange);

            data.editable = DOMUtils.isNodeEditableTextElement(
              DOMUtils.getNode(resultJsonRange.start.b),
            );
          }
        }
      }
    } catch (error) {
      Logger.captureException(error);
      throw error;
    } finally {
      this.visualizerManager?.selection.debounceStartSelectionTracker();
    }

    return data;
  }

  async replaceInDocument(
    newTerm: string,
    matchCase: boolean = false,
    wholeWords: boolean = false,
    all: boolean = false,
  ): Promise<FindAndReplaceData> {
    let editable: boolean = false;

    this.visualizerManager?.selection.stopSelectionTracker();
    try {
      if (this.currentMatchIndex != null && this.matches[this.currentMatchIndex] != null) {
        const currentMatch = this.matches[this.currentMatchIndex];

        const options = {
          matchCase,
          wholeWords,
          suggesting: ReduxInterface.isEditorTrackingStateOn(),
        };
        if (all) {
          await this.dataManager?.findAndReplace?.replaceAllInDocument(
            currentMatch.word,
            newTerm,
            options,
          );

          this.matches = [];
          this.currentMatchIndex = -1;
          editable = false;
        } else {
          let results = await this.dataManager?.findAndReplace?.replaceInDocument(
            currentMatch,
            newTerm,
            options,
          );
          this.replaceMatches(results || []);
          this.findMatchIndex();

          return this.nextMatch();
        }
      } else {
        throw new Error('Invalid data to replace!');
      }
    } catch (error) {
      Logger.captureException(error);
      throw error;
    } finally {
      this.visualizerManager?.selection.debounceStartSelectionTracker();
    }

    return {
      currentMatchIndex: this.currentMatchIndex,
      matches: JSON.parse(JSON.stringify(this.matches)),
      editable,
    };
  }

  async nextMatch(): Promise<FindAndReplaceData> {
    let editable: boolean = false;

    if (this.currentMatchIndex != null && this.matches.length > 0) {
      this.currentMatchIndex += 1;

      if (this.currentMatchIndex >= this.matches.length) {
        this.currentMatchIndex = 0;
      }

      const nextMatch = this.matches[this.currentMatchIndex];

      if (nextMatch) {
        const resultJsonRange = new JsonRange(nextMatch.location.start, nextMatch.location.end);

        await this.navigationManager.renderDocumentAtId(resultJsonRange.start.b);

        const resultRange = resultJsonRange.serializeToDOMRange();
        SelectionUtils.applyRangeToSelection(resultRange);

        editable = DOMUtils.isNodeEditableTextElement(DOMUtils.getNode(resultJsonRange.start.b));
      } else {
        throw new Error('Next match not found!');
      }
    }

    return {
      currentMatchIndex: this.currentMatchIndex,
      matches: JSON.parse(JSON.stringify(this.matches)),
      editable,
    };
  }

  async previousMatch(): Promise<FindAndReplaceData> {
    let editable: boolean = false;

    if (this.currentMatchIndex != null && this.matches.length > 0) {
      this.currentMatchIndex -= 1;

      if (this.currentMatchIndex < 0) {
        this.currentMatchIndex = 0;
      }

      const previousMatch = this.matches[this.currentMatchIndex];

      if (previousMatch) {
        const resultJsonRange = new JsonRange(
          previousMatch.location.start,
          previousMatch.location.end,
        );

        await this.navigationManager.renderDocumentAtId(resultJsonRange.start.b);

        const resultRange = resultJsonRange.serializeToDOMRange();
        SelectionUtils.applyRangeToSelection(resultRange);

        editable = DOMUtils.isNodeEditableTextElement(DOMUtils.getNode(resultJsonRange.start.b));
      } else {
        throw new Error('Previous match not found!');
      }
    }
    return {
      currentMatchIndex: this.currentMatchIndex,
      matches: JSON.parse(JSON.stringify(this.matches)),
      editable,
    };
  }

  async navigateToMatch(matchIndex: number): Promise<FindAndReplaceData> {
    let editable: boolean = false;

    if (this.matches.length > 0 && this.matches[matchIndex]) {
      const match = this.matches[matchIndex];

      this.currentMatchIndex = matchIndex;

      const resultJsonRange = new JsonRange(match.location.start, match.location.end);

      await this.navigationManager.renderDocumentAtId(resultJsonRange.start.b);

      const resultRange = resultJsonRange.serializeToDOMRange();
      SelectionUtils.applyRangeToSelection(resultRange);

      editable = DOMUtils.isNodeEditableTextElement(DOMUtils.getNode(resultJsonRange.start.b));
    } else {
      throw new Error('Match not found!');
    }
    return {
      currentMatchIndex: this.currentMatchIndex,
      matches: JSON.parse(JSON.stringify(this.matches)),
      editable,
    };
  }
}
