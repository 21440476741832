/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { CaptureContext, Primitive } from '@sentry/types';

const LOG_LEVELS = ['OFF', 'ERROR', 'WARNING', 'INFO', 'DEBUG'] as const;

const loggerOptions: LoggerClass.Options = {
  level: 'ERROR',
};

export class LoggerClass {
  get LOG_LEVELS() {
    return LOG_LEVELS;
  }

  getLogLevel() {
    return loggerOptions.level;
  }

  setLogLevel(logLevel: LoggerClass.Level) {
    if (LOG_LEVELS.includes(logLevel)) {
      loggerOptions.level = logLevel;
    }
  }

  // ############################################################
  //                      Sentry functions
  // ############################################################
  captureException(exception: any, context?: CaptureContext) {}

  captureMessage(message: string, context?: CaptureContext) {}

  setUser(user: Sentry.User) {}

  setTag(key: string, value: Primitive) {}

  configureScope(callback: (scope: Sentry.Scope) => void) {}

  reportToSentry = (report: string) => {};

  // ############################################################
  //                      Message Logging
  // ############################################################
  error(...args: any[]) {}

  warning(...args: any[]) {}

  warn(...args: any[]) {}

  info(message: any, ...args: any[]) {}

  static info(message: any, ...args: any[]) {}

  trace(message: any, ...args: any[]) {}

  debug(message: any, ...args: any[]) {}
}

export default new LoggerClass();
