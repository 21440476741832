import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import EditableCardContent, { EditableCardProps } from './EditableCardContent';

const EditableCard = (props: EditableCardProps) => {
  return (
    <IntlErrorBoundary
      fallbackType="card"
      mockProps={{ sidebar: !!props.panel, width: props.panel ? '100%' : '43rem' }}
    >
      <EditableCardContent {...props} />
    </IntlErrorBoundary>
  );
};

export default EditableCard;
