import { ReactNode } from 'react';
import { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';
import { ExtraAction } from '_common/components/AuditLog/ActionSelect/useGetActionOptions';
import { UserOption } from '_common/components/SearchUser/SearchUser';

//#region Functional types
//Utility types (reuse and simplify)
type AuditLogAction = NonNullable<ActionLog['action'] | ExtraAction>;
type ControlOption = {
  value: string;
  label: string;
};

//Filter types
export type DateRange = { startISO?: ISODate; endISO?: ISODate } | null;

//Define possible filter controls
export type FilterControl =
  | 'select'
  | 'multiSelect'
  | 'user'
  | 'multiUser'
  | 'dateRange'
  | 'checkbox'
  | 'switch';

//Define options type for each filter control
export type OptionByType = {
  select: SelectOption[] | undefined;
  user: UserOption[] | undefined;
  checkbox: ControlOption[] | undefined;
  switch: ControlOption[] | undefined;
  dateRange: undefined;
};

type SelectSettings = {
  placeholder?: string;
  multiOverflowLabel?: string;
  selectedOptionsGroupLabel?: string;
  listOptionsGroupLabel?: string;
};

type DateRangeSettings = {
  minDate?: ISODate;
  maxDate?: ISODate;
};

type UserSettings = {
  editorAvatar?: boolean;
};

export type SettingByType = {
  select?: SelectSettings;
  user?: UserSettings;
  checkbox?: undefined;
  switch?: undefined;
  dateRange?: DateRangeSettings;
};

type FilterSetting<T extends Exclude<FilterControl, 'multiSelect' | 'multiUser'>> = {
  options?: OptionByType[T];
  settings?: SettingByType[T];
};

export type FilterValue<T> = {
  value: ArrayElement<T>;
  label: ReactNode;
};
type ArrayElement<T> = T extends (infer U)[] ? U : T;
//#endregion

/** Required step
 * Define filter identities
 */
export type FilterIdentity =
  | 'storage'
  | 'shared'
  | 'auditLog'
  | 'notifications'
  | 'tenantSettings_users'
  | `templates-${string}` //Generate dynamic identities for 'templates'
  | `referenceStyles-${string}` //Generate dynamic identities for 'referenceStyles'
  | 'editorTaskPanel'
  | 'editorCommentPanel'
  | 'editorTrackPanel'
  | 'editorDocCaption'
  | 'editorNavPanel';

/** Required step
 * Define filter structures
 * Each can be reusable by multiple identities
 * Even if identical they shouldn't be merged in order to differentiate labels and such
 */
export type FilterType = {
  status: ElementStatus['id'];
  objectType: NavigableObject;
  approvedBy: UserId;
  createdBy: UserId;
  users: UserId[];
  auditActions: AuditLogAction[];
  notificationCategory: string[];
  timestamp: DateRange;
  userPermissions: string[];
  registrationDate: DateRange;
  templateStatus: string[];
  taskStatus: string[];
  assignedUser: UserId;
  cardPriority: string[];
  author: UserId[];
  creationDate: DateRange;
  trackedActionType: string[];
  captionLabel: string[];
  elementType: string[];
};

/** Required step
 * This represents how each filter is saved in redux
 */
export type Filters = {
  status?: FilterValue<FilterType['status']>;
  objectType?: FilterValue<FilterType['objectType']>;
  approvedBy?: FilterValue<FilterType['approvedBy']>;
  createdBy?: FilterValue<FilterType['createdBy']>;
  users?: FilterValue<FilterType['users']>[];
  auditActions?: FilterValue<FilterType['auditActions']>[];
  notificationCategory?: FilterValue<FilterType['notificationCategory']>;
  userPermissions?: FilterValue<FilterType['userPermissions']>;
  templateStatus?: FilterValue<FilterType['templateStatus']>[];
  timestamp?: FilterType['timestamp'];
  registrationDate?: FilterType['registrationDate'];
  taskStatus?: FilterValue<FilterType['taskStatus']>[];
  assignedUser?: FilterValue<FilterType['assignedUser']>;
  cardPriority?: FilterValue<FilterType['cardPriority']>[];
  author?: FilterValue<FilterType['author']>[];
  creationDate?: FilterType['creationDate'];
  trackedActionType?: FilterValue<FilterType['trackedActionType']>[];
  captionLabel?: FilterValue<FilterType['captionLabel']>[];
  elementType?: FilterValue<FilterType['elementType']>[];
};
export type FilterName = keyof FilterType;

/** Required step
 * Modify to define which control settings should each filter use
 */
export type Settings = {
  status?: FilterSetting<'select'>;
  objectType?: FilterSetting<'select'>;
  approvedBy?: FilterSetting<'user'>;
  createdBy?: FilterSetting<'user'>;
  users?: FilterSetting<'user'>;
  auditActions?: FilterSetting<'select'>;
  timestamp?: FilterSetting<'dateRange'>;
  userPermissions?: FilterSetting<'switch'>;
  registrationDate?: FilterSetting<'dateRange'>;
  templateStatus?: FilterSetting<'checkbox'>;
  notificationCategory?: FilterSetting<'switch'>;
  taskStatus?: FilterSetting<'checkbox'>;
  assignedUser?: FilterSetting<'user'>;
  cardPriority?: FilterSetting<'checkbox'>;
  author?: FilterSetting<'user'>;
  creationDate?: FilterSetting<'dateRange'>;
  trackedActionType?: FilterSetting<'checkbox'>;
  captionLabel?: FilterSetting<'checkbox'>;
  elementType?: FilterSetting<'checkbox'>;
};

/** Required step
 * Assign filters to an identity
 * By adding a filter to an identity it will automatically be rendered (if assigned to a control in TYPES)
 */
export const FILTERS: Record<FilterIdentity, FilterName[]> = {
  storage: ['status', 'objectType', 'approvedBy', 'createdBy'],
  shared: ['status', 'objectType', 'approvedBy', 'createdBy'],
  auditLog: ['users', 'auditActions', 'timestamp'],
  tenantSettings_users: ['userPermissions', 'registrationDate'],
  'referenceStyles-': ['templateStatus'],
  'templates-': ['templateStatus'],
  notifications: ['notificationCategory'],
  editorTaskPanel: ['taskStatus', 'assignedUser'],
  editorCommentPanel: ['cardPriority', 'author', 'creationDate'],
  editorTrackPanel: ['trackedActionType', 'cardPriority', 'author', 'creationDate'],
  editorDocCaption: ['captionLabel'],
  editorNavPanel: ['elementType'],
};

/** Required step
 * This defines what control each filter should be rendered as
 * Each control will have its handler (except multi* which will be handled together with non multi*)
 * If a filter isn't assigned to any type, it will not be rendered
 */
export const TYPES: Record<FilterControl, FilterName[]> = {
  select: ['status', 'objectType'],
  multiSelect: ['auditActions'],
  user: ['createdBy', 'approvedBy', 'assignedUser'],
  multiUser: ['users', 'author'],
  dateRange: ['timestamp', 'registrationDate', 'creationDate'],
  checkbox: [
    'templateStatus',
    'notificationCategory',
    'taskStatus',
    'cardPriority',
    'trackedActionType',
    'captionLabel',
    'elementType',
  ],
  switch: ['userPermissions'],
};

/** Required step
 * Set translation id to each filter
 */
export const TRANSLATIONS: Record<FilterName, string> = {
  status: 'STATUS',
  objectType: 'storage.actionBar.filters.objectType',
  approvedBy: 'storage.actionBar.filters.approvedBy',
  createdBy: 'storage.actionBar.filters.createdBy',
  users: 'global.user',
  auditActions: 'editor.menu.view.action',
  notificationCategory: 'NOTIFICATION_CATEGORY',
  timestamp: 'TIMESTAMP',
  userPermissions: 'USER_PERMISSIONS',
  registrationDate: 'REGISTRATION_DATE',
  templateStatus: 'TEMPLATE_STATUS',
  taskStatus: 'TASK_STATUS',
  assignedUser: 'ASSIGNED_USER',
  cardPriority: 'global.priority',
  author: 'editor.sidebar.review.filter.byAuthor',
  creationDate: 'CREATION_DATE',
  trackedActionType: 'editor.sidebar.review.filter.byType',
  captionLabel: 'CAPTION_LABEL',
  elementType: 'ELEMENT_TYPE',
};

/** Optional step
 * Set id of filter as recognized by API
 * In order to be able to work with our nomenclature its necessary to link each filter with how API recognizes them
 */
export const APISYNC: { [key in FilterName]?: string } = {
  objectType: 'type',
  approvedBy: 'approved_by',
  createdBy: 'creator',
  auditActions: 'action',
  users: 'user',
};
