import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import { ExtensionsService } from '_common/services';

import {
  getDocumentObject,
  selectReadOnlyMode,
  selectIsPageLayout,
} from 'Editor/redux/EditorStatusSlice';
import { useGetInstalledTemplatesListQuery } from 'Settings/pages/TenantSettingsPage/Templates/TemplatesApi';

import styles from './DocumentTemplatePreview.module.scss';
import { TemplateGallery } from '_common/components';

const DocumentTemplatePreview = () => {
  const actions = useSelector((state) => state.app.information.actions);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const document = useSelector(getDocumentObject);
  const [choosingTemplate, setChoosingTemplate] = useState(false);
  const { objectTemplate: template } = useGetInstalledTemplatesListQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      objectTemplate: result.data?.find((docTemplate) => docTemplate.id === document.template),
    }),
  });

  const [thumbnail, setThumbnail] = useState('');
  //@ts-expect-error AuthoritySchemas['ThirdPartyActions'] should be more specific
  const canChangeTemplate = actions?.editor?.template;
  const isOwner =
    document.user_permissions.includes('admin') || document.user_permissions.includes('owner');
  useEffect(() => {
    setThumbnail('');
    if (document.template) {
      new ExtensionsService().previewTemplate(document.template).then((response) => {
        const url = URL.createObjectURL(response.data); // create <img> with src set to the blob
        setThumbnail(url);
      });
    }
  }, [document.template]);

  return (
    <div className={styles.root}>
      {thumbnail && (
        <div className={styles.img_container}>
          <img alt="template" src={thumbnail} className={styles.image} />
        </div>
      )}
      {!document.template && (
        <div className={styles.img_container}>
          <div className={`${styles.image} ${styles.noTemplate}`} />
        </div>
      )}
      <div>
        <div className={styles.name}>{template ? template.name : 'No Template'}</div>
        <div>
          <Button
            variant="primary"
            disabled={isReadOnlyMode || isPageLayout || (actions && !canChangeTemplate) || !isOwner}
            size="small"
            onClick={() => setChoosingTemplate(true)}
            testId="change-template-button"
          >
            <FormattedMessage id="CHANGE_TEMPLATE" />
          </Button>
        </div>
        {choosingTemplate && <TemplateGallery close={() => setChoosingTemplate(false)} />}
      </div>
    </div>
  );
};

export default DocumentTemplatePreview;
