import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { parseAccount } from 'Auth/redux/utils';
import authority from '_common/services/api/authority';

type LocalStorageSliceState = {
  version: string;
  accounts: Record<Account['id'], Account>;
};

const SLICE_NAME = 'localStorage';

// #region State
export const INITIAL_STATE: LocalStorageSliceState = {
  version: '',
  accounts: {},
};
// #endregion

// #region Selectors
const getAccounts = (state: RootState) => state.localStorage.accounts;

export const selectAccounts = createSelector([getAccounts], (accounts) =>
  Object.values(accounts).reduce<Record<UserId, ParsedProfile<Account>>>((previous, account) => {
    if (account.id) {
      previous[account.id] = parseAccount(accounts[account.id]);
    }
    return previous;
  }, {}),
);

export const selectValidAccountsForLogin = createSelector(
  [selectAccounts, (state: RootState) => state],
  (accounts, state) => {
    return Object.keys(accounts).reduce<Record<UserId, ParsedProfile<Account>>>((previous, id) => {
      const { data } = authority.endpoints.loginSetup.select()(state);
      if (data?.allow_login_with_password || accounts[id].third_party) {
        previous[id] = accounts[id];
      }
      return previous;
    }, {});
  },
);
// endregion

// #region Slice
const localStorageSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    updateAccount: (state, action: PayloadAction<Partial<Account> & Pick<Account, 'id'>>) => {
      state.accounts[action.payload.id] = {
        ...state.accounts[action.payload.id],
        ...action.payload,
      };
    },
    resetAccount: (state, action: PayloadAction<Account['id']>) => {
      if (state.accounts[action.payload]) {
        state.accounts[action.payload].token = '';
        state.accounts[action.payload].expires = new Date(Date.now()).toISOString();
      }
    },
    removeAccount: (state, action: PayloadAction<Account['id']>) => {
      delete state.accounts[action.payload];
    },
    removeAccounts: (state, action: PayloadAction<Account['id'][]>) => {
      action.payload.forEach((userId) => {
        delete state.accounts[userId];
      });
    },
    removeUserToken: (state, action: PayloadAction<Account['token']>) => {
      const account = Object.keys(state.accounts).filter(
        (account) => state.accounts[account].token === action.payload,
      );

      if (account.length > 0) {
        state.accounts[account[0]].token = '';
        state.accounts[account[0]].expires = new Date(Date.now()).toISOString();
      }
    },
    setAccountExpireTime: (state, action: PayloadAction<Pick<Account, 'id' | 'expires'>>) => {
      if (state.accounts[action.payload.id]) {
        state.accounts[action.payload.id].expires = action.payload.expires;
      }
    },
    deviceRegistrationRequested: (
      state,
      action: PayloadAction<Pick<Account, 'id' | 'username' | 'token' | 'device'>>,
    ) => {
      state.accounts[action.payload.id] = {
        ...state.accounts[action.payload.id],
        ...action.payload,
      };
    },
    setAppVersion: (state, action: PayloadAction<LocalStorageSliceState['version']>) => {
      state.version = action.payload;
    },
    authenticationSuccessful: (
      state,
      action: PayloadAction<
        Partial<Omit<Account, 'id'>> & Pick<Account, 'id'> & { first_login?: boolean }
      >,
    ) => {
      if (action.payload.first_login) {
        delete action.payload.first_login;
      }

      const thirdParty = action.payload.third_party;
      const payload: typeof action.payload = { ...action.payload };
      delete payload.extra;
      delete payload.third_party;
      delete payload.actions;

      state.accounts[payload.id] = {
        ...state.accounts[payload.id],
        ...payload,
        third_party: thirdParty || state.accounts[payload.id]?.third_party,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authority.endpoints.getCurrentUser.matchFulfilled, (state, action) => {
      const user = { ...action.payload };
      state.accounts[user.profile.id] = {
        ...state.accounts[user.profile.id],
        username: user.profile.username,
        email: user.profile.email,
        first_name: user.profile.first_name,
        last_name: user.profile.last_name,
        avatar: user.avatar,
        password_validators: user.other.password_validators,
      };
    });
    builder.addMatcher(authority.endpoints.getTokenInfo.matchFulfilled, (state, action) => {
      state.accounts[action.payload.id] = {
        ...state.accounts[action.payload.id],
        ...action.payload,
      };
    });
    builder.addMatcher(authority.endpoints.signOut.matchFulfilled, (state, action) => {
      if (state.accounts[action.meta.arg.originalArgs]) {
        state.accounts[action.meta.arg.originalArgs].token = '';
        state.accounts[action.meta.arg.originalArgs].expires = new Date(Date.now()).toISOString();
      }
    });
  },
});

// Actions
export const {
  updateAccount,
  resetAccount,
  removeAccount,
  removeAccounts,
  removeUserToken,
  setAccountExpireTime,
  deviceRegistrationRequested,
  setAppVersion,
  authenticationSuccessful,
} = localStorageSlice.actions;

// Persistence
const persistConfig = {
  key: 'localStorage',
  storage,
  whitelist: ['version', 'accounts'],
};

const localStorageReducer = persistReducer(persistConfig, localStorageSlice.reducer);

export default localStorageReducer;
// #endregion
