import { intersection } from 'lodash';
import { ELEMENTS } from 'Editor/services/consts';
import { NON_CONTENT_ELEMENTS, NON_CONTENT_ELEMENTS_WITH_PROPERTIES } from '../../controllers';

export class NodeUtils {
  private static getProperParentForReferenceNode(element: Editor.Data.Node.Data, ref: string) {
    const queue: { last: Editor.Data.Node.Data; node: Editor.Data.Node.Data }[] = [
      {
        last: element,
        node: element,
      },
    ];
    let result = element;
    let index;

    while ((index = queue.shift())) {
      const { node, last } = index;
      if (node.id === ref) {
        result = last;
        break;
      }
      if (
        !NON_CONTENT_ELEMENTS.includes(node.type) &&
        !(
          NON_CONTENT_ELEMENTS_WITH_PROPERTIES[node.type] &&
          intersection(
            Object.keys(node.properties || {}),
            NON_CONTENT_ELEMENTS_WITH_PROPERTIES[node.type],
          ).length > 0
        ) &&
        node.childNodes
      ) {
        let properLast = last;
        if (node.type === 'p') {
          properLast = node;
        }

        // eslint-disable-next-line no-loop-func
        const childreen = node.childNodes.map((value: Editor.Data.Node.Data) => ({
          last: properLast,
          node: value,
        }));

        queue.unshift(...childreen);
      }
    }
    return result;
  }

  private static getNodeContents(node: Editor.Data.Node.Data) {
    const queue = [node];
    let result = '';
    while (queue.length) {
      const element = queue.shift();
      if (element) {
        if (element.type === 'text') {
          result += element.content;
        } else if (
          !NON_CONTENT_ELEMENTS.includes(element.type) &&
          !(
            NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type] &&
            intersection(
              Object.keys(element.properties || {}),
              NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type],
            ).length > 0
          ) &&
          element.childNodes
        ) {
          queue.unshift(...element.childNodes);
        }
      }
    }
    return result;
  }

  static getContent(element: Editor.Data.Node.Data, ref: string | null = null) {
    if (element.type === ELEMENTS.TableElement.ELEMENT_TYPE) {
      if (ref) {
        return NodeUtils.getNodeContents(NodeUtils.getProperParentForReferenceNode(element, ref));
      }
      return NodeUtils.getNodeContents(element);
    }
    if (element.type === 'p') {
      return NodeUtils.getNodeContents(element);
    }
    return null;
  }

  static getNodeContentsAfterField(node: Editor.Data.Node.Data, fieldId: string | undefined) {
    let result = '';
    if (!fieldId) {
      return result;
    }
    let queue = [JSON.parse(JSON.stringify(node))];
    if (node.type === ELEMENTS.TableElement.ELEMENT_TYPE) {
      queue = [NodeUtils.getProperParentForReferenceNode(node, fieldId)];
    }
    while (queue.length) {
      const element = queue.shift();
      if (element.type === 'f' && element.id === fieldId) {
        result = '';
      } else if (element.type === 'text') {
        result += element.content;
      } else if (
        !NON_CONTENT_ELEMENTS.includes(element.type) &&
        !(
          NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type] &&
          intersection(
            Object.keys(element.properties),
            NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type],
          ).length > 0
        ) &&
        element.childNodes
      ) {
        queue.unshift(...element.childNodes);
      }
    }
    return result;
  }

  static getNodeContentsBeforeField(node: Editor.Data.Node.Data, fieldId: string | undefined) {
    let result = '';
    if (!fieldId) {
      return result;
    }
    let queue = [JSON.parse(JSON.stringify(node))];
    if (node.type === ELEMENTS.TableElement.ELEMENT_TYPE) {
      queue = [NodeUtils.getProperParentForReferenceNode(node, fieldId)];
    }
    while (queue.length) {
      const element = queue.shift();
      if (element.type === 'f' && element.id === fieldId) {
        queue = [...(element.childNodes || [])];
      } else if (element.type === 'text') {
        result += element.content;
      } else if (
        !NON_CONTENT_ELEMENTS.includes(element.type) &&
        !(
          NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type] &&
          intersection(
            Object.keys(element.properties),
            NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type],
          ).length > 0
        ) &&
        element.childNodes
      ) {
        queue.unshift(...element.childNodes);
      }
    }
    return result;
  }
}
