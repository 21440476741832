import { FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';

import { FormattedDate } from '_common/components';

import styles from './DueDateBanner.module.scss';
import dayjs from 'dayjs';
import { useMemo } from 'react';

type DueDateBannerProps = {
  dueDate: ISODate;
};

const DueDateBanner = ({ dueDate }: DueDateBannerProps) => {
  //Will be due by amount of:
  //If negative, is due by amount of:
  const dueDiff = useMemo(() => dayjs(dueDate).diff(dayjs(), 'days'), [dueDate]);

  if (dueDiff < 0) {
    return (
      <div className={`${styles.dueDate} ${styles.isDue}`}>
        <Icon size={16} icon="CalendarRed" />
        <FormattedMessage id="DUE_DATE_IS_OVERDUE_BY_DAYS" values={{ days: Math.abs(dueDiff) }} />
      </div>
    );
  }

  if (dueDiff <= 2) {
    return (
      <div className={`${styles.dueDate} ${styles.dueSoon}`}>
        <Icon size={16} icon="CalendarYellow" />
        <FormattedMessage
          id="DUE_DATE_IS"
          values={{ dueDate: <FormattedDate date={dueDate} type="simplistic" /> }}
        />
      </div>
    );
  }

  return (
    <div className={`${styles.dueDate} ${styles.notDue}`}>
      <Icon size={16} icon="CalendarBlue" />
      <FormattedMessage
        id="DUE_DATE_IS"
        values={{ dueDate: <FormattedDate date={dueDate} type="simplistic" /> }}
      />
    </div>
  );
};

export default DueDateBanner;
