import { useSelector } from '_common/hooks';

import OnboardingTemplate from './OnboardingTemplate';
import { selectIsIEnvision } from 'App/redux/appSlice';

import styles from './OnboardingOverlay.module.scss';

const OnboardingOverlay = () => {
  const active = useSelector((state) => state.onboarding.active.editor);
  const isIEnvision = useSelector(selectIsIEnvision);

  if (active && !isIEnvision) {
    return (
      <div className={styles.overlay}>
        <div className={styles.content}>
          <OnboardingTemplate />
        </div>
      </div>
    );
  }
  return null;
};

export default OnboardingOverlay;
