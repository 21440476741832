/* eslint-disable class-methods-use-this */

import { RealtimeObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { NodeNumberingData } from '.';
import { NumberingData, ListIndexData, GeneralNumberingData } from './Numbering.types';

export class Numbering extends RealtimeObject<NumberingData> {
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'numberings_limited');
  }

  get generalNumbering() {
    return JSON.parse(JSON.stringify(this.get(['gN'])));
  }

  get lists() {
    return JSON.parse(JSON.stringify(this.get(['lst'])));
  }

  getBlockInfo(nodeId: string): NodeNumberingData | undefined {
    return this.get(['gN', nodeId]) as unknown as NodeNumberingData;
  }

  getListIndexing(listId: string): ListIndexData {
    const tempNumbering: GeneralNumberingData = {};
    const listNodes = (this.get(['lst', listId]) || []) as string[];
    const gN = this.get(['gN']) as unknown as GeneralNumberingData;
    for (let index = 0, length = listNodes.length; index < length; index++) {
      const nodeId = listNodes[index];
      if (gN[nodeId]) {
        tempNumbering[nodeId] = gN[nodeId];
      }
    }
    return JSON.parse(
      JSON.stringify({
        order: listNodes,
        data: tempNumbering,
      }),
    );
  }

  handleLoad(): void {
    //
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    const data = this.get();
    this.emit('UPDATED', data, ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }
}
