import { Mixin } from 'mixwith';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { Parser } from 'Editor/services/EditionManager/Clipboard';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { notify } from '_common/components/ToastSystem';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class CopyPasteHandler extends superclass {
      destroy() {
        super.destroy();
      }

      // #################################################
      //                       CUT
      // #################################################
      handleCutOnNonCollapsedSelection(actionContext, event) {
        if (this.selectionManager.isCurrentSelectionDeletable()) {
          this.selectionManager.fixNonCollapsedTextSelection({
            suggestionMode: true,
            forceTextAsWrap: true,
            // isDelete: true, // avoid these properties here with multiselection
          });

          this.clipboard.handleCut(event);

          this.removeSelectionContent(actionContext);
        } else {
          notify({
            type: 'warning',
            title: 'global.warning',
            message: 'editor.errors.cut',
          });
        }
      }

      // #################################################
      //                       PASTE
      // #################################################
      handlePasteOnNonCollapsedSelection(actionContext, parsedData, pasteOption) {
        actionContext.selectionType = ActionContext.SELECTION.RANGE;
        actionContext.selectionBackwards = this.selectionManager.isSelectionBackwards();

        this.selectionManager.fixNonCollapsedTextSelection({
          suggestionMode: true,
          forceTextAsWrap: true,
        });

        if (this.removeSelectionContent(actionContext)) {
          const pasteOptions = this.clipboard.getPasteOptions();
          const startMarker = pasteOptions && pasteOptions.startMarker;
          if (startMarker) {
            this.selectionManager.setCaret(startMarker, 'POST');
          }

          const selection = EditorSelectionUtils.getSelection();
          const level0Node = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);

          this.handlePasteOnCollapsedSelection(
            actionContext,
            parsedData,
            pasteOption,
            level0Node,
            selection.anchorNode,
          );
        }
      }

      handlePasteOnCollapsedSelection(
        actionContext,
        parsedData,
        pasteOption,
        baseNode,
        anchorNode,
      ) {
        if (!baseNode || DOMUtils.isNodeAContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);
            // anchorNode = selection.anchorNode;
            // anchorOffset = selection.anchorOffset;
          }
        }

        // handle insert data
        // this._handlePasteOperation(actionContext, parsedData, baseNode, anchorNode);

        if (baseNode) {
          if (DOMUtils.isNodeEditableTextElement(baseNode)) {
            // SELECTION IS A DEFAULT TEXT ELEMENT
            this._handlePasteOnTextElement(
              actionContext,
              parsedData,
              baseNode,
              anchorNode,
              pasteOption,
            );
          } else if (!DOMUtils.isBlockNodeEditable(baseNode)) {
            // SELECTION IS A NON-EDITABLE ELEMENT
            this._handlePasteOnNonEditableElement(
              actionContext,
              parsedData,
              baseNode,
              anchorNode,
              pasteOption,
            );
          } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
            // SELECTION IS A FIGURE
            this._handlePasteOnFigureElement(
              actionContext,
              parsedData,
              baseNode,
              anchorNode,
              pasteOption,
            );
          } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
            // SELECTION IS A TABLE
            this._handlePasteOnTableElement(
              actionContext,
              parsedData,
              baseNode,
              anchorNode,
              pasteOption,
            );
          } else if (DOMUtils.isNodeAContainerElement(baseNode)) {
            this._handlePasteOnContainerElement(
              actionContext,
              parsedData,
              baseNode,
              anchorNode,
              pasteOption,
            );
          }
        }
      }

      _handlePasteOnContainerElement(actionContext, parsedData, baseNode, anchorNode, pasteOption) {
        if (DOMUtils.BLOCK_CONTAINER_ELEMENTS.includes(anchorNode.tagName)) {
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
          }
        }

        const subLevel0Node = DOMUtils.findNodeLevel0(baseNode, anchorNode);
        if (subLevel0Node) {
          this._handlePasteOnTextElement(
            actionContext,
            parsedData,
            subLevel0Node,
            anchorNode,
            pasteOption,
          );
        }
      }

      _handlePasteOnNonEditableElement(
        actionContext,
        parsedData,
        baseNode,
        anchorNode,
        pasteOption,
      ) {
        const p = DOMElementFactory.createNewParagraphElement();

        this.handleInsertBlockNodeOnCollapsedSelection(
          actionContext,
          p,
          baseNode,
          anchorNode,
          pasteOption,
        );

        this.selectionManager.setCaret(p, 'INSIDE_END');

        const selection = EditorSelectionUtils.getSelection();
        baseNode = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);

        this._handlePasteOnTextElement(
          actionContext,
          parsedData,
          baseNode,
          selection.anchorNode,
          pasteOption,
        );
      }

      _handlePasteOnTableElement(actionContext, parsedData, baseNode, anchorNode, pasteOption) {
        // TODO: this needs to be reviewed

        this._handlePasteOnTextElement(
          actionContext,
          parsedData,
          baseNode,
          anchorNode,
          pasteOption,
        );
      }

      _handlePasteOnFigureElement(actionContext, parsedData, baseNode, anchorNode, pasteOption) {
        // TODO: this needs to be reviewed

        this._handlePasteOnTextElement(
          actionContext,
          parsedData,
          baseNode,
          anchorNode,
          pasteOption,
        );
      }

      // _handlePasteOnListElement(actionContext, baseNode) {}

      _handlePasteOnTextElement(actionContext, parsedData, baseNode, anchorNode, pasteOption) {
        // if markers don't exist create markers
        let selection;
        let level0;

        const pasteOptions = this.clipboard.getPasteOptions();
        let endMarker = pasteOptions && pasteOptions.endMarker;

        if (parsedData) {
          let childElement;
          const auxBin = DOMElementFactory.buildElement('div');

          // TODO: merge first block content temporarily disabled
          // let spliSelectionAfterElement = null;

          // if (this.canMergeContens(baseNode, parsedData.firstChild)) {
          //   // unwrap first block child
          //   const blockToUnwrap = parsedData.firstChild;

          //   if (
          //     !parsedData.firstChild.nextSibling ||
          //     DOMUtils.isInlineNode(parsedData.firstChild.nextSibling)
          //   ) {
          //     spliSelectionAfterElement = blockToUnwrap.lastChild;
          //   }

          //   while (blockToUnwrap.firstChild) {
          //     parsedData.insertBefore(blockToUnwrap.firstChild, blockToUnwrap);
          //   }

          //   parsedData.removeChild(blockToUnwrap);
          // }

          while (parsedData.firstChild) {
            childElement = parsedData.firstChild;

            auxBin.appendChild(childElement);

            const selectionOptions = {};

            if (pasteOption === Parser.ORIGINAL_STYLES) {
              if (childElement === auxBin.firstChild) {
                // split all format elements and insert directly in block element
                selectionOptions.forceInlineSplit = true;
              }
              // handle all format elements as wrap elements
              selectionOptions.forceTextAsWrap = true;
            } else if (
              pasteOption === Parser.MATCH_DESTINATION &&
              !childElement === auxBin.firstChild
            ) {
              // handle format elements as wrap elements
              selectionOptions.forceTextAsWrap = true;
            }

            if (EditorDOMElements.isNodeInlineElement(childElement)) {
              // insert inline node

              selection = EditorSelectionUtils.getSelection();
              level0 = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);

              this.clipboard.prepareForInlinePaste(actionContext, childElement);

              this.handleInsertInlineNodeOnCollapsedSelection(
                actionContext,
                childElement,
                level0,
                selection.anchorNode,
                selectionOptions,
              );
              if (childElement.nodeType !== Node.TEXT_NODE) {
                this.selectionManager.setCaret(childElement, 'POST');
              }

              // if (childElement === spliSelectionAfterElement) {
              //   this.insertRootNodeOnText(actionContext, null, null, (before, after) => true);
              // }
            } else {
              // insert block nodes

              selection = EditorSelectionUtils.getSelection();
              level0 = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);

              this.clipboard.prepareForPaste(actionContext, childElement);

              this.handleInsertBlockNodeOnCollapsedSelection(
                actionContext,
                childElement,
                level0,
                selection.anchorNode,
              );
            }
          }

          if (this.clipboard.allowOpenPasteOptions) {
            if (endMarker) {
              this.clipboard.removePasteOptions();
            }

            endMarker = DOMElementFactory.buildElement(ELEMENTS.PasteMarkerElement.TAG, {
              id: 'paste_marker_end',
              markerposition: 'end',
            });

            let range = EditorSelectionUtils.getRange();

            const closest = DOMUtils.closest(range.commonAncestorContainer, [
              ...DOMUtils.BLOCK_NON_EDITABLE_ELEMENTS,
              ...DOMUtils.MULTI_BLOCK_CONTAINER_ELEMENTS,
              ELEMENTS.TableElement.TAG,
              ELEMENTS.FigureElement.TAG,
              ELEMENTS.TrackInsertElement.TAG,
            ]);

            if (
              closest &&
              closest.nodeType === Node.ELEMENT_NODE &&
              !DOMUtils.isNodeAContainerElement(closest)
            ) {
              closest.appendChild(endMarker);
            } else {
              if (range.startContainer.parentNode === this.page) {
                this.selectionManager.fixSelection();
                range = EditorSelectionUtils.getRange();
              }

              range.insertNode(endMarker);
            }

            if (endMarker.parentNode) {
              this.selectionManager.setCaret(endMarker, 'PRE');
            }

            setTimeout(() => {
              this.clipboard.openPasteOptions();
            }, 0);
          }

          // fix selection after paste
          this.selectionManager.fixCollapsedTextSelection({ suggestionMode: true });
        }
      }
    },
);
