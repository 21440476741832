import { createContext, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from '_common/hooks';
import { usePDFContext, usePDFManagerEvents } from 'PDF/PDFContext';
import { PageAnnotations, PDFPage } from 'PDF/services/DataManager/models';
import AnnotationsLayer from '../AnnotationsLayer/AnnotationsLayer';
import TextLayer from '../TextLayer/TextLayer';
import CanvasLayer from '../CanvasLayer/CanvasLayer';
import AnnotationsTogglesLayer from '../AnnotationsTogglesLayer/AnnotationsTogglesLayer';
import CreationLayer from '../CreationLayer/CreationLayer';
import {
  selectFilteredPDFAnnotations,
  selectFilteredPDFTasks,
  selectPDFTasks,
  setInitiatedWithTasks,
} from 'PDF/redux/PDFAnnotationsSlice';
import { selectPDFZoom } from 'PDF/redux/PDFGeneralSlice';
import styles from './PDFPage.module.scss';
import { PageViewport } from 'pdfjs-dist';

type PDFPageContextValue = {
  pageNum: number;
  viewport: PageViewport;
};

const PDFPageContext = createContext<PDFPageContextValue | undefined>(undefined);

export type PDFPageContentProps = {
  index: number;
  pdfViewport: PageViewport | null;
  setPdfViewport: (pdfViewport: PageViewport) => void;
};

const PDFPageContent = ({ index, pdfViewport, setPdfViewport }: PDFPageContentProps) => {
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();

  const annotations = useSelector(selectFilteredPDFAnnotations);
  const tasks = useSelector(selectFilteredPDFTasks);
  const zoom = useSelector(selectPDFZoom);
  const annotationsLoaded = useSelector((state) => state.pdf.annotations.loaded);
  const allActiveTasks = useSelector(selectPDFTasks).filter((task) => task.state !== 'Cancelled');

  const [pdfPage, setPdfPage] = useState<PDFPage | null>(null);
  const [textContent, setTextContent] = useState<PDF.Page.PDFPageContent | null>(null);
  const [findHighlights, setFindHighlights] = useState<PDF.Find.FindHitType[] | null>(null);

  useEffect(() => {
    if (annotationsLoaded) {
      dispatch(setInitiatedWithTasks(!!allActiveTasks.length));
    }
  }, [annotationsLoaded]);

  const pageNum = index + 1;

  // temp
  const [reRender, setReRender] = useState<any>(null);

  const reRenderPageListener = (pageNum: number) => {
    if (pageNum === index + 1) {
      setReRender(Date.now());
    }
  };

  useEffect(() => {
    let _collection: PageAnnotations | undefined;

    let pdfPage: PDFPage | null = null;
    const loadPage = async () => {
      pdfPage = await pdfManager.getPage(pageNum);
      if (pdfPage) {
        setPdfPage(pdfPage);
        pdfPage.on('UPDATE_HIGHLIGHTS', setFindHighlights);
        const text = await pdfPage.getTextContent();
        setTextContent(text);
        setFindHighlights(pdfPage.findHighlights);
        pdfManager.navigation?.loadedPage(pageNum);
      }

      _collection = pdfManager.getAnnotations(pageNum);
    };

    loadPage();

    return () => {
      if (_collection) {
        _collection.dispose();
      }
      if (pdfPage) {
        pdfPage.off('UPDATE_HIGHLIGHTS', setFindHighlights);
      }
      pdfManager.navigation?.unloadedPage(pageNum);
    };
  }, [pdfManager, index]);

  usePDFManagerEvents('RE_RENDER_PAGE', reRenderPageListener, [index]);

  useEffect(() => {
    if (pdfPage) {
      const viewport = pdfPage.getViewport({ scale: zoom });
      setPdfViewport(viewport || null);
    }
  }, [pdfPage, zoom, reRender]);

  const pageContext = useMemo(() => {
    return {
      pageNum,
      viewport: pdfViewport!,
    };
  }, [pageNum, pdfViewport]);

  const pageAnnotations = useMemo(() => {
    const pageAnnotations: PDF.Annotation[] = [];
    for (let i = 0; i < annotations.list.length; i++) {
      const annotation = annotations.annotations[annotations.list[i]];
      if (annotation.pageNumber === pageNum) {
        pageAnnotations.push(annotation);
      }
    }
    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];
      if (task.pageNumber === pageNum) {
        pageAnnotations.push(task);
      }
    }
    return pageAnnotations;
  }, [annotations, tasks]);

  if (!pdfViewport) {
    return null;
  }

  return (
    <PDFPageContext.Provider value={pageContext}>
      <CanvasLayer pageNum={pageNum} viewport={pdfViewport} pdfPage={pdfPage} />
      <div data-overlays-layer data-page-num={pageNum} className={styles.overlays}>
        <TextLayer
          pageNum={pageNum}
          textContent={textContent}
          viewport={pdfViewport}
          findHighlights={findHighlights}
        />
        <AnnotationsLayer pageNum={pageNum} annotations={pageAnnotations} viewport={pdfViewport} />
        <AnnotationsTogglesLayer
          pageNum={pageNum}
          annotations={pageAnnotations}
          viewport={pdfViewport}
        />
      </div>
      <CreationLayer pageNum={pageNum} viewport={pdfViewport} />
    </PDFPageContext.Provider>
  );
};

export default memo(PDFPageContent);
