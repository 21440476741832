import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'dodoc-design-system';

import SearchUser from '_common/components/SearchUser/SearchUser';
import { UserOption } from '_common/components/SearchUser/SearchUser';

import { USER_BEHAVIOUR, HandlerProps } from '../AdvancedFilterOptions';
import { useAdvancedFilterContext } from '../AdvancedFilterContext';
import styles from './Handlers.module.scss';

type UserHandlerProps = {
  testId: string;
};

const UserHandler = ({ sequence, testId }: HandlerProps & UserHandlerProps) => {
  const intl = useIntl();

  const { advancedFilter, setValue, setAuxValue, setQuery } = useAdvancedFilterContext();
  const { option, metadataOption, value, auxValue } = advancedFilter;
  const mergedOption = metadataOption ? `${option}.${metadataOption}` : option;

  const [options, setOptions] = useState<UserOption[]>();
  const selectedUser = useMemo(() => {
    return options?.find(
      (userOption) => userOption.name === value || userOption.username === value,
    );
  }, [options, value]);

  useEffect(() => {
    if (!auxValue) {
      if (!sequence.type || !value) {
        setValue(undefined);
        setQuery(undefined);
        return;
      }

      // sequence.type === 'text_user' ? localUser.name || localUser.username : localUser.username;
      setQuery(
        USER_BEHAVIOUR({
          field: mergedOption,
          value,
        }),
      );
    } else {
      setValue('');
      setQuery(
        USER_BEHAVIOUR({
          field: mergedOption,
          value: '',
        }),
      );
    }
  }, [value, auxValue]);

  const handleUserChange = (newUser: UserOption) => {
    if (newUser) {
      setValue(sequence.type === 'text_user' ? newUser.name || newUser.username : newUser.username);
    } else {
      setValue(undefined);
    }
  };

  const handleOptionsLoad = async (optionsPromise: Promise<UserOption[]>) => {
    setOptions(await optionsPromise);
  };

  if (!sequence) {
    return null;
  }

  return (
    <div className={styles.handlerRoot} data-testid={testId}>
      <SearchUser
        escapeClearsValue
        value={selectedUser}
        onChange={handleUserChange}
        placeholder={intl.formatMessage({
          id: 'TASK_SELECT_USER',
        })}
        creatable={sequence.type === 'text_user'}
        width="30rem"
        disabled={!!auxValue}
        loadOnRender={value}
        onLoadOptions={handleOptionsLoad}
        testId={testId}
      />
      {metadataOption === 'assignee' && (
        <div className={styles.emptyField}>
          <Switch
            labelPlacement="right"
            size="large"
            active={!!auxValue}
            onChange={() => {
              setAuxValue(auxValue ? undefined : 'true');
            }}
            testId={`${testId}-empty-field-toggle`}
          >
            {intl.formatMessage(
              { id: 'FIELD_IS_EMPTY' },
              {
                field: metadataOption,
              },
            )}
          </Switch>
        </div>
      )}
    </div>
  );
};

export default UserHandler;
