import { useIntl } from 'react-intl';
import { Tooltip } from 'dodoc-design-system';

import TOOLBAR_ELEMENTS, {
  Type,
  Structure,
  Symbol,
} from 'Editor/modals/EquationsModal/ToolbarElements';
import ToolbarSection from './ToolbarSection/ToolbarSection';

import styles from './ToolbarGroup.module.scss';

const ToolbarGroup = ({ type }: { type: Type }) => {
  const intl = useIntl();

  const renderSection = (element: Structure | Symbol) => {
    switch (type) {
      case 'structures':
        return <ToolbarSection element={element as Structure} type="structures" />;
      case 'symbols':
        return <ToolbarSection element={element as Symbol} type="symbols" />;
    }
  };

  console.log(TOOLBAR_ELEMENTS[type]);

  return (
    <div className={styles.group}>
      {TOOLBAR_ELEMENTS[type].map((element, i) => (
        <Tooltip
          key={element.label}
          content={intl.formatMessage({ id: element.label })}
          testId={`${type}-${i}-tooltip`}
        >
          {renderSection(element)}
        </Tooltip>
      ))}
    </div>
  );
};

export default ToolbarGroup;
