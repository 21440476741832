import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Popover, Toggle, Tooltip } from 'dodoc-design-system';
import type { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

import { useSelector } from '_common/hooks';

import styles from './ImageOptionsWidget.module.scss';

type ImageOptionsWidgetProps = {
  currentProperty: Editor.Styles.ImageWrapProperty;
  offsets: Editor.Common.Rect | null;
  onImageWrapChange: (props: Editor.Styles.ImageProperties) => void;
};

const ImageOptionsWidgetView = ({
  currentProperty,
  offsets,
  onImageWrapChange,
}: ImageOptionsWidgetProps) => {
  const intl = useIntl();

  const zoom = useSelector((state) => state.editor.status.zoom);
  const widgetRef = useRef<HTMLDivElement>(null);

  const PROPERTIES: {
    id: Editor.Styles.ImageWrapProperty;
    icon: IconTypes['24'];
    label: string;
  }[] = [
    {
      id: 'inline',
      icon: 'TextWrapInline',
      label: intl.formatMessage({ id: 'IN_LINE_WITH_TEXT' }),
    },
    { id: 'left', icon: 'TextWrapLeft', label: intl.formatMessage({ id: 'LEFT' }) },
    { id: 'right', icon: 'TextWrapRight', label: intl.formatMessage({ id: 'RIGHT' }) },
    {
      id: 'topAndBottom',
      icon: 'TextWrapTopAndBottom',
      label: intl.formatMessage({ id: 'TOP_AND_BOTTOM' }),
    },
    {
      id: 'behindText',
      icon: 'TextWrapBehindText',
      label: intl.formatMessage({ id: 'BEHIND_TEXT' }),
    },
    {
      id: 'inFrontText',
      icon: 'TextWrapInFrontOfText',
      label: intl.formatMessage({ id: 'IN_FRONT_OF_TEXT' }),
    },
  ];

  const [position, setPosition] = useState<{ left: number; top: number } | undefined>({
    left: -2,
    top: (offsets?.height ?? 0) / zoom + 8,
  });

  const handleWidgetPosition = useCallback(() => {
    const editorRoot = document.getElementById('EditorRoot');
    const widgetElement = widgetRef?.current;
    if (editorRoot && offsets && widgetElement) {
      const figureMargin = 20 / zoom;
      const padding = 8;
      const widgetTop = offsets.height / zoom;
      const widgetHeight = widgetElement.getBoundingClientRect().height / zoom;
      const widgetSpace = widgetHeight + figureMargin + padding;
      const rootHeight = editorRoot.clientHeight / zoom;
      const rootScroll = editorRoot.scrollTop / zoom;
      const defaultLeft = -2;

      if (rootHeight + rootScroll - widgetSpace <= offsets.top) {
        setPosition({ left: defaultLeft, top: 0 });
      } else {
        const diff = rootHeight + rootScroll - widgetTop - offsets.top - widgetSpace;
        const defaultTop = widgetTop + Math.min(diff, padding);

        const top = defaultTop * zoom;
        const left = defaultLeft * zoom;

        setPosition({ left: left + (defaultLeft - left), top: top + (defaultTop - top) });
      }
    }
  }, [offsets, zoom]);

  const style = useMemo<CSSProperties>(() => {
    const newStyle: CSSProperties = {};
    if (position) {
      newStyle.left = position.left;
      newStyle.top = position.top;
    }

    return newStyle;
  }, [position]);

  useEffect(() => {
    handleWidgetPosition();
  }, [offsets]);

  useEffect(() => {
    const editorRoot = document.getElementById('EditorRoot');

    if (editorRoot) {
      editorRoot.addEventListener('scroll', handleWidgetPosition);
      window.addEventListener('resize', handleWidgetPosition);
    }

    return () => {
      if (editorRoot) {
        editorRoot.removeEventListener('scroll', handleWidgetPosition);
        window.removeEventListener('resize', handleWidgetPosition);
      }
    };
  }, []);

  const setCurrentProperty = useCallback(
    (property: Editor.Styles.ImageWrapProperty) => {
      if (property !== currentProperty) {
        /*TODO:InlineProperties callback*/
        onImageWrapChange({
          imageWrap: {
            value: property,
          },
        });
      }
    },
    [currentProperty],
  );

  if (!offsets) {
    return null;
  }

  return (
    <div
      ref={widgetRef}
      className={styles.container}
      style={style}
      data-testid="image-inline-properties-container"
    >
      <Popover isOpen={true} close={() => {}} testId="image-inline-properties-popper">
        <div className={styles.root}>
          <div className={styles.properties}>
            {PROPERTIES.map((property) => (
              <Tooltip
                key={property.id}
                placement="top"
                content={property.label}
                testId={`image-property-${property.id}-tooltip`}
              >
                <Toggle
                  size="medium"
                  variant="link"
                  icon={property.icon}
                  isToggled={currentProperty === property.id}
                  onClick={() => {
                    setCurrentProperty(property.id);
                  }}
                  testId={`image-property-${property.id}-toggle`}
                />
              </Tooltip>
            ))}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default ImageOptionsWidgetView;
