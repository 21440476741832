import { ReactNode } from 'react';
import { ContextMenuTrigger as MenuTrigger } from 'react-contextmenu';

type ContextMenuTriggerProps = {
  id: string;
  disabled?: boolean;
  children: ReactNode;
};

const ContextMenuTrigger = ({ id, disabled, children }: ContextMenuTriggerProps) => (
  <MenuTrigger
    id={id}
    disable={disabled}
    disableIfShiftIsPressed
    holdToDisplay={-1}
    renderTag={'div'}
  >
    {children}
  </MenuTrigger>
);

export default ContextMenuTrigger;
