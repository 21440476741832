import { RangeIterator } from '../Iterators';

class DoDOCRange extends Range implements DoDOCSelection.DoDOCRange.IAcceptDoDOCVisitor {
  static fromNativeRange(nativeRange: Range) {
    let range = new DoDOCRange();
    range.setStart(nativeRange.startContainer, nativeRange.startOffset);
    range.setEnd(nativeRange.endContainer, nativeRange.endOffset);
    return range;
  }

  cloneRange(): DoDOCRange {
    const newRange = new DoDOCRange();
    newRange.setStart(this.startContainer, this.startOffset);
    newRange.setEnd(this.endContainer, this.endOffset);
    return newRange;
  }

  getClientRects(): DOMRectList {
    return super.getClientRects();
  }

  accept(visitor: DoDOCSelection.DoDOCRange.IRangeVisitor) {
    visitor.visitDoDOCRange(this);
  }

  collapseToStart() {
    this.collapse(true);
  }

  collapseToEnd() {
    this.collapse();
  }

  setStartAndEnd(startNode: Node, startOffset: number, endNode: Node, endOffset: number) {
    this.setStart(startNode, startOffset);
    this.setEnd(endNode, endOffset);
  }

  getNodes(nodeTypes: number[] = [], filterFuntion?: (node: Node) => boolean): Node[] {
    const nodes: Node[] = [];

    const rangeIterator = new RangeIterator(this);

    let node: Node | null;
    while (rangeIterator.hasNext()) {
      node = rangeIterator.next();

      if (node) {
        if (nodeTypes.length && !nodeTypes.includes(node.nodeType)) {
          continue;
        }

        if (filterFuntion && !filterFuntion(node)) {
          continue;
        }

        if (
          node instanceof Text &&
          node === this.startContainer &&
          this.startOffset === node.length
        ) {
          continue;
        }

        if (node instanceof Text && node === this.endContainer && this.endOffset === 0) {
          continue;
        }

        nodes.push(node);
      }
    }

    return nodes;
  }

  containsNode(node: Node): boolean {
    const length = node instanceof Text ? node.length : node.childNodes.length;

    if (this.comparePoint(node, 0) === 0 && this.comparePoint(node, length) === 0) {
      return true;
    }

    return false;
  }

  instersectsNode(node: Node): boolean {
    const length = node instanceof Text ? node.length : node.childNodes.length;

    if (
      (this.comparePoint(node, 0) === -1 && this.comparePoint(node, length) === 0) ||
      (this.comparePoint(node, 0) === 0 && this.comparePoint(node, length) === 1)
    ) {
      return true;
    }

    return false;
  }

  isValidOffset(node: Node, offset: number): boolean {
    const length = node instanceof Text ? node.length : node.childNodes.length;
    return offset >= 0 && offset <= length;
  }

  isValid(): boolean {
    return (
      !!this.startContainer &&
      !!this.endContainer &&
      !!this.commonAncestorContainer &&
      document.contains(this.commonAncestorContainer) &&
      this.isValidOffset(this.startContainer, this.startOffset) &&
      this.isValidOffset(this.endContainer, this.endOffset)
    );
  }
}

export default DoDOCRange;
