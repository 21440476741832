//@ts-nocheck

const OnboardingTemplate = () => {
  return (
    <>
      <paragraph-element
        element_type="p"
        id="ddc9405ea5e-3810-4233-b72a-6b359583a366"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-alignment="left"
        data-style-id="t"
        data-line-height="1"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
        style={{ lineHeight: 1 }}
      >
        <format-element
          element_type="format"
          id="ddce9c2774b-c152-4d7f-ba72-ea4bb1a07a06"
          parent_id="ddc9405ea5e-3810-4233-b72a-6b359583a366"
          fontfamily="Times New Roman"
          fontsize="24"
          style={{ fontSize: '24pt', fontFamily: 'Times New Roman' }}
        />
        <format-element
          element_type="format"
          id="ddc6b3ab335-ebab-4d9f-87ab-f07ed8c8ce31"
          parent_id="ddc9405ea5e-3810-4233-b72a-6b359583a366"
          fontfamily="Calibri"
          style={{ fontFamily: 'Calibri' }}
        />
        <format-element
          element_type="format"
          id="ddcad5e46cd-6e60-4cf7-8ba2-15c8c95386c9"
          parent_id="ddc9405ea5e-3810-4233-b72a-6b359583a366"
          color="rgb(26,26,26)"
          fontsize="18"
          style={{ fontSize: '18pt', color: 'rgb(26, 26, 26)' }}
        >
          <format-element
            element_type="format"
            id="ddc9e0217ec-9735-40e8-be93-ed0fb8cc1b25"
            parent_id="ddcad5e46cd-6e60-4cf7-8ba2-15c8c95386c9"
            bold="true"
          >
            Reducing blood draws with Artificial Intelligence
          </format-element>
        </format-element>
      </paragraph-element>
      <paragraph-element
        element_type="p"
        id="ddcf04f72fa-628c-4823-82e0-f53cdb4dfeb9"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-alignment="justify"
        data-style-id="a"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
      >
        <format-element
          element_type="format"
          id="ddcb39873dd-b647-4fa4-8e29-fbdc2bce51ce"
          parent_id="ddcf04f72fa-628c-4823-82e0-f53cdb4dfeb9"
          color="rgb(26,26,26)"
          fontfamily="Calibri"
          fontsize="11"
          style={{ fontSize: '11pt', color: 'rgb(26, 26, 26)', fontFamily: 'Calibri' }}
        >
          <format-element
            element_type="format"
            id="ddc2219d975-e449-41fa-a18d-c0a503ae6524"
            parent_id="ddcb39873dd-b647-4fa4-8e29-fbdc2bce51ce"
            bold="true"
            fontfamily="Inter"
            italic="false"
            fontsize="10"
            style={{ fontSize: '10pt', fontFamily: 'Inter' }}
          />
        </format-element>
        <format-element
          element_type="format"
          id="ddc222c4c99-cecf-48aa-9ea5-0aca54936e76"
          parent_id="ddcf04f72fa-628c-4823-82e0-f53cdb4dfeb9"
          color="rgb(26,26,26)"
          fontsize="11"
          style={{ fontSize: '11pt', color: 'rgb(26, 26, 26)' }}
        >
          <format-element
            element_type="format"
            id="ddcfe8ac1a8-a080-40d2-a099-da1dc5ccf0a1"
            parent_id="ddc222c4c99-cecf-48aa-9ea5-0aca54936e76"
            bold="true"
            fontfamily="Inter"
            italic="false"
            fontsize="10"
            style={{ fontSize: ' 10pt', fontFamily: ' Inter' }}
          >
            <format-element
              element_type="format"
              id="ddcbab4309d-1581-4e19-b0d7-58389f5d05e8"
              parent_id="ddcfe8ac1a8-a080-40d2-a099-da1dc5ccf0a1"
              italic="true"
            >
              <format-element
                element_type="format"
                id="ddc9f720d3d-f4a9-48ce-bf60-69c8d804a8c9"
                parent_id="ddcbab4309d-1581-4e19-b0d7-58389f5d05e8"
                fontfamily="Calibri"
                style={{ fontFamily: 'Calibri' }}
              >
                Objectives:
              </format-element>
            </format-element>
          </format-element>
          <format-element
            element_type="format"
            id="ddc9aa28b2f-246e-437e-8e0e-e29eed4cdbbf"
            parent_id="ddc222c4c99-cecf-48aa-9ea5-0aca54936e76"
            fontfamily="Calibri"
            italic="true"
            fontsize="10"
            style={{ fontSize: ' 10pt', fontFamily: 'Calibri' }}
          >
            {' '}
            To reduce blood draws by predicting when a proposed future lab test is likely to
            contribute information gain and thereby influence clinical management in patients with
            gastrointestinal bleeding.{' '}
            <comment-element
              element_type="comment"
              element_reference="ddcce88d88a-c234-4d80-a4fd-31c3bfa58a7f"
              id="ddc8c64acd5-c211-4c8c-848a-9e9d698e3eb1"
              parent_id="ddc9aa28b2f-246e-437e-8e0e-e29eed4cdbbf"
              author="2"
              style={{
                fontFamily: 'inherit',
                fontSize: 'inherit',
                fontStyle: 'inherit',
                fontWeight: 'inherit',
                textDcoration: 'inherit',
                verticalAlign: 'inherit',
                boxShadow:
                  'rgba(217, 33, 36, 0.14) 0px 2px 0px, rgba(217, 33, 36, 0.14) 0px -2px 0px 0px',
                background: 'rgba(217, 33, 36, 0.14)',
              }}
            >
              Recent studies have demonstrated that frequent laboratory testing does not necessarily
              relate to better outcomes.
            </comment-element>
          </format-element>
        </format-element>
      </paragraph-element>
      <paragraph-element
        element_type="p"
        id="ddcf8aa7ad4-8775-4841-b981-6d606280052f"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-alignment="justify"
        data-style-id="a"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
      >
        <format-element
          element_type="format"
          id="ddc9f006fd4-4b34-41f7-baf3-b7798e25fea8"
          parent_id="ddcf8aa7ad4-8775-4841-b981-6d606280052f"
          color="rgb(26,26,26)"
          fontsize="11"
          style={{ fontSize: '11pt', color: 'rgb(26, 26, 26)' }}
        >
          <format-element
            element_type="format"
            id="ddc62a126eb-d2f5-49b1-b380-fc6a616b4642"
            parent_id="ddc9f006fd4-4b34-41f7-baf3-b7798e25fea8"
            bold="true"
            fontfamily="Calibri"
            italic="true"
            fontsize="10"
            style={{ fontSize: '10pt', fontFamily: 'Calibri' }}
          >
            Design:
          </format-element>
          <format-element
            element_type="format"
            id="ddca0fc64d0-3dce-49a2-bc49-8ce675589322"
            parent_id="ddc9f006fd4-4b34-41f7-baf3-b7798e25fea8"
            fontfamily="Calibri"
            italic="true"
            fontsize="10"
            style={{ fontSize: '10pt', fontFamily: 'Calibri' }}
          >
            {' '}
            Data preprocessing, feature selection, and classification were performed and an
            artificial intelligence tool, fuzzy modeling, was used to identify lab tests that do not
            contribute an information gain.{' '}
            <comment-element
              element_type="comment"
              element_reference="ddc31de7496-d33e-4e0c-a053-512721a834ad"
              id="ddc805f45ab-aa6a-4841-aa03-d02d00aa6539"
              parent_id="ddca0fc64d0-3dce-49a2-bc49-8ce675589322"
              author="2"
              style={{
                fontFamily: 'inherit',
                fontSize: 'inherit',
                fontStyle: 'inherit',
                fontWeight: 'inherit',
                textDcoration: 'inherit',
                verticalAlign: 'inherit',
                boxShadow:
                  'rgba(217, 33, 36, 0.14) 0px 2px 0px, rgba(217, 33, 36, 0.14) 0px -2px 0px 0px',
                background: 'rgba(217, 33, 36, 0.14)',
              }}
            >
              There were 11 input variables in total. Ten of these were derived from bedside monitor
              trends heart rate, oxygen saturation, respiratory rate, temperature, blood pressure,
              and urine collections, as well as infusion products and transfusions.
            </comment-element>{' '}
            The final input variable was a previous value from one of the eight lab tests being
            predicted: calcium, PTT, hematocrit, fibrinogen, lactate, platelets, INR and hemoglobin.
            The outcome for each test was a binary framework defining whether a test result
            contributed information gain or not.
          </format-element>
        </format-element>
      </paragraph-element>
      <paragraph-element
        element_type="p"
        id="ddcd22532d3-c974-479d-bf16-0d7fdb164041"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-alignment="justify"
        data-style-id="a"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
      >
        <format-element
          element_type="format"
          id="ddcc3101781-c184-4a00-b597-12db7f64afe0"
          parent_id="ddcd22532d3-c974-479d-bf16-0d7fdb164041"
          color="rgb(26,26,26)"
          fontsize="11"
          style={{ fontSize: '11pt', color: ' rgb(26, 26, 26)' }}
        >
          <format-element
            element_type="format"
            id="ddc7ad8bfb1-333c-4d98-b389-ca190df82516"
            parent_id="ddcc3101781-c184-4a00-b597-12db7f64afe0"
            bold="true"
            fontfamily="Calibri"
            italic="true"
            fontsize="10"
            style={{ fontSize: '10pt', fontFamily: ' Calibri' }}
          />
          <format-element
            element_type="format"
            id="ddc7ad8bfb1-333c-4d98-b389-ca190df82516"
            parent_id="ddcc3101781-c184-4a00-b597-12db7f64afe0"
            bold="true"
            fontfamily="Calibri"
            italic="true"
            fontsize="10"
            style={{ fontSize: '10pt', fontFamily: 'Calibri' }}
          >
            Patients:
          </format-element>
          <format-element
            element_type="format"
            id="ddc5f203535-8955-41c4-ab12-ac82b9ca0085"
            parent_id="ddcc3101781-c184-4a00-b597-12db7f64afe0"
            fontfamily="Calibri"
            italic="true"
            fontsize="10"
            style={{ fontSize: '10pt', fontFamily: 'Calibri' }}
          >
            {' '}
            Predictive modeling was applied to a real world ICU database extract comprising 746
            patients with gastrointestinal bleeding.
          </format-element>
          <format-element
            element_type="format"
            id="ddc5f203535-8955-41c4-ab12-ac82b9ca0085"
            parent_id="ddcc3101781-c184-4a00-b597-12db7f64afe0"
            fontfamily="Calibri"
            italic="true"
            fontsize="10"
            style={{ fontSize: '10pt', fontFamily: 'Calibri' }}
          />
        </format-element>
      </paragraph-element>
      <paragraph-element
        element_type="p"
        id="ddc71c2b99c-dbf6-42fd-a558-5c51e3b4ffaf"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-alignment="justify"
        data-style-id="a"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
      >
        <format-element
          element_type="format"
          id="ddc37e3e515-c431-48f1-8887-36d731e367c6"
          parent_id="ddc71c2b99c-dbf6-42fd-a558-5c51e3b4ffaf"
          color="rgb(26,26,26)"
          fontsize="11"
          style={{ fontSize: '11pt', color: ' rgb(26, 26, 26)' }}
        >
          <format-element
            element_type="format"
            id="ddccc048680-bb26-4165-ac8c-accee3defa57"
            parent_id="ddc37e3e515-c431-48f1-8887-36d731e367c6"
            bold="true"
            fontfamily="Calibri"
            italic="true"
            fontsize="10"
            style={{ fontSize: '10pt', fontFamily: 'Calibri' }}
          >
            Main Results:
          </format-element>
          <format-element
            element_type="format"
            id="ddc4b940035-4a07-410c-b3da-f87f19604abf"
            parent_id="ddc37e3e515-c431-48f1-8887-36d731e367c6"
            fontfamily="Calibri"
            italic="true"
            fontsize="10"
            style={{ fontSize: '10pt', fontFamily: 'Calibri' }}
          >
            {' '}
            Classification accuracy of greater than{' '}
            <track-del-element
              element_type="tracked-delete"
              element_reference="ddc33545974-a7e9-4b4d-9fe9-12d3bb8cf872"
              class="deleted"
              author="2"
              id="ddc2067da71-58db-465d-bb20-0b08128cdc15"
              parent_id="ddc4b940035-4a07-410c-b3da-f87f19604abf"
              style={{
                color: 'rgb(217, 33, 36)',
                fontFamily: 'inherit',
                fontSize: 'inherit',
                fontStyle: 'inherit',
                fontWeight: 'inherit',
                textDcoration: 'inherit',
                verticalAlign: 'inherit',
              }}
            >
              80
            </track-del-element>
            <track-ins-element
              element_type="tracked-insert"
              element_reference="ddc33545974-a7e9-4b4d-9fe9-12d3bb8cf872"
              class="inserted"
              author="2"
              id="ddc1cdc341d-721c-4356-b7b9-2f8bde12effc"
              parent_id="ddc4b940035-4a07-410c-b3da-f87f19604abf"
              style={{ color: 'rgb(217, 33, 36)' }}
            >
              85
            </track-ins-element>
            % was achieved for all of the eight lab tests. Sensitivity and specificity were{' '}
            <track-del-element
              element_type="tracked-delete"
              element_reference="ddc036a41ce-d4dc-4b8f-9239-29eff175a3ed"
              class="deleted"
              author="2"
              id="ddc83457866-6555-4777-aece-b00c87780a75"
              parent_id="ddc4b940035-4a07-410c-b3da-f87f19604abf"
              style={{
                color: 'rgb(217, 33, 36)',
                fontFamily: 'inherit',
                fontSize: 'inherit',
                fontStyle: 'inherit',
                fontWeight: 'inherit',
                textDcoration: 'inherit',
                verticalAlign: 'inherit',
              }}
            >
              satisfactory
            </track-del-element>
            <track-ins-element
              element_type="tracked-insert"
              element_reference="ddc036a41ce-d4dc-4b8f-9239-29eff175a3ed"
              class="inserted"
              author="2"
              id="ddcf57a5032-2373-4f82-890a-2c4f28b09402"
              parent_id="ddc4b940035-4a07-410c-b3da-f87f19604abf"
              style={{ color: 'rgb(217, 33, 36)' }}
            >
              above 90%
            </track-ins-element>{' '}
            for all the outcomes.
          </format-element>
        </format-element>
      </paragraph-element>
      <paragraph-element
        element_type="p"
        id="ddc7262a099-b141-4f69-8897-042fe8929e04"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-alignment="justify"
        data-style-id="a"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
      >
        <format-element
          element_type="format"
          id="ddc6e50f847-5580-4daf-ba27-87a773f3d2f7"
          parent_id="ddc7262a099-b141-4f69-8897-042fe8929e04"
          color="rgb(26,26,26)"
          fontsize="11"
          style={{ fontSize: '11pt', color: ' rgb(26, 26, 26)' }}
        >
          <format-element
            element_type="format"
            id="ddc30dd6823-b997-4224-8f54-32681f002e06"
            parent_id="ddc6e50f847-5580-4daf-ba27-87a773f3d2f7"
            bold="true"
            fontfamily="Calibri"
            italic="true"
            fontsize="10"
            style={{ fontSize: '10pt', fontFamily: 'Calibri' }}
          >
            Conclusions:{' '}
          </format-element>
          <format-element
            element_type="format"
            id="ddc74b21bac-87f5-47d3-918e-0bfa29516941"
            parent_id="ddc6e50f847-5580-4daf-ba27-87a773f3d2f7"
            fontfamily="Calibri"
            italic="true"
            fontsize="10"
            style={{ fontSize: '10pt', fontFamily: 'Calibri' }}
          >
            Reducing frequent lab testing an
          </format-element>
        </format-element>
        <format-element
          element_type="format"
          id="ddc3600b292-0a33-43c7-b3e1-f2a3cfc11144"
          parent_id="ddc7262a099-b141-4f69-8897-042fe8929e04"
          color="rgb(26,26,26)"
          fontsize="11"
          style={{ fontSize: '11pt', color: ' rgb(26, 26, 26)' }}
        >
          <format-element
            element_type="format"
            id="ddca60740d2-f4f9-4cd6-b592-f0f452b15afc"
            parent_id="ddc3600b292-0a33-43c7-b3e1-f2a3cfc11144"
            fontfamily="Inter"
            italic="false"
            style={{ fontFamily: 'Inter' }}
          >
            <format-element
              element_type="format"
              id="ddcb7aa6ce1-3ac9-4b88-9272-1ddd1c53907e"
              parent_id="ddca60740d2-f4f9-4cd6-b592-f0f452b15afc"
              fontsize="10"
              style={{ fontSize: '10pt' }}
            >
              <format-element
                element_type="format"
                id="ddcdaba5888-120d-4dab-9106-486e284f8a72"
                parent_id="ddcb7aa6ce1-3ac9-4b88-9272-1ddd1c53907e"
                italic="true"
              >
                <format-element
                  element_type="format"
                  id="ddcb9639a96-051c-47a4-a7d8-0d66681b1685"
                  parent_id="ddcdaba5888-120d-4dab-9106-486e284f8a72"
                  fontfamily="Calibri"
                  style={{ fontFamily: 'Calibri' }}
                >
                  d the potential clinical and financial implications are an important issue in
                  intensive care. In this work we present an artificial intelligence method to
                  predict the benefit of proposed future laboratory tests. Using ICU data from 746
                  patients with gastrointestinal bleeding, and eleven measurements, we demonstrate
                  high accuracy in predicting the likely information to be gained from proposed
                  future lab testing for eight common GI related lab tests. Future work will explore
                  applications of this approach to a range of underlying medical conditions and
                  laboratory tests.
                </format-element>
              </format-element>
            </format-element>
          </format-element>
        </format-element>
      </paragraph-element>
      <paragraph-element
        element_type="p"
        id="ddc372d7b3f-0470-41c2-9699-8b5a878d4e8a"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-style-id="h1"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
      >
        <format-element
          element_type="format"
          id="ddc533216cb-db0a-41f2-91f7-9418736e4611"
          parent_id="ddc372d7b3f-0470-41c2-9699-8b5a878d4e8a"
          fontfamily="Times New Roman"
          fontsize="12"
          style={{ fontSize: '12pt', fontFamily: 'Times New Roman' }}
        />
        <format-element
          element_type="format"
          id="ddc79a44dc0-3df1-48c1-a58b-cd0713ad8181"
          parent_id="ddc372d7b3f-0470-41c2-9699-8b5a878d4e8a"
          fontfamily="Calibri"
          fontsize="14"
          style={{ fontSize: '14pt', fontFamily: 'Calibri' }}
        >
          <format-element
            element_type="format"
            id="ddc1d27e443-45ee-47e1-8636-9c7cf3616742"
            parent_id="ddc79a44dc0-3df1-48c1-a58b-cd0713ad8181"
            fontfamily="Arial"
            bold="true"
            style={{ fontFamily: 'Arial' }}
          >
            Introduction
          </format-element>
        </format-element>
      </paragraph-element>
      <paragraph-element
        element_type="p"
        id="ddcc4ab9fad-d28b-4d4d-85fc-b261eeaedbe4"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-style-id="p"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
      >
        <format-element
          element_type="format"
          id="ddc4ab22d9e-8407-4326-a2d2-d42de9119842"
          parent_id="ddcc4ab9fad-d28b-4d4d-85fc-b261eeaedbe4"
          fontfamily="Calibri"
          style={{ fontFamily: 'Calibri' }}
        >
          Laboratory testing occurs frequently in hospitalized patients
          <track-del-element
            element_type="tracked-delete"
            element_reference="ddccaf4d9c5-a707-42a4-921b-72cc378552f7"
            class="deleted"
            author="2"
            id="ddce514014d-5b37-47bd-b76c-99a6acf9a362"
            parent_id="ddc4ab22d9e-8407-4326-a2d2-d42de9119842"
            style={{
              color: 'rgb(217, 33, 36)',
              fontFamily: 'inherit',
              fontSize: 'inherit',
              fontStyle: 'inherit',
              fontWeight: 'inherit',
              textDcoration: 'inherit',
              verticalAlign: 'inherit',
            }}
          >
            {' '}
            (1)
          </track-del-element>
          . This is especially so for patients in intensive care, where frequent blood draws are
          associated with general phlebotomy complications
          <track-del-element
            element_type="tracked-delete"
            element_reference="ddc44591c4b-1a23-4cbf-8632-dd04607fb71c"
            class="deleted"
            author="2"
            id="ddcc925da25-80e1-442b-96c3-9379f00f5257"
            parent_id="ddc4ab22d9e-8407-4326-a2d2-d42de9119842"
            style={{
              color: 'rgb(217, 33, 36)',
              fontFamily: 'inherit',
              fontSize: 'inherit',
              fontStyle: 'inherit',
              fontWeight: 'inherit',
              textDcoration: 'inherit',
              verticalAlign: 'inherit',
            }}
          >
            {' '}
            (2,3)
          </track-del-element>
          . While part of this testing reflects changes in the intrinsic critical status of ICU
          patients, other tests are run by default, following general guidelines and not driven by
          patient-specific clinical questions
          <track-del-element
            element_type="tracked-delete"
            element_reference="ddc35404bf9-5611-4140-84e2-d2b09ef8033a"
            class="deleted"
            author="2"
            id="ddc2414c6bf-e0ba-481e-bbcd-a402f3d75561"
            parent_id="ddc4ab22d9e-8407-4326-a2d2-d42de9119842"
            style={{
              color: 'rgb(217, 33, 36)',
              fontFamily: 'inherit',
              fontSize: 'inherit',
              fontStyle: 'inherit',
              fontWeight: 'inherit',
              textDcoration: 'inherit',
              verticalAlign: 'inherit',
            }}
          >
            {' '}
            (4,5)
          </track-del-element>
          . One strategy to reduce these complications is to avoid{' '}
          <track-del-element
            element_type="tracked-delete"
            element_reference="ddcc45d0661-44eb-4bb3-bea2-ab5a9714096d"
            class="deleted"
            author="2"
            id="ddc0fb0c505-fc1f-4573-9ea5-8214c99fd97a"
            parent_id="ddc4ab22d9e-8407-4326-a2d2-d42de9119842"
            style={{
              color: 'rgb(217, 33, 36)',
              fontFamily: 'inherit',
              fontSize: 'inherit',
              fontStyle: 'inherit',
              fontWeight: 'inherit',
              textDcoration: 'inherit',
              verticalAlign: 'inherit',
            }}
          >
            testing
          </track-del-element>
          <track-ins-element
            element_type="tracked-insert"
            element_reference="ddcc45d0661-44eb-4bb3-bea2-ab5a9714096d"
            class="inserted"
            author="2"
            id="ddc7df9e48d-a70d-4d22-8278-eb4e1d670615"
            parent_id="ddc4ab22d9e-8407-4326-a2d2-d42de9119842"
            style={{ color: 'rgb(217, 33, 36)' }}
          >
            drawing blodd
          </track-ins-element>{' '}
          when no additional information is expected.
        </format-element>
      </paragraph-element>
      <paragraph-element
        element_type="p"
        id="ddc3cc9275d-48e0-48a4-866b-4ea9ae0313a8"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-style-id="p"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
      >
        <format-element
          element_type="format"
          id="ddc7c788f8a-45c9-4375-8ea1-c751dc560896"
          parent_id="ddc3cc9275d-48e0-48a4-866b-4ea9ae0313a8"
          fontfamily="Calibri"
          style={{ fontFamily: 'Calibri' }}
        >
          Among the reasons for over-testing, one may find that many tests are ordered as part of a
          panel. Many factors contribute to this practice, including lack of awareness of the
          consequences of over-testing, arising from the medical culture promoting ‘more visible’
          care, the medico-legal environment and financial incentives arising from a fee-for-service
          reimbursement scheme
          <track-del-element
            element_type="tracked-delete"
            element_reference="ddc2a747e9e-667b-4d05-9d19-437433ec0bfb"
            class="deleted"
            author="2"
            id="ddcda0d51fc-28ef-4b49-892a-7e1624b413c7"
            parent_id="ddc7c788f8a-45c9-4375-8ea1-c751dc560896"
            style={{
              color: 'rgb(217, 33, 36)',
              fontFamily: 'inherit',
              fontSize: 'inherit',
              fontStyle: 'inherit',
              fontWeight: 'inherit',
              textDcoration: 'inherit',
              verticalAlign: 'inherit',
            }}
          >
            {' '}
            (6)
          </track-del-element>
          . Previous studies have shown that a significant percentage of the tests requested are
          medically unnecessary{' '}
          <track-del-element
            element_type="tracked-delete"
            element_reference="ddc0c225750-bec3-48cd-95ee-49e165069740"
            class="deleted"
            author="2"
            id="ddc13a93902-7bf4-4d47-b947-9e6080004aab"
            parent_id="ddc7c788f8a-45c9-4375-8ea1-c751dc560896"
            style={{
              color: 'rgb(217, 33, 36)',
              fontFamily: 'inherit',
              fontSize: 'inherit',
              fontStyle: 'inherit',
              fontWeight: 'inherit',
              textDcoration: 'inherit',
              verticalAlign: 'inherit',
            }}
          >
            (7)
          </track-del-element>
          .
        </format-element>
      </paragraph-element>
      <paragraph-element
        element_type="p"
        id="ddca9c7b1a5-196a-4fc6-929d-5730309242ac"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-style-id="p"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
      >
        <format-element
          element_type="format"
          id="ddcabcb08de-6f04-49e1-bfa8-fdb3c5343f7e"
          parent_id="ddca9c7b1a5-196a-4fc6-929d-5730309242ac"
          fontfamily="Calibri"
          style={{ fontFamily: 'Calibri' }}
        >
          Studies (8,9) have shown that general ward admissions average 1.1 draws per day per
          patient, extracting 12.4ml of blood per day, resulting in 175ml of blood drawn per
          hospitalization. These numbers are increased for an average ICU admission where there are
          3.4 draws per day per patient, and 762.2ml for the entire admission, and even more for ICU
          patients with an arterial line inserted, where there are 4.0 draws per day per patient,
          and 944ml during the whole admission. Depending on the patient’s condition and the
          underlying reasons for admission, the cumulative amount of blood drawn for laboratory
          testing purposes might warrant transfusion replacement, an expensive and risky practice in
          itself.{' '}
        </format-element>
      </paragraph-element>
      <paragraph-element
        element_type="p"
        id="ddcfbf41352-3cdf-43cd-b35b-e98fa765eb5f"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-style-id="p"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
      >
        <format-element
          element_type="format"
          id="ddca310b695-7ca9-4b86-82aa-a2609abecfa7"
          parent_id="ddcfbf41352-3cdf-43cd-b35b-e98fa765eb5f"
          fontfamily="Calibri"
          style={{ fontFamily: 'Calibri' }}
        >
          A further laboratory risk is false positives associated with over-testing (10,11). The
          probability of false positives (lab results out of the normal range, when in fact the real
          values are normal) is dependent on many things including laboratory equipment, employee
          training and correct phlebotomy technique. The incidence of false positives increases with
          the number of tests run (11). One strategy to reduce false positives is to avoid testing
          when no additional information is expected.
        </format-element>
      </paragraph-element>
      <paragraph-element
        element_type="p"
        id="ddcd23074cb-aeb5-4a86-a144-46c73f86013e"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-style-id="p"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
      >
        <format-element
          element_type="format"
          id="ddc5b47a75c-513a-44de-bcc3-4fabadc95174"
          parent_id="ddcd23074cb-aeb5-4a86-a144-46c73f86013e"
          fontfamily="Calibri"
          style={{ fontFamily: 'Calibri' }}
        >
          The objective of this paper is to predict the relevancy of proposed individual lab tests
          with respect to information gain. In this context we use “relevancy” synonymously with
          “information gain” to broadly mean an increase in knowledge that could change clinical
          decision-making. This outcome framework will be described in greater detail in subsequent
          sections.
        </format-element>
      </paragraph-element>
      <paragraph-element
        element_type="p"
        id="ddcbeb8b785-30ef-4920-bef7-561455b9b263"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-style-id="p"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
      >
        <format-element
          element_type="format"
          id="ddc0b1f4411-62bc-4736-88f4-9d9ebfa38fef"
          parent_id="ddcbeb8b785-30ef-4920-bef7-561455b9b263"
          fontfamily="Calibri"
          style={{ fontFamily: 'Calibri' }}
        >
          We focus our models on gastrointestinal bleeding patients (GI bleeds) and use predictive
          modeling to recognize when GI bleed specific lab tests will result in a gain of
          information. The eight lab tests that we focus on are the following: hematocrit (Hct),
          hemoglobin (Hgb), platelets, calcium, lactate, partial prothrombine time (PTT),
          international normalized ratio (INR) for blood clotting and fibrinogen. Vital trends
          collected from bedside monitors, urine output, and infusion and transfusion volumes are
          used as the input variables for the predictions.
        </format-element>
      </paragraph-element>
      <paragraph-element
        element_type="p"
        id="ddc42d0c95e-43a9-4b40-9d75-9764bc5d7f70"
        parent_id="5ec7e0a1eb3035b4323bfdf8"
        data-style-id="h2"
        section="ddc912232ab-91ea-4948-883a-7356aaa924de"
      >
        <format-element
          element_type="format"
          id="ddc5801b3f5-f56f-4ad1-92bf-6d3f1c4b302a"
          parent_id="ddc42d0c95e-43a9-4b40-9d75-9764bc5d7f70"
          fontfamily="Calibri"
          fontsize="12"
          style={{ fontSize: '12pt', fontFamily: 'Calibri' }}
        >
          <br element_type="breakline" />
        </format-element>
      </paragraph-element>
    </>
  );
};

export default OnboardingTemplate;
