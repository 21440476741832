import { ReactNode } from 'react';
import styles from './SidebarHeader.module.scss';

type SidebarHeaderProps = {
  children: ReactNode;
};

const SidebarHeader = ({ children }: SidebarHeaderProps) => {
  return <div className={styles.header}>{children}</div>;
};

export default SidebarHeader;
