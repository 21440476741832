import { useState, useRef, useEffect, useLayoutEffect, MouseEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, Button, Toggle, Tooltip, Dropdown, usePopper, Link } from 'dodoc-design-system';

import { stringToRichText, containsMention } from 'utils';
import EditorManager from 'Editor/services/EditorManager';
import { useDispatch, useIsNodeClamped, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectCollaborators } from 'App/redux/appSlice';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';

import {
  UsernameLabel,
  FormattedDate,
  FormattedTime,
  RichTextEditor,
  InteractionController,
} from '_common/components';

import EditorAvatar from '../EditorAvatar/EditorAvatar';
import EditableCard from '../EditableCard/EditableCard';
import LikesTooltip from '../LikesTooltip/LikesTooltip';
import PageLayoutTooltip from '../PageLayoutTooltip/PageLayoutTooltip';
import styles from './CommentCard.module.scss';

import type { EditableCardProps } from 'Editor/components/EditableCard/EditableCardContent';
import { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';

const PRIORITY = {
  HIGH: { icon: 'High' },
  MEDIUM: { icon: 'Medium' },
  LOW: {
    icon: 'Low',
  },
} as const;

export type CommentCardProps = {
  user: UserId;
  comment: Editor.Comment;
  mainComment?: MyAny;
  document: Objekt;
  panel?: boolean;
  subcomment?: boolean;
  isTask?: boolean;
  selected?: boolean;
  isReadOnlyMode: boolean;
};

const CommentCardContent = ({
  user,
  panel,
  isTask,
  selected,
  document,
  mainComment,
  isReadOnlyMode,
  subcomment,
  comment,
}: CommentCardProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onboardingStarted = useSelector((state) => state.onboarding.started.editor);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const pulseData = useSelector((state) => state.onboarding.pulseData);
  const collaborators = useSelector((state) =>
    selectCollaborators(state, state.editor.status.documentId),
  );

  const mainEditorRef = useRef<RichTextEditorHandler>(null);
  const replyEditorRef = useRef<RichTextEditorHandler>(null);
  const replyToggleRef = useRef<HTMLButtonElement>(null);

  const [showReplyButtons, setShowReplyButtons] = useState(false);
  const [reply, setReply] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [readMore, setReadMore] = useState(true);
  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const isClamped = useIsNodeClamped({
    ref: mainEditorRef.current?.editorRef,
    clamp: 3,
    dependencies: [comment?.content, mainEditorRef.current],
  });
  const priority = usePopper();
  const moreActions = usePopper();

  useEffect(() => {
    if (!pulseData.commentId && comment?.id) {
      dispatch(setPulseData({ commentId: comment?.id }));
    }
  }, [pulseData]);

  useLayoutEffect(() => {
    if (!isClamped) {
      setReadMore(false);
    }
  }, [isClamped]);

  useEffect(() => {
    if (onboardingStarted) {
      setShowReplyButtons(false);
    }
  }, [onboardingStarted]);

  useEffect(() => {
    setShowReplyButtons(false);
    setReply('');
    setEditMode(false);
    setIsReplyOpen(!subcomment && comment?.comments && comment?.comments.length > 0);
  }, [comment?.id]);

  useEffect(() => {
    if (replyToggleRef?.current) {
      if (!isReplyOpen && !actionsCompleted.editor_comments_mentionInCommentReply) {
        dispatch(
          setPulseData({
            annotationCardReplyRect: {
              top: replyToggleRef.current.offsetTop,
              left: replyToggleRef.current.offsetLeft,
              height: replyToggleRef.current.offsetHeight,
              width: replyToggleRef.current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            annotationCardReplyRect: undefined,
          }),
        );
      }
    }

    return () => {
      dispatch(
        setPulseData({
          annotationCardReplyRect: undefined,
        }),
      );
    };
  }, [isReplyOpen]);

  const selectComment = (commentId: Editor.Comment['id']) => {
    EditorManager.getInstance().focusComment(commentId);
  };

  const handleCancelReply = () => {
    setReply('');
    setShowReplyButtons(false);
  };

  const handleReplyOpen = () => {
    setIsReplyOpen(!isReplyOpen);

    dispatch(completeAction('editor_comments_openReplies'));
  };

  const handleCommentSelected = () => {
    if (!selected && comment.status === 'OPEN') {
      selectComment(comment.id);
    }
  };

  const handleReplyClicked = () => {
    EditorManager.getInstance().replyComment(comment.id, stringToRichText(reply));
    setReply('');
    setShowReplyButtons(false);
    replyEditorRef.current?.clear();

    if (containsMention(stringToRichText(reply))) {
      dispatch(completeAction('editor_comments_mentionInCommentReply'));
    }
  };

  const handleReplyFocused = () => {
    setShowReplyButtons(true);
  };

  const handleReplyChanged = (commentValue: string) => {
    setReply(commentValue);
  };

  const handleSetEditMode = () => {
    setEditMode(true);
  };

  const handleToggleReadMore: MouseEventHandler<HTMLDivElement> = (e) => {
    /**
     * Stop propagation to avoid card selection
     * Card selection will invoke scrollIntoView and app might pause to load new content
     */
    e.stopPropagation();

    setReadMore(!readMore);
  };

  const handleSaveClicked: EditableCardProps['handleSaveClicked'] = (textValue) => {
    if (textValue) {
      if (subcomment) {
        if (isTask) {
          EditorManager.getInstance().editTaskReply(
            mainComment.id,
            comment.id,
            stringToRichText(textValue),
          );
        } else {
          EditorManager.getInstance().editReply(
            mainComment.id,
            comment.id,
            stringToRichText(textValue),
          );
        }
      } else {
        EditorManager.getInstance()
          .editComment(comment.id, stringToRichText(textValue))
          .then(() => {
            notify({
              type: 'success',
              title: 'COMMENT_EDITED',
              message: 'THE_COMMENT_WAS_SUCCESSFULLY_EDITED',
            });
          });
      }
      setEditMode(false);
    }
  };

  const handleCancelClicked: EditableCardProps['handleCancelClicked'] = (isEditing) => {
    if (isEditing) {
      setEditMode(false);
    } else {
      // TODO: review this
      EditorManager.getInstance().removeTemporaryComment();
    }
  };

  const handleChangePriority = (priority: keyof typeof PRIORITY) => {
    if (!subcomment && comment.status === 'OPEN' && comment.priority !== priority) {
      EditorManager.getInstance().changeCommentPriority(comment.id, priority);
    }
  };

  const handleVoteComment = (vote: boolean) => {
    if (subcomment) {
      if (isTask && mainComment.s !== 'd') {
        EditorManager.getInstance().voteTaskReply(mainComment.id, comment.id, vote);
      } else if (mainComment.status && mainComment.status === 'OPEN') {
        EditorManager.getInstance().voteReply(mainComment.id, comment.id, vote);
      }
    } else if (comment.status === 'OPEN') {
      EditorManager.getInstance().voteComment(comment.id, vote);
    }
  };

  const handleDeleteClicked = () => {
    if (subcomment) {
      if (isTask) {
        EditorManager.getInstance().deleteTaskReply(mainComment.id, comment.id);
      } else {
        EditorManager.getInstance().deleteReply(mainComment.id, comment.id);
      }
    } else {
      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            title: 'DELETE_COMMENT',
            message: 'DELETING_THIS_COMMENT_WILL_PERMANENTLY_REMOVE_IT_CONFIRM',
            cancelButtonTextId: 'global.cancel',
            confirmButtonType: 'danger',
            confirmButtonTextId: 'global.delete',
            headerType: 'error',
            actionCode: 'deleteComment',
            actionValue: {
              commentId: comment.id,
            },
            cancelButtonShow: true,
          },
        }),
      );
    }
  };

  const handleResolveClicked = () => {
    EditorManager.getInstance()
      .resolveComment(comment.id)
      .then(() => {
        notify({
          type: 'success',
          title: 'notifications.commentResolve.messageSuccess',
          message: 'THE_COMMENT_WAS_SUCCESSFULLY_RESOLVED',
        });
      })
      .catch((error) => {
        notify({
          type: 'error',
          title: 'global.comment',
          message: 'notifications.commentResolve.messageError',
        });
        throw error;
      });
  };

  const renderHeader = () => {
    const isAuthor = comment.author === user;
    const isOwner =
      document.user_permissions.includes('admin') || document.user_permissions.includes('owner');

    // Change a comment's priority:  Owner, Comment Creator or Document Owner permission
    const canChangePriority = isOwner || isAuthor;
    // Edit a comment: Comment Creator
    const canEdit = isAuthor;
    const canComment = document.user_permissions.includes('comment');
    // Delete a comment: Comment Creator
    const canDelete = isOwner || (isAuthor && canComment);
    return (
      <div style={{ display: 'flex' }}>
        <EditorAvatar
          margin="0 1rem 0 0"
          userId={comment.author || 'IMPORTED_USER'}
          name={comment?.user}
        />
        <div className={styles.labels}>
          <div className={styles.author}>
            <div className={styles.authorName}>
              <UsernameLabel userId={comment?.author} name={comment?.user} />
            </div>
            {!subcomment && (
              <>
                <PageLayoutTooltip
                  type="comment"
                  content={intl.formatMessage({
                    id:
                      isReadOnlyMode || !canChangePriority
                        ? 'CHANGE_PRIORITY_CARD'
                        : 'global.priority',
                  })}
                  testId={`comment-card-${comment.id}-priority-tooltip`}
                >
                  <span>
                    <Toggle
                      variant="link"
                      disabled={isReadOnlyMode || !canChangePriority}
                      style={{ marginLeft: '1rem', padding: 0 }}
                      size="small"
                      isToggled={priority.isOpen}
                      testId={`comment-card-${comment.id}-priority-toggle`}
                      {...PRIORITY[comment.priority]}
                      {...priority.referenceProps}
                    />
                  </span>
                </PageLayoutTooltip>
                <Dropdown
                  {...priority.popperProps}
                  testId={`comment-card-${comment.id}-priority-dropdown`}
                >
                  <Dropdown.Item
                    onClick={() => handleChangePriority('HIGH')}
                    disabled={!canChangePriority}
                    prefixIcon={PRIORITY.HIGH.icon}
                    testId={`comment-card-${comment.id}-priority-high-dropdown-item`}
                  >
                    <FormattedMessage id="editor.sidebar.review.filter.priority.high" />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleChangePriority('MEDIUM')}
                    disabled={!canChangePriority}
                    prefixIcon={PRIORITY.MEDIUM.icon}
                    testId={`comment-card-${comment.id}-priority-medium-dropdown-item`}
                  >
                    <FormattedMessage id="editor.sidebar.review.filter.priority.medium" />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleChangePriority('LOW')}
                    disabled={!canChangePriority}
                    prefixIcon={PRIORITY.LOW.icon}
                    testId={`comment-card-${comment.id}-priority-low-dropdown-item`}
                  >
                    <FormattedMessage id="editor.sidebar.review.filter.priority.low" />
                  </Dropdown.Item>
                </Dropdown>
              </>
            )}
          </div>
          <div className={styles.time}>
            <FormattedDate date={comment.time} type="short" />
            &nbsp;
            <FormattedTime time={comment.time} type="meridiem" />
          </div>
        </div>

        {/* Link Button with 3 vertical dots */}
        <div style={{ marginTop: '0.5rem' }}>
          <>
            <PageLayoutTooltip
              type="comment"
              content={intl.formatMessage({ id: 'global.moreActions' })}
              testId={`comment-card-${comment.id}-more-options-tooltip`}
            >
              <span>
                <Button
                  size="medium"
                  variant="link"
                  disabled={isReadOnlyMode}
                  style={{ marginLeft: '1rem', padding: 0 }}
                  icon="Options"
                  testId={`comment-card-${comment.id}-more-options-button`}
                  {...moreActions.referenceProps}
                />
              </span>
            </PageLayoutTooltip>

            <Dropdown
              {...moreActions.popperProps}
              testId={`comment-card-${comment.id}-more-options-dropdown`}
            >
              <Tooltip
                content={
                  comment.application
                    ? intl.formatMessage({ id: 'CANNOT_DELETE_COMMENT_IMPORTED_FROM_WORD' })
                    : intl.formatMessage({ id: 'CANNOT_DELETE_COMMENT_CREATED_BY_OTHERS' })
                }
                placement="bottom"
                disabled={canDelete}
                testId={`comment-card-${comment.id}-more-options-delete-dropdown-item-tooltip`}
              >
                <span>
                  <Dropdown.Item
                    onClick={handleDeleteClicked}
                    disabled={!canDelete}
                    testId={`comment-card-${comment.id}-more-options-delete-dropdown-item`}
                  >
                    <FormattedMessage id="global.delete" />
                  </Dropdown.Item>
                </span>
              </Tooltip>
              <Tooltip
                content={
                  comment.application
                    ? intl.formatMessage({ id: 'CANNOT_EDIT_COMMENT_IMPORTED_FROM_WORD' })
                    : intl.formatMessage({ id: 'CANNOT_EDIT_COMMENT_CREATED_BY_OTHERS' })
                }
                placement="bottom"
                disabled={canEdit}
                testId={`comment-card-${comment.id}-more-options-edit-dropdown-item-tooltip`}
              >
                <span>
                  <Dropdown.Item
                    onClick={handleSetEditMode}
                    disabled={!canEdit}
                    testId={`comment-card-${comment.id}-more-options-edit-dropdown-item`}
                  >
                    <FormattedMessage id="global.edit" />
                  </Dropdown.Item>
                </span>
              </Tooltip>
            </Dropdown>
          </>
        </div>
      </div>
    );
  };

  const renderSubComments = () => {
    if (!subcomment) {
      const { comments } = comment;

      if (comments.length === 0) {
        return null;
      }
      return comments.map((subcomment) => (
        <CommentCardContent
          subcomment
          panel={panel}
          user={user}
          key={subcomment.id}
          document={document}
          selected={selected}
          comment={subcomment}
          mainComment={comment}
          isReadOnlyMode={isReadOnlyMode}
        />
      ));
    }
  };

  const renderReply = () => {
    const hasCommentPermission =
      document.user_permissions.includes('admin') ||
      document.user_permissions.includes('owner') ||
      document.user_permissions.includes('comment');

    if (comment.status !== 'OPEN') {
      return null;
    }

    if (hasCommentPermission) {
      return (
        <div className={styles.replyContainer}>
          <div className={styles.reply}>
            <EditorAvatar margin="0 1rem 0 0" userId={user} />
            <div style={{ flex: 1 }}>
              <InteractionController
                environment="editor"
                rules={[
                  {
                    interaction: 'editor_sidepanel_mentionComment',
                    actions: ['editor_comments_mentionInCommentReply'],
                  },
                ]}
              >
                <RichTextEditor
                  ref={replyEditorRef}
                  mentionableUsers={collaborators}
                  placeholder={intl.formatMessage({ id: 'REPLY_PLACEHOLDER' })}
                  onFocus={handleReplyFocused}
                  onChange={handleReplyChanged}
                  skipFocus
                  testId={`comment-card-${comment.id}-reply-input`}
                />
              </InteractionController>
            </div>
          </div>
          {showReplyButtons && (
            <div className={styles.replyButtons}>
              <Button
                variant="link"
                size="small"
                onClick={handleCancelReply}
                testId={`comment-card-${comment.id}-reply-cancel-button`}
              >
                <FormattedMessage id="global.cancel" />
              </Button>
              <InteractionController
                environment="editor"
                rules={[
                  {
                    interaction: 'editor_sidepanel_mentionComment',
                    actions: ['editor_comments_mentionInCommentReply'],
                  },
                ]}
              >
                <Button
                  variant="primary"
                  size="small"
                  onClick={handleReplyClicked}
                  disabled={reply === ''}
                  testId={`comment-card-${comment.id}-reply-create-button`}
                >
                  <FormattedMessage id="global.create" />
                </Button>
              </InteractionController>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  const renderBody = () => {
    const hasNotImportedContent = comment && comment.content && comment.content.includes('( ! )');
    return (
      <>
        {comment.application === 'docx' && (
          <div className={styles.imported}>
            <FormattedMessage id="IMPORTED_FROM_WORD_DOCUMENT" />
          </div>
        )}

        <RichTextEditor
          readOnly
          ref={mainEditorRef}
          initialValue={comment.content}
          expanded={readMore}
          testId={`comment-card-${comment.id}-rich-text-editor`}
        />

        {isClamped && (
          <div className={styles.readMore} onClick={handleToggleReadMore}>
            <FormattedMessage
              id={readMore ? 'editor.comments.readLess' : 'editor.comments.readMore'}
            />
          </div>
        )}

        {hasNotImportedContent && (
          <div className={styles.notImportedContent}>
            <FormattedMessage id="CONTENT_NOT_IMPORTED" />
          </div>
        )}
      </>
    );
  };

  const renderFooter = () => {
    const voted = comment?.votes?.some((vote) => vote.user === user);

    const isAuthor = comment.author === user;
    const isOwner =
      document.user_permissions.includes('owner') || document.user_permissions.includes('admin');

    // Resolve a comment: Comment Creator or user with Owner permission.
    const canResolve = isAuthor || isOwner;

    return (
      <>
        <div className={styles.leftPanel}>
          <span className={styles.likes}>
            <PageLayoutTooltip type="comment" testId={`comment-card-${comment.id}-like-tooltip`}>
              <Toggle
                size="medium"
                variant="link"
                icon="Like"
                isToggled={voted}
                onClick={() => handleVoteComment(true)}
                disabled={isReadOnlyMode}
                testId={`comment-card-${comment.id}-like-toggle`}
              />
            </PageLayoutTooltip>
            {comment.votes && comment.votes.length > 0 && (
              <LikesTooltip
                votes={comment.votes}
                testId={`comment-card-${comment.id}-likes-tooltip`}
              >
                <span className={styles.counter}>
                  <Link
                    onClick={() =>
                      dispatch(
                        openAndUpdateModal({
                          modal: 'LikesModal',
                          data: { votes: comment.votes, type: 'comment' },
                        }),
                      )
                    }
                    variant="neutral"
                    testId={`comment-card-${comment.id}-likes-link`}
                  >
                    {comment?.votes?.length}
                  </Link>
                </span>
              </LikesTooltip>
            )}
          </span>

          {!subcomment && (
            <PageLayoutTooltip
              type="comment"
              disabled={comment?.comments?.length > 0 || undefined}
              testId={`comment-card-${comment.id}-reply-tooltip`}
            >
              <InteractionController
                environment="editor"
                rules={[
                  {
                    interaction: 'editor_sidepanel_mentionComment',
                    actions: ['editor_comments_openReplies'],
                  },
                ]}
              >
                <Toggle
                  size="medium"
                  variant="link"
                  margin="0 0.25rem 0 1.5rem"
                  icon="Replies"
                  isToggled={isReplyOpen}
                  onClick={handleReplyOpen}
                  disabled={isReadOnlyMode && comment?.comments?.length < 1}
                  testId={`comment-card-${comment.id}-reply-toggle`}
                  ref={replyToggleRef}
                />
              </InteractionController>
            </PageLayoutTooltip>
          )}
          {!subcomment && comment.comments && comment.comments.length > 0 && (
            <div className={styles.numberOfReplies}>{comment.comments.length}</div>
          )}
        </div>
        {!subcomment && (
          <PageLayoutTooltip type="comment" testId={`comment-card-${comment.id}-resolve-tooltip`}>
            <Button
              variant="link"
              disabled={!canResolve || isReadOnlyMode}
              size="small"
              onClick={handleResolveClicked}
              testId={`comment-card-${comment.id}-resolve-button`}
            >
              <FormattedMessage id="RESOLVE" />
            </Button>
          </PageLayoutTooltip>
        )}
      </>
    );
  };

  if (editMode) {
    return (
      <EditableCard
        user={user}
        editMode
        subcomment={subcomment}
        content={comment.content}
        handleSaveClicked={handleSaveClicked}
        handleCancelClicked={handleCancelClicked}
        placeholder={intl.formatMessage({ id: 'INSERT_YOUR_COMMENT_HERE' })}
      />
    );
  }
  if (!comment?.id) {
    return null;
  }

  return !subcomment ? (
    <div style={{ position: 'relative' }}>
      <InteractionController environment="editor">
        <Card
          id={`Comment#${comment.id}`}
          testId={`Comment#${comment.id}`}
          sidebar={panel}
          selected={selected}
          onClick={panel ? handleCommentSelected : () => {}}
          width={panel ? '100%' : '43rem'}
        >
          <Card.Header size="medium">{renderHeader()}</Card.Header>
          <Card.Body>{renderBody()}</Card.Body>
          <Card.Footer size="large">{renderFooter()}</Card.Footer>
          {isReplyOpen && !subcomment && renderSubComments()}
          {isReplyOpen && !subcomment && !isReadOnlyMode && renderReply()}
        </Card>
      </InteractionController>
    </div>
  ) : (
    <div className={styles.subComment}>
      <Card.Header size="medium" background="grey">
        {renderHeader()}
      </Card.Header>
      <Card.Body>{renderBody()}</Card.Body>
      <Card.Footer size="large">{renderFooter()}</Card.Footer>
    </div>
  );
};

export default CommentCardContent;
