import { uniq } from 'lodash';
import EditorAvatar from '../EditorAvatar/EditorAvatar';
import styles from './AvatarList.module.scss';

type AvatarListProps = {
  users: UserId[];
};

const AvatarList = ({ users }: AvatarListProps) => {
  return (
    <div className={styles.avatars}>
      {uniq(users).map((userId) => (
        <EditorAvatar showTooltip key={userId} margin="0 0 0 0.5rem" userId={userId} />
      ))}
    </div>
  );
};

export default AvatarList;
