import uuid from 'uuid/v4';

import { ELEMENTS } from 'Editor/services/consts';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';

export default class ActionContext {
  static CHANGE_TYPE = {
    REMOVED: 'REMOVED',
    ADDED: 'ADDED',
    UPDATED: 'UPDATED',
  };

  static COLLAPSE = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
  };

  static ACTION = {
    BACKSPACE: 'BACKSPACE',
    TAB: 'TAB',
    DELETE: 'DELETE',
    DEFAULT: 'DEFAULT',
    ENTER: 'ENTER',
    INSERT_BLOCK: 'INSERT_BLOCK',
    INSERT_INLINE: 'INSERT_INLINE',
    PASTE: 'PASTE',
    CUT: 'CUT',
  };

  static TYPE = {
    DELETE: 'DELETE',
    INSERT: 'INSERT',
    REPLACE: 'REPLACE',
  };

  static NODE_CONTEXT = {
    TABLE: 'TABLE',
    DEFAULT: 'DEFAULT',
  };

  static TEXT = {
    [ELEMENTS.TableElement.ELEMENT_TYPE]: 'Table',
    [ELEMENTS.FigureElement.ELEMENT_TYPE]: 'Figure',
  };

  static SELECTION = {
    CARET: 'caret',
    RANGE: 'range',
  };

  static INLINE_INSERT_ACTION = [
    ActionContext.ACTION.TAB,
    ActionContext.ACTION.DEFAULT,
    ActionContext.ACTION.INSERT_INLINE,
    ActionContext.ACTION.PASTE,
  ];

  static getTextFromSuggestion(suggestionElenment) {
    const suggestionContent = {
      type: null,
      value: '',
    };

    if (suggestionElenment) {
      const blockNodes = [ELEMENTS.FigureElement.TAG, ELEMENTS.TableElement.TAG];
      const inlineNodes = [
        ELEMENTS.CitationsGroupElement.TAG,
        ELEMENTS.NoteElement.TAG,
        ELEMENTS.EquationElement.TAG,
        ELEMENTS.ImageElement.TAG,
      ];

      const elementsToCheck = [...blockNodes, ...inlineNodes];

      const nodeIterator = document.createNodeIterator(suggestionElenment, NodeFilter.SHOW_ALL, {
        acceptNode: (node) => {
          const closestTracked = DOMUtils.closest(node, [
            ELEMENTS.TrackInsertElement.TAG,
            ELEMENTS.TrackDeleteElement.TAG,
          ]);

          if (
            ((node.nodeType === Node.TEXT_NODE &&
              !DOMUtils.closest(node, DOMUtils.INLINE_NON_EDITABLE_ELEMENTS)) ||
              elementsToCheck.includes(node.tagName)) &&
            closestTracked &&
            (closestTracked === suggestionElenment ||
              closestTracked.getAttribute('element_reference') ===
                suggestionElenment.getAttribute('element_reference'))
          ) {
            return NodeFilter.FILTER_ACCEPT;
          }

          return NodeFilter.FILTER_REJECT;
        },
      });

      let node;
      while ((node = nodeIterator.nextNode())) {
        if (node.nodeType === Node.TEXT_NODE) {
          suggestionContent.type = 'text';
          suggestionContent.value = suggestionContent.value.concat(node.nodeValue);
        } else if (blockNodes.includes(node.tagName)) {
          suggestionContent.type = node.getAttribute('element_type');
          suggestionContent.value = '';
          break;
        } else if (node.tagName === ELEMENTS.ImageElement.TAG) {
          suggestionContent.type = 'figure';
          suggestionContent.value = '';
          break;
        } else if (suggestionContent.value.length === 0 && inlineNodes.includes(node.tagName)) {
          suggestionContent.type = node.getAttribute('element_type');
          suggestionContent.value = '';
        }
      }
    }

    return suggestionContent;
  }

  static handleSuggestedContent(actionNodes) {
    if (actionNodes.length > 0) {
      const suggestionData = {
        type: null,
        value: '',
      };

      for (let i = 0; i < actionNodes.length; i++) {
        const node = actionNodes[i];

        if (node.isParagraphMarker()) {
          suggestionData.type = 'text';
          suggestionData.value = suggestionData.value.concat('\n');
        } else {
          const newData = ActionContext.getTextFromSuggestion(node);

          if (suggestionData.type == null || newData.type === 'text') {
            suggestionData.type = newData.type;
          }

          if (newData.type === 'text') {
            suggestionData.value = suggestionData.value.concat(newData.value);
          }
        }
      }

      if (suggestionData.type !== 'text') {
        suggestionData.value = null;
      }

      return suggestionData;
    }
    return undefined;
  }

  /**
   *
   * @param {*} ref
   * @returns {Object} returns an object: { type: 'type', value: 'text'}
   */
  static suggestedInsertedContent(ref) {
    const actionNodes = Array.prototype.slice.call(
      DOMUtils.getPageNode().querySelectorAll(`track-ins-element[element_reference="${ref}"]`),
    );
    return ActionContext.handleSuggestedContent(actionNodes);
  }

  /**
   *
   * @param {*} ref
   * @returns {Object} returns an object: { type: 'type', value: 'text'}
   */
  static suggestedDeletedContent(ref) {
    const actionNodes = Array.prototype.slice.call(
      DOMUtils.getPageNode().querySelectorAll(`track-del-element[element_reference="${ref}"]`),
    );
    return ActionContext.handleSuggestedContent(actionNodes);
  }

  static suggestedContentLocations() {
    return [];
  }

  /**
   * actionContext constructor
   * @param {string=} action action type
   * @param {Object=} options { confirmDeleteCaption, caretPosition }
   */
  constructor(action, options = {}) {
    this._action = action;

    this._selectionType = ActionContext.SELECTION.CARET;

    this._nodeContext = ActionContext.NODE_CONTEXT.DEFAULT;

    this._selectionBackwards = false;

    this._refreshReferences = [];
    this._removedParagraphs = [];

    this._changes = {
      [ActionContext.CHANGE_TYPE.REMOVED]: [],
      [ActionContext.CHANGE_TYPE.ADDED]: [],
      [ActionContext.CHANGE_TYPE.UPDATED]: [],
    };

    this._jsonChanges = false;

    // options
    this._actionOptions = {};
    this.setOptions(options);
  }

  setOptions({ confirmDeleteCaption, caretPosition }) {
    if (confirmDeleteCaption) {
      this._actionOptions.deleteCaption = true;
    }

    if (caretPosition != null) {
      this._actionOptions.caretPosition = caretPosition;
    }
  }

  get confirmDeleteCaption() {
    return this._actionOptions.deleteCaption;
  }

  get caretPosition() {
    return this._actionOptions.caretPosition;
  }

  get isReplacing() {
    return (
      ActionContext.INLINE_INSERT_ACTION.includes(this._action) &&
      this._selectionType === ActionContext.SELECTION.RANGE
    );
  }

  get changes() {
    return this._changes;
  }

  flushChanges() {
    this._refreshReferences = [];
    this._removedParagraphs = [];
    this._changes = {
      [ActionContext.CHANGE_TYPE.REMOVED]: [],
      [ActionContext.CHANGE_TYPE.ADDED]: [],
      [ActionContext.CHANGE_TYPE.UPDATED]: [],
    };
    this._jsonChanges = false;
  }

  get nodeContext() {
    return this._nodeContext;
  }

  get action() {
    return this._action;
  }

  get id() {
    if (!this._id) {
      this._id = `ddc${uuid()}`;
    }
    return this._id;
  }

  get collapseDirection() {
    if (this._action === ActionContext.ACTION.BACKSPACE) {
      return ActionContext.COLLAPSE.LEFT;
    }
    if (this._action === ActionContext.ACTION.DELETE) {
      return ActionContext.COLLAPSE.RIGHT;
    }
    if (this._selectionBackwards) {
      return ActionContext.COLLAPSE.LEFT;
    }
    return ActionContext.COLLAPSE.RIGHT;
  }

  get needsCreation() {
    return DOMUtils.getPageNode().querySelectorAll(`*[element_reference="${this.id}"]`).length > 0;
  }

  get deletedContent() {
    return ActionContext.suggestedDeletedContent(this.id);
  }

  get selectionType() {
    return this._selectionType;
  }

  get insertedContent() {
    return ActionContext.suggestedInsertedContent(this.id);
  }

  get locations() {
    return ActionContext.suggestedContentLocations(this.id);
  }

  get refreshReferences() {
    return this._refreshReferences;
  }

  get removedParagraphs() {
    return this._removedParagraphs;
  }

  get type() {
    if (
      this._action === ActionContext.ACTION.BACKSPACE ||
      this._action === ActionContext.ACTION.DELETE ||
      this._action === ActionContext.ACTION.CUT
    ) {
      return ActionContext.TYPE.DELETE;
    } else {
      if (ActionContext.suggestedDeletedContent(this.id)) {
        return ActionContext.TYPE.REPLACE;
      }
      return ActionContext.TYPE.INSERT;
    }
  }

  set collapseDirection(value) {
    this._collapseDirection = value;
  }

  set selectionType(value) {
    this._selectionType = value;
  }

  set selectionBackwards(value) {
    this._selectionBackwards = value;
  }

  set nodeContext(value) {
    this._nodeContext = value;
  }

  get jsonChanges() {
    return this._jsonChanges;
  }

  set jsonChanges(value) {
    if (value === true) {
      this._jsonChanges = true;
    } else {
      this._jsonChanges = false;
    }
  }

  setId(id) {
    this._id = id;
  }

  addReferenceToRefresh(reference) {
    if (!this._refreshReferences.includes(reference) && this.id !== reference) {
      this._refreshReferences.push(reference);
    }
  }

  getAllSuggestionsRefs() {
    return [...this._refreshReferences, this._id];
  }

  /**
   *  add change to context
   * @param {ActionContext.CHANGE_TYPE} changeType
   * @param {String} level0Id
   */
  addChange(changeType, level0Id, childId) {
    let index = -1;
    switch (changeType) {
      case ActionContext.CHANGE_TYPE.ADDED: {
        if ((index = this._changes[ActionContext.CHANGE_TYPE.REMOVED].indexOf(level0Id)) >= 0) {
          this._changes[ActionContext.CHANGE_TYPE.REMOVED].splice(index, 1);
        }
        if ((index = this._changes[ActionContext.CHANGE_TYPE.UPDATED].indexOf(level0Id)) >= 0) {
          this._changes[ActionContext.CHANGE_TYPE.UPDATED].splice(index, 1);
        }
        break;
      }
      case ActionContext.CHANGE_TYPE.REMOVED: {
        if ((index = this._changes[ActionContext.CHANGE_TYPE.ADDED].indexOf(level0Id)) >= 0) {
          this._changes[ActionContext.CHANGE_TYPE.ADDED].splice(index, 1);
        }
        if ((index = this._changes[ActionContext.CHANGE_TYPE.UPDATED].indexOf(level0Id)) >= 0) {
          this._changes[ActionContext.CHANGE_TYPE.UPDATED].splice(index, 1);
        }
        break;
      }
      case ActionContext.CHANGE_TYPE.UPDATED: {
        if (
          this._changes[ActionContext.CHANGE_TYPE.ADDED].indexOf(level0Id) >= 0 ||
          this._changes[ActionContext.CHANGE_TYPE.REMOVED].indexOf(level0Id) >= 0
        ) {
          return;
        }
        break;
      }
      default:
        break;
    }

    if (ActionContext.CHANGE_TYPE[changeType]) {
      if (!Array.isArray(this._changes[changeType])) {
        this._changes[changeType] = [];
      }
      if (!this._changes[changeType].includes(level0Id)) {
        if (childId) {
          const changeId = `${level0Id}:${childId}`;
          if (!this._changes[changeType].includes(changeId)) {
            this._changes[changeType].push(changeId);
          }
        } else {
          this._changes[changeType].push(level0Id);
        }
      }
    } else {
      throw new Error('Invalid change type!');
    }
  }

  /**
   * add removed or updated node change
   * @param {Node} node
   */
  addChangeRemovedNode(node) {
    if (node.getAttribute('element_type') === ELEMENTS.ParagraphElement.ELEMENT_TYPE) {
      this._removedParagraphs.push(node.id);
    }
    if (node && node.parentNode) {
      if (node.parentNode === DOMUtils.getPageNode()) {
        this.addChange(ActionContext.CHANGE_TYPE.REMOVED, node.id);
      } else {
        const level0Node = DOMUtils.findNodeLevel0(DOMUtils.getPageNode(), node);
        if (level0Node) {
          let childId;
          const closesContainer = DOMUtils.closestMultiBlockContainerElement(node);
          if (closesContainer) {
            childId = closesContainer.id;
          } else {
            childId = node.parentNode.id;
          }

          this.addChange(ActionContext.CHANGE_TYPE.UPDATED, level0Node.id, childId);
        }
      }
    } else {
      this.addChange(ActionContext.CHANGE_TYPE.REMOVED, node.id);
    }
  }

  /**
   * add added or updated node change
   * @param {Node} node
   */
  addChangeAddedNode(node) {
    if (node && node.parentNode) {
      if (node.parentNode === DOMUtils.getPageNode()) {
        this.addChange(ActionContext.CHANGE_TYPE.ADDED, node.id);
      } else {
        const level0Node = DOMUtils.findNodeLevel0(DOMUtils.getPageNode(), node);
        if (level0Node) {
          let childId;
          const closesContainer = DOMUtils.closestMultiBlockContainerElement(node);
          if (closesContainer) {
            childId = closesContainer.id;
          } else {
            childId = node.parentNode.id;
          }

          this.addChange(ActionContext.CHANGE_TYPE.UPDATED, level0Node.id, childId);
        }
      }
    }
  }

  /**
   * add updated node change
   * @param {Node} node
   */
  addChangeUpdatedNode(node) {
    if (node && node.parentNode) {
      if (node.parentNode === DOMUtils.getPageNode()) {
        this.addChange(ActionContext.CHANGE_TYPE.UPDATED, node.id);
      } else {
        const level0Node = DOMUtils.findNodeLevel0(DOMUtils.getPageNode(), node);
        if (level0Node) {
          let childId;
          const closesContainer = DOMUtils.closestMultiBlockContainerElement(node);
          if (closesContainer) {
            childId = closesContainer.id;
            // if (
            //   closesContainer.tagName !== ELEMENTS.TableCellElement.TAG ||
            //   closesContainer === node
            // ) {
            //   childId = closesContainer.id;
            // } else {
            //   const child = DOMUtils.findNodeLevel0(closesContainer, node);
            //   if (child) {
            //     childId = child.id;
            //   } else {
            //     childId = node.parentNode.id;
            //   }
            // }
          }
          // else {
          //   childId = node.parentNode.id;
          // }

          this.addChange(ActionContext.CHANGE_TYPE.UPDATED, level0Node.id, childId);
        }
      }
    }
  }
}
