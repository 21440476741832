import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyCardListState, EmptyState } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import {
  selectPDFAnnotations,
  selectFilteredPDFAnnotations,
  setClearAllFilters,
  selectTotalAnnotationsFilters,
} from 'PDF/redux/PDFAnnotationsSlice';
import { useGetCurrentUserQuery } from '_common/services/api/authority';
import Header from '../Header/Header';
import AnnotationCard from './AnnotationCard/AnnotationCard';
import Filters from './Filters/Filters';
import Search from './Search/Search';

import panelStyles from '../RightSidePanel.module.scss';
import styles from './AnnotationsPanel.module.scss';
import usePDFData from 'PDF/hooks/usePDFData';

const Annotations = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const object = usePDFData();
  const annotationList = useSelector(selectPDFAnnotations);
  const annotationsFilteredList = useSelector(selectFilteredPDFAnnotations);
  const searchBy = useSelector((state) => state.pdf.annotations.searchBy);
  const totalAnnotationsFilters = useSelector(selectTotalAnnotationsFilters);
  const { data: userProfile } = useGetCurrentUserQuery();

  if (!object || !userProfile) {
    return null;
  }

  const renderEmptyState = () => {
    if (searchBy) {
      return (
        <div className={styles.emptyStateSearchBy} data-testid="sidebar-comments-empty-search">
          <FormattedMessage id="NO_MATCHES_FOUND" />
        </div>
      );
    }

    return (
      <div className={styles.emptyStateFiltersApplied} data-testid="sidebar-comments-empty-filters">
        <EmptyState
          size="medium"
          footer={<FormattedMessage id="CLEAR_FILTERS" />}
          onClick={() => dispatch(setClearAllFilters())}
          title={intl.formatMessage({ id: 'NO_RESULTS_FOUND' })}
          testId="sidebar-comments-empty-filters"
        >
          <FormattedMessage id="NO_RESULTS_FOUND_FILTER_COMMENTS" />
        </EmptyState>
      </div>
    );
  };

  return (
    <>
      <Header>
        <FormattedMessage id="COMMENTS" />
        <Filters />
      </Header>
      <div className={panelStyles.content}>
        {annotationList.length === 0 && totalAnnotationsFilters === 0 ? (
          <div className={styles.emptyState} data-testid="no-comments-yet">
            <EmptyCardListState size="medium" testId="no-comments-empty-card-list-state">
              <FormattedMessage id="NO_COMMENTS_YET" />
            </EmptyCardListState>
          </div>
        ) : (
          <>
            <Search />
            <div className={styles.annotationsList} data-testid="sidebar-comments-list">
              {annotationsFilteredList.list.length > 0
                ? annotationsFilteredList.list.map((annotation) => {
                    const filteredAnnotation = annotationsFilteredList.annotations[annotation];
                    return (
                      <AnnotationCard
                        key={`annotationpanel-annotation-${filteredAnnotation.id}`}
                        annotation={filteredAnnotation}
                        sidebar
                        testId={`sidebar-comment-${filteredAnnotation.id}`}
                      />
                    );
                  })
                : renderEmptyState()}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Annotations;
