import { InteractionController, IntlErrorBoundary } from '_common/components';

import TasksCardContent, { TasksCardProps } from './TasksCardContent';

const TasksCard = (props: TasksCardProps) => {
  return (
    <IntlErrorBoundary
      fallbackType="card"
      mockProps={{ sidebar: !!props.panel, width: props.panel ? '100%' : '43rem' }}
    >
      <InteractionController environment="editor">
        <TasksCardContent {...props} />
      </InteractionController>
    </IntlErrorBoundary>
  );
};

export default TasksCard;
