import { ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';

import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import { navigateToMyFiles } from 'router/history';

import {
  closeAndResetModal,
  closeModal,
  openAndUpdateModal,
  openModal,
  updateModal,
} from '_common/modals/ModalsSlice';
import { clearSelection } from '_common/components/Table/TableSlice';
import { useRemoveRolesMutation, useRemovePermissionMutation } from 'App/redux/objectApi';
import { useApplyTemplateMutation } from '_common/services/api/realtime';
import {
  checkOutFile,
  deleteObject,
  downloadOriginalFile,
  removeGroupPermission,
  removeUserFromGroup,
  setAppLoading,
} from 'App/redux/appSlice';
import { deleteGroup } from 'Groups/redux/GroupsPageSlice';
import { exportToNetDocuments, exportToBox } from 'Editor/redux/CurrentIntegrationSlice';
import { deleteSpace } from 'Storage/pages/SpacesListPage/redux/spacesListPageSlice';
import { useDeletePublicLinkMutation } from 'Settings/pages/ObjectSettingsPage/PublicLinkView/PublicLinkSettingsApi';
import {
  removeUser,
  setUserActiveState,
} from 'Settings/pages/TenantSettingsPage/Users/redux/userManagementSlice';
import {
  setWordHasChanged,
  toggleChangedAllTrigger,
} from 'Editor/pages/EditorPage/SidebarComponent/SpellCheckTab/SpellcheckSlice';
import { setTaskDeleted } from 'Editor/redux/TasksSlice';
import { exportCitations } from 'Editor/redux/CitationsSlice';
import { useDeleteRoleMutation } from 'Settings/pages/TenantSettingsPage/Roles/RolesApi';
import { useDeleteAffiliationFieldMutation } from 'Settings/pages/TenantSettingsPage/Affiliations/AffiliationsApi';
import { useDeleteTagMutation } from 'Settings/pages/TenantSettingsPage/Tags/TagsApi';
import { InstanceService } from '_common/services';
import { setLoadingValue } from 'Editor/redux/EditorStatusSlice';
import { useUninstallTemplateMutation } from 'Settings/pages/TenantSettingsPage/Templates/TemplatesApi';
import {
  useInstallReferenceStyleMutation,
  useUninstallReferenceStyleMutation,
} from 'Settings/pages/TenantSettingsPage/ReferencesStyles/ReferenceStylesApi';
import { useChangeElementStatusMutation } from 'App/redux/objectApi';

type Occurrence = Editor.SpellCheck.Occurrence;
type ActionProps = {
  deleteGroup: Parameters<typeof deleteGroup>[0];
  deleteObjects: {
    objectList: { id: ObjectId; type: ObjectType; name: string }[];
    identity: Table.Identity;
  };
  deleteSpace: Parameters<typeof deleteSpace>[0];
  checkOut: Parameters<typeof checkOutFile>[0];
  deletedRole: { roleId: Role['id'] };
  removePermission: Permission.Operation;
  removeUserFromGroup: Parameters<typeof removeUserFromGroup>[0];
  removeGroupPermission: Parameters<typeof removeGroupPermission>[0];
  removeLastPermission: {
    object: Objekt;
    element: Permission.Element;
    permission: Permission.CollaboratorPermission;
    formKey: 'user' | 'group';
    selectedRow: Permission.Collaborator;
    recursive?: boolean;
  };
  changeManager: { handleChangeManager: () => void };
  removeRow: {
    data: Objekt;
    id: UserId | ObjectId;
    formKey: 'user' | 'group';
    recursive?: boolean;
  };
  uninstallTemplate: Pick<Template, 'id' | 'name'>;
  backToEditor: {};
  // TODO: Set propper type after editor thunks are TS
  exportToIntegration: {
    id: ObjectId;
    destination: MyAny;
    type: ObjectType;
    exportTemplate: MyAny;
    integrationType: 'netdocuments' | 'box';
  };
  deleteTask: { taskId: ObjectId };
  deleteComment: { commentId: ObjectId };
  deleteFieldOption: { field: Affiliation; value: string };
  deleteCustomStyle: { styleId: ObjectId };
  resetTemplateStyle: { styleId: ObjectId };
  changeDocumentTemplate: {
    templateId: ObjectId;
    removeInlineStyles?: boolean;
    objectId?: ObjectId;
    forceReopen?: boolean;
  };
  confirmDeleteCaption: {};
  acceptAllTrackedActions: { selectedActions: MyAny; ontoAll?: boolean; forceAll?: boolean };
  rejectAllTrackedActions: { selectedActions: MyAny; ontoAll?: boolean; forceAll?: boolean };
  resolveAllComments: {
    selectedComments: MyAny;
    ontoAll?: boolean;
    forceAll?: boolean;
  };
  deleteAllComments: { selectedComments: MyAny; ontoAll?: boolean; forceAll?: boolean };
  backToExplorer: {};
  changeElementStatus: {
    payload: ObjectParams & {
      status: ElementStatus['id'];
      comment: string;
      recursive: boolean;
    };
    messageValues: Record<string, string>;
  };
  deletePublicLink: {
    objectId: string;
    linkId: string;
  } & {
    linkName: string;
  };
  removeUserFromTenant: { user: UserPublicProfileExtended };
  editUserActiveState: { user: UserPublicProfileExtended; active: boolean };
  removeReferenceLibrary: { citationId: ObjectId };
  exportCitations: Parameters<typeof exportCitations>[0];
  deleteHyperlink: {};
  updateReferenceSection: {};
  updateReferences: {};
  updateNotes: { noteType: Notes.NoteType };
  updateListIndex: {};
  uninstallReferenceStyle: Pick<Template, 'id' | 'name'>;
  installReferenceStyle: Pick<Template, 'id' | 'name'> & { updating?: boolean };
  // TODO: Set propper type after Occurrence gets an official type
  spellcheckChangeAll: { suggestion: Occurrence['suggestions'][number] } & Pick<
    Occurrence,
    'rangeData' | 'word'
  >;
  restoreDocumentVersion: { loadedVersion: number };
  deleteTag: Tag;
  exportAuditLog: {
    // @ts-expect-error Generic endpoint
    objectId: paths['/api/object/{object_type}/{object_id}/actions/export']['get']['parameters']['path']['object_id'];
    // @ts-expect-error Generic endpoint
    objectType: paths['/api/object/{object_type}/{object_id}/actions/export']['get']['parameters']['path']['object_type'];
    // @ts-expect-error Generic endpoint
    params: paths['/api/object/{object_type}/{object_id}/actions/export']['get']['parameters']['query'];
  };
  downloadOriginalFile: Parameters<typeof downloadOriginalFile>[0];
};

type Action = keyof ActionProps;

const MODAL = 'ConfirmationModal';

const ConfirmationModal = () => {
  const dispatch = useDispatch();

  const openedModals = useSelector((state) => state.modals.open);
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const headerType = useSelector((state) => state.modals[MODAL].headerType);
  const title = useSelector((state) => state.modals[MODAL].title);
  const titleValues = useSelector((state) => state.modals[MODAL].titleValues);
  const message = useSelector((state) => state.modals[MODAL].message);
  const messageValues = useSelector((state) => state.modals[MODAL].messageValues);
  const messageChildren = useSelector((state) => state.modals[MODAL].messageChildren);
  const confirmButtonTextId = useSelector((state) => state.modals[MODAL].confirmButtonTextId);
  const confirmButtonTextValues = useSelector(
    (state) => state.modals[MODAL].confirmButtonTextValues,
  );
  const confirmButtonType = useSelector((state) => state.modals[MODAL].confirmButtonType);
  const cancelButtonTextId = useSelector((state) => state.modals[MODAL].cancelButtonTextId);
  const cancelButtonTextValues = useSelector((state) => state.modals[MODAL].cancelButtonTextValues);
  const cancelButtonAction = useSelector((state) => state.modals[MODAL].cancelButtonAction);
  const cancelButtonShow = useSelector((state) => state.modals[MODAL].cancelButtonShow);
  const persistent = useSelector((state) => state.modals[MODAL].persistent);
  const actionCode = useSelector((state) => state.modals[MODAL].actionCode);
  const actionValue = useSelector((state) => state.modals[MODAL].actionValue);
  const width = useSelector((state) => state.modals[MODAL].width);
  const citations = useSelector((state) => state.modals.CitationsModal);
  const isEditor = useSelector((state) => state.editor.status.visible);

  const [deleteRole] = useDeleteRoleMutation();
  const [deleteAffiliationField] = useDeleteAffiliationFieldMutation();
  const [deleteTag] = useDeleteTagMutation();
  const [deletePublicLink] = useDeletePublicLinkMutation();
  const [uninstallTemplate] = useUninstallTemplateMutation();
  const [installReferenceStyle] = useInstallReferenceStyleMutation();
  const [uninstallReferenceStyle] = useUninstallReferenceStyleMutation();
  const [changeElementStatus] = useChangeElementStatusMutation();
  const [removeRoles] = useRemoveRolesMutation();
  const [removePermission] = useRemovePermissionMutation();
  const [applyTemplate] = useApplyTemplateMutation();

  const ACTIONS: { [key in Action]: (props: ActionProps[key]) => void } = {
    deleteGroup: (thunkParams) => {
      dispatch(deleteGroup(thunkParams));

      dispatch(clearSelection());
    },
    deleteObjects: ({ objectList, identity }) => {
      objectList.forEach(({ id: objectId, type: objectType, name }) => {
        dispatch(
          deleteObject({
            params: {
              objectId,
              objectType,
              name,
            },
            identity,
          }),
        );
      });
    },
    deleteSpace: (thunkParams) => {
      dispatch(deleteSpace(thunkParams));
    },
    checkOut: (thunkParams) => {
      dispatch(checkOutFile(thunkParams));
    },
    deletedRole: ({ roleId }) => deleteRole(roleId),
    removePermission: (thunkParams) => removePermission(thunkParams),
    removeUserFromGroup: (thunkParams) => dispatch(removeUserFromGroup(thunkParams)),
    removeGroupPermission: (thunkParams) => dispatch(removeGroupPermission(thunkParams)),
    // TODO: Recheck action usage after Permissions refactor
    removeLastPermission: ({ object, element, permission, formKey, selectedRow, recursive }) => {
      const { permissions } = object;

      if (permissions[element][selectedRow.collaboratorId]) {
        if (selectedRow.permissions[permission]) {
          const parameters = {
            objectType: object.type,
            objectId: object.id,
            code: permission,
            recursive,
            [formKey]: selectedRow.collaboratorId,
          };
          //@ts-expect-error Should be fixed after Permissions refactor is done
          removePermission(parameters);
        }
      } else {
        removeRoles({
          objectId: object.id,
          objectType: object.type,
          params: {
            [formKey]: selectedRow.collaboratorId,
            recursive,
          },
        });
      }
    },
    changeManager: ({ handleChangeManager }) => {
      handleChangeManager();
    },
    // TODO: Recheck action usage after Permissions refactor
    removeRow: ({ data, id, formKey, recursive }) => {
      const element = formKey === 'user' ? 'users' : 'groups';
      const params = {
        objectType: data.type,
        objectId: data.id,
        user: formKey === 'user' ? id : undefined,
        group: formKey !== 'user' ? id : undefined,
        recursive,
      };
      if (data.permissions[element].hasOwnProperty(id)) {
        //@ts-expect-error Should be fixed after Permissions refactor is done
        removePermission(params);
      }
      if (data.permissions.roles[element].hasOwnProperty(id)) {
        removeRoles({ objectId: data.id, objectType: data.type, params });
      }
    },
    uninstallTemplate: (params) => uninstallTemplate(params),
    backToEditor: () => dispatch(closeAndResetModal('ExportIntegrationModal')),
    // TODO: Check if use can be simplified
    exportToIntegration: ({ id, destination, type, exportTemplate, integrationType }) => {
      switch (integrationType) {
        case 'netdocuments':
          dispatch(
            exportToNetDocuments({
              documentId: id,
              options: { destination, type, ...exportTemplate },
            }),
          );
          break;
        case 'box':
          dispatch(
            exportToBox({ documentId: id, options: { destination, type, ...exportTemplate } }),
          );
          break;
      }
    },
    deleteTask: ({ taskId }) =>
      EditorManager.getInstance()
        .deleteTask(taskId)
        .then(() => {
          notify({
            type: 'success',
            title: 'TASK_DELETED',
            message: 'THE_TASK_WAS_SUCCESSFULLY_DELETED',
          });
          dispatch(setTaskDeleted(taskId));
        }),
    deleteComment: ({ commentId }) =>
      EditorManager.getInstance()
        .deleteComment(commentId)
        .then(() => {
          notify({
            type: 'success',
            title: 'COMMENT_DELETED',
            message: 'THE_COMMENT_WAS_SUCCESSFULLY_DELETED',
          });
        }),
    deleteFieldOption: (thunkParams) => deleteAffiliationField(thunkParams),

    deleteCustomStyle: ({ styleId }) => {
      const editorManager = EditorManager.getInstance();
      if (editorManager) {
        editorManager.deleteDocumentStyle(styleId);
      }
    },
    resetTemplateStyle: ({ styleId }) => {
      const editorManager = EditorManager.getInstance();
      if (editorManager) {
        editorManager.resetTemplateStyle(styleId);
      }
    },
    changeDocumentTemplate: ({ templateId, forceReopen, removeInlineStyles, objectId }) => {
      if (isEditor) {
        EditorManager.getInstance().updateTemplateStyles(templateId, removeInlineStyles);
      } else if (objectId) {
        applyTemplate({ template: templateId, forceReopen, removeInlineStyles, objectId });
      }
    },
    confirmDeleteCaption: () => {
      EditorManager.getInstance().confirmDeleteCaption();
    },
    acceptAllTrackedActions: ({ selectedActions, ontoAll, forceAll }) => {
      EditorManager.getInstance()
        .acceptAllTrackedActions(selectedActions, forceAll)
        .then(() => {
          //Selected tracked actions
          if (ontoAll) {
            notify({
              type: 'success',
              title: 'SELECTED_CHANGES_ACCEPTED',
              message: 'CHANGES_IN_THE_SELECTION_SUCCESSFULLY_ACCEPTED',
            });
          } else {
            notify({
              type: 'success',
              title: 'SELECTION_CHANGES_ACCEPTED',
              message: 'ALL_YOUR_CHANGES_SUCCESSFULLY_ACCEPTED',
            });
          }
        });
    },
    rejectAllTrackedActions: ({ selectedActions, ontoAll, forceAll }) => {
      EditorManager.getInstance()
        .rejectAllTrackedActions(selectedActions, forceAll)
        .then(() => {
          //Selected tracked actions
          if (ontoAll) {
            notify({
              type: 'success',
              title: 'SELECTION_CHANGES_REJECTED',
              message: 'CHANGES_IN_THE_SELECTION_SUCCESSFULLY_REJECTED',
            });
          } else {
            notify({
              type: 'success',
              title: 'SELECTED_CHANGES_REJECTED',
              message: 'YOUR_CHANGES_IN_THE_SELECTION_SUCCESSFULLY_REJECTED',
            });
          }
        });
    },
    resolveAllComments: ({ selectedComments, ontoAll, forceAll }) => {
      EditorManager.getInstance()
        .resolveAllComments(selectedComments, forceAll)
        .then(() => {
          if (ontoAll) {
            //All comments
            if (forceAll) {
              //All
              notify({
                type: 'success',
                title: 'ALL_COMMENTS_RESOLVED',
                message: 'ALL_COMMENTS_WERE_SUCCESSFULLY_RESOLVED',
              });
            } else {
              //Selected
              notify({
                type: 'success',
                title: 'ALL_SELECTED_COMMENTS_RESOLVED',
                message: 'ALL_THE_COMMENTS_IN_SELECTION_WERE_SUCCESSFULLY_RESOLVED',
              });
            }
          } else {
            //Own comments only
            if (forceAll) {
              //All
              notify({
                type: 'success',
                title: 'COMMENTS_RESOLVED',
                message: 'ALL_YOUR_COMMENTS_WERE_SUCCESSFULLY_RESOLVED',
              });
            } else {
              //Selected
              notify({
                type: 'success',
                title: 'SELECTED_COMMENTS_RESOLVED',
                message: 'ALL_YOUR_COMMENTS_IN_SELECTION_WERE_SUCCESSFULLY_RESOLVED',
              });
            }
          }
        });
    },
    deleteAllComments: ({ selectedComments, ontoAll, forceAll }) => {
      EditorManager.getInstance()
        .deleteAllComments(selectedComments, forceAll)
        .then(() => {
          if (ontoAll) {
            //All comments
            if (forceAll) {
              //All
              notify({
                type: 'success',
                title: 'ALL_COMMENTS_DELETED',
                message: 'ALL_COMMENTS_WERE_SUCCESSFULLY_DELETED',
              });
            } else {
              //Selected
              notify({
                type: 'success',
                title: 'ALL_SELECTED_COMMENTS_DELETED',
                message: 'ALL_THE_COMMENTS_IN_SELECTION_WERE_SUCCESSFULLY_DELETED',
              });
            }
          } else {
            //Own comments only
            if (forceAll) {
              //All
              notify({
                type: 'success',
                title: 'COMMENTS_DELETED',
                message: 'ALL_YOUR_COMMENTS_WERE_SUCCESSFULLY_DELETED',
              });
            } else {
              //Selected
              notify({
                type: 'success',
                title: 'SELECTED_COMMENTS_DELETED',
                message: 'ALL_YOUR_COMMENTS_IN_SELECTION_WERE_SUCCESSFULLY_DELETED',
              });
            }
          }
        });
    },
    backToExplorer: () => {
      navigateToMyFiles();
    },
    changeElementStatus: (thunkParams) => {
      dispatch(closeAndResetModal('ChangeElementStatusModal'));
      changeElementStatus(thunkParams);
    },
    deletePublicLink: ({ linkName, ...thunkParams }) => {
      deletePublicLink(thunkParams).then(() => {
        notify({
          type: 'success',
          title: 'PUBLIC_LINK_DELETED',
          message: 'PUBLIC_LINK_DELETED_MESSAGE',
          messageValues: { link: linkName },
        });
      });
    },
    removeUserFromTenant: ({ user }) => {
      dispatch(removeUser(user.id)).then(() => {
        notify({
          type: 'success',
          title: 'USER_REMOVED_FROM_TENANT',
          message: 'USER_HAS_BEEN_SUCCESSFULLY_REMOVED',
          messageValues: { email: user.email },
        });
      });
    },
    editUserActiveState: ({ user, active }) => {
      dispatch(setUserActiveState({ userId: user.id, active })).then(() => {
        if (active) {
          notify({
            type: 'success',
            title: 'USER_ACTIVATED',
            message: 'USER_WAS_SUCCESSFULLY_ACTIVATED',
            messageValues: {
              firstName: user.first_name,
              lastName: user.last_name,
            },
          });
        } else {
          notify({
            type: 'success',
            title: 'USER_DEACTIVATED',
            message: 'USER_WAS_SUCCESSFULLY_DEACTIVATED',
          });
        }
      });
    },
    removeReferenceLibrary: ({ citationId }) => {
      EditorManager.getInstance()
        .removeCitationFromLibrary(citationId)
        .then(() => {
          EditorManager.getInstance().removeCitation(citationId);
          notify({
            type: 'success',
            title: 'REFERENCE_REMOVED',
            message: 'REFERENCE_REMOVED_MESSAGE',
          });
        });
    },
    exportCitations: (payload) => {
      dispatch(exportCitations({ ...payload, params: { ...payload.params, force: true } })).then(
        () => {
          dispatch(closeAndResetModal('CitationsModal'));
        },
      );
    },
    deleteHyperlink: () => {
      EditorManager.getInstance().deleteHyperlink();
    },
    updateReferenceSection: () => {
      EditorManager.getInstance()
        .updateReferenceSectionElement()
        .then(() => {
          notify({
            type: 'success',
            title: 'REFERENCE_SECTION_UPDATED',
            message: 'REFERENCE_SECTION_SUCCESSFULLY_UPDATED',
          });
        });
    },
    updateNotes: ({ noteType }) => {
      if (noteType) {
        try {
          EditorManager.getInstance().refreshNotes();
          notify({
            type: 'success',
            title: noteType === 'endnote' ? 'ENDNOTES_UPDATED' : 'FOOTNOTES_UPDATED',
            message:
              noteType === 'endnote'
                ? 'NUMBERING_OF_THE_ENDNOTES_HAS_BEEN_UPDATED'
                : 'NUMBERING_OF_THE_FOOTNOTES_HAS_BEEN_UPDATED',
          });
        } catch (e) {
          throw e;
        }
      }
    },
    updateListIndex: () => {
      try {
        EditorManager.getInstance().updateListNumbering();
        notify({
          type: 'success',
          title: 'LIST_UPDATED',
          message: 'THE_LIST_ORDERING_WAS_SUCCESSFULLY_UPDATED',
        });
      } catch (e) {
        throw e;
      }
    },
    updateReferences: () => {
      try {
        EditorManager.getInstance().updateCitationsNumbering();
        notify({
          type: 'success',
          title: 'REFERENCES_UPDATED',
          message: 'THE_REFERENCES_NUMBERING_WAS_SUCCESSFULLY_UPDATED',
        });
      } catch (e) {
        throw e;
      }
    },

    uninstallReferenceStyle: (params) => {
      uninstallReferenceStyle(params);
    },
    installReferenceStyle: (params) => {
      installReferenceStyle(params);
    },
    spellcheckChangeAll: ({ rangeData, word, suggestion }) => {
      if (EditorManager.getInstance().restoreSelectionToError(rangeData, word)) {
        EditorManager.getInstance()
          .changeAllOcurrences(word, suggestion)
          .then(({ changes }) => {
            dispatch(toggleChangedAllTrigger());
            notify({
              type: 'success',
              title: 'CHANGED_OCCURRENCES_OF_AN_ERROR',
              message: 'ALL_QUANTITY_OCCURRENCES_CHANGED_TO',
              messageValues: {
                noOccurrences: changes,
                error: word,
                newWord: suggestion,
              },
            });
          })
          .catch(() => {
            notify({
              type: 'error',
              title: 'CANNOT_COMPLETE_THIS_ACTION',
              message: 'IF_PROBLEM_PERSISTS_CONTACT_SUPPORT',
            });
          });
      } else {
        dispatch(setWordHasChanged(true));
      }
    },

    restoreDocumentVersion: ({ loadedVersion }) => {
      EditorManager.getInstance()
        .restoreVersion(loadedVersion)
        .then(() => {
          notify({
            type: 'success',
            title: 'DOCUMENT_VERSION_RESTORED',
            message: 'THE_DOCUMENT_VERSION_WAS_SUCCESSFULLY_RESTORED',
          });
        });
    },
    deleteTag: (tagId) => {
      deleteTag(tagId);
    },
    exportAuditLog: (params) => {
      dispatch(closeModal('DocumentAuditLogModal'));

      dispatch(setLoadingValue('EXPORTING_AUDIT_LOG'));
      new InstanceService()
        .exportAuditLog(params)
        .then(() => {
          notify({
            type: 'success',
            title: 'AUDIT_LOG_EXPORTED',
            message: 'AUDIT_LOG_EXPORTED_MESSAGE',
          });
        })
        .finally(() => {
          dispatch(setLoadingValue(false));
        });
    },
    downloadOriginalFile: (params) => {
      dispatch(setAppLoading({ isOpen: true }));
      dispatch(downloadOriginalFile(params))
        .then(() => {
          notify({
            type: 'success',
            title: 'ORIGINAL_FILE_DOWNLOADED',
            message:
              params.objectType === 'document'
                ? 'ORIGINAL_FILE_FOR_DOCUMENT_WAS_DOWNLOADED'
                : 'ORIGINAL_FILE_FOR_DOPDF_WAS_DOWNLOADED',
            messageValues: { docName: params.filename },
          });
        })
        .finally(() => {
          dispatch(setAppLoading({ isOpen: false }));
          if (openedModals.DocumentAuditLogModal) {
            dispatch(updateModal({ modal: 'DocumentAuditLogModal', data: { isVisible: true } }));
          }
        });
    },
  };
  const typedActionCode = actionCode in ACTIONS ? (actionCode as Action) : undefined;

  useEffect(() => {
    return () => {
      if (openedModals.DocumentAuditLogModal) {
        dispatch(updateModal({ modal: 'DocumentAuditLogModal', data: { isVisible: true } }));
      }
    };
  }, []);

  const cancel = () => {
    dispatch(closeAndResetModal(MODAL));
    dispatch(openModal('ExportIntegrationModal'));
  };

  const close = () => {
    if (actionCode === 'exportCitations') {
      dispatch(closeAndResetModal(MODAL));
      dispatch(
        openAndUpdateModal({
          modal: 'CitationsModal',
          data: citations,
        }),
      );
    } else dispatch(closeAndResetModal(MODAL));
  };

  const confirm = () => {
    if (typedActionCode && ACTIONS[typedActionCode]) {
      ACTIONS[typedActionCode](actionValue);
    }
    close();
  };

  const getText = ({
    id,
    values,
    messageChildren,
  }: TranslationMessage & { messageChildren?: ReactNode }) =>
    messageChildren || <FormattedMessage id={id} values={values} />;

  const renderConfirmButton = () => {
    if (confirmButtonType === 'primary') {
      return (
        <Button
          size="medium"
          variant="primary"
          onClick={confirm}
          testId="confirm-modal-submit-button"
        >
          <FormattedMessage id={confirmButtonTextId} values={confirmButtonTextValues} />
        </Button>
      );
    }
    if (confirmButtonType === 'danger') {
      return (
        <Button
          size="medium"
          variant="danger"
          onClick={confirm}
          testId="confirm-modal-submit-button"
        >
          <FormattedMessage id={confirmButtonTextId} values={confirmButtonTextValues} />
        </Button>
      );
    }

    return (
      <Button size="medium" onClick={confirm} testId="confirm-modal-submit-button">
        <FormattedMessage id={confirmButtonTextId} values={confirmButtonTextValues} />
      </Button>
    );
  };

  const modalWidth = typeof width === 'number' ? `${width}rem` : width;

  return (
    <Modal
      open={!!isOpen}
      onClose={persistent ? undefined : close}
      width={modalWidth}
      type={headerType}
      testId="confirmation-modal"
    >
      <Modal.Header onClose={persistent ? undefined : close}>
        {getText({ id: title, values: titleValues })}
      </Modal.Header>
      <Modal.Body>{getText({ id: message, values: messageValues, messageChildren })}</Modal.Body>
      <Modal.Footer>
        {cancelButtonShow && (
          <Button
            size="medium"
            onClick={cancelButtonAction ? cancel : close}
            testId="confirm-modal-close-button"
          >
            <FormattedMessage id={cancelButtonTextId} values={cancelButtonTextValues} />
          </Button>
        )}
        {renderConfirmButton()}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
