import { ReactNode } from 'react';
import styles from './SidebarContent.module.scss';

type SidebarContentProps = {
  children: ReactNode;
  className?: string;
};

const SidebarContent = ({ children, className }: SidebarContentProps) => (
  <div className={`${styles.content} ${className}`}>{children}</div>
);

export default SidebarContent;
