import { FormattedMessage } from 'react-intl';
import { Checkbox } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import {
  selectPDFAnnotations,
  setAnnotationsFilter,
  annotationBelongsToGroupType,
} from 'PDF/redux/PDFAnnotationsSlice';

import styles from '../Filters.module.scss';

const TypeFilter = () => {
  const dispatch = useDispatch();

  const annotationList = useSelector(selectPDFAnnotations);
  const filters = useSelector((state) => state.pdf.annotations.filters);

  const handleFilter = (value: typeof filters['type'][number]) => {
    const newValue = [...filters['type']];

    if (!newValue?.includes(value)) {
      newValue?.push(value);
    } else {
      newValue.splice(newValue.indexOf(value), 1);
    }
    dispatch(setAnnotationsFilter({ filter: 'type', value: [...newValue] }));
  };

  const handleAnnotationsByType = (type: typeof filters['type'][number]) => {
    return annotationList.filter((annotation) => annotationBelongsToGroupType(type, annotation))
      .length;
  };
  return (
    <div style={{ paddingLeft: '3rem' }}>
      <Checkbox
        checked={filters['type']?.includes('comment') ? 'checked' : 'unchecked'}
        margin="1rem 0 2rem 0"
        onChange={() => {
          handleFilter('comment');
        }}
        testId="sidebar-comments-filters-type-comment-checkbox"
      >
        <FormattedMessage id="COMMENTS" />
        <span
          data-testid="sidebar-comments-filters-type-comment-counter"
          className={styles.checkboxText}
        >{`(${handleAnnotationsByType('comment')})`}</span>
      </Checkbox>
      <Checkbox
        checked={filters['type']?.includes('freehand') ? 'checked' : 'unchecked'}
        margin="0 0 2rem 0"
        onChange={() => handleFilter('freehand')}
        testId="sidebar-comments-filters-type-freehand-checkbox"
      >
        <FormattedMessage id="FREEHAND" />
        <span
          data-testid="sidebar-comments-filters-type-freehand-counter"
          className={styles.checkboxText}
        >{`(${handleAnnotationsByType('freehand')})`}</span>
      </Checkbox>
      <Checkbox
        checked={filters['type']?.includes('highlights_notes') ? 'checked' : 'unchecked'}
        margin="0 0 2rem 0"
        onChange={() => handleFilter('highlights_notes')}
        testId="sidebar-comments-filters-type-highlights_notes-checkbox"
      >
        <FormattedMessage id="HIGHLIGHTS_AND_NOTES" />
        <span
          data-testid="sidebar-comments-filters-type-highlights_notes-counter"
          className={styles.checkboxText}
        >{`(${handleAnnotationsByType('highlights_notes')})`}</span>
      </Checkbox>
      <Checkbox
        checked={filters['type']?.includes('shapes') ? 'checked' : 'unchecked'}
        margin="0 0 2rem 0"
        onChange={() => handleFilter('shapes')}
        testId="sidebar-comments-filters-type-shapes-checkbox"
      >
        <FormattedMessage id="SHAPES" />
        <span
          data-testid="sidebar-comments-filters-type-shapes-counter"
          className={styles.checkboxText}
        >{`(${handleAnnotationsByType('shapes')})`}</span>
      </Checkbox>
      <Checkbox
        checked={filters['type']?.includes('textbox') ? 'checked' : 'unchecked'}
        margin="0 0 2rem 0"
        onChange={() => handleFilter('textbox')}
        testId="sidebar-comments-filters-type-textbox-checkbox"
      >
        <FormattedMessage id="TEXT_BOX" />
        <span
          data-testid="sidebar-comments-filters-type-textbox-counter"
          className={styles.checkboxText}
        >{`(${handleAnnotationsByType('textbox')})`}</span>
      </Checkbox>
    </div>
  );
};

export default TypeFilter;
