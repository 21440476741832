/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import pdfjs from 'PDF/services/PDFJS';
import { InstanceService } from '_common/services';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { TypedArray } from 'pdfjs-dist/types/src/display/api';
import { DataObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';

export class PDFDocument extends DataObject<{}, {}> {
  pdfDocument: PDFDocumentProxy | null = null;
  constructor(transport: Transport, id: string) {
    super(id);
    this.data = {
      _id: id,
    };
  }

  async fetch(): Promise<any> {
    const { data } = (await new InstanceService().downloadPDF(this._id)) as { data: Blob };
    const buffer = (await new Response(data).arrayBuffer()) as TypedArray;
    this.pdfDocument = await pdfjs.getDocument({ data: buffer }).promise;

    return this;
  }

  get numPages() {
    return this.pdfDocument?.numPages || 0;
  }

  getPage(page: number) {
    return this.pdfDocument?.getPage(page);
  }

  dispose(): void {
    super.dispose();
  }
}
