import { createContext, useContext, useState } from 'react';
import { useSelector } from '_common/hooks';
import Extensors from './Extensors';
import ResizeAnchors from './ResizeAnchors';
import SelectionOverlay from './SelectionOverlay';

type ITableOptionsContext = {
  deleting: boolean;
  setDeleting: React.Dispatch<React.SetStateAction<boolean>>;
};

const TableOptionsContext = createContext<ITableOptionsContext>({
  deleting: false,
  setDeleting: () => {},
});

type TableOptionsProps = {
  columnWidths: number[];
  rowHeights: number[];
  selection: {
    polygon: [
      Editor.Elements.Table.Point,
      Editor.Elements.Table.Point,
      Editor.Elements.Table.Point,
      Editor.Elements.Table.Point,
    ][];
    rows: number[];
    columns: number[];
    fullySelected: {
      row: number[];
      column: number[];
    };
  };
  insert: (index: number, selection: Editor.Elements.Table.Selection) => void;
  select: (index: number, selection: Editor.Elements.Table.Selection) => void;
  remove: (index: number[], selection: Editor.Elements.Table.Selection) => void;
  resize: (
    selection: Editor.Elements.Table.Selection,
    params: { [index: number | string]: { current: number; delta: number } },
  ) => void;
};

const TableOptions = ({
  columnWidths,
  rowHeights,
  selection,
  insert,
  select,
  remove,
  resize,
}: TableOptionsProps) => {
  const zoom = useSelector((state) => state.editor.status.zoom);
  const [deleting, setDeleting] = useState(false);

  const heights = rowHeights.map((h) => h / zoom);
  const widths = columnWidths.map((w) => w / zoom);

  const width = widths.reduce((previous, current) => previous + current, 0);
  const height = heights.reduce((previous, current) => previous + current, 0);

  return (
    <TableOptionsContext.Provider value={{ deleting, setDeleting }}>
      <Extensors
        sizes={widths}
        insert={insert}
        select={select}
        remove={remove}
        selected={selection.columns}
        fullySelected={selection.fullySelected}
        type="column"
      />
      <Extensors
        sizes={heights}
        insert={insert}
        select={select}
        remove={remove}
        selected={selection.rows}
        fullySelected={selection.fullySelected}
        type="row"
      />
      <SelectionOverlay selection={selection.polygon} width={width} height={height} />
      <ResizeAnchors rows={rowHeights} columns={columnWidths} resize={resize} />
    </TableOptionsContext.Provider>
  );
};

export const useTableOptionsContext = () => useContext(TableOptionsContext);

export default TableOptions;
