import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';
import { WidgetViewModel } from './WidgetViewModel';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { OnboardingPulseWidgetView } from '../../Views';

export class OnboardingPulseWidgetModel extends WidgetViewModel<'onboardingPulse'> {
  constructor(
    Visualizer: Editor.Visualizer.State,
    refView: Editor.Visualizer.WidgetData['onboardingPulse']['view'],
    props: Editor.Visualizer.WidgetData['onboardingPulse']['props'],
  ) {
    super(Visualizer, refView, props);

    this.container.dataset.type = 'onboardingPulse';
  }

  render() {
    let offsets = EditorDOMUtils.getOffsets(this.refView);

    if (offsets) {
      this.container.style.position = 'absolute';
      this.container.style.top = `${offsets.top}px`;

      if (this.Visualizer.getLayoutType?.() === 'PAGE') {
        this.container.style.left = `calc(50% - ${offsets.width / 2}px)`;
      } else {
        this.container.style.left = `${offsets.left}px`;
      }

      this.container.style.width = `0px`;
      this.container.style.height = `${offsets.height}px`;

      ReactDOM.unmountComponentAtNode(this.container);

      // mount react component
      ReactDOM.render(
        <Provider store={ReduxInterface.getStore()}>
          <OnboardingPulseWidgetView {...this.props} />
        </Provider>,
        this.container,
      );
    }
  }

  dispose(): void {
    ReactDOM.unmountComponentAtNode(this.container);
    this.container.remove();
  }
}
