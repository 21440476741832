import { Button } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';
import SavedAccount from '../SavedAccount/SavedAccount';
import styles from './AccountsList.module.scss';

import { useDispatch } from '_common/hooks';
import { checkActiveAccount } from 'Auth/redux/authSlice';
import { useAuthContext } from 'Auth/AuthContext';

export const TEST_IDS = {
  OTHER_ACCOUNT_BUTTON: 'otherAccountButton',
  REMOVE_BUTTON: 'removeButton',
  CANCEL_BUTTON: 'cancelButton',
};

type AccountsListProps = {
  removeAccount: (userId: UserId) => void;
  useAnotherAccount: () => void;
  setSelectedAccount: (account: ParsedProfile<Account>) => void;
  removingAccounts: boolean;
  setRemovingAccounts: (removingAccount: boolean) => void;
};

const AccountsList = ({
  removeAccount,
  useAnotherAccount,
  setSelectedAccount,
  removingAccounts,
  setRemovingAccounts,
}: AccountsListProps) => {
  const dispatch = useDispatch();
  const { accounts } = useAuthContext();
  const toggleRemovingAccounts = () => setRemovingAccounts(!removingAccounts);

  const onAccountBtnClick = (account: ParsedProfile<Account>, useThirdParty: boolean) => {
    if (!removingAccounts) {
      if (account.online) {
        dispatch(checkActiveAccount({ account }));
      } else if (useThirdParty && account.third_party) {
        window.location.href = account.third_party.url;
      } else {
        const localStorage = window.localStorage.getItem('persist:localStorage');
        if (localStorage) {
          const accounts = JSON.parse(JSON.parse(localStorage).accounts);
          if (accounts[account.id]) {
            const { expires, token } = accounts[account.id];
            if (token && new Date() < new Date(expires)) {
              dispatch(checkActiveAccount({ account: { ...account, token, expires } }));
            }
          }
        }

        setSelectedAccount(account);
      }
    }
  };

  return (
    <div>
      <div className={styles.accounts}>
        {Object.keys(accounts).map((key) => (
          <div key={key} className={styles.savedAccount}>
            <SavedAccount
              key={key}
              account={accounts[key]}
              removing={removingAccounts}
              onClick={onAccountBtnClick}
              removeAccount={removeAccount}
            />
          </div>
        ))}
      </div>
      <Button
        size="large"
        fullWidth
        onClick={removingAccounts ? () => setRemovingAccounts(false) : useAnotherAccount}
        testId={removingAccounts ? TEST_IDS.CANCEL_BUTTON : TEST_IDS.OTHER_ACCOUNT_BUTTON}
      >
        {removingAccounts ? (
          <FormattedMessage id="global.cancel" />
        ) : (
          <FormattedMessage id="USE_ANOTHER_ACCOUNT" />
        )}
      </Button>
      {!removingAccounts && (
        <div className={styles['remove-account']}>
          <Button
            variant="link"
            size="small"
            onClick={toggleRemovingAccounts}
            testId={TEST_IDS.REMOVE_BUTTON}
          >
            <FormattedMessage id="REMOVE_ACCOUNT" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default AccountsList;
