import { notify } from '_common/components/ToastSystem';
import api from '_common/services/api/api';
import type { paths } from '_types/api';

type RoleParams = { roles: string[]; recursive?: boolean } & (
  | { user?: string; group?: never }
  | { group?: string; user?: never }
);

export const objectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getObject: builder.query<
      Objekt,
      ObjectParams & { open?: boolean; errorsExpected?: Request.StatusCode[] }
    >({
      query: (payload) => {
        return {
          url: `/object/${payload.objectType}/get${payload.objectId ? `/${payload.objectId}` : ''}${
            payload.open ? '?open=true' : ''
          }`,
          errorsExpected: [403, 404, ...(payload.errorsExpected ?? [])],
        };
      },
      providesTags: (result, _, arg) => [{ type: 'Object', id: result?.id || arg?.objectId }],
    }),
    changeElementStatus: builder.mutation<
      doDOC.BaseObject,
      {
        payload: ObjectParams & {
          status: ElementStatus['id'];
          comment: string;
          recursive: boolean;
        };
        messageValues: Record<string, string>;
      }
    >({
      query: ({ payload }) => {
        return {
          url: `/object/${payload.objectType}/${payload.objectId}/change_status`,
          method: 'POST',
          body: { ...payload },
        };
      },
      invalidatesTags: ['Object'],
      onQueryStarted: async (parameters, { queryFulfilled }) => {
        await queryFulfilled;
        notify({
          type: 'success',
          title: 'DOCUMENT_STATUS_CHANGED',
          message: 'THE_DOCUMENT_STATUS_WAS_SUCCESSFULLY_CHANGED_FROM_TO',
          messageValues: parameters.messageValues,
        });
      },
    }),
    getUserGroups: builder.query<
      paths['/api/object/user/groups']['get']['responses']['200']['content']['multipart/form-data'],
      UserId[]
    >({
      query: (payload) => ({
        url: '/object/user/groups',
        config: { params: payload },
      }),
    }),
    //#region Permissions
    addPermission: builder.mutation<
      void,
      ObjectParams & { code: Permission.Operation['code']; recursive: boolean } & (
          | { group: string; user?: never }
          | { user: string; group?: never }
        )
    >({
      query: (payload) => {
        return {
          url: `/object/${payload.objectType}/${payload.objectId}/permission/add`,
          method: 'POST',
          body: {
            code: payload.code,
            user: payload.user,
            group: payload.group,
            recursive: payload.recursive,
          },
        };
      },
      invalidatesTags: (_, __, arg) => [{ type: 'Object', id: arg.objectId }],
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResultRole = dispatch(
          objectApi.util.updateQueryData('getObject', patch, (draft) => {
            //Optimistic save
            const { group, user, code } = patch;
            const addedToType = group ? 'groups' : 'users';
            const userId = group || user;

            if (userId) {
              if (!draft.permissions[addedToType][userId]) {
                draft.permissions[addedToType][userId] = [];
              }

              if (!draft.permissions[addedToType][userId].includes(code)) {
                draft.permissions[addedToType][userId].push(code);
              }
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          //Due to request fail, undo optimistic save
          patchResultRole.undo();
        }
      },
    }),
    removePermission: builder.mutation<void, Permission.Operation>({
      query: (payload) => {
        return {
          url: `/object/${payload.objectType}/${payload.objectId}/permission/remove`,
          method: 'POST',
          body: {
            code: payload.code,
            user: payload.user,
            group: payload.group,
            recursive: payload.recursive,
          },
        };
      },
      invalidatesTags: (_, __, arg) => [{ type: 'Object', id: arg.objectId }],
    }),
    addRoles: builder.mutation<
      void,
      ObjectParams & {
        params: RoleParams;
      }
    >({
      query: (payload) => {
        return {
          url: `/object/${payload.objectType}/${payload.objectId}/role/add`,
          method: 'POST',
          body: {
            objectType: payload.objectType,
            objectId: payload.objectId,
            ...payload.params,
          },
        };
      },
      invalidatesTags: (_, __, arg) => [{ type: 'Object', id: arg.objectId }],
    }),
    removeRoles: builder.mutation<
      void,
      ObjectParams & { params: Omit<RoleParams, 'roles'> & { roles?: RoleParams['roles'] } }
    >({
      query: (payload) => {
        return {
          url: `/object/${payload.objectType}/${payload.objectId}/role/remove`,
          method: 'POST',
          body: {
            objectType: payload.objectType,
            objectId: payload.objectId,
            ...payload.params,
          },
        };
      },
      invalidatesTags: (_, __, arg) => [{ type: 'Object', id: arg.objectId }],
    }),
    //#endregion
  }),
});

// Export queries and mutations
export const {
  useGetObjectQuery,
  useGetUserGroupsQuery,
  useChangeElementStatusMutation,
  useAddPermissionMutation,
  useRemovePermissionMutation,
  useAddRolesMutation,
  useRemoveRolesMutation,
} = objectApi;

export default objectApi;
