import {
  forwardRef,
  KeyboardEventHandler,
  MouseEventHandler,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, usePopper } from 'dodoc-design-system';
import cx from 'classnames';
import {
  Editor,
  createEditor,
  BaseEditor,
  Descendant,
  Transforms,
  Text,
  Range,
  NodeEntry,
  Element as SlateElement,
} from 'slate';
import { withHistory } from 'slate-history';
import {
  Slate,
  Editable,
  withReact,
  ReactEditor,
  RenderLeafProps,
  RenderElementProps,
  RenderPlaceholderProps,
} from 'slate-react';

import { usePublicProfiles, useSelector } from '_common/hooks';
import {
  TextBoxColor,
  TextBoxFont,
  TextBoxFontSize,
  TextBoxAlign,
} from 'PDF/redux/PDFAnnotationsSlice';

import CustomLeaf from './CustomLeaf';
import HandledElements from './HandledElements';
import MentionCard from './MentionCard/MentionCard';

import styles from './RichTextEditor.module.scss';
import interactionControllerStyles from '../OnboardingOverlay/InteractionController.module.scss';

export type CustomElement = ParagraphElement | MentionElement | LinkElement;
type MentionElement = {
  type: 'mention';
  userId: UserId;
  children: CustomText[];
};
type LinkElement = {
  type: 'link';
  href: string;
  children: CustomText[];
};
type ParagraphElement = {
  type: 'paragraph';
  children: Descendant[];
  align?: TextBoxAlign;
};
type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  initialMention?: string;
  color?: TextBoxColor;
  fontfamily?: TextBoxFont;
  fontsize?: TextBoxFontSize;
};

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

export type RichTextEditorHandler = {
  clear: () => void;
  editorRef: RefObject<HTMLDivElement | null>;
  getCurrent: () => HTMLDivElement | null;
  addMarkStyle: <F extends SelectionFormat>(format: F, value: SelectionValue<F>) => void;
  removeMarkStyle: (format: SelectionFormat) => void;
  addBlockStyle: <F extends BlockFormat>(format: F, value: BlockValue<F>) => void;
  removeBlockStyle: (format: BlockFormat) => void;
};

export type RichFormat = {
  [key in Exclude<SelectionFormat, 'initialMention'>]?: SelectionValue<key>;
} & {
  [key in BlockFormat]?: BlockValue<key>;
};

type SelectionFormat = Exclude<keyof CustomText, 'text'>;
type SelectionValue<F extends SelectionFormat> = F extends 'color'
  ? TextBoxColor
  : F extends 'fontfamily'
  ? TextBoxFont
  : F extends 'fontsize'
  ? TextBoxFontSize
  : boolean;
type BlockFormat = Exclude<keyof ParagraphElement, 'type' | 'children'>;
type BlockValue<F extends BlockFormat> = F extends 'align' ? TextBoxAlign : undefined;

export const TEXT_ALIGN_TYPES: TextBoxAlign[] = ['left', 'center', 'right', 'justify'];

export type RichTextEditorProps = {
  initialValue?: string;
  variant?: 'default' | 'transparent';
  stretched?: boolean;
  /** Useful for scalling with zoom, such as in editor or PDF annotator */
  scale?: number;
  /** This will overwrite displayed styles without affecting content object */
  overwrittenStyles?: RichFormat;
  onClick?: MouseEventHandler<HTMLDivElement>;
  /** Element data-testid attribute for tests.
   * This will result in 7 different attributes:
   * * ${testId}-editorWrapper (div wrapper around editor)
   * * ${testId}-inlineMention (Mention component)
   * * ${testId}-paragraph (Editor paragraph element)
   * * ${testId}-leaf-bold (Editor bold text element)
   * * ${testId}-leaf-italic (Editor italic text element)
   * * ${testId}-leaf-underline (Editor underline text element)
   * * ${testId}-leaf-inlineMention (Editor text element inlineMention decorator)
   */
  testId: string;
} & (
  | {
      readOnly?: false;
      /** Informs when there is a change on editor input value */
      onChange: (commentValue: string) => void;
      /** Informs when the editor input is focused */
      onFocus?: () => void;
      /** Informs which styles are active based on current user selection. Updates on selection change */
      onSelectionChanged?: (activeStyles: RichFormat) => void;
      placeholder: string;
      /** If true, editor won't claim focus into the input */
      skipFocus?: boolean;
      /** If true, editor won't have any kind of 'new line' behaviour */
      singleLine?: boolean;
      /** Array with mentionable user's IDs (if undefined, mention behaviour is disabled) */
      mentionableUsers?: UserId[];

      expanded?: never;
      maxLines?: never;
    }
  | {
      /** If true, editor won't have any edit functionality */
      readOnly: true;
      /** If readOnly, this will decide if editor value should be limited by 'maxLines' */
      expanded: boolean;
      /** If readOnly and NOT expanded, this will decide by how many lines the editor value should be limited */
      maxLines?: number;

      onFocus?: never;
      onChange?: never;
      onSelectionChanged?: never;
      placeholder?: never;
      skipFocus?: never;
      singleLine?: never;
      mentionableUsers?: never;
    }
);

//#region Editor Extensions
//Parse the data that is pasted
const withParse = (editor: ReactEditor) => {
  const { insertData } = editor;

  editor.insertData = (data) => {
    const richText = data.getData('dodoc/richText');

    if (richText) {
      Transforms.insertFragment(editor, JSON.parse(richText));
      return;
    }

    insertData(data);
  };

  return editor;
};

const withMentions = (editor: ReactEditor) => {
  const { isInline, isVoid, insertData } = editor;

  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const richText = data.getData('dodoc/richText');

    if (richText) {
      Transforms.insertFragment(editor, JSON.parse(richText));
      return;
    }

    insertData(data);
  };

  return editor;
};

const withSingleLine = <T extends Editor>(editor: T, singleLine?: boolean) => {
  if (!singleLine) {
    return editor;
  }

  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor);
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};
//#endregion

const RichTextEditor = forwardRef(
  (
    {
      initialValue,
      variant = 'default',
      scale,
      overwrittenStyles,
      mentionableUsers,
      testId,
      readOnly,
      placeholder,
      expanded,
      maxLines = 3,
      skipFocus,
      singleLine,
      stretched,
      onClick,
      onFocus,
      onChange,
      onSelectionChanged,
    }: RichTextEditorProps,
    ref: Ref<RichTextEditorHandler>,
  ) => {
    const editorRef = useRef<HTMLDivElement>(null);
    const { align, ...leafOverwrite } = overwrittenStyles ?? {};

    const { popperProps, referenceProps } = usePopper();

    const isMacOS = useSelector((state) => state.app.platform.os.mac);
    const collaborators = Object.values(usePublicProfiles(mentionableUsers).profiles);

    //#region Renders
    const renderHandledElements = useCallback(
      (props: RenderElementProps) => (
        <HandledElements {...props} overwriteStyles={{ align }} testId={testId} />
      ),
      [],
    );

    const renderRefLeaf = useCallback(
      (leafProps: RenderLeafProps) => (
        <CustomLeaf
          {...leafProps}
          scale={scale}
          overwriteStyles={leafOverwrite}
          testId={testId}
          ref={leafProps.leaf.initialMention && !!target ? referenceProps.ref : null}
        />
      ),
      [],
    );

    const renderPlaceholder = useCallback(
      ({ attributes, children }: RenderPlaceholderProps) => (
        <span
          {...attributes}
          id={interactionControllerStyles.skipControl}
          className={styles.placeholder}
        >
          {children}
        </span>
      ),
      [],
    );
    //#endregion

    //Editor
    const editor = useMemo(
      () =>
        withParse(withMentions(withHistory(withSingleLine(withReact(createEditor()), singleLine)))),
      [],
    );

    const notifySelectionUpdate = useCallback(
      (editor: ReactEditor) => {
        if (onSelectionChanged) {
          const { initialMention, ...selectionStyles } = { ...Editor.marks(editor) };

          onSelectionChanged({ ...selectionStyles, ...getActiveBlockStyles(editor) });
        }
      },
      [onSelectionChanged],
    );

    useImperativeHandle(ref, () => ({
      clear() {
        Transforms.delete(editor, {
          at: {
            anchor: Editor.start(editor, []),
            focus: Editor.end(editor, []),
          },
        });
        setValue(parseValue(''));
        setTarget(undefined);
      },
      getCurrent() {
        return editorRef.current;
      },
      get editorRef() {
        return editorRef;
      },
      addMarkStyle<F extends SelectionFormat>(format: F, value: SelectionValue<F>) {
        addMarkStyle(editor, format, value);
      },
      removeMarkStyle(format: SelectionFormat) {
        removeMarkStyle(editor, format);
      },
      addBlockStyle<F extends BlockFormat>(format: F, value: BlockValue<F>) {
        addBlockStyle(editor, format, value);
      },
      removeBlockStyle(format: BlockFormat) {
        removeBlockStyle(editor, format);
      },
    }));

    useEffect(() => {
      if (!readOnly && !skipFocus) {
        onFocus?.();
        setTimeout(() => {
          Transforms.select(editor, Editor.end(editor, []));
          ReactEditor.focus(editor);
        }, 0);
      }
    }, [editor, readOnly, skipFocus]);

    //Mention
    const [target, setTarget] = useState<Range | undefined>();
    const [search, setSearch] = useState('');
    const filteredMentions = useMemo(
      () =>
        collaborators
          .filter((collaborator) =>
            collaborator.name.toLowerCase().startsWith(search.toLowerCase()),
          )
          .slice(0, 10),
      [collaborators, search],
    );

    //Current JSON Value
    const parseValue = useCallback((value) => {
      try {
        const parsedValue = JSON.parse(!value || value === 'null' ? '' : value);

        if (!Array.isArray(parsedValue)) {
          throw new Error('parsedValue isnt Array');
        }
        return parsedValue as Descendant[];
      } catch (_) {
        return [
          {
            type: 'paragraph',
            children: [
              {
                text: !value || value === 'null' ? '' : value,
              },
            ],
          },
        ] as Descendant[];
      }
    }, []);
    const [value, setValue] = useState<Descendant[]>(parseValue(initialValue));

    useEffect(() => {
      setValue(parseValue(initialValue));
    }, [initialValue]);

    //#region Slate utils
    const addMarkStyle = useCallback((editor: ReactEditor, format: SelectionFormat, value) => {
      Editor.addMark(editor, format, value);
      notifySelectionUpdate(editor);
    }, []);
    const removeMarkStyle = useCallback((editor: ReactEditor, format: SelectionFormat) => {
      Editor.removeMark(editor, format);
      notifySelectionUpdate(editor);
    }, []);

    const toggleMark = (editor: ReactEditor, format: SelectionFormat) => {
      const isActive = isMarkActive(editor, format);

      if (isActive) {
        Editor.removeMark(editor, format);
      } else {
        Editor.addMark(editor, format, true);
      }
    };

    const isMarkActive = (editor: ReactEditor, format: SelectionFormat) => {
      const marks = Editor.marks(editor);
      return marks && marks[format];
    };

    const addBlockStyle = useCallback(
      <F extends BlockFormat>(editor: ReactEditor, format: F, value: BlockValue<F>) => {
        Transforms.setNodes<SlateElement>(
          editor,
          {
            [format]: value,
          },
          // If align, apply to all blocks
          format === 'align'
            ? { at: [], match: (n) => SlateElement.isElement(n) && n.type === 'paragraph' }
            : undefined,
        );
        notifySelectionUpdate(editor);
      },
      [],
    );
    const removeBlockStyle = useCallback((editor: ReactEditor, format: BlockFormat) => {
      Transforms.setNodes<SlateElement>(editor, {
        [format]: undefined,
      });
      notifySelectionUpdate(editor);
    }, []);

    const getActiveBlockStyles = (editor: ReactEditor) => {
      const { selection } = editor;
      if (!selection) return false;

      let styles: { [key in BlockFormat]?: BlockValue<key> } = {};

      Array.from(
        Editor.nodes(editor, {
          at: Editor.unhangRange(editor, selection),
          match: (n) => {
            const match =
              !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'paragraph';

            if (match) {
              const { children, type, ...nodeStyles } = n;
              styles = { ...styles, ...nodeStyles };
            }

            return match;
          },
        }),
      );

      return styles;
    };
    //#endregion

    const handleOnClick: MouseEventHandler<HTMLDivElement> = (e) => {
      onClick?.(e);

      if (readOnly) {
        return;
      }
      e.stopPropagation();
    };

    const handleSelectionChanged = () => {
      notifySelectionUpdate(editor);
    };

    const handleOnChange = (value: Descendant[]) => {
      if (readOnly) {
        return;
      }

      setValue(value);

      // Mention behaviour
      const { selection } = editor;

      if (selection && Range.isCollapsed(selection) && mentionableUsers) {
        const [start] = Range.edges(selection);
        const wordBefore = Editor.before(editor, start, { unit: 'word' });
        const before = wordBefore && Editor.before(editor, wordBefore);
        const beforeRange = before && Editor.range(editor, before, start);
        const beforeText = beforeRange && Editor.string(editor, beforeRange);
        const beforeMatch = beforeText && beforeText.match(/^@(\w*)$/);
        const after = Editor.after(editor, start);
        const afterRange = Editor.range(editor, start, after);
        const afterText = Editor.string(editor, afterRange);
        const afterMatch = afterText.match(/^(\s|$)/);

        //Allow search mentionable users without initial text
        if (!beforeMatch) {
          const wordBefore = Editor.before(editor, start, { unit: 'character' });
          const beforeRange = wordBefore && Editor.range(editor, wordBefore, start);

          if (!beforeRange || !Editor.string(editor, beforeRange).endsWith('@')) {
            setTarget(undefined);
          } else {
            setTarget(beforeRange);
            setSearch('');
            return;
          }
        }

        //Ignore if before match only includes '@' to avoid false positives from new lines
        if (beforeMatch && beforeMatch.input !== '@' && afterMatch) {
          setTarget(beforeRange);
          setSearch(beforeMatch[1]);
          return;
        }
      }

      setTarget(undefined);

      // Check if change operation was not a selection change
      const isAstChange = editor.operations.some((op) => op.type !== 'set_selection');
      if (isAstChange) {
        //If content is empty (paragraph wrapper always exists)
        if (value.length === 1) {
          const typedValue = value[0] as unknown as { children: { text: string }[] };
          if (typedValue.children.length === 1 && typedValue.children[0].text === '') {
            onChange('');
            return;
          }
        }

        onChange(JSON.stringify(value));
      }
    };

    const handleKeyDown: KeyboardEventHandler = (event) => {
      if ((!isMacOS && event.ctrlKey) || (isMacOS && event.metaKey)) {
        switch (event.key.toLowerCase()) {
          case 'b':
            event.preventDefault();
            toggleMark(editor, 'bold');
            break;
          case 'i':
            event.preventDefault();
            toggleMark(editor, 'italic');
            break;
          case 'u':
            event.preventDefault();
            toggleMark(editor, 'underline');
            break;
        }
      }

      event.stopPropagation();
    };

    const handleInsertMention = (userId: UserId) => {
      if (target) {
        Transforms.select(editor, target);

        const mention: MentionElement = {
          type: 'mention',
          userId,
          children: [{ text: ' ' }],
        };
        Transforms.insertNodes(editor, mention);
        Transforms.move(editor);

        setValue(editor.children);
        setTarget(undefined);
      }
    };

    const decorate = ([node, path]: NodeEntry) => {
      if (!Text.isText(node) || !mentionableUsers) {
        return [];
      }
      const ranges = [];
      const tokens = node.text
        .split(/(\s+)/)
        .map((x) => (x[0] === '@' && !!target ? { content: x, type: 'initialMention' } : x));

      let start = 0;
      for (const token of tokens) {
        const end = start + (typeof token === 'string' ? token.length : token.content.length);
        if (typeof token !== 'string') {
          ranges.push({
            [token.type]: true,
            anchor: { path, offset: start },
            focus: { path, offset: end },
          });
        }
        start = end;
      }

      return ranges;
    };

    /**
     * Slate editor is uncontrolled, so a forced update is necessary
     * Quote: The simplest way is to replace the value of editor.children editor.children = newValue and trigger a re-rendering
     * Source: https://docs.slatejs.org/walkthroughs/06-saving-to-a-database
     */
    editor.children = value;

    return (
      <>
        <div
          ref={editorRef}
          style={{
            lineHeight: `${2.5 * (scale ?? 1)}rem`,
            WebkitLineClamp: readOnly && !expanded ? maxLines : 'none',
          }}
          className={cx(styles.editor, {
            [styles.editCursor]: !readOnly,
            [styles.editable]: !readOnly && variant !== 'transparent',
            [styles.transparent]: variant === 'transparent',
            [styles.readLess]: readOnly && !expanded,
            [styles.breakAll]: stretched,
          })}
          onClick={handleOnClick}
          data-testid={testId ? `${testId}-editorWrapper` : ''}
        >
          {/* Parent div needs another ref which is unrelated to dropdown ref */}
          <span {...referenceProps}>
            <Slate editor={editor} value={value} onChange={handleOnChange}>
              <Editable
                readOnly={readOnly}
                className={cx(styles.editorInput, {
                  [styles.singleLine]: singleLine,
                  [styles.stretched]: stretched,
                })}
                decorate={decorate}
                renderElement={renderHandledElements}
                renderLeaf={renderRefLeaf}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                renderPlaceholder={renderPlaceholder}
                onSelect={handleSelectionChanged}
                onFocus={onFocus}
                data-testid={testId ? `${testId}-editor-input` : ''}
              />
            </Slate>
          </span>
          <Dropdown
            {...popperProps}
            isOpen={!readOnly && !!target}
            close={() => setTarget(undefined)}
            testId={`${testId}-users-dropdown`}
          >
            {filteredMentions.length > 0 ? (
              filteredMentions.map((user) => (
                <MentionCard
                  key={`mention-card-${user.id}`}
                  userId={user.id}
                  onInsertMention={handleInsertMention}
                />
              ))
            ) : (
              <Dropdown.Item
                size="large"
                disabled
                testId={`${testId}-users-no-users-available-dropdown-item`}
              >
                <FormattedMessage id="NO_USERS_AVAILABLE" />
              </Dropdown.Item>
            )}
          </Dropdown>
        </div>
      </>
    );
  },
);

export default RichTextEditor;
