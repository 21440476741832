import { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Divider, Helper, Button } from 'dodoc-design-system';
import { Redirect, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { useDispatch, useSelector } from '_common/hooks';

import { openModal } from '_common/modals/ModalsSlice';
import { gotFeedback, resetLoginErrors } from 'Auth/redux/authSlice';
import { removeAccount } from 'Auth/redux/localStorageSlice';
import { useLoginSetupQuery } from '_common/services/api/authority';
import { useAuthContext } from 'Auth/AuthContext';

import SupportButton from '_common/components/SupportButton/SupportButton';
import AuthHero from 'Auth/components/AuthHero/AuthHero';

import SavedAccountsLogin from './SavedAccountsLogin/SavedAccountsLogin';
import ThirdPartyLogin from './ThirdPartyLogin/ThirdPartyLogin';
import FreshLogin from './FreshLogin/FreshLogin';

import styles from './SignInPage.module.scss';

const SignInPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { accounts } = useAuthContext();
  const location = useLocation<LocationState>();
  const setupIntervalId = useRef(-1);

  const authenticated = useSelector((state) => state.auth.authenticated);
  const firstLogin = useSelector((state) => state.auth.firstLogin);
  const isUpdatePasswordForced = useSelector((state) => state.auth.isUpdatePasswordForced);
  const feedback = useSelector((state) => state.auth.feedback);
  const platform = useSelector((state) => state.app.platform);

  const [signInForm, setSignInForm] = useState<Auth.SignInState | undefined>();
  const [from, setFrom] = useState<{ pathname: string }>({ pathname: '/storage' });

  useEffect(() => {
    if (Object.keys(accounts).length !== 0) {
      setSignInForm('SAVED_ACCOUNTS');
    } else {
      setSignInForm('FRESH_LOGIN');
    }
  }, [accounts]);

  const { data: setupData, isError, refetch } = useLoginSetupQuery();
  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        refetch();
      }, 2000);
    }
  }, [isError]);

  useEffect(() => {
    if (platform.browser.ie) {
      dispatch(openModal('BrowserSupportWarningModal'));
    }

    const from =
      location.state && location.state.from.pathname !== '/auth/updatepassword'
        ? location.state.from
        : { pathname: '/' };

    setFrom(from);
    if (from.pathname !== '/') {
      sessionStorage.setItem('redirect-sso', from.pathname);
    }
    return () => {
      /**
       * Resets unlock message
       */
      dispatch(gotFeedback(null));
      clearInterval(setupIntervalId.current);
    };
  }, []);

  const changeSignInForm = (form: Auth.SignInState) => {
    dispatch(resetLoginErrors());
    setSignInForm(form);
  };

  const handleUseAnotherAccount = () => {
    changeSignInForm('FRESH_LOGIN');
  };

  const handleUseAccounts = () => {
    changeSignInForm('SAVED_ACCOUNTS');
  };

  const handleRemoveAccount = (id: UserId) => {
    dispatch(removeAccount(id));
    const ids = Object.keys(accounts);
    if (ids.length === 1 && ids[0] === id) {
      changeSignInForm('FRESH_LOGIN');
    }
  };

  const renderAuthRoute = () => {
    const hasSavedAccount = Object.keys(accounts).length > 0;
    switch (signInForm) {
      case 'SAVED_ACCOUNTS':
        return (
          <SavedAccountsLogin
            removeAccount={handleRemoveAccount}
            useAnotherAccount={handleUseAnotherAccount}
            setSignInForm={changeSignInForm}
          />
        );
      case 'FRESH_LOGIN':
        return (
          <div data-testid="sign-in-form-container" data-has-error={isError}>
            {setupData?.['3p'] && setupData['3p'].length > 0 && (
              <>
                <ThirdPartyLogin data={setupData['3p']} />
                {(setupData.allow_login_with_password || hasSavedAccount) && (
                  <Divider margin="2rem 0 1rem">{intl.formatMessage({ id: 'global.or' })}</Divider>
                )}
              </>
            )}
            {setupData?.allow_login_with_password && <FreshLogin />}
            {hasSavedAccount && (
              <Button
                margin="1rem 0 0 0"
                fullWidth
                size="large"
                onClick={handleUseAccounts}
                tabIndex={1}
                testId="sign-in-using-existing-account-button"
              >
                <FormattedMessage id="SIGN_IN_USING_EXISTING_ACCOUNT" />
              </Button>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  if (authenticated) {
    if (isUpdatePasswordForced) {
      return <Redirect to="/auth/updatepassword" />;
    }

    if (from?.pathname?.includes('//')) {
      return <Redirect to="/404" />;
    }

    return <Redirect to={from} />;
  }

  if (firstLogin) {
    return <Redirect to="/auth/welcome" />;
  }

  return (
    <div className={styles.root}>
      <AuthHero />
      <div className={styles.form}>
        <div className={styles.title}>
          <FormattedMessage id="SIGN_IN_TO_DODOC" />
        </div>
        <CSSTransition in={!!feedback} timeout={200} classNames="feedback">
          <SignInHelper feedback={feedback} />
        </CSSTransition>
        <div className={styles.wrapper}>{renderAuthRoute()}</div>
      </div>
      <SupportButton />
    </div>
  );
};

const SignInHelper = ({ feedback }: { feedback: Feedback | null }) => {
  return (
    <div className={styles.feedback}>
      {feedback && (
        <div data-testid="feedback-container">
          <Helper type={feedback.type} testId="sign-in-page-feedback-helper">
            <FormattedMessage id={feedback.message} values={feedback.messageValues} />
          </Helper>
        </div>
      )}
    </div>
  );
};

export default SignInPage;
