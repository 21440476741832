import cx from 'classnames';
import type { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';
import { Icon } from 'dodoc-design-system';
import styles from './Body.module.scss';
import { useIntl } from 'react-intl';

type AnnotationType = {
  type: PDF.Annotation['subtype'];
  notification?: boolean;
};

const AnnotationType = ({ type, notification }: AnnotationType) => {
  const intl = useIntl();
  let icon: IconTypes['16'] | null = null;
  let labelId: string | null = null;

  switch (type) {
    case 'Note':
      icon = 'CommentGrey';
      labelId = 'global.comment';
      break;
    case 'Highlight':
      icon = 'HighlightGrey';
      labelId = 'HIGHLIGHTED_TEXT';
      break;
    case 'Underline':
      icon = 'UnderlineGrey';
      labelId = 'UNDERLINED_TEXT';
      break;
    case 'StrikeOut':
      icon = 'StrikethroughGrey';
      labelId = 'STRIKETHROUGH_TEXT';
      break;
    case 'Line':
    case 'Square':
    case 'Circle':
    case 'Polygon':
    case 'PolyLine':
      icon = 'ShapeGrey';
      labelId = 'SHAPE';
      break;
    case 'FreeText':
      icon = 'TextboxGrey';
      labelId = 'TEXT_BOX';
      break;
    case 'Ink':
      icon = 'FreehandGrey';
      labelId = 'FREEHAND';
      break;
  }

  if (icon && labelId) {
    return (
      <div
        className={cx({
          [styles.annotationType]: true,
          [styles.notification]: notification,
        })}
      >
        <Icon size={16} icon={icon} color="#6D6D82" />
        <span>{intl.formatMessage({ id: labelId })}</span>
      </div>
    );
  }
  return null;
};

export default AnnotationType;
