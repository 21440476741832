import { FormattedMessage } from 'react-intl';
import { Checkbox } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { selectPDFTasks, setTasksFilter } from 'PDF/redux/PDFAnnotationsSlice';

import styles from '../Filters.module.scss';

const StatusFilter = () => {
  const dispatch = useDispatch();

  const tasksList = useSelector(selectPDFTasks);
  const filters = useSelector((state) => state.pdf.annotations.tasksFilters);

  const handleStatusFilter = (value: typeof filters['status'][number] | 'deleted') => {
    if (value === 'deleted') {
      dispatch(setTasksFilter({ filter: 'deleted', value: !filters.deleted }));
    } else {
      const newValue = [...filters['status']];

      if (!newValue?.includes(value)) {
        newValue?.push(value);
      } else {
        newValue.splice(newValue.indexOf(value), 1);
      }
      dispatch(setTasksFilter({ filter: 'status', value: [...newValue] }));
    }
  };

  const handleTasksByStatus = (status: typeof filters['status'][number] | 'deleted') => {
    if (status === 'deleted') {
      return tasksList.filter((task) => task.state === 'Cancelled').length;
    }
    return tasksList.filter((task) => task.status === status && task.state !== 'Cancelled').length;
  };
  return (
    <div style={{ paddingLeft: '3rem' }}>
      <Checkbox
        checked={filters['status']?.includes('d') ? 'checked' : 'unchecked'}
        margin="1rem 0 2rem 0"
        onChange={() => {
          handleStatusFilter('d');
        }}
        testId="sidebar-tasks-filters-status-done-checkbox"
      >
        <FormattedMessage id="DONE" />
        <span
          data-testid="sidebar-tasks-filters-status-done-counter"
          className={styles.checkboxText}
        >{`(${handleTasksByStatus('d')})`}</span>
      </Checkbox>
      <Checkbox
        checked={filters.deleted ? 'checked' : 'unchecked'}
        margin="1rem 0 2rem 0"
        onChange={() => {
          handleStatusFilter('deleted');
        }}
        testId="sidebar-tasks-filters-status-deleted-checkbox"
      >
        <FormattedMessage id="editor.sidebar.review.filter.status.deleted" />
        <span
          data-testid="sidebar-tasks-filters-status-deleted-counter"
          className={styles.checkboxText}
        >{`(${handleTasksByStatus('deleted')})`}</span>
      </Checkbox>
      <Checkbox
        checked={filters['status']?.includes('td') ? 'checked' : 'unchecked'}
        margin="0 0 2rem 0"
        onChange={() => handleStatusFilter('td')}
        testId="sidebar-tasks-filters-status-todo-checkbox"
      >
        <FormattedMessage id="TODO" />
        <span
          data-testid="sidebar-tasks-filters-status-todo-counter"
          className={styles.checkboxText}
        >{`(${handleTasksByStatus('td')})`}</span>
      </Checkbox>
      <Checkbox
        checked={filters['status']?.includes('pr') ? 'checked' : 'unchecked'}
        margin="0 0 2rem 0"
        onChange={() => handleStatusFilter('pr')}
        testId="sidebar-tasks-filters-status-progress-checkbox"
      >
        <FormattedMessage id="IN_PROGRESS" />
        <span
          data-testid="sidebar-tasks-filters-status-progress-counter"
          className={styles.checkboxText}
        >{`(${handleTasksByStatus('pr')})`}</span>
      </Checkbox>
    </div>
  );
};

export default StatusFilter;
