import { useEffect } from 'react';
import { ViewNoteCard, EditableCard } from 'Editor/components';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { setNoteOverlayData, selectNote } from 'Editor/redux/NotesSlice';
import {
  selectReadOnlyMode,
  getDocumentObject,
  selectUser,
  selectIsPageLayout,
} from 'Editor/redux/EditorStatusSlice';
import { setSidebarPanelTab, setSidebarView } from 'Editor/redux/SidebarSlice';

import { EditableCardProps } from 'Editor/components/EditableCard/EditableCardContent';

const NoteOverlay = () => {
  const dispatch = useDispatch();

  const type = useSelector((state) => state.editor.notes.overlay.type);
  const offsets = useSelector((state) => state.editor.notes.overlay.offsets);
  const operation = useSelector((state) => state.editor.notes.overlay.operation);
  const sidebarView = useSelector((state) => state.editor.sidebar.view);
  const user = useSelector(selectUser);
  const document = useSelector(getDocumentObject);
  const note = useSelector(selectNote);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const zoom = useSelector((state) => state.editor.status.zoom);
  const isPageLayout = useSelector(selectIsPageLayout);

  useEffect(() => {
    if (isPageLayout && !sidebarView && note) {
      dispatch(
        setSidebarPanelTab({
          view: 'notes',
          tab: note.type === 'footnote' ? 'footnotes' : 'endnotes',
        }),
      );
      dispatch(setSidebarView('NOTES'));
    }
  }, [isPageLayout, note, sidebarView]);

  if (offsets === null) {
    return null;
  }

  const manager = EditorManager.getInstance();
  const getAdditionalWidth = () => {
    // 400 is the offset from which the card would potentially overlap other cards
    if (offsets.left > 400) {
      // 344 is the current size of the Note card
      return -344 - offsets.width;
    }
    return 20;
  };

  const handleInsertNote: EditableCardProps['handleCreateClicked'] = async (text) => {
    if (text) {
      const id = await manager.createNote(null, type, text);
      if (id) {
        manager.insertNote(type, id);
      }
    }
    handleCancelEdition();
  };

  const handleSaveClicked: EditableCardProps['handleSaveClicked'] = async (text) => {
    if (note?.id) {
      await manager.editNote(note.id, text);
    }
    handleCancelEdition();
  };

  const handleCancelEdition = () => {
    dispatch(
      setNoteOverlayData(operation === 'create' ? { offsets: null } : { operation: 'view' }),
    );
  };

  const renderCard = () => {
    if (((operation === 'edit' || operation === 'view') && !note) || isPageLayout) {
      return null;
    }
    if (operation === 'create' || (operation === 'edit' && !sidebarView)) {
      return (
        <EditableCard
          isNote
          type={type}
          note={note}
          user={user.id}
          name={operation === 'edit' ? note?.author : undefined}
          editMode={operation === 'edit'}
          content={operation === 'edit' ? note?.content : ''}
          handleCreateClicked={handleInsertNote}
          handleSaveClicked={handleSaveClicked}
          handleCancelClicked={handleCancelEdition}
        />
      );
    }
    if (operation === 'view' && !sidebarView && note) {
      return (
        <ViewNoteCard
          doc={document}
          note={note}
          selected
          isReadOnlyMode={isReadOnlyMode}
          sidebar={!!sidebarView}
        />
      );
    }
    return null;
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: offsets.left * zoom + offsets.width + getAdditionalWidth() * zoom,
        top: (operation === 'create' ? offsets.top : offsets.top * zoom) + offsets.height + 5,
      }}
    >
      {renderCard()}
    </div>
  );
};

export default NoteOverlay;
