import { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Helper } from 'dodoc-design-system';
import { AxiosResponse } from 'axios';

import { useDispatch, usePublicGroup, usePublicProfiles } from '_common/hooks';

import { addUserToGroup } from 'App/redux/appSlice';
import { listObjects } from '_common/components/Table/TableSlice';

import { Table, SearchUser } from '_common/components';
import { SettingGroupHeader } from 'Settings/components';
import { UserOption } from '_common/components/SearchUser/SearchUser';

import GroupSettingsContext from '../GroupSettingsContext';
import MembersViewRow from './MembersViewRow';

import styles from '../GroupSettingsPage.module.scss';

const PAGE_IDENTITY = 'groups_members';
const COLUMNS_ARRAY = [
  {
    id: 'avatar',
    labelId: '',
    size: 6.5,
  },
  {
    id: 'name',
    labelId: 'groups.header.name',
  },
  {
    id: 'email',
    labelId: 'storage.modals.user.email',
    size: 40,
  },
  {
    id: 'permissions',
    labelId: 'groups.header.permissions',
    orderable: true,
    size: 20,
  },
  {
    id: 'settings',
    labelId: '',
    size: 8,
  },
];

const MembersView = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { groupId } = useContext(GroupSettingsContext);

  const { group } = usePublicGroup(groupId ?? '');
  const { profiles } = usePublicProfiles(group.users);

  const [memberSelected, setMemberSelected] = useState<UserOption | undefined>();
  const useIsOwner =
    group.user_permissions.includes('admin') || group.user_permissions.includes('owner');

  //#region Table object listing handlers
  const fetchObjects = useCallback(async () => {
    return new Promise<AxiosResponse<unknown>>((resolve) => {
      resolve({
        data: { nodes: Object.values(profiles) },
        status: 200,
        statusText: '',
        headers: { placeholder: '' },
        config: {},
      });
    });
  }, [profiles]);

  useEffect(() => {
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, [profiles]);
  //#endregion

  const handleOnMemberAutocompleteChange = (user: UserOption) => {
    setMemberSelected(user);
  };

  const handleOnClickAddMember = () => {
    if (memberSelected) {
      dispatch(addUserToGroup({ groupId: group.id, userId: memberSelected.value }));
      setMemberSelected(undefined);
    }
  };

  return (
    <>
      {/* Members */}
      <SettingGroupHeader
        style={{ padding: '16px' }}
        actions={
          useIsOwner && (
            <div className={styles.autocompleteContainer}>
              <SearchUser
                value={memberSelected}
                valuesToFilter={group.users}
                onChange={handleOnMemberAutocompleteChange}
                placeholder={intl.formatMessage({
                  id: 'settings.group.placeholderAutocomplete',
                })}
                escapeClearsValue
                width="37rem"
                testId="members"
              />
              <Button
                size="medium"
                variant="primary"
                disabled={!memberSelected}
                onClick={handleOnClickAddMember}
                style={{ marginLeft: '2rem' }}
                testId="members-add-button"
              >
                <FormattedMessage id="global.add" />
              </Button>
            </div>
          )
        }
      >
        <div className={styles.userPermissionsText}>
          <span className={styles.title}>
            {intl.formatMessage({ id: 'settings.group.groupMembersTitle' })}
          </span>
        </div>
      </SettingGroupHeader>

      {group.users.length === 0 ? (
        <Helper margin="2rem 0 0 0" testId="group-settings-page-no-members-helper">
          <FormattedMessage id="groups.emptyList" />
        </Helper>
      ) : (
        <Table
          identity={PAGE_IDENTITY}
          columns={COLUMNS_ARRAY}
          RowComponent={MembersViewRow}
          fetchObjects={fetchObjects}
          selectable={false}
        />
      )}
    </>
  );
};

export default MembersView;
