import { useEffect } from 'react';
import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import {
  selectReadOnlyMode,
  getDocumentObject,
  selectUser,
  selectIsPageLayout,
} from 'Editor/redux/EditorStatusSlice';
import { setSidebarView } from 'Editor/redux/SidebarSlice';

import FindAndReplaceTab from 'Editor/pages/EditorPage/SidebarComponent/FindAndReplaceTab/FindAndReplaceTab';
import { selectUserIsAdmin } from '_common/services/api/authority';
import { InteractionController, IntlErrorBoundary } from '_common/components';

import ContentPermissionsTab from './ContentPermissionsTab/ContentPermissionsTab';
import ApproveContentTab from './ApproveContentTab/ApproveContentTab';
import ReviewTab from './ReviewTab/ReviewTab';
import TasksTab from './TasksTab/TasksTab';
import ReferencesTab from './ReferencesTab/ReferencesTab';
import NotesTab from './NotesTab/NotesTab';
import NavigationTab from './NavigationTab/NavigationTab';
import CrossReferencesTab from './CrossRefrencesTab/CrossReferencesTab';
import VersionsTab from './VersionsTab/VersionsTab';
import SpellCheckTab from './SpellCheckTab/SpellCheckTab';
import DocumentStylesTab from './DocumentStylesTab/DocumentStylesTab';
import SidebarToggles from './SidebarToggles';

import styles from './SidebarComponent.module.scss';

const SidebarComponent = () => {
  const dispatch = useDispatch();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const userIsAdmin = useSelector(selectUserIsAdmin);
  const document = useSelector(getDocumentObject);
  const user = useSelector(selectUser);
  const versionHistory = useSelector((state) => state.editor.status.versionHistory);
  const sidebar = useSelector((state) => state.editor.sidebar);

  useEffect(() => {
    if (isReadOnlyMode && !isPageLayout) {
      dispatch(setSidebarView(null));
    }
  }, [isReadOnlyMode, isPageLayout]);

  useEffect(() => {
    switch (sidebar.view) {
      case 'APPROVE_CONTENT':
        EditorManager.getInstance().changeEditorMode(EditorManager.RENDER_MODES.APPROVALS);
        break;
      case 'CONTENT_PERMISSIONS':
        EditorManager.getInstance().changeEditorMode(EditorManager.RENDER_MODES.PERMISSIONS);
        break;
      default:
        const manager = EditorManager.getInstance();
        if (manager.renderMode !== EditorManager.RENDER_MODES.BASIC) {
          manager.changeEditorMode(EditorManager.RENDER_MODES.BASIC);
        }
        break;
    }
  }, [sidebar.view]);

  useEffect(() => {
    return () => {
      const manager = EditorManager.getInstance();
      if (manager.renderMode !== EditorManager.RENDER_MODES.BASIC) {
        manager.changeEditorMode(EditorManager.RENDER_MODES.BASIC);
      }
    };
  }, []);

  const renderView = () => {
    switch (sidebar.view) {
      case 'APPROVE_CONTENT':
        return <ApproveContentTab />;
      case 'CONTENT_PERMISSIONS':
        return <ContentPermissionsTab />;
      case 'REVIEW':
        return <ReviewTab />;
      case 'REFERENCES':
        return <ReferencesTab />;
      case 'NOTES':
        return <NotesTab />;
      case 'CROSS_REFERENCES':
        return <CrossReferencesTab />;
      case 'NAVIGATION':
        return <NavigationTab />;
      case 'VERSIONS':
        return versionHistory &&
          document &&
          (document.owners.includes(user) ||
            document.user_permissions.includes('owner') ||
            userIsAdmin) &&
          document.lifecycle.versions.length > 0 ? (
          <VersionsTab />
        ) : null;
      case 'SPELL_CHECK':
        return <SpellCheckTab />;
      case 'TASKS':
        return <TasksTab />;
      case 'DOCUMENT_STYLES':
        return <DocumentStylesTab />;
      case 'FIND_AND_REPLACE':
        return <FindAndReplaceTab />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.root}>
      <InteractionController environment="editor">
        {sidebar.view && renderView()}
        <div className={styles.toggles}>
          <IntlErrorBoundary size="xsmall" tooltipPlacement="left" margin="auto" collapsed>
            <SidebarToggles />
          </IntlErrorBoundary>
        </div>
      </InteractionController>
    </div>
  );
};

export default SidebarComponent;
