import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useSelector } from '_common/hooks/redux';
import { selectShareModalValues } from 'App/redux/appSlice';

import { SearchUser } from '_common/components';
import type { UserOption } from '_common/components/SearchUser/SearchUser';

import RoleSelect from './RoleSelect';

import styles from './ShareModal.module.scss';
import { useAddRolesMutation, useGetObjectQuery } from 'App/redux/objectApi';

type ShareContainerProps = {
  applyToDescendants: boolean;
  isDisabled: boolean;
  roles: any[];
};

const ShareContainer = ({ applyToDescendants, isDisabled, roles }: ShareContainerProps) => {
  const intl = useIntl();
  const [users, setUsers] = useState<UserOption[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<UserOption[]>([]);
  const { usersList, groupsList } = useSelector(selectShareModalValues);
  const objectId = useSelector((state) => state.modals.ShareModal.objectId);
  const objectType = useSelector((state) => state.modals.ShareModal.objectType);
  const [addRoles] = useAddRolesMutation();
  const { data: object, refetch } = useGetObjectQuery(
    { objectId, objectType },
    { skip: !objectId && !objectType },
  );
  const valuesToFilter = useMemo(() => [...usersList, ...groupsList], [usersList, groupsList]);

  useEffect(() => {
    refetch();
  }, [users]);

  const share = () => {
    if (users.length > 0) {
      users.forEach((user) => {
        addRoles({
          objectId: object?.id ?? objectId,
          objectType: object?.type ?? objectType,
          params: {
            [user.type]: user.value,
            roles: selectedRoles.map((role) => role.value),
            recursive: applyToDescendants,
          },
        });
      });
      setUsers([]);
      setSelectedRoles([]);
    }
  };

  return (
    <div className={styles.shareContainer}>
      <div style={{ marginRight: '1rem' }}>
        <SearchUser
          value={users}
          valuesToFilter={valuesToFilter}
          onChange={setUsers}
          both
          size="large"
          disabled={isDisabled}
          menuPosition="fixed"
          width="35rem"
          placeholder={intl.formatMessage({ id: 'NAME_EMAIL_OR_GROUP' })}
          isMulti
          defaultOptions={users}
          searchable
          testId="share-modal"
        />
      </div>
      <RoleSelect
        width="22rem"
        size="large"
        value={selectedRoles}
        onChange={setSelectedRoles}
        options={roles}
        disabled={isDisabled}
        placeholder={intl.formatMessage({ id: 'SELECT_ROLE' })}
        testId="share-modal-role"
      />
      <Button
        margin="0 0 0 2rem"
        variant="primary"
        size="large"
        onClick={share}
        disabled={selectedRoles.length === 0 || users.length === 0}
        fullWidth
        style={{ maxWidth: '12rem' }}
        testId="share-modal-share-button"
      >
        <FormattedMessage id="storage.modals.share.title" />
      </Button>
    </div>
  );
};

export default ShareContainer;
