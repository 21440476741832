import { useEffect, useState } from 'react';
import { Input } from 'dodoc-design-system';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { setFindData } from 'PDF/redux/PDFFindSlice';
import { usePDFContext } from 'PDF/PDFContext';

const Search = () => {
  const intl = useIntl();
  const manager = usePDFContext();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const find = useSelector((state) => state.pdf.find);

  useEffect(() => {
    if (inputValue === '') {
      dispatch(setFindData({ ...find, selected: null }));
      if (manager.isFindRunning()) {
        manager.resetFind();
      }
    }
  }, [inputValue]);

  useEffect(() => {
    if (inputValue) {
      search(inputValue);
    }
  }, [find?.matchCase, find?.findWholeWords]);

  const search = (inputValue: string) => {
    manager.find({
      caseSensitive: find?.matchCase,
      entireWord: find?.findWholeWords,
      query: inputValue,
    });
  };

  return (
    <Input
      placeholder={intl.formatMessage({ id: 'SEARCH_TEXT' })}
      size="medium"
      prefix="SearchGrey"
      width="22.25rem"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onEnterKey={() => search(inputValue)}
      onPrefixClick={() => search(inputValue)}
      testId="find-text"
    />
  );
};

export default Search;
