import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import usePDFCollaborators from 'PDF/hooks/usePDFCollaborators';
import { DEFAULT_CARD_STYLES_PANEL } from 'PDF/redux/PDFAnnotationsSlice';
import { RichTextEditor } from '_common/components';
import { RichTextEditorProps } from '_common/components/RichTextEditor/RichTextEditor';

import styles from './Body.module.scss';

type BodyProps = {
  annotation: PDF.Annotation;
  newContent: string | undefined;
  onChange: Exclude<RichTextEditorProps['onChange'], undefined>;
  testId: string;
};

const Body = ({ annotation, newContent, onChange, testId }: BodyProps) => {
  const intl = useIntl();

  const collaborators = usePDFCollaborators();

  const initialValue = useMemo(() => {
    if (newContent) {
      return newContent;
    }
    if (annotation.content && annotation.content.content.length > 0) {
      return JSON.stringify(annotation.content.content);
    }
  }, [annotation, newContent]);

  return (
    <div className={styles.root}>
      <RichTextEditor
        initialValue={initialValue}
        onChange={onChange}
        placeholder={intl.formatMessage({ id: 'storage.modals.approve.placeholder' })}
        mentionableUsers={annotation.subtype === 'FreeText' ? undefined : collaborators}
        overwrittenStyles={DEFAULT_CARD_STYLES_PANEL}
        testId={`${testId}-rich-text-editor`}
      />
    </div>
  );
};

export default Body;
