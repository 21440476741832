import { BlockViewModel } from './BlockViewModel';
import { BaseViewModel } from './BaseViewModel';
import { SectionViewModel } from './SectionViewModel';
import { ListViewModel } from './ListViewModel';
import { ParagraphStyleViewModel } from './ParagraphStyleViewModel';
import { PageViewModel } from './PageViewModel';
import { NoteViewModel } from './NoteViewModel';
import { SelectionViewModel } from './SelectionViewModel';
import { HeaderViewModel } from './HeaderViewModel';
import { FooterViewModel } from './FooterViewModel';

export class ViewModelFactory {
  private Data: Editor.Data.API;
  private Visualizer: Editor.Visualizer.State;
  private cache: { [index: string]: BaseViewModel } = {};

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State) {
    this.Data = Data;
    this.Visualizer = Visualizer;
  }

  dispose(id: string) {
    if (this.cache[id]) {
      delete this.cache[id];
    }
  }

  get(id: string, noCache?: boolean) {
    if (noCache) {
      return new BlockViewModel(this.Data, this.Visualizer, id);
    }
    if (!this.cache[id]) {
      this.cache[id] = new BlockViewModel(this.Data, this.Visualizer, id);
    }
    return this.cache[id];
    // return new BlockViewModel(this.Data, this.Visualizer, id);
  }

  getSection(id: string) {
    if (!this.cache[id]) {
      this.cache[id] = new SectionViewModel(this.Data, this.Visualizer, id);
    }
    return this.cache[id] as SectionViewModel;
  }

  getList(id: string) {
    if (!this.cache[id]) {
      this.cache[id] = new ListViewModel(this.Data, this.Visualizer, id);
    }
    return this.cache[id] as ListViewModel;
  }

  getParagraph(id: string) {
    if (!this.cache[id]) {
      this.cache[id] = new ParagraphStyleViewModel(this.Data, this.Visualizer, id);
    }
    return this.cache[id] as ParagraphStyleViewModel;
  }

  getPage(id?: string, sectionId?: string) {
    return new PageViewModel(this.Data, this.Visualizer, sectionId);
  }

  getPageHeader(sectionId: string) {
    return new HeaderViewModel(this.Data, this.Visualizer, sectionId);
  }

  getPageFooter(sectionId: string) {
    return new FooterViewModel(this.Data, this.Visualizer, sectionId);
  }

  getNote(id: string) {
    if (!this.cache[id]) {
      this.cache[id] = new NoteViewModel(this.Data, this.Visualizer, id);
    }
    return this.cache[id] as NoteViewModel;
  }

  getSelection(id: string) {
    if (!this.cache[id]) {
      this.cache[id] = new SelectionViewModel(this.Data, this.Visualizer, id);
    }

    return this.cache[id] as SelectionViewModel;
  }

  destroy() {
    let vms = Object.keys(this.cache);
    for (let index = 0; index < vms.length; index++) {
      this.cache[vms[index]].dispose();
      delete this.cache[vms[index]];
    }
  }
}
