import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Input, SectionHeading } from 'dodoc-design-system';

import { FilterPopover } from '_common/components';
import {
  useGetReferenceStylesListQuery,
  useInstallAllReferenceStylesMutation,
} from '../ReferenceStylesApi';

type ReferenceStylesHeadingProps = {
  category: Template['category'];
  search: string;
  onSearchChange: (search: string) => void;
  testId: string;
};

const ReferenceStylesHeading = ({
  category,
  search,
  onSearchChange,
  testId,
}: ReferenceStylesHeadingProps) => {
  const intl = useIntl();

  const [installAll] = useInstallAllReferenceStylesMutation();

  const { data } = useGetReferenceStylesListQuery();
  const { outdated, not_installed } = useMemo(
    () => ({
      outdated: data
        ? Object.values(data.extensions)
            .filter((template) => template.status === 'outdated')
            .map((template) => template.id)
        : [],
      not_installed: data
        ? Object.values(data.extensions)
            .filter((template) => template.status === 'not_installed')
            .map((template) => template.id)
        : [],
    }),
    [data],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onSearchChange(e.target.value);

  const handleInstallAll = () => {
    installAll({ idList: not_installed });
  };

  const handleUpdateAll = () => {
    installAll({ idList: outdated, updating: true });
  };

  return (
    <SectionHeading
      sticky
      title={category.slice(0, 1).toUpperCase() + category.slice(1)}
      buttonRightProps={{
        size: 'medium',
        children: intl.formatMessage({ id: 'INSTALL_ALL' }),
        onClick: handleInstallAll,
        disabled: not_installed.length === 0,
        testId: 'install-all-button'
      }}
      buttonLeftProps={{
        size: 'medium',
        children: intl.formatMessage({ id: 'UPDATE_ALL' }),
        onClick: handleUpdateAll,
        disabled: outdated.length === 0,
        testId: 'update-all-button'
      }}
      search={
        <Input
          prefix="NavSearchGrey"
          size="medium"
          placeholder={intl.formatMessage({ id: 'global.search' })}
          value={search}
          onChange={handleSearchChange}
          testId="references-styles-search"
        />
      }
      searchFilters={
        <FilterPopover
          identity={`referenceStyles-${category}`}
          templateStatus={{
            options: [
              { value: 'not_installed', label: 'NOT_INSTALLED' },
              { value: 'installed', label: 'INSTALLED' },
              { value: 'outdated', label: 'OUTDATED' },
            ],
          }}
        />
      }
      testId={testId}
    />
  );
};

export default ReferenceStylesHeading;
