import { ChangeEventHandler, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import { Button, Input, Logo, Popover, Tooltip, usePopper } from 'dodoc-design-system';

import { navigateToMyFiles } from 'router/history';
import { useSelector, useDispatch } from '_common/hooks';
import { renameObject, selectIsIEnvision } from 'App/redux/appSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { setPulseData } from 'App/redux/onboardingSlice';

import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import usePDFData from 'PDF/hooks/usePDFData';

import {
  InteractionController,
  NotificationsCenter,
  Status,
  UserPopover,
} from '_common/components';
import { EditorAvatar } from 'Editor/components';
import AvatarList from 'Editor/components/AvatarList/AvatarList';

import styles from './Title.module.scss';

const Title = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const object = usePDFData();
  const usersOnline = useSelector((state) => state.pdf.general.usersOnline);
  const isIEnvision = useSelector(selectIsIEnvision);
  const userId = useSelector((state) => state.auth.userId);
  const { referenceProps, popperProps, close } = usePopper({
    placement: 'bottom-end',
    disabled: isIEnvision,
  });
  const { canShare, canChangeStatus } = usePDFPermissions();
  const [hover, setHover] = useState(false);
  const [inputWidth, setInputWidth] = useState<number | undefined>(undefined);
  const [nameWidth, setNameWidth] = useState<number | undefined>(undefined);

  const [renaming, setRenaming] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLDivElement>(null);
  const [validations, setValidations] = useState<{ name?: string }>({});
  const [name, setName] = useState(object?.name);
  const canRename =
    object?.user_permissions.includes('owner') || object?.user_permissions.includes('admin');

  const statusLabelRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const length = object?.name.length;
    if (length && length > 0 && inputRef) {
      if (inputRef.current) {
        if (inputRef.current.scrollWidth > inputRef.current.clientWidth) {
          setInputWidth(inputRef.current.scrollWidth);
          return;
        }
      }
      if (nameRef?.current) {
        if (nameRef.current.scrollWidth > nameRef.current.clientWidth) {
          setNameWidth(nameRef.current.scrollWidth);
        } else {
          const width = nameRef.current.clientWidth > 120 ? undefined : nameRef.current.clientWidth;
          setNameWidth(width);
        }

        return;
      }
    }
  }, [object?.name, renaming]);

  useEffect(() => {
    if (nameRef?.current && object?.name) {
      setInputWidth(nameRef.current.scrollWidth - object.name.length * 8);
    }
  }, [object?.name]);

  useEffect(() => {
    if (statusLabelRef?.current) {
      dispatch(
        setPulseData({
          statusLabelRect: {
            top: statusLabelRef.current.offsetTop,
            left: statusLabelRef.current.offsetLeft,
            height: statusLabelRef.current.offsetHeight,
            width: statusLabelRef.current.offsetWidth,
          },
        }),
      );
    }
  }, [object?.name, nameWidth]);

  const handleShare = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ShareModal',
        data: {
          view: 'users',
          objectId: object?.id,
          objectType: object?.type,
          editor: true,
        },
      }),
    );
  };

  const handleChangeStatus = () => {
    if (canChangeStatus) {
      dispatch(
        openAndUpdateModal({
          modal: 'ChangeElementStatusModal',
          data: {
            objectId: object?.id,
            objectType: object?.type,
          },
        }),
      );
    }
  };

  const handleExport = () => {
    if (object) {
      dispatch(
        openAndUpdateModal({
          modal: 'ExportPDFModal',
          data: {
            id: object?.id,
          },
        }),
      );
    }
  };

  const handleLogoClick = () => {
    if (!isIEnvision) {
      navigateToMyFiles();
    }
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const inputName = e.target.value;
    setName(inputName);
    const substring = /[<>"\\:*?/|]/;
    const newValidations: typeof validations = {};

    if (inputName.length === 0) {
      newValidations.name = intl.formatMessage({
        id: 'validation.name.insertName',
      });
    } else if (substring.test(inputName)) {
      newValidations.name = intl.formatMessage({ id: 'RENAME_FILE_INPUT_ERROR' });
    } else if (inputName.length > 146) {
      newValidations.name = intl.formatMessage({ id: 'DOCUMENT_TITLE_LIMIT_CHARACTER' });
    }

    if (Object.keys(newValidations).length > 0) {
      setValidations({ ...newValidations });
    } else {
      setValidations({});
    }
  };

  const handleRename = () => {
    if (Object.keys(validations).length > 0) {
      setRenaming(false);
      setValidations({});
      setName(object?.name);
      setHover(false);
    } else {
      if (name !== object?.name) {
        dispatch(
          renameObject({
            objectId: object?.id ?? '',
            objectType: object?.type ?? 'dopdf',
            newName: name ?? '',
          }),
        );
      }
      setRenaming(false);
      setValidations({});
      setHover(false);
    }
  };

  const onMouseOver = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  const handleOpenRenamePdf = () => {
    if (canRename) {
      setRenaming(true);
    }
  };

  return (
    <InteractionController environment="dopdf" style={{ width: '100%', alignItems: 'center' }}>
      <Tooltip
        content={intl.formatMessage({ id: 'GO_BACK_EXPLORER' })}
        placement="bottom"
        disabled={isIEnvision}
        testId="go-to-tooltip"
      >
        <span
          onClick={handleLogoClick}
          className={cx(styles.logo, { [styles.clickableLogo]: !isIEnvision })}
        >
          <Logo color="red" testId="pdf-title-logo" />
        </span>
      </Tooltip>
      <div
        className={`${canRename && renaming ? styles.input : styles.text}`}
        onClick={handleOpenRenamePdf}
        data-testid="name"
      >
        {renaming ? (
          <Tooltip
            content={validations.name || ''}
            visible={validations.name !== undefined ?? false}
            testId="rename-validations-tooltip"
          >
            <div
              style={{
                width: `${inputWidth}px`,
                maxWidth: '75rem',
              }}
            >
              <Input
                name="name"
                ref={inputRef}
                value={name}
                onChange={handleOnChange}
                onEnterKey={handleRename}
                onBlur={handleRename}
                error={!!validations.name}
                size="medium"
                placeholder=""
                clearable={false}
                testId="title-name"
              />
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            content={intl.formatMessage({ id: 'RENAME_FILE' })}
            visible={canRename && hover === true}
            testId="rename-tooltip"
          >
            <div
              ref={nameRef}
              className={`${styles.name} ${(nameWidth ?? 0) > 600 && styles.textOverflow}`}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
              style={{
                width: nameWidth != null ? `${nameWidth}px` : undefined,
                maxWidth: '75rem',
                whiteSpace: 'nowrap',
              }}
            >
              {object?.name}
            </div>
          </Tooltip>
        )}
      </div>
      {!isIEnvision && (
        <Tooltip
          content={intl.formatMessage({ id: 'CLICK_TO_CHANGE_PDF_STATUS' })}
          disabled={!canChangeStatus}
          placement="bottom"
          testId="change-status-tooltip"
        >
          <div
            style={{ cursor: canChangeStatus ? 'pointer' : 'auto' }}
            onClick={handleChangeStatus}
            data-testid="pdf-status-value"
            ref={statusLabelRef}
          >
            <Status id={object?.status || 'draft'} size="small" testId="pdf-status-label" />
          </div>
        </Tooltip>
      )}
      <div className={styles.avatars}>
        <AvatarList users={usersOnline} />
      </div>
      {!isIEnvision && (
        <Button
          variant="primary"
          size="medium"
          margin="0 0 0 1rem"
          disabled={!canShare}
          onClick={handleShare}
          testId="pdf-share-button"
        >
          <FormattedMessage id="storage.actionBar.actions.share" />
        </Button>
      )}
      <Tooltip
        content={intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })}
        placement="left"
        disabled={!isIEnvision}
        testId="export-no-permission-tooltip"
      >
        <Button
          variant="neutral"
          size="medium"
          margin="0 0 0 1rem"
          onClick={handleExport}
          disabled={isIEnvision}
          testId="pdf-export-button"
        >
          <FormattedMessage id="global.export" />
        </Button>
      </Tooltip>

      <NotificationsCenter margin="0 0 0 2rem" testId="pdf-notifications" />

      <div
        {...referenceProps}
        style={{ cursor: isIEnvision ? 'auto' : 'pointer' }}
        data-testid="user-popover-toggle"
      >
        <EditorAvatar margin="0 0 0 1rem" userId={userId} />
      </div>
      <Popover {...popperProps} testId="user-profile-popper">
        <UserPopover toggle={close} />
      </Popover>
    </InteractionController>
  );
};

export default Title;
