import { Toggle, Divider, Dropdown, usePopper } from 'dodoc-design-system';
import { usePDFDataEvents } from 'PDF/PDFContext';
import {
  setSelectedInstance,
  toggleFindMatchCaseOption,
  toggleFindEntireWordOption,
} from 'PDF/redux/PDFFindSlice';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from '_common/hooks';
import styles from './FindInstances.module.scss';

const Instances = () => {
  const dispatch = useDispatch();
  const currentPageNumber = useSelector((state) => state.pdf.general.currentPageNumber);
  const matchCaseOption = useSelector((state) => state.pdf.find?.matchCase);
  const entireWordOption = useSelector((state) => state.pdf.find?.findWholeWords);
  const selectedInstance = useSelector((state) => state.pdf.find.selected);
  const moreActions = usePopper({ placement: 'bottom-start' });
  const [findResult, setFindResult] = useState<PDF.Find.CycleResultType | null>(null);
  const [hitIndex, setHitIndex] = useState<number>(
    selectedInstance && findResult ? findResult.list.indexOf(selectedInstance) : 1,
  );

  usePDFDataEvents('LOAD_FIND_HITS', setFindResult);

  useEffect(() => {
    if (findResult) {
      const instanceIndex = findResult.list.findIndex(
        (id) => findResult?.data[id]?.numPage >= currentPageNumber,
      );
      let findInstance;
      if (instanceIndex >= 0) {
        findInstance = findResult?.list[instanceIndex];
      }
      if (findInstance) {
        setHitIndex(instanceIndex);
        dispatch(setSelectedInstance(findInstance));
      }
    }
  }, [findResult, findResult?.list]);

  useEffect(() => {
    if (
      selectedInstance &&
      findResult?.data[selectedInstance]?.numPage !== undefined &&
      findResult?.data[selectedInstance]?.numPage !== currentPageNumber
    ) {
      pdfManager?.navigation?.goTo(findResult?.data[selectedInstance]?.numPage);
    }
  }, [selectedInstance]);

  const next = () => {
    if (findResult) {
      let newIndex = (hitIndex + 1) % findResult?.list.length;
      setHitIndex(newIndex);
      dispatch(setSelectedInstance(findResult?.list[newIndex]));
    }
  };

  const previous = () => {
    if (findResult) {
      let newIndex = (hitIndex + findResult.list.length - 1) % findResult.list.length;
      setHitIndex(newIndex);
      dispatch(setSelectedInstance(findResult.list[newIndex]));
    }
  };

  if (!findResult) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        {findResult && findResult?.total > 0 ? (
          <FormattedMessage
            id="NUMBER_OF_INSTANCES"
            values={{ current: hitIndex + 1, total: findResult?.total }}
          />
        ) : (
          'No matches found'
        )}
      </div>
      <div className={styles.toggles}>
        <Toggle
          size="medium"
          variant="ghost"
          icon="AngleDownGrey"
          onClick={next}
          disabled={findResult?.total === 0}
          testId="next-instance"
        />
        <Toggle
          size="medium"
          variant="ghost"
          icon="AngleUpGrey"
          onClick={previous}
          disabled={findResult?.total === 0}
          testId="previous-instance"
        />
      </div>
      <Divider vertical />
      <Toggle
        size="medium"
        variant="ghost"
        icon="Options"
        margin="0 1rem 0 0.75rem"
        isToggled={moreActions.isOpen}
        disabled={findResult?.total === 0}
        testId="more-options"
        {...moreActions.referenceProps}
      />
      <Dropdown {...moreActions.popperProps} testId="more-options-dropdown">
        <Dropdown.Item
          onClick={() => dispatch(toggleFindMatchCaseOption())}
          prefixIcon={matchCaseOption ? 'ResolveComment' : undefined}
          testId="match-case-dropdown-item"
        >
          <FormattedMessage id="MATCH_CASE" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => dispatch(toggleFindEntireWordOption())}
          prefixIcon={entireWordOption ? 'ResolveComment' : undefined}
          testId="find-whole-word-dropdown-item"
        >
          <FormattedMessage id="FIND_WHOLE_WORDS" />
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
};

export default Instances;
