import { InteractionController, IntlErrorBoundary } from '_common/components';

import Language from './Language/Language';
import Zoom from './Zoom/Zoom';
import Divider from './Divider/Divider';
import Layout from './Layout/Layout';
import WordCount from './WordCount/WordCount';

import styles from './FooterComponent.module.scss';

const FooterComponent = () => {
  return (
    <div className={styles.root} data-testid="editor-footer">
      <IntlErrorBoundary size="xsmall" margin="auto">
        <div className={styles.labels}>
          <InteractionController environment="editor">
            <WordCount />
            <Language />
          </InteractionController>
        </div>
        <div className={styles.actions}>
          <InteractionController environment="editor">
            <Layout />
            <Divider />
          </InteractionController>
          <Zoom />
        </div>
      </IntlErrorBoundary>
    </div>
  );
};

export default FooterComponent;
