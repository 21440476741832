import { useDispatch, useSelector } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { Tooltip, Divider } from 'dodoc-design-system';
import { EditorIconButton, Versions } from 'Editor/components';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';
import { updateMiniExplorer } from '_common/components/MiniExplorer/miniExplorerSlice';
import styles from './VersionHistoryToolbar.module.scss';
import { useIntl } from 'react-intl';
import EditorManager from 'Editor/services/EditorManager';

const VersionHistoryToolbar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const loadedVersion = useSelector((state) => state.editor.status.loadedVersion);
  const document = useSelector(getDocumentObject);

  const saveAs = () => {
    const current: Pick<Objekt, 'id' | 'type'> = document.parent
      ? {
          id: document.parent,
          type: 'folder',
        }
      : {
          id: document.space,
          type: 'space',
        };

    dispatch(updateMiniExplorer({ current }));
    dispatch(
      openAndUpdateModal({
        modal: 'SaveAsModal',
        data: {
          current,
          objectIds: [document.id],
        },
      }),
    );
  };

  const editDescription = () => {
    if (loadedVersion !== undefined) {
      dispatch(
        openAndUpdateModal({
          modal: 'SaveVersionModal',
          data: {
            type: 'editDescription',
            index: loadedVersion,
            description: document.lifecycle.versions[loadedVersion].description ?? '',
          },
        }),
      );
    }
  };

  const restoreVersion = () => {
    if (loadedVersion !== undefined) {
      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            title: 'RESTORE_DOCUMENT_VERSION',
            message: 'ARE_YOU_SURE_YOU_WANT_TO_RESTORE_THIS_DOCUMENT_VERSION',
            messageValues: { versionNumber: loadedVersion + 1 },
            confirmButtonTextId: 'RESTORE_VERSION',
            confirmButtonType: 'primary',
            cancelButtonTextId: 'global.cancel',
            actionCode: 'restoreDocumentVersion',
            actionValue: { loadedVersion },
            headerType: 'information',
            cancelButtonShow: true,
            width: '60rem',
          },
        }),
      );
    }
  };

  return (
    <div className={styles.root}>
      <Versions
        versions={document?.lifecycle.versions}
        loadedVersion={loadedVersion}
        loadVersion={EditorManager.getInstance().loadVersion}
        testId="versions"
      />
      <Divider vertical margin="0 0.5rem" />
      <Tooltip
        content={intl.formatMessage({ id: 'RESTORE_VERSION' })}
        testId="version-history-toolbar-restore-version-tooltip"
      >
        <EditorIconButton
          icon="RestoreGrey"
          onClick={restoreVersion}
          disabled={loadedVersion === undefined}
          testId="version-history-toolbar-restore-version-toggle"
        />
      </Tooltip>
      <Tooltip
        content={intl.formatMessage({ id: 'SAVE_A_COPY' })}
        testId="version-history-toolbar-save-a-copy-tooltip"
      >
        <EditorIconButton
          icon="SaveaCopy"
          onClick={saveAs}
          disabled={loadedVersion === undefined}
          testId="version-history-toolbar-save-a-copy-toggle"
        />
      </Tooltip>
      <Tooltip
        content={intl.formatMessage({ id: 'EDIT_DESCRIPTION' })}
        testId="version-history-toolbar-edit-description-tooltip"
      >
        <EditorIconButton
          icon="EditDescription"
          onClick={editDescription}
          disabled={loadedVersion === undefined}
          testId="version-history-toolbar-edit-description-toggle"
        />
      </Tooltip>
    </div>
  );
};

export default VersionHistoryToolbar;
