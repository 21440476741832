import cx from 'classnames';
import { Icon, Popover, SplitToggle, Tooltip, usePopper } from 'dodoc-design-system';
import { useDispatch, useSelector } from '_common/hooks';
import { setCursorMode } from 'PDF/redux/PDFGeneralSlice';
import styles from './Toggles.module.scss';
import { setCreationSetting } from 'PDF/redux/PDFAnnotationsSlice';
import { FormattedMessage, useIntl } from 'react-intl';

export const SHAPES = ['Line', 'Arrow', 'Circle', 'Square'] as const;

const ShapesToggle = ({ disabled }: { disabled: boolean }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { referenceProps, popperProps, isOpen, close } = usePopper();
  const cursorMode = useSelector((state) => state.pdf.general.cursorMode);
  const roiShape = useSelector((state) => state.pdf.annotations.creation.shape);

  const setShape = (shape: (typeof SHAPES)[number]) => {
    dispatch(
      setCreationSetting({
        shape,
      }),
    );
    dispatch(setCursorMode(shape));
    close();
  };

  const isToggled =
    cursorMode === 'Line' ||
    cursorMode === 'Arrow' ||
    cursorMode === 'Circle' ||
    cursorMode === 'Square';

  const handleCursorMode = () => {
    dispatch(setCursorMode(isToggled ? 'normal' : roiShape));
  };

  return (
    <>
      <Tooltip
        disabled={isOpen}
        content={
          disabled
            ? intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })
            : intl.formatMessage({ id: 'SHAPES' })
        }
        placement="bottom"
        testId="shape-split-tooltip"
      >
        <SplitToggle
          margin="0 0.5rem 0 0"
          isToggled={isToggled}
          onClick={handleCursorMode}
          icon={isToggled ? 'ShapeBlue' : 'ShapeGrey'}
          disabled={disabled}
          testId="shape-split"
          {...referenceProps}
          isOpen={isOpen}
          onPopperClick={referenceProps.onClickCapture}
        />
      </Tooltip>
      <Popover {...popperProps} testId="shapes-popper">
        <div className={styles.popover}>
          {SHAPES.map((shape) => (
            <div
              key={shape}
              className={cx(styles.item, { [styles.isSelected]: roiShape === shape })}
              onClick={() => setShape(shape)}
            >
              <span className={styles.selection}>
                <Icon size={16} icon="Check" />
              </span>
              <Icon icon={shape} size={24} margin="0 0.5rem 0 0" />
              <span className={styles.text}>
                <FormattedMessage id={shape.toUpperCase()} />
              </span>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default ShapesToggle;
