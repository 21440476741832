import { useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { OnboardingSlides, OnboardingCards } from 'dodoc-design-system';

import { navigateToEditor } from 'router/history';
import { useDispatch, useSelector } from '_common/hooks';
import { InstanceService } from '_common/services';
import { stopOnboarding } from 'App/redux/onboardingSlice';
import { setInfoPanelOpenValue } from 'Storage/pages/StoragePage/StoragePageSlice';
import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';

import image1 from 'assets/images/image1.png';
import image2 from 'assets/images/image2.png';
import image3 from 'assets/images/image3.png';
import image4 from 'assets/images/image4.png';
import image5 from 'assets/images/image5.png';
import image6 from 'assets/images/image6.png';
import image7 from 'assets/images/image7.png';
import image8 from 'assets/images/image8.png';

const OnboardingExplorer = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sendOnboardingStatus] = useOnboardingStatusMutation();
  const { data: currentUser } = useGetCurrentUserQuery();
  const active = useSelector((state) => state.onboarding.active.explorer);
  const [wizard, setWizard] = useState(true);

  useEffect(() => {
    if (active) {
      dispatch(setInfoPanelOpenValue(false));
    }
  }, [active]);

  const onboardingSlides = useMemo(
    () => [
      {
        title: intl.formatMessage(
          {
            id: 'ONBOARDING_SLIDE_1_TITLE',
          },
          { name: currentUser?.profile.name },
        ),
        content: intl.formatMessage({ id: 'ONBOARDING_SLIDE_1_CONTENT' }),
        img: image1,
      },
      {
        title: intl.formatMessage({ id: 'ONBOARDING_SLIDE_2_TITLE' }),
        content: intl.formatMessage({ id: 'ONBOARDING_SLIDE_2_CONTENT' }),
        img: image2,
      },
      {
        title: intl.formatMessage({ id: 'ONBOARDING_SLIDE_3_TITLE' }),
        content: intl.formatMessage({ id: 'ONBOARDING_SLIDE_3_CONTENT' }),
        img: image3,
      },
      {
        title: intl.formatMessage({ id: 'ONBOARDING_SLIDE_4_TITLE' }),
        content: intl.formatMessage({ id: 'ONBOARDING_SLIDE_4_CONTENT' }),
        img: image4,
      },
      {
        title: intl.formatMessage({ id: 'ONBOARDING_SLIDE_5_TITLE' }),
        content: intl.formatMessage({ id: 'ONBOARDING_SLIDE_5_CONTENT' }),
        img: image5,
      },
      {
        title: intl.formatMessage({ id: 'ONBOARDING_SLIDE_6_TITLE' }),
        content: intl.formatMessage({ id: 'ONBOARDING_SLIDE_6_CONTENT' }),
        img: image6,
      },
      {
        title: intl.formatMessage({ id: 'ONBOARDING_SLIDE_7_TITLE' }),
        content: intl.formatMessage({ id: 'ONBOARDING_SLIDE_7_CONTENT' }),
        img: image7,
      },
      {
        title: intl.formatMessage({ id: 'ONBOARDING_SLIDE_8_TITLE' }),
        content: intl.formatMessage({ id: 'ONBOARDING_SLIDE_8_CONTENT' }),
        img: image8,
      },
    ],
    [currentUser],
  );

  const onboardingCards = useMemo(
    () => [
      {
        position: {
          right: '5rem',
          bottom: '10rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '5rem',
          bottom: '10rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_2_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_2_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          left: '39rem',
          bottom: '10rem',
        },
        pulse: {
          right: '52.5rem',
          top: '0rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_3_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_3_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          top: '13rem',
          right: '5rem',
        },
        pulse: {
          bottom: '30.5rem',
          left: '37rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_4_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_4_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '5rem',
          bottom: '10rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_5_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_5_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
    ],
    [],
  );

  const skipProps = useMemo(() => {
    return {
      skipPreviousLabel: intl.formatMessage({ id: 'global.cancel' }),
      skipFinishLabel: intl.formatMessage({ id: 'SKIP' }),
      skipHeader: intl.formatMessage({ id: 'ONBOARDING_SKIP_CARD_HEADER' }),
      skipContent: intl.formatMessage({ id: 'ONBOARDING_SKIP_CARD_CONTENT' }),
      skipPosition: {
        right: '5rem',
        bottom: '10rem',
      },
    };
  }, [intl]);

  const handleGoToDodoc = () => {
    setWizard(false);
  };

  const handleGoToEditor = () => {
    new InstanceService().createObject({ name: 'Onboarding' }, 'document').then((response) => {
      //@ts-expect-error TODO: Improve generic endpoint type
      navigateToEditor(response.data.id);
      dispatch(stopOnboarding('explorer'));
    });
  };

  const handleOnCloseOnboardingCards = () => {
    sendOnboardingStatus({ target: 'explorer', step: 'ending', skip: true });
  };

  if (!active) {
    return null;
  }

  return wizard ? (
    <OnboardingSlides
      slides={onboardingSlides}
      onClose={handleGoToDodoc}
      onExit={handleGoToDodoc}
      startLabel={intl.formatMessage({ id: 'GO_TO_DODOC' })}
      testId="explorer"
    />
  ) : (
    <OnboardingCards
      cards={onboardingCards}
      finishLabel={intl.formatMessage({ id: 'OPEN_EDITOR' })}
      onExit={handleOnCloseOnboardingCards}
      onFinalExit={handleGoToEditor}
      onCloseTooltipContent={intl.formatMessage({ id: 'SKIP_ONBOARDING' })}
      skipOnCloseTooltipContent={intl.formatMessage({ id: 'CONTINUE_ONBOARDING' })}
      {...skipProps}
      testId="explorer"
    />
  );
};

export default OnboardingExplorer;
