import { ReadyState, ViewModelChildren } from '../../utils';
import { NotesList } from 'Editor/services/DataManager/controllers/Notes/NotesList';
import { IndexerDeltaType } from 'Editor/services/DataManager/controllers/Models/ModelIndexer';
import { NoteViewModel } from '../../ViewModels';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';

export class NotesManager {
  private readyState?: ReadyState | null;
  private Data: Editor.Data.API;
  private Visualizer: Editor.Visualizer.State;
  private notesList?: NotesList;
  private children: ViewModelChildren<NoteViewModel>;

  constructor(dataManager: Editor.Data.API, Visualizer: Editor.Visualizer.State) {
    this.Data = dataManager;
    this.Visualizer = Visualizer;
    this.children = new ViewModelChildren<NoteViewModel>();
    this.handleNotesDelta = this.handleNotesDelta.bind(this);
    this.handleLoadNotes = this.handleLoadNotes.bind(this);
    this.handleNoteUpdate = this.handleNoteUpdate.bind(this);
  }

  start() {
    this.Data.events.on('LOAD_NOTES_DATA', this.handleLoadNotes);
    this.Data.events.on('UPDATE_NOTE', this.handleNoteUpdate);
  }

  private handleNoteUpdate(data: Required<Notes.NoteData>) {
    const renderedFootnotes = document.querySelectorAll(
      `note-element[element-reference="${data.id}"]`,
    );
    try {
      for (let index = 0; index < renderedFootnotes.length; index++) {
        const element = renderedFootnotes.item(index);
        let model = this.Data.notes.get(data.id);
        if (model) {
          if (element.getAttribute('number') !== `${model.serial}`) {
            element.setAttribute('number', `${model.serial}`);
            //! maybe this should be refactored
            if (!this.Data.versions.hasLoadedVersion()) {
              const closestTracked = DOMUtils.closest(element, [
                'TRACK-INS-ELEMENT',
                'TRACK-DEL-ELEMENT',
              ]);
              if (closestTracked) {
                const ref = closestTracked.getAttribute('element_reference');
                if (ref) {
                  this.Data.suggestions?.updateSuggestionContent(ref, {
                    inserted: ActionContext.suggestedInsertedContent(ref) as any,
                    deleted: ActionContext.suggestedDeletedContent(ref) as any,
                  });
                }
              }
            }
          }
        }
      }
    } catch (error) {
      logger.captureException(error, {
        extra: {
          // renderedFootnotes,
          document,
        },
      });
    }
  }

  private handleLoadNotes() {
    const renderedFootnotes = document.querySelectorAll(`note-element`);
    try {
      for (let index = 0; index < renderedFootnotes.length; index++) {
        const element = renderedFootnotes.item(index);
        const noteId = element.getAttribute('element_reference');
        let model;
        if (noteId) {
          model = this.Data.notes.get(noteId);
        }
        if (model) {
          if (element.getAttribute('number') !== `${model.serial}`) {
            element.setAttribute('number', `${model.serial}`);
            //! maybe this should be refactored
            if (!this.Data.versions.hasLoadedVersion()) {
              const closestTracked = DOMUtils.closest(element, [
                'TRACK-INS-ELEMENT',
                'TRACK-DEL-ELEMENT',
              ]);
              if (closestTracked) {
                const ref = closestTracked.getAttribute('element_reference');
                if (ref) {
                  this.Data.suggestions?.updateSuggestionContent(ref, {
                    inserted: ActionContext.suggestedInsertedContent(ref) as any,
                    deleted: ActionContext.suggestedDeletedContent(ref) as any,
                  });
                }
              }
            }
          }
        }
      }
    } catch (error) {
      logger.captureException(error, {
        extra: {
          // renderedFootnotes,
          document,
        },
      });
    }
  }

  private handleNotesDelta(
    data: IndexerDeltaType<{
      footNotes: Notes.NoteDataId[];
      endnotes: Notes.NoteDataId[];
    }>,
  ) {
    if (this.notesList) {
      let model;
      for (let index = 0; index < data.in.footNotes.length; index++) {
        model = this.Visualizer.viewModelFactory?.getNote(data.in.footNotes[index]);
        this.appendChild(model);
      }
      for (let index = 0; index < data.in.endnotes.length; index++) {
        model = this.Visualizer.viewModelFactory?.getNote(data.in.endnotes[index]);
        this.appendChild(model);
      }
      for (let index = 0; index < data.out.footNotes.length; index++) {
        let removed = this.children.removeById(data.out.footNotes[index]);
        for (let j = 0; j < removed.length; j++) {
          removed[j].dispose();
        }
      }
      for (let index = 0; index < data.out.endnotes.length; index++) {
        let removed = this.children.removeById(data.out.endnotes[index]);
        for (let j = 0; j < removed.length; j++) {
          removed[j].dispose();
        }
      }
    }
  }

  private appendChild(child?: NoteViewModel) {
    if (child) {
      this.children.push(child);
    }
  }

  destroy() {
    if (this.notesList) {
      this.notesList.off('CHANGED_DELTA', this.handleNotesDelta);
    }
    this.Data.events.off('LOAD_NOTES_DATA', this.handleLoadNotes);
    let children = this.children.removeAll();
    for (let index = 0; index < children.length; index++) {
      children[index].dispose();
    }
  }
}
