/* eslint-disable class-methods-use-this */
import { ParseMapper } from 'Editor/services/Parsers';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { HtmlParser } from '.';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import {
  DEFAULT_STYLE_OBJECT,
  ELEMENTS,
  INDENT_TYPE,
  DEFAULT_TABLE_BORDERS,
} from 'Editor/services/consts';
import { parseMeasurement, hexColorFromKeyword } from 'utils';
import { Logger } from '_common/services';
import NumberingUtils from 'Editor/services/_Common/NumberingUtils';
import getSymbolCode from './SymbolsCodes';

export class OfficeParser extends HtmlParser {
  static parseMSOfficeStyleToObject(style) {
    let parsedJson = {};

    if (style != null) {
      style = style.replace(/(\n|!msorm)/gm, ' ').replace(/\\,/gm, ' ');
      const json = `{${style
        .split(';')
        .filter((rule) => rule.trim() && !rule.includes('mso-style-textfill-'))
        .map((rule) =>
          rule
            .trim()
            .split(':')
            .reduce((previous, current) => {
              if (previous.length < 2) {
                previous.push(current);
              } else {
                previous[1] += current;
              }
              return previous;
            }, [])
            .map((str) => {
              let wrapped = str;
              wrapped = wrapped.replace(/\\/g, '');
              if (wrapped === '"' || !(wrapped[0] === '"' && wrapped.slice(-1) === '"')) {
                wrapped = `"${wrapped.replace(/"/gm, '\\"').trim()}"`;
              }
              return wrapped;
            })
            .join(':'),
        )
        .join(',')}}`;

      try {
        parsedJson = JSON.parse(json);
      } catch (error) {
        Logger.captureException(error, { json });
      }
    }
    return parsedJson;
  }

  constructor(...args) {
    super(...args);
    this.htmlParsers['#text'] = this.handleTextNode.bind(this);
    this.htmlParsers.A = this.handleAnchor.bind(this);
    this.htmlParsers.DIV = this.handleDiv.bind(this);
    this.htmlParsers.FONT = this.handleFont.bind(this);
    this.htmlParsers.P = this.handleParagraph.bind(this);
    this.htmlParsers.TABLE = this.handleTable.bind(this);
    this.htmlParsers.SPAN = this.handleSpan.bind(this);
    this.htmlParsers['W:SDT'] = this.handleWordSDT.bind(this);
    this.ALLOWED_STYLES_FOR_ELEMENT.FONT = [
      'color',
      'background-color',
      'font-style',
      'font-family',
      'font-size',
      'font-weight',
      'text-decoration',
    ];
    this.notesLists = {
      footnote: null,
      endnote: null,
    };
  }

  beforeParse() {
    super.beforeParse();
    // Parse the styles from word classnames and merge them inline in the matching elements
    this.cssParser.parseHTMLStyles(this.html);
  }

  /**
   * This parses the style attribute from string to json of the node
   * and stores it in a map of id -> json
   * @param {Node} node The reference to the node of which to parse the style attribute to json
   * @param {string} id Generated internal ID to which the json should be mapped
   */
  _handleStyleAttribute(node, id) {
    // Text elements do not have style
    if (node.nodeType === Node.ELEMENT_NODE && node.hasAttribute('style')) {
      const style = node.getAttribute('style');
      const json = OfficeParser.parseMSOfficeStyleToObject(style);
      this.nodeStyleJson[id] = json;
    }
  }
  /* handleStyleAttribute(node) {
    if (node.hasAttribute('style')) {
      const style = node.getAttribute('style');
      const json = OfficeParser.parseMSOfficeStyleToObject(style);

      if (json['mso-footnote-id'] || json['mso-endnote-id']) {
        node.remove();
      } else if (
        json['mso-element'] &&
        (json['mso-element'] === 'footnote-list' || json['mso-element'] === 'endnote-list')
      ) {
        node.remove();
      } else if (node.tagName === 'BR' && style.includes('page-break')) {
        const pageBreak = DOMElementFactory.buildElement('page-break-element');
        node.parentNode.replaceChild(pageBreak, node);
        while (pageBreak.parentNode !== this.container) {
          const parent = pageBreak.parentNode;
          parent.parentNode.insertBefore(pageBreak, parent.nextSibling);
          if (parent.childNodes.length === 0) {
            parent.remove();
          }
        }
      } else if (json['mso-list']) {
        const list = json['mso-list'].split(' ');
        node.setAttribute('list_id', list[0]);
        node.setAttribute('list_level', list[1]);
      } else if (!this.ALLOWED_STYLES_FOR_ELEMENT[node.tagName]) {
        this.pendingStyleAttrRemoval.push(node);
      }
    }
  } */

  getValueFromStyleSheet(node) {
    const stylesheet = this.styleElements[0].sheet;
    for (let i = 0; i < stylesheet.cssRules.length; i++) {
      const rule = stylesheet.rules[i];
      if (node.matches(rule.selectorText)) {
        return rule.style;
      }
    }
    return null;
  }

  handleTextNode(node) {
    return this.handleCrossReference(node) || super.handleTextNode(node);
  }

  handleAnchor(node, parent) {
    if (node.hasAttribute('style')) {
      const noteRegex = /^mso-(footnote|endnote)-id: ?(\w+)$/;
      const matches = noteRegex.exec(node.getAttribute('style'));
      if (matches) {
        // Get the note content
        const type = matches[1];
        const wordId = matches[2];
        // The HTML container from word always has a div of the format: <div style="mso-element:(footnote|endnote)-list">
        // This div has all of the footnotes/endnotes that were copied to the clipboard
        // We are only going to get them once and store them in the notesLists object for faster posterior user
        // We also remove the <a> because we only care about the note content and these elements have things like "[1]"
        if (this.notesLists[type] === null) {
          const list = this.html.querySelector(`div[style="mso-element:${type}-list"]`);
          if (list !== null) {
            const anchors = Array.from(list.querySelectorAll('a'));
            for (let i = 0; i < anchors.length; i++) {
              anchors[i].remove();
            }
            this.notesLists[type] = list;
          }
        }
        if (this.notesLists[type] !== null) {
          const noteElement = this.notesLists[type].querySelector(
            `div[style="mso-element:${type}"][id="${wordId}"]`,
          );
          this.newNotes[wordId] = {
            type,
            text: noteElement.textContent.trim(),
          };
          const note = DOMElementFactory.buildElement('note-element', {
            type,
            'data-temp-id': wordId,
          });

          return {
            parsedWorkNode: note,
            parentForChildNodes: null,
          };
        }
      }
    }
    // Don't parse remaining tree
    if (
      node.classList.contains('msocomanchor') ||
      (node.hasAttribute('style') && node.getAttribute('style').includes('note-id'))
    ) {
      return null;
    }
    return super.handleAnchor(node, parent);
  }

  handleDiv(node, parent) {
    if (node.hasAttribute('style') && node.getAttribute('style').includes('mso-element')) {
      return null;
    }
    return super.handleDiv(node, parent);
  }

  handleFont(node, parent) {
    const styles = this.cssParser.getNodeStyle(node);

    if (this.ALLOWED_STYLES_FOR_ELEMENT.FONT.some((property) => styles[property])) {
      // If the font has any allowed style then create a format for it, otherwise ignore it and unwrap
      Object.keys(styles).forEach((style) => {
        node.style[style] = styles[style];
      });
      const parsedNode = this.createFormatElement(this.parseStyles(node));
      return {
        parsedWorkNode: parsedNode,
        parentForChildNodes: node,
      };
    }
    return {
      parentForChildNodes: parent,
    };
  }

  handleParagraph(node) {
    // Return ToF Element and ignore the following siblings that belong to the same ToF
    if (node.className.includes('MsoTof')) {
      // ToF comes from Word as a paragraph with a 'MsoToF' class
      // Word pastes a paragraph with a MsoTof class for each entry in its ToF
      // so we only parse the first one and ignore the rest
      // And ignore the rest
      let nextSibling = node.nextSibling;
      while (
        nextSibling &&
        nextSibling.tagName === 'P' &&
        nextSibling.className.includes('MsoTof')
      ) {
        const id = this.getNodeId(nextSibling);
        this.nodesToIgnore[id] = 1;
        nextSibling = nextSibling.nextSibling;
      }
      return {
        parsedWorkNode: DOMElementFactory.buildElement(ELEMENTS.ListOfFiguresElement.TAG),
      };
    }
    const parsedNode = DOMElementFactory.buildElement(ELEMENTS.ParagraphElement.TAG);
    if (node.dataset.alignment) {
      parsedNode.dataset.alignment = node.dataset.alignment;
    }
    const officeParagraph = node.querySelector('o\\:p');
    if (officeParagraph) {
      this.handleOfficeParagraph(officeParagraph, parsedNode);
    }
    // Handle as list paragraph
    if (
      node.hasAttribute('style') &&
      (node.className.includes('ListParagraph') || node.getAttribute('style').includes('mso-list'))
    ) {
      this.handleParagraphList(node, parsedNode);
    }
    // Anchors with name like "_RefXXXXXXXX" are the target of a cross reference
    // Also, there can be more than 1 reference in the same element
    const anchors = node.querySelectorAll('a[name^="_Ref"]');
    for (let i = 0; i < anchors.length; i++) {
      const anchor = anchors[i];
      const refId = anchor.getAttribute('name');
      // Initial ref id validation, should be enough
      if (refId.startsWith('_Ref')) {
        if (parsedNode.dataset.tempCrossReferenceId) {
          const references = parsedNode.dataset.tempCrossReferenceId.split(',');
          if (!references.includes(refId)) {
            references.push(refId);
            parsedNode.dataset.tempCrossReferenceId = references;
          }
        } else {
          parsedNode.dataset.tempCrossReferenceId = [refId];
        }
        this.newCrossRefs[refId] = { ...this.newCrossRefs[refId], hasTarget: true };
      }
    }

    parsedNode.setAttribute('element_type', ELEMENTS.ParagraphElement.ELEMENT_TYPE);
    return this.handleParagraphStyles(node, parsedNode);
  }

  handleOfficeParagraph(node, paragraph) {
    const styles = {};
    let climber = node;
    while (climber) {
      climber = climber.parentNode;
      if (climber.hasAttribute('style')) {
        const climberStyles = this.parseStyles(climber);
        for (let i = 0; i < climberStyles.length; i++) {
          const style = climberStyles[i];
          if (!styles[style]) {
            styles[style] = climberStyles[style];
          }
        }
      }
      if (!DOMUtils.isInlineNode(climber)) {
        break;
      }
    }

    if (climber && climber.tagName === 'P') {
      const styles = this.parseStyles(climber);
      Object.keys(styles).forEach((property) => {
        const style = Array.isArray(styles[property]) ? styles[property][0] : styles[property];
        if (!styles[property]) {
          styles[property] =
            style.type === 'DIMENSION' ? `${style.value}${style.unit}` : style.value;
        }
      });
    }

    const computedStyles = window.getComputedStyle(node);

    if (paragraph) {
      if (styles.fontFamily) {
        paragraph.dataset[StylesUtils.STYLES.FONTFAMILY] = computedStyles.fontFamily.split(',')[0];
      }
      if (styles.fontSize) {
        paragraph.dataset[StylesUtils.STYLES.FONTSIZE] = parseMeasurement(
          computedStyles.fontSize,
          'pt',
        );
      }
      if (styles.color) {
        paragraph.dataset[StylesUtils.STYLES.COLOR] = computedStyles.color;
      }

      const isBold = DOMUtils.closest(node, 'B');
      if (isBold) {
        paragraph.dataset[StylesUtils.STYLES.BOLD] = true;
      }

      const isItalic = DOMUtils.closest(node, 'I');
      if (isItalic) {
        paragraph.dataset[StylesUtils.STYLES.ITALIC] = true;
      }

      const isUnderline = DOMUtils.closest(node, 'U');
      if (isUnderline) {
        paragraph.dataset[StylesUtils.STYLES.UNDERLINE] = true;
      }

      const isStrikethrough = DOMUtils.closest(node, 'S');
      if (isStrikethrough) {
        paragraph.dataset[StylesUtils.STYLES.STRIKETHROUGH] = true;
      }

      const isSuperscript = DOMUtils.closest(node, 'SUP');
      if (isSuperscript) {
        paragraph.dataset[StylesUtils.STYLES.SUPERSCRIPT] = true;
      }

      const isSubscript = DOMUtils.closest(node, 'SUB');
      if (isSubscript) {
        paragraph.dataset[StylesUtils.STYLES.SUBSCRIPT] = true;
      }
    }
  }

  parseListData(listData) {
    this.debugMessage('parse list data', listData);
    const parsedData = {};

    const levelKeys = Object.keys(listData);

    for (let i = 0; i < levelKeys.length; i++) {
      const level = levelKeys[i];
      const levelData = listData[level];

      parsedData[level] = {
        level: +level - 1,
      };

      const propKeys = Object.keys(levelData);

      for (let j = 0; j < propKeys.length; j++) {
        const prop = propKeys[j];

        switch (prop) {
          case 'margin-left':
            parsedData[level].indentation_left = levelData[prop];
            break;
          case 'margin-right':
            parsedData[level].indentation_right = levelData[prop];
            break;
          case 'text-indent':
            const textIndent = DOMUtils.convertUnitTo(levelData[prop]);

            if (textIndent >= 0) {
              parsedData[level].special_indent = INDENT_TYPE.FIRST_LINE;
            } else {
              parsedData[level].special_indent = INDENT_TYPE.HANGING;
            }
            parsedData[level].special_indent_value = Math.abs(textIndent);
            break;
          case 'mso-level-number-format':
            parsedData[level].numbering_type = {
              value: NumberingUtils.parseOfficeType(levelData[prop]),
            };

            break;
          case 'mso-level-legal-format':
            break;
          case 'mso-level-number-position':
            break;
          case 'mso-level-tab-stop':
            break;
          case 'mso-level-text':
            const text = levelData[prop];

            const levelTextReg = new RegExp(`%${level}`, 'g');

            const matches = levelTextReg.exec(text);

            let beforeText = '';
            let afterText = '';

            if (matches?.length > 0) {
              beforeText = text.substring(0, text.indexOf(matches[0]));

              let previousLevelRegex = new RegExp(`(?=%\\d)(.*)(?<=%\\d)`, 'g');
              const previousMatches = previousLevelRegex.exec(beforeText);
              if (previousMatches?.length > 0) {
                parsedData[level].incl_prev_lvls = true;

                beforeText = beforeText.replace(previousLevelRegex, ''); // TODO: check
              }

              afterText = text.substring(text.indexOf(matches[0]) + matches[0].length, text.length);
            } else {
              beforeText = text;
            }

            parsedData[level].char_after = afterText;
            parsedData[level].char_before = beforeText;

            break;
          case 'mso-level-style-link':
            parsedData[level].paragraph_style = {
              value: levelData[prop],
            };
            break;

          case 'font-family':
            parsedData[level].font_family = levelData[prop];
            break;
          case 'mso-ansi-font-size':
            break;
          case 'mso-ansi-font-style':
            break;
          case 'mso-ansi-font-weight':
            break;

          case 'mso-hide':
            break;

          case 'text-decoration':
            break;
          case 'text-effect':
            break;
          case 'text-line-through':
            break;
          case 'text-shadow':
            break;
          case 'text-transform':
            break;
          case 'text-underline':
            break;
          case 'vertical-align':
            break;

          //TODO: complete props

          default:
            break;
        }
      }

      if (parsedData[level].numbering_type == null) {
        parsedData[level].numbering_type = {
          value: NumberingUtils.TYPE.DECIMAL,
        };
      }

      if (
        parsedData[level].start_from == null &&
        parsedData[level].numbering_type.value != null &&
        parsedData[level].numbering_type.value !== NumberingUtils.TYPE.NONE &&
        parsedData[level].numbering_type.value !== NumberingUtils.TYPE.BULLET &&
        parsedData[level].numbering_type.value !== NumberingUtils.TYPE.SPECIAL_CHAR
      ) {
        parsedData[level].start_from = 1;
      }

      if (parsedData[level]?.paragraph_style?.value) {
        let styleName = parsedData[level]?.paragraph_style?.value;

        for (let i = 0; i < this.cssParser.rules.length; i++) {
          const rule = this.cssParser.rules[i];
          if (rule.styleName === styleName) {
            const style = rule.style;
            this.prepareNewDocumentStyle(styleName, style);
          }
        }
      }
    }

    if (Object.keys(parsedData).length > 0) {
      return parsedData;
    } else {
      return null;
    }
  }

  handleParagraphList(node, parsedNode) {
    // Get the attributes of the list item
    const parsedStyle = OfficeParser.parseMSOfficeStyleToObject(node.getAttribute('style'));
    const list = parsedStyle['mso-list'].split(' ');
    const msoListId = list[0];
    const msoListLevel = list[1];

    if (!this.listDefinitions[msoListId]) {
      // this.listDefinitions[msoListId] = {};

      const listData = {};
      // Regexs that use the list id and the level found in the paragraph style attribute (found in handleStyleAttribute function)
      // to access the css declarations from the <style /> tag that came in the clipboard from word and was stored in this.styleSheets
      // Using Regex because the style has invalid attributes and as soon as the style object is accessed, it will be sanitized by the browser

      const listLevelRegex = new RegExp(`${msoListId}:level\\d\\s+{(.+?)}`, 'gi');
      let listLevelProperties;
      const headElement = this.html.querySelector('head');
      for (let i = 0; i < headElement.childNodes.length; i += 1) {
        const childElement = headElement.childNodes[i];
        if (
          (childElement.nodeType === Node.ELEMENT_NODE && childElement.tagName === 'STYLE') ||
          childElement.nodeType === Node.COMMENT_NODE
        ) {
          const stylesheet = childElement.textContent.replace(/\n/gm, '');
          // const listIdMatches = listIdRegex.exec(stylesheet);
          // if (listIdMatches) {
          let listLevelMatches = listLevelRegex.exec(stylesheet);
          while (listLevelMatches != null) {
            const listLevel = listLevelMatches[0].match(/(?<=level)(.*?)(?=\s+{)/);
            listLevelProperties = OfficeParser.parseMSOfficeStyleToObject(listLevelMatches[1]);

            listData[listLevel[0]] = listLevelProperties;

            listLevelMatches = listLevelRegex.exec(stylesheet);
          }

          // }
          if (Object.keys(listData).length > 0) {
            this.listDefinitions[msoListId] = this.parseListData(listData);
            break;
          }
        }
      }
    }

    const listLevel = +msoListLevel?.substring(5) || 1; // From "level1" discard "level"

    const levelData = this.listDefinitions[msoListId][listLevel];

    if (levelData != null) {
      if (parsedStyle['margin-left']) {
        levelData.indentation_left = DOMUtils.convertUnitTo(parsedStyle['margin-left'], 'pt', 'pt');
      }

      if (levelData?.paragraph_style?.value == null) {
        switch (levelData.numbering_type?.value) {
          case NumberingUtils.TYPE.BULLET:
            parsedNode.setAttribute('cp_default_list_style', 'u1');
            break;
          case NumberingUtils.TYPE.DECIMAL:
          default:
            parsedNode.setAttribute('cp_default_list_style', 'o1');
            break;
        }
      }
    }

    // parsedNode.setAttribute('cp_list_id', msoListId);
    parsedNode.setAttribute('cp_list_level', listLevel - 1);
    parsedNode.setAttribute('cp_list_style', msoListId);
  }

  handleParagraphStyles(node, parsedNode) {
    // Node must be a P (excluding headings because they should have been handled before)
    // If node is P, it must have one of these classes [MsoHeading7, MsoHeading8, MsoHeading9]
    // If node is P and has some other class, that class must have the mso-style-name property
    // If node fits the in the previous description, then use the retrieved style properties from the CSSParser
    // If there is a mso-style-name, use it to validate if there is already a Document Style of that name
    //   - If not, create new Document Style and apply to parsedNode
    //   - If the Document Style already exists, use the existing ID

    // --------------------------------------------------------------------------------
    // parse paragraph "inline" styles
    const styles = OfficeParser.parseMSOfficeStyleToObject(node.getAttribute('style'));
    if (styles['mso-text-indent-alt']) {
      const specialValue = parseMeasurement(styles['mso-text-indent-alt'], 'pt', {
        defaultUnit: 'px',
      });
      if (specialValue != null) {
        let special;
        if (specialValue > 0) {
          special = 'f';
        } else if (specialValue < 0) {
          special = 'h';
        }
        parsedNode.dataset.specialIndent = special;
        parsedNode.dataset.specialIndentValue = Math.abs(specialValue);
      }
    }

    if (styles['mso-margin-top-alt'] != null && styles['mso-margin-top-alt'] === 'auto') {
      parsedNode.dataset[StylesUtils.STYLES.ASB] = true;
    }

    if (styles['mso-margin-bottom-alt'] != null && styles['mso-margin-bottom-alt'] === 'auto') {
      parsedNode.dataset[StylesUtils.STYLES.ASA] = true;
    }

    // pagination props
    if (styles['page-break-before'] != null && styles['page-break-before'].includes('always')) {
      parsedNode.dataset[StylesUtils.STYLES.PBB] = true;
    }

    if (styles['mso-pagination'] != null) {
      if (styles['mso-pagination'].includes('widow-orphan')) {
        parsedNode.dataset[StylesUtils.STYLES.WC] = true;
      } else if (styles['mso-pagination'] === 'none') {
        parsedNode.dataset[StylesUtils.STYLES.WC] = false;
      }

      if (styles['mso-pagination'].includes('lines-together')) {
        parsedNode.dataset[StylesUtils.STYLES.KL] = true;
      }
    }

    if (styles['page-break-after'] != null && styles['page-break-after'].includes('avoid')) {
      parsedNode.dataset[StylesUtils.STYLES.KN] = true;
    }
    //---------------------------------------------------------------------------------

    for (let i = 0; i < this.cssParser.rules.length; i++) {
      const rule = this.cssParser.rules[i];
      if (rule.selectors.some((selector) => node.matches(selector))) {
        const style = rule.style;
        // The previous parser can already identify the default document styles like normal paragraph, title and the headings
        // We didn't skip before because we still need to transfer the paragraph styles to be inline
        // if (parsedNode.dataset.styleId) {
        //   Object.keys(style).forEach(st => {
        //     if (!node.style[st]) {
        //       node.style[st] = style[st];
        //     }
        //   });
        //   return super.handleParagraphStyles(node, parsedNode);
        // }

        let styleName;
        if (style['mso-style-name']) {
          styleName = style['mso-style-name'];
          if (styleName[0] === '"' && styleName[styleName.length - 1] === '"') {
            styleName = styleName.substring(1, styleName.length - 1);
          }
          styleName = styleName.replace(/\\/g, '');
        } else if (node.getAttribute('class')) {
          styleName = node.getAttribute('class');
          styleName = styleName.replace('CxSpFirst', '');
          styleName = styleName.replace('CxSpMiddle', '');
          styleName = styleName.replace('CxSpLast', '');

          switch (styleName) {
            case 'MsoTitle':
              styleName = 'Title';
              break;
            case 'MsoNormal':
              styleName = 'Paragraph';
              break;
            case 'MsoNoSpacing':
              styleName = 'No spacing';
              break;
            case 'MsoHeading7':
              styleName = 'Heading 7';
              break;
            case 'MsoHeading8':
              styleName = 'Heading 8';
              break;
            case 'MsoHeading9':
              styleName = 'Heading 9';
              break;
            case 'MsoListParagraph':
              styleName = 'List paragraph';
              break;
            default:
              break;
          }
        } else if (node.tagName !== 'P') {
          styleName = node.tagName.toLowerCase();
        }

        if (styleName) {
          // Set the style as a temporary style for change to the real id when pasting
          parsedNode.dataset.tempStyleId = styleName;
          this.prepareNewDocumentStyle(styleName, style);
        } else if (!parsedNode.dataset.styleId) {
          if (node.tagName !== 'P') {
            const st = HtmlParser.NODE_TAG_MAPPING[node.tagName].st;
            parsedNode.dataset.styleId = st;
          } else {
            parsedNode.dataset.styleId = ELEMENTS.ParagraphElement.BASE_STYLES.PARAGRAPH;
          }
        }
      }
    }
    return super.handleParagraphStyles(node, parsedNode);
  }

  getTableCellStyles(td) {
    const parsedStyles = {};

    const cellStyle = OfficeParser.parseMSOfficeStyleToObject(td.getAttribute('style'));

    const styleKeys = Object.keys(cellStyle);
    for (let i = 0; i < styleKeys.length; i++) {
      switch (styleKeys[i]) {
        case 'mso-border-alt':
        case 'border': {
          const values = cellStyle[styleKeys[i]].split(' ');
          if (values[0] === 'none') {
            parsedStyles.borderTop = 'none';
            parsedStyles.borderLeft = 'none';
            parsedStyles.borderBottom = 'none';
            parsedStyles.borderRight = 'none';
          } else {
            const style = ParseMapper.borderStyleMapper.render(values[0]);
            parsedStyles.borderTopStyle = style;
            parsedStyles.borderLeftStyle = style;
            parsedStyles.borderBottomStyle = style;
            parsedStyles.borderRightStyle = style;

            const color = values[1];
            parsedStyles.borderTopColor = color;
            parsedStyles.borderLeftColor = color;
            parsedStyles.borderBottomColor = color;
            parsedStyles.borderRightColor = color;

            const width = values[2];
            parsedStyles.borderTopWidth = width;
            parsedStyles.borderLeftWidth = width;
            parsedStyles.borderBottomWidth = width;
            parsedStyles.borderRightWidth = width;
          }
          break;
        }
        case 'mso-border-top-alt':
        case 'border-top': {
          const values = cellStyle[styleKeys[i]].split(' ');
          if (values[0] === 'none') {
            parsedStyles.borderTop = 'none';
          } else {
            const style = ParseMapper.borderStyleMapper.render(values[0]);
            parsedStyles.borderTopStyle = style;

            const color = values[1];
            parsedStyles.borderTopColor = color;

            const width = values[2];
            parsedStyles.borderTopWidth = width;
          }
          break;
        }
        case 'mso-border-left-alt':
        case 'border-left': {
          const values = cellStyle[styleKeys[i]].split(' ');
          if (values[0] === 'none') {
            parsedStyles.borderLeft = 'none';
          } else {
            const style = ParseMapper.borderStyleMapper.render(values[0]);
            parsedStyles.borderLeftStyle = style;

            const color = values[1];
            parsedStyles.borderLeftColor = color;

            const width = values[2];
            parsedStyles.borderLeftWidth = width;
          }
          break;
        }
        case 'mso-border-right-alt':
        case 'border-right': {
          const values = cellStyle[styleKeys[i]].split(' ');
          if (values[0] === 'none') {
            parsedStyles.borderRight = 'none';
          } else {
            const style = ParseMapper.borderStyleMapper.render(values[0]);
            parsedStyles.borderRightStyle = style;

            const color = values[1];
            parsedStyles.borderRightColor = color;

            const width = values[2];
            parsedStyles.borderRightWidth = width;
          }
          break;
        }
        case 'mso-border-bottom-alt':
        case 'border-bottom': {
          const values = cellStyle[styleKeys[i]].split(' ');
          if (values[0] === 'none') {
            parsedStyles.borderBottom = 'none';
          } else {
            const style = ParseMapper.borderStyleMapper.render(values[0]);
            parsedStyles.borderBottomStyle = style;

            const color = values[1];
            parsedStyles.borderBottomColor = color;

            const width = values[2];
            parsedStyles.borderBottomWidth = width;
          }
          break;
        }

        case 'padding': {
          const values = cellStyle[styleKeys[i]].split(' ');
          parsedStyles.paddingTop = values[0];
          parsedStyles.paddingRight = values[1];
          parsedStyles.paddingBottom = values[2];
          parsedStyles.paddingLeft = values[3];
          break;
        }

        case 'background': {
          parsedStyles.backgroundColor = cellStyle[styleKeys[i]];
          break;
        }

        default:
          break;
      }
    }

    if (td.hasAttribute('valign')) {
      parsedStyles.verticalAlignment = td.getAttribute('valign');
    }

    return parsedStyles;
  }

  shouldParseTableCell(cell) {
    const cellStyle = OfficeParser.parseMSOfficeStyleToObject(cell.getAttribute('style'));

    if (cellStyle?.['mso-cell-special'] === 'placeholder') {
      return false;
    }

    return true;
  }

  async handleTable(table) {
    const parsedTable = DOMElementFactory.createNewTableElement();

    const tableStyle = OfficeParser.parseMSOfficeStyleToObject(table.getAttribute('style'));

    if (tableStyle['mso-border-alt']) {
      // parse table border
      const tableborders = JSON.parse(JSON.stringify(DEFAULT_TABLE_BORDERS));

      const values = tableStyle['mso-border-alt'].split(' ');
      if (values[0] === 'none') {
        tableborders.t.w = 0;
        tableborders.b.w = 0;
        tableborders.l.w = 0;
        tableborders.r.w = 0;
      } else {
        const style = ParseMapper.borderStyleMapper.parse(values[0]);
        tableborders.t.s = style;
        tableborders.b.s = style;
        tableborders.l.s = style;
        tableborders.r.s = style;

        if (values[1][0] === '#') {
          const color = values[1].slice(1);
          tableborders.t.c = color;
          tableborders.b.c = color;
          tableborders.l.c = color;
          tableborders.r.c = color;
        }

        const width = DOMUtils.convertUnitTo(values[2], null, 'pt', 3);
        tableborders.t.w = width;
        tableborders.b.w = width;
        tableborders.l.w = width;
        tableborders.r.w = width;
      }

      parsedTable.setAttribute('cp_cell_borders', JSON.stringify(tableborders));
    }

    return super.handleTable(table, parsedTable);
  }

  handleSpan(node, parent) {
    if (
      node.hasAttribute('style') &&
      node.getAttribute('style').includes('mso-list:Ignore') // To ignore list prefixes
    ) {
      return null;
    }
    if (node.className.includes('MsoCommentReference')) {
      return null;
    }
    const result = this.handleCrossReference(node);
    if (result) {
      return result;
    }

    if (node.hasAttribute('style')) {
      const parsedStyle = OfficeParser.parseMSOfficeStyleToObject(node.getAttribute('style'));
      if (parsedStyle['mso-char-type'] && parsedStyle['mso-char-type'] === 'symbol') {
        if (node.style.fontFamily) {
          return {
            // Currently dealing with the symbol wrapper, we don't care about this one
            parentForChildNodes: parent,
          };
        }
        const symbolElement = DOMElementFactory.buildElement('symbol-element');
        symbolElement.setAttribute('fontfamily', parsedStyle['mso-symbol-font-family']);
        if (node.childNodes.length === 1) {
          const value = node.textContent.charCodeAt(0).toString(16).toUpperCase();
          symbolElement.setAttribute(
            'content',
            getSymbolCode(parsedStyle['mso-symbol-font-family'].toLowerCase(), `F0${value}`),
          );
          symbolElement.setAttribute('fonthex', `F0${value}`);
          return {
            parsedWorkNode: symbolElement,
            parentForChildNodes: node,
          };
        }
      }
    }
    return super.handleSpan(node, parent);
  }

  handleWordSDT(node) {
    let parsedNode = null;
    if (
      (node.hasAttribute('docparttype') &&
        node.getAttribute('docparttype') === 'Table of Contents') ||
      node.querySelector('p[class^="MsoToc"]')
    ) {
      parsedNode = DOMElementFactory.buildElement(ELEMENTS.TableOfContentsElement.TAG);
    } else if (node.querySelector('p[class^="MsoTof"]')) {
      parsedNode = DOMElementFactory.buildElement(ELEMENTS.ListOfFiguresElement.TAG);
    } else if (node.hasAttribute('title') && node.getAttribute('title') === 'Author') {
      parsedNode = DOMElementFactory.buildElement(ELEMENTS.AuthorsElement.TAG);
    } else if (node.hasAttribute('title') && node.getAttribute('title') === 'Keywords') {
      parsedNode = DOMElementFactory.buildElement(ELEMENTS.KeywordsElement.TAG);
    } else if (node.hasAttribute('citation')) {
      parsedNode = DOMElementFactory.buildElement('span');
      parsedNode.appendChild(document.createTextNode(node.textContent.trim()));
    }
    return {
      parsedWorkNode: parsedNode,
    };
  }

  // handleTableCellStyles(td, parsedTd) {
  //   // TDs can have styles coming from classes, specially if coming from excel.
  //   // For now, we will only transfer them to the style attribute
  //   // And continue with the standard _parseStyles
  //   if (td.hasAttribute('class')) {
  //     const styles = this.cssParser.getNodeStyle(td);
  //     Object.keys(styles).forEach(style => {
  //       if (!td.style[style]) {
  //         td.style[style] = styles[style];
  //       }
  //     });
  //   }
  //   return super.handleTableCellStyles(td, parsedTd);
  // }

  handleCrossReference(node) {
    // This span might be a cross reference if the previous siblings is a comment elements and contain the a certain attribute
    if (
      node.previousSibling?.nodeType === 8 &&
      node.previousSibling.data.includes('mso-element:field-begin')
    ) {
      // To be sure, there must be a similar next sibling (does not have to be the immediate next sibling)
      let nextSibling = node.nextSibling;
      while (nextSibling) {
        // this ensures that the span is a field element
        if (nextSibling.nodeType === 8 && nextSibling.data.includes('mso-element:field-end')) {
          break;
        }
        nextSibling = nextSibling.nextSibling;
      }
      if (nextSibling) {
        const refRegex = /REF (_Ref\d+)/g;
        const matches = refRegex.exec(node.previousSibling.data);
        if (matches) {
          const refId = matches[1];
          const crossReference = DOMElementFactory.buildElement('field-element');
          crossReference.dataset.ref = ':';
          crossReference.dataset.type = 'cr';
          // TODO: Validate if it is always this format (Seemed impossible to know)
          crossReference.dataset.format = 'lnt';
          // TODO: Detect if it is also a link (Seemed impossible to know)
          crossReference.dataset.link = true;
          crossReference.dataset.tempCrossReferenceTarget = refId;
          this.newCrossRefs[refId] = { ...this.newCrossRefs[refId], hasReference: true };
          if (node.nodeType === Node.TEXT_NODE) {
            crossReference.appendChild(node.cloneNode());
          }
          return {
            parsedWorkNode: crossReference,
            parentForChildNodes: node.nodeType === Node.ELEMENT_NODE && node,
          };
        }
      }
    }
    return null;
  }

  prepareNewDocumentStyle(name, styles) {
    // If this particular style hasn't been prepared already
    if (!this.newDocumentStyles[name]) {
      const p = { ...DEFAULT_STYLE_OBJECT.p.p };
      Object.keys(styles).forEach((style) => {
        const value = styles[style];
        switch (style) {
          case 'text-align':
            p.a = ParseMapper.alignmentMapper.parse(value);
            break;
          case 'background-color':
            p.bg = hexColorFromKeyword(value) || value;
            break;
          case 'color':
            p.color = hexColorFromKeyword(value) || value;
            break;
          case 'font-family':
            p.fontfamily = value.replace(/"/g, '');
            break;
          case 'font-size':
          case 'mso-ansi-font-size':
            p.fontsize = parseMeasurement(value, 'pt');
            break;
          case 'font-style':
            if (value === 'italic') {
              p.italic = true;
            }
            break;
          case 'line-height':
            p.lh = this.handleLineHeight(value, styles['font-size']);
            break;
          case 'margin-bottom':
            p.sa = parseMeasurement(value, 'pt');
            break;
          case 'mso-margin-bottom-alt':
            if (value === 'auto') {
              p.asa = true;
            }
            break;
          case 'margin-left':
            const leftIndentation = parseMeasurement(value, 'pt');
            if (p.ind) {
              p.ind.l = leftIndentation;
            } else {
              p.ind = {
                l: leftIndentation,
              };
            }
            break;
          case 'margin-right':
            const rightIndentation = parseMeasurement(value, 'pt');
            if (p.ind) {
              p.ind.r = rightIndentation;
            } else {
              p.ind = {
                r: rightIndentation,
              };
            }
            break;
          case 'margin-top':
            p.sb = parseMeasurement(value, 'pt');
            break;
          case 'mso-margin-top-alt':
            if (value === 'auto') {
              p.asb = true;
            }
            break;
          case 'text-decoration':
            if (value.includes('underline')) {
              p.underline = true;
            }
            break;
          case 'font-weight':
            if (value === 'bold') {
              p.bold = true;
            }
            break;
          case 'page-break-before':
            if (value.includes('always')) {
              p.pbb = true;
            }
            break;
          case 'mso-pagination':
            if (value.includes('widow-orphan')) {
              p.wc = true;
            } else {
              p.wc = false;
            }
            if (value.includes('lines-together')) {
              p.kl = true;
            }
            break;
          case 'page-break-after':
            if (value.includes('avoid')) {
              p.kn = true;
            }
            break;
          case 'margin':
            p.sa = parseMeasurement(value, 'pt');
            p.sb = parseMeasurement(value, 'pt');
            break;
          case 'text-indent':
          case 'mso-text-indent-alt': {
            let specialValue = DOMUtils.convertUnitTo(value);

            if (specialValue != null) {
              let special;
              if (specialValue >= 0) {
                special = INDENT_TYPE.FIRST_LINE;
              } else if (specialValue < 0) {
                special = INDENT_TYPE.HANGING;
                specialValue = Math.abs(specialValue);
              }

              p.sp_ind = {
                t: special,
                v: specialValue,
              };
            }
            break;
          }

          case 'mso-list': {
            const list = value.split(' ');
            const msoListId = list[0];
            const msoListLevel = list[1];
            const listLevel = +msoListLevel?.substring(5) || 1;

            p.lst = {
              // lId: msoListId,
              lStId: msoListId,
              lLv: listLevel - 1,
            };
            break;
          }
          default:
            break;
        }
      });
      let t;
      switch (styles['mso-style-parent']) {
        case 'Title':
          t = 't';
          break;
        case 'Heading 1':
          t = 'h1';
          break;
        case 'Heading 2':
          t = 'h2';
          break;
        case 'Heading 3':
          t = 'h3';
          break;
        case 'Heading 4':
          t = 'h4';
          break;
        case 'Heading 5':
          t = 'h5';
          break;
        case 'Heading 6':
          t = 'h6';
          break;
        case 'Normal':
        default:
          t = 'p';
          break;
      }

      this.newDocumentStyles[name] = { n: name, p, t };
    }
  }
}
