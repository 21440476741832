import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { useSelector } from '_common/hooks';
import { useGetObjectQuery } from 'App/redux/objectApi';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

import { Permissions, TabMenu, MenuItem, AuditLog } from '_common/components';
import Metadata from '_common/components/Metadata/Metadata';

import SharedSpaceSettings from './SharedSpaceSettings/SharedSpaceSettings';
import PersonalSpaceSettings from './PersonalSpaceSettings/PersonalSpaceSettings';
import FolderSettings from './FolderSettings/FolderSettings';
import PublicLinkView from './PublicLinkView/PublicLinkView';

const Body = () => {
  const intl = useIntl();

  const data = useSelector((state) => state.app.data);

  const { id: objectId, type: objectType }: { id: ObjectId; type: ObjectType } = useParams();

  const { data: object } = useGetObjectQuery(
    { objectId, objectType },
    { skip: !objectId || !objectType },
  );
  const { data: metadata } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });

  switch (object?.type) {
    case 'space':
      if (object.personal) {
        return (
          <TabMenu menuId="objectSettingsPage_space_personal">
            <MenuItem id="general" text={intl.formatMessage({ id: 'settings.tabs.general' })}>
              <PersonalSpaceSettings />
              <div />
            </MenuItem>
          </TabMenu>
        );
      }
      return (
        <TabMenu menuId="objectSettingsPage_space">
          <MenuItem id="general" text={intl.formatMessage({ id: 'settings.tabs.general' })}>
            <SharedSpaceSettings />
          </MenuItem>
          <MenuItem text={intl.formatMessage({ id: 'COLLABORATORS' })} id="permissions">
            <Permissions />
          </MenuItem>
        </TabMenu>
      );
    case 'dopdf':
    case 'folder':
      return (
        <TabMenu menuId="objectSettingsPage_folder">
          <MenuItem id="general" text={intl.formatMessage({ id: 'settings.tabs.general' })}>
            <FolderSettings />
          </MenuItem>
          <MenuItem id="permissions" text={intl.formatMessage({ id: 'COLLABORATORS' })}>
            <Permissions />
          </MenuItem>
          <MenuItem
            text={intl.formatMessage({ id: 'AUDIT_LOG' })}
            id="auditLog"
            hidden={!object.user_permissions.includes('owner') || object.type !== 'dopdf'}
          >
            <AuditLog objectId={object.id} data={data} margin="2rem 0 0 0" />
          </MenuItem>
        </TabMenu>
      );
    case 'document':
      return (
        <TabMenu menuId="objectSettingsPage_document">
          <MenuItem id="general" text={intl.formatMessage({ id: 'settings.tabs.general' })}>
            <FolderSettings />
          </MenuItem>
          <MenuItem id="permissions" text={intl.formatMessage({ id: 'COLLABORATORS' })}>
            <Permissions />
          </MenuItem>
          <MenuItem
            id="metadat"
            text={intl.formatMessage({ id: 'METADATA' })}
            hidden={Object.keys(metadata).length <= 0}
          >
            <Metadata id={object.id} setting />
          </MenuItem>
          <MenuItem
            text={intl.formatMessage({ id: 'AUDIT_LOG' })}
            id="auditLog"
            hidden={!object.user_permissions.includes('owner')}
          >
            <AuditLog objectId={object.id} data={data} margin="2rem 0 0 0" />
          </MenuItem>
        </TabMenu>
      );
    case 'file':
      return (
        <TabMenu menuId="objectSettingsPage_file">
          <MenuItem id="general" text={intl.formatMessage({ id: 'settings.tabs.general' })}>
            <FolderSettings />
          </MenuItem>
          <MenuItem id="permissions" text={intl.formatMessage({ id: 'COLLABORATORS' })}>
            <Permissions />
          </MenuItem>
          {object.user_permissions.includes('owner') && (
            <MenuItem
              id="public-link"
              text={intl.formatMessage({ id: 'settings.publicLink.publicLink' })}
            >
              <PublicLinkView />
            </MenuItem>
          )}
        </TabMenu>
      );
  }

  return null;
};

export default Body;
