import { Mixin } from 'mixwith';
import { notify } from '_common/components/ToastSystem';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';

export default Mixin(
  (superclass) =>
    class InsertInlineHandler extends superclass {
      // ##########################################################
      //               handle insert inline element
      // ##########################################################
      /**
       * @param {ActionContext} actionContext
       * @param {*} newNode
       */
      _handleInsertInlineDefaultNode(actionContext, baseNode, newNode, selectionOptions = {}) {
        this.selectionManager.fixCollapsedTextSelection(selectionOptions);

        if (selectionOptions.forceInlineSplit) {
          const range = EditorSelectionUtils.getRange();
          // split inline selection
          const { anchorNode, anchorOffset } = EditorSelectionUtils.splitInlineTextElements(
            baseNode,
            range.startContainer,
            range.startOffset,
          );

          if (anchorNode && anchorNode !== range.startContainer && baseNode.contains(anchorNode)) {
            this.selectionManager.setCaret(anchorNode, 'INDEX', anchorOffset);
          }

          actionContext.addChangeUpdatedNode(baseNode);
        }

        if (selectionOptions.forceBlockSplit) {
          const { before } = this.splitSelectionContent(actionContext);
          this.selectionManager.setCaret(before, 'INSIDE_END');
        }

        if (typeof newNode === 'string') {
          this.insertTextInline(actionContext, newNode);
        } else {
          this.insertInlineNode(actionContext, newNode);
        }

        // this.selectionManager.setCaret(node, 'END');
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} citationElement
       */
      _handleInsertInlineCitationNode(actionContext, baseNode, citationElement, selectionOptions) {
        const selection = EditorSelectionUtils.getSelection();
        const anchorNode = selection.anchorNode;
        const anchorOffset = selection.anchorOffset;

        let closestCitationGroup = DOMUtils.findClosestCitationGroup(anchorNode, anchorOffset);

        if (closestCitationGroup) {
          const citationReference = citationElement.getAttribute('element_reference');
          if (
            closestCitationGroup.querySelector(
              `citation-element[element_reference="${citationReference}"]`,
            )
          ) {
            notify({
              type: 'warning',
              title: 'global.warning',
              message: 'editor.warnings.insert',
            });
          } else {
            closestCitationGroup.addCitationElement(citationElement);
            actionContext.addChangeUpdatedNode(closestCitationGroup);

            this.selectionManager.setCaret(closestCitationGroup, 'POST');
          }
        } else {
          closestCitationGroup = DOMElementFactory.buildElement(
            ELEMENTS.CitationsGroupElement.IDENTIFIER,
            {
              element_type: ELEMENTS.CitationsGroupElement.IDENTIFIER,
            },
          );

          closestCitationGroup.addCitationElement(citationElement);

          this._handleInsertInlineDefaultNode(
            actionContext,
            baseNode,
            closestCitationGroup,
            selectionOptions,
          );
          this.selectionManager.setCaret(closestCitationGroup, 'POST');
        }
      }

      /**
       * handle insert inline in text element
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       * @param {Node} baseNode
       * @param {Node} anchorNode
       */
      _handleInsertInlineNodeOnTextElement(
        actionContext,
        newNode,
        baseNode,
        anchorNode,
        selectionOptions,
      ) {
        switch (newNode.tagName) {
          case ELEMENTS.CitationElement.TAG:
            this._handleInsertInlineCitationNode(
              actionContext,
              baseNode,
              newNode,
              selectionOptions,
            );
            break;
          default:
            this._handleInsertInlineDefaultNode(actionContext, baseNode, newNode, selectionOptions);
            break;
        }
      }

      /**
       * handle insert inline in a figure
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       * @param {Node} baseNode
       * @param {Node} anchorNode
       */
      _handleInsertInlineNodeOnFigure(
        actionContext,
        newNode,
        baseNode,
        anchorNode,
        selectionOptions,
      ) {
        const closest = DOMUtils.closest(anchorNode, ['FIGCAPTION']);
        if (closest && !closest.hasAttribute('lock')) {
          this._handleInsertInlineNodeOnTextElement(
            actionContext,
            newNode,
            baseNode,
            anchorNode,
            selectionOptions,
          );
        }
      }

      /**
       * handle insert inline in a table
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       * @param {Node} baseNode
       * @param {Node} anchorNode
       */
      _handleInsertInlineNodeOnTable(
        actionContext,
        newNode,
        baseNode,
        anchorNode,
        selectionOptions,
      ) {
        const closest = DOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);
        if (closest && !closest.hasAttribute('lock')) {
          if (closest.tagName === ELEMENTS.TableCellElement.TAG) {
            const tdLevel0Node = DOMUtils.findNodeLevel0(closest, anchorNode);
            if (tdLevel0Node && tdLevel0Node.tagName !== ELEMENTS.TableElement.TAG) {
              this.handleInsertInlineNodeOnCollapsedSelection(
                actionContext,
                newNode,
                tdLevel0Node,
                anchorNode,
                selectionOptions,
              );
            }
          }
        }
      }

      _handleInsertInlineOnContainerElement(
        actionContext,
        newNode,
        baseNode,
        anchorNode,
        selectionOptions,
      ) {
        if (DOMUtils.BLOCK_CONTAINER_ELEMENTS.includes(anchorNode.tagName)) {
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
          }
        }

        let subLevel0Node;
        if (baseNode === anchorNode) {
          subLevel0Node = baseNode.firstChild;
        } else {
          subLevel0Node = DOMUtils.findNodeLevel0(baseNode, anchorNode);
        }
        if (subLevel0Node) {
          this.handleInsertInlineNodeOnCollapsedSelection(
            actionContext,
            newNode,
            subLevel0Node,
            anchorNode,
            selectionOptions,
          );
        }
      }

      /**
       * @description insert node inside a level0 node on collapsed selection
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       * @param {Node} baseNode
       * @param {Node} anchorNode
       */
      handleInsertInlineNodeOnCollapsedSelection(
        actionContext,
        newNode,
        baseNode,
        anchorNode,
        selectionOptions = {},
      ) {
        if (!baseNode || DOMUtils.isNodeAContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
          }
        }

        if (baseNode) {
          if (anchorNode) {
            if (DOMUtils.isNodeEditableTextElement(baseNode)) {
              this.selectionManager.fixCollapsedTextSelection({
                forceTextAsWrap: !!selectionOptions.forceTextAsWrap,
              });

              // SELECTION IS A DEFAULT TEXT ELEMENT
              this._handleInsertInlineNodeOnTextElement(
                actionContext,
                newNode,
                baseNode,
                anchorNode,
                selectionOptions,
              );
            } else if (!DOMUtils.isBlockNodeEditable(baseNode)) {
              // SELECTION IS A NON-EDITABLE ELEMENT
              // does nothing
            } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
              // SELECTION IS A FIGURE
              this._handleInsertInlineNodeOnFigure(
                actionContext,
                newNode,
                baseNode,
                anchorNode,
                selectionOptions,
              );
            } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
              // SELECTION IS A TABLE
              this._handleInsertInlineNodeOnTable(
                actionContext,
                newNode,
                baseNode,
                anchorNode,
                selectionOptions,
              );
            } else if (DOMUtils.isNodeAContainerElement(baseNode)) {
              this._handleInsertInlineOnContainerElement(
                actionContext,
                newNode,
                baseNode,
                anchorNode,
                selectionOptions,
              );
            }
          }
        }
      }

      /**
       * @description insert node inside a level0 node on non-collapsed selection
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       */
      /* eslint-disable-next-line */
      handleInsertInlineNodeOnMultiSelection(actionContext, newNode, selectionOptions) {
        if (this.joinSelectionContent(actionContext)) {
          const selection = EditorSelectionUtils.getSelection();
          const baseNode = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);
          const anchorNode = selection.anchorNode;
          this.handleInsertInlineNodeOnCollapsedSelection(
            actionContext,
            newNode,
            baseNode,
            anchorNode,
            selectionOptions,
          );
        }
      }
    },
);
