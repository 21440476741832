import { mix } from 'mixwith';
import { ElementNodeBuilder } from 'Editor/services/Model';
import { ParseMapper } from 'Editor/services/Parsers';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import DOMSanitizer from 'Editor/services/DOMUtilities/DOMSanitizer/DOMSanitizer';
import { ELEMENTS } from 'Editor/services/consts';
import PageNodeParser from './PageNodeParser';
import RedactedElementParser from './RedactedElementParser';
import ApprovedElementParser from './ApprovedElementParser';
import ImageElementParser from './ImageElementParser';
import SectionBreakElementParser from './SectionBreakElementParser';
import TableOfContentsElementParser from './TableOfContentsElementParser';
import ListOfFiguresElementParser from './ListOfFiguresElementParser';
import ListOfTablesElementParser from './ListOfTablesElementParser';
import CitationGroupElementParser from './CitationGroupElementParser';
import NoteElementParser from './NoteElementParser';
import KeywordsElementParser from './KeywordsElementParser';
import AuthorsElementParser from './AuthorsElementParser.js';
import IgnoreElementsParser from './IgnoreElementsParser';
import CrossReferenceParser from './CrossReferenceParser';
import EquationElementParser from './EquationElementParser';
import ParagraphElementParser from './ParagraphElementParser';
import TableElementsParser from './TableElementsParser';
import FieldElementParser from './FieldElementParser';
import FormatElementParser from './FormatElementParser';
import NonEditableElementParser from './NonEditableElementParser.js';
import PlaceholderElementParser from './PlaceholderElementParser.js';
import HyperlinkElementParser from './HyperlinkElementParser';
import ReferencesSectionElementParser from './ReferencesSectionElementParser';
import TabElementParser from './TabElementParser';
import TextNodeParser from './TextNodeParser';
/* eslint-disable class-methods-use-this */

const DENIED_CLASS_NAMES = [];

class NodeParser {
  constructor(editorContext) {
    this.editorContext = editorContext;
  }

  parseNode(representation) {
    if (!representation) {
      return null;
    }
    if (
      representation.hasAttribute('ispagenode') &&
      representation.getAttribute('ispagenode') === true
    ) {
      return this.parsePageNode(representation);
    }
    return this.parseElement(representation);
  }

  parseElement(representation) {
    const elementType = ParseMapper.getElementTypeForNode(representation);
    if (NodeParser.shouldParseElement(elementType)) {
      if (elementType === 'text') {
        return this.parseTextNode(representation.nodeValue);
      }
      if (elementType === 'redacted') {
        return this.parseRedactedNode(representation);
      }
      if (elementType === 'approved') {
        return this.parseApprovedNode(representation);
      }
      if (elementType === ELEMENTS.FigureElement.ELEMENT_TYPE) {
        return this.parseFigureNode(representation);
      }
      if (elementType === ELEMENTS.ImageElement.ELEMENT_TYPE) {
        return this.parseImageElement(representation);
      }
      if (
        elementType === ELEMENTS.ColumnBreakElement.ELEMENT_TYPE ||
        elementType === ELEMENTS.PageBreakElement.ELEMENT_TYPE ||
        elementType === ELEMENTS.SectionBreakElement.ELEMENT_TYPE ||
        elementType === ELEMENTS.TableOfContentsElement.ELEMENT_TYPE ||
        elementType === ELEMENTS.ListOfFiguresElement.ELEMENT_TYPE ||
        elementType === ELEMENTS.ListOfTablesElement.ELEMENT_TYPE ||
        elementType === ELEMENTS.KeywordsElement.ELEMENT_TYPE ||
        elementType === ELEMENTS.AuthorsElement.ELEMENT_TYPE
      ) {
        return this.parseNonEditableElement(representation, elementType);
      }
      if (elementType === ELEMENTS.CitationsGroupElement.ELEMENT_TYPE) {
        return this.parseCitationGroupNode(representation);
      }
      if (elementType === ELEMENTS.CitationElement.ELEMENT_TYPE) {
        return this.parseCitation(representation);
      }
      if (elementType === 'note') {
        return this.parseNote(representation);
      }
      // TODO: remove first if clause after database patch
      if (
        elementType === 'cross-reference' ||
        elementType === ELEMENTS.CrossReferenceElement.ELEMENT_TYPE
      ) {
        return this.parseCrossReference(representation);
      }
      if (elementType === 'equation') {
        return this.parseEquation(representation);
      }

      if (
        elementType === ELEMENTS.ParagraphElement.ELEMENT_TYPE ||
        Object.values(ELEMENTS.ParagraphElement.BASE_STYLES).includes(elementType)
      ) {
        return this.parseParagraph(representation);
      }

      if (elementType === ELEMENTS.TableElement.ELEMENT_TYPE) {
        return this.parseTable(representation);
      }

      if (elementType === ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.ELEMENT_TYPE) {
        return this.parseTableBody(representation);
      }

      if (elementType === ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.ELEMENT_TYPE) {
        return this.parseTableRow(representation);
      }

      if (elementType === ELEMENTS.TableCellElement.ELEMENT_TYPE) {
        return this.parseTableCell(representation);
      }

      if (elementType === ELEMENTS.FieldElement.ELEMENT_TYPE) {
        return this.parseField(representation);
      }

      if (elementType === ELEMENTS.FormatElement.ELEMENT_TYPE) {
        return this.parseFormat(representation);
      }

      if (elementType === ELEMENTS.PlaceholderElement.ELEMENT_TYPE) {
        return this.parsePlaceholder(representation);
      }

      if (elementType === ELEMENTS.HyperlinkElement.ELEMENT_TYPE) {
        return this.parseHyperlinkElement(representation);
      }

      if (elementType === ELEMENTS.ReferencesSectionElement.ELEMENT_TYPE) {
        return this.parseReferenceSectionElement(representation);
      }

      if (elementType === ELEMENTS.TabElement.ELEMENT_TYPE) {
        return this.parseTabElement(representation);
      }

      const builder = new ElementNodeBuilder();
      builder.addElementType(elementType);
      this.parseChildren(representation, builder);
      this.parseProperties(representation, builder);
      this.parseClassNames(representation, builder);
      if (representation.attributes.status) {
        builder.addStatus(representation.attributes.status.nodeValue);
      }
      if (representation.attributes.task) {
        builder.addTasks(representation.attributes.task.split(','));
      }
      if (representation.attributes.parent_id) {
        builder.addParentId(representation.attributes.parent_id.nodeValue);
      }
      if (representation.attributes.id) {
        builder.addId(representation.attributes.id.nodeValue);
      }

      builder.addDataset(representation.dataset);

      return builder.getNode();
    }
    return this.ignoreElementNode(representation);
  }

  parseChildren(representation, builder) {
    if (representation.childNodes !== null && representation.childNodes.length) {
      const length = representation.childNodes.length;
      for (let index = 0; index < length; index++) {
        const child = representation.childNodes[index];
        if (this.shouldParseChild(child)) {
          builder.addChild(this.parseElement(child));
        }
      }
    }
  }

  parseProperties(representation, builder) {
    if (representation.attributes != null && representation.attributes.length) {
      Object.keys(representation.attributes)
        .map((element) => representation.attributes[element].nodeName)
        .filter((element) => this.shouldParseProperty(representation, element))
        .forEach((key) => {
          if (key === 'style') {
            builder.addStyleProperty(representation.style, representation.nodeName);
          } else {
            builder.addProperty(key, representation.attributes[key].nodeValue);
          }
        });
    }
  }

  parseClassNames() {
    // if (representation.classList != null && representation.classList.length > 0) {
    //   const classNames = Array.from(representation.classList)
    //     .filter(className => this.shouldParseClassName(representation, className))
    //     .join(' ');
    //   builder.addProperty('s', classNames);
    // }
  }

  static shouldParseElement(elementType) {
    const elementsToIgnore = DOMUtils.BLOCK_FRONTEND_ONLY_ELEMENTS;

    return elementType && !elementsToIgnore.includes(elementType);
  }

  shouldParseChild(child) {
    return child.nodeType === 3 || DOMUtils.ALLOWED_CHILDREN_TO_PARSE.includes(child.tagName);
  }

  shouldParseProperty(representation, property) {
    if (property.indexOf('data-') === 0) {
      return false;
    }
    if (property === 'list_id' || property === 'list_level') {
      return false;
    }
    const allowedProperties = DOMSanitizer.allowedAttributesForTag(representation.nodeName);
    return (
      allowedProperties && Array.isArray(allowedProperties) && allowedProperties.includes(property)
    );
  }

  shouldParseClassName(representation, className) {
    if (DENIED_CLASS_NAMES.includes(className)) {
      return false;
    }
    return true;
  }
}

class NodeParserMix extends mix(NodeParser).with(
  PageNodeParser,
  RedactedElementParser,
  ApprovedElementParser,
  ImageElementParser,
  SectionBreakElementParser,
  TableOfContentsElementParser,
  ListOfFiguresElementParser,
  ListOfTablesElementParser,
  CitationGroupElementParser,
  NoteElementParser,
  KeywordsElementParser,
  AuthorsElementParser,
  IgnoreElementsParser,
  CrossReferenceParser,
  EquationElementParser,
  ParagraphElementParser,
  TableElementsParser,
  FieldElementParser,
  FormatElementParser,
  NonEditableElementParser,
  PlaceholderElementParser,
  HyperlinkElementParser,
  ReferencesSectionElementParser,
  TabElementParser,
  TextNodeParser,
) {}

export default NodeParserMix;
