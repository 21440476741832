/* eslint-disable no-plusplus */
import { Mixin, mix } from 'mixwith';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import OperationDebouncer from 'Editor/services/Mixins/OperationDebouncer';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import { TrackInsertElement, TrackDeleteElement } from 'Editor/services/VisualizerManager';
import { Logger } from '_common/services';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class extends mix(superclass).with(OperationDebouncer) {
      hasClosestSuggestion(node) {
        let focusClosestInsertNode = DOMUtils.closest(node, 'TRACK-INS-ELEMENT');
        if (focusClosestInsertNode) {
          if (!this.isUserAuthor(focusClosestInsertNode)) {
            focusClosestInsertNode = null;
          }
        }
        return focusClosestInsertNode;
      }

      insertAddParagraphMarker(actionContext, referenceNode, siblingId) {
        if (referenceNode && siblingId) {
          // remove BR if exist
          const elements = referenceNode.querySelectorAll('BR');
          Array.from(elements).forEach((br) => {
            br.parentNode.removeChild(br);
          });
          const marker = document.createElement('SPAN');
          DOMUtils.appendNode(referenceNode, marker, false);
          const markParagraph = this.getTrackInsElement(
            this.getProperSuggestionRef(actionContext, marker),
          );

          if (DOMUtils.isNodeAContainerElement(referenceNode.parentNode)) {
            markParagraph.setAttribute('replacewithsibling', siblingId);
          } else {
            markParagraph.setAttribute('replacewith', siblingId);
          }

          DOMNormalizer.normalizeTree(markParagraph, referenceNode.id);

          DOMUtils.replaceNode(referenceNode, markParagraph, marker);

          if (marker && marker.parentNode != null) {
            marker.remove();
          }

          actionContext.addChangeUpdatedNode(referenceNode);
        }
      }

      // eslint-disable-next-line class-methods-use-this
      isDeleteParagraphMarker(node) {
        return (
          node &&
          node.tagName === 'TRACK-DEL-ELEMENT' &&
          (node?.isParagraphMarker() || node?.hasChildParagraphMarker())
        );
      }

      // eslint-disable-next-line class-methods-use-this
      isAddParagraphMarker(node) {
        return (
          node &&
          node.tagName === 'TRACK-INS-ELEMENT' &&
          (node?.isParagraphMarker() || node?.hasChildParagraphMarker())
        );
      }

      isParagraphMarker(node) {
        return this.isAddParagraphMarker(node) || this.isDeleteParagraphMarker(node);
      }

      isUserAuthor(node) {
        if (
          !node ||
          !(node.tagName === 'TRACK-DEL-ELEMENT' || node.tagName === 'TRACK-INS-ELEMENT')
        ) {
          return false;
        }
        try {
          if (node.hasAttribute('author')) {
            return node.getAttribute('author') === `${this.user.id}`;
          }
          return this.dataManager?.suggestions?.isUserAuthorOfSuggestion(
            node.getAttribute('element_reference'),
          );
        } catch (error) {
          Logger.captureException(error);
          return false;
        }
      }

      isNodeTrackedAction(node, tags) {
        if (!node) {
          return null;
        }
        if (Array.isArray(tags)) {
          if (tags.includes(node.tagName)) {
            if (this.isUserAuthor(node)) {
              return node;
            }
          }
        } else if (node.tagName === tags) {
          if (this.isUserAuthor(node)) {
            return node;
          }
        }
        return null;
      }

      hasNextContiguousTrackedNode(node, tags) {
        if (node) {
          let workingNode = DOMUtils.findNextAncestorSibling(node);

          while (
            workingNode &&
            DOMUtils.INLINE_FRONTEND_ONLY_ELEMENTS.includes(workingNode.tagName)
          ) {
            workingNode = DOMUtils.findNextAncestorSibling(workingNode);
          }

          if (workingNode) {
            if (tags.includes(workingNode.tagName)) {
              if (this.isUserAuthor(workingNode)) {
                return workingNode;
              }
            }

            let firstChild = workingNode.firstChild;
            while (
              firstChild &&
              firstChild.nextSibling &&
              ((firstChild.nodeType === Node.TEXT_NODE && firstChild.textContent.length === 0) ||
                DOMUtils.INLINE_FRONTEND_ONLY_ELEMENTS.includes(firstChild.tagName))
            ) {
              firstChild = firstChild.nextSibling;
            }

            if (firstChild != null && tags.includes(firstChild.tagName)) {
              if (this.isUserAuthor(firstChild)) {
                return firstChild;
              }
            }
          }
        }
        return null;
      }

      hasPreviousContiguousTrackedNode(node, tags) {
        if (node) {
          let workingNode = DOMUtils.findPreviousAncestorSibling(node);

          while (
            workingNode &&
            DOMUtils.INLINE_FRONTEND_ONLY_ELEMENTS.includes(workingNode.tagName)
          ) {
            workingNode = DOMUtils.findPreviousAncestorSibling(workingNode);
          }

          if (workingNode) {
            if (tags.includes(workingNode.tagName)) {
              if (this.isUserAuthor(workingNode)) {
                return workingNode;
              }
            }

            let lastChild = workingNode.lastChild;
            while (
              lastChild &&
              lastChild.previousSibling &&
              ((lastChild.nodeType === Node.TEXT_NODE && lastChild.textContent.length === 0) ||
                DOMUtils.INLINE_FRONTEND_ONLY_ELEMENTS.includes(lastChild.tagName))
            ) {
              lastChild = lastChild.previousSibling;
            }

            if (lastChild != null && tags.includes(lastChild.tagName)) {
              if (this.isUserAuthor(lastChild)) {
                return lastChild;
              }
            }
          }
        }
        return null;
      }

      hasNextContiguousTrackedAction(trackedNode) {
        return this.hasNextContiguousTrackedNode(trackedNode, [trackedNode.tagName]);
      }

      hasPreviousContiguousTrackedAction(trackedNode) {
        return this.hasPreviousContiguousTrackedNode(trackedNode, [trackedNode.tagName]);
      }

      getReferenceOfNearestSuggestions(marker) {
        let created = false;
        if (!marker) {
          marker = DOMElementFactory.buildElement('span');
          let range = EditorSelectionUtils.getRange();
          range.insertNode(marker);
          created = true;
        }
        const result = {};
        const focusClosestInsertNode = DOMUtils.closest(marker, 'TRACK-INS-ELEMENT');
        if (
          focusClosestInsertNode &&
          focusClosestInsertNode !== marker &&
          this.isUserAuthor(focusClosestInsertNode)
        ) {
          result.closest = focusClosestInsertNode.getAttribute('element_reference');
        } else {
          const nextSiblingTracked = this.isNodeTrackedAction(marker.nextSibling, [
            'TRACK-INS-ELEMENT',
            'TRACK-DEL-ELEMENT',
          ]);
          const previousSiblingTracked = this.isNodeTrackedAction(marker.previousSibling, [
            'TRACK-INS-ELEMENT',
            'TRACK-DEL-ELEMENT',
          ]);
          if (
            nextSiblingTracked &&
            previousSiblingTracked &&
            this.isUserAuthor(nextSiblingTracked) &&
            this.isUserAuthor(previousSiblingTracked)
          ) {
            result.previous = previousSiblingTracked.getAttribute('element_reference');
            result.next = nextSiblingTracked.getAttribute('element_reference');
            result.nextNode = nextSiblingTracked;
          } else if (nextSiblingTracked && this.isUserAuthor(nextSiblingTracked)) {
            result.next = nextSiblingTracked.getAttribute('element_reference');
          } else if (previousSiblingTracked && this.isUserAuthor(previousSiblingTracked)) {
            result.previous = previousSiblingTracked.getAttribute('element_reference');
          } else {
            const nextTracked = this.hasNextContiguousTrackedNode(marker, [
              'TRACK-DEL-ELEMENT',
              'TRACK-INS-ELEMENT',
            ]);
            const previousTracked = this.hasPreviousContiguousTrackedNode(marker, [
              'TRACK-DEL-ELEMENT',
              'TRACK-INS-ELEMENT',
            ]);
            if (
              nextTracked &&
              previousTracked &&
              this.isUserAuthor(nextTracked) &&
              this.isUserAuthor(previousTracked)
            ) {
              result.previous = previousTracked.getAttribute('element_reference');
              result.next = nextTracked.getAttribute('element_reference');
              result.nextNode = nextTracked;
            } else if (nextTracked && this.isUserAuthor(nextTracked)) {
              result.next = nextTracked.getAttribute('element_reference');
            } else if (previousTracked && this.isUserAuthor(previousTracked)) {
              result.previous = previousTracked.getAttribute('element_reference');
            }
          }
        }
        if (created) {
          marker.remove();
        }
        return result;
      }

      getTrackInsElement(actionId, text) {
        const element = DOMElementFactory.buildElement('track-ins-element');
        element.setAttribute('element_type', 'track-insert');
        element.setAttribute('element_reference', actionId);
        element.setAttribute('id', DOMUtils.generateRandomNodeId());
        element.setAttribute('author', this.user.id);
        if (text) {
          element.appendChild(document.createTextNode(text));
        }
        return element;
      }

      getTrackDelElement(actionId) {
        const element = DOMElementFactory.buildElement('track-del-element');
        element.setAttribute('element_type', 'tracked-delete');
        element.setAttribute('element_reference', actionId);
        element.setAttribute('author', this.user.id);
        element.setAttribute('id', DOMUtils.generateRandomNodeId());
        return element;
      }

      getParagraphMarker(type, ref, attr) {
        const element = DOMElementFactory.buildElement(type);
        element.setAttribute(
          'element_type',
          type === 'track-del-element' ? 'tracked-delete' : 'tracked-insert',
        );
        element.setAttribute('element_reference', ref);
        element.setAttribute('author', this.user.id);
        element.setAttribute('id', DOMUtils.generateRandomNodeId());
        const attrKeys = Object.keys(attr);
        for (let index = 0; index < attrKeys.length; index++) {
          const key = attrKeys[index];
          element.setAttribute(key, attr[key]);
        }
        return element;
      }

      insertContentsBefore(actionContext, destination, contents, before) {
        const queue = Array.from(contents.childNodes);
        while (queue.length) {
          const transportNode = queue.shift();
          if (
            this.isNodeTrackedAction(transportNode, ['TRACK-DEL-ELEMENT']) &&
            transportNode.isMergeable()
          ) {
            queue.unshift(...Array.from(transportNode.childNodes));
            actionContext.addReferenceToRefresh(transportNode.getAttribute('element_reference'));
          } else {
            destination.insertBefore(transportNode, before);
          }
        }
      }

      appendContents(actionContext, destination, contents) {
        const queue = Array.from(contents.childNodes);
        while (queue.length) {
          const transportNode = queue.shift();
          if (
            this.isNodeTrackedAction(transportNode, ['TRACK-DEL-ELEMENT']) &&
            transportNode.isMergeable()
          ) {
            queue.unshift(...Array.from(transportNode.childNodes));
            actionContext.addReferenceToRefresh(transportNode.getAttribute('element_reference'));
          } else {
            destination.appendChild(transportNode);
          }
        }
      }

      // eslint-disable-next-line class-methods-use-this
      joinParagraphContents(actionContext, referenceNode, nodeToJoin) {
        if (
          referenceNode &&
          nodeToJoin &&
          referenceNode.tagName === nodeToJoin.tagName &&
          referenceNode.tagName === ELEMENTS.ParagraphElement.TAG
        ) {
          if (referenceNode !== nodeToJoin) {
            let brElements;
            // remove BR elements from referenceNode
            brElements = referenceNode.querySelectorAll('BR');
            Array.from(brElements).forEach((br) => {
              br.parentNode.removeChild(br);
            });

            // remove BR elements from nodeToJoin
            brElements = nodeToJoin.querySelectorAll('BR');
            Array.from(brElements).forEach((br) => {
              br.parentNode.removeChild(br);
            });

            while (nodeToJoin.firstChild) {
              DOMUtils.appendNode(referenceNode, nodeToJoin.firstChild);
            }

            actionContext.addChangeUpdatedNode(referenceNode);
            this.removeBlockElementFromDOM(actionContext, nodeToJoin);
          }
        }
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} range
       */
      addDeleteSuggestionOnRange(actionContext, range, savedRangeMarkers) {
        const opResult = {};
        let contents = range.cloneContents();

        // check if all the elements are track del or track ins
        if (
          Array.from(contents.childNodes).every(
            (val) =>
              val.tagName ===
              ELEMENTS.TrackDeleteElement.TAG /*  || val.tagName === 'TRACK-INS-ELEMENT' */,
          ) ||
          DOMUtils.closest(range.commonAncestorContainer, [ELEMENTS.TrackDeleteElement.TAG])
        ) {
          return {};
        }

        // extract all the contents
        contents = this.extractRangeContents(actionContext, range);

        // check extracted elements
        const childNodes = contents.childNodes;
        for (let i = 0; i < childNodes.length; i++) {
          const child = childNodes[i];

          if (child.nodeName === 'BR') {
            child.parentNode?.removeChild(child);
          }
          if (
            (child instanceof TrackInsertElement || child instanceof TrackDeleteElement) &&
            EditorDOMUtils.isEmptyElement(child)
          ) {
            child.parentNode?.removeChild(child);
          }
        }

        // insert marker
        const marker = DOMElementFactory.buildElement('span');
        range.insertNode(marker);
        marker.parentNode.normalize();

        let markerNextSibling = marker.nextSibling;

        // check for spans and other markers
        while (
          markerNextSibling &&
          DOMUtils.INLINE_FRONTEND_ONLY_ELEMENTS.includes(markerNextSibling.tagName)
        ) {
          markerNextSibling = markerNextSibling.nextSibling;
        }

        let markerPreviousSibling = marker.previousSibling;

        // check for spans and other markers
        while (
          markerPreviousSibling &&
          DOMUtils.INLINE_FRONTEND_ONLY_ELEMENTS.includes(markerPreviousSibling.tagName)
        ) {
          markerPreviousSibling = markerPreviousSibling.previousSibling;
        }

        const nextSiblingTracked = this.isNodeTrackedAction(markerNextSibling, [
          'TRACK-DEL-ELEMENT',
        ]);
        const previousSiblingTracked = this.isNodeTrackedAction(markerPreviousSibling, [
          'TRACK-DEL-ELEMENT',
        ]);
        let closestSuggestion = DOMUtils.closest(marker, 'TRACK-DEL-ELEMENT');
        if (closestSuggestion) {
          opResult.ref = closestSuggestion.getAttribute('element_reference');
          opResult.node = closestSuggestion;
          while (contents.firstChild) {
            marker.parentNode.appendChild(contents.firstChild);
          }

          marker.remove();
          return opResult;
        }

        closestSuggestion = this.hasClosestSuggestion(marker);
        if (closestSuggestion) {
          actionContext.addReferenceToRefresh(closestSuggestion.getAttribute('element_reference'));
          opResult.ref = closestSuggestion.getAttribute('element_reference');
          opResult.node = closestSuggestion;
        }

        if (
          nextSiblingTracked &&
          nextSiblingTracked.isMergeable() &&
          previousSiblingTracked &&
          previousSiblingTracked.isMergeable()
        ) {
          this.insertContentsBefore(
            actionContext,
            nextSiblingTracked,
            contents,
            nextSiblingTracked.firstChild,
          );
          actionContext.addChangeUpdatedNode(nextSiblingTracked);
          const oldTrackedRef = previousSiblingTracked.getAttribute('element_reference');
          previousSiblingTracked.setAttribute(
            'element_reference',
            nextSiblingTracked.getAttribute('element_reference'),
          );
          opResult.ref = nextSiblingTracked.getAttribute('element_reference');
          opResult.node = nextSiblingTracked;
          actionContext.addReferenceToRefresh(oldTrackedRef);
          actionContext.addReferenceToRefresh(nextSiblingTracked.getAttribute('element_reference'));
        } else if (nextSiblingTracked && nextSiblingTracked.isMergeable()) {
          this.insertContentsBefore(
            actionContext,
            nextSiblingTracked,
            contents,
            nextSiblingTracked.firstChild,
          );

          // check for paragraph markers
          if (
            nextSiblingTracked.lastElementChild?.isParagraphMarker?.() &&
            nextSiblingTracked.lastElementChild?.author === nextSiblingTracked.author
          ) {
            DOMUtils.insertNodeAfter(
              nextSiblingTracked.parentNode,
              nextSiblingTracked.lastElementChild,
              nextSiblingTracked,
            );
          }

          actionContext.addChangeUpdatedNode(nextSiblingTracked);
          actionContext.addReferenceToRefresh(nextSiblingTracked.getAttribute('element_reference'));
          opResult.ref = nextSiblingTracked.getAttribute('element_reference');
          opResult.node = nextSiblingTracked;
        } else if (previousSiblingTracked && previousSiblingTracked.isMergeable()) {
          this.appendContents(actionContext, previousSiblingTracked, contents);

          // check for paragraph markers
          if (
            previousSiblingTracked.lastElementChild?.isParagraphMarker?.() &&
            previousSiblingTracked.lastElementChild?.author === previousSiblingTracked.author
          ) {
            DOMUtils.insertNodeAfter(
              previousSiblingTracked.parentNode,
              previousSiblingTracked.lastElementChild,
              previousSiblingTracked,
            );
          }

          actionContext.addChangeUpdatedNode(previousSiblingTracked);
          actionContext.addReferenceToRefresh(
            previousSiblingTracked.getAttribute('element_reference'),
          );
          opResult.ref = previousSiblingTracked.getAttribute('element_reference');
          opResult.node = previousSiblingTracked;
        } else if (contents.childNodes.length > 0) {
          // TODO:

          const trackNode = this.getTrackDelElement(actionContext.id);
          this.appendContents(actionContext, trackNode, contents);

          if (marker.parentNode === DOMUtils.getPageNode()) {
            const data = NodeDataBuilder.buildNodeData({
              data: {
                ...this.documentParser.parse(trackNode),
                parent_id: marker.parentNode.id,
              },
            });
            this.inserBlockNodeOperation(actionContext, data, marker, 'AFTER');
            marker.remove();
          } else {
            // check for paragraph markers
            if (
              trackNode.lastElementChild?.isParagraphMarker?.() &&
              trackNode.lastElementChild?.author === trackNode.author
            ) {
              actionContext.addChangeAddedNode(trackNode.lastElementChild);
              DOMUtils.insertNodeAfter(marker.parentNode, trackNode.lastElementChild, marker);
            }

            if (trackNode.childNodes.length > 0) {
              DOMUtils.replaceNode(marker.parentNode, trackNode, marker);
              actionContext.addChangeAddedNode(trackNode);
            } else {
              marker.remove();
            }
          }
          let nextTracked;
          let previousTracked;
          if (!actionContext.isReplacing) {
            nextTracked = nextSiblingTracked || this.hasNextContiguousTrackedAction(trackNode);
            previousTracked =
              previousSiblingTracked || this.hasPreviousContiguousTrackedAction(trackNode);
          }
          opResult.ref = trackNode.getAttribute('element_reference');
          opResult.node = trackNode;
          if (nextTracked && previousTracked) {
            opResult.ref = nextTracked.getAttribute('element_reference');
            const oldTracked = nextTracked.getAttribute('element_reference');
            trackNode.setAttribute('element_reference', oldTracked);
            actionContext.addReferenceToRefresh(oldTracked);
          } else if (nextTracked) {
            opResult.ref = nextTracked.getAttribute('element_reference');
            const oldTracked = nextTracked.getAttribute('element_reference');
            trackNode.setAttribute('element_reference', oldTracked);
            actionContext.addReferenceToRefresh(oldTracked);
          } else if (previousTracked) {
            opResult.ref = previousTracked.getAttribute('element_reference');
            const oldTracked = previousTracked.getAttribute('element_reference');
            trackNode.setAttribute('element_reference', oldTracked);
            actionContext.addReferenceToRefresh(oldTracked);
          } else {
            opResult.node = trackNode;
            opResult.ref = trackNode.getAttribute('element_reference');
          }
        }

        if (marker && DOMUtils.parentContainsNode(this.page, marker)) {
          marker.remove();
        }

        if (savedRangeMarkers != null) {
          range = this.selectionManager.getRangeBasedOnSavedMarkers(savedRangeMarkers);
        } else {
          range = EditorSelectionUtils.getRange();
        }
        const parentContainer = range.commonAncestorContainer;

        // add a empty P if all the content is deleted from the PAGE or from inside a TD
        if (parentContainer === this.page || DOMUtils.isNodeAContainerElement(parentContainer)) {
          if (parentContainer.childNodes.length === 0) {
            if (parentContainer === this.page) {
              const data = NodeDataBuilder.buildParagraph({
                data: {
                  parent_id: parentContainer.id,
                },
              });
              this.inserBlockNodeOperation(actionContext, data, null, 'AFTER');
            } else if (DOMUtils.isNodeAContainerElement(parentContainer)) {
              const p = DOMElementFactory.createNewParagraphElement();
              DOMUtils.appendNode(parentContainer, p);
              this.selectionManager.setCaret(p, 'INDEX', 0);
              actionContext.addChangeAddedNode(p);
            }
          } else {
            // this.selectionManager.fixSelection();
          }
        }

        // check for editable elements, format-elements, track-insert, track-delete
        const closestEditableElement = DOMUtils.closest(parentContainer, [
          'FORMAT-ELEMENT',
          ELEMENTS.TrackInsertElement.TAG,
          ELEMENTS.TrackDeleteElement.TAG,
        ]);

        if (closestEditableElement) {
          if (
            closestEditableElement.parentNode !== this.page &&
            EditorDOMUtils.isEmptyElement(closestEditableElement)
          ) {
            if (closestEditableElement.tagName === 'FORMAT-ELEMENT') {
              this.stylesHandler.addArrayAppliersToPendingStyles({
                appliers: this.stylesHandler.getStyleAppliersFromElement(closestEditableElement),
                skipNextCheckStyles: true,
              });
            }

            actionContext.addChangeUpdatedNode(closestEditableElement);
            this.selectionManager.setCaret(closestEditableElement, 'PRE');
            closestEditableElement.parentNode.removeChild(closestEditableElement);
          } else if (
            closestEditableElement.parentNode === this.page &&
            EditorDOMUtils.isEmptyElement(closestEditableElement) &&
            closestEditableElement.childNodes.length === 0
          ) {
            if (closestEditableElement.previousSibling) {
              this.selectionManager.setCaret(closestEditableElement.previousSibling, 'INSIDE_END');
            } else if (closestEditableElement.nextSibling) {
              this.selectionManager.setCaret(closestEditableElement.nextSibling, 'INSIDE_START');
            } else {
              const paragraphData = NodeDataBuilder.buildNodeData({
                data: {
                  type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
                  parent_id: this.page.id,
                },
              });

              this.inserBlockNodeOperation(actionContext, paragraphData, null, 'AFTER');
            }

            this._removeBlockNode(actionContext, closestEditableElement);
          }
        }

        return opResult;
      }

      findClosestReference(direction, range) {
        let closest;
        let sibling;

        if (direction === 'LEFT') {
          closest = DOMUtils.closest(range.startContainer, 'TRACK-DEL-ELEMENT');
          if (closest) {
            return closest.getAttribute('element_reference');
          }
          sibling = this.isNodeTrackedAction(range.startContainer.previousSibling, [
            'TRACK-DEL-ELEMENT',
          ]);
          if (sibling) {
            return sibling.getAttribute('element_reference');
          }

          let lastChild = this.isNodeTrackedAction(
            range.startContainer.previousSibling?.lastChild,
            ['TRACK-DEL-ELEMENT'],
          );
          if (lastChild) {
            return lastChild.getAttribute('element_reference');
          }
        } else {
          closest = DOMUtils.closest(range.endContainer, 'TRACK-DEL-ELEMENT');
          if (closest) {
            return closest.getAttribute('element_reference');
          }
          sibling = this.isNodeTrackedAction(range.endContainer.nextSibling, ['TRACK-DEL-ELEMENT']);
          if (sibling) {
            return sibling.getAttribute('element_reference');
          }

          let firstChild = this.isNodeTrackedAction(range.endContainer.nextSibling?.firstChild, [
            'TRACK-DEL-ELEMENT',
          ]);
          if (firstChild) {
            return firstChild.getAttribute('element_reference');
          }
        }
        return null;
      }

      setCursorPosition(refs, direction, range = EditorSelectionUtils.getRange()) {
        let references = [];

        if (Array.isArray(refs)) {
          if (!refs.length) {
            references.push(this.findClosestReference(direction, range));
          } else {
            references = refs;
          }
        } else {
          references.push(refs);
        }

        const trackElements = {
          allTrackDel: [],
          allTrackIns: [],
        };

        for (let index = 0; index < references.length; index++) {
          const trackDel = Array.from(
            this.page.querySelectorAll(
              `track-del-element[element_reference="${references[index]}"]`,
            ),
          );
          const trackIns = Array.from(
            this.page.querySelectorAll(
              `track-ins-element[element_reference="${references[index]}"]`,
            ),
          );

          trackElements[references[index]] = {
            trackDel,
            trackIns,
          };

          trackElements.allTrackDel.push(...trackDel);
          trackElements.allTrackIns.push(...trackIns);
        }

        if (direction === 'LEFT') {
          const anchorClosestDel =
            trackElements[references[0]].trackDel.length > 0 &&
            trackElements[references[0]].trackIns.length === 0
              ? trackElements[references[0]].trackDel[0]
              : null;

          if (anchorClosestDel) {
            const closest = DOMUtils.closest(anchorClosestDel, ['CITATIONS-GROUP-ELEMENT']);
            if (closest) {
              if (
                anchorClosestDel.previousSibling &&
                anchorClosestDel.previousSibling.tagName === 'CITATION-ELEMENT'
              ) {
                range.setCaret(anchorClosestDel.previousSibling, 'INSIDE_END');
              } else {
                range.setCaret(anchorClosestDel, 'PRE');
              }
            } else if (anchorClosestDel.parentNode !== this.page) {
              range.setCaret(anchorClosestDel, 'PRE');
            } else {
              range.setCaret(anchorClosestDel, 'INSIDE_START');
            }
          } else {
            range.collapseToStart();
          }
        } else {
          // const focusClosestDel = DOMUtils.closest(
          //   EditorSelectionUtils.getSelection().focusNode,
          //   'TRACK-DEL-ELEMENT',
          // );

          const trackedDelLength = trackElements[references[0]].trackDel.length;
          const trackedInsLenght = trackElements[references[0]].trackIns.length;
          const focusClosestDel =
            trackedDelLength > 0 && trackedInsLenght === 0
              ? trackElements[references[0]].trackDel[trackedDelLength - 1]
              : null;

          if (focusClosestDel) {
            const closest = DOMUtils.closest(focusClosestDel, ['CITATIONS-GROUP-ELEMENT']);
            if (closest) {
              if (
                focusClosestDel.nextSibling &&
                focusClosestDel.nextSibling.tagName === 'CITATION-ELEMENT'
              ) {
                range.setCaret(focusClosestDel.nextSibling, 'INSIDE_START');
              } else {
                range.setCaret(focusClosestDel, 'POST');
              }
            } else if (focusClosestDel.parentNode !== this.page) {
              range.setCaret(focusClosestDel, 'POST');
            } else {
              range.setCaret(focusClosestDel, 'INSIDE_END');
            }
          } else {
            range.collapseToEnd();
          }
        }

        EditorSelectionUtils.applyRangeToSelection(range);
      }

      /**
       *
       * @param {ActionContext} actionContext
       * @param {*} marker
       */
      getProperSuggestionRef(actionContext, marker) {
        const scopes = this.getReferenceOfNearestSuggestions(marker);
        let suggRef = actionContext.id;
        if (scopes.closest) {
          suggRef = scopes.closest;
          actionContext.addReferenceToRefresh(scopes.closest);
        } else if (scopes.previous && scopes.next) {
          suggRef = scopes.previous;
          scopes.nextNode.setAttribute('element_reference', scopes.previous);
          actionContext.addReferenceToRefresh(scopes.previous);
          actionContext.addReferenceToRefresh(scopes.next);
        } else if (scopes.previous) {
          suggRef = scopes.previous;
          actionContext.addReferenceToRefresh(scopes.previous);
        } else if (scopes.next) {
          suggRef = scopes.next;
          actionContext.addReferenceToRefresh(scopes.next);
        }
        return suggRef;
      }
    },
);
