import { useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { closeAllModals } from '_common/modals/ModalsSlice';
import { usePDFContext } from 'PDF/PDFContext';

import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import {
  ShareModal,
  LikesModal,
  AboutModal,
  ChangeElementStatusModal,
  ConfirmationModal,
  ExportPDFModal,
} from '_common/modals';
import { SaveVersionModal, DocumentAuditLogModal } from 'Editor/modals';
import { PDFConfirmationModal, PDFTaskWatchModal, PDFExportingDocumentModal } from './Modals';
import usePDFData from './hooks/usePDFData';

const PDFModalConductor = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();

  const pdfData = usePDFData();
  const open = useSelector((state) => state.modals.open);
  const firstOpenModal = Object.entries(open).find(([, v]) => v)?.[0] as keyof typeof open;

  return (
    <IntlErrorBoundary
      fallbackType="modal"
      mockProps={{
        title: intl.formatMessage({ id: 'global.error' }),
        onClose: () => dispatch(closeAllModals()),
      }}
      disabled={!firstOpenModal}
    >
      {open.DocumentAuditLogModal && (
        <DocumentAuditLogModal data={pdfData ? { [pdfData.id]: pdfData } : null} />
      )}
      {open.PDFConfirmationModal && <PDFConfirmationModal />}
      {open.PDFTaskWatchModal && <PDFTaskWatchModal />}
      {open.SaveVersionModal && <SaveVersionModal saveVersion={pdfManager.saveVersion} />}
      {open.PDFExportingDocumentModal && <PDFExportingDocumentModal />}
      {open.ShareModal && <ShareModal />}
      {open.LikesModal && <LikesModal />}
      {open.AboutModal && <AboutModal />}
      {open.ChangeElementStatusModal && <ChangeElementStatusModal />}
      {open.ConfirmationModal && <ConfirmationModal />}
      {open.ExportPDFModal && <ExportPDFModal />}
    </IntlErrorBoundary>
  );
};

export default PDFModalConductor;
