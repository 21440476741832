import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Tooltip, usePopper, Button } from 'dodoc-design-system';

import { useDispatch, useSelector, usePublicProfile } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectUser } from 'Editor/redux/EditorStatusSlice';

import { FormattedDate, FormattedTime } from '_common/components';

import styles from './VersionCard.module.scss';

type VersionCardProps = {
  versions: Editor.Version[];
  isOverflowed?: boolean;
  activeCard?: number;
  setActiveCard: (index: number | undefined) => void;
} & (
  | { currentVersion: true; index?: undefined; version?: undefined }
  | {
      currentVersion?: false;
      index: number;
      version: Editor.Version;
    }
);

const VersionCard = ({
  versions,
  activeCard,
  setActiveCard,
  isOverflowed,
  ...props
}: VersionCardProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const loadedVersion = useSelector((state) => state.editor.status.loadedVersion);

  const profile = usePublicProfile(props.currentVersion ? user.id : props.version.creator);
  const { popperProps, referenceProps } = usePopper({
    placement: 'bottom-end',
  });
  const editDescription = () => {
    if (!props.currentVersion) {
      const versionIndex = versions.length - props.index - 1;
      dispatch(
        openAndUpdateModal({
          modal: 'SaveVersionModal',
          data: {
            type: 'editDescription',
            index: versionIndex,
            description: versions[versionIndex].description ?? '',
          },
        }),
      );
    }
  };

  const restoreVersion = () => {
    if (!props.currentVersion) {
      const ind = versions.length - props.index - 1;
      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            title: 'RESTORE_DOCUMENT_VERSION',
            message: 'ARE_YOU_SURE_YOU_WANT_TO_RESTORE_THIS_DOCUMENT_VERSION',
            messageValues: { versionNumber: ind + 1 },
            confirmButtonTextId: 'RESTORE_VERSION',
            confirmButtonType: 'primary',
            cancelButtonTextId: 'global.cancel',
            actionCode: 'restoreDocumentVersion',
            actionValue: { loadedVersion: ind },
            headerType: 'information',
            cancelButtonShow: true,
            width: '60rem',
          },
        }),
      );
    }
  };

  const handleActiveVersion = () => {
    const ind =
      props.index !== undefined && props.index !== null
        ? versions.length - props.index - 1
        : undefined;
    if (loadedVersion !== ind) {
      setActiveCard(props.index);
      EditorManager.getInstance().loadVersion(ind);
    }
  };

  return (
    <div
      id={
        props.index === undefined || props.index === null || Number.isNaN(props.index)
          ? 'currentVersion'
          : `${props.index}`
      }
      onClick={handleActiveVersion}
      className={`${styles.cardBox} ${props.currentVersion && styles.currentCard} ${
        activeCard === props.index && styles.activeCard
      } ${isOverflowed && styles.resizeCard}`}
    >
      <div className={`${styles.cardTitle} ${props.currentVersion && styles.currentVersion}`}>
        <div className={styles.version}>
          {props.currentVersion
            ? intl
                .formatMessage({ id: 'CURRENT_VERSION' }, { version: versions.length + 1 })
                .toUpperCase()
            : `v.${versions.length - props.index}`}
        </div>
        {!props.currentVersion && ( // TODO: To remove when Export Version and Save Copy are ready!!
          <>
            <Tooltip
              placement="bottom"
              content={intl.formatMessage({ id: 'global.moreActions' })}
              testId={`version-card-${props.index}-more-actions-tooltip`}
            >
              <Button
                size="medium"
                variant="link"
                style={{ marginLeft: '1rem', padding: 0 }}
                icon="Options"
                testId={`version-card-${props.index}-more-actions-button`}
                {...referenceProps}
              />
            </Tooltip>

            <Dropdown {...popperProps} testId={`version-card-${props.index}-actions-dropdown`}>
              {!props.currentVersion && (
                <Dropdown.Item
                  prefixIcon="Unapprove"
                  onClick={restoreVersion}
                  size="large"
                  testId={`version-card-${props.index}-more-actions-restore-version-item`}
                >
                  <FormattedMessage id="RESTORE_VERSION" />
                </Dropdown.Item>
              )}
              {!props.currentVersion && (
                <Dropdown.Item
                  prefixIcon="EditDescription"
                  onClick={editDescription}
                  size="large"
                  testId={`version-card-${props.index}-more-actions-edit-description-item`}
                >
                  <FormattedMessage id="settings.description.header" />
                </Dropdown.Item>
              )}
            </Dropdown>
          </>
        )}
      </div>
      <div className={styles.cardName}>{profile?.name}</div>

      {!props.currentVersion && (
        <>
          <div className={styles.cardDate}>
            <FormattedDate date={props.version.creation} />{' '}
            <FormattedTime type="meridiem" time={props.version.creation} />
          </div>
          <div
            className={`${styles.cardDescription} ${
              !props.version.description && styles.noDescription
            }`}
          >
            {props.version.description || intl.formatMessage({ id: 'NO_DESCRIPTION' })}
          </div>
        </>
      )}
      <div className={`${styles.dot} ${activeCard === props.index && styles.activeCard}`} />
    </div>
  );
};

export default VersionCard;
