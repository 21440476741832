import { MouseEvent, useCallback } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from '_common/hooks';
import { usePDFContext } from 'PDF/PDFContext';

import {
  clickOnPDFPage,
  selectAnnotation,
  setCreationSetting,
  setEditingAnnotation,
} from 'PDF/redux/PDFAnnotationsSlice';
import { setCursorMode } from 'PDF/redux/PDFGeneralSlice';

import {
  calculateRelativeCoordinates /* calculateSelectionRelativeCoordinates */,
} from '../AnnotationsLayer/Annotation/Utils';
import styles from './PDFPage.module.scss';
import { COLORS_HEX_MAP } from 'PDF/services';
import { newRTEObject } from 'utils';
import { setPulseData } from 'App/redux/onboardingSlice';

const useCursorMode = () => {
  const manager = usePDFContext();
  const dispatch = useDispatch();
  const cursorMode = useSelector((state) => state.pdf.general.cursorMode);
  const creationData = useSelector((state) => state.pdf.annotations.creation);
  const textboxPresetStyles = useSelector((state) => state.pdf.annotations.textbox.preset);

  const onMouseDown = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const page = e.nativeEvent
        .composedPath()
        .find(
          (element) =>
            element instanceof HTMLDivElement &&
            element.dataset.pageNum &&
            element.dataset.viewportScale,
        ) as HTMLDivElement | undefined;
      if (
        page &&
        page.dataset.pageNum &&
        page.dataset.viewportScale &&
        e.button === 0 // Only activate with the left mouse button
      ) {
        switch (cursorMode) {
          case 'Line':
          case 'Arrow':
          case 'Circle':
          case 'Square':
          case 'Ink': {
            const mouseCoords = calculateRelativeCoordinates(
              page,
              e.clientX,
              e.clientY,
              +page.dataset.viewportScale,
            );
            if (mouseCoords) {
              dispatch(
                setCreationSetting({
                  start: { x: mouseCoords.left, y: mouseCoords.top },
                  pageNum: +page.dataset.pageNum,
                }),
              );
            }
            break;
          }
        }
      }
    },
    [cursorMode],
  );

  const onClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (e.button === 0) {
        const page = e.nativeEvent
          .composedPath()
          .find(
            (element) =>
              element instanceof HTMLDivElement &&
              element.dataset.pageNum &&
              element.dataset.viewportScale,
          ) as HTMLDivElement | undefined;
        if (page && page.dataset.pageNum && page.dataset.viewportScale) {
          switch (cursorMode) {
            case 'Underline':
            case 'StrikeOut':
            case 'Highlight': {
              manager.createTextMarkupAnnotation(cursorMode);

              dispatch(selectAnnotation(null));
              break;
            }
            case 'Note': {
              const id = manager.createNoteAnnotation(
                new DOMRect(e.pageX, e.pageY, 32, 32),
                +page.dataset.pageNum,
              );
              if (id) {
                dispatch(setCursorMode('normal'));
                dispatch(setEditingAnnotation({ id, isTask: false }));
                dispatch(setPulseData({ commentId: id }));
              }
              break;
            }
            case 'Task': {
              manager.startCreatingFreestyleTask(
                new DOMRect(e.pageX, e.pageY, 32, 32),
                +page.dataset.pageNum,
              );
              dispatch(setCursorMode('normal'));

              break;
            }
            case 'FreeText': {
              const { stroke, fill, strokeWidth } = creationData;
              const id = manager.createAnnotation({
                subtype: 'FreeText',
                pageNumber: +page.dataset.pageNum,
                viewportScale: +page.dataset.viewportScale,
                pageRect: page.getBoundingClientRect(),
                boundingRect: new DOMRect(e.pageX, e.pageY, 64, 48),
                color: {
                  stroke: stroke !== 'none' ? COLORS_HEX_MAP[stroke] : undefined,
                  fill: fill !== 'none' ? COLORS_HEX_MAP[fill] : undefined,
                },
                border: {
                  style: 'S',
                  width: strokeWidth,
                },
                content: newRTEObject('', textboxPresetStyles),
              });
              if (id) {
                dispatch(setEditingAnnotation({ id, isTask: false }));
                dispatch(setCursorMode('normal'));
              }
              break;
            }
            case 'normal': {
              const mouseCoords = calculateRelativeCoordinates(
                page,
                e.clientX,
                e.clientY,
                +page.dataset.viewportScale,
              );

              if (mouseCoords) {
                dispatch(
                  clickOnPDFPage({
                    left: mouseCoords.left,
                    bottom: mouseCoords.bottom,
                    pageNum: +page.dataset.pageNum,
                  }),
                );
              }

              // let toolboxInfo = null;
              // const selectionCoords = calculateSelectionRelativeCoordinates(page);
              // const pageNum = page.dataset.pageNum;
              // if (pageNum && selectionCoords) {
              //   toolboxInfo = {
              //     pageNum: +pageNum,
              //     coords: selectionCoords,
              //   };
              // }

              // dispatch(setToolboxInfo(toolboxInfo));
              break;
            }
            default:
              return;
          }
        }
      }
    },
    [cursorMode, { ...creationData }],
  );

  const className = cx(styles.pdfPage, { [styles.drawing]: cursorMode === 'Ink' });

  return { onClick, onMouseDown, className };
};

export default useCursorMode;
