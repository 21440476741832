import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { TextLineIterator } from '../../../Iterators/HTMLIterators';
import { BaseModifier } from '../BaseModifier';

export class ExpandLineModifier extends BaseModifier {
  constructor(Data: Editor.Data.API, direction: Editor.Selection.ModifierDirection) {
    super(Data, 'expand', 'line', direction);
  }

  private expandLineForward(range: Editor.Selection.EditorRange) {
    const modifiersData: Editor.Data.Selection.Modifiers = this.Data.selection?.modifiersData || {};

    if (!modifiersData.px) {
      const clientRects = range.getClientRects();
      if (modifiersData.expandingDirection === 'forward') {
        modifiersData.px =
          clientRects.length > 0 ? clientRects[clientRects.length - 1].right : null;
      } else if (modifiersData?.expandingDirection === 'backward') {
        modifiersData.px = clientRects.length > 0 ? clientRects[0].left : null;
      }
    }

    if (modifiersData.expandingDirection === 'backward') {
      const lineIterator = new TextLineIterator(
        range.startContainer,
        range.startOffset,
        modifiersData,
      );

      const htmlPosition = lineIterator.next();

      if (htmlPosition.node != null && htmlPosition.offset != null) {
        if (range.comparePoint && range.comparePoint(htmlPosition.node, htmlPosition.offset) > 0) {
          modifiersData.expandingDirection = 'forward';

          range.setStart(range.endContainer, range.endOffset);
          range.setEnd(htmlPosition.node, htmlPosition.offset);
        } else {
          range.setStart(htmlPosition.node, htmlPosition.offset);
        }
      } else {
        throw new Error('Invalid position!');
      }
    } else {
      const lineIterator = new TextLineIterator(range.endContainer, range.endOffset, modifiersData);

      const htmlPosition = lineIterator.next();

      if (htmlPosition.node != null && htmlPosition.offset != null) {
        modifiersData.expandingDirection = 'forward';

        if (
          (EditorDOMElements.isTableElement(htmlPosition.node) ||
            (modifiersData.cellSelection &&
              EditorDOMElements.isTableCellElement(htmlPosition.node))) &&
          EditorDOMUtils.parentContainsNode(htmlPosition.node, range.startContainer)
        ) {
          range.setStart(htmlPosition.node, 0);
        }

        if (
          range.endContainer === htmlPosition.node &&
          range.endOffset === htmlPosition.offset &&
          EditorDOMUtils.findNextElementSibling(range.endContainer, null)
        ) {
          logger.warn('Not yet implemented!');
          // TODO:  fix selection when paragraph is aligned to right
          // range.moveEnd('character', 1);
          // setTimeout(() => {
          //   const caretData = this._getCaretOffsetFromSiblingLine({
          //     direction,
          //     changeType: 'expand',
          //     granularity,
          //   });
          //   const selection = EditorSelectionUtils.getSelection();
          //   const range = selection.getRangeAt(0);
          //   range.setEnd(caretData.node, caretData.offset);
          //   if (range) {
          //     selection.removeAllRanges();
          //     selection.addRange(range);
          //   }
          // }, 0);
        } else {
          range.setEnd(htmlPosition.node, htmlPosition.offset);
        }
      } else {
        throw new Error('Invalid position!');
      }
    }

    this.Data.selection?.updateModifiers(modifiersData);
  }

  private expandLineBackward(range: Editor.Selection.EditorRange) {
    const modifiersData: Editor.Data.Selection.Modifiers = this.Data.selection?.modifiersData || {};

    if (!modifiersData.px) {
      const clientRects = range.getClientRects();
      if (modifiersData.expandingDirection === 'forward') {
        modifiersData.px =
          clientRects.length > 0 ? clientRects[clientRects.length - 1].right : null;
      } else if (modifiersData?.expandingDirection === 'backward') {
        modifiersData.px = clientRects.length > 0 ? clientRects[0].left : null;
      }
    }

    if (modifiersData.expandingDirection === 'forward') {
      const lineIterator = new TextLineIterator(range.endContainer, range.endOffset, modifiersData);

      const htmlPosition = lineIterator.previous();

      if (htmlPosition.node != null && htmlPosition.offset != null) {
        if (range.comparePoint && range.comparePoint(htmlPosition.node, htmlPosition.offset) < 0) {
          modifiersData.expandingDirection = 'backward';

          range.setEnd(range.startContainer, range.startOffset);
          range.setStart(htmlPosition.node, htmlPosition.offset);
        } else {
          range.setEnd(htmlPosition.node, htmlPosition.offset);
        }
      } else {
        throw new Error('Invalid position!');
      }
    } else {
      const lineIterator = new TextLineIterator(
        range.startContainer,
        range.startOffset,
        modifiersData,
      );

      const htmlPosition = lineIterator.previous();

      if (htmlPosition.node != null && htmlPosition.offset != null) {
        modifiersData.expandingDirection = 'backward';

        if (
          (EditorDOMElements.isTableElement(htmlPosition.node) ||
            (modifiersData.cellSelection &&
              EditorDOMElements.isTableCellElement(htmlPosition.node))) &&
          EditorDOMUtils.parentContainsNode(htmlPosition.node, range.endContainer)
        ) {
          range.setEnd(htmlPosition.node, htmlPosition.node.childNodes.length);
        }

        if (
          range.startContainer === htmlPosition.node &&
          range.startOffset === htmlPosition.offset &&
          range.startOffset > 0 &&
          EditorDOMUtils.findPreviousElementSibling(range.startContainer, null)
        ) {
          logger.warn('Not yet implemented!');
          // TODO:  fix selection when paragraph is aligned to right
          // range.moveStart('character', -1);
          // setTimeout(() => {
          //   const caretData = this._getCaretOffsetFromSiblingLine({
          //     direction,
          //     changeType: 'expand',
          //     granularity,
          //   });
          //   const selection = EditorSelectionUtils.getSelection();
          //   const range = selection.getRangeAt(0);
          //   range.setStart(caretData.node, caretData.offset);
          //   if (range) {
          //     selection.removeAllRanges();
          //     selection.addRange(range);
          //   }
          // }, 0);
        } else {
          range.setStart(htmlPosition.node, htmlPosition.offset);
        }
      } else {
        throw new Error('Invalid position!');
      }
    }

    this.Data.selection?.updateModifiers(modifiersData);
  }

  visitDoDOCRange(range: Editor.Selection.EditorRange): void {
    switch (this.direction) {
      case 'forward':
        return this.expandLineForward(range);
      case 'backward':
        return this.expandLineBackward(range);
      default:
        return;
    }
  }

  visitJsonRange(range: Editor.Selection.JsonRange): void {
    const editorRange = range.serializeToDOMRange();
    this.visitDoDOCRange(editorRange);
    range.updateFromDOMRange(editorRange);
  }
}
