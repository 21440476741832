import { useEffect, useRef } from 'react';
import { Toggle } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { setPulseData } from 'App/redux/onboardingSlice';

import { InteractionController } from '_common/components';

import styles from './ReplyToggle.module.scss';

type ReplyToggleProps = {
  repliesCount: number;
  isToggled?: boolean;
  onToggleReplies: () => void;
  disabled?: boolean;
};

const ReplyToggle = ({ repliesCount, isToggled, onToggleReplies }: ReplyToggleProps) => {
  const { canComment } = usePDFPermissions();

  const dispatch = useDispatch();

  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);

  const replyRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (replyRef?.current) {
      if (!isToggled && !actionsCompleted.pdf_comments_mentionInCommentReply) {
        dispatch(
          setPulseData({
            annotationCardReplyRect: {
              top: replyRef.current.offsetTop,
              left: replyRef.current.offsetLeft,
              height: replyRef.current.offsetHeight,
              width: replyRef.current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            annotationCardReplyRect: undefined,
          }),
        );
      }
    }

    return () => {
      dispatch(
        setPulseData({
          annotationCardReplyRect: undefined,
        }),
      );
    };
  }, [isToggled]);

  return (
    <InteractionController environment="dopdf" rules={[{ interaction: 'pdf_comment_reply' }]}>
      <div className={styles.replyCounter}>
        <Toggle
          size="medium"
          variant="link"
          isToggled={isToggled}
          disabled={repliesCount < 1 && !canComment}
          icon={isToggled ? 'ReplyBlue' : 'ReplyGrey'}
          onClick={onToggleReplies}
          testId="annotation-reply-toggle"
          ref={replyRef}
        />
        {repliesCount > 0 && repliesCount}
      </div>
    </InteractionController>
  );
};

export default ReplyToggle;
