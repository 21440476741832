import { useState, useMemo, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Accordion, InputField, Popover, Select, Toggle, Tooltip, usePopper } from 'dodoc-design-system';

import styles from './Section.module.scss';
import { hexToRgb } from 'assets/colors';
import { ColorsMenu } from 'Editor/components';
import EditorManager from 'Editor/services/EditorManager';
import { ColorsMenuProps } from 'Editor/components/ColorsMenu/ColorsMenu';
import { useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';
import { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';
import { DOMUtils } from '_common/utils';
import { MeasureInput } from '_common/components';

type StyleProps = Pick<Editor.Styles.TableProperties, 'background' | 'border'> & SectionProps;

const DEFAULT_PROPERTIES: Pick<Editor.Styles.TableProperties, 'background'> & {
  borderColor?: string;
  borderWidth?: number;
  borderStyle?: Editor.Data.Node.BorderStyle;
  borderChosen?: Editor.Styles.BorderChosen;
} = {
  background: 'transparent',
  borderColor: '#000000',
  borderWidth: 0.75,
  borderStyle: 's',
  borderChosen: 'none',
};

type ToggleOption = {
  value: Editor.Styles.BorderChosen;
  icon: IconTypes['24'];
  label: string;
};

//TODO: Replace values to specific when type is defined properly
const BORDER_TOGGLES: ToggleOption[] = [
  {
    value: 'all',
    icon: 'AllBorders',
    label: 'ALL_BORDERS',
  },
  {
    value: 'top',
    icon: 'TopBorder',
    label: 'TOP_BORDER',
  },
  {
    value: 'right',
    icon: 'RightBorder',
    label: 'RIGHT_BORDER',
  },
  {
    value: 'bottom',
    icon: 'BottomBorder',
    label: 'BOTTOM_BORDER',
  },
  {
    value: 'left',
    icon: 'LeftBorder',
    label: 'LEFT_BORDER',
  },
  {
    value: 'inside',
    icon: 'InsideBorder',
    label: 'INSIDE_BORDERS',
  },
  {
    value: 'outside',
    icon: 'OutsideBorders',
    label: 'OUTSIDE_BORDERS',
  },
];

type BorderStyleOption = {
  value: Editor.Data.Node.BorderStyle;
};

//Forcefully restricting value type while maintaining SelectOption type
const BORDER_STYLES: (SelectOption & BorderStyleOption)[] = [
  { value: 's', label: '\u2014 (solid)' },
  { value: 'dot', label: '\u00B7\u00B7\u00B7 (dotted)' },
  { value: 'das', label: '--- (dashed)' },
  { value: 'd', label: '\u2550 \u2550 \u2550 (double)' },
];

const Style = ({ background, border, updateData }: StyleProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const backgroundColorPopper = usePopper({
    placement: 'top-end',
    strategy: 'fixed',
    closeOnReferenceHidden: true,
  });
  const borderColorPopper = usePopper({
    placement: 'top-end',
    strategy: 'fixed',
    closeOnReferenceHidden: true,
  });

  const [backgroundIsOpen, setBackgroundIsOpen] = useState(false);
  const [borderColorIsOpen, setBorderColorIsOpen] = useState(false);
  //TODO: Check how this state is populated and clean type accordingly
  const [documentColors, setDocumentColors] = useState<
    { r?: string | number; g?: string | number; b?: string | number; rgb?: string }[]
  >([]);

  const [localBackground, setLocalBackground] = useState(
    background ?? DEFAULT_PROPERTIES.background,
  );
  const rbgBackground = useMemo(() => {
    if (localBackground) {
      const rbgBackground = hexToRgb(localBackground);
      return rbgBackground && `rgb(${rbgBackground[0]},${rbgBackground[1]},${rbgBackground[2]})`;
    }
  }, [localBackground]);
  const [localBorderColor, setLocalBorderColor] = useState(
    border?.color?.value ?? DEFAULT_PROPERTIES.borderColor,
  );
  const rgbBorderColor = useMemo(() => {
    if (localBorderColor) {
      const rgbBorderColor = hexToRgb(localBorderColor);
      return (
        rgbBorderColor && `rgb(${rgbBorderColor[0]},${rgbBorderColor[1]},${rgbBorderColor[2]})`
      );
    }
  }, [localBorderColor]);

  const [localBorderWidth, setLocalBorderWidth] = useState(
    `${border?.width?.value ?? DEFAULT_PROPERTIES.borderWidth}`,
  );
  const [localBorderStyle, setLocalBorderStyle] = useState(
    BORDER_STYLES.find(
      (borderStyleOption) =>
        borderStyleOption.value === (border?.style?.value ?? DEFAULT_PROPERTIES.borderStyle),
    ),
  );
  const [localBorderChosen, setLocalBorderChosen] = useState(border?.chosen?.value);

  useEffect(() => {
    const manager = EditorManager.getInstance();
    const documentColors = manager.getDocumentColors();
    if (documentColors) {
      setDocumentColors(documentColors);
    }
  }, []);

  //#region Property change handlers
  const updateColorCallback = useCallback<SectionProps['updateData']>(({ property, value }) => {
    const typedValue = value as
      | Editor.Styles.TableProperties['background']
      | NonNullable<NonNullable<Editor.Styles.TableProperties['border']>['color']>['value'];
    if (!typedValue) {
      return;
    }

    switch (property) {
      case 'background':
        setLocalBackground(typedValue);
        updateData({ property: 'background', value: typedValue });
        break;
      case 'borderColor':
        setLocalBorderColor(typedValue);
        updateData({ property: 'border', value: { ...border, color: { value: typedValue } } });
        break;
    }
  }, []);

  const handleBorderStyleChange = (newStyle: (typeof BORDER_STYLES)[number]) => {
    setLocalBorderStyle(newStyle);
    updateData({ property: 'border', value: { ...border, style: { value: newStyle.value } } });
  };

  const handleBorderWidthChange = (newValue: string) => {
    setLocalBorderWidth(newValue);
    updateData({ property: 'border', value: { ...border, width: { value: +newValue } } });
  };

  const handleChosenBorderChange = (newChosenBorder: Editor.Styles.BorderChosen) => {
    setLocalBorderChosen(newChosenBorder);
    updateData({
      property: 'border',
      value: { ...border, chosen: { value: newChosenBorder } },
    });
  };
  //#endregion

  //#region Background ColorsMenu handlers
  const handleBackgroundToggle = () => {
    setBackgroundIsOpen(!backgroundIsOpen);
  };

  const handleBackgroundChange: ColorsMenuProps['changeBackgroundColor'] = (newBackground) => {
    const hexBackground = DOMUtils.rgbToHex(`${newBackground ?? ''}`) ?? '';

    setLocalBackground(newBackground);
    updateData({ property: 'background', value: hexBackground });
  };
  const handleClearBackground: ColorsMenuProps['clearBackgroundColor'] = () => {
    setLocalBackground('transparent');
    updateData({ property: 'background', value: 'transparent' });
  };

  const handleOpenColorPickerModalBackground: ColorsMenuProps['setOpenColorPickerModal'] = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ColorPickerModal',
        data: { attribute: 'background', action: 'updateTableColor', updateColorCallback },
      }),
    );
  };
  //#endregion

  //#region BorderColor ColorsMenu handlers
  const handleBorderColorToggle = () => {
    setBorderColorIsOpen(!borderColorIsOpen);
  };

  const handleBorderColorChange: ColorsMenuProps['changeBackgroundColor'] = (newBorderColor) => {
    const hexBorderColor = DOMUtils.rgbToHex(`${newBorderColor ?? ''}`) ?? '';

    setLocalBorderColor(newBorderColor);
    updateData({ property: 'border', value: { ...border, color: { value: hexBorderColor } } });
  };
  const handleClearBorderColor: ColorsMenuProps['clearBackgroundColor'] = () => {
    setLocalBorderColor('rgb(0, 0, 0)');
    updateData({ property: 'border', value: { ...border, color: { value: 'rgb(0, 0, 0)' } } });
  };

  const handleOpenColorPickerModalBorderColor: ColorsMenuProps['setOpenColorPickerModal'] = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ColorPickerModal',
        data: { attribute: 'borderColor', action: 'updateTableColor', updateColorCallback },
      }),
    );
  };
  //#endregion

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: 'STYLE' })}
      initialCollapsed={false}
      contentMargin="1rem"
      testId="tableProperties-style-accordion"
    >
      <div className={styles.root}>
        <div className={styles.column}>
          <InputField
            label={intl.formatMessage({ id: 'BACKGROUND_COLOR' })}
            testId="tableProperties-background-color-field"
          >
            <Toggle
              size="medium"
              variant="standard"
              {...backgroundColorPopper.referenceProps}
              margin="0 0 3rem 0"
              testId="tableProperties-background-color-toggle"
            >
              <div
                style={{
                  width: '2rem',
                  height: '2rem',
                  boxShadow: 'inset 0px 0px 1px 0px rgba(0, 0, 0, 0.5)',
                  background: localBackground,
                  margin: '1rem',
                }}
              />
            </Toggle>
            <Popover {...backgroundColorPopper.popperProps} testId="tableProperties-background-color-popper">
              <ColorsMenu
                selected={rbgBackground}
                documentColors={documentColors}
                toggle={handleBackgroundToggle}
                changeBackgroundColor={handleBackgroundChange}
                clearBackgroundColor={handleClearBackground}
                setOpenColorPickerModal={handleOpenColorPickerModalBackground}
              />
            </Popover>
          </InputField>
        </div>
        <div className={styles.column}>
          <InputField
            label={intl.formatMessage({ id: 'BORDER' })}
            testId="tableProperties-border-field"
          >
            <Select
              size="medium"
              value={localBorderStyle}
              options={BORDER_STYLES}
              onChange={handleBorderStyleChange}
              clearable={false}
              menuPosition="fixed"
              fullWidth
              testId="border-styles"
            />
            <div className={`${styles.row} ${styles.withMargin}`}>
              <MeasureInput
                size="medium"
                value={localBorderWidth}
                clearable={false}
                valueSuffix="pt"
                placeholder=""
                onChange={handleBorderWidthChange}
                allowDecimal
                avoidEmpty
                testId="tableProperties-border"
              />
              <Toggle
                size="medium"
                {...borderColorPopper.referenceProps}
                margin="0 0 3rem 0"
                testId="tableProperties-border-color-toggle"
              >
                <div
                  style={{
                    width: '2rem',
                    height: '2rem',
                    boxShadow: 'inset 0px 0px 1px 0px rgba(0, 0, 0, 0.5)',
                    background: localBorderColor,
                    margin: '1rem',
                  }}
                />
              </Toggle>
              <Popover {...borderColorPopper.popperProps} testId="tableProperties-border-color-popper">
                <ColorsMenu
                  selected={rgbBorderColor}
                  documentColors={documentColors}
                  toggle={handleBorderColorToggle}
                  changeBackgroundColor={handleBorderColorChange}
                  clearBackgroundColor={handleClearBorderColor}
                  setOpenColorPickerModal={handleOpenColorPickerModalBorderColor}
                />
              </Popover>
            </div>
            <div className={styles.shelf}>
              {BORDER_TOGGLES.map((toggle) => (
                <Tooltip content={intl.formatMessage({ id: toggle.label })} testId={`tableProperties-border-${toggle.value}-tooltip`}>
                  <Toggle
                    key={`borderType-${toggle.value}`}
                    size="medium"
                    variant="link"
                    isToggled={localBorderChosen === toggle.value}
                    icon={toggle.icon}
                    onClick={() => handleChosenBorderChange(toggle.value)}
                    testId={`tableProperties-border-${toggle.value}-toggle`}
                  />
                </Tooltip>
              ))}
            </div>
          </InputField>
        </div>
      </div>
    </Accordion>
  );
};

export default Style;
