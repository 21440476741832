/* eslint-disable no-lonely-if */
import { Mixin } from 'mixwith';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { NodeDataBuilder } from 'Editor/services/DataManager/models';
import ReduxInterface from 'Editor/services/ReduxInterface';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class BackspaceHandler extends superclass {
      destroy() {
        super.destroy();
      }

      /**
       * @description selects a function to handle backspace based on baseNode
       * @param {ActionContext} actionContext
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleBackspaceOnCollapsedSelection(actionContext, baseNode, anchorNode, anchorOffset) {
        if (!baseNode || DOMUtils.isNodeAContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        }

        if (baseNode) {
          if (anchorNode) {
            if (
              baseNode.tagName === ELEMENTS.ParagraphElement.TAG &&
              this.dataManager.numbering.isListElement(baseNode.id)
            ) {
              // SELECTION IS A LIST
              this.handleBackspaceOnListElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (DOMUtils.isNodeEditableTextElement(baseNode)) {
              this.selectionManager.fixCollapsedTextSelection({
                suggestionMode: true,
                forceWrapAsText: true,
                isBackspace: true,
              });
              const selection = EditorSelectionUtils.getSelection();
              anchorNode = selection.anchorNode;
              anchorOffset = selection.anchorOffset;

              // SELECTION IS A DEFAULT TEXT ELEMENT
              const selectionCheck = DOMUtils.closest(anchorNode, DOMUtils.BLOCK_TEXT_ELEMENTS);
              this.handleBackspaceOnTextElement(
                actionContext,
                baseNode,
                selectionCheck,
                anchorNode,
                anchorOffset,
              );
            } else if (!DOMUtils.isBlockNodeEditable(baseNode)) {
              // SELECTION IS A NON-EDITABLE ELEMENT
              this.handleNonEditableElementBackspace(actionContext, baseNode);
            } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
              // SELECTION IS A FIGURE
              this.handleBackspaceOnFigureElement(
                actionContext,
                baseNode,
                anchorNode,
                anchorOffset,
              );
            } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
              // SELECTION IS A TABLE
              this.handleBackspaceOnTableElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (DOMUtils.isNodeAContainerElement(baseNode)) {
              this.handleBackspaceOnContainerElement(
                actionContext,
                baseNode,
                anchorNode,
                anchorOffset,
              );
            }
          }
        }
      }

      /**
       * @description handle backspace on multi selection
       * @param {ActionContext} actionContext
       * @param {Node} level0Node
       */
      handleBackspaceOnMultiSelection(actionContext, level0Node, options = {}) {
        const { fixTextSelection = true } = options;

        actionContext.selectionType = ActionContext.SELECTION.RANGE;
        if (fixTextSelection) {
          this.selectionManager.fixNonCollapsedTextSelection({
            suggestionMode: true,
            forceTextAsWrap: true,
            // isBackspace: true, // avoid these properties here with multiselection
          });
        }
        this.removeSelectionContent(actionContext);
        // TODO : Create action or update
      }

      handleBackspaceOnContainerElement(actionContext, baseNode, anchorNode, anchorOffset) {
        if (baseNode === anchorNode) {
          if (anchorOffset === 0) {
            if (baseNode.previousSibling) {
              this.selectionManager.setCaret(baseNode.previousSibling, 'INSIDE_END');
              return;
            }
          } else if (anchorOffset === anchorNode.childNodes.length) {
            this.selectionManager.selectNode(anchorNode);
            return;
          } else {
            if (this.selectionManager.fixSelection()) {
              const selection = EditorSelectionUtils.getSelection();
              anchorNode = selection.anchorNode;
              anchorOffset = selection.anchorOffset;
            }
          }
        } else if (baseNode.tagName === ELEMENTS.TrackDeleteElement.TAG) {
          if (baseNode.previousSibling) {
            this.selectionManager.setCaret(baseNode.previousSibling, 'INSIDE_END');
          }
          return;
        }

        const subLevel0Node = DOMUtils.findNodeLevel0(baseNode, anchorNode);
        if (subLevel0Node) {
          this.handleBackspaceOnCollapsedSelection(
            actionContext,
            subLevel0Node,
            anchorNode,
            anchorOffset,
          );
        }
      }

      /**
       * handle backspace event on list elements
       * @param {ActionContext} actionContext
       * @param {Node} listNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleBackspaceOnListElement(actionContext, listNode, anchorNode, anchorOffset) {
        if (this.selectionManager.isSelectionAtStart(listNode)) {
          this.stylesHandler.removeListStyleFromBlock(actionContext, listNode);
        } else {
          this.handleBackspaceOnTextElement(
            actionContext,
            listNode,
            listNode,
            anchorNode,
            anchorOffset,
          );
        }
      }

      /**
       * handle backspace event on table element
       * @param {ActionContext} actionContext
       * @param {Node} tableNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleBackspaceOnTableElement(actionContext, tableNode, anchorNode, anchorOffset) {
        const closest = DOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);
        if (anchorNode.tagName === ELEMENTS.TableElement.TAG) {
          if (anchorOffset === anchorNode.childNodes.length) {
            this.selectionManager.selectNode(anchorNode);
          } else if (anchorOffset === 0) {
            this._handleBackspaceAtElementStart(actionContext, anchorNode);
          }
        } else if (closest && !closest.hasAttribute('lock')) {
          if (closest.tagName === ELEMENTS.TableCellElement.TAG) {
            actionContext.nodeContext = ActionContext.NODE_CONTEXT.TABLE;
            const tdLevel0Node = DOMUtils.findNodeLevel0(closest, anchorNode);
            if (tdLevel0Node) {
              if (tdLevel0Node.tagName !== ELEMENTS.TableElement.TAG) {
                this.handleBackspaceOnCollapsedSelection(
                  actionContext,
                  tdLevel0Node,
                  anchorNode,
                  anchorOffset,
                );
              } else if (tdLevel0Node !== tableNode) {
                this.selectionManager.selectNode(tdLevel0Node);
              }
            }
          }
        }
      }

      /**
       * handle backspace event on a figure element
       * @param {ActionContext} actionContext
       * @param {Node} figureNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleBackspaceOnFigureElement(actionContext, figureNode, anchorNode, anchorOffset) {
        const closest = DOMUtils.closest(anchorNode, ['FIGCAPTION', 'IMAGE-ELEMENT']);
        if (closest) {
          if (closest.tagName === 'FIGCAPTION' && !closest.hasAttribute('lock')) {
            if (this.selectionManager.isSelectionAtStart(closest)) {
              const closestSuggestion = DOMUtils.closest(closest, 'TRACK-INS-ELEMENT');
              if (closestSuggestion && this.isUserAuthor(closestSuggestion)) {
                this.selectionManager.setCaret(
                  closest.previousSibling,
                  'INDEX',
                  closest.previousSibling.childNodes.length,
                );
                closest.remove();
                actionContext.addChangeUpdatedNode(figureNode);
              } else {
                // TODO : check for inner suggestions
                const suggRef = this.getProperSuggestionRef(actionContext);
                const captionDelete = this.getTrackDelElement(suggRef);
                captionDelete.appendChild(closest.cloneNode(true));
                DOMUtils.replaceNode(closest.parentNode, captionDelete, closest);
                actionContext.addChangeUpdatedNode(figureNode);
                this.selectionManager.setCaret(
                  captionDelete.previousSibling,
                  'INDEX',
                  captionDelete.previousSibling.childNodes.length,
                );
              }
            } else {
              this.handleBackspaceOnTextElement(
                actionContext,
                figureNode,
                closest,
                anchorNode,
                anchorOffset,
              );
            }
          } else if (
            // This is the localfile="true" case of an image-element
            // happens when the user copies a block of content from a word file that includes a figure
            closest.tagName === 'IMAGE-ELEMENT' &&
            anchorNode.nodeType === Node.TEXT_NODE &&
            anchorNode.previousSibling.tagName === 'DIV'
          ) {
            this.selectionManager.selectLevel0Node(figureNode);
          } else if (anchorNode.tagName === 'IMAGE-ELEMENT') {
            if (
              (closest === anchorNode && anchorOffset === closest.childNodes.length) ||
              (closest !== anchorNode && !anchorNode.nextSibling)
            ) {
              this.selectionManager.selectLevel0Node(figureNode);
            } else if (
              (closest === anchorNode && anchorOffset === 0) ||
              (closest !== anchorNode && !anchorNode.previousSibling)
            ) {
              this._handleBackspaceAtElementStart(actionContext, figureNode);
            }
          }
        } else if (
          anchorNode.tagName === 'IMG' ||
          anchorNode.tagName === ELEMENTS.FigureElement.TAG
        ) {
          if (anchorOffset === anchorNode.childNodes.length) {
            this.selectionManager.selectLevel0Node(anchorNode);
          } else if (anchorOffset === 0) {
            // backspace at start
            this._handleBackspaceAtElementStart(actionContext, figureNode);
          }
        }
      }

      /**
       * handle backsapce event on a default text element
       * @param {ActionContext} actionContext
       * @param {Node} level0Node
       * @param {Node} textElementNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleBackspaceOnTextElement(
        actionContext,
        level0Node,
        textElementNode,
        anchorNode,
        anchorOffset,
      ) {
        if (textElementNode) {
          // check if there is a paragraph marker at the end
          // fix when selection is after suggestion paragraph marker
          if (
            this.selectionManager.isSelectionAtEnd(textElementNode) &&
            (this.isAddParagraphMarker(anchorNode) || this.isDeleteParagraphMarker(anchorNode))
          ) {
            if (anchorNode.previousSibling) {
              this.selectionManager.setCaret(anchorNode.previousSibling, 'INSIDE_END');
            } else {
              this.selectionManager.setCaret(anchorNode, 'PRE');
            }
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
          }

          if (this.selectionManager.isSelectionAtStart(textElementNode)) {
            // and start of text element
            this._handleBackspaceAtElementStart(actionContext, level0Node, textElementNode);
          } else {
            // selection is at mid or end

            const closest = DOMUtils.closest(anchorNode, [
              ...DOMUtils.INLINE_NON_EDITABLE_ELEMENTS,
              ELEMENTS.TrackDeleteElement.TAG,
            ]);

            let previousClosest;
            if (!closest) {
              let previousSibling;

              if (this.selectionManager.isSelectionAtStart(anchorNode)) {
                previousSibling = anchorNode.previousSibling;
              } else if (!(anchorNode instanceof Text)) {
                previousSibling = anchorNode.childNodes[anchorOffset - 1];
              }

              if (previousSibling) {
                if (
                  previousSibling.nodeType === Node.ELEMENT_NODE &&
                  (previousSibling.tagName === ELEMENTS.TrackInsertElement.TAG ||
                    previousSibling.tagName === ELEMENTS.TrackDeleteElement.TAG) &&
                  previousSibling.childNodes.length > 0
                ) {
                  previousClosest = DOMUtils.closest(previousSibling.lastChild, [
                    ...DOMUtils.INLINE_NON_EDITABLE_ELEMENTS,
                    ELEMENTS.TrackDeleteElement.TAG,
                    ELEMENTS.FieldElement.TAG,
                  ]);
                } else {
                  previousClosest = DOMUtils.closest(previousSibling, [
                    ...DOMUtils.INLINE_NON_EDITABLE_ELEMENTS,
                    ELEMENTS.TrackDeleteElement.TAG,
                    ELEMENTS.FieldElement.TAG,
                  ]);
                }
              }
            }

            const nonEditable = closest || previousClosest;

            // handle non-editable text elements
            if (nonEditable) {
              if (nonEditable.tagName === ELEMENTS.CitationElement.TAG) {
                // WARN: legacy code
                if (!closest && previousClosest) {
                  this.selectionManager.setCaret(previousClosest, 'INSIDE_END');
                  this.removeCitations(
                    actionContext,
                    previousClosest,
                    previousClosest.nodeType === Node.ELEMENT_NODE
                      ? previousClosest.childNodes.length
                      : previousClosest.length,
                    nonEditable,
                  );
                } else {
                  this.removeCitations(actionContext, anchorNode, anchorOffset, nonEditable);
                }
              } else if (nonEditable.tagName === ELEMENTS.CitationsGroupElement.TAG) {
                nonEditable.selected = true;
                this.selectionManager.selectNode(nonEditable);
              } else if (
                nonEditable.tagName === ELEMENTS.EquationElement.TAG ||
                nonEditable.tagName === ELEMENTS.FieldElement.TAG
              ) {
                nonEditable.selected = true;
                this.selectionManager.selectNode(nonEditable);

                // trigger confirmation modal
                if (nonEditable.isTypeCaption()) {
                  ReduxInterface.openDeleteCaptionConfirmationModal();
                }
              } else if (nonEditable.tagName === ELEMENTS.TrackDeleteElement.TAG) {
                this.setCursorPosition(
                  nonEditable.getAttribute('element_reference'),
                  actionContext.collapseDirection,
                );
              } else {
                this.selectionManager.selectNode(nonEditable);
                this.handleBackspaceOnMultiSelection(actionContext);
              }
            } else {
              // handle normal characters
              this.selectionManager.modifySelection('expand', 'backward', 'character');

              this.handleBackspaceOnMultiSelection(actionContext);
            }
          }
        }
      }

      /**
       * handle backspace on a non-editable element
       * @param {ActionContext} actionContext
       * @param {Node} level0Node
       */
      handleNonEditableElementBackspace(actionContext, level0Node) {
        if (this.hasEditPermissions()) {
          const contentEditable = level0Node && level0Node.selectableContent;

          if (contentEditable) {
            if (this.selectionManager.isSelectionAtStart(contentEditable)) {
              this._handleBackspaceAtElementStart(actionContext, level0Node);
              // if (level0Node.previousSibling) {
              //   if (DOMUtils.isBlockNodeEditable(level0Node.previousSibling)) {
              //     if (level0Node.previousSibling.textContent === '') {
              //       this.selectionManager.setCaret(level0Node.previousSibling, 'INSIDE_START');
              //     } else {
              //       this.selectionManager.selectNode(level0Node.previousSibling);
              //     }
              //   } else {
              //     const nodeToSelect = level0Node.previousSibling.selectableContent
              //       ? level0Node.previousSibling.selectableContent
              //       : level0Node.previousSibling;
              //     this.selectionManager.setCaret(nodeToSelect, 'INSIDE_END');
              //   }
              // }
            } else if (this.selectionManager.isSelectionAtEnd(contentEditable)) {
              if (DOMUtils.isBlockNodeDeletable(level0Node)) {
                if (DOMUtils.BLOCK_INVALID_ELEMENTS.includes(level0Node.tagName)) {
                  const nodeToSelect = level0Node.nextSibling.selectableContent
                    ? level0Node.nextSibling.selectableContent
                    : level0Node.nextSibling;
                  this.selectionManager.setCaret(nodeToSelect, 'INSIDE_START');

                  // remove element
                  actionContext.addChange(
                    ActionContext.CHANGE_TYPE.REMOVED,
                    level0Node.getAttribute('id'),
                  );
                  level0Node.remove();
                } else if (level0Node.parentNode === DOMUtils.getPageNode()) {
                  let nodeToSelect;
                  if (level0Node.previousSibling) {
                    nodeToSelect = level0Node.previousSibling.selectableContent
                      ? level0Node.previousSibling.selectableContent
                      : level0Node.previousSibling;
                  } else {
                    const p = DOMElementFactory.createNewParagraphElement();
                    const suggestionInsert = this.getTrackInsElement(actionContext.id);
                    suggestionInsert.appendChild(p);

                    const data = NodeDataBuilder.buildNodeData({
                      data: {
                        ...this.documentParser.parse(suggestionInsert),
                        parent_id: level0Node.parentNode.id,
                      },
                    });

                    this.inserBlockNodeOperation(actionContext, data, level0Node, 'BEFORE');
                    nodeToSelect = DOMUtils.getBlockNode(data.id).lastChild;
                  }

                  const suggestionDelete = this.getTrackDelElement(actionContext.id);
                  suggestionDelete.appendChild(level0Node.cloneNode(true));

                  const data = NodeDataBuilder.buildNodeData({
                    data: {
                      ...this.documentParser.parse(suggestionDelete),
                      parent_id: level0Node.parentNode.id,
                    },
                  });
                  this.inserBlockNodeOperation(actionContext, data, level0Node, 'BEFORE');

                  this.removeBlockElementFromDOM(actionContext, level0Node);

                  this.selectionManager.setCaret(nodeToSelect, 'INSIDE_END');
                } else if (level0Node.parentNode.tagName === ELEMENTS.TrackInsertElement.TAG) {
                  this.selectionManager.selectNodeContents(level0Node.parentNode);
                }
              }
            }
          }
        }
      }

      _handleBackspaceAtElementStart(actionContext, level0Node, textElementNode) {
        let previousSibling;

        if (textElementNode?.previousSibling || level0Node.previousSibling) {
          previousSibling = textElementNode
            ? textElementNode.previousSibling
            : level0Node.previousSibling;
        } else if (
          DOMUtils.isNodeAContainerElement(level0Node.parentNode) &&
          level0Node.parentNode.tagName !== ELEMENTS.TableCellElement.TAG
        ) {
          previousSibling = DOMUtils.findPreviousAncestorSibling(level0Node);
        }

        if (previousSibling) {
          if (previousSibling.tagName === ELEMENTS.TrackDeleteElement.TAG) {
            // TODO : set caret previousSibling.previousSibling
            this.selectionManager.setCaret(previousSibling, 'PRE');
          } else if (previousSibling.tagName === ELEMENTS.TrackInsertElement.TAG) {
            this.selectionManager.selectNode(previousSibling);
          } else if (!DOMUtils.isBlockNodeDeletable(previousSibling)) {
            // previous sibling is non-deletable
            if (EditorDOMUtils.isEmptyElement(level0Node) && previousSibling.selectableContent) {
              this.selectionManager.selectNode(level0Node);
              const range = EditorSelectionUtils.getRange();
              this.addDeleteSuggestionOnRange(actionContext, range);
              this.selectionManager.setCaret(previousSibling.selectableContent, 'INSIDE_END');
            } else {
              this.selectionManager.selectNodeContents(level0Node);
            }
          } else if (!DOMUtils.isBlockNodeEditable(previousSibling)) {
            if (DOMUtils.BLOCK_INVALID_ELEMENTS.includes(previousSibling.tagName)) {
              this.selectionManager.setCaret(textElementNode, 'INSIDE_START');

              // remove element
              actionContext.addChange(
                ActionContext.CHANGE_TYPE.REMOVED,
                previousSibling.getAttribute('id'),
              );
              previousSibling.remove();
            } else {
              this.selectionManager.selectNode(previousSibling);
              const range = EditorSelectionUtils.getRange();
              this.addDeleteSuggestionOnRange(actionContext, range);
              this.selectionManager.setCaret(textElementNode, 'INSIDE_START');
            }
          } else if (
            previousSibling.tagName === ELEMENTS.FigureElement.TAG ||
            previousSibling.tagName === ELEMENTS.TableElement.TAG ||
            DOMUtils.isNodeAContainerElement(previousSibling)
          ) {
            this.selectionManager.selectNode(previousSibling);
          } else if (DOMUtils.isNodeEditableTextElement(previousSibling)) {
            // previous sibling is a default text element
            if (DOMUtils.INLINE_LAST_CHILD_ELEMENTS.includes(previousSibling.lastChild.tagName)) {
              this.selectionManager.selectNode(previousSibling.lastChild);
            } else {
              this.selectionManager.setRangeStart(
                previousSibling,
                'INDEX',
                previousSibling.childNodes.length,
              );

              this.selectionManager.setRangeEnd(textElementNode, 'INDEX', 0);

              this.handleBackspaceOnMultiSelection(actionContext, level0Node, {
                fixTextSelection: false,
              });
            }
          }
        }
      }
    },
);
