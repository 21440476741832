import { Patch } from './Patch';
import { Stack } from './Stack';
import { Hook } from './UndoManagerHook';

type UndoManagerOptions = {
  stackLimit?: number;
  autoPatch?: boolean;
};

type UndoManagerHooks<
  T extends Realtime.Core.UndoManager.AvailableHooks = Realtime.Core.UndoManager.AvailableHooks,
> = Record<T, Hook<T>>;

export class UndoManager {
  private undoStack: Stack;
  private redoStack: Stack;
  private patch?: Patch;
  private options: Required<UndoManagerOptions>;
  hooks: UndoManagerHooks;

  constructor(options?: UndoManagerOptions) {
    this.options = {
      stackLimit: 100,
      autoPatch: false,
      ...options,
    };
    this.hooks = {
      onCreatedPatch: new Hook(),
      onFinishingPatch: new Hook(),
      beforePatchApply: new Hook(),
      afterPatchApply: new Hook(),
      beforePatchRevert: new Hook(),
      afterPatchRevert: new Hook(),
      onUndoStatusChanged: new Hook<'onUndoStatusChanged'>(),
      onRedoStatusChanged: new Hook<'onRedoStatusChanged'>(),
    };
    this.undoStack = new Stack({
      limit: this.options.stackLimit,
      onStatusChanged: this.hooks.onUndoStatusChanged as Hook<'onUndoStatusChanged'>,
    });
    this.redoStack = new Stack({
      limit: this.options.stackLimit,
      onStatusChanged: this.hooks.onRedoStatusChanged as Hook<'onRedoStatusChanged'>,
    });
  }

  private getExistingPatch() {
    if (!this.patch) {
      this.patch = new Patch();
      this.hooks.onCreatedPatch.trigger(this.patch);
    }
    return this.patch;
  }

  canUndo() {
    return !this.undoStack.isEmpty;
  }

  canRedo() {
    return !this.redoStack.isEmpty;
  }

  onDocOperation(
    doc: Realtime.Core.RealtimeObject,
    ops: Realtime.Core.RealtimeOps,
    options: Realtime.Core.RealtimeSourceOptions,
  ) {
    if (ops.length <= 0) {
      return;
    }
    if (options.source === true || options.source === 'LOCAL_RENDER') {
      this.redoStack.clear();
      const patch = this.getExistingPatch();
      patch.add(doc, ops);
      if (this.options.autoPatch) {
        this.createPatch();
      }
    } else if (!options.source) {
      this.undoStack.transformStack(doc, ops);
      this.redoStack.transformStack(doc, ops);
    }
  }

  createPatch() {
    if (this.patch && !this.patch.isEmpty) {
      this.hooks.onFinishingPatch.trigger(this.patch);
      this.undoStack.setLast(this.patch);
      this.patch = undefined;
    }
  }

  async undo() {
    if (this.canUndo()) {
      const patch = this.undoStack.pop();
      if (patch && !patch.isEmpty) {
        await this.hooks.beforePatchRevert.trigger(patch);
        console.log('pre revert');
        patch.revert();
        console.log('pos revert');
        this.redoStack.setLast(patch);
        console.log('pos set last');
        await this.hooks.afterPatchRevert.trigger(patch);
      }
    }
  }

  async redo() {
    if (this.canRedo()) {
      const patch = this.redoStack.pop();
      if (patch && !patch.isEmpty) {
        await this.hooks.beforePatchApply.trigger(patch);
        patch.apply();
        this.undoStack.setLast(patch);
        await this.hooks.afterPatchApply.trigger(patch);
      }
    }
  }
}
