import { merge } from 'lodash';
import { RealtimeObject } from '_common/services/Realtime';

const DEFAULT_PROPERTIES = {
  // 612
  // 792
  sz: {
    w: 612,
    h: 792,
  },
  p_o: 'P',
  mar: {
    t: 72,
    r: 72,
    b: 72,
    l: 72,
    h: 36,
    f: 36,
    g: 0,
  },
};

export class Section extends RealtimeObject<Editor.Data.Sections.Data> {
  protected previous?: Section;
  constructor(transport: Realtime.Transport.Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'sections');
    this.handlePreviousSectionUpdate = this.handlePreviousSectionUpdate.bind(this);
  }

  protected selectedData(): Editor.Data.Sections.Data | null {
    let data;
    if (this.loadedVersion) {
      data = this.loadedVersion.data ? this.loadedVersion.data : null;
    } else {
      data = this.model.data;
    }

    let properties = {};
    if (this.previous) {
      if (!data) {
        properties = this.previous.selectedData()?.p || {};
      } else {
        merge(properties, this.previous.selectedData()?.p || {}, data.p);
      }
    } else {
      properties = data?.p || {};
    }

    data = {
      id: this.id,
      ...(data || {}),
      p: {
        ...properties,
      },
    };

    if (data != null) {
      return JSON.parse(JSON.stringify(data));
    }
    return null;
  }

  get previousSection() {
    return this.selectedData()?.ps;
  }

  get properties() {
    return this.selectedData()?.p;
  }

  get pageOrientation() {
    if (!this.selectedData()?.p.p_o) {
      return DEFAULT_PROPERTIES.p_o;
    }
    return this.selectedData()?.p.p_o;
  }

  get pageSize() {
    let data = this.selectedData();
    if (data?.p.sz) {
      return data.p.sz;
    }
    if (data?.p.p_o === 'L') {
      return {
        w: DEFAULT_PROPERTIES.sz.h,
        h: DEFAULT_PROPERTIES.sz.w,
      };
    }
    return DEFAULT_PROPERTIES.sz;
  }

  get pageMargins() {
    let data = this.selectedData();
    if (data?.p.mar) {
      return data.p.mar;
    }
    return DEFAULT_PROPERTIES.mar;
  }

  get pageNumberFormat() {
    let data = this.selectedData();
    return data?.p.pnf;
  }

  protected handlePreviousSectionUpdate() {
    this.emit('UPDATED', this.get(), []);
  }

  bindToPrevious(previous: Section) {
    if (this.previous !== previous) {
      this.previous?.off('LOADED', this.handlePreviousSectionUpdate);
      this.previous?.off('UPDATED', this.handlePreviousSectionUpdate);
    }
    this.previous = previous;
    if (this.previous) {
      this.previous.on('LOADED', this.handlePreviousSectionUpdate);
      this.previous.on('UPDATED', this.handlePreviousSectionUpdate);
      this.handlePreviousSectionUpdate();
    }
  }

  get sectionType() {
    return this.selectedData()?.p?.t;
  }

  static TYPE_NAME() {
    return 'SECTION';
  }

  static TYPE_COLLECTION() {
    return 'sections';
  }

  handleLoad(): void {}

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    this.emit('UPDATED', this.get(), ops, source);
    /* const length = ops.length;
    let op;
    let path;
    let updateOp = false;
    for (let i = 0; i < length; i++) {
      op = ops[i];
      path = op.p;
      if (path && path.includes('p')) {
        // eslint-disable-next-line no-continue
        updateOp = true;
      }
    } */
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }
}
