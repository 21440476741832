import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import CommentCardContent, { CommentCardProps } from './CommentCardContent';

const CommentCard = (props: CommentCardProps) => {
  return (
    <IntlErrorBoundary
      fallbackType="card"
      mockProps={{ sidebar: !!props.panel, width: props.panel ? '100%' : '43rem' }}
    >
      <CommentCardContent {...props} />
    </IntlErrorBoundary>
  );
};

export default CommentCard;
