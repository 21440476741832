import { Logger } from '_common/services';
import { RealtimeOpsBuilder } from '_common/services/Realtime';
import { Structure, StructureData } from '../../models';
import BaseController from '../BaseController';

export class StructureController extends BaseController {
  private structure?: Structure;

  constructor(Data: Editor.Data.State) {
    super(Data);

    this.handleEventUpdated = this.handleEventUpdated.bind(this);
    this.handleEventLoaded = this.handleEventLoaded.bind(this);
    this.handleEventChildAdded = this.handleEventChildAdded.bind(this);
    this.handleEventChildRemoved = this.handleEventChildRemoved.bind(this);
  }

  start(documentId: string): void {
    this.structure = this.Data.models?.get(
      this.Data?.models.TYPE_NAME.STRUCTURE,
      `DS${documentId}`,
    );

    this.structure?.on('UPDATED', this.handleEventUpdated);
    this.structure?.on('LOADED', this.handleEventLoaded);
    this.structure?.on('CHILD_ADDED', this.handleEventChildAdded);
    this.structure?.on('CHILD_REMOVED', this.handleEventChildRemoved);
  }

  stop(): void {
    this.structure?.off('UPDATED', this.handleEventUpdated);
    this.structure?.off('LOADED', this.handleEventLoaded);
    this.structure?.off('CHILD_ADDED', this.handleEventChildAdded);
    this.structure?.off('CHILD_REMOVED', this.handleEventChildRemoved);
  }

  destroy(): void {
    this.unregisterAllTransportEvents();
    this.stop();
  }

  private handleEventUpdated(data: StructureData | null) {}

  private handleEventLoaded(data: StructureData | null) {}

  private handleEventChildAdded(operationContext: Realtime.Core.OperationContext) {
    this.Data?.events?.emit('ADDED_BLOCK', operationContext);
  }

  private handleEventChildRemoved(operationContext: Realtime.Core.OperationContext) {
    this.Data?.events?.emit('REMOVED_BLOCK', operationContext);
  }

  get structureModel() {
    return this.structure;
  }

  get structureData() {
    return this.structure?.get();
  }

  updateTaskSequence(sequence: number, options?: Realtime.Core.RealtimeSourceOptions) {
    return this.structure?.updateTaskSequence(sequence, options);
  }

  structureHasAutomaticUpdates() {
    return this.structure?.hasAutomaticUpdates;
  }

  getWordTemplate() {
    return this.structure?.wordTemplate;
  }

  forceUpdateStructure() {
    return this.structure?.forceUpdate();
  }

  getDocumentNodes() {
    return this.structure?.get()?.childNodes || [];
  }

  getSectionsNodes() {
    return this.structure?.getSectionsNodes();
  }

  getSectionNodes(sectionId: string) {
    return this.structure?.getSectionNodes(sectionId);
  }

  getDefaultTabStop(): number | null {
    return this.structure?.get(['extra', 'ts']) || null;
  }

  setDefaultTabStop(value: number) {
    if (this.structure) {
      if (!this.structure.get(['extra'])) {
        return this.structure.set(['extra'], { ts: value });
      } else {
        return this.structure.set(['extra', 'ts'], value);
      }
    }
  }

  getSectionForPosition(
    nodeId: string,
    referenceId: string | null,
    position: number,
  ): string | null {
    const getPreviousNodeSection = (position: number, reference?: any) => {
      const previousNodeId = this.structure?.get()?.childNodes[position - 1];
      if (previousNodeId) {
        const nodeModel = this.Data.models?.get(this.Data?.models.TYPE_NAME.NODE, previousNodeId);
        const nodeData = nodeModel?.get();
        if (nodeData?.properties?.n_sct) {
          return nodeData.properties.n_sct;
        }
        const breakReference = nodeData?.refs?.br?.find((elem: any) => elem.t === 'Section');
        if (breakReference && breakReference.n_sct && breakReference.n_sct !== reference?.n_sct) {
          return breakReference?.n_sct;
        }
        return this.structure?.get()?.blkProps[previousNodeId].sct;
      }
      return null;
    };
    if (this.structure?.get()?.childNodes == null) {
      return null;
    }
    if (this.structure?.get()?.childNodes.length === position) {
      return getPreviousNodeSection(position);
    }
    const thisNode = this.Data.models?.get(this.Data?.models.TYPE_NAME.NODE, nodeId);
    const thisNodeData = thisNode?.get();
    const breakReference = thisNodeData?.refs?.br?.find((elem: any) => elem.t === 'Section');
    if (breakReference) {
      return getPreviousNodeSection(position, breakReference);
    }

    if (referenceId) {
      return this.structure?.get()?.blkProps[referenceId]?.sct;
    }

    return null;
  }

  getBlockProperties(nodeId: string) {
    return this.structure?.getBlockProperties(nodeId);
  }

  getIndexOfNode(nodeId: string) {
    return this.structure?.getIndexOfNode(nodeId);
  }

  getNodeReferences(nodeId: string) {
    return this.structure?.getNodeReferences(nodeId);
  }

  addChildBeforeOps(
    nodeId: string,
    referenceId: string | null,
    extra: any = {},
    newBlocks: any = undefined,
    childOffset: number = 0,
  ): Realtime.Core.RealtimeOps {
    const data = this.structure?.get();
    const op: Realtime.Core.RealtimeOps = [];
    if (data) {
      let position;
      let sct;
      if (newBlocks && referenceId && newBlocks[referenceId]) {
        position = newBlocks[referenceId].position;
        sct = newBlocks[referenceId].sct;
      } else {
        position = data.childNodes?.indexOf(referenceId);
        if (position < 0 || position == null) {
          position = data.childNodes?.length || 0;
          referenceId = data.childNodes[position];
        }

        sct = this.getSectionForPosition(nodeId, referenceId, position);
      }

      if (sct) {
        if (newBlocks) {
          newBlocks[nodeId] = { sct, position };
        }
        op.push({
          li: nodeId,
          p: ['childNodes', position - childOffset],
        });
        op.push({
          oi: {
            sct,
            ...this.structure?.getBlockProperties(nodeId),
            ...extra,
          },
          p: ['blkProps', nodeId],
        });
        if (extra.lst && extra.lst.lId) {
          let listPosition = data.lists[extra.lst.lId]?.n?.indexOf(referenceId);

          if (listPosition < 0 || listPosition == null) {
            listPosition = data.lists[extra.lst.lId]?.n?.length || 0;
          }

          op.push({
            p: ['lists', extra.lst.lId, 'n', listPosition],
            li: nodeId,
          });
        }
      }
    }
    return op;
  }

  async addChildBefore(
    nodeId: string,
    referenceId: string,
    options?: Realtime.Core.RealtimeSourceOptions,
  ) {
    try {
      if (this.structure) {
        return this.structure.apply(this.addChildBeforeOps(nodeId, referenceId), options);
      }
    } catch (error) {
      Logger.captureException(error);
    }
  }

  removeChildOps(nodeId: string, childOffset: number = 0): Realtime.Core.RealtimeOps {
    const nodeIndex = this.structure?.get()?.childNodes.indexOf(nodeId);
    if (nodeIndex != null && nodeIndex >= 0) {
      const op = [];
      // remove the node from the structure
      op.push(RealtimeOpsBuilder.listDelete(nodeId, ['childNodes', nodeIndex - childOffset]));
      // check if we have block properties, something might have gone wrong
      if (this.structure?.get()?.blkProps.hasOwnProperty(nodeId)) {
        const props = this.structure?.get()?.blkProps[nodeId];
        op.push({
          od: props,
          p: ['blkProps', nodeId],
        });
        // we might need to override the style list definition with an invalid inline
        // to remove a block from an outline. This check has to stay here
        if (props.lst && props.lst.lId && this.structure?.get()?.lists[props.lst.lId]) {
          const listPosition = this.structure?.get()?.lists[props.lst.lId].n.indexOf(nodeId);
          if (listPosition >= 0) {
            if (this.structure?.get()?.lists[props.lst.lId].n.length === 1) {
              op.push({
                p: ['lists', props.lst.lId],
                od: this.structure?.get()?.lists[props.lst.lId],
              });
            } else {
              op.push({
                p: ['lists', props.lst.lId, 'n', listPosition],
                ld: nodeId,
              });
            }
          }
        }
      }
      return op;
    }
    throw new Error(`NodeID (${nodeId}) not found on structure`);
  }

  removeChild(
    nodeId: string,
    childOffset: number = 0,
    options?: Realtime.Core.RealtimeSourceOptions,
  ) {
    try {
      return this.structure?.apply(this.removeChildOps(nodeId, childOffset), options);
    } catch (error) {
      Logger.captureException(error);
    }
  }

  async applyOpsToStructure(
    ops: Realtime.Core.RealtimeOps,
    options?: Realtime.Core.RealtimeSourceOptions,
  ) {
    try {
      return this.structure?.apply(ops, options);
    } catch (error) {
      Logger.captureException(error);
    }
  }

  async revertOpsToStructure(
    ops: Realtime.Core.RealtimeOps,
    options?: Realtime.Core.RealtimeSourceOptions,
  ) {
    try {
      return this.structure?.revert(ops, options);
    } catch (error) {
      Logger.captureException(error);
    }
  }
}
