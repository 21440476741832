import uuid from 'uuid/v4';
import { Logger } from '_common/services';
import { ParseMapper } from 'Editor/services/Parsers';

const LEVEL_0_ALLOWED = [
  'PARAGRAPH-ELEMENT',
  'TABLE',
  'FIGURE-ELEMENT',
  'TRACK-INS-ELEMENT',
  'TRACK-DEL-ELEMENT',
  'PAGE-BREAK-ELEMENT', // legacy
  'SECTION-BREAK-ELEMENT', // legacy
  'KEYWORDS-ELEMENT',
  'AUTHORS-ELEMENT',
  'TABLE-OF-CONTENTS-ELEMENT',
  'LIST-OF-FIGURES-ELEMENT',
  'LIST-OF-TABLES-ELEMENT',
  'REFERENCES-SECTION-ELEMENT',

  'PAGE-ELEMENT',
];

const ALLOWED_DESCENDANTS = {
  'PAGE-ELEMENT': [],
  'PARAGRAPH-ELEMENT': [
    'HYPERLINK-ELEMENT',
    'COMMENT-ELEMENT',
    'TRACK-INS-ELEMENT',
    'TRACK-DEL-ELEMENT',
    'FORMAT-ELEMENT',
    'BR',
    'CITATIONS-GROUP-ELEMENT',
    'TEMP-COMMENT-ELEMENT',
    'NOTE-ELEMENT',
    'SYMBOL-ELEMENT',
    'CROSS-REFERENCE-ELEMENT',
    'PASTE-MARKER-ELEMENT',
    'EQUATION-ELEMENT',
    'FIELD-ELEMENT',
    'PLACEHOLDER-ELEMENT',
    'FIGURE-ELEMENT',
    'PAGE-BREAK-ELEMENT',
    'SECTION-BREAK-ELEMENT',
    'COLUMN-BREAK-ELEMENT',
    'TAB-ELEMENT',
    'IMAGE-ELEMENT',
  ],
  'FORMAT-ELEMENT': [
    'FORMAT-ELEMENT',
    'HYPERLINK-ELEMENT',
    'COMMENT-ELEMENT',
    'TRACK-INS-ELEMENT',
    'TRACK-DEL-ELEMENT',
    'BR',
    'CITATIONS-GROUP-ELEMENT',
    'CITATION-ELEMENT',
    'TEMP-COMMENT-ELEMENT',
    'NOTE-ELEMENT',
    'SYMBOL-ELEMENT',
    'CROSS-REFERENCE-ELEMENT',
    'PASTE-MARKER-ELEMENT',
    'EQUATION-ELEMENT',
    'FIELD-ELEMENT',
    'PLACEHOLDER-ELEMENT',
    'PAGE-BREAK-ELEMENT',
    'SECTION-BREAK-ELEMENT',
    'COLUMN-BREAK-ELEMENT',
    'TAB-ELEMENT',
    'IMAGE-ELEMENT',
  ],
  'TEMP-COMMENT-ELEMENT': [
    'COMMENT-ELEMENT',
    'TEMP-COMMENT-ELEMENT',
    'CROSS-REFERENCE-ELEMENT',
    'HYPERLINK-ELEMENT',
    'TRACK-INS-ELEMENT',
    'TRACK-DEL-ELEMENT',
    'FORMAT-ELEMENT',
    'CITATIONS-GROUP-ELEMENT',
    'NOTE-ELEMENT',
    'SYMBOL-ELEMENT',
    'CROSS-REFERENCE-ELEMENT',
    'PASTE-MARKER-ELEMENT',
    'EQUATION-ELEMENT',
    'FIELD-ELEMENT',
    'PAGE-BREAK-ELEMENT',
    'SECTION-BREAK-ELEMENT',
    'COLUMN-BREAK-ELEMENT',
    'PLACEHOLDER-ELEMENT',
    'TAB-ELEMENT',
    'BR',
    'IMAGE-ELEMENT',
  ],
  'COMMENT-ELEMENT': [
    'COMMENT-ELEMENT',
    'TEMP-COMMENT-ELEMENT',
    'CROSS-REFERENCE-ELEMENT',
    'HYPERLINK-ELEMENT',
    'TRACK-INS-ELEMENT',
    'TRACK-DEL-ELEMENT',
    'FORMAT-ELEMENT',
    'CITATIONS-GROUP-ELEMENT',
    'NOTE-ELEMENT',
    'SYMBOL-ELEMENT',
    'CROSS-REFERENCE-ELEMENT',
    'PASTE-MARKER-ELEMENT',
    'EQUATION-ELEMENT',
    'FIELD-ELEMENT',
    'PAGE-BREAK-ELEMENT',
    'SECTION-BREAK-ELEMENT',
    'COLUMN-BREAK-ELEMENT',
    'PLACEHOLDER-ELEMENT',
    'TAB-ELEMENT',
    'BR',
    'IMAGE-ELEMENT',
  ],
  'FIELD-ELEMENT': [
    'COMMENT-ELEMENT',
    'TEMP-COMMENT-ELEMENT',
    'CROSS-REFERENCE-ELEMENT',
    'HYPERLINK-ELEMENT',
    'TRACK-INS-ELEMENT',
    'TRACK-DEL-ELEMENT',
    'FORMAT-ELEMENT',
    'CITATIONS-GROUP-ELEMENT',
    'NOTE-ELEMENT',
    'SYMBOL-ELEMENT',
    'CROSS-REFERENCE-ELEMENT',
    'PASTE-MARKER-ELEMENT',
    'EQUATION-ELEMENT',
    'FIELD-ELEMENT',
    'PAGE-BREAK-ELEMENT',
    'SECTION-BREAK-ELEMENT',
    'COLUMN-BREAK-ELEMENT',
    'PLACEHOLDER-ELEMENT',
    'TAB-ELEMENT',
    'BR',
    'IMAGE-ELEMENT',
  ],
  'REFERENCES-SECTION-ELEMENT': [
    'PARAGRAPH-ELEMENT',
    'TABLE',
    'FIGURE-ELEMENT',
    'TRACK-INS-ELEMENT',
    'TRACK-DEL-ELEMENT',
    'PAGE-BREAK-ELEMENT',
    'SECTION-BREAK-ELEMENT',
    'KEYWORDS-ELEMENT',
    'AUTHORS-ELEMENT',
    'TABLE-OF-CONTENTS-ELEMENT',
    'LIST-OF-FIGURES-ELEMENT',
    'LIST-OF-TABLES-ELEMENT',
  ],
  TABLE: ['STYLE', 'CAPTION', 'THEAD', 'TBODY', 'PASTE-MARKER-ELEMENT'],
  THEAD: ['TR'],
  TBODY: ['TR'],
  TR: ['TD', 'TH'],
  TD: ['PARAGRAPH-ELEMENT', 'TABLE', 'FIGURE-ELEMENT', 'TRACK-INS-ELEMENT', 'TRACK-DEL-ELEMENT'],
  'FIGURE-ELEMENT': ['IMAGE-ELEMENT', 'FIGCAPTION', 'TRACK-INS-ELEMENT', 'PASTE-MARKER-ELEMENT'],
  'CITATIONS-GROUP-ELEMENT': [
    'CITATION-ELEMENT',
    'TRACK-INS-ELEMENT',
    'TRACK-DEL-ELEMENT',
    'FORMAT-ELEMENT',
    'TEXT',
  ],
  'CITATION-ELEMENT': [],
  'CROSS-REFERENCE-ELEMENT': [],
  'TRACK-INS-ELEMENT': [
    'SYMBOL-ELEMENT',
    'PARAGRAPH-ELEMENT',
    'TABLE',
    'FIGURE-ELEMENT',
    'FIGCAPTION',
    'TRACK-INS-ELEMENT',
    'TRACK-DEL-ELEMENT',
    'PAGE-BREAK-ELEMENT',
    'SECTION-BREAK-ELEMENT',
    'COLUMN-BREAK-ELEMENT',
    'KEYWORDS-ELEMENT',
    'AUTHORS-ELEMENT',
    'TABLE-OF-CONTENTS-ELEMENT',
    'LIST-OF-FIGURES-ELEMENT',
    'LIST-OF-TABLES-ELEMENT',
    'HYPERLINK-ELEMENT',
    'COMMENT-ELEMENT',
    'FORMAT-ELEMENT',
    'BR',
    'CITATIONS-GROUP-ELEMENT',
    'CITATION-ELEMENT',
    'TEMP-COMMENT-ELEMENT',
    'CROSS-REFERENCE-ELEMENT',
    'EQUATION-ELEMENT',
    'NOTE-ELEMENT',
    'PASTE-MARKER-ELEMENT',
    'FIELD-ELEMENT',
    'PLACEHOLDER-ELEMENT',
    'TAB-ELEMENT',
    'REFERENCES-SECTION-ELEMENT',
    'IMAGE-ELEMENT',
  ],
  'TRACK-DEL-ELEMENT': [
    'SYMBOL-ELEMENT',
    'PARAGRAPH-ELEMENT',
    'TABLE',
    'FIGURE-ELEMENT',
    'FIGCAPTION',
    'TRACK-INS-ELEMENT',
    'TRACK-DEL-ELEMENT',
    'PAGE-BREAK-ELEMENT',
    'SECTION-BREAK-ELEMENT',
    'COLUMN-BREAK-ELEMENT',
    'KEYWORDS-ELEMENT',
    'AUTHORS-ELEMENT',
    'TABLE-OF-CONTENTS-ELEMENT',
    'LIST-OF-FIGURES-ELEMENT',
    'LIST-OF-TABLES-ELEMENT',
    'HYPERLINK-ELEMENT',
    'COMMENT-ELEMENT',
    'FORMAT-ELEMENT',
    'BR',
    'CITATIONS-GROUP-ELEMENT',
    'CITATION-ELEMENT',
    'TEMP-COMMENT-ELEMENT',
    'NOTE-ELEMENT',
    'CROSS-REFERENCE-ELEMENT',
    'EQUATION-ELEMENT',
    'PASTE-MARKER-ELEMENT',
    'FIELD-ELEMENT',
    'PLACEHOLDER-ELEMENT',
    'TAB-ELEMENT',
    'REFERENCES-SECTION-ELEMENT',
    'IMAGE-ELEMENT',
  ],
  'IMAGE-ELEMENT': ['IMG'],
  'PASTE-MARKER-ELEMENT': [],
  'HYPERLINK-ELEMENT': [
    'COMMENT-ELEMENT',
    'TEMP-COMMENT-ELEMENT',
    'CROSS-REFERENCE-ELEMENT',
    'HYPERLINK-ELEMENT',
    'TRACK-INS-ELEMENT',
    'TRACK-DEL-ELEMENT',
    'FORMAT-ELEMENT',
    'CITATIONS-GROUP-ELEMENT',
    'NOTE-ELEMENT',
    'SYMBOL-ELEMENT',
    'CROSS-REFERENCE-ELEMENT',
    'PASTE-MARKER-ELEMENT',
    'EQUATION-ELEMENT',
    'FIELD-ELEMENT',
    'BR',
    'PAGE-BREAK-ELEMENT',
    'SECTION-BREAK-ELEMENT',
    'COLUMN-BREAK-ELEMENT',
    'PLACEHOLDER-ELEMENT',
    'TAB-ELEMENT',
    'IMAGE-ELEMENT',
  ],
};

const ALLOW_MERGE = [
  'COMMENT-ELEMENT',
  'TRACK-INS-ELEMENT',
  'TRACK-DEL-ELEMENT',
  'FORMAT-ELEMENT',
  'TEMP-COMMENT-ELEMENT',
];

const SKIP_CHILD_NORMALIZATION = ['EQUATION-ELEMENT'];

const SKIP_NORMALIZATION = {
  SPAN: () => {
    return true;
  },
  DIV: () => {
    return true;
  },
};

export function skipChildNormalization(node) {
  return SKIP_CHILD_NORMALIZATION.includes(node.tagName);
}

// TODO: maybe change the name
export function skipNormalization(node) {
  if (node && SKIP_NORMALIZATION[node.tagName]) {
    return SKIP_NORMALIZATION[node.tagName](node);
  }
  return false;
}

export function getValidNodes(root) {
  const results = [];
  if (root && root.childNodes) {
    const length = root.childNodes.length;
    for (let i = 0; i < length; i++) {
      if (!skipNormalization(root.childNodes[i])) {
        results.push(root.childNodes[i]);
      }
    }
  }
  return results;
}

export function evaluateNodes(nodes) {
  const result = [];
  const length = nodes.length;
  // eslint-disable-next-line
  for (var i = 0; i < length; i++) {
    if (!skipNormalization(nodes[i])) {
      result.push(nodes[i]);
    }
  }
  return result;
}

class DOMNormalizer {
  static generateRandomNodeId() {
    return `ddc${uuid()}`;
  }

  static isElementAllowedUnderPage(nodeTag) {
    return LEVEL_0_ALLOWED.includes(nodeTag);
  }

  static isAllowedUnder(nodeTag, parentTag) {
    return ALLOWED_DESCENDANTS[parentTag] && ALLOWED_DESCENDANTS[parentTag].includes(nodeTag);
  }

  static canBeMerged(tag) {
    return ALLOW_MERGE.includes(tag);
  }

  static normalizeChildren(node) {
    const nodeTag = node.tagName;

    // TODO: refactor this
    // this will provoke unecessary calls to connect and disconnect callbacks

    const children = Array.from(node.childNodes);
    let child;
    let childTag;
    node.innerHTML = '';
    const nodeList = node.childNodes;
    // remove and merge textnodes
    for (let i = 0; i < children.length; i += 1) {
      child = children[i];
      if (child.nodeType === 3) {
        // text nodes
        // skip empty nodes
        if (child.textContent.length !== 0) {
          // check if we have a previous node and if it is a text node
          if (nodeList.length - 1 >= 0 && nodeList[nodeList.length - 1].nodeType === 3) {
            // append this content to the previous
            nodeList[nodeList.length - 1].textContent += child.textContent;
          } else {
            // append this node to the new
            node.appendChild(child);
          }
        }
      } else if (child.nodeType === 1) {
        childTag = child.tagName;
        if (!skipNormalization(child) && !DOMNormalizer.isAllowedUnder(childTag, nodeTag)) {
          // check node tag
          // child is not allowed under this node, has to be unwraped
          Logger.captureMessage(`Unwrapped ${childTag} not allowed under ${nodeTag}`);
          // unwrap node into parent
          // TODO: check if for is faster than the ...
          children.splice(i, 1, ...child.childNodes);
          i -= 1;
        } else if (childTag === nodeTag && node.isEqual && node.isEqual(child)) {
          // child has the same properties as the parent it can be unwraped
          while (child.firstChild) {
            node.appendChild(child.firstChild);
          }
        }
        // TODO: merge nodes can't be done this way
        // commented code its just for future reference
        // else if (
        //   nodeList.length - 1 >= 0 &&
        //   childTag === nodeList[nodeList.length - 1].tagName &&
        //   DOMNormalizer.canBeMerged(childTag) &&
        //   child.isEqual &&
        //   child.isEqual(nodeList[nodeList.length - 1])
        // ) {
        //   // special case for nodeTypes that can be merged if they are equal
        //   while (child.firstChild) {
        //     nodeList[nodeList.length - 1].appendChild(child.firstChild);
        //   }
        // }
        else {
          node.appendChild(child);
        }
      } // ignore other nodeTypes
    }
  }

  static normalize(node, root) {
    if (!skipNormalization(node.tagName)) {
      // set id
      if (!node.id || node.id.indexOf('ddc') !== 0) {
        node.setAttribute('id', DOMNormalizer.generateRandomNodeId());
      }
      // set parent_id
      if (node !== root) {
        node.setAttribute('parent_id', node.parentNode.id);
      }
      // set element_type
      node.setAttribute('element_type', ParseMapper.getElementTypeForNode(node));
    }
  }

  static isAlreadyNormalized(root) {
    return root.__normalized;
  }

  static normalizeTree(root, parentId) {
    // console.log('NORMALIZE TREE BEFORE', root.cloneNode(true));
    if (root.nodeType === 1) {
      if (root.tagName !== 'SPAN') {
        // console.time('==== NORMALIZE-TREE');
        // initial normalization for root
        if (parentId) {
          root.setAttribute('parent_id', parentId);
        }
        // setup iteration
        const nodes = [root];
        let node;
        // breadth first
        while (nodes.length > 0) {
          node = nodes.shift();
          if (node.nodeType === 1 && node.tagName !== 'SPAN') {
            // normalize node
            DOMNormalizer.normalize(node, root);
            // normalize children
            if (node.childNodes.length > 0 && !skipChildNormalization(node)) {
              DOMNormalizer.normalizeChildren(node);
              // append children for normalization
              if (node.childNodes.length > 0) {
                nodes.push(...node.childNodes);
              }
            }
          }
        }
        // console.timeEnd('==== NORMALIZE-TREE');
      }
    } else if (root.nodeType === 11) {
      // TODO: remove recursivity
      // document-fragment
      for (let i = 0; i < root.childElementCount; i += 1) {
        DOMNormalizer.normalizeTree(root.children[i], parentId);
      }
    }
    root.__normalized = true;
    // console.log('NORMALIZE TREE AFTER', root.cloneNode(true));
  }
}

export default DOMNormalizer;
