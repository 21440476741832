import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import DetailsPanel from './DetailsPanel';

const DetailsPanelBoundary = () => (
  <IntlErrorBoundary size="medium" margin="16rem 0 0 0">
    <DetailsPanel />
  </IntlErrorBoundary>
);

export default DetailsPanelBoundary;
