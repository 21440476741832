import { useCallback, useEffect } from 'react';

import { useDispatch } from '_common/hooks';
import { InstanceService } from '_common/services';
import { viewPersonalSpaces } from 'Storage/pages/SpacesListPage/redux/spacesListPageSlice';
import { listObjects } from '_common/components/Table/TableSlice';

import { IntlErrorBoundary, Table } from '_common/components';

import PersonalSpaceListRow from './PersonalSpaceListRow/PersonalSpaceListRow';
import Header from './Header';

import styles from './PersonalSpacesListPage.module.scss';

const PAGE_IDENTITY = 'personal_spaces';
const COLUMNS_ARRAY = [
  {
    id: 'name',
    labelId: 'storage.browserHeader.name',
  },
];

const PersonalSpacesListPage = () => {
  const dispatch = useDispatch();

  //#region Table object listing handlers
  const fetchObjects = useCallback(async (parameters) => {
    const res = await new InstanceService().listSpaces({ ...parameters, personals: true });
    return res;
  }, []);

  useEffect(() => {
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, []);

  // No useEffectOnUpdate since fetchObjects doesn't have any dependencies
  // Add if dependencies are added to fetchObjects
  //#endregion

  useEffect(() => {
    return () => {
      dispatch(viewPersonalSpaces(false));
    };
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <IntlErrorBoundary size="small" margin="0 0 0 7rem">
          <Header />
        </IntlErrorBoundary>
      </div>
      <div className={styles.content}>
        <Table
          identity={PAGE_IDENTITY}
          columns={COLUMNS_ARRAY}
          RowComponent={PersonalSpaceListRow}
          fetchObjects={fetchObjects}
        />
      </div>
    </div>
  );
};

export default PersonalSpacesListPage;
