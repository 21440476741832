import { Mixin } from 'mixwith';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class SplitSelectionHelper extends superclass {
      /**
       * @description split content on collapsed selection
       * @param {ActionContext} actionContext
       * @param {Array} extraBlocksToSplit
       */
      splitCollapsedSelection(actionContext, extraBlocksToSplit = [], onlyBaseLevel = false) {
        // split collapsed selection

        const blocksToSplit = [
          ...DOMUtils.DEFAULT_SPLITABLE_LEVEL0_ELEMENTS,
          ...extraBlocksToSplit,
        ];

        const selection = EditorSelectionUtils.getSelection();

        let before;

        if (onlyBaseLevel) {
          const closestContainer = DOMUtils.closestMultiBlockContainerElement(selection.anchorNode);
          let baseLevel;
          if (closestContainer) {
            baseLevel = DOMUtils.findNodeLevel0(closestContainer, selection.anchorNode);
          } else {
            baseLevel = DOMUtils.findNodeLevel0(DOMUtils.getPageNode(), selection.anchorNode);
          }

          if (baseLevel && blocksToSplit.includes(baseLevel.tagName)) {
            before = baseLevel;
          }
        } else {
          before = DOMUtils.closest(selection.anchorNode, blocksToSplit);
        }

        if (!before) {
          return {};
        }

        const copyingRange = EditorSelectionUtils.createNewRange();
        copyingRange.setStart(selection.anchorNode, selection.anchorOffset);
        copyingRange.setEndAfter(before);

        const after = copyingRange.extractContents();

        const nodes = Array.from(after.querySelectorAll('*'));
        for (let i = 0; i < nodes.length; i += 1) {
          if (nodes[i].nodeType === Node.ELEMENT_NODE) {
            nodes[i].removeAttribute('id');
          }
        }

        // remove empty elements from the remaining elements
        let elements = Array.from(
          before.querySelectorAll(
            `comment-element,citations-group-element,${ELEMENTS.TrackInsertElement.IDENTIFIER},${ELEMENTS.TrackDeleteElement.IDENTIFIER}`,
          ),
        );
        elements.forEach((element) => {
          if (
            element &&
            EditorDOMUtils.isEmptyElement(element) &&
            !this.isAddParagraphMarker(element) &&
            !this.isDeleteParagraphMarker(element)
          ) {
            element.remove();
          }
        });
        elements = Array.from(
          after.querySelectorAll(
            `comment-element,citations-group-element,${ELEMENTS.TrackInsertElement.IDENTIFIER},${ELEMENTS.TrackDeleteElement.IDENTIFIER}`,
          ),
        );
        elements.forEach((element) => {
          if (
            element &&
            EditorDOMUtils.isEmptyElement(element) &&
            !this.isAddParagraphMarker(element) &&
            !this.isDeleteParagraphMarker(element)
          ) {
            element.remove();
          }
        });

        // if splited element is a citation group
        const anchorCitationGroup = DOMUtils.closest(
          EditorSelectionUtils.getSelection().anchorNode,
          'CITATIONS-GROUP-ELEMENT',
        );

        if (anchorCitationGroup) {
          if (anchorCitationGroup.getCitationsCount() === 0) {
            anchorCitationGroup.remove();
          }
        }

        const splitedCitationGroup = after.childNodes[0].firstChild;
        if (
          splitedCitationGroup &&
          splitedCitationGroup.tagName === 'CITATIONS-GROUP-ELEMENT' &&
          splitedCitationGroup.getCitationsCount() === 0
        ) {
          splitedCitationGroup.remove();
        }

        // register before as updated
        actionContext.addChangeUpdatedNode(before);

        return { before, after: after.childNodes[0] };
      }
    },
);
