import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';

import { useSelector } from '_common/hooks';
import { useGetTemplatesListQuery } from '../../TemplatesApi';
import { useFilterParamsSelector } from '_common/components/Filters/FilterSlice';

import { EmptyFilteredState } from '_common/components';
import { Table } from '_common/components/Table2';
import Cell from '_common/components/Table2/Cells';

import TemplateEmptyState from './TemplateEmptyState/TemplateEmptyState';
import ActionsCell from './Cells/ActionsCell/ActionsCell';
import NameCell from './Cells/NameCell/NameCell';

import styles from './TemplateTable.module.scss';

type TemplatesTableProps = {
  category: Template['category'];
  search: string;
};

const TemplateTable = ({ category, search }: TemplatesTableProps) => {
  const intl = useIntl();

  const { data, isFetching } = useGetTemplatesListQuery();

  const { selectFilterParams } = useFilterParamsSelector();
  const filterParams = useSelector((state) =>
    selectFilterParams(state, state.filters[`templates-${category}`]),
  );

  const filteredTemplates = useMemo(() => {
    if (!data) {
      return [];
    }
    let filteredTemplates = data.order;

    // Filters by name
    if (search !== '') {
      filteredTemplates = filteredTemplates.filter((templateId) =>
        data.extensions[templateId].name.toLowerCase().includes(search.toLowerCase()),
      );
    }

    // Filter by category
    filteredTemplates = filteredTemplates.filter(
      (templateId) => data.extensions[templateId].category === category,
    );

    // Filters by status if any filter is active
    return filterParams.filter_fields.length > 0
      ? filteredTemplates.filter((templateId) =>
          filterParams.filter_fields.includes(data.extensions[templateId].status),
        )
      : filteredTemplates;
  }, [data, search, filterParams]);

  const columns = useMemo<ColumnProps[]>(
    () => [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'global.name' }),
        width: 344,
        flex: true,
        orderable: true,
      },
      {
        id: 'version',
        header: intl.formatMessage({ id: 'VERSION' }),
        width: 120,
        flex: true,
      },
      {
        id: 'description',
        header: intl.formatMessage({ id: 'global.description' }),
        width: 248,
      },
      {
        id: 'actions',
        width: 198,
      },
    ],
    [],
  );

  const value = useMemo(
    () =>
      data
        ? filteredTemplates.map((templateId) => {
            const template = data.extensions[templateId];
            return {
              id: template.id,
              name: (
                <Cell testId={`${templateId}-name-column`} ellipsis>
                  <NameCell templateId={templateId} />
                </Cell>
              ),
              version: (
                <Cell testId={`${templateId}-version-column`} ellipsis>
                  {template.installed_version ? `v.${template.installed_version}` : ''}
                </Cell>
              ),
              description: (
                <Cell testId={`${templateId}-description-column`} ellipsis>
                  {template.description}
                </Cell>
              ),
              actions: (
                <Cell testId={`${templateId}-actions-column`}>
                  <ActionsCell templateId={templateId} />
                </Cell>
              ),
            };
          })
        : [],
    [filteredTemplates, data],
  );

  if (filteredTemplates.length === 0) {
    return filterParams.filter_fields.length > 0 ? (
      <EmptyFilteredState identity={`templates-${category}`} margin="8rem 0" />
    ) : (
      <TemplateEmptyState />
    );
  }

  return (
    <div className={styles.root}>
      <Table
        identity="templates"
        columns={columns}
        value={value}
        renderFooter={() => null}
        isLoading={isFetching}
        loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
        testId="templates"
        selectable={false}
        flex={false}
        withSuffix={false}
        virtualized={false}
      />
    </div>
  );
};

export default TemplateTable;
