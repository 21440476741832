import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useDispatch, usePublicProfiles, useSelector } from '_common/hooks';
import { setAnnotationsFilter, selectAnnotationsAuthors } from 'PDF/redux/PDFAnnotationsSlice';

import { SearchUser } from '_common/components';

const UsersFilter = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.pdf.annotations.filters);
  const users = useSelector(selectAnnotationsAuthors);
  const { profiles } = usePublicProfiles(users.map((user) => user.id));

  const userOptions = useMemo(() => {
    const options: { label: string; id: string; email?: string; value: string }[] = [];

    users.forEach((user) => {
      const option: { label: string; id: string; email?: string; value: string } = {
        label: '',
        id: '',
        value: '',
      };
      if (user.imported) {
        option.label = user.id;
        option.id = 'IMPORTED_USER';
      } else {
        option.label = profiles[user.id]?.name;
        option.email = profiles[user.id]?.email;
        option.id = user.id;
      }
      option.value = user.id;
      options.push(option);
    });

    return options;
  }, [users, profiles]);

  return (
    <div style={{ padding: '1rem 3rem' }}>
      <SearchUser
        isMulti
        width="100%"
        size="medium"
        options={userOptions}
        onChange={(value) => dispatch(setAnnotationsFilter({ filter: 'users', value }))}
        placeholder={intl.formatMessage({ id: 'SELECT_USER' })}
        value={filters['users']}
        testId="sidebar-comments-filters-users"
        searchable
        editor
      />
    </div>
  );
};

export default UsersFilter;
