import { ReactNode } from 'react';
import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import styles from './SidebarPanel.module.scss';

type SidebarPanelProps = {
  children: ReactNode;
  testId: string;
};

const SidebarPanel = ({ children, testId }: SidebarPanelProps) => (
  <div className={styles.panel} data-testid={testId}>
    <IntlErrorBoundary size="medium" margin="17rem 0 0 0">
      {children}
    </IntlErrorBoundary>
  </div>
);

export default SidebarPanel;
