import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { FigureElement, ImageElement, ParagraphElement, TableElement } from '../../Views';
import {
  TabBarWidgetModel,
  ImageOptionsWidgetModel,
  ResizableWidgetModel,
  TableOptionsWidgetModel,
} from '../../ViewModels/WidgetViewModels';
import { OnboardingPulseWidgetModel } from '../../ViewModels/WidgetViewModels/OnboardingPulseWidgetModel';

type Timeouts = {
  [index in Editor.Visualizer.WidgetTypes]: {
    [index: string]: NodeJS.Timeout;
  };
};

type DynamicWidgets = {
  [index in Editor.Visualizer.DynamicWidgets]: {
    [index: string]: Editor.Visualizer.IWidgetViewModel;
  };
};

type FixedWidgets = {
  [index in Editor.Visualizer.FixedWidgets]: {
    [index: string]: Editor.Visualizer.IWidgetViewModel;
  };
};

export class WidgetManager {
  private Data: Editor.Data.API;
  private Visualizer: Editor.Visualizer.State;

  private widgetsContainer?: HTMLElement;

  private timeouts: Timeouts = {
    resizable: {},
    tableOptions: {},
    imageOptions: {},
    tabulations: {},
    onboardingPulse: {},
  };

  private dynamicWidgets: DynamicWidgets = {
    resizable: {},
    tableOptions: {},
    imageOptions: {},
  };

  private fixedWidgets: FixedWidgets = {
    tabulations: {},
    onboardingPulse: {},
  };

  private debug: boolean = false;

  constructor(dataManager: Editor.Data.API, Visualizer: Editor.Visualizer.State) {
    this.Data = dataManager;
    this.Visualizer = Visualizer;

    this.scheduleAddWidget = this.scheduleAddWidget.bind(this);
    this.addWidget = this.addWidget.bind(this);

    this.rebuildWidgets = this.rebuildWidgets.bind(this);
    this.rebuildWidgetForView = this.rebuildWidgetForView.bind(this);

    this.handleImageWrapChange = this.handleImageWrapChange.bind(this);
  }

  start() {
    if (this.debug) {
      logger.trace('WidgetManager start');
    }
    const editorContainer = document.getElementById('EditorContainer');
    if (editorContainer) {
      this.widgetsContainer = document.createElement('div');
      this.widgetsContainer.id = 'widgetContainer';
      this.widgetsContainer.style.position = 'absolute';
      this.widgetsContainer.style.top = '0px';
      this.widgetsContainer.style.left = '0px';
      this.widgetsContainer.style.zIndex = '1';

      editorContainer.appendChild(this.widgetsContainer);
    }

    if (this.Visualizer?.hooks?.afterAllParagraphStylesUpdate) {
      this.Visualizer.hooks.afterAllParagraphStylesUpdate?.register(() => {
        setTimeout(() => {
          this.rebuildWidgets();
        }, 0);
      });
    }
  }

  destroy() {
    if (this.debug) {
      logger.trace('WidgetManager destroy');
    }
    this.removeAllWidgets();

    if (this.widgetsContainer) {
      this.widgetsContainer.remove();
      delete this.widgetsContainer;
    }
  }

  isFixedWidget(type: Editor.Visualizer.WidgetTypes): type is Editor.Visualizer.FixedWidgets {
    return type === 'tabulations' || type === 'onboardingPulse';
  }

  layoutChanged(layout: Editor.Visualizer.LayoutType) {
    if (this.debug) {
      logger.trace('WidgetManager layoutChanged ' + layout);
    }
    this.removeAllWidgets();

    if (this.widgetsContainer) {
      switch (layout) {
        case 'PAGE':
          this.widgetsContainer.style.left = '50%';
          break;
        case 'WEB':
          this.widgetsContainer.style.left = '0px';
          break;
        default:
          break;
      }
    }
  }

  private handleImageWrapChange(props: Editor.Styles.ImageProperties) {
    this.Visualizer.editionManager.handleUpdateImageProperties(props);
  }

  private addImageOptionsWidget(
    view: HTMLElement,
    props: Partial<Editor.Visualizer.WidgetData['imageOptions']['props']>,
  ) {
    if (view instanceof FigureElement && view.firstElementChild != null) {
      view = view.firstElementChild as Editor.Visualizer.BaseView;
    }

    if (!this.widgetsContainer || !(view instanceof ImageElement)) {
      return;
    }

    const propsToWidget = {
      ...props,
      onImageWrapChange: this.handleImageWrapChange,
    };

    let widget = this.dynamicWidgets['imageOptions'][view.id];
    if (!widget) {
      widget = new ImageOptionsWidgetModel(this.Visualizer, view, propsToWidget);

      this.dynamicWidgets['imageOptions'][view.id] = widget;
      this.widgetsContainer.appendChild(widget.getContainer());
    } else {
      widget.updateProps(propsToWidget);
    }

    widget.render();
  }

  private addTableOptionsWidget(
    view: HTMLElement,
    props: Editor.Visualizer.WidgetData['tableOptions']['props'],
  ) {
    if (!this.widgetsContainer || !(view instanceof TableElement)) {
      return;
    }

    let widget = this.dynamicWidgets['tableOptions'][view.id];
    if (!widget) {
      // build widget view and append it to widgets container
      widget = new TableOptionsWidgetModel(this.Visualizer, view, props);

      this.dynamicWidgets['tableOptions'][view.id] = widget;
      this.widgetsContainer.appendChild(widget.getContainer());
    } else {
      widget.updateProps(props);
    }

    widget.render();
  }

  private addResizableWidget(
    view: HTMLElement,
    props: Partial<Editor.Visualizer.WidgetData['resizable']['props']>,
  ) {
    if (view instanceof FigureElement && view.firstElementChild != null) {
      view = view.firstElementChild as ImageElement;
    }

    if (!this.widgetsContainer) {
      return;
    }

    if (view instanceof ImageElement || view instanceof TableElement) {
      let widget = this.dynamicWidgets['resizable'][view.id];
      if (!widget) {
        widget = new ResizableWidgetModel(this.Visualizer, view, props);

        this.dynamicWidgets['resizable'][view.id] = widget;
        this.widgetsContainer.appendChild(widget.getContainer());
      } else {
        widget.updateProps(props);
      }

      widget.render();
    }
  }

  private addTabBarWidget(
    view: HTMLElement,
    props: Partial<Editor.Visualizer.WidgetData['tabulations']['props']>,
  ) {
    if (!(view instanceof ParagraphElement) || !this.widgetsContainer) {
      return;
    }

    let widget = this.fixedWidgets['tabulations'][view.id];
    if (!widget) {
      widget = new TabBarWidgetModel(this.Visualizer, view, {
        tabPos: [],
        ...props,
      });

      this.fixedWidgets['tabulations'][view.id] = widget;
      this.widgetsContainer.appendChild(widget.getContainer());
    } else {
      widget.updateProps(props);
    }

    widget.render();
  }

  private addOnboardingPulseWidget(
    view: HTMLElement,
    props: Partial<Editor.Visualizer.WidgetData['onboardingPulse']['props']>,
  ) {
    if (!(view instanceof ParagraphElement) || !this.widgetsContainer) {
      return;
    }

    let widget = this.fixedWidgets['onboardingPulse'][view.id];
    if (!widget) {
      widget = new OnboardingPulseWidgetModel(this.Visualizer, view, {
        ...props,
      });

      this.fixedWidgets['onboardingPulse'][view.id] = widget;
      this.widgetsContainer.appendChild(widget.getContainer());
    } else {
      widget.updateProps(props);
    }

    widget.render();
  }

  rebuildWidgets() {
    if (this.debug) {
      logger.trace('WidgetManager rebuildWidgets');
    }

    const docContainer = this.Visualizer.rootContainer || EditorDOMUtils.getContentContainer();

    const widgetTypes = Object.keys(this.dynamicWidgets);
    for (let t = 0; t < widgetTypes.length; t++) {
      const type = widgetTypes[t] as Editor.Visualizer.DynamicWidgets;

      const widgetIds = Object.keys(this.dynamicWidgets[type]);
      for (let w = 0; w < widgetIds.length; w++) {
        if (docContainer?.contains(this.dynamicWidgets[type][widgetIds[w]].getRefView())) {
          this.dynamicWidgets[type][widgetIds[w]].render();
        } else {
          this.dynamicWidgets[type][widgetIds[w]].dispose();
          delete this.dynamicWidgets[type][widgetIds[w]];
        }
      }
    }

    const fixedWidgetTypes = Object.keys(this.fixedWidgets);
    for (let t = 0; t < fixedWidgetTypes.length; t++) {
      const type = fixedWidgetTypes[t] as Editor.Visualizer.FixedWidgets;

      const widgetIds = Object.keys(this.fixedWidgets[type]);
      for (let w = 0; w < widgetIds.length; w++) {
        if (docContainer?.contains(this.fixedWidgets[type][widgetIds[w]].getRefView())) {
          this.fixedWidgets[type][widgetIds[w]].render();
        } else {
          this.fixedWidgets[type][widgetIds[w]].dispose();
          delete this.fixedWidgets[type][widgetIds[w]];
        }
      }
    }
  }

  rebuildWidgetForView(viewId: string) {
    if (this.debug) {
      logger.trace('WidgetManager rebuildWidgetForView ' + viewId);
    }

    const docContainer = this.Visualizer.rootContainer || EditorDOMUtils.getContentContainer();

    let widgetTypes = Object.keys(this.dynamicWidgets);
    for (let t = 0; t < widgetTypes.length; t++) {
      const type = widgetTypes[t] as Editor.Visualizer.DynamicWidgets;

      if (this.dynamicWidgets[type][viewId]) {
        if (docContainer?.contains(this.dynamicWidgets[type][viewId].getRefView())) {
          this.dynamicWidgets[type][viewId].render();
        } else {
          this.dynamicWidgets[type][viewId].dispose();
          delete this.dynamicWidgets[type][viewId];
        }
      }
    }

    widgetTypes = Object.keys(this.fixedWidgets);
    for (let t = 0; t < widgetTypes.length; t++) {
      const type = widgetTypes[t] as Editor.Visualizer.FixedWidgets;

      if (this.fixedWidgets[type][viewId]) {
        if (docContainer?.contains(this.fixedWidgets[type][viewId].getRefView())) {
          this.fixedWidgets[type][viewId].render();
        } else {
          this.fixedWidgets[type][viewId].dispose();
          delete this.fixedWidgets[type][viewId];
        }
      }
    }
  }

  isAnyWidgetOpenForView(viewId: string) {
    const widgetTypes = Object.keys(this.dynamicWidgets);
    for (let t = 0; t < widgetTypes.length; t++) {
      const type = widgetTypes[t] as Editor.Visualizer.DynamicWidgets;

      if (this.dynamicWidgets[type][viewId]) {
        return true;
      }
    }

    const fixedWidgetTypes = Object.keys(this.fixedWidgets);
    for (let t = 0; t < fixedWidgetTypes.length; t++) {
      const type = fixedWidgetTypes[t] as Editor.Visualizer.FixedWidgets;

      if (this.fixedWidgets[type][viewId]) {
        return true;
      }
    }
  }

  isWidgetOpen(type: Editor.Visualizer.WidgetTypes, viewId: string) {
    if (this.isFixedWidget(type)) {
      return !!this.fixedWidgets[type][viewId];
    } else {
      return !!this.dynamicWidgets[type][viewId];
    }
  }

  scheduleAddWidget<T extends Editor.Visualizer.WidgetTypes>(
    type: T,
    view: Editor.Visualizer.WidgetData[T]['view'],
    props: Editor.Visualizer.WidgetData[T]['props'],
  ) {
    if (this.timeouts[type]?.[view.id]) {
      clearTimeout(this.timeouts[type][view.id]);
      delete this.timeouts[type][view.id];
    }

    const timeouts: { [index: string]: NodeJS.Timeout } = this.timeouts[type];

    timeouts[view.id] = setTimeout(() => {
      this.addWidget(type, view, props);
    }, 50);
  }

  private canAddWidget<T extends Editor.Visualizer.WidgetTypes>(type: T, view: HTMLElement) {
    switch (type) {
      case 'resizable':
      case 'tableOptions':
      case 'imageOptions': {
        let userHasPermission = false;
        let layout: Editor.Visualizer.LayoutType | undefined = 'PAGE';

        let elementToCheck: Editor.Visualizer.BaseView = view;
        const containerNode = EditorDOMUtils.getContentContainer(view);

        if (elementToCheck.parentNode !== containerNode) {
          elementToCheck = EditorDOMUtils.findFirstLevelChildNode(
            containerNode,
            elementToCheck,
          ) as Editor.Visualizer.BaseView;
        }

        userHasPermission = this.Data.permissions.canUserPerform(elementToCheck.id, 'edit');
        layout = this.Visualizer?.getLayoutType?.();

        return (
          EditorDOMUtils.isClosestBlockNodeEditable(view) && userHasPermission && layout === 'WEB'
        );
      }

      case 'tabulations':
      case 'onboardingPulse':
        return true;

      default:
        return;
    }
  }

  addWidget<T extends Editor.Visualizer.WidgetTypes>(
    type: T,
    view: HTMLElement,
    props: Partial<Editor.Visualizer.WidgetData[T]['props']>,
  ) {
    if (this.canAddWidget(type, view)) {
      if (this.debug) {
        logger.trace('WidgetManager addWidget ' + type, props);
      }

      switch (type) {
        case 'resizable':
          return this.addResizableWidget(view, props);
        case 'tableOptions':
          return this.addTableOptionsWidget(view, props);
        case 'imageOptions':
          return this.addImageOptionsWidget(view, props);
        case 'tabulations':
          return this.addTabBarWidget(view, props);
        case 'onboardingPulse':
          return this.addOnboardingPulseWidget(view, props);
        default:
          return;
      }
    }
  }

  removeWidget(type: Editor.Visualizer.WidgetTypes, viewId: string) {
    if (this.debug) {
      logger.trace('WidgetManager removeWidget ' + type + ' ' + viewId);
    }

    if (this.isFixedWidget(type)) {
      if (this.fixedWidgets[type]?.[viewId]) {
        this.fixedWidgets[type][viewId].dispose();
        delete this.fixedWidgets[type][viewId];
      }
    } else {
      if (this.dynamicWidgets[type]?.[viewId]) {
        this.dynamicWidgets[type][viewId].dispose();
        delete this.dynamicWidgets[type][viewId];
      }
    }
  }

  removeAllWidgetsForType(type: Editor.Visualizer.WidgetTypes) {
    if (this.debug) {
      logger.trace('WidgetManager removeAllWidgetsForType ' + type);
    }
    if (this.isFixedWidget(type)) {
      const widgetIds = Object.keys(this.fixedWidgets[type]);
      for (let w = 0; w < widgetIds.length; w++) {
        if (this.fixedWidgets[type]?.[widgetIds[w]]) {
          this.fixedWidgets[type][widgetIds[w]].dispose();
          delete this.fixedWidgets[type][widgetIds[w]];
        }
      }
    } else {
      const widgetIds = Object.keys(this.dynamicWidgets[type]);
      for (let w = 0; w < widgetIds.length; w++) {
        if (this.dynamicWidgets[type]?.[widgetIds[w]]) {
          this.dynamicWidgets[type][widgetIds[w]].dispose();
          delete this.dynamicWidgets[type][widgetIds[w]];
        }
      }
    }
  }

  removeAllWidgetsForView(viewId: string) {
    if (this.debug) {
      logger.trace('WidgetManager removeAllWidgetsForView ' + viewId);
    }
    let widgetTypes = Object.keys(this.dynamicWidgets);
    for (let t = 0; t < widgetTypes.length; t++) {
      const type = widgetTypes[t] as Editor.Visualizer.DynamicWidgets;

      if (this.dynamicWidgets[type]?.[viewId]) {
        this.dynamicWidgets[type][viewId].dispose();
        delete this.dynamicWidgets[type][viewId];
      }
    }

    widgetTypes = Object.keys(this.fixedWidgets);
    for (let t = 0; t < widgetTypes.length; t++) {
      const type = widgetTypes[t] as Editor.Visualizer.FixedWidgets;

      if (this.fixedWidgets[type]?.[viewId]) {
        this.fixedWidgets[type][viewId].dispose();
        delete this.fixedWidgets[type][viewId];
      }
    }
  }

  removeAllDynamicWidgets() {
    if (this.debug) {
      logger.trace('WidgetManager removeAllDynamicWidgets');
    }
    let widgetTypes = Object.keys(this.dynamicWidgets);
    for (let t = 0; t < widgetTypes.length; t++) {
      const type = widgetTypes[t] as Editor.Visualizer.DynamicWidgets;

      const widgetIds = Object.keys(this.dynamicWidgets[type]);
      for (let w = 0; w < widgetIds.length; w++) {
        if (this.dynamicWidgets[type]?.[widgetIds[w]]) {
          this.dynamicWidgets[type][widgetIds[w]].dispose();
          delete this.dynamicWidgets[type][widgetIds[w]];
        }
      }
    }
  }

  removeAllFixedWidgets() {
    if (this.debug) {
      logger.trace('WidgetManager removeAllFixedWidgets');
    }
    let widgetTypes = Object.keys(this.fixedWidgets);
    for (let t = 0; t < widgetTypes.length; t++) {
      const type = widgetTypes[t] as Editor.Visualizer.FixedWidgets;

      const widgetIds = Object.keys(this.fixedWidgets[type]);
      for (let w = 0; w < widgetIds.length; w++) {
        if (this.fixedWidgets[type]?.[widgetIds[w]]) {
          this.fixedWidgets[type][widgetIds[w]].dispose();
          delete this.fixedWidgets[type][widgetIds[w]];
        }
      }
    }
  }

  removeAllWidgets() {
    if (this.debug) {
      logger.trace('WidgetManager removeAllWidgets');
    }
    this.removeAllDynamicWidgets();
    this.removeAllFixedWidgets();
  }
}
