import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';

import { useSelector, useDispatch } from '_common/hooks';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { AuditLog } from '_common/components';

import styles from './DocumentAuditLogModal.module.scss';

import type { AuditLogProps } from '_common/components/AuditLog/AuditLog';

const MODAL = 'DocumentAuditLogModal';

type DocumentAuditLogModalProps = Pick<AuditLogProps, 'data'>;

const DocumentAuditLogModal = ({ data }: DocumentAuditLogModalProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);

  const objectId = useSelector((state) => state.modals[MODAL].objectId);
  const isVisible = useSelector((state) => state.modals[MODAL].isVisible);

  const close = () => {
    dispatch(closeAndResetModal('DocumentAuditLogModal'));
  };

  return (
    <Modal
      open={!!isOpen}
      onClose={close}
      width="131rem"
      testId="document-audit-log"
      isVisible={isVisible}
    >
      <Modal.Header onClose={close}>
        <FormattedMessage id="DOCUMENT_AUDIT_LOG" />
      </Modal.Header>
      <Modal.Body overflow="visible" className={styles.modalRoot}>
        <AuditLog objectId={objectId} data={data} noFlex />
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="document-audit-log-modal-close-button">
          <FormattedMessage id="global.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentAuditLogModal;
