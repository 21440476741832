import { FormattedMessage } from 'react-intl';

import { useSelector } from '_common/hooks/redux';
import UsernameLabel from '_common/components/Labels/UsernameLabel/UsernameLabel';
import FormattedDate from '_common/components/FormattedDate/FormattedDate';

import styles from './ApprovalComment.module.scss';

/**
 * ApprovalComment
 */

type ApprovalCommentProps = {
  objectId: ObjectId;
};

const ApprovalComment = ({ objectId }: ApprovalCommentProps) => {
  const object = useSelector((state) => state.app.data[objectId]);
  if (!object) {
    return null;
  }
  const approval = object?.lifecycle?.approvals?.[0];
  if (!approval) {
    return null;
  }
  const author = approval?.author;

  const name = <UsernameLabel userId={author} />;

  return (
    <div>
      {object.lifecycle.approvals && object.lifecycle.approvals[0].comment ? (
        <div className={styles.comment}>
          <span>
            {object.lifecycle.approvals && object.lifecycle.approvals[0].comment}
            <br />
          </span>
        </div>
      ) : (
        <div className={styles.comment}>
          <FormattedMessage id="storage.browserSidebar.noComment" />
        </div>
      )}
      <div className={styles.header}>
        <FormattedMessage
          id="Approved on {date} by {user}"
          values={{
            user: name,
            date: <FormattedDate date={approval?.date} />,
            b: (chunks: React.ReactChild | string) => (
              <span style={{ fontWeight: 700 }}>{chunks}</span>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default ApprovalComment;
