import { useMemo, useContext, useRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Icon, Tooltip } from 'dodoc-design-system';
import cx from 'classnames';

import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector } from '_common/hooks';
import { InteractionController } from '_common/components';
import { setEditingAnnotation } from 'PDF/redux/PDFAnnotationsSlice';
import { InteractionRule } from '_common/components/OnboardingOverlay/InteractionController';
import { setPulseData } from 'App/redux/onboardingSlice';
import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { COLORS_HEX_MAP } from 'PDF/services';

import DropdownContext from './BaseAnnotationDropdownContext';
import styles from './Annotation.module.scss';

export const COLORS = ['yellow', 'green', 'red', 'blue'] as const;

type AnnotationContextMenuProps = {
  annotation: PDF.Annotation;
};

const AnnotationContextMenu = ({ annotation }: AnnotationContextMenuProps) => {
  const intl = useIntl();
  const manager = usePDFContext();
  const dispatch = useDispatch();
  const popper = useContext(DropdownContext);

  const editAnnotationItemRef = useRef<HTMLDivElement>(null);

  const inVersionHistory = useSelector((state) => state.pdf.general.versionHistory);

  const { canEditAnnotation, canDeleteAnnotation } = usePDFPermissions();

  useEffect(() => {
    if (popper?.isOpen && editAnnotationItemRef?.current) {
      dispatch(
        setPulseData({
          contextMenuTaskItemRect: {
            top: editAnnotationItemRef.current.offsetTop,
            left: editAnnotationItemRef.current.offsetLeft,
            height: editAnnotationItemRef.current.offsetHeight,
            width: editAnnotationItemRef.current.offsetWidth,
          },
        }),
      );
    } else {
      dispatch(
        setPulseData({
          contextMenuTaskItemRect: undefined,
        }),
      );
    }
  }, [popper?.isOpen]);

  const canChangeColor = useMemo(() => {
    return (
      (annotation.subtype === 'Highlight' ||
        annotation.subtype === 'Underline' ||
        annotation.subtype === 'StrikeOut') &&
      canEditAnnotation(annotation)
    );
  }, [annotation.subtype]);

  const editAnnotationColor = (newColor: (typeof COLORS)[number]) => {
    manager.editAnnotationColor(annotation.pageNumber, annotation.id, {
      ...annotation.color,
      stroke: COLORS_HEX_MAP[newColor],
    });
  };

  const items = useMemo<{ content?: JSX.Element | null; rules?: InteractionRule[] }[]>(() => {
    return [
      {
        content: canChangeColor ? (
          <Tooltip
            content={intl.formatMessage({ id: 'CANNOT_PERFORM_THIS_ACTION_IN_VERSION_HISTORY' })}
            placement="right"
            disabled={!inVersionHistory}
            testId="change-color-dropdown-submenu-tooltip"
          >
            <Dropdown.SubMenu
              size="medium"
              itemContent={intl.formatMessage({ id: 'CHANGE_COLOR' })}
              width="19rem"
              disabled={inVersionHistory}
              testId="change-color-dropdown-submenu"
            >
              {COLORS.map((color) => (
                <div
                  key={color}
                  className={cx(styles.item, {
                    [styles.isSelected]: annotation.color?.stroke === COLORS_HEX_MAP[color],
                  })}
                  onClick={() => {
                    console.log('change color of annotation with id ', annotation.id, 'to', color);
                    editAnnotationColor(color);
                  }}
                >
                  <span className={styles.selection}>
                    <Icon size={16} icon="Check" />
                  </span>
                  <span className={cx(styles.color, styles[color])} />
                  <FormattedMessage id={color.toUpperCase()} />
                </div>
              ))}
            </Dropdown.SubMenu>
          </Tooltip>
        ) : null,
      },
      {
        content: (
          <Tooltip
            content={intl.formatMessage({ id: 'CANNOT_PERFORM_THIS_ACTION_IN_VERSION_HISTORY' })}
            placement="right"
            disabled={!inVersionHistory}
            testId="add-description-dropdown-item-tooltip"
          >
            <div style={{ width: '100%' }}>
              <Dropdown.Item
                size="medium"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    setEditingAnnotation({
                      id: annotation.id,
                      isTask: annotation.subtype === 'Task',
                    }),
                  );
                }}
                testId="add-description-dropdown-item"
                disabled={inVersionHistory || !canEditAnnotation(annotation)}
                ref={editAnnotationItemRef}
              >
                <FormattedMessage id={'global.edit'} />
              </Dropdown.Item>
            </div>
          </Tooltip>
        ),
        rules: [{ interaction: 'pdf_annotation_edit' }],
      },
      {
        content: (
          <Tooltip
            content={intl.formatMessage({ id: 'CANNOT_PERFORM_THIS_ACTION_IN_VERSION_HISTORY' })}
            placement="right"
            disabled={!inVersionHistory}
            testId="delete-annotation-dropdown-item-tooltip"
          >
            <Dropdown.Item
              size="medium"
              onClick={() => {
                manager.deleteAnnotation(annotation.pageNumber, annotation.id);
                notify({
                  type: 'success',
                  title: intl.formatMessage({ id: 'COMMENT_DELETED' }),
                  message: intl.formatMessage({ id: 'THE_COMMENT_WAS_SUCCESSFULLY_DELETED' }),
                });
              }}
              testId="delete-annotation-dropdown-item"
              disabled={inVersionHistory || !canDeleteAnnotation(annotation)}
            >
              <FormattedMessage id="global.delete" />
            </Dropdown.Item>
          </Tooltip>
        ),
      },
    ];
  }, [canChangeColor, inVersionHistory]);

  if (popper) {
    return (
      //@ts-expect-error Dropdown type doesnt accept "id"
      <Dropdown {...popper.popperProps} id="AnnotationContextMenu" testId="annotation-context-menu">
        {items
          .filter((item) => item.content)
          .map((item, i) => {
            return (
              <InteractionController
                environment="dopdf"
                rules={item.rules}
                key={i}
                style={{ width: '100%' }}
              >
                {item.content}
              </InteractionController>
            );
          })}
      </Dropdown>
    );
  }

  return null;
};

export default AnnotationContextMenu;
