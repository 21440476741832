import DocumentTitle from 'react-document-title';

import { useSelector } from '_common/hooks';
import EditorPage from './pages/EditorPage/EditorPage';
import EditorModalConductor from './EditorModalConductor';
import { getDocumentObject } from './redux/EditorStatusSlice';

const EditorContainer = () => {
  const document = useSelector(getDocumentObject);
  return (
    <>
      <DocumentTitle title={document ? document.name : 'doDOC'}>
        <EditorPage />
      </DocumentTitle>
      <EditorModalConductor />
    </>
  );
};

export default EditorContainer;
