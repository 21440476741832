import { ELEMENTS } from 'Editor/services/consts';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';

export type NodeDataBuilderOptions = {
  data: Editor.Data.Node.Data;
  rows?: number;
  cells?: number;
  tableWidth?: number;
  rowHeight?: number;
  columnWidth?: number;
};

export class NodeDataBuilder {
  private data: Editor.Data.Node.Data;

  constructor(options: NodeDataBuilderOptions) {
    this.data = {
      ...options.data,
    };

    if (!this.data.id) {
      this.data.id = DOMUtils.generateRandomNodeId();
    }

    if (!this.data.properties) {
      this.data.properties = {};
    }

    if (!this.data.childNodes) {
      this.data.childNodes = [];
    }
  }

  set id(id: string) {
    this.data.id = id;
  }

  getId() {
    return this.data.id;
  }

  set parentId(parentId: string) {
    this.data.parent_id = parentId;
  }

  set elementType(type: Editor.Elements.ElementTypesType) {
    this.data.type = type;
  }

  set properties(properties: Editor.Data.Node.GenericProperties) {
    this.data.properties = properties;
  }

  getProperties() {
    return this.data.properties;
  }

  set childNodes(childNodes: Editor.Data.Node.Data[]) {
    this.data.childNodes = childNodes;
  }

  getChild(index: number) {
    return this.data.childNodes?.[index];
  }

  addKeyValue(key: string, value: any) {
    if (value === 'true') {
      this.data[key] = true;
    } else if (value === 'false') {
      this.data[key] = false;
    } else {
      this.data[key] = value;
    }
  }

  addProperty(key: string, value: any) {
    if (!this.data.properties) {
      this.data.properties = {};
    }

    if (value === 'true') {
      this.data.properties[key] = true;
    } else if (value === 'false') {
      this.data.properties[key] = false;
    } else {
      this.data.properties[key] = value;
    }
  }

  addChildNode(childNode?: Editor.Data.Node.Data) {
    if (!this.data.childNodes) {
      this.data.childNodes = [];
    }

    if (childNode) {
      this.data.childNodes.push(childNode);
    }
  }

  normalizeChildNodes() {
    if (this.data.childNodes?.length) {
      const queue = [
        {
          id: this.data.id,
          childNodes: this.data.childNodes,
        },
      ];

      let item;
      while ((item = queue.shift())) {
        for (let i = 0; i < item.childNodes.length; i++) {
          const child = item.childNodes[i];
          if (child.type !== ELEMENTS.Text.ELEMENT_TYPE) {
            child.parent_id = item.id;

            if (!child.id) {
              child.id = DOMUtils.generateRandomNodeId();
            }

            if (child.childNodes?.length) {
              queue.push({
                id: child.id,
                childNodes: child.childNodes,
              });
            }
          }
        }
      }
    }
  }

  getNodeData(): Editor.Data.Node.Data | undefined {
    if (this.data.id && this.data.parent_id && this.data.type) {
      this.normalizeChildNodes();
      return this.data;
    }

    return undefined;
  }

  private static buildTableRow(options: NodeDataBuilderOptions) {
    const { cells = 3, rowHeight = 48, columnWidth = 36 } = options;

    options.data.type = ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.ELEMENT_TYPE;

    const rowBuilder = new NodeDataBuilder(options);

    rowBuilder.addProperty('rh', DOMUtils.convertUnitTo(rowHeight, 'px', 'pt', 3));

    for (let i = 0; i < cells; i++) {
      const cellBuilder = new NodeDataBuilder({
        data: {
          type: ELEMENTS.TableCellElement.ELEMENT_TYPE,
          parent_id: rowBuilder.getId(),
        },
      });

      cellBuilder.addProperty('w', {
        t: 'abs',
        v: columnWidth,
      });

      rowBuilder.addChildNode(cellBuilder.getNodeData());
    }

    return rowBuilder.getNodeData();
  }

  static buildTable(options: NodeDataBuilderOptions) {
    const { rows = 3, cells = 3, tableWidth = 516, rowHeight = 48 } = options;

    options.data.type = ELEMENTS.TableElement.ELEMENT_TYPE;

    const tableBuilder = new NodeDataBuilder(options);

    const columnWidth = tableWidth / cells;

    if (options.data.properties?.w == null) {
      tableBuilder.addProperty('w', {
        t: 'auto',
        v: 0,
      });
    }

    // cell borders
    if (options.data.properties?.cb == null) {
      tableBuilder.addProperty('cb', {
        t: {
          w: 0.75,
          s: 's',
          c: '000000',
        },
        b: {
          w: 0.75,
          s: 's',
          c: '000000',
        },
        l: {
          w: 0.75,
          s: 's',
          c: '000000',
        },
        r: {
          w: 0.75,
          s: 's',
          c: '000000',
        },
      });
    }

    if (!options.data.childNodes) {
      const bodyBuilder = new NodeDataBuilder({
        data: {
          type: ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.ELEMENT_TYPE,
          parent_id: tableBuilder.getId(),
        },
      });

      for (let i = 0; i < rows; i++) {
        const rowData = NodeDataBuilder.buildTableRow({
          data: {
            type: ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.ELEMENT_TYPE,
            parent_id: bodyBuilder.getId(),
          },
          cells,
          rowHeight,
          columnWidth,
        });

        bodyBuilder.addChildNode(rowData);
      }

      tableBuilder.addChildNode(bodyBuilder.getNodeData());
    }

    return tableBuilder.getNodeData();
  }

  static buildParagraph(options: NodeDataBuilderOptions) {
    if (!options.data.properties) {
      options.data.properties = {};
    }

    if (options.data.properties.s == null) {
      options.data.properties.s = ELEMENTS.ParagraphElement.BASE_STYLES.PARAGRAPH;
    }

    options.data.type = ELEMENTS.ParagraphElement.ELEMENT_TYPE;

    return this.buildDefault(options);
  }

  private static buildDefault(options: NodeDataBuilderOptions) {
    return new NodeDataBuilder(options).getNodeData();
  }

  static buildNodeData(options: NodeDataBuilderOptions) {
    switch (options.data.type) {
      case ELEMENTS.TableElement.ELEMENT_TYPE:
        return NodeDataBuilder.buildTable(options);
      case ELEMENTS.ParagraphElement.ELEMENT_TYPE:
        return NodeDataBuilder.buildParagraph(options);
      default:
        return NodeDataBuilder.buildDefault(options);
    }
  }
}
