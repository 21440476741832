import { ReactNode } from 'react';

import styles from './TopBarWrapper.module.scss';

type TopBarWrapperProps = {
  children: ReactNode;
};

const TopBarWrapper = ({ children }: TopBarWrapperProps) => (
  <div id="top-bar-wrapper" className={styles.root}>
    {children}
  </div>
);

export default TopBarWrapper;
