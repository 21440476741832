export const getOnboardingProgress = (totalPhases: number, phase: number, phasePercent: number) => {
  const progressBarWidth = 344;
  const spacingWidth = 4;

  //Onboarding split progress bars are not all the same size, so static values are used instead
  let firstPhaseWidth;
  let otherPhasesWidth;
  switch (totalPhases) {
    case 4:
      firstPhaseWidth = 86;
      otherPhasesWidth = 82;
      break;
    case 3:
      firstPhaseWidth = 115;
      otherPhasesWidth = 110;
      break;
    default: //2
      firstPhaseWidth = 172;
      otherPhasesWidth = 168;
      break;
  }

  const previousPhaseFiller =
    phase > 1
      ? firstPhaseWidth + spacingWidth + (phase - 2) * (otherPhasesWidth + spacingWidth)
      : 0;
  const currentPhaseFiller = Math.max(
    ((phase === 1 ? firstPhaseWidth : otherPhasesWidth) * phasePercent) / 100,
    8,
  );

  return ((previousPhaseFiller + currentPhaseFiller) * 100) / progressBarWidth;
};
