import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';
import { setEditingAnnotation } from 'PDF/redux/PDFAnnotationsSlice';
import { forwardRef, MouseEvent, Ref } from 'react';
import { InteractionController } from '_common/components';

type FooterProps = { onSave: () => void };

const Footer = forwardRef(({ onSave }: FooterProps, ctaRef: Ref<HTMLButtonElement>) => {
  const dispatch = useDispatch();

  const handleCloseEdition = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setEditingAnnotation({ id: null, isTask: false }));
  };

  return (
    <>
      <Button
        variant="link"
        size="small"
        margin="0 1rem 0 0"
        onClick={handleCloseEdition}
        testId="annotation-cancel-button"
      >
        <FormattedMessage id="global.cancel" />
      </Button>

      <InteractionController environment="dopdf" rules={[{ interaction: 'pdf_annotation_save' }]}>
        <Button
          variant="primary"
          size="small"
          onClick={onSave}
          testId="annotation-save-button"
          ref={ctaRef}
        >
          <FormattedMessage id="global.save" />
        </Button>
      </InteractionController>
    </>
  );
});

export default Footer;
