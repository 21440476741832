import { MouseEventHandler, useLayoutEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Toggle, Card, Dropdown, usePopper } from 'dodoc-design-system';

import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { useIsNodeClamped } from '_common/hooks';
import { DEFAULT_CARD_STYLES_PANEL } from 'PDF/redux/PDFAnnotationsSlice';

import { UserPresentation, RichTextEditor } from '_common/components';
import { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';
import LikeToggle from 'PDF/components/Cards/LikeToggle/LikeToggle';

import styles from './ViewReplyCard.module.scss';

type ViewReplyCardProps = {
  reply: PDF.Annotation.Reply;
  pageNumber: PDF.Annotation['pageNumber'];
  annotationId: PDF.Annotation['id'];
  onStartEdit: () => void;
  /** ID to be used in unit or automated tests.
   * Will result in 4 different attributes:
   * * ${testId}-view
   * * ${testId}-moreOptions
   * * ${testId}-moreOptions-edit
   * * ${testId}-moreOptions-delete
   */
  testId: string;
};

const ViewReplyCard = ({
  reply,
  pageNumber,
  annotationId,
  onStartEdit,
  testId,
}: ViewReplyCardProps) => {
  const pdfManager = usePDFContext();

  const { canEditAnnotationReply, canDeleteAnnotationReply } = usePDFPermissions();

  const { referenceProps, popperProps } = usePopper({ closeOnReferenceHidden: true });

  const replyRef = useRef<RichTextEditorHandler>(null);
  const isClamped = useIsNodeClamped({
    ref: replyRef.current?.editorRef,
    clamp: 3,
    dependencies: [reply.content.content, replyRef.current],
  });

  const [readMore, setReadMore] = useState(true);

  useLayoutEffect(() => {
    if (!isClamped) {
      setReadMore(false);
    }
  }, [isClamped]);

  const handleToggleReadMore: MouseEventHandler<HTMLDivElement> = (e) => {
    /**
     * Stop propagation to avoid card selection
     * Card selection will invoke scrollIntoView and app might pause to load new content
     */
    e.stopPropagation();

    setReadMore(!readMore);
  };

  const handleDeleteReply = async () => {
    try {
      await pdfManager.deleteReplyAnnotation(pageNumber, annotationId, reply.id);
    } catch (e) {
      throw e;
    }
  };

  return (
    <div className={styles.replyCard} data-testid={`${testId}-view`}>
      <Card.Header size="medium">
        <div className={styles.header}>
          <UserPresentation
            userId={reply.authorId}
            fixedName={!reply.authorId ? reply.title : undefined}
            //@ts-expect-error PDF.Annotation.Reply.creationDate is typed as Date but is string
            creationDate={reply.creationDate}
          />
          <Toggle
            size="medium"
            variant="link"
            margin="0 0 0 auto"
            icon={popperProps.isOpen ? 'PDFOptionsBlue' : 'PDFOptionsGrey'}
            isToggled={popperProps.isOpen}
            testId={`${testId}-moreOptions`}
            {...referenceProps}
          />
          <Dropdown {...popperProps} testId={`${testId}-options-dropdown`}>
            <Dropdown.Item
              onClick={onStartEdit}
              disabled={!canEditAnnotationReply(reply)}
              testId={`${testId}-moreOptions-edit`}
            >
              <FormattedMessage id="global.edit" />
            </Dropdown.Item>
            <Dropdown.Item
              onClick={handleDeleteReply}
              disabled={!canDeleteAnnotationReply(reply)}
              testId={`${testId}-moreOptions-delete`}
            >
              <FormattedMessage id="global.delete" />
            </Dropdown.Item>
          </Dropdown>
        </div>
      </Card.Header>
      <Card.Body>
        <RichTextEditor
          readOnly
          ref={replyRef}
          initialValue={JSON.stringify(reply.content.content)}
          expanded={readMore}
          overwrittenStyles={DEFAULT_CARD_STYLES_PANEL}
          testId={testId}
        />

        {isClamped && (
          <div className={styles.readMore} onClick={handleToggleReadMore}>
            <FormattedMessage
              id={readMore ? 'editor.comments.readLess' : 'editor.comments.readMore'}
            />
          </div>
        )}
      </Card.Body>
      <Card.Footer size="large">
        <div className={styles.footer}>
          <LikeToggle
            votes={reply.votes ?? []}
            pageNumber={pageNumber}
            annotationId={annotationId}
            replyId={reply.id}
            target="reply"
            testId={testId}
          />
        </div>
      </Card.Footer>
    </div>
  );
};

export default ViewReplyCard;
