//@ts-expect-error needs mixins refactor
import { Mixin } from 'mixwith';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { ErrorCannotMergeCells } from 'Editor/services/_CustomErrors';
import { Path } from 'sharedb';
import { NodeDataBuilder, NodeModel } from 'Editor/services/DataManager';
import { RealtimeOpsBuilder } from '_common/services/Realtime';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { TableCellElement, TableElement } from 'Editor/services/VisualizerManager';
import { ELEMENTS } from 'Editor/services/consts';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';

const DEFAULT_ROW_HEIGHT = 36;

export default Mixin(
  (superclass: any) =>
    class TableOperations extends superclass {
      destroy() {
        super.destroy();
      }

      getColumnWidths(tableData: Editor.Data.Node.TableData) {
        const columnWidths: Editor.Data.Node.TableWidth[] = [];

        const rowsData = tableData.childNodes?.[0].childNodes;

        if (rowsData) {
          for (let r = 0; r < rowsData.length; r++) {
            const row = rowsData[r];

            if (row.childNodes) {
              for (let c = 0; c < row.childNodes.length; c++) {
                const cell = row.childNodes[c];

                let cellWidth: Editor.Data.Node.TableWidth;
                if (cell.properties?.w) {
                  cellWidth = {
                    t: cell.properties.w.t,
                    v: cell.properties.w.v,
                  };
                } else {
                  cellWidth = {
                    t: 'auto',
                    v: 0,
                  };
                }

                if (columnWidths[c] != null) {
                  if (
                    (columnWidths[c].t !== 'abs' && cellWidth.t === 'abs') ||
                    (columnWidths[c].t === cellWidth.t && columnWidths[c].v < cellWidth.v)
                  ) {
                    columnWidths[c].t = cellWidth.t;
                    columnWidths[c].v = cellWidth.v;
                  }
                } else {
                  columnWidths.push(cellWidth);
                }
              }
            }
          }
        }

        return columnWidths;
      }

      /**
       * calculates the real column weight in the table from 0 to 1;
       *
       * @param level0Model
       * @param tableData
       * @param columnIndex
       * @returns
       */
      findColumnsWeights(
        level0Model: NodeModel,
        tableData: Editor.Data.Node.TableData,
        columnIndex: number[] = [],
      ) {
        const columnsWeights: { [index: number]: number } = {};

        const pageWidth = this.dataManager.sections.getPageWidthForBlockId(level0Model.id);
        const tableWidth = tableData.properties?.w;
        const columnWidths: Editor.Data.Node.TableWidth[] = this.getColumnWidths(tableData);

        let tableWidthAbs = 0;
        if (tableWidth?.t === 'abs') {
          tableWidthAbs = tableWidth.v;
        } else if (tableWidth?.t === 'pct') {
          tableWidthAbs = tableWidth.v * pageWidth;
        }

        if (tableWidthAbs > 0) {
          const colSum = columnWidths.reduce((sum, col) => {
            if (col.t === 'pct') {
              sum = sum + tableWidthAbs * col.v;
            } else if (col.t === 'abs') {
              sum = sum + col.v;
            } else {
              sum = sum + tableWidthAbs / columnWidths.length;
            }
            return sum;
          }, 0);

          for (let i = 0; i < columnIndex.length; i++) {
            const ci = columnIndex[i];

            let columnIndexAbs = 0;
            if (columnWidths[ci]?.t === 'abs') {
              columnIndexAbs = columnWidths[ci].v;
            } else if (columnWidths[ci]?.t === 'pct') {
              columnIndexAbs = columnWidths[ci].v * pageWidth;
            } else {
              columnIndexAbs = tableWidthAbs / columnWidths.length;
            }

            columnsWeights[ci] = (columnIndexAbs * 1) / colSum;
          }
        }

        return columnsWeights;
      }

      deleteCellsOperation(
        actionContext: ActionContext,
        level0Node: HTMLElement,
        table: TableElement,
        selectedCells: TableCellElement[] = [],
        { shiftCells }: any,
      ) {
        if (table != null && selectedCells.length > 0) {
          if (shiftCells !== 'SHIFT_LEFT' && shiftCells !== 'SHIFT_UP') {
            shiftCells = 'SHIFT_LEFT';
          }

          const headCells: TableCellElement[] = [];

          const cellIndexes: number[] = [];
          const rowIndexes: number[] = [];

          const cellsToDelete: TableCellElement[] = [];

          // get indexes and head cells
          for (let i = 0; i < selectedCells.length; i++) {
            let selectedCell = selectedCells[i];
            if (selectedCell.tagName !== ELEMENTS.TableCellElement.TAG) {
              selectedCell = DOMUtils.closest(
                selectedCell,
                ELEMENTS.TableCellElement.TAG,
              ) as TableCellElement;
            }

            if (
              (selectedCell.colSpan > 1 || selectedCell.rowSpan > 1) &&
              !headCells.includes(selectedCell)
            ) {
              headCells.push(selectedCell);

              for (let r = 0; r < selectedCell.rowSpan; r++) {
                const rowIndex =
                  (selectedCell.parentNode as HTMLTableRowElement).sectionRowIndex + r;

                for (let c = 0; c < selectedCell.colSpan; c++) {
                  const cellIndex = selectedCell.cellIndex + c;

                  const cell = selectedCell.parentNode?.parentNode?.childNodes[rowIndex].childNodes[
                    cellIndex
                  ] as TableCellElement;

                  if (!cellIndexes.includes(cellIndex) && cell != null) {
                    cellIndexes.push(cellIndex);
                  }
                  if (!rowIndexes.includes(rowIndex) && cell != null) {
                    rowIndexes.push(rowIndex);
                  }
                  if (cell != null && !cellsToDelete.includes(cell)) {
                    cellsToDelete.push(cell);
                  }
                }
              }
            } else {
              if (!cellIndexes.includes(selectedCell.cellIndex)) {
                cellIndexes.push(selectedCell.cellIndex);
              }

              if (!cellsToDelete.includes(selectedCell)) {
                cellsToDelete.push(selectedCell);
              }

              const rowIndex = (selectedCell.parentNode as HTMLTableRowElement).sectionRowIndex;

              if (!rowIndexes.includes(rowIndex)) {
                rowIndexes.push(rowIndex);
              }
            }
          }

          cellIndexes.sort((a, b) => {
            return a - b;
          });

          rowIndexes.sort((a, b) => {
            return a - b;
          });

          const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

          let tableData;
          if (level0Node === table) {
            tableData = level0Model.get();
          } else {
            tableData = level0Model.getChildDataById(table.id);
          }

          const tBody: HTMLTableSectionElement = table.tBodies[0] as HTMLTableSectionElement;

          if (table.cells.length === cellsToDelete.length) {
            // remove table
            this.removeBlockNodeOperation(actionContext, table.id);
            this.visualizerManager?.getWidgetsManager()?.removeAllWidgetsForView(table.id);
          } else {
            // build ops to remove cells
            const ops = [];

            const tablePath = level0Model.findPath(level0Model.KEYS.ID, table.id);
            tablePath.pop(); // remove path id

            const rows = tBody.rows;

            let rowOffsets: any = {};

            const columnCellsDeleted: { [index: number]: number } = {};

            for (let i = 0; i < cellIndexes.length; i++) {
              const cellIndex = cellIndexes[i];

              for (let j = 0; j < rows.length; j++) {
                const cell = rows[j]?.cells[cellIndex] as TableCellElement;

                if (!columnCellsDeleted[i]) {
                  columnCellsDeleted[i] = 0;
                }

                if (cell) {
                  if (cellsToDelete.includes(cell) && rowIndexes.includes(j)) {
                    columnCellsDeleted[i] += 1;

                    const rowIndex = j;
                    if (rowOffsets[rowIndex] == null) {
                      rowOffsets[rowIndex] = 0;
                    }

                    const cellData = level0Model.getChildDataById(cell.id);

                    // remove cell
                    const cellPath = level0Model.findPath(level0Model.KEYS.ID, cell.id);
                    cellPath.pop(); // remove path id

                    // adjust cell path based on delete offset
                    // i works has cell offset
                    cellPath[cellPath.length - 1] =
                      +cellPath[cellPath.length - 1] - rowOffsets[rowIndex];

                    ops.push(RealtimeOpsBuilder.listDelete(cellData, cellPath));
                    rowOffsets[rowIndex] += 1;
                  }
                } else {
                  columnCellsDeleted[i] += 1;
                }
              }
            }

            // check empty rows
            let deletedRows = 0;
            for (let j = 0; j < rows.length; j++) {
              if (rows[j] && rows[j].cells.length === rowOffsets[j]) {
                // remove row

                const rowData = level0Model.getChildDataById(rows[j].id);
                rowData.childNodes = []; // to avoid wrong undo operation

                const rowPath = level0Model.findPath(level0Model.KEYS.ID, rows[j].id);
                rowPath.pop(); // remove path id

                // adjust row path based on delete offset
                rowPath[rowPath.length - 1] = +rowPath[rowPath.length - 1] - deletedRows;

                ops.push(RealtimeOpsBuilder.listDelete(rowData, rowPath));

                deletedRows += 1;
              }
            }

            // SHIFT UP option
            if (
              rows.length !== rowIndexes.length &&
              shiftCells === 'SHIFT_UP' &&
              cellsToDelete.length === rowIndexes.length * cellIndexes.length
            ) {
              for (
                let j = rowIndexes[rowIndexes.length - 1] + 1, offset = 0;
                j < rows.length;
                j++, offset++
              ) {
                for (let i = 0; i < cellIndexes.length; i++) {
                  const cellIndex = cellIndexes[i];

                  const row = rows[j];
                  const cell = row?.cells[cellIndex];

                  if (cell) {
                    const cellData = level0Model.getChildDataById(cell.id);

                    // remove cell
                    const cellPath = level0Model.findPath(level0Model.KEYS.ID, cell.id);
                    cellPath.pop(); // remove path id

                    // adjust cell path based on delete offset
                    // i works has cell offset
                    cellPath[cellPath.length - 1] = +cellPath[cellPath.length - 1] - i;

                    ops.push(RealtimeOpsBuilder.listDelete(cellData, cellPath));

                    ops.push(
                      RealtimeOpsBuilder.listInsert(cellData, [
                        ...tablePath,
                        level0Model.KEYS.CHILDNODES,
                        0, // tbody
                        level0Model.KEYS.CHILDNODES,
                        rowIndexes[0] + offset, // row
                        level0Model.KEYS.CHILDNODES,
                        cellIndex, // cell
                      ]),
                    );
                  }
                }
              }
            }

            // check deleted columns and update table width
            const deletedIndexes: number[] = [];
            const columnIndexes = Object.keys(columnCellsDeleted);
            for (let i = 0; i < columnIndexes.length; i++) {
              const index = +columnIndexes[i];

              if (columnCellsDeleted[index] === rows.length) {
                deletedIndexes.push(index);
              }
            }

            if (deletedIndexes.length) {
              const columnsWeights = this.findColumnsWeights(
                level0Model,
                tableData,
                deletedIndexes,
              );
              const tableWidth = tableData.properties?.w;

              let op;
              const indexes = Object.keys(columnsWeights);
              if (
                tableWidth &&
                (tableWidth.t === 'abs' || tableWidth.t === 'pct') &&
                level0Node === table &&
                indexes.length > 0
              ) {
                const newTableWidth = {
                  t: tableWidth.t,
                  v: tableWidth.v,
                };

                for (let i = 0; i < indexes.length; i++) {
                  newTableWidth.v = +(
                    newTableWidth.v -
                    newTableWidth.v * columnsWeights[+indexes[i]]
                  ).toFixed(3);
                }

                op = this.getObjectOperationforPathValue(tableWidth, newTableWidth, [
                  ...tablePath,
                  level0Model.KEYS.PROPERTIES,
                  'w',
                ]);
              } else if (!tableWidth || tableWidth.t !== 'auto') {
                op = this.getObjectOperationforPathValue(tableWidth, { t: 'auto', v: 0 }, [
                  ...tablePath,
                  level0Model.KEYS.PROPERTIES,
                  'w',
                ]);
              }

              if (op) {
                ops.push(op);
              }
            }

            // apply ops
            if (ops.length > 0) {
              actionContext.jsonChanges = true;
              level0Model.apply(ops, { source: 'LOCAL_RENDER' });
            }
          }
        }
      }

      deleteRowsOperation(
        actionContext: ActionContext,
        level0Node: HTMLElement,
        table: TableElement,
        selectedCells: TableCellElement[] = [],
      ) {
        if (table != null && selectedCells.length > 0) {
          const rowIndexes: number[] = [];

          for (let i = 0; i < selectedCells.length; i++) {
            let cell = selectedCells[i];
            if (cell.tagName !== ELEMENTS.TableCellElement.TAG) {
              cell = DOMUtils.closest(
                selectedCells[i],
                ELEMENTS.TableCellElement.TAG,
              ) as TableCellElement;
            }

            const index = +(cell.parentNode as HTMLTableRowElement).sectionRowIndex;

            if (!rowIndexes.includes(index)) {
              rowIndexes.push(index);
            }
          }

          rowIndexes.sort((a, b) => {
            return a - b;
          });

          const tbody = table.tBodies[0] as HTMLTableSectionElement;

          if (tbody.rows.length > 0 && tbody.rows.length === rowIndexes.length) {
            this.removeBlockNodeOperation(actionContext, table.id);
            this.visualizerManager?.getWidgetsManager()?.removeAllWidgetsForView(table.id);
          } else {
            const ops = [];

            const headCellToUpdate: any = {};
            const headCellToRemove: string[] = [];

            const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

            const tablePath = level0Model.findPath(level0Model.KEYS.ID, table.id);
            tablePath.pop(); // remove path id

            const rows = tbody.rows;

            for (let i = 0; i < rowIndexes.length; i++) {
              const row = rows[rowIndexes[i]];

              // head cells checked per row
              const headCellChecked: string[] = [];

              // check for merged cells
              const length = row.cells.length;
              for (let j = 0; j < length; j++) {
                const cell = row.cells[j];

                if (cell) {
                  if (cell.hasAttribute('head-id')) {
                    const headCell = table.querySelector(
                      `*[id="${cell.getAttribute('head-id')}"]`,
                    ) as TableCellElement;

                    if (
                      headCell &&
                      (headCell.parentNode as HTMLTableRowElement).sectionRowIndex !==
                        (cell.parentNode as HTMLTableRowElement).sectionRowIndex &&
                      !headCellChecked.includes(headCell.id)
                    ) {
                      // add headcell to update
                      if (!headCellToRemove.includes(headCell.id)) {
                        if (headCellToUpdate[headCell.id] != null) {
                          headCellToUpdate[headCell.id].properties.rs -= 1;
                        } else {
                          headCellToUpdate[headCell.id] = {
                            properties: { rs: headCell.rowSpan - 1 },
                          };
                        }
                      }

                      headCellChecked.push(headCell.id);
                    }
                  } else if (cell.rowSpan > 1) {
                    // add headcell to remove
                    headCellToRemove.push(cell.id);

                    if (headCellToUpdate[cell.id]) {
                      delete headCellToUpdate[cell.id];
                    }
                  }
                }
              }

              // remove row
              const rowPath = level0Model.findPath(level0Model.KEYS.ID, row.id);
              rowPath.pop(); // remove path id

              const rowData = level0Model.get(rowPath);

              // adjust row path based on delete offset
              // i works has row offset
              rowPath[rowPath.length - 1] = +rowPath[rowPath.length - 1] - i;

              ops.push(RealtimeOpsBuilder.listDelete(rowData, rowPath));
            }

            // build ops for removed headcells
            const removeLength = headCellToRemove.length;
            for (let i = 0; i < removeLength; i++) {
              const headCell = table.querySelector(
                `*[id="${headCellToRemove[i]}"]`,
              ) as TableCellElement;

              const rowToUpdate = rows[rowIndexes[rowIndexes.length - 1] + 1];

              if (rowToUpdate != null) {
                const newHeadCellPath = level0Model.findPath(level0Model.KEYS.ID, rowToUpdate.id);
                newHeadCellPath.pop(); // remove path id
                newHeadCellPath.pop(); // remove row index
                newHeadCellPath.push(rowIndexes[0]); // add row index with offset
                newHeadCellPath.push(level0Model.KEYS.CHILDNODES); // add path to cells
                newHeadCellPath.push(headCell.cellIndex); // add cell index
                newHeadCellPath.push(level0Model.KEYS.PROPERTIES); // add path to properties

                const newHeadCellData = level0Model.getChildDataById(
                  (rowToUpdate.childNodes[headCell.cellIndex] as TableCellElement).id,
                );

                const newRowSpanValue =
                  headCell.rowSpan -
                  (rowToUpdate.sectionRowIndex -
                    (headCell.parentNode as HTMLTableRowElement).sectionRowIndex);

                // if new row span value is equal or inferior to 0,
                // means that all rows merged were deleted so no cells need update
                if (newRowSpanValue > 0) {
                  // remove display false
                  ops.push(
                    RealtimeOpsBuilder.objectDelete(
                      newHeadCellData[level0Model.KEYS.PROPERTIES]?.d,
                      [...newHeadCellPath, 'd'],
                    ),
                  );

                  // remove head-id
                  ops.push(
                    RealtimeOpsBuilder.objectDelete(
                      newHeadCellData[level0Model.KEYS.PROPERTIES]?.['head-id'],
                      [...newHeadCellPath, 'head-id'],
                    ),
                  );

                  // add new row span
                  if (newRowSpanValue > 1) {
                    ops.push(
                      RealtimeOpsBuilder.objectInsert(newRowSpanValue, [...newHeadCellPath, 'rs']),
                    );
                  }

                  // copy colspan
                  const colspan = headCell.colSpan;
                  if (colspan > 1) {
                    ops.push(RealtimeOpsBuilder.objectInsert(colspan, [...newHeadCellPath, 'cs']));
                  }

                  // update headIds value
                  for (let x = 0; x < newRowSpanValue; x++) {
                    for (let y = 0; y < colspan; y++) {
                      if (x !== 0 || y !== 0) {
                        ops.push(
                          RealtimeOpsBuilder.objectReplace(
                            headCellToRemove[i],
                            newHeadCellData.id,
                            [
                              ...tablePath,
                              level0Model.KEYS.CHILDNODES,
                              0,
                              level0Model.KEYS.CHILDNODES,
                              rowIndexes[0] + x, // path offset
                              level0Model.KEYS.CHILDNODES,
                              headCell.cellIndex + y,
                              level0Model.KEYS.PROPERTIES,
                              'head-id',
                            ],
                          ),
                        );
                      }
                    }
                  }

                  // if new head cell is empty, add an empty paragraph
                  if (newHeadCellData.childNodes && newHeadCellData.childNodes.length === 0) {
                    const paragraph = NodeDataBuilder.buildNodeData({
                      data: {
                        type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
                        parent_id: newHeadCellData.id,
                      },
                    });

                    newHeadCellPath.pop(); // remove properties path
                    newHeadCellPath.push(level0Model.KEYS.CHILDNODES); // add child nodes path
                    newHeadCellPath.push(0); // add base index

                    ops.push(RealtimeOpsBuilder.listInsert(paragraph, newHeadCellPath));
                  }
                }
              }
            }

            // build ops for updated headcells
            const keyIds = Object.keys(headCellToUpdate);
            for (let i = 0; i < keyIds.length; i++) {
              const updatedData = headCellToUpdate[keyIds[i]];

              const headCellPropPath = level0Model.findPath(level0Model.KEYS.ID, keyIds[i]);
              headCellPropPath.pop(); // remove path id
              headCellPropPath.push(level0Model.KEYS.PROPERTIES); // path to properties
              headCellPropPath.push('rs');

              if (updatedData.properties.rs > 0) {
                const op = level0Model.buildOp(headCellPropPath, updatedData.properties.rs);
                if (op != null) {
                  ops.push(op);
                }
              } else {
                const op = level0Model.buildOp(headCellPropPath, null);
                if (op != null) {
                  ops.push(op);
                }
              }
            }

            if (ops.length > 0) {
              actionContext.jsonChanges = true;
              level0Model.apply(ops, { source: 'LOCAL_RENDER' });
            }
          }
        }
      }

      deleteColumnsOperation(
        actionContext: ActionContext,
        level0Node: HTMLElement,
        table: TableElement,
        selectedCells: TableCellElement[] = [],
      ) {
        if (table != null && selectedCells.length > 0) {
          const cellIndexes: number[] = [];

          for (let i = 0; i < selectedCells.length; i++) {
            let cell = selectedCells[i];
            if (cell.tagName !== ELEMENTS.TableCellElement.TAG) {
              cell = DOMUtils.closest(
                selectedCells[i],
                ELEMENTS.TableCellElement.TAG,
              ) as TableCellElement;
            }
            if (!cellIndexes.includes(cell.cellIndex)) {
              cellIndexes.push(cell.cellIndex);
            }
          }

          cellIndexes.sort((a, b) => {
            return a - b;
          });

          const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

          let tableData;
          if (level0Node === table) {
            tableData = level0Model.get();
          } else {
            tableData = level0Model.getChildDataById(table.id);
          }

          const cellWidths = this.getColumnWidths(tableData);

          if (cellWidths.length === cellIndexes.length) {
            // remove table
            this.removeBlockNodeOperation(actionContext, table.id);
            this.visualizerManager?.getWidgetsManager()?.removeAllWidgetsForView(table.id);
          } else {
            // build ops to remove columns
            const ops = [];

            const headCellToUpdate: any = {};
            const headCellToRemove: string[] = [];

            const tablePath = level0Model.findPathToChild(table.id);

            const tbody = table.tBodies[0] as HTMLTableSectionElement;
            const rows = tbody.rows;

            for (let i = 0; i < cellIndexes.length; i++) {
              const cellIndex = cellIndexes[i];

              // head cells checked per column
              const headCellChecked: string[] = [];

              for (let j = 0; j < rows.length; j++) {
                const cell = rows[j].cells[cellIndex];

                if (cell) {
                  if (cell.hasAttribute('head-id')) {
                    const headCell = table.querySelector(
                      `*[id="${cell.getAttribute('head-id')}"]`,
                    ) as TableCellElement;
                    if (
                      headCell &&
                      headCell.cellIndex !== cell.cellIndex &&
                      !headCellChecked.includes(headCell.id)
                    ) {
                      // add headcell to update
                      if (!headCellToRemove.includes(headCell.id)) {
                        if (headCellToUpdate[headCell.id] != null) {
                          headCellToUpdate[headCell.id].properties.cs -= 1;
                        } else {
                          headCellToUpdate[headCell.id] = {
                            properties: { cs: headCell.colSpan - 1 },
                          };
                        }
                      }

                      headCellChecked.push(headCell.id);
                    }
                  } else if (cell.colSpan > 1) {
                    // add headcell to remove
                    headCellToRemove.push(cell.id);

                    if (headCellToUpdate[cell.id]) {
                      delete headCellToUpdate[cell.id];
                    }
                  }

                  const cellData = level0Model.getChildDataById(cell.id);

                  // remove cell
                  const cellPath = level0Model.findPath(level0Model.KEYS.ID, cell.id);
                  cellPath.pop(); // remove path id

                  // adjust cell path based on delete offset
                  // i works has cell offset
                  cellPath[cellPath.length - 1] = +cellPath[cellPath.length - 1] - i;

                  ops.push(RealtimeOpsBuilder.listDelete(cellData, cellPath));
                }
              }
            }

            // build ops for removed headcells
            const removeLength = headCellToRemove.length;
            for (let i = 0; i < removeLength; i++) {
              const headCell = table.querySelector(
                `*[id="${headCellToRemove[i]}"]`,
              ) as TableCellElement;

              const cellToUpdate =
                rows[(headCell.parentNode as HTMLTableRowElement)?.sectionRowIndex].cells[
                  cellIndexes[cellIndexes.length - 1] + 1
                ];

              if (cellToUpdate != null) {
                const newHeadCellPath = level0Model.findPath(level0Model.KEYS.ID, cellToUpdate.id);
                newHeadCellPath.pop(); // remove path id
                newHeadCellPath.pop(); // remove cell index

                newHeadCellPath.push(cellIndexes[0]); // add cell index with offset
                newHeadCellPath.push(level0Model.KEYS.PROPERTIES); // add path to properties

                const newHeadCellData = level0Model.getChildDataById(cellToUpdate.id);

                const newColSpanValue =
                  headCell.colSpan - (cellToUpdate.cellIndex - headCell.cellIndex);

                // if new row span value is equal or inferior to 0,
                // means that all rows merged were deleted so no cells need update
                if (newColSpanValue > 0) {
                  // remove display false
                  ops.push(
                    RealtimeOpsBuilder.objectDelete(
                      newHeadCellData[level0Model.KEYS.PROPERTIES]?.d,
                      [...newHeadCellPath, 'd'],
                    ),
                  );

                  // remove head-id
                  ops.push(
                    RealtimeOpsBuilder.objectDelete(
                      newHeadCellData[level0Model.KEYS.PROPERTIES]?.['head-id'],
                      [...newHeadCellPath, 'head-id'],
                    ),
                  );

                  // add new col span
                  if (newColSpanValue > 1) {
                    ops.push(
                      RealtimeOpsBuilder.objectInsert(newColSpanValue, [...newHeadCellPath, 'cs']),
                    );
                  }

                  // copy row span
                  const rowSpan = headCell.rowSpan;
                  if (rowSpan > 1) {
                    ops.push(RealtimeOpsBuilder.objectInsert(rowSpan, [...newHeadCellPath, 'rs']));
                  }

                  // update headIds value
                  for (let x = 0; x < rowSpan; x++) {
                    for (let y = 0; y < newColSpanValue; y++) {
                      if (x !== 0 || y !== 0) {
                        ops.push(
                          RealtimeOpsBuilder.objectReplace(
                            headCellToRemove[i],
                            newHeadCellData.id,
                            [
                              ...tablePath,
                              level0Model.KEYS.CHILDNODES,
                              0,
                              level0Model.KEYS.CHILDNODES,
                              (headCell.parentNode as HTMLTableRowElement).sectionRowIndex + x,
                              level0Model.KEYS.CHILDNODES,
                              cellIndexes[0] + y, // path offset
                              level0Model.KEYS.PROPERTIES,
                              'head-id',
                            ],
                          ),
                        );
                      }
                    }
                  }

                  // if new head cell is empty, add an empty paragraph
                  if (newHeadCellData.childNodes && newHeadCellData.childNodes.length === 0) {
                    const paragraph = NodeDataBuilder.buildNodeData({
                      data: {
                        type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
                        parent_id: newHeadCellData.id,
                      },
                    });

                    newHeadCellPath.pop(); // remove properties path
                    newHeadCellPath.push(level0Model.KEYS.CHILDNODES); // add child nodes path
                    newHeadCellPath.push(0); // add base index

                    ops.push(RealtimeOpsBuilder.listInsert(paragraph, newHeadCellPath));
                  }
                }
              }
            }

            // build ops for updated headcells
            const keyIds = Object.keys(headCellToUpdate);
            for (let i = 0; i < keyIds.length; i++) {
              const updatedData = headCellToUpdate[keyIds[i]];

              const headCellPropPath = level0Model.findPath(level0Model.KEYS.ID, keyIds[i]);
              headCellPropPath.pop(); // remove path id
              headCellPropPath.push(level0Model.KEYS.PROPERTIES); // path to properties
              headCellPropPath.push('cs');

              if (updatedData.properties.cs > 0) {
                const op = level0Model.buildOp(headCellPropPath, updatedData.properties.cs);
                if (op != null) {
                  ops.push(op);
                }
              } else {
                const op = level0Model.buildOp(headCellPropPath, null);
                if (op != null) {
                  ops.push(op);
                }
              }
            }

            // update table width
            if (cellIndexes.length) {
              const columnsWeights = this.findColumnsWeights(level0Model, tableData, cellIndexes);
              const tableWidth = tableData.properties?.w;

              let op;
              const indexes = Object.keys(columnsWeights);
              if (
                tableWidth &&
                (tableWidth.t === 'abs' || tableWidth.t === 'pct') &&
                level0Node === table &&
                indexes.length > 0
              ) {
                const newTableWidth = {
                  t: tableWidth.t,
                  v: tableWidth.v,
                };

                for (let i = 0; i < indexes.length; i++) {
                  newTableWidth.v = +(
                    newTableWidth.v -
                    newTableWidth.v * columnsWeights[+indexes[i]]
                  ).toFixed(3);
                }

                op = this.getObjectOperationforPathValue(tableWidth, newTableWidth, [
                  ...tablePath,
                  level0Model.KEYS.PROPERTIES,
                  'w',
                ]);
              } else if (!tableWidth || tableWidth.t !== 'auto') {
                op = this.getObjectOperationforPathValue(tableWidth, { t: 'auto', v: 0 }, [
                  ...tablePath,
                  level0Model.KEYS.PROPERTIES,
                  'w',
                ]);
              }

              if (op) {
                ops.push(op);
              }
            }

            if (ops.length > 0) {
              actionContext.jsonChanges = true;
              level0Model.apply(ops, { source: 'LOCAL_RENDER' });
            }
          }
        }
      }

      insertRowOperation(
        actionContext: ActionContext,
        level0Node: HTMLElement,
        table: TableElement,
        selectedCells: TableCellElement[] = [],
        {
          before,
          rowHeigth,
          mergeUnselectedCells = false,
          rowToInsert,
        }: {
          before?: boolean;
          rowHeigth?: string | number;
          mergeUnselectedCells?: boolean;
          rowToInsert?: HTMLTableRowElement;
        },
      ) {
        if (table != null && selectedCells.length > 0) {
          const ops = [];

          const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

          const rowIndexes: number[] = [];

          for (let i = 0; i < selectedCells.length; i++) {
            let cell = selectedCells[i];
            if (cell.tagName !== ELEMENTS.TableCellElement.TAG) {
              cell = DOMUtils.closest(
                selectedCells[i],
                ELEMENTS.TableCellElement.TAG,
              ) as TableCellElement;
            }

            const index = (cell.parentNode as HTMLTableRowElement).sectionRowIndex;

            if (!rowIndexes.includes(index)) {
              rowIndexes.push(index);
            }
          }

          rowIndexes.sort((a, b) => {
            return a - b;
          });

          const tBody = table.tBodies[0] as HTMLTableSectionElement;

          const refRow = before
            ? tBody.rows[rowIndexes[0]]
            : tBody.rows[rowIndexes[rowIndexes.length - 1]];

          const headCellToUpdate: any = {};

          let newRowData: Editor.Data.Node.Data | undefined;
          if (rowToInsert != null) {
            newRowData = {
              ...this.documentParser.parse(rowToInsert),
              id: EditorDOMUtils.generateRandomNodeId(),
              parent_id: refRow.getAttribute('parent_id') as string,
            };
          } else {
            const newRowBuilder = new NodeDataBuilder({
              data: {
                type: ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.ELEMENT_TYPE,
                parent_id: refRow.getAttribute('parent_id') as string,
              },
            });

            const rh =
              rowHeigth != null
                ? rowHeigth
                : DOMUtils.convertUnitTo(refRow.style.height || '48px', null, 'pt', 3);

            newRowBuilder.addProperty('rh', rh);

            for (let j = 0; j < refRow.childNodes.length; j++) {
              const cell = refRow.childNodes[j] as TableCellElement;

              if (cell) {
                const cellData = level0Model.getChildDataById(cell.id);

                const clonedProperties = JSON.parse(
                  JSON.stringify(cellData[level0Model.KEYS.PROPERTIES]),
                );
                delete clonedProperties['head-id'];
                delete clonedProperties.d;
                delete clonedProperties.cs;
                delete clonedProperties.rs;

                const cellBuilder = new NodeDataBuilder({
                  data: {
                    type: ELEMENTS.TableCellElement.ELEMENT_TYPE,
                    parent_id: newRowBuilder.getId(),
                    properties: clonedProperties,
                  },
                });

                if (cell.hasAttribute('head-id')) {
                  const headCell = table.querySelector(
                    `*[id="${cell.getAttribute('head-id')}"]`,
                  ) as TableCellElement;
                  if (headCell) {
                    if (
                      (headCell.parentNode as HTMLTableRowElement).sectionRowIndex ===
                      (cell.parentNode as HTMLTableRowElement).sectionRowIndex
                    ) {
                      if (!before && headCell.rowSpan > 1) {
                        cellBuilder.addProperty('head-id', headCell.id);
                        cellBuilder.addProperty('d', false);

                        if (!headCellToUpdate[headCell.id]) {
                          // add headcell to update
                          headCellToUpdate[headCell.id] = {
                            properties: { rs: headCell.rowSpan + 1 },
                          };
                        }
                      } else if (headCell.colSpan > 1) {
                        cellBuilder.addProperty(
                          'head-id',
                          newRowBuilder.getChild(headCell.cellIndex)?.id,
                        );
                        cellBuilder.addProperty('d', false);
                      }
                    } else if (
                      (headCell.parentNode as HTMLTableRowElement).sectionRowIndex +
                        headCell.rowSpan -
                        1 ===
                        (cell.parentNode as HTMLTableRowElement).sectionRowIndex &&
                      !(mergeUnselectedCells && !selectedCells.includes(cell))
                    ) {
                      if (!before && headCell.colSpan > 1) {
                        if (headCell.cellIndex === cell.cellIndex) {
                          cellBuilder.addProperty('cs', headCell.colSpan);
                        } else {
                          cellBuilder.addProperty(
                            'head-id',
                            newRowBuilder.getChild(headCell.cellIndex)?.id,
                          );
                          cellBuilder.addProperty('d', false);
                        }
                      }
                      if (before) {
                        cellBuilder.addProperty('head-id', headCell.id);
                        cellBuilder.addProperty('d', false);

                        if (!headCellToUpdate[headCell.id]) {
                          // add headcell to update
                          headCellToUpdate[headCell.id] = {
                            properties: { rs: headCell.rowSpan + 1 },
                          };
                        }
                      }
                    } else {
                      cellBuilder.addProperty('head-id', headCell.id);
                      cellBuilder.addProperty('d', false);

                      if (!headCellToUpdate[headCell.id]) {
                        // add headcell to update
                        headCellToUpdate[headCell.id] = {
                          properties: { rs: headCell.rowSpan + 1 },
                        };
                      }
                    }
                  }
                } else if (
                  !before &&
                  (cell.rowSpan > 1 || (mergeUnselectedCells && !selectedCells.includes(cell)))
                ) {
                  cellBuilder.addProperty('head-id', cell.id);
                  cellBuilder.addProperty('d', false);

                  if (!headCellToUpdate[cell.id]) {
                    // add headcell to update
                    headCellToUpdate[cell.id] = {
                      properties: { rs: cell.rowSpan + 1 },
                    };
                  }
                } else if (cell.colSpan > 1) {
                  cellBuilder.addProperty('cs', cell.colSpan);
                }

                if (!cellBuilder.getProperties()?.['head-id']) {
                  const firstChild = cellData.childNodes?.[0];
                  let properties = {};

                  if (firstChild?.type === ELEMENTS.ParagraphElement.ELEMENT_TYPE) {
                    properties = JSON.parse(
                      JSON.stringify(firstChild[level0Model.KEYS.PROPERTIES]),
                    );
                  }

                  // add a paragraph to cell
                  cellBuilder.addChildNode(
                    NodeDataBuilder.buildNodeData({
                      data: {
                        type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
                        parent_id: cellBuilder.getId(),
                        properties,
                      },
                    }),
                  );
                }

                newRowBuilder.addChildNode(cellBuilder.getNodeData());
              }
            }

            newRowData = newRowBuilder.getNodeData();
          }

          if (newRowData) {
            const refRowPath = level0Model.findPath(level0Model.KEYS.ID, refRow.id);
            refRowPath.pop(); // remove path id

            if (!before) {
              refRowPath[refRowPath.length - 1] = +refRowPath[refRowPath.length - 1] + 1; // increment index
            }

            ops.push(RealtimeOpsBuilder.listInsert(newRowData, refRowPath));

            // build ops for updated headcells
            const keyIds = Object.keys(headCellToUpdate);
            for (let k = 0; k < keyIds.length; k++) {
              const updatedData = headCellToUpdate[keyIds[k]];

              const headCellPropPath = level0Model.findPath(level0Model.KEYS.ID, keyIds[k]);
              headCellPropPath.pop(); // remove path id
              headCellPropPath.push(level0Model.KEYS.PROPERTIES); // path to properties
              headCellPropPath.push('rs');

              if (updatedData.properties.rs > 0) {
                const op = level0Model.buildOp(headCellPropPath, updatedData.properties.rs);
                if (op != null) {
                  ops.push(op);
                }
              }
            }
          }

          if (ops.length > 0) {
            actionContext.jsonChanges = true;
            level0Model.apply(ops, { source: 'LOCAL_RENDER' });
          }
        }
      }

      insertColumnOperation(
        actionContext: ActionContext,
        level0Node: HTMLElement,
        table: TableElement,
        selectedCells: TableCellElement[] = [],
        {
          before,
          columnWidth,
          mergeUnselectedCells = false,
        }: {
          before?: boolean;
          columnWidth?: Editor.Data.Node.TableWidth;
          mergeUnselectedCells?: boolean;
        },
      ) {
        if (table != null && selectedCells.length > 0) {
          const cellIndexes: number[] = [];

          for (let i = 0; i < selectedCells.length; i++) {
            let cell = selectedCells[i];
            if (cell.tagName !== ELEMENTS.TableCellElement.TAG) {
              cell = DOMUtils.closest(
                selectedCells[i],
                ELEMENTS.TableCellElement.TAG,
              ) as TableCellElement;
            }
            if (!cellIndexes.includes(cell.cellIndex)) {
              cellIndexes.push(cell.cellIndex);
            }
          }

          cellIndexes.sort((a, b) => {
            return a - b;
          });

          const ops = [];

          const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

          let tableData: Editor.Data.Node.TableData;
          if (level0Node === table) {
            tableData = level0Model.get();
          } else {
            tableData = level0Model.getChildDataById(table.id) as Editor.Data.Node.TableData;
          }

          const tablePath = level0Model.findPathToChild(table.id);

          const refCellIndex = before ? cellIndexes[0] : cellIndexes[cellIndexes.length - 1];

          const tBody = table.tBodies[0] as HTMLTableSectionElement;

          const rows = tBody.rows;
          const rowsLength = rows.length;

          const headCellToUpdate: any = {};

          const columnCells = [];

          for (let j = 0; j < rowsLength; j++) {
            const row = rows[j];
            const refCell = row.childNodes[refCellIndex] as TableCellElement;

            if (refCell) {
              const refCellData = level0Model.getChildDataById(refCell.id);

              const clonedProperties = JSON.parse(
                JSON.stringify(refCellData[level0Model.KEYS.PROPERTIES]),
              );
              delete clonedProperties['head-id'];
              delete clonedProperties.d;
              delete clonedProperties.cs;
              delete clonedProperties.rs;

              const cellBuilder = new NodeDataBuilder({
                data: {
                  type: ELEMENTS.TableCellElement.ELEMENT_TYPE,
                  parent_id: row.id,
                  properties: clonedProperties,
                },
              });

              const headId = refCellData[level0Model.KEYS.PROPERTIES]?.['head-id'];
              if (headId) {
                const headCell = table.querySelector(`*[id="${headId}"]`) as TableCellElement;
                if (headCell) {
                  if (headCell.cellIndex === refCell.cellIndex) {
                    if (!before && headCell.colSpan > 1) {
                      cellBuilder.addProperty('head-id', headCell.id);
                      cellBuilder.addProperty('d', false);

                      if (!headCellToUpdate[headCell.id]) {
                        // add headcell to update
                        headCellToUpdate[headCell.id] = {
                          properties: { cs: headCell.colSpan + 1 },
                        };
                      }
                    } else if (headCell.rowSpan > 1) {
                      cellBuilder.addProperty(
                        'head-id',
                        columnCells[(headCell.parentNode as HTMLTableRowElement).sectionRowIndex]
                          ?.id,
                      );
                      cellBuilder.addProperty('d', false);
                    }
                  } else if (
                    headCell.cellIndex + headCell.colSpan - 1 === refCell.cellIndex &&
                    !(mergeUnselectedCells && !selectedCells.includes(refCell))
                  ) {
                    if (!before && headCell.rowSpan > 1) {
                      if (
                        (headCell.parentNode as HTMLTableRowElement).sectionRowIndex ===
                        (refCell.parentNode as HTMLTableRowElement).sectionRowIndex
                      ) {
                        cellBuilder.addProperty('rs', headCell.rowSpan);
                      } else {
                        cellBuilder.addProperty(
                          'head-id',
                          columnCells[(headCell.parentNode as HTMLTableRowElement).sectionRowIndex]
                            ?.id,
                        );
                        cellBuilder.addProperty('d', false);
                      }
                    }
                    if (before) {
                      cellBuilder.addProperty('head-id', headCell.id);
                      cellBuilder.addProperty('d', false);

                      if (!headCellToUpdate[headCell.id]) {
                        // add headcell to update
                        headCellToUpdate[headCell.id] = {
                          properties: { cs: headCell.colSpan + 1 },
                        };
                      }
                    }
                  } else {
                    cellBuilder.addProperty('head-id', headCell.id);
                    cellBuilder.addProperty('d', false);

                    if (!headCellToUpdate[headCell.id]) {
                      // add headcell to update
                      headCellToUpdate[headCell.id] = {
                        properties: { cs: headCell.colSpan + 1 },
                      };
                    }
                  }
                }
              } else if (
                !before &&
                (refCell.colSpan > 1 || (mergeUnselectedCells && !selectedCells.includes(refCell)))
              ) {
                cellBuilder.addProperty('head-id', refCell.id);
                cellBuilder.addProperty('d', false);

                if (!headCellToUpdate[refCell.id]) {
                  // add headcell to update
                  headCellToUpdate[refCell.id] = {
                    properties: { cs: refCell.colSpan + 1 },
                  };
                }
              } else if (refCell.rowSpan > 1) {
                cellBuilder.addProperty('rs', refCell.rowSpan);
              }

              if (!cellBuilder.getProperties()?.['head-id']) {
                const firstChild = refCellData.childNodes?.[0];
                let properties: Editor.Data.Node.Data['properties'] = {};

                if (firstChild?.type === ELEMENTS.ParagraphElement.ELEMENT_TYPE) {
                  properties = JSON.parse(JSON.stringify(firstChild[level0Model.KEYS.PROPERTIES]));
                }

                if (columnWidth != null && properties) {
                  properties.w = {
                    t: columnWidth.t,
                    v: columnWidth.v,
                  };
                }

                // add a paragraph to cell
                cellBuilder.addChildNode(
                  NodeDataBuilder.buildNodeData({
                    data: {
                      type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
                      parent_id: cellBuilder.getId(),
                      properties,
                    },
                  }),
                );
              }

              columnCells.push(cellBuilder.getNodeData());
            } else {
              columnCells.push(null);
            }
          }

          const indexToAdd = before ? refCellIndex : refCellIndex + 1;

          // build ops to add cell
          for (let l = 0; l < columnCells.length; l++) {
            if (columnCells[l] != null) {
              ops.push(
                RealtimeOpsBuilder.listInsert(columnCells[l], [
                  ...tablePath,
                  level0Model.KEYS.CHILDNODES,
                  0,
                  level0Model.KEYS.CHILDNODES,
                  l,
                  level0Model.KEYS.CHILDNODES,
                  indexToAdd,
                ]),
              );
            }
          }

          // build ops for updated headcells
          const keyIds = Object.keys(headCellToUpdate);
          for (let k = 0; k < keyIds.length; k++) {
            const updatedData = headCellToUpdate[keyIds[k]];

            const headCellPropPath = level0Model.findPath(level0Model.KEYS.ID, keyIds[k]);
            headCellPropPath.pop(); // remove path id
            headCellPropPath.push(level0Model.KEYS.PROPERTIES); // path to properties
            headCellPropPath.push('cs');

            if (updatedData.properties.cs > 0) {
              const op = level0Model.buildOp(headCellPropPath, updatedData.properties.cs);
              if (op != null) {
                ops.push(op);
              }
            }
          }

          const columnsWeights = this.findColumnsWeights(level0Model, tableData, [refCellIndex]);
          const tableWidth = tableData.properties?.w;

          let op;
          if (
            tableWidth &&
            (tableWidth.t === 'abs' || tableWidth.t === 'pct') &&
            level0Node === table &&
            columnsWeights[refCellIndex] != null
          ) {
            const value = +(tableWidth.v + tableWidth.v * columnsWeights[refCellIndex]).toFixed(3);
            op = this.getObjectOperationforPathValue(tableWidth, { t: tableWidth.t, v: value }, [
              ...tablePath,
              level0Model.KEYS.PROPERTIES,
              'w',
            ]);
          } else if (!tableWidth || tableWidth.t !== 'auto') {
            op = this.getObjectOperationforPathValue(tableWidth, { t: 'auto', v: 0 }, [
              ...tablePath,
              level0Model.KEYS.PROPERTIES,
              'w',
            ]);
          }

          if (op) {
            ops.push(op);
          }

          if (ops.length > 0) {
            actionContext.jsonChanges = true;
            level0Model.apply(ops, { source: 'LOCAL_RENDER' });
          }
        }
      }

      mergeCellsOperation(
        actionContext: ActionContext,
        level0Node: HTMLElement,
        table: TableElement,
        selectedCells: TableCellElement[] = [],
      ) {
        if (table != null && selectedCells.length > 0) {
          const headCells: TableCellElement[] = [];

          const cellIndexes: number[] = [];
          const rowIndexes: number[] = [];

          const cellsToMerge: TableCellElement[] = [];

          for (let i = 0; i < selectedCells.length; i++) {
            let selectedCell = selectedCells[i];
            if (selectedCell.tagName !== ELEMENTS.TableCellElement.TAG) {
              selectedCell = DOMUtils.closest(
                selectedCells[i],
                ELEMENTS.TableCellElement.TAG,
              ) as TableCellElement;
            }

            if (
              (selectedCell.colSpan > 1 || selectedCell.rowSpan > 1) &&
              !headCells.includes(selectedCell)
            ) {
              headCells.push(selectedCell);

              for (let r = 0; r < selectedCell.rowSpan; r++) {
                const rowIndex =
                  (selectedCell.parentNode as HTMLTableRowElement).sectionRowIndex + r;

                for (let c = 0; c < selectedCell.colSpan; c++) {
                  const cellIndex = selectedCell.cellIndex + c;

                  const cell = selectedCell.parentNode?.parentNode?.childNodes[rowIndex].childNodes[
                    cellIndex
                  ] as TableCellElement;

                  if (cell) {
                    if (!cellIndexes.includes(cellIndex) && cell != null) {
                      cellIndexes.push(cellIndex);
                    }
                    if (!rowIndexes.includes(rowIndex) && cell != null) {
                      rowIndexes.push(rowIndex);
                    }
                    if (!cellsToMerge.includes(cell) && cell != null) {
                      cellsToMerge.push(cell);
                    }
                  }
                }
              }
            } else {
              if (!cellIndexes.includes(selectedCell.cellIndex)) {
                cellIndexes.push(selectedCell.cellIndex);
              }

              const index = (selectedCell.parentNode as HTMLTableRowElement).sectionRowIndex;

              if (!rowIndexes.includes(index)) {
                rowIndexes.push(index);
              }

              if (!cellsToMerge.includes(selectedCell)) {
                cellsToMerge.push(selectedCell);
              }
            }
          }

          cellIndexes.sort((a, b) => {
            return a - b;
          });

          rowIndexes.sort((a, b) => {
            return a - b;
          });

          // validate number of cells to merge
          if (
            (rowIndexes.length <= 1 && cellIndexes.length <= 1) ||
            cellsToMerge.length !== cellIndexes.length * rowIndexes.length
          ) {
            throw new ErrorCannotMergeCells();
          }

          // validate if indexes are contigous
          if (
            rowIndexes[rowIndexes.length - 1] - rowIndexes[0] > rowIndexes.length - 1 ||
            cellIndexes[cellIndexes.length - 1] - cellIndexes[0] > cellIndexes.length - 1
          ) {
            throw new ErrorCannotMergeCells();
          }

          // validate headcells rowspan and colspan
          for (let i = 0; i < headCells.length; i++) {
            const headCell = headCells[i];

            if (
              headCell.rowSpan >
              rowIndexes.length -
                rowIndexes.indexOf((headCell.parentNode as HTMLTableRowElement).sectionRowIndex)
            ) {
              throw new ErrorCannotMergeCells();
            }

            if (headCell.colSpan > cellIndexes.length - cellIndexes.indexOf(headCell.cellIndex)) {
              throw new ErrorCannotMergeCells();
            }
          }

          const ops = [];

          const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

          const tbody = table.tBodies[0] as HTMLTableSectionElement;

          const rows = tbody.rows;

          const childNodesToUpdate = [];

          const headCell = rows[rowIndexes[0]].cells[cellIndexes[0]];
          let headCellPath: Path = [];

          for (let x = 0; x < rowIndexes.length; x++) {
            for (let y = 0; y < cellIndexes.length; y++) {
              const cell = rows[rowIndexes[x]].cells[cellIndexes[y]];

              if (cell) {
                const cellPath = level0Model.findPath(level0Model.KEYS.ID, cell.id);
                cellPath.pop(); // remove path id

                const cellData = level0Model.get(cellPath);

                if (x === 0 && y === 0) {
                  // head cell
                  headCellPath = cellPath;

                  // add child nodes to update
                  Array.prototype.push.apply(childNodesToUpdate, cellData.childNodes);

                  // update rowspan
                  if (rowIndexes.length > 1 && rowIndexes.length !== cell.rowSpan) {
                    const op = level0Model.buildOp(
                      [...cellPath, level0Model.KEYS.PROPERTIES, 'rs'],
                      rowIndexes.length,
                    );
                    if (op != null) {
                      ops.push(op);
                    }
                  }

                  // update colspan
                  if (cellIndexes.length > 1 && cellIndexes.length !== cell.colSpan) {
                    const op = level0Model.buildOp(
                      [...cellPath, level0Model.KEYS.PROPERTIES, 'cs'],
                      cellIndexes.length,
                    );
                    if (op != null) {
                      ops.push(op);
                    }
                  }
                } else {
                  // cells to hide

                  // add child nodes to update
                  const childsLength = cell.childNodes.length;
                  for (let j = 0; j < childsLength; j++) {
                    const node = cell.childNodes[j] as HTMLElement;

                    if (node.tagName === ELEMENTS.ParagraphElement.TAG) {
                      if (node.textContent && node.textContent.length > 0) {
                        childNodesToUpdate.push(cellData.childNodes[j]);
                      }
                    } else {
                      childNodesToUpdate.push(cellData.childNodes[j]);
                    }
                  }

                  // delete child nodes
                  const childNodesOp = level0Model.buildOp(
                    [...cellPath, level0Model.KEYS.CHILDNODES],
                    [],
                  );
                  if (childNodesOp != null) {
                    ops.push(childNodesOp);
                  }

                  // remove rowspan if exist
                  if (cellData.properties.rs != null) {
                    const op = level0Model.buildOp(
                      [...cellPath, level0Model.KEYS.PROPERTIES, 'rs'],
                      null,
                    );
                    if (op != null) {
                      ops.push(op);
                    }
                  }

                  // remove colspan if exist
                  if (cellData.properties.cs != null) {
                    const op = level0Model.buildOp(
                      [...cellPath, level0Model.KEYS.PROPERTIES, 'cs'],
                      null,
                    );
                    if (op != null) {
                      ops.push(op);
                    }
                  }

                  // update head id
                  const headIdOp = level0Model.buildOp(
                    [...cellPath, level0Model.KEYS.PROPERTIES, 'head-id'],
                    headCell.id,
                  );
                  if (headIdOp) {
                    ops.push(headIdOp);
                  }

                  // update display
                  const displayOp = level0Model.buildOp(
                    [...cellPath, level0Model.KEYS.PROPERTIES, 'd'],
                    false,
                  );
                  if (displayOp) {
                    ops.push(displayOp);
                  }
                }
              }
            }
          }

          // update child nodes parent id
          for (let i = 0; i < childNodesToUpdate.length; i++) {
            childNodesToUpdate[i].parent_id = headCell.id;
          }

          // update head cell child nodes
          const childNodesOp = level0Model.buildOp(
            [...headCellPath, level0Model.KEYS.CHILDNODES],
            childNodesToUpdate,
          );
          if (childNodesOp) {
            ops.push(childNodesOp);
          }

          if (ops.length > 0) {
            actionContext.jsonChanges = true;
            level0Model.apply(ops, { source: 'LOCAL_RENDER' });
          }
        }
      }

      _getOpsToUpdateMergedCellProperties(
        level0Model: NodeModel,
        cellData: Editor.Data.Node.Data,
        cellPath: Path,
        { rowSpan, colSpan, headId, display }: any,
      ) {
        const ops = [];

        let op;

        op = level0Model.buildOp([...cellPath, level0Model.KEYS.PROPERTIES, 'rs'], rowSpan);
        if (op != null) {
          ops.push(op);
        }

        op = level0Model.buildOp([...cellPath, level0Model.KEYS.PROPERTIES, 'cs'], colSpan);
        if (op != null) {
          ops.push(op);
        }

        op = level0Model.buildOp([...cellPath, level0Model.KEYS.PROPERTIES, 'head-id'], headId);
        if (op != null) {
          ops.push(op);
        }

        // remove display
        op = level0Model.buildOp([...cellPath, level0Model.KEYS.PROPERTIES, 'd'], display);
        if (op != null) {
          ops.push(op);

          if (display !== false && cellData.childNodes && cellData.childNodes.length === 0) {
            // check child nodes
            const paragraph = NodeDataBuilder.buildNodeData({
              data: {
                type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
                parent_id: cellData.id,
              },
            });

            ops.push(
              RealtimeOpsBuilder.listInsert(paragraph, [
                ...cellPath,
                level0Model.KEYS.CHILDNODES,
                0,
              ]),
            );
          }
        }

        return ops;
      }

      splitCellsOperation(
        actionContext: ActionContext,
        level0Node: HTMLElement,
        table: TableElement,
        selectedCells: TableCellElement[] = [],
        { splitColumns = 2, splitRows = 1, mergeCells = false },
      ) {
        if (table != null && selectedCells.length > 0) {
          const headCells: TableCellElement[] = [];

          const cellIndexes: number[] = [];
          const rowIndexes: number[] = [];

          for (let i = 0; i < selectedCells.length; i++) {
            let selectedCell = selectedCells[i];
            if (selectedCell.tagName !== ELEMENTS.TableCellElement.TAG) {
              selectedCell = DOMUtils.closest(
                selectedCells[i],
                ELEMENTS.TableCellElement.TAG,
              ) as TableCellElement;
            }

            if (
              (selectedCell.colSpan > 1 || selectedCell.rowSpan > 1) &&
              !headCells.includes(selectedCell)
            ) {
              headCells.push(selectedCell);

              for (let r = 0; r < selectedCell.rowSpan; r++) {
                const rowIndex =
                  (selectedCell.parentNode as HTMLTableRowElement).sectionRowIndex + r;

                for (let c = 0; c < selectedCell.colSpan; c++) {
                  const cellIndex = selectedCell.cellIndex + c;

                  const cell =
                    selectedCell.parentNode?.parentNode?.childNodes[rowIndex].childNodes[cellIndex];

                  if (cell) {
                    if (!cellIndexes.includes(cellIndex) && cell != null) {
                      cellIndexes.push(cellIndex);
                    }
                    if (!rowIndexes.includes(rowIndex) && cell != null) {
                      rowIndexes.push(rowIndex);
                    }
                  }
                }
              }
            } else {
              if (!cellIndexes.includes(selectedCell.cellIndex)) {
                cellIndexes.push(selectedCell.cellIndex);
              }

              const rowIndex = (selectedCell.parentNode as HTMLTableRowElement).sectionRowIndex;

              if (!rowIndexes.includes(rowIndex)) {
                rowIndexes.push(rowIndex);
              }
            }
          }

          const ops = [];

          if (mergeCells === true) {
            // TODO: TO BE IMPLEMENTED
          }

          const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

          let tableData;
          if (level0Node === table) {
            tableData = level0Model.get();
          } else {
            tableData = level0Model.getChildDataById(table.id);
          }

          const tablePath = level0Model.findPath(level0Model.KEYS.ID, table.id);
          tablePath.pop(); // remove path id

          const tBody = table.tBodies[0] as HTMLTableSectionElement;

          const rows = tBody.rows;

          const cellWidths = this.getColumnWidths(tableData);

          if (headCells.length === 0) {
            // check split rows value
            if (rowIndexes.length > 1 && mergeCells === false) {
              splitRows = 1;
            }

            // iterate throw selected cell indexes
            for (let c = 0; c < cellIndexes.length; c++) {
              const cellIndex = cellIndexes[c];

              const splitCellWidth = cellWidths[cellIndex];
              if (splitCellWidth.t === 'abs' || splitCellWidth.t === 'pct') {
                splitCellWidth.v = splitCellWidth.v / splitColumns;
              }

              const columnCells: TableCellElement[] = [];

              for (let r = 0; r < rowIndexes.length; r++) {
                const cell = rows[rowIndexes[r]].cells[
                  cellIndexes[0] + c * splitColumns
                ] as TableCellElement;
                if (cell && !columnCells.includes(cell)) {
                  columnCells.push(cell);
                }
              }

              // insert columns
              for (let j = 1; j < splitColumns; j++) {
                this.insertColumnOperation(actionContext, level0Node, table, columnCells, {
                  columnWidth: splitCellWidth,
                  mergeUnselectedCells: true,
                });
              }
            }

            for (let r = 0; r < rowIndexes.length; r++) {
              const rowIndex = rowIndexes[r];

              const rowPath = level0Model.findPath(level0Model.KEYS.ID, rows[rowIndex].id);
              rowPath.pop(); // remove path id

              const rowData = level0Model.getChildDataByPath(rowPath);

              const splitRowHeight =
                (rowData[level0Model.KEYS.PROPERTIES]?.rh || DEFAULT_ROW_HEIGHT) / splitRows;

              const rowCells: TableCellElement[] = [];
              for (let c = 0; c < cellIndexes.length; c++) {
                // add recent added columns to selected cells
                for (let sc = 0; sc < splitColumns; sc++) {
                  const cell = rows[rowIndex].cells[
                    cellIndexes[0] + c * splitColumns + sc
                  ] as TableCellElement;
                  if (cell && !rowCells.includes(cell)) {
                    rowCells.push(cell);
                  }
                }
              }

              for (let j = 1; j < splitRows; j++) {
                this.insertRowOperation(actionContext, level0Node, table, rowCells, {
                  rowHeigth: splitRowHeight,
                  mergeUnselectedCells: true,
                });
              }

              // update this row heigth
              ops.push(
                RealtimeOpsBuilder.objectReplace(
                  rowData[level0Model.KEYS.PROPERTIES]?.rh,
                  splitRowHeight,
                  [...rowPath, level0Model.KEYS.PROPERTIES, 'rh'],
                ),
              );
            }
          } else {
            // split merged cells
            for (let i = 0; i < headCells.length; i++) {
              const headCell = headCells[i];

              // temp
              // fix values for split
              if (splitColumns !== 1 && splitColumns !== headCell.colSpan) {
                splitColumns = headCell.colSpan;
              }
              if (splitRows !== 1 && splitRows !== headCell.rowSpan) {
                splitRows = headCell.rowSpan;
              }

              if (headCell.colSpan === splitColumns && headCell.rowSpan === splitRows) {
                // just unmerge

                for (let x = 0; x < headCell.rowSpan; x++) {
                  for (let y = 0; y < headCell.colSpan; y++) {
                    const cell =
                      rows[(headCell.parentNode as HTMLTableRowElement).sectionRowIndex + x].cells[
                        headCell.cellIndex + y
                      ];

                    if (cell) {
                      const cellPath = level0Model.findPath(level0Model.KEYS.ID, cell.id);
                      cellPath.pop(); // remove path id

                      const cellData = level0Model.get(cellPath);

                      const dataToUpdate = {
                        rowSpan: undefined,
                        colSpan: undefined,
                        headId: undefined,
                        display: undefined,
                      };

                      ops.push(
                        ...this._getOpsToUpdateMergedCellProperties(
                          level0Model,
                          cellData,
                          cellPath,
                          dataToUpdate,
                        ),
                      );
                    }
                  }
                }
              } else {
                // handle columns
                if (headCell.colSpan === splitColumns) {
                  // unmerge columns but keep rows merged

                  for (let y = 0; y < headCell.colSpan; y++) {
                    const newHeadCell =
                      rows[(headCell.parentNode as HTMLTableRowElement).sectionRowIndex].cells[
                        headCell.cellIndex + y
                      ];

                    for (let x = 0; x < headCell.rowSpan; x++) {
                      const cell =
                        rows[(headCell.parentNode as HTMLTableRowElement).sectionRowIndex + x]
                          .cells[headCell.cellIndex + y];

                      if (cell) {
                        const cellPath = level0Model.findPath(level0Model.KEYS.ID, cell.id);
                        cellPath.pop(); // remove path id

                        const cellData = level0Model.get(cellPath);

                        if (x === 0) {
                          const dataToUpdate = {
                            rowSpan: headCell.rowSpan,
                            colSpan: 1,
                            headId: undefined,
                            display: undefined,
                          };

                          // update new head cell data
                          ops.push(
                            ...this._getOpsToUpdateMergedCellProperties(
                              level0Model,
                              cellData,
                              cellPath,
                              dataToUpdate,
                            ),
                          );
                        } else {
                          const dataToUpdate = {
                            rowSpan: undefined,
                            colSpan: undefined,
                            headId: newHeadCell.id,
                            display: false,
                          };

                          // update head id
                          ops.push(
                            ...this._getOpsToUpdateMergedCellProperties(
                              level0Model,
                              cellData,
                              cellPath,
                              dataToUpdate,
                            ),
                          );
                        }
                      }
                    }
                  }
                } else {
                  // TODO: to be impemented
                  if (headCell.colSpan < splitColumns) {
                    // insert columns
                    // let imparColumns = headCell.colSpan
                  }

                  // rearrange merges
                }

                // if (headCell.rowSpan !== splitRows) {
                //   // check rows limit
                //   if (splitRows > headCell.rowSpan) {
                //     splitRows = headCell.rowSpan;
                //   } else if (headCell.rowSpan % 2 !== 0) {
                //     splitRows = 1;
                //   }
                //   // else if(splitRows % 2 !== 0){
                //   //   splitRows =
                //   // }
                // }

                // handle rows
                if (headCell.rowSpan === splitRows) {
                  // unmerge rows but keep columns merged
                  for (let x = 0; x < headCell.rowSpan; x++) {
                    const newHeadCell =
                      rows[(headCell.parentNode as HTMLTableRowElement).sectionRowIndex + x].cells[
                        headCell.cellIndex
                      ];

                    for (let y = 0; y < headCell.colSpan; y++) {
                      const cell =
                        rows[(headCell.parentNode as HTMLTableRowElement).sectionRowIndex + x]
                          .cells[headCell.cellIndex + y];

                      if (cell) {
                        const cellPath = level0Model.findPath(level0Model.KEYS.ID, cell.id);
                        cellPath.pop(); // remove path id

                        const cellData = level0Model.get(cellPath);

                        if (x === 0) {
                          const dataToUpdate: any = {
                            rowSpan: 1,
                            colSpan: headCell.colSpan,
                          };

                          // remove headId
                          if (cellData.properties['head-id'] != null) {
                            dataToUpdate.headId = null;
                          }

                          // remove display
                          if (cellData.properties.d != null) {
                            dataToUpdate.display = null;
                          }

                          // update new head cell data
                          ops.push(
                            ...this._getOpsToUpdateMergedCellProperties(
                              level0Model,
                              cellData,
                              cellPath,
                              dataToUpdate,
                            ),
                          );
                        } else {
                          const dataToUpdate: any = {
                            headId: newHeadCell.id,
                            display: false,
                          };

                          // remove rowspan
                          if (cellData.properties.rs != null) {
                            dataToUpdate.rowSpan = undefined;
                          }

                          // remove colspan
                          if (cellData.properties.cs != null) {
                            dataToUpdate.colSpan = undefined;
                          }

                          // update head id
                          ops.push(
                            ...this._getOpsToUpdateMergedCellProperties(
                              level0Model,
                              cellData,
                              cellPath,
                              dataToUpdate,
                            ),
                          );
                        }
                      }
                    }
                  }
                } else {
                  // TODO: to be implemented
                  // rearrange merges
                }
              }
            }
          }

          if (ops.length > 0) {
            actionContext.jsonChanges = true;
            level0Model.apply(ops, { source: 'LOCAL_RENDER' });
          }
        }
      }

      updateColumnWidthsOperation(
        level0Node: HTMLElement,
        table: TableElement,
        changedColumnWidths: {
          [index: number]: { current: Editor.Data.Node.TableWidth; delta: number };
        },
      ) {
        if (!level0Node || !table || !changedColumnWidths) {
          return;
        }

        const ops = [];

        const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

        let tableData: Editor.Data.Node.TableData;
        let tablePath: (string | number)[] = [];
        if (level0Node === table) {
          tableData = level0Model.get();
        } else {
          tableData = level0Model.getChildDataById(table.id) as Editor.Data.Node.TableData;
          tablePath = level0Model.findPathToChild(table.id);
        }

        const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
        const rowsData = tableData?.childNodes?.[0].childNodes;

        let maxColumns: number = 0;

        const indexes = Object.keys(changedColumnWidths);

        if (rowsData) {
          for (let r = 0; r < rowsData.length; r++) {
            const row = rowsData[r];

            if (row.childNodes) {
              if (row.childNodes.length > maxColumns) {
                maxColumns = row.childNodes.length;
              }

              for (let c = 0; c < indexes.length; c++) {
                const cellIndex = +indexes[c];
                const cell = row.childNodes[cellIndex];

                if (cell) {
                  const propPath = [
                    ...rowsPath,
                    r,
                    'childNodes',
                    cellIndex,
                    level0Model.KEYS.PROPERTIES,
                    'w',
                  ];

                  let newWidth: Editor.Data.Node.TableWidth | undefined = undefined;

                  switch (changedColumnWidths[cellIndex].current.t) {
                    case 'abs':
                    case 'pct': {
                      newWidth = {
                        t: changedColumnWidths[cellIndex].current.t,
                        v: changedColumnWidths[cellIndex].current.v,
                      };
                      break;
                    }
                    case 'nil':
                    case 'auto': {
                      newWidth = {
                        t: 'auto',
                        v: 0,
                      };
                      break;
                    }
                  }

                  const op = this.getObjectOperationforPathValue(
                    cell.properties?.w,
                    newWidth,
                    propPath,
                  );
                  if (op) {
                    ops.push(op);
                  }
                }
              }
            }
          }
        }

        const tableWidth = tableData.properties?.w;
        // update table width
        if (
          changedColumnWidths[maxColumns - 1] &&
          indexes.length === 1 &&
          tableWidth &&
          (tableWidth.t === 'abs' || tableWidth.t === 'pct')
        ) {
          let newWidth: Editor.Data.Node.TableWidth | undefined = undefined;
          if (tableWidth?.t === 'abs') {
            newWidth = {
              t: 'abs',
              v: +(tableWidth.v + changedColumnWidths[maxColumns - 1].delta).toFixed(3),
            };
          } else if (tableWidth?.t === 'pct') {
            let containerWidth = 0;
            if (level0Model.id === table.id) {
              containerWidth = this.dataManager.sections.getPageWidthForBlockId(table.id);

              const oldAbsWidth = containerWidth * tableWidth.v;
              const newAbsWidth = oldAbsWidth + changedColumnWidths[maxColumns - 1].delta;

              newWidth = {
                t: 'pct',
                v: +((newAbsWidth * tableWidth.v) / oldAbsWidth).toFixed(3),
              };
            } else {
              newWidth = {
                t: 'auto',
                v: 0,
              };
            }
          }

          const op = this.getObjectOperationforPathValue(tableWidth, newWidth, [
            ...tablePath,
            level0Model.KEYS.PROPERTIES,
            'w',
          ]);
          if (op) {
            ops.push(op);
          }
        }

        const op = this.getObjectOperationforPathValue(tableData.properties?.ar, false, [
          ...tablePath,
          level0Model.KEYS.PROPERTIES,
          'ar',
        ]);
        if (op) {
          ops.push(op);
        }

        // apply ops
        if (ops.length > 0) {
          level0Model.apply(ops, { source: 'LOCAL_RENDER' });
        }
      }

      /**
       *
       * @param level0Node
       * @param table
       * @param rowHeights in points (pt)
       */
      updateRowHeightsOperation(
        level0Node: HTMLElement,
        table: TableElement,
        rowHeights: number[],
      ) {
        if (!level0Node || !table || !rowHeights) {
          return;
        }

        const ops = [];

        const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

        let tableData;
        let tablePath: (string | number)[] = [];
        if (level0Node === table) {
          tableData = level0Model.get();
        } else {
          tableData = level0Model.getChildDataById(table.id);
          tablePath = level0Model.findPath(level0Model.KEYS.ID, table.id);
          tablePath.pop(); // remove path id
        }

        const rowsData = tableData.childNodes[0].childNodes;

        for (let i = 0; i < rowHeights.length; i++) {
          if (rowsData[i]) {
            let rh = rowsData[i][level0Model.KEYS.PROPERTIES].rh;

            if (rh != null) {
              if (+rh !== rowHeights[i]) {
                ops.push(
                  RealtimeOpsBuilder.objectReplace(rh, rowHeights[i], [
                    ...tablePath,
                    level0Model.KEYS.CHILDNODES,
                    '0',
                    level0Model.KEYS.CHILDNODES,
                    `${i}`,
                    level0Model.KEYS.PROPERTIES,
                    'rh',
                  ]),
                );
              }
            } else {
              ops.push(
                RealtimeOpsBuilder.objectInsert(rowHeights[i], [
                  ...tablePath,
                  level0Model.KEYS.CHILDNODES,
                  '0',
                  level0Model.KEYS.CHILDNODES,
                  `${i}`,
                  level0Model.KEYS.PROPERTIES,
                  'rh',
                ]),
              );
            }
          }
        }

        const op = this.getObjectOperationforPathValue(tableData.properties.ar, false, [
          ...tablePath,
          level0Model.KEYS.PROPERTIES,
          'ar',
        ]);
        if (op) {
          ops.push(op);
        }

        // apply ops
        if (ops.length > 0) {
          level0Model.apply(ops, { source: 'LOCAL_RENDER' });
        }
      }

      updateTableSizeOperation(
        level0Node: HTMLElement,
        table: TableElement,
        height: number,
        newWidth: Editor.Data.Node.TableWidth,
      ) {
        if (!level0Node || !table || height == null || newWidth == null) {
          return;
        }

        const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

        let tableData;
        let tablePath: (string | number)[] = [];
        if (level0Node === table) {
          tableData = level0Model.get();
        } else {
          tableData = level0Model.getChildDataById(table.id);
          tablePath = level0Model.findPathToChild(table.id);
        }

        const ops = [];

        const op = this.getObjectOperationforPathValue(tableData.properties.w, newWidth, [
          ...tablePath,
          level0Model.KEYS.PROPERTIES,
          'w',
        ]);
        if (op) {
          ops.push(op);
        }

        const opAR = this.getObjectOperationforPathValue(tableData.properties.ar, false, [
          ...tablePath,
          level0Model.KEYS.PROPERTIES,
          'ar',
        ]);
        if (opAR) {
          ops.push(opAR);
        }

        // update height
        let actualHeight: number = 0;

        const rowsData = tableData.childNodes[0].childNodes;
        for (let i = 0; i < rowsData.length; i++) {
          actualHeight =
            actualHeight + +(rowsData[i][level0Model.KEYS.PROPERTIES].rh || DEFAULT_ROW_HEIGHT);
        }

        const heightDiffRatio = height / actualHeight;

        for (let i = 0; i < rowsData.length; i++) {
          let rh = rowsData[i][level0Model.KEYS.PROPERTIES].rh;

          let newHeight: number;
          if (rh != null) {
            newHeight = +(+rh * heightDiffRatio).toFixed(2);
          } else {
            newHeight = +(DEFAULT_ROW_HEIGHT * heightDiffRatio).toFixed(2);
          }

          const op = this.getObjectOperationforPathValue(rh, newHeight, [
            ...tablePath,
            level0Model.KEYS.CHILDNODES,
            '0',
            level0Model.KEYS.CHILDNODES,
            `${i}`,
            level0Model.KEYS.PROPERTIES,
            'rh',
          ]);

          if (op) {
            ops.push(op);
          }
        }

        // apply ops
        if (ops.length > 0) {
          level0Model.apply(ops, { source: 'LOCAL_RENDER' });
        }
      }
    },
);
