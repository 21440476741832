import { CSSProperties, useEffect, useState } from 'react';
import cx from 'classnames';
import { useSelector } from '_common/hooks';
import CanvasLayer from 'PDF/Content/CanvasLayer/CanvasLayer';
import { usePDFContext } from 'PDF/PDFContext';
import styles from './Thumbnail.module.scss';
import { PageViewport } from 'pdfjs-dist';
import { NAVIGATION_THUMBNAIL_WIDTH } from 'PDF/consts';

const BACKGROUND_PADDING_SIZE = 8;

type ThumbnailProps = {
  index: number;
  style: CSSProperties;
};

const Thumbnail = ({ index, style }: ThumbnailProps) => {
  const pdfManager = usePDFContext();
  const [pdfPage, setPdfPage] = useState<PDF.Page | null>(null);
  const [pdfViewport, setPdfViewport] = useState<PageViewport>();
  const currentPageNumber = useSelector((state) => state.pdf.general.currentPageNumber);

  const pageNum = index + 1;

  useEffect(() => {
    const loadPage = async () => {
      const pdfPage = await pdfManager.getPage(pageNum);
      if (pdfPage) {
        setPdfPage(pdfPage);
      }
    };

    loadPage();

    return () => {};
  }, [pdfManager, pageNum]);

  useEffect(() => {
    if (pdfPage) {
      const pageHeight = pdfManager.getPageHeight(index + 1);
      const pageWidth = pdfManager.getPageWidth(index + 1);

      const targetedHeight = (NAVIGATION_THUMBNAIL_WIDTH * pageHeight) / pageWidth;
      const viewport = pdfPage.getViewport({ scale: targetedHeight / pageHeight });
      if (viewport) {
        setPdfViewport(viewport);
      }
    }
  }, [pdfPage]);

  const isCurrent = pageNum === currentPageNumber;

  if (!pdfViewport) {
    return null;
  }

  return (
    <div
      style={{ ...style, top: style.top, height: style.height, maxWidth: style.width || 1 }}
      className={cx(styles.container, { [styles.current]: isCurrent })}
    >
      <div
        style={{
          height: `${pdfViewport.height + BACKGROUND_PADDING_SIZE * 2}px`,
          width: `${pdfViewport.width + BACKGROUND_PADDING_SIZE * 2}px`,
        }}
        className={styles.background}
      >
        <div
          data-page-num={pageNum}
          className={styles.thumbnail}
          style={{ height: `${pdfViewport.height}px`, width: `${pdfViewport.width}px` }}
          onClick={() => {
            pdfManager.navigation?.goTo(pageNum);
          }}
        >
          <CanvasLayer pageNum={pageNum} viewport={pdfViewport} pdfPage={pdfPage} />
        </div>
      </div>
      <div className={styles.number}>
        <div>{pageNum}</div>
      </div>
    </div>
  );
};

export default Thumbnail;
