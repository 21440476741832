import { useRef } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router';

import { useSelector } from '_common/hooks';

import { selectIsPageLayout, selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';

import { ContextMenuTrigger, InteractionController } from '_common/components';

import EditorContextMenu from './EditorContextMenu/EditorContextMenu';
import EditorOverlay from './EditorOverlay/EditorOverlay';

import styles from './EditorInputComponent.module.scss';
import pageStyles from './EditorInputPageStyles.module.scss';
import Ruler from 'Editor/components/Ruler/Ruler';

type EditorInputComponentProps = {
  insertComment: () => void;
};

const EditorInputComponent = ({ insertComment }: EditorInputComponentProps) => {
  const { id } = useParams() as { id: string };
  const editorInputRef = useRef<HTMLDivElement>(null);

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isEditorVisible = useSelector((state) => state.editor.status.visible);
  const zoomvalue = useSelector((state) => state.editor.status.zoom);
  const isPageLayout = useSelector(selectIsPageLayout);

  return (
    <>
      <div id="WelcomeScreen" />
      <div
        id="EditorRoot"
        className={classNames(
          styles.root,
          styles.canvas,
          isReadOnlyMode && styles.readOnlyMode,
          isPageLayout && styles.pageLayout,
        )}
      >
        <Ruler />
        <div
          ref={editorInputRef}
          className={classNames(styles.inputRoot, styles.canvas)}
          style={{
            visibility: isEditorVisible ? 'visible' : 'hidden',
          }}
        >
          <div
            id="EditorContainer"
            className={styles.document}
            style={{
              transform: 'scale(' + zoomvalue + ')',
              transformOrigin: isPageLayout ? 'top center' : '0 0',
            }}
          >
            <ContextMenuTrigger id="EditorContextMenu">
              <InteractionController
                environment="editor"
                rules={[
                  {
                    interaction: 'editor_mainComponent_addText',
                    actions: ['editor_suggestions_addText'],
                  },
                  {
                    interaction: 'editor_mainComponent_deleteText',
                    actions: ['editor_suggestions_deleteText'],
                  },
                  {
                    interaction: 'editor_mainComponent_addComment',
                    actions: ['editor_comments_startCommentCreation'],
                  },
                  {
                    interaction: 'editor_mainComponent_addTask',
                    actions: ['editor_tasks_startTaskCreation'],
                  },
                ]}
              >
                <div
                  id={id}
                  contentEditable="true"
                  data-gramm_editor={false}
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  data-show-invisible-content="true" /* TODO: temp value, use redux value after implement toggle */
                  className={classNames(
                    pageStyles.dodoc_page,
                    pageStyles.canvas,
                    pageStyles.hyphenate,
                  )}
                />
              </InteractionController>
            </ContextMenuTrigger>
          </div>
          <EditorOverlay editorInputRef={editorInputRef} />

          <InteractionController environment="editor">
            <EditorContextMenu insertComment={insertComment} />
          </InteractionController>
        </div>
      </div>
    </>
  );
};

export default EditorInputComponent;
