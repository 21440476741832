import { createBrowserHistory, createMemoryHistory } from 'history';
import getConfig from 'dodoc.config';

const isTest = process.env.NODE_ENV === 'test';

const history = isTest ? createMemoryHistory({ initialEntries: ['/'] }) : createBrowserHistory();

// --------------------------------------------
// ---------------- Navigation -------------------

// It is now centralized in a way every route push goes through here so it can be traced
// although, this maybe should not be exported
export function navigateTo(route: string, isBlank?: boolean, replace?: boolean) {
  if (isBlank) {
    window.open(route, '_blank');
  } else if (replace) {
    history.replace(route);
  } else {
    history.push(route);
  }
}

export function navigateToGroups(isBlank?: boolean) {
  navigateTo('/groups', isBlank);
}

export function navigateToError404(isBlank?: boolean) {
  navigateTo('/404', isBlank);
}

export function navigateToError403(isBlank?: boolean) {
  navigateTo('/403', isBlank);
}

export function navigateToMyFiles(isBlank?: boolean, replace?: boolean) {
  navigateTo('/storage', isBlank, replace);
}

export function navigateToSpaces(isBlank?: boolean) {
  navigateTo('/storage/spaces', isBlank);
}

export function navigateToFile(id: ObjectId) {
  window.open(`/file/${id}`, '_blank');
}

/**
 * @param {string} object type
 * @param {*} object id
 */
export function navigateToObject(type: NavigableObject, id: ObjectId, isBlank?: boolean) {
  navigateTo(`/storage/${type}/${id}`, isBlank);
}

export function navigateToShared(isBlank?: boolean) {
  navigateTo('/shared', isBlank);
}

export function navigateToRecycle(isBlank?: boolean) {
  navigateTo('/storage/recycle', isBlank);
}

export function navigateToSignIn(isBlank?: boolean) {
  navigateTo('/auth/signin', isBlank);
}

export function navigateToDeviceRegistration(isBlank?: boolean) {
  navigateTo('/auth/deviceregistration', isBlank);
}

/**
 * @param {string} hashElement
 */
export function navigateToUserSettings(hashElement?: string, isBlank?: boolean) {
  navigateTo(
    `/settings/user${hashElement && hashElement.length > 0 ? `#${hashElement}` : ''}`,
    isBlank,
  );
}

/**
 * @param {string} object type
 * @param {*} object id
 * @param {string} hashElement
 */
export function navigateToSettings(
  type: ObjectType,
  id: ObjectId,
  hashElement?: string,
  isBlank?: boolean,
) {
  navigateTo(
    `/settings/${type}/${id}${hashElement && hashElement ? `#${hashElement}` : ''}`,
    isBlank,
  );
}

export function navigateToTenantSettings(isBlank?: boolean) {
  navigateTo('/settings', isBlank);
}

/**
 * @param {*} object Id
 */
export function navigateToEditor(objectId: ObjectId, isBlank: boolean = true) {
  navigateTo(`/editor/${objectId}`, isBlank);
}

export function navigateToPDF(objectId: ObjectId, isBlank: boolean = true) {
  navigateTo(`/pdf/${objectId}`, isBlank);
}

export function navigateToAdmin(adminUrl: string) {
  window.open(
    `${
      getConfig() && getConfig().authority && getConfig().authority.length > 0
        ? getConfig().authority
        : window.location.origin
    }${adminUrl}`,
    '_blank',
  );
}

export function navigateToSearch(isBlank?: boolean) {
  navigateTo('/search', isBlank);
}

export function refreshPage() {
  window.location.reload();
}

export function navigateToPrevious() {
  history.goBack();
}

export default history;
