import ReactDOM from 'react-dom';
import { IntlProvider } from 'Intl/Intl';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './PlaceholderElement.module.scss';

import { Icon, Tooltip } from 'dodoc-design-system';

import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { ReduxInterface } from 'Editor/services';
import { ELEMENTS, DISPLAY_TYPES } from 'Editor/services/consts';
import { BaseBlockElement } from '../..';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

type PlaceholderBreakContentArgs = {
  icon: IconTypes[32];
  translation: string;
};

const PlaceholderContent = ({ icon, translation }: PlaceholderBreakContentArgs) => {
  const intl = useIntl();

  return (
    <Tooltip
      content={intl.formatMessage({ id: 'CONTENT_CANNOT_BE_EDITED' })}
      testId="placeholder-cannot-be-edited-tooltip"
    >
      <div className={styles.contentWrapper}>
        <div className={styles.iconWrapper}>
          <Icon icon={icon} size={32} />
        </div>
        <div className={styles.info}>
          <FormattedMessage id={translation} />
        </div>
      </div>
    </Tooltip>
  );
};
export class PlaceholderElement extends BaseBlockElement {
  private container?: HTMLElement;

  constructor() {
    super();
    this.validateOnlyChildState = this.validateOnlyChildState.bind(this);
    this.removeOnlyChildState = this.removeOnlyChildState.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    this.buildElement();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  get isPlaceholder() {
    return true;
  }

  get isSelectable() {
    return false;
  }

  get displayType() {
    // return INLINE / BLOCK
    return DISPLAY_TYPES.INLINE;
  }

  get isEditable() {
    return false;
  }

  get isDeletable() {
    return true;
  }

  get isSplitable() {
    return false;
  }

  get isContentWrapper() {
    return false;
  }

  private getProperIcon(): IconTypes[32] {
    switch (this.dataset.type) {
      case 'fc':
      case 'so':
      case 'dw':
      case 'dg':
        return 'PlaceholderGraphicElement';
      default:
        return 'PlaceholderGraphicElement';
    }
  }

  private getProperTranslation() {
    switch (this.dataset.type) {
      case 'uk':
        return 'UNKNOWN';
      case 'dw':
        return 'DRAWING';
      case 'so':
        return 'SMART_OBJECT';
      case 'fc':
        return 'FLOWCHART';
      default:
        return 'FLOWCHART';
    }
  }

  removeOnlyChildState() {
    this.removeAttribute('onlychild');
  }

  validateOnlyChildState() {
    if (
      this.isInline &&
      DOMUtils.BLOCK_TEXT_ELEMENTS.includes((this.parentNode as HTMLElement)?.tagName) &&
      this.parentNode?.childNodes.length === 1
    ) {
      this.setAttribute('onlychild', 'true');
    } else {
      this.removeAttribute('onlychild');
    }
  }

  buildElement() {
    this.innerHTML = '';
    this.setAttribute('spellcheck', 'false');

    this.container = DOMElementFactory.buildElement('div');
    this.container.setAttribute('class', styles.container);

    // this.appendChild(document.createTextNode('\u00A0'));
    this.appendChild(document.createTextNode('\u202F'));
    this.appendChild(this.container);
    this.appendChild(document.createTextNode('\u202F'));

    const locale = ReduxInterface.getLocale();

    ReactDOM.unmountComponentAtNode(this.container);
    ReactDOM.render(
      <IntlProvider locale={locale}>
        <PlaceholderContent
          icon={this.getProperIcon()}
          translation={this.getProperTranslation()}
        ></PlaceholderContent>
      </IntlProvider>,
      this.container,
    );

    setTimeout(this.validateOnlyChildState, 0);
  }
}

if (!window.customElements.get(ELEMENTS.PlaceholderElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.PlaceholderElement.IDENTIFIER, PlaceholderElement);
}
