import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popover, Toggle, Tooltip, usePopper, Button, Divider } from 'dodoc-design-system';
import type { PopperProps } from 'dodoc-design-system/build/types/Components/Popper/Popper';
import type { ToggleProps } from 'dodoc-design-system/build/types/Components/Toggle/Toggle';

import { useDispatch, useSelector } from '_common/hooks';
import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import { clearFilterIdentity, selectHasFilters } from '../../FilterSlice';
import Filters, { FiltersProps } from '../../Filters';

import styles from './FilterPopover.module.scss';

type FilterPopoverProps = FiltersProps & {
  popperSettings?: PopperProps;
  toggleVariant?: ToggleProps<'medium', 'Filters'>['variant'];
};

const FilterPopover = ({
  identity,
  popperSettings,
  toggleVariant = 'standard',
  ...props
}: FilterPopoverProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { isOpen, popperProps, referenceProps } = usePopper(popperSettings);

  const hasActiveFilters = useSelector((state) => selectHasFilters(state, state.filters[identity]));

  useEffect(() => {
    return () => {
      dispatch(clearFilterIdentity({ identity, noFeedback: true }));
    };
  }, []);

  const handleClearAllFilters = () => {
    dispatch(clearFilterIdentity({ identity }));
  };

  return (
    <>
      <Tooltip
        placement="bottom"
        content={intl.formatMessage({ id: 'global.filter' })}
        testId={`${identity}-filterPopover-tooltip`}
      >
        <Toggle
          size="medium"
          variant={toggleVariant}
          isToggled={isOpen}
          icon="Filters"
          iconBadge={
            hasActiveFilters
              ? { icon: 'StatusApprove', size: 24, position: 'bottom-right' }
              : undefined
          }
          margin="0 0 0 1rem"
          testId={`${identity}-filterPopover-toggle`}
          {...referenceProps}
        />
      </Tooltip>
      <Popover {...popperProps} testId={`${identity}-filterPopover-popper`}>
        <IntlErrorBoundary margin="auto">
          <div className={styles.root}>
            <div className={styles.header}>
              <div className={styles.title}>
                <FormattedMessage id="FILTERS" />
              </div>
              <Button
                size="small"
                variant="link"
                margin="0 0 0 0.5rem"
                onClick={handleClearAllFilters}
                disabled={!hasActiveFilters}
                testId={`${identity}-filterPopover-clearAll`}
              >
                <FormattedMessage id="CLEAR_ALL" />
              </Button>
            </div>
            <Divider margin="0" />
            <div className={styles.filterList}>
              <Filters identity={identity} testId={`${identity}-filterPopover`} {...props} />
            </div>
          </div>
        </IntlErrorBoundary>
      </Popover>
    </>
  );
};

export default FilterPopover;
