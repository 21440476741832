import { useIntl } from 'react-intl';
import { useState, useEffect, useRef, MouseEventHandler, RefObject } from 'react';

import { stringToRichText } from 'utils';
import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector } from '_common/hooks';
import { Logger } from '_common/services';
import EditorManager from 'Editor/services/EditorManager';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';

import {
  selectReadOnlyMode,
  getDocumentObject,
  selectTrackedActionsActive,
  selectFilteredCommentsActive,
  selectUser,
  selectIsPageLayout,
} from 'Editor/redux/EditorStatusSlice';
import { cancelTemporaryComment, selectFilteredComments } from 'Editor/redux/CommentsSlice';
import { selectActiveTasks, selectTask, setTaskOverlayData } from 'Editor/redux/TasksSlice';
import { selectFilteredTrackedActions } from 'Editor/redux/TrackedActionsSlice';
import { completeAction } from 'App/redux/onboardingSlice';
import { setSidebarPanelTab, setSidebarView } from 'Editor/redux/SidebarSlice';

import { InteractionController } from '_common/components';
import { EditableTaskCard, CommentCard, EditableCard, TrackedActionCard } from 'Editor/components';
import TasksCard from 'Editor/pages/EditorPage/SidebarComponent/TasksTab/TasksCard/TasksCard';
import { EditableTaskCardProps } from 'Editor/components/EditableTaskCard/EditableTaskCardContent';
import { EditableCardProps } from 'Editor/components/EditableCard/EditableCardContent';

type OverlayProps = {
  editorInputRef: RefObject<HTMLDivElement>;
};

const Overlay = ({ editorInputRef }: OverlayProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const sidebarView = useSelector((state) => state.editor.sidebar.view);
  const selected = useSelector((state) => state.editor.tasks.overlay.selected);
  const operation = useSelector((state) => state.editor.tasks.overlay.operation);
  const tasks = useSelector((state) => state.editor.tasks.tasks);
  const offsets = useSelector((state) => state.editor.tasks.overlay.offsets);
  const taskSelected = useSelector(selectTask);
  const document = useSelector(getDocumentObject);
  const user = useSelector(selectUser);
  const comments = useSelector(selectFilteredComments);
  const commentsActive = useSelector(selectFilteredCommentsActive);
  const trackedActions = useSelector(selectFilteredTrackedActions);
  const activeTrackedActions = useSelector(selectTrackedActionsActive);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const activeTasks = useSelector(selectActiveTasks);
  const zoom = useSelector((state) => state.editor.status.zoom);
  const isPageLayout = useSelector(selectIsPageLayout);

  const [pageHeight, setPageHeight] = useState<number>();
  const editorInputObserver = useRef<ResizeObserver>();
  const [cardOffsets, setCardOffsets] = useState<{
    top: number;
    left: number;
    width: number;
    height: number;
  } | null>(null);
  const timeout = useRef<number>();

  const handleCardsOffsets = () => {
    if (activeTasks.tasks.length > 0) {
      const node = DOMUtils.getBlockNode(activeTasks.blockId);

      if (node) {
        const offsets = DOMUtils.getOffsets(node);
        return offsets;
      }
    }
    if (activeTrackedActions.length > 0) {
      const page = DOMUtils.getPageNode();
      const suggestion = page?.querySelector(`*[element_reference="${activeTrackedActions[0]}"]`);
      if (suggestion) {
        const level0 = DOMUtils.findNodeLevel0(page, suggestion);
        if (level0) {
          const level0Offsets = DOMUtils.getOffsets(level0);
          const suggestionOffsets = DOMUtils.getOffsets(suggestion);
          const offsets = {
            //@ts-expect-error DOMUtils not TS
            top: suggestionOffsets.top,
            //@ts-expect-error DOMUtils not TS
            left: level0Offsets.left,
            //@ts-expect-error DOMUtils not TS
            width: level0Offsets.width,
            //@ts-expect-error DOMUtils not TS
            height: suggestionOffsets.height,
          };
          return offsets;
        }
      }
    }
    if (commentsActive.length > 0 || comments.insert.inserting) {
      const inserting = comments.insert.inserting;
      const reference = comments.insert.reference;
      const page = DOMUtils.getPageNode();
      const comment = page?.querySelector(
        inserting
          ? `temp-comment-element[element_reference="${reference}"]`
          : `comment-element[element_reference="${commentsActive[0]}"]`,
      );
      if (comment) {
        const level0 = DOMUtils.findNodeLevel0(page, comment);
        if (level0) {
          const level0Offsets = DOMUtils.getOffsets(level0);
          const commentOffsets = DOMUtils.getOffsets(comment);
          const offsets = {
            //@ts-expect-error DOMUtils not TS
            top: commentOffsets.top,
            //@ts-expect-error DOMUtils not TS
            left: level0Offsets.left,
            //@ts-expect-error DOMUtils not TS
            width: level0Offsets.width,
            //@ts-expect-error DOMUtils not TS
            height: commentOffsets.height,
          };
          return offsets;
        }
      } else {
        const node = DOMUtils.getNode(comments.insert.level0);
        const offsets = DOMUtils.getOffsets(node);
        return offsets;
      }
    }
    if (offsets) {
      return offsets;
    }
    return null;
  };

  useEffect(() => {
    timeout.current = window.setTimeout(() => setCardOffsets(handleCardsOffsets()), 0);
  }, [
    zoom,
    offsets,
    pageHeight,
    comments.insert.inserting,
    commentsActive[0],
    activeTrackedActions[0],
    activeTasks.tasks[0],
    tasks,
    selected,
  ]);

  useEffect(() => {
    if (isPageLayout && !sidebarView) {
      if (commentsActive?.length > 0) {
        dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
        dispatch(setSidebarView('REVIEW'));
      } else if (activeTrackedActions?.length > 0) {
        dispatch(setSidebarPanelTab({ view: 'review', tab: 'changes' }));
        dispatch(setSidebarView('REVIEW'));
      } else if (activeTasks?.tasks?.length > 0) {
        dispatch(setSidebarView('TASKS'));
      }
    }
  }, [isPageLayout, commentsActive, activeTrackedActions, activeTasks]);

  useEffect(() => {
    if (
      commentsActive.length === 0 &&
      activeTrackedActions.length === 0 &&
      activeTasks.tasks.length === 0 &&
      !comments.insert.inserting
    ) {
      if (editorInputObserver.current) {
        editorInputObserver.current.disconnect();
        editorInputObserver.current = undefined;
      }

      return () => {
        clearTimeout(timeout.current);
      };
    }
    const newObserver = new ResizeObserver((entries) =>
      setPageHeight(entries[0].target.clientHeight),
    );

    if (editorInputRef?.current) {
      newObserver.observe(editorInputRef.current);
    }
    editorInputObserver.current = newObserver;

    return () => {
      newObserver.disconnect();
    };
  }, [
    commentsActive.length,
    activeTrackedActions.length,
    comments.insert.inserting,
    activeTasks.tasks.length,
  ]);

  const overlayRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    EditorManager.getInstance().filterComments(comments.order);
  }, [comments.order]);
  useEffect(() => {
    EditorManager.getInstance().filterSuggestions(trackedActions.order);
  }, [trackedActions.order]);

  if (
    !offsets &&
    commentsActive.length === 0 &&
    !activeTrackedActions &&
    activeTasks.tasks.length === 0 &&
    !comments.insert.inserting
  ) {
    return null;
  }

  const handleCancelEdition = () => {
    dispatch(setTaskOverlayData({ operation: 'view' }));
  };

  const handleCreateTask: EditableTaskCardProps['handleCreateTask'] = async ({
    description,
    dueDate,
    assignee,
  }) => {
    try {
      const taskId = await EditorManager.getInstance().createTask({
        description: stringToRichText(description),
        dueDate,
        assignee,
        user: user.id,
      });
      dispatch(completeAction('editor_tasks_createTask'));
      dispatch(setTaskOverlayData({ operation: 'view', selected: taskId }));
    } catch (error) {
      Logger.captureException(error);
    }
  };

  const handleEditTask: EditableTaskCardProps['handleCreateTask'] = async ({
    description,
    dueDate,
    assignee,
  }) => {
    if (taskSelected) {
      await EditorManager.getInstance()
        .editTask(taskSelected.id, {
          description: stringToRichText(description),
          dueDate,
          assignee,
        })
        .then(() => {
          notify({
            type: 'success',
            title: 'TASK_EDITED',
            message: 'THE_TASK_WAS_SUCCESSFULLY_EDITED',
          });
        });
      dispatch(setTaskOverlayData({ operation: 'view', selected: taskSelected?.id }));
    }
  };

  const handleTempCommentSelected: MouseEventHandler<HTMLDivElement> = () => {
    if (comments.insert?.inserting && comments.insert?.reference) {
      EditorManager.getInstance().focusComment(comments.insert.reference);
    }
  };

  const handleCancelClicked = () => {
    EditorManager.getInstance().removeTemporaryComment();
  };

  const handleCreateClicked: EditableCardProps['handleCreateClicked'] = (comment) => {
    EditorManager.getInstance().addComment(stringToRichText(comment));
    dispatch(cancelTemporaryComment());
  };

  const renderCard = () => {
    return (
      <div>
        {/* TRACKED ACTIONS LAYER */}
        {!isPageLayout &&
          activeTrackedActions.length > 0 &&
          !sidebarView &&
          activeTrackedActions
            .filter((trackedActionId) => trackedActions.actions[trackedActionId])
            .map((trackedActionId) => (
              <div key={trackedActionId} style={{ marginBottom: '1rem' }}>
                <TrackedActionCard
                  selected
                  trackedAction={trackedActions.actions[trackedActionId]}
                />
              </div>
            ))}
        {/* COMMENTS LAYER */}
        {comments.insert.inserting && !sidebarView && !isPageLayout && (
          <div style={{ marginBottom: '1rem' }}>
            <EditableCard
              user={user.id}
              handleCreateClicked={handleCreateClicked}
              handleCancelClicked={handleCancelClicked}
              onClick={handleTempCommentSelected}
              id={`Comment#${comments.insert.reference}`}
              placeholder={intl.formatMessage({ id: 'INSERT_YOUR_COMMENT_HERE' })}
            />
          </div>
        )}
        {!isPageLayout &&
          commentsActive.length > 0 &&
          !sidebarView &&
          commentsActive.map((commentId) => (
            <div key={commentId} style={{ marginBottom: '1rem' }}>
              <CommentCard
                document={document}
                user={user.id}
                selected
                comment={comments.comments[commentId]}
                isReadOnlyMode={isReadOnlyMode || isPageLayout}
              />
            </div>
          ))}
        {/* TASKS LAYER */}
        {(operation === 'create' || operation === 'edit') && (
          <InteractionController
            environment="editor"
            rules={[
              {
                interaction: 'editor_tasks_tempCard',
                actions: ['editor_tasks_createTask'],
              },
            ]}
          >
            <EditableTaskCard
              isSidebarOpened={!!sidebarView}
              handleCancelClicked={handleCancelEdition}
              handleCreateTask={operation === 'edit' ? handleEditTask : handleCreateTask}
              description={operation === 'edit' ? taskSelected?.d ?? '' : ''}
              assigneeValue={operation === 'edit' ? taskSelected?.asg ?? '' : ''}
              dueDateValue={operation === 'edit' ? taskSelected?.t.d ?? '' : ''}
              operation={operation}
            />
          </InteractionController>
        )}
        {!isPageLayout &&
          operation === 'view' &&
          activeTasks.tasks.length > 0 &&
          !sidebarView &&
          activeTasks.tasks.map((taskId) => {
            return (
              <div key={taskId} style={{ marginBottom: '1rem' }}>
                <TasksCard task={tasks[taskId]} />
              </div>
            );
          })}
      </div>
    );
  };

  if (!cardOffsets) {
    return null;
  }
  return (
    <div
      ref={overlayRef}
      style={{
        left: cardOffsets.left * zoom + cardOffsets.width + 20 * zoom,
        top: cardOffsets.top * zoom,
        position: 'absolute',
      }}
    >
      {renderCard()}
    </div>
  );
};

export default Overlay;
