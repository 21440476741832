import { TypedUseQueryHookResult } from '@reduxjs/toolkit/dist/query/react';
import { useGetObject2Query } from '_common/services/api/ObjectApi';
import { AxiosBaseQuery } from '_common/services/axios/axios';
import { operations } from '_types/api';

type UseGetObjectQueryArgs<
  OT extends operations['get_object']['parameters']['path']['object_type'],
> = { object_id: string; object_type: OT };

const useObject = <OT extends operations['get_object']['parameters']['path']['object_type']>(
  args: UseGetObjectQueryArgs<OT>,
  queryOptions?: { skip?: boolean; refetchOnMountOrArgChange?: boolean },
): TypedUseQueryHookResult<
  ObjectTypeToSchemaMap[OT],
  UseGetObjectQueryArgs<OT>,
  AxiosBaseQuery
> => {
  // @ts-expect-error internal @reduxjs/toolkit types error
  return useGetObject2Query(args, queryOptions);
};

export default useObject;
