import './CitationsGroupElement.module.scss';

import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewElement } from '../..';

export class CitationsGroupElement extends BaseViewElement {
  private rightBracket?: HTMLElement;

  get displayType(): Editor.Elements.DisplayType | null {
    // return INLINE / BLOCK
    return 'INLINE';
  }

  get isEditable(): boolean | null {
    return false;
  }

  get isDeletable(): boolean | null {
    return true;
  }

  get isSplitable(): boolean | null {
    return false;
  }

  get isContentWrapper(): boolean | null {
    return false;
  }

  get isSelectable(): boolean | null {
    return true;
  }

  insertNewCitation(elementId: string, citationId: string, serialText: string) {
    const citationElement = DOMElementFactory.buildElement(ELEMENTS.CitationElement.TAG, {
      element_type: ELEMENTS.CitationElement.IDENTIFIER,
      element_reference: citationId,
      id: elementId,
    });
    citationElement.appendChild(document.createTextNode(serialText));

    DOMUtils.insertNodeBefore(this, citationElement, this.rightBracket);
  }

  getCitationsCount() {
    return this.querySelectorAll(ELEMENTS.CitationElement.TAG).length;
  }

  getNonDeletedCitationsCount() {
    const citations = Array.from(this.querySelectorAll(ELEMENTS.CitationElement.TAG));
    let count = 0;
    for (let index = 0; index < citations.length; index++) {
      const citation = citations[index]?.parentNode as HTMLElement;
      if (citation && citation.tagName !== 'TRACK-DEL-ELEMENT') {
        count += 1;
      }
    }
    return count;
  }

  getCitationElements() {
    return Array.from(this.querySelectorAll(ELEMENTS.CitationElement.TAG));
  }

  /**
   *
   * @param {Node} citationElement
   */
  addCitationElement(citations: HTMLElement[]) {
    if (citations) {
      let citationsArray;

      if (!Array.isArray(citations)) {
        citationsArray = [citations];
      } else {
        citationsArray = citations;
      }

      let i;
      for (i = 0; i < citationsArray.length; i++) {
        if (
          citationsArray[i].tagName === ELEMENTS.CitationElement.TAG ||
          citationsArray[i].tagName === ELEMENTS.TrackInsertElement.TAG
        ) {
          const nodeIterator = document.createNodeIterator(
            citationsArray[i],
            NodeFilter.SHOW_ELEMENT,
          );
          let currentNode: HTMLElement;

          while ((currentNode = nodeIterator.nextNode() as HTMLElement)) {
            currentNode.removeAttribute('id');
            currentNode.removeAttribute('parent_id');
          }

          citationsArray[i].removeAttribute('id');
          citationsArray[i].removeAttribute('parent_id');

          DOMUtils.insertNodeBefore(this, citationsArray[i], this.rightBracket);
        }
      }
    }
  }

  // removeCitationElement(citationElement) {
  //   const indexOf = this.childContent.indexOf(citationElement);
  //   if (indexOf >= 0 && indexOf < this.childContent.length) {
  //     // this.childContent.splice(indexOf, 1);
  //     this.removeChild(citationElement);
  //   }
  // }

  connectedCallback() {
    super.connectedCallback();
  }
}

if (!window.customElements.get(ELEMENTS.CitationsGroupElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.CitationsGroupElement.IDENTIFIER, CitationsGroupElement);
}
