import { useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { closeAllModals } from '_common/modals/ModalsSlice';
import EditorManager from 'Editor/services/EditorManager';

import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import {
  DocumentDetailsModal,
  ReopenBlock,
  ClipboardInfoModal,
  ClipboardInstallExtensionModal,
  EquationsModal,
  CaptionsModal,
  SuggestionModeLockConfirmationModal,
  SuggestionModeLockInfoModal,
  SetLanguageModal,
  ExportIntegrationModal,
  RenameDocumentStylesModal,
  NewListModal,
  IndentationOptionsModal,
  MissingFontsModal,
  KeyboardShortcutsModal,
  TableProperties,
  SynonymsModal,
  CharmapModal,
  HyperlinkModal,
  UpdateCrossReferences,
  EditCrossReferencesModal,
  SaveVersionModal,
  ColorPickerModal,
  ReplaceReferenceLibraryModal,
  RenameEndNoteFileModal,
  SplitCellsModal,
  OpenHyperlinkModal,
  ReplaceAllModal,
  DeleteCellsModal,
  DocumentAuditLogModal,
  PageSetupModal,
  TaskWatchModal,
  TabulationsModal,
  WordCountModal,
  WrapTextOptionsModal,
} from 'Editor/modals';
import { CitationsModal, CustomSpacingModal, EditorErrorModal, LikesModal } from '_common/modals';

const ModalConductor = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const open = useSelector((state) => state.modals.open);
  const data = useSelector((state) => state.app.data);
  const firstOpenModal = Object.entries(open).find(([, v]) => v)?.[0] as keyof typeof open;

  return (
    <IntlErrorBoundary
      fallbackType="modal"
      mockProps={{
        title: intl.formatMessage({ id: 'global.error' }),
        onClose: () => dispatch(closeAllModals()),
      }}
      disabled={!firstOpenModal}
    >
      {open.SaveVersionModal && (
        <SaveVersionModal
          saveVersion={EditorManager.getInstance().saveVersion}
          editDescription={EditorManager.getInstance().editVersionDescription}
        />
      )}
      {open.CitationsModal && <CitationsModal />}
      {open.CustomSpacingModal && <CustomSpacingModal />}
      {open.EditorErrorModal && <EditorErrorModal />}
      {open.DocumentDetailsModal && <DocumentDetailsModal />}
      {open.ExportIntegrationModal && <ExportIntegrationModal />}
      {open.RenameDocumentStylesModal && <RenameDocumentStylesModal />}
      {open.ReopenBlock && <ReopenBlock />}
      {open.ClipboardInfoModal && <ClipboardInfoModal />}
      {open.ClipboardInstallExtensionModal && <ClipboardInstallExtensionModal />}
      {open.SetLanguageModal && <SetLanguageModal />}
      {open.TaskWatchModal && <TaskWatchModal />}
      {open.CaptionsModal && <CaptionsModal />}
      {open.EquationsModal && <EquationsModal />}
      {open.SuggestionModeLockInfoModal && <SuggestionModeLockInfoModal />}
      {open.SuggestionModeLockConfirmationModal && <SuggestionModeLockConfirmationModal />}
      {open.NewListModal && <NewListModal />}
      {open.IndentationOptionsModal && <IndentationOptionsModal />}
      {open.MissingFontsModal && <MissingFontsModal />}
      {open.KeyboardShortcutsModal && <KeyboardShortcutsModal />}
      {open.TablePropertiesModal && <TableProperties />}
      {open.SynonymsModal && <SynonymsModal />}
      {open.CharmapModal && <CharmapModal />}
      {open.HyperlinkModal && <HyperlinkModal />}
      {open.UpdateCrossReferencesModal && <UpdateCrossReferences />}
      {open.EditCrossReferencesModal && <EditCrossReferencesModal />}
      {open.ColorPickerModal && <ColorPickerModal />}
      {open.ReplaceReferenceLibraryModal && <ReplaceReferenceLibraryModal />}
      {open.RenameEndNoteFileModal && <RenameEndNoteFileModal />}
      {open.SplitCellsModal && <SplitCellsModal />}
      {open.OpenHyperlinkModal && <OpenHyperlinkModal />}
      {open.ReplaceAllModal && <ReplaceAllModal />}
      {open.DeleteCellsModal && <DeleteCellsModal />}
      {open.DocumentAuditLogModal && <DocumentAuditLogModal data={data} />}
      {open.PageSetupModal && <PageSetupModal />}
      {open.LikesModal && <LikesModal />}
      {open.TabulationsModal && <TabulationsModal />}
      {open.WordCountModal && <WordCountModal />}
      {open.WrapTextOptionsModal && <WrapTextOptionsModal />}
    </IntlErrorBoundary>
  );
};

export default ModalConductor;
