import { ELEMENTS } from 'Editor/services/consts';

export class EditorDOMElements {
  // list of default text elements
  static BLOCK_TEXT_ELEMENTS: string[] = [ELEMENTS.ParagraphElement.TAG];

  static BLOCK_FRONTEND_ONLY_ELEMENTS: string[] = [
    ELEMENTS.InvalidElement.TAG,
    ELEMENTS.PasteMarkerElement.TAG,
    ELEMENTS.LoaderElement.TAG,
    'STYLE',
  ];

  static BLOCK_INVALID_ELEMENTS: string[] = ['INVALID-ELEMENT', 'LOADER-ELEMENT'];

  static BLOCK_NON_EDITABLE_ELEMENTS: string[] = [
    ...EditorDOMElements.BLOCK_INVALID_ELEMENTS,
    ELEMENTS.PageBreakElement.TAG, // legacy
    ELEMENTS.SectionBreakElement.TAG, // legacy
    'TABLE-OF-CONTENTS-ELEMENT',
    'LIST-OF-FIGURES-ELEMENT',
    'LIST-OF-TABLES-ELEMENT',
    'KEYWORDS-ELEMENT',
    'AUTHORS-ELEMENT',
  ];

  // list of editable level0 elements
  static EDITABLE_LEVEL0_ELEMENTS: string[] = [
    ELEMENTS.ParagraphElement.TAG,
    'TABLE',
    'FIGURE-ELEMENT',
    'TRACK-INS-ELEMENT',
    'TRACK-DEL-ELEMENT',
    ELEMENTS.ReferencesSectionElement.TAG,
  ];

  // list of deletable level0 elements
  static DELETABLE_LEVEL0_ELEMENTS: string[] = [
    ...EditorDOMElements.EDITABLE_LEVEL0_ELEMENTS,
    ...EditorDOMElements.BLOCK_NON_EDITABLE_ELEMENTS,
  ];

  public static BLOCK_ELEMENTS: string[] = [
    ...EditorDOMElements.DELETABLE_LEVEL0_ELEMENTS,
    'APPROVED-ELEMENT',
    'READONLY-ELEMENT',
    'REDACTED-ELEMENT',
  ];

  static MULTI_BLOCK_CONTAINER_ELEMENTS: string[] = [ELEMENTS.ReferencesSectionElement.TAG, 'TD'];

  static BLOCK_CONTAINER_ELEMENTS: string[] = [
    ...EditorDOMElements.MULTI_BLOCK_CONTAINER_ELEMENTS,
    ELEMENTS.TrackInsertElement.TAG,
    ELEMENTS.TrackDeleteElement.TAG,
  ];

  static FRONTEND_CONTAINER_ELEMENTS: string[] = [
    ELEMENTS.PageElement.TAG,
    ELEMENTS.SectionElement.TAG,
  ];

  // list of splitable level0 elements
  static DEFAULT_SPLITABLE_LEVEL0_ELEMENTS: string[] = [ELEMENTS.ParagraphElement.TAG];

  static INLINE_FRONTEND_ONLY_ELEMENTS: string[] = [
    'BR',
    ELEMENTS.PasteMarkerElement.TAG,
    ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER.TAG,
    ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER_CELL.TAG,
    ELEMENTS.InvalidElement.TAG,
    'STYLE',
    'SPAN',
    'DIV',
  ];

  // list of non selectable elements
  static INLINE_NON_SELECTABLE_ELEMENTS: string[] = [
    'STYLE',
    'BR',
    'TRACK-DEL-ELEMENT',
    'EQUATION-ELEMENT',
    'PASTE-MARKER-ELEMENT',
    // 'SPAN',
    // 'DIV',
    ELEMENTS.PlaceholderElement.TAG,
    ELEMENTS.PageBreakElement.TAG,
    ELEMENTS.SectionBreakElement.TAG,
    ELEMENTS.ColumnBreakElement.TAG,
    // 'FIELD-ELEMENT',
  ];

  // list of non-editable elements that can be inside a text element
  static INLINE_NON_EDITABLE_ELEMENTS: string[] = [
    ELEMENTS.CitationsGroupElement.TAG,
    // 'CITATION-ELEMENT',
    ELEMENTS.NoteElement.TAG,
    ELEMENTS.SymbolElement.TAG,
    'PASTE-MARKER-ELEMENT',
    ELEMENTS.EquationElement.TAG,
    'INVALID-ELEMENT',
    ELEMENTS.PlaceholderElement.TAG,
    ELEMENTS.PageBreakElement.TAG,
    ELEMENTS.SectionBreakElement.TAG,
    ELEMENTS.ColumnBreakElement.TAG,
    ELEMENTS.TabElement.TAG,
    ELEMENTS.ImageElement.TAG,
    // 'FIELD-ELEMENT',
  ];

  // list of elements that can't have styles inside
  static INLINE_NON_STYLABLE_ELEMENTS: string[] = [
    ELEMENTS.CitationsGroupElement.TAG,
    ELEMENTS.NoteElement.TAG,
    ELEMENTS.EquationElement.TAG,
    // ELEMENTS.FieldElement.TAG,
    ELEMENTS.PlaceholderElement.TAG,
    ELEMENTS.PageBreakElement.TAG,
    ELEMENTS.SectionBreakElement.TAG,
    ELEMENTS.ColumnBreakElement.TAG,
    ELEMENTS.TabElement.TAG,
  ];

  static INLINE_TEXT_ELEMENTS: string[] = ['FORMAT-ELEMENT'];

  static INLINE_WRAP_ELEMENTS: string[] = [
    'COMMENT-ELEMENT',
    'TEMP-COMMENT-ELEMENT',
    ELEMENTS.HyperlinkElement.TAG,
    ELEMENTS.FieldElement.TAG,
  ];

  static INLINE_EDITABLE_ELEMENTS: string[] = [
    ...EditorDOMElements.INLINE_TEXT_ELEMENTS,
    ...EditorDOMElements.INLINE_WRAP_ELEMENTS,
  ];

  static INLINE_ELEMENTS: string[] = [
    ...EditorDOMElements.INLINE_EDITABLE_ELEMENTS,
    ...EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS,
  ];

  static INLINE_DOUBLE_STATE_ELEMENTS: string[] = [
    ELEMENTS.PlaceholderElement.TAG,
    ELEMENTS.PageBreakElement.TAG,
    ELEMENTS.SectionBreakElement.TAG,
    ELEMENTS.ColumnBreakElement.TAG,
  ];

  static INLINE_LAST_CHILD_ELEMENTS: string[] = [
    ELEMENTS.PageBreakElement.TAG,
    ELEMENTS.SectionBreakElement.TAG,
    ELEMENTS.ColumnBreakElement.TAG,
  ];

  static WRAPPER_LEVEL0_ELEMENTS: string[] = ['READONLY-ELEMENT', 'APPROVED-ELEMENT'];

  static isTableElement(node: Node | null): node is Editor.Elements.TableElement {
    return (
      node != null &&
      node.nodeName === ELEMENTS.TableElement.TAG &&
      'identifier' in node &&
      node.identifier === ELEMENTS.TableElement.IDENTIFIER
    );
  }

  static isTableCellElement(node: Node | null): node is Editor.Elements.TableCellElement {
    return node != null && node.nodeName === ELEMENTS.TableCellElement.TAG;
  }

  static isFigureElement(node: Node | null): node is Editor.Elements.FigureElement {
    return node != null && node.nodeName === ELEMENTS.FigureElement.TAG;
  }

  static isImageElement(node: Node | null): node is Editor.Elements.ImageElement {
    return node != null && node.nodeName === ELEMENTS.ImageElement.TAG;
  }

  static isPageElement(node: Node | null): node is Editor.Elements.PageElement {
    return node != null && node.nodeName === ELEMENTS.PageElement.TAG;
  }

  static isSectionElement(node: Node | null): node is Editor.Elements.SectionElement {
    return node != null && node.nodeName === ELEMENTS.SectionElement.TAG;
  }

  static isParagraphElement(node: Node | null): node is Editor.Elements.ParagraphElement {
    return node != null && node.nodeName === ELEMENTS.ParagraphElement.TAG;
  }

  static isFormatElement(node: Node | null): node is Editor.Elements.FormatElement {
    return node != null && node.nodeName === ELEMENTS.FormatElement.TAG;
  }

  static isFieldElement(node: Node | null): node is Editor.Elements.FieldElement {
    return node != null && node.nodeName === ELEMENTS.FieldElement.TAG;
  }

  static isNoteElement(node: Node | null): node is Editor.Elements.NoteElement {
    return node != null && node.nodeName === ELEMENTS.NoteElement.TAG;
  }

  static isTrackedElement(
    node: Node | null,
  ): node is Editor.Elements.TrackInsertElement | Editor.Elements.TrackDeleteElement {
    return (
      node != null &&
      (node.nodeName === ELEMENTS.TrackInsertElement.TAG ||
        node.nodeName === ELEMENTS.TrackDeleteElement.TAG)
    );
  }

  static isSupportedElement(node: Node | null): node is Editor.Elements.SupportedElement {
    return (
      node != null &&
      (EditorDOMElements.BLOCK_ELEMENTS.includes(node.nodeName) ||
        EditorDOMElements.INLINE_ELEMENTS.includes(node.nodeName) ||
        node.nodeName === ELEMENTS.TableCellElement.TAG)
    );
  }

  static isSupportedBlockElement(node: Node | null): node is Editor.Elements.SupportedBlockElement {
    return node != null && EditorDOMElements.BLOCK_ELEMENTS.includes(node.nodeName);
  }

  static isNodeBlockWrapperElement(node: Node | null): node is Editor.Elements.BaseBlockElement {
    if (node && EditorDOMElements.WRAPPER_LEVEL0_ELEMENTS.includes(node.nodeName)) {
      return true;
    }

    return false;
  }

  static isApprovedElement(node: Node | null): node is Editor.Elements.ApprovedViewElement {
    if (node && node.nodeName === ELEMENTS.ApprovedElement.TAG) {
      return true;
    }

    return false;
  }

  static isNodeContainerElement(
    node: Node | null,
  ): node is
    | Editor.Elements.BaseBlockElement
    | Editor.Elements.TrackInsertElement
    | Editor.Elements.TrackDeleteElement {
    if (node && EditorDOMElements.BLOCK_CONTAINER_ELEMENTS.includes(node.nodeName)) {
      if (EditorDOMElements.isTrackedElement(node)) {
        if (node.displayType === 'BLOCK') {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  static isNodeBlockTextElement(node: Node | null): node is Editor.Elements.BaseBlockElement {
    if (node && EditorDOMElements.BLOCK_TEXT_ELEMENTS.includes(node.nodeName)) {
      return true;
    }

    return false;
  }

  static isNodeInlineElement(node: Node | null): node is Editor.Elements.BaseViewElement | Text {
    if (node instanceof Text) {
      return true;
    }

    if (node instanceof Element && EditorDOMElements.INLINE_ELEMENTS.includes(node.nodeName)) {
      return true;
    }

    return EditorDOMElements.isInlineNode(node);
  }

  static isNodeSuggestionParagraphMarker(node: Node | null) {
    return node != null && EditorDOMElements.isTrackedElement(node) && !node.isMergeable();
  }

  static isInlineNode(node: Node | null) {
    if (node instanceof Text) {
      return true;
    }

    if (node instanceof Element) {
      if (document.body.contains(node)) {
        const gcs = 'getComputedStyle' in window;
        // @ts-expect-error
        const cStyle = (gcs ? window.getComputedStyle(node, '') : node.currentStyle).display;
        if (cStyle.includes('inline')) {
          return true;
        }
      } else {
        const gcs = 'getComputedStyle' in window;
        const t = document.createElement(node.nodeName);
        document.body.appendChild(t);
        // @ts-expect-error
        const cStyle = (gcs ? window.getComputedStyle(t, '') : t.currentStyle).display;
        document.body.removeChild(t);
        if (cStyle.includes('inline')) {
          return true;
        }
      }
    }

    return false;
  }
}
