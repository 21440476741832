import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Switch, DatePicker } from 'dodoc-design-system';
import dayjs from 'dayjs';
import cx from 'classnames';

import { useDispatch, useSelector } from '_common/hooks';
import FormattedDate from '_common/components/FormattedDate/FormattedDate';

import { editOverdue, deleteOverdue, saveDueDate, deleteWarningDate } from 'App/redux/appSlice';

import styles from './DueDateSystem.module.scss';

const MAX_REMINDERS = 5;

export type DueDateSystemProps = {
  objectId: ObjectId;
  showLabels?: boolean;
  canEdit?: boolean;
  dueDateValue?: Date;
  remindersValue?: string[];
  size?: 'medium' | 'large';
  onChangeDueDate?: (dueDate: Date) => void;
  onDeleteDueDate?: () => void;
  onChangeReminder?: (reminder: Date) => void;
  onDeleteReminder?: (reminder: Date | null, deleteAll?: boolean) => void;
};

const DueDateSystem = ({
  objectId,
  showLabels,
  canEdit,
  dueDateValue,
  remindersValue,
  size = 'medium',
  onChangeDueDate,
  onDeleteDueDate,
  onChangeReminder,
  onDeleteReminder,
}: DueDateSystemProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const object = useSelector((state) => state.app.data[objectId]);

  //Due date
  const [dueDate, setDueDate] = useState(object.events.due);

  //Reminders = Warnings
  const [reminderActive, setReminderActive] = useState(object.events.warnings.length !== 0);
  const [reminderSortedArray, setReminderSortedArray] = useState([]);

  useEffect(() => {
    setReminderActive(object.events.warnings.length !== 0);
  }, [objectId, object]);

  useEffect(() => {
    if (dueDateValue || dueDateValue === '') {
      setDueDate(dueDateValue);
    } else {
      setDueDate(object.events.due);
    }
  }, [objectId, dueDateValue]);

  useEffect(() => {
    let reminders = [];
    if (remindersValue) {
      reminders = remindersValue;
    } else {
      reminders = object.events.warnings;
    }

    if ((!object.events?.due || !dueDateValue) && reminders?.length === 0) {
      setReminderActive(false);
    }

    setReminderSortedArray(
      reminders
        .map((reminder: Date) => new Date(reminder))
        .sort((a: Date, b: Date) => (dayjs(a).isAfter(b) ? 1 : -1)),
    );
  }, [object.events.warnings, remindersValue, dueDateValue]);

  useEffect(() => {
    if (!reminderActive) {
      if (onDeleteReminder) {
        onDeleteReminder(null, true);
      } else {
        reminderSortedArray.forEach((reminder) => {
          dispatch(deleteWarningDate({ objectId, warning: reminder }));
        });
      }
    }
  }, [reminderActive]);

  const handleEditOverdue = (dueDate: Date) => {
    setDueDate(dueDate);

    if (dueDate) {
      onChangeDueDate
        ? onChangeDueDate(dueDate)
        : dispatch(editOverdue({ objectId, overdue: dueDate.toISOString() }));
    } else {
      onDeleteDueDate
        ? onDeleteDueDate()
        : dispatch(deleteOverdue({ objectId, overdue: undefined }));
    }
  };

  const handleReminderChange = (reminder: Date, oldReminder?: Date) => {
    if (reminder) {
      onChangeReminder
        ? onChangeReminder(reminder)
        : dispatch(saveDueDate({ objectId, warning: reminder.toISOString() }));
    } else if (oldReminder) {
      onDeleteReminder
        ? onDeleteReminder(oldReminder)
        : dispatch(deleteWarningDate({ objectId, warning: oldReminder.toISOString() }));
    }
  };

  const handleToggleSwitch = () => {
    setReminderActive(!reminderActive);
  };

  const renderUneditableContent = () => {
    return (
      <div className={styles.uneditableRoot}>
        <div className={styles.labelsWrapper}>
          <FormattedMessage id="settings.general.dueDate" />
          {reminderSortedArray.length > 0 && <FormattedMessage id="REMINDER" />}
        </div>
        <div className={styles.valuesWrapper}>
          {object.events.due ? (
            <FormattedDate date={object.events.due} />
          ) : (
            <div className={styles.unset}>
              <FormattedMessage id="NO_DUE_DATE_SET" />
            </div>
          )}
          {reminderSortedArray.map((reminder) => (
            <FormattedDate date={reminder} />
          ))}
        </div>
      </div>
    );
  };

  const renderEditableContent = () => {
    return (
      <div className={styles.editableRoot}>
        <div className={styles.labeledContent}>
          {showLabels && (
            <div className={styles.labelsWrapper}>
              <div className={styles.label}>
                <FormattedMessage id="settings.general.dueDate" />
              </div>
              <FormattedMessage id="REMINDER" />
            </div>
          )}
          <div className={cx(styles.valuesWrapper, { [styles.large]: size === 'large' })}>
            <div>
              <DatePicker
                size={size}
                placeholder="Select Option"
                selectedDate={dueDate ? new Date(dueDate) : undefined}
                onChange={handleEditOverdue}
                testId="due-date-datepicker"
              />
            </div>
            <Switch
              disabled={!dueDate}
              active={!!dueDate && reminderActive}
              onChange={handleToggleSwitch}
              size={'medium'}
              labelPlacement={'left'}
              testId="due-date-reminder-toggle"
            />
          </div>
        </div>
        {reminderActive && (
          <div className={styles.reminderList}>
            {reminderSortedArray.map((reminder, index) => (
              <div>
                <DatePicker
                  size={size}
                  key={`reminder-${reminder}`}
                  placeholder={intl.formatMessage({ id: 'SET_A_REMINDER' })}
                  selectedDate={reminder}
                  onChange={(newReminder: Date) => {
                    handleReminderChange(newReminder, reminder);
                  }}
                  filterDate={[
                    {
                      filterType: 'before',
                      filterDate: dueDate,
                    },
                    {
                      filterType: 'same',
                      filterDate: reminderSortedArray[0],
                    },
                  ]}
                  testId={`${index}-reminder-datepicker`}
                />
              </div>
            ))}
            {reminderSortedArray.length < MAX_REMINDERS && reminderActive && (
              <DatePicker
                size={size}
                placeholder={intl.formatMessage({ id: 'SET_A_REMINDER' })}
                onChange={(date: Date) => {
                  handleReminderChange(date);
                }}
                filterDate={[
                  {
                    filterType: 'before',
                    filterDate: dueDate,
                  },
                ]}
                testId={`${reminderSortedArray.length}-reminder-datepicker`}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  if (canEdit) {
    return renderEditableContent();
  } else {
    return renderUneditableContent();
  }
};

export default DueDateSystem;
