import { FormattedMessage } from 'react-intl';
import SidebarPanel from 'Editor/pages/EditorPage/SidebarComponent/SidebarPanel';
import SidebarHeader from 'Editor/pages/EditorPage/SidebarComponent/SidebarHeader';
import SidebarContent from 'Editor/pages/EditorPage/SidebarComponent/SidebarContent';
import BlockApprovalPanel from './BlockApprovalPanel/BlockApprovalPanel';

const ApproveContentTab = () => {
  return (
    <SidebarPanel testId='approve-content-panel'>
      <SidebarHeader>
        <FormattedMessage id="editor.menu.review.approveContent" />
      </SidebarHeader>
      <SidebarContent>
        <BlockApprovalPanel />
      </SidebarContent>
    </SidebarPanel>
  );
};

export default ApproveContentTab;
