import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router';

import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector, useFocusElementByUrl } from '_common/hooks';
import { PageVisibility } from 'Editor/services';
import EditorManager from 'Editor/services/EditorManager';

import { InstanceService } from '_common/services';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import {
  setDocumentId,
  setLoadingValue,
  getDocumentObject,
  getDocumentInfo,
  selectIsPageLayout,
  setNodeToFocus,
  updateZoomValue,
  setInitiatedWithTasks,
} from 'Editor/redux/EditorStatusSlice';
import { setSidebarPanelTab, setSidebarView } from 'Editor/redux/SidebarSlice';
import { selectIsIEnvision } from 'App/redux/appSlice';
import { setCurrentDocument } from 'App/redux/onboardingSlice';

import { FileUploadLoading, InteractionController, IntlErrorBoundary } from '_common/components';
import OnboardingEditor from 'Editor/components/OnboardingEditor/OnboardingEditor';
import EditorContentLoader from 'Editor/components/EditorContentLoader/EditorContentLoader';

import TopBarWrapper from './TopBarComponent/TopBarWrapper';
import Title from './TopBarComponent/Title/Title';
import Menu from './TopBarComponent/Menu/Menu';
import Toolbar from './TopBarComponent/Toolbar/Toolbar';

import EditorInputComponent from './ContentComponent/EditorInputComponent/EditorInputComponent';
import SidebarComponent from './SidebarComponent/SidebarComponent';
import FooterComponent from './FooterComponent/FooterComponent';
import OnboardingEditorIntegration from 'Editor/components/OnboardingEditor/OnboardingEditorIntegration';

import styles from './EditorPage.module.scss';

const EditorPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const userId = useSelector((state) => state.auth.userId);
  const accounts = useSelector((state) => state.localStorage.accounts);
  const token = accounts[userId]?.token;
  const errorModal = useSelector((state) => state.modals.open.EditorErrorModal);
  const temporaryComment = useSelector((state) => state.editor.comments.insert);
  const documentObject = useSelector(getDocumentObject);
  const sidebar = useSelector((state) => state.editor.sidebar);
  const isUploadingImage = useSelector((state) => state.editor.imageUpload.uploading);
  const nodeToFocus = useSelector((state) => state.editor.status.nodeToFocus);
  const commentsLoaded = useSelector((state) => state.editor.comments.loaded);
  const comments = useSelector((state) => state.editor.comments.order);
  const trackedActions = useSelector((state) => state.editor.trackedActions.order);
  const trackedActionsLoaded = useSelector((state) => state.editor.trackedActions.loaded);
  const tasks = useSelector((state) => state.editor.tasks.order);
  const tasksLoaded = useSelector((state) => state.editor.tasks.loaded);
  const isPageLayout = useSelector(selectIsPageLayout);
  const isEnvision = useSelector(selectIsIEnvision);
  const active = useSelector((state) => state.onboarding.active);
  const started = useSelector((state) => state.onboarding.started);
  const zoomValue = useSelector((state) => state.editor.status.zoom);
  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (tasksLoaded) {
      dispatch(setInitiatedWithTasks(!!tasks.length));
    }
  }, [tasksLoaded]);

  useEffect(() => {
    // Zoom affects all widgets due to absolute positioning, they must be recalculated
    EditorManager.getInstance().updateWidgets();
  }, [zoomValue]);

  useFocusElementByUrl();

  const handleObjectProcessing = ({
    objectId,
    objectType,
  }: {
    objectId: ObjectId;
    objectType: ObjectType;
  }) => {
    timeoutRef.current = window.setTimeout(() => {
      new InstanceService().getObjectStatus(objectType, objectId).then(({ data }) => {
        if (data.status !== 'processing') {
          if (data.id !== id) {
            dispatch(setCurrentDocument({ target: 'editor', id, zoom: zoomValue }));
            window.open(`/editor/${data.id}`, '_self');
          }
        } else {
          handleObjectProcessing({ objectId: data.id, objectType: objectType });
        }
      });
    }, 2500);
  };

  const handleDragStart = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (errorModal) {
      terminateEditorServices();
    }
  }, [errorModal]);

  useEffect(() => {
    dispatch(setDocumentId(id));
    dispatch(setLoadingValue(true));

    document.addEventListener('dragstart', handleDragStart);
    if (errorModal) {
      dispatch(closeAndResetModal('EditorErrorModal'));
    }

    return () => {
      dispatch(setDocumentId(''));
      document.removeEventListener('dragstart', handleDragStart);
      if (EditorManager.getInstance()) {
        EditorManager.getInstance().destroy();
      }
      PageVisibility.getInstance().destroy();
    };
  }, []);

  useEffect(() => {
    if (id && userId && token) {
      let service;
      const request = dispatch(getDocumentInfo({ objectId: id, open: true }));
      request.then(() => {
        service = EditorManager.getInstance();
        service.initializeServices(id, {
          id: userId,
          token: token,
        });

        PageVisibility.getInstance().on('TAB_FOCUS', handlePageFocus);
        PageVisibility.getInstance().on('WINDOW_FOCUS', handlePageFocus);
        PageVisibility.getInstance().start();
      });

      return () => {
        request?.abort();
        terminateEditorServices();
      };
    }
  }, [id, token]);

  useEffect(() => {
    if (nodeToFocus && documentObject && nodeToFocus.documentId === documentObject.id) {
      const { objectType, objectId } = nodeToFocus;
      let objectNotFound = false;
      switch (objectType) {
        case 'comment': {
          if (commentsLoaded) {
            if (comments.includes(objectId)) {
              EditorManager.getInstance().focusComment(objectId);
            } else {
              objectNotFound = true;
            }

            dispatch(setNodeToFocus(undefined));
          }

          break;
        }
        case 'suggestion': {
          if (trackedActionsLoaded) {
            if (trackedActions?.includes(objectId)) {
              EditorManager.getInstance().focusTrackedAction(objectId);
            } else {
              objectNotFound = true;
            }

            dispatch(setNodeToFocus(undefined));
          }

          break;
        }
        case 'task': {
          if (tasksLoaded) {
            if (tasks.includes(objectId)) {
              EditorManager.getInstance().focusTask(objectId);
            } else {
              objectNotFound = true;
            }

            dispatch(setNodeToFocus(undefined));
          }

          break;
        }
        case 'node': {
          if (commentsLoaded && trackedActionsLoaded && tasksLoaded) {
            setTimeout(async () => {
              EditorManager.getInstance()
                .scrollIntoView(objectId)
                .catch((_) => {
                  notify({
                    type: 'error',
                    title: 'ELEMENT_NOT_ACCESSIBLE',
                    message: 'LINK_CANNOT_BE_OPENED_BECAUSE_ELEMENT_DOESNT_EXIST',
                  });
                });

              dispatch(setNodeToFocus(undefined));
            }, 0);
          }
          break;
        }
        default: {
          break;
        }
      }

      if (objectNotFound) {
        notify({
          type: 'error',
          title: 'CARD_TYPE_X_NOT_ACCESSIBLE',
          titleValues: { cardType: objectType.charAt(0).toUpperCase() + objectType.slice(1) },
          message: 'LINK_CANNOT_BE_OPENED_BECAUSE_CARD_TYPE_X_DOESNT_EXIST',
          messageValues: { cardType: objectType },
        });
      }
    }
  }, [
    nodeToFocus,
    tasks,
    tasksLoaded,
    commentsLoaded,
    comments,
    trackedActions,
    trackedActionsLoaded,
    documentObject,
  ]);

  useEffect(() => {
    restoreZoomValue();
  }, [userId, documentObject]);

  useEffect(() => {
    if (id && (active.editor || started.editor) && isEnvision) {
      new InstanceService().getDocumentOnboarding().then(({ data }) => {
        if (data.status === 'active') {
          if (data.id !== id) {
            dispatch(setCurrentDocument({ target: 'editor', id, zoom: zoomValue }));
            window.open(`/editor/${data.id}`, '_self');
          }
        }
        if (data.status === 'processing') {
          handleObjectProcessing({ objectId: data.id, objectType: 'document' });
        }
      });
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [active, started, id, isEnvision]);

  const restoreZoomValue = () => {
    if (userId && documentObject) {
      const zoom = window.localStorage.getItem(`${userId}-${documentObject.id}-zoom`);
      if (zoom) {
        dispatch(updateZoomValue(+zoom));
      }
    }
  };

  const terminateEditorServices = () => {
    document.removeEventListener('dragstart', handleDragStart);
    if (EditorManager.getInstance()) {
      EditorManager.getInstance().terminateServices();
    }
    PageVisibility.getInstance().destroy();
  };

  const handlePageFocus = () => {
    EditorManager.getInstance().restartOnTabFocus(id, {
      id: userId,
      token: token,
    });
  };

  const handleInsertComment = useCallback(() => {
    if (isPageLayout) {
      dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
      dispatch(setSidebarView('REVIEW'));

      EditorManager.getInstance().addTemporaryComment();
    } else if (temporaryComment.inserting && temporaryComment?.reference) {
      EditorManager.getInstance().focusComment(temporaryComment.reference);
    } else {
      if (sidebar.view) {
        if (sidebar.view !== 'REVIEW') {
          dispatch(setSidebarView('REVIEW'));
        }
        if (sidebar.tabs.review !== 'comments') {
          dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
        }
      }

      EditorManager.getInstance().addTemporaryComment();
    }
  }, [sidebar.view, sidebar.tabs.review, temporaryComment, isPageLayout]);

  if (!documentObject || errorModal) {
    return null;
  }
  return (
    <div className={styles.root}>
      <TopBarWrapper>
        <IntlErrorBoundary size="small" margin="1rem 0 0 1rem">
          <InteractionController environment="editor">
            <Title />
          </InteractionController>
        </IntlErrorBoundary>
        <IntlErrorBoundary size="small" margin="1.5rem 0 0 1rem">
          <InteractionController environment="editor">
            <Menu insertComment={handleInsertComment} />
          </InteractionController>
        </IntlErrorBoundary>
        <IntlErrorBoundary size="small" margin="1rem 0 0.5rem 1rem">
          <InteractionController environment="editor">
            <Toolbar />
          </InteractionController>
        </IntlErrorBoundary>
      </TopBarWrapper>
      <div className={styles.contentContainer}>
        <EditorInputComponent insertComment={handleInsertComment} />
        <SidebarComponent />
      </div>
      <FooterComponent />
      {isUploadingImage && <FileUploadLoading />}
      <EditorContentLoader />
      {isEnvision ? <OnboardingEditorIntegration /> : <OnboardingEditor />}
    </div>
  );
};

export default EditorPage;
