import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from 'dodoc-design-system';

import styles from './ErrorView.module.scss';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

type ErrorViewProps = {
  onClick: () => void;
  error: {
    status?: number;
    title?: string;
    message?: string;
  };
};

/**
 * ErrorView
 */
const ErrorView = ({ onClick, error }: ErrorViewProps) => {
  const intl = useIntl();

  const renderErrorIcon = () => {
    let icon: IconTypes['96'];

    switch (error.status) {
      case 403:
        icon = 'NoPermissions';
        break;

      case 400:
      case 404:
        icon = 'NoSearchResults';
        break;

      default:
        icon = 'NoSearchResults';
        break;
    }

    return <Icon size={96} icon={icon} />;
  };

  const renderErrorText = () => {
    let title;
    let subtitle;

    if (error.title && error.message) {
      title = error.title;
      subtitle = error.message;
    } else {
      switch (error.status) {
        case 403:
          title = 'errorView.forbidden.title';
          subtitle = 'errorView.clickToGoBack';
          break;

        case 400:
        case 404:
          title = 'errorView.notFound.title';
          subtitle = 'errorView.clickToGoBack';
          break;

        default:
          title = 'errorView.notFound.title';
          subtitle = 'errorView.clickToGoBack';
          break;
      }
    }

    return (
      <>
        <div className={styles.title} title={intl.formatMessage({ id: title })}>
          <FormattedMessage id={title} />
        </div>
        <div className={styles.subTitle} title={intl.formatMessage({ id: subtitle })}>
          <FormattedMessage id={subtitle} />
        </div>
        {error.message && (
          <div
            className={styles.subTitle}
            title={intl.formatMessage({ id: 'errorView.clickToGoBack' })}
          >
            <FormattedMessage id={'errorView.clickToGoBack'} />
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.root} data-testid="error-view-container">
      <div className={`${styles.circle}`} onClick={onClick}>
        <div className={`${styles.icon}`}>{renderErrorIcon()}</div>
        {renderErrorText()}
      </div>
    </div>
  );
};

export default ErrorView;
