import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from '_common/hooks';

import { AuthService } from '_common/services';
import { listObjects } from '_common/components/Table/TableSlice';

import { Table } from '_common/components';
import Loading from 'Settings/pages/TenantSettingsPage/Users/Loading/Loading';

import Row from '_common/modals/UserManagement/UserTokensModal/TokenList/Row';
import Empty from '_common/modals/UserManagement/UserTokensModal/TokenList/Empty/Empty';

import type { EmptyProps } from '_common/modals/UserManagement/UserTokensModal/TokenList/Empty/Empty';

import styles from './TokenList.module.scss';

const IDENTITY: Table.Identity = 'userManagement/tokens';

const COLUMNS_HEADER: Table.Column[] = [
  {
    id: 'browser',
    labelId: 'BROWSER',
    orderable: true,
  },
  {
    id: 'expires',
    labelId: 'settings.security.expires',
    orderable: true,
  },
  {
    id: 'remove',
    size: 6,
  },
];

type TokenListProps = {
  emptyState: { title: EmptyProps['title']['id']; body: EmptyProps['body']['id'] };
  loadingState: { message: string };
  filters: Request.FilterParams;
};

const TokenList = ({ emptyState, loadingState, filters }: TokenListProps) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.modals.UserTokensModal.user);

  //#region Table object listing handlers
  const fetchObjects = useCallback(
    async (parameters) => {
      const response = await new AuthService().getUserTokens(user.id, {
        ...parameters,
        ...filters,
      });

      return {
        ...response,
        data: {
          nodes: response.data,
          dataStorage: 'tenantTokens',
        },
      };
    },
    [filters],
  );

  useEffect(() => {
    dispatch(
      listObjects({
        identity: IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, []);

  // No useEffectOnUpdate since fetchObjects doesn't have any dependencies
  // Add if dependencies are added to fetchObjects

  //#endregion

  const renderLoadingState = () => {
    return (
      <div className={styles.loading}>
        <Loading message={{ id: loadingState.message }} />
      </div>
    );
  };

  const renderEmptyState = () => {
    return (
      <div className={`${styles.center} ${styles.empty}`}>
        <Empty title={{ id: emptyState.title }} body={{ id: emptyState.body }} />
      </div>
    );
  };

  return (
    <div className={styles.tokenList}>
      <div className={styles.list}>
        <Table
          identity={IDENTITY}
          columns={COLUMNS_HEADER}
          fetchObjects={fetchObjects}
          selectable={false}
          RowComponent={Row}
          renderLoadingState={renderLoadingState}
          renderEmptyState={renderEmptyState}
          renderFooter={() => null}
        />
      </div>
    </div>
  );
};

export default TokenList;
