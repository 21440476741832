import { forwardRef, Ref, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Card, Divider } from 'dodoc-design-system';
import { CardHeaderProps } from 'dodoc-design-system/build/types/Components/Card/CardHeader/CardHeader';

import { useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';

import { InteractionController } from '_common/components';
import type { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';
import { LikeToggle, ReplyList, ReplyToggle } from 'PDF/components/Cards';

import Header from './Header/Header';
import Body from './Body/Body';

import styles from './ViewAnnotationCard.module.scss';

export type ViewAnnotationCardProps = {
  annotation: PDF.Annotation;
  sidebar: boolean;
  testId: string;
};

const ViewAnnotationCardContent = forwardRef(
  ({ annotation, sidebar, testId }: ViewAnnotationCardProps, ref: Ref<HTMLDivElement>) => {
    const pdfManager = usePDFContext();
    const editorRef = useRef<RichTextEditorHandler>(null);

    const [showReplies, setShowReplies] = useState(false);

    const isSelected = useSelector((state) => state.pdf.annotations.selected === annotation.id);

    const { canResolveAnnotation } = usePDFPermissions();

    const handleScrollToAnnotation = () => {
      if (sidebar) {
        pdfManager.selectAnnotation(annotation);
      }
    };

    const handleResolve = async () => {
      try {
        await pdfManager.resolveAnnotation(annotation.pageNumber, annotation.id);
        notify({
          type: 'success',
          title: 'notifications.commentResolve.messageSuccess',
          message: 'THE_COMMENT_WAS_SUCCESSFULLY_RESOLVED',
        });
      } catch (error) {
        throw error;
      }
    };

    const handleToggleReplies = () => {
      setShowReplies(!showReplies);
    };

    const getCardHeaderColor = (): CardHeaderProps['background'] => {
      switch (annotation.state) {
        case 'Cancelled':
          return 'red';
        case 'Completed':
          return 'green';
        default:
          return undefined;
      }
    };

    return (
      <InteractionController environment="dopdf">
        <Card
          sidebar={sidebar}
          selected={isSelected}
          onClick={handleScrollToAnnotation}
          width={sidebar ? '100%' : undefined}
          ref={ref}
          id={`annotation-card-${annotation.id}`}
          testId={`annotation-card-${annotation.id}`}
        >
          <Card.Header size="medium" background={getCardHeaderColor()}>
            <Header annotation={annotation} editorRef={editorRef} testId={testId} />
          </Card.Header>
          {/* Add divider when card header has a non-default color */}
          {getCardHeaderColor() && <Divider margin="0" />}
          <Card.Body>
            <Body annotation={annotation} editorRef={editorRef} testId={testId} />
          </Card.Body>
          <Card.Footer size="large">
            <div className={styles.footer}>
              <LikeToggle
                votes={annotation.votes ?? []}
                pageNumber={annotation.pageNumber}
                annotationId={annotation.id}
                target="annotation"
                testId={testId}
              />
              <ReplyToggle
                repliesCount={annotation.replies?.length ?? 0}
                isToggled={showReplies}
                onToggleReplies={handleToggleReplies}
              />
              <Button
                variant="link"
                size="small"
                margin="0 0 0 auto"
                onClick={handleResolve}
                disabled={!canResolveAnnotation(annotation)}
                testId={`${testId}-resolve-button`}
              >
                <FormattedMessage id="RESOLVE" />
              </Button>
            </div>
          </Card.Footer>
          {showReplies && (
            <ReplyList
              replies={annotation.replies ?? []}
              pageNumber={annotation.pageNumber}
              annotationId={annotation.id}
              testId={`${testId}-replyList`}
            />
          )}
        </Card>
      </InteractionController>
    );
  },
);

export default ViewAnnotationCardContent;
