import { Logger } from '_common/services';
import { ReduxInterface } from 'Editor/services';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { BaseTypedEmitter } from '_common/services/Realtime';

export class EditorDOMRect implements DOMRect {
  x: number = 0;
  y: number = 0;
  width: number = 0;
  height: number = 0;
  static fromRect(rect: { x?: number; y?: number; width?: number; height?: number } = {}) {
    return new this(rect.x ?? 0, rect.y ?? 0, rect.width ?? 0, rect.height ?? 0);
  }

  constructor(x?: number, y?: number, width?: number, height?: number) {
    if (x != null) {
      this.x = x;
    }
    if (y != null) {
      this.y = y;
    }
    if (width != null) {
      this.width = width;
    }
    if (height != null) {
      this.height = height;
    }
  }

  get top() {
    return this.y;
  }

  get right() {
    return this.x + this.width;
  }

  get bottom() {
    return this.y + this.height;
  }

  get left() {
    return this.x;
  }

  intersects(other: EditorDOMRect) {
    if (this.top > other.bottom || other.top > this.bottom) {
      return false;
    }
    return true;
  }

  // But it has a toJSON that does include all the properties.
  toJSON() {
    return {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      top: this.top,
      right: this.right,
      bottom: this.bottom,
      left: this.left,
    };
  }
}

const SCROLL_BUFFER = 100;

export class ScrollManager extends BaseTypedEmitter<Editor.Visualizer.ScrollManagerEvents> {
  private rootElement: HTMLElement | undefined | null;
  private pageElement: HTMLElement | undefined;
  private scrollElement: HTMLElement | undefined;
  private anchorElement?: HTMLElement;
  private running: boolean = false;
  private scrollEventsCount: number = 0;
  private scrollEventsDebounceTimer: any | null = null;
  private editorOffsets: DOMRect | undefined;
  private visibleWindow: EditorDOMRect;

  constructor(pageElement?: HTMLElement) {
    super();
    if (pageElement) {
      this.pageElement = pageElement;
      this.scrollElement = this.getScrollParent(this.pageElement);
      this.rootElement = document.getElementById('EditorRoot');
      if (this.rootElement) {
        this.editorOffsets = this.rootElement.getBoundingClientRect();
      }
      this.setScrollListeners();
    }
    this.visibleWindow = this.getRangeFromBounds(this.editorOffsets);
  }

  private getAllBlockViews() {
    let result = document.querySelectorAll('section-element > *');
    if (!result.length) {
      result = document.querySelectorAll('[ispagenode="true"] > *');
    }
    return Array.from(result);
  }

  private getRangeFromBounds(bounds: DOMRect = new EditorDOMRect()) {
    return EditorDOMRect.fromRect(bounds);
  }

  bindView(pageElement: HTMLElement) {
    if (this.pageElement) {
      this.clearScrollListeners();
    }
    if (pageElement) {
      this.pageElement = pageElement;
      this.scrollElement = this.getScrollParent(this.pageElement);
      this.rootElement = document.getElementById('EditorRoot');
      if (this.rootElement) {
        this.editorOffsets = this.rootElement.getBoundingClientRect();
      }
      this.setScrollListeners();
    }
    this.visibleWindow = this.getRangeFromBounds(this.editorOffsets);
  }

  destroy() {
    this.clearScrollListeners();
    super.destroy();
  }

  get isRunning() {
    return this.running;
  }

  private isBlockView(view: Editor.Visualizer.BaseView) {
    return view.vm !== undefined;
  }

  private getBlockView(nodeId: string) {
    let newAnchor;
    newAnchor = document.getElementById(nodeId) as Editor.Visualizer.BaseView;
    if (!newAnchor) {
      return;
    }
    while (!this.isBlockView(newAnchor)) {
      newAnchor = newAnchor.parentNode as Editor.Visualizer.BaseView;
    }
    return newAnchor;
  }

  private findFirstBlockView() {
    let result = document.querySelectorAll('section-element > *:first-child');
    if (!result.length) {
      result = document.querySelectorAll('[ispagenode="true"] > *:first-child');
    }
    return result.length > 0 ? result[0] : null;
  }

  private findLastBlockView() {
    let result = document.querySelectorAll('section-element > *:last-child');
    if (!result.length) {
      result = document.querySelectorAll('[ispagenode="true"] > *:last-child');
    }
    return result.length > 0 ? result[result.length - 1] : null;
  }

  private getScrollParent(element?: HTMLElement): HTMLElement {
    let overflowY;
    if (element instanceof HTMLElement) {
      overflowY = window.getComputedStyle(element as Element).overflowY;
    }
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

    if (!element) {
      return document.body;
    }
    if (isScrollable && element.scrollHeight >= element.clientHeight) {
      return element;
    }
    if (element.parentElement) {
      return this.getScrollParent(element.parentElement);
    }
    return document.body;
  }

  private setScrollListeners() {
    window.addEventListener('resize', this.scrollListener.bind(this), false);
    this.scrollElement?.addEventListener('scroll', this.scrollListener.bind(this), false);
    this.running = true;
  }

  private clearScrollListeners() {
    this.running = false;
    if (this.scrollEventsDebounceTimer) {
      clearTimeout(this.scrollEventsDebounceTimer);
    }
    window.removeEventListener('resize', this.scrollListener.bind(this));
    this.scrollElement?.removeEventListener('scroll', this.scrollListener.bind(this));
  }

  private scrollListener(e: Event) {
    if (this.scrollEventsCount < 15) {
      if (this.scrollEventsDebounceTimer) {
        clearTimeout(this.scrollEventsDebounceTimer);
      }
      this.scrollEventsDebounceTimer = setTimeout(() => {
        this.scrollEventsCount = 0;
        this.trackAnchor(true);
      }, 50);
    } else {
      if (this.scrollEventsDebounceTimer) {
        clearTimeout(this.scrollEventsDebounceTimer);
      }
      this.scrollEventsCount = 0;
      this.trackAnchor(true);
    }
    this.scrollEventsCount += 1;
  }

  private fetchNextViableAnchor(anchorElement: HTMLElement) {
    let newAnchor: HTMLElement | null = anchorElement;
    const bounding = anchorElement.getBoundingClientRect();
    let elements = this.getAllBlockViews() as HTMLElement[];
    let anchorIndex = elements.indexOf(anchorElement);
    let rect;
    if (this.editorOffsets && bounding.bottom < this.editorOffsets.top) {
      for (let index = anchorIndex + 1; index < elements.length; index++) {
        newAnchor = elements[index];
        rect = newAnchor.getBoundingClientRect();
        if (this.editorOffsets && rect.bottom >= this.editorOffsets.top) {
          // current = current.previousSibling as HTMLElement;
          break;
        }
      }
    } else if (this.editorOffsets && bounding.top > this.editorOffsets.bottom) {
      for (let index = anchorIndex - 1; index >= 0; index--) {
        newAnchor = elements[index];
        rect = newAnchor.getBoundingClientRect();
        if (this.editorOffsets && rect.top < this.editorOffsets.bottom) {
          // current = current.previousSibling as HTMLElement;
          break;
        }
      }
    }
    return newAnchor;
  }

  private trackAnchor(reschedule: boolean) {
    let newAnchor: HTMLElement | null = null;
    if (!this.scrollElement || !this.pageElement) {
      return;
    }
    if (!this.anchorElement) {
      let firstChild = this.findFirstBlockView();
      if (!firstChild) {
        this.emit('ANCHOR_NOT_FOUND');
        return;
      }
      this.anchorElement = firstChild as HTMLElement;
    }
    if (!this.isNodeVisible(this.anchorElement)) {
      newAnchor = this.fetchNextViableAnchor(this.anchorElement) as HTMLElement;
      this.anchorElement = newAnchor;
      this.emit('UPDATED_ANCHOR', this.anchorElement.id);
    }
    this.checkScrollPosition();
  }

  private isNodeVisible(node: HTMLElement, margin: number = 0) {
    let nodeRange = this.getRangeFromBounds(node.getBoundingClientRect());
    return nodeRange.intersects(this.visibleWindow);
  }

  private isAnchorNodeVisible(margin: number = 0) {
    let anchorNode = this.getAnchorNode();
    return this.isNodeVisible(anchorNode, margin);
  }

  getAnchorNode() {
    let anchorNode = this.anchorElement;
    if (this.anchorElement !== undefined && !this.isBlockView(this.anchorElement)) {
      anchorNode = this.getBlockView(this.anchorElement.id);
    }
    if (!anchorNode) {
      anchorNode = this.findFirstBlockView() as HTMLElement;
    }
    return anchorNode;
  }

  setAnchorNode(nodeId?: string) {
    if (!nodeId) {
      return;
    }
    if (this.anchorElement?.id === nodeId) {
      return;
    }
    let newAnchor = this.getBlockView(nodeId);
    if (newAnchor != null && newAnchor !== this.anchorElement) {
      this.anchorElement = newAnchor as HTMLElement;
    }
  }

  scrollIntoView(nodeId?: string, alignOption: string = 'CLOSEST', checkViewport: boolean = true) {
    const node: HTMLElement = DOMUtils.getNode(nodeId, this.pageElement) as HTMLElement;
    if (node && this.rootElement) {
      try {
        let scrollAllowed = true;
        if (checkViewport && node.isInEditorRootViewport()) {
          scrollAllowed = false;
        }

        if (scrollAllowed) {
          const nodeOffsets = DOMUtils.getOffsets(node);

          if (nodeOffsets !== null) {
            const elemRect = node.getBoundingClientRect();
            const editorRect = this.rootElement.getBoundingClientRect();

            const zoomValue = ReduxInterface.getZoomValue();
            const defaultIncrement = 20 * zoomValue;
            const nodeOffsetTop = nodeOffsets.top * zoomValue;
            const nodeOffsetLeft = nodeOffsets.left * zoomValue;

            let scrollToX = 0;
            if (elemRect.right >= editorRect.right) {
              scrollToX = nodeOffsetLeft - this.rootElement.offsetWidth / 2 + node.offsetWidth / 2;
            }

            switch (alignOption) {
              case 'TOP': {
                const scrollToY = nodeOffsetTop - defaultIncrement;
                if (this.rootElement?.scrollTo) {
                  this.rootElement.scrollTo(scrollToX, scrollToY);
                }
                break;
              }

              case 'BOTTOM': {
                const scrollToY =
                  nodeOffsetTop -
                  this.rootElement.offsetHeight +
                  node.offsetHeight +
                  defaultIncrement;
                if (this.rootElement?.scrollTo) {
                  this.rootElement.scrollTo(scrollToX, scrollToY);
                }
                break;
              }

              case 'MID': {
                const scrollToY =
                  nodeOffsetTop - this.rootElement.offsetHeight / 2 + node.offsetHeight / 2;
                if (this.rootElement?.scrollTo) {
                  this.rootElement.scrollTo(scrollToX, scrollToY);
                }
                break;
              }

              case 'CLOSEST': {
                const midElem = elemRect.top + (elemRect.bottom - elemRect.top) / 2;
                const midEditor = editorRect.top + (editorRect.bottom - editorRect.top) / 2;

                if (midElem < midEditor) {
                  const scrollToY = nodeOffsetTop - defaultIncrement;
                  if (this.rootElement?.scrollTo) {
                    this.rootElement.scrollTo(scrollToX, scrollToY);
                  }
                } else {
                  const scrollToY =
                    nodeOffsetTop -
                    this.rootElement.offsetHeight +
                    node.offsetHeight +
                    defaultIncrement;
                  if (this.rootElement?.scrollTo) {
                    this.rootElement.scrollTo(scrollToX, scrollToY);
                  }
                }
                break;
              }

              default: {
                const scrollToY = nodeOffsetTop - defaultIncrement;
                if (this.rootElement?.scrollTo) {
                  this.rootElement.scrollTo(scrollToX, scrollToY);
                }
                break;
              }
            }
          }
        }
      } catch (error) {
        Logger.captureException(error, {
          extra: {
            error,
          },
        });
      }
    }
  }

  scheduleTrackAnchor(force: boolean) {
    this.trackAnchor(force);
  }

  hasOverflow() {
    if (this.pageElement && this.pageElement.lastChild) {
      let { bottom } = (this.pageElement.lastChild as HTMLElement).getBoundingClientRect();
      // let scrollHeight = this.scrollElement?.scrollHeight || 0;
      if (this.editorOffsets) {
        return bottom > Math.round(this.editorOffsets.bottom);
      }
      return false;
    }
    return false;
  }

  getScrollDiffToBottom() {
    if (this.scrollElement) {
      return this.scrollElement.scrollHeight - this.scrollElement.scrollTop;
    }
    return null;
  }

  updateScrollDiff(scrollDiffToBottom: number | null) {
    if (this.scrollElement && scrollDiffToBottom != null) {
      const height = this.scrollElement.scrollHeight;
      this.scrollElement.scrollTo(0, Math.max(1, height - scrollDiffToBottom));
    }
  }

  applyOffsetToScroll(offset: number) {
    if (this.scrollElement) {
      this.scrollElement.scrollTo(0, Math.max(1, this.scrollElement.scrollTop + offset));
    }
  }

  checkScrollPosition() {
    if (this.scrollElement) {
      const scrollTop = this.scrollElement.scrollTop;
      const rootElementHeight = this.editorOffsets?.height || 0;
      const isTop = scrollTop - SCROLL_BUFFER <= 0;
      const isBottom =
        scrollTop + SCROLL_BUFFER + rootElementHeight >= this.scrollElement?.scrollHeight;
      if (isTop && isBottom) {
        if (scrollTop === 0) {
          // prevent sticky top
          this.scrollElement.scrollTop = 1;
        }
        this.emit('REACHED_TOP_BOTTOM');
      } else if (isTop) {
        if (scrollTop === 0) {
          // prevent sticky top
          this.scrollElement.scrollTop = 1;
        }
        this.emit('REACHED_TOP');
      } else if (isBottom) {
        this.emit('REACHED_BOTTOM');
      }
    }
  }
}
