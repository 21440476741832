import { MouseEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Toggle, usePopper } from 'dodoc-design-system';

import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { notify } from '_common/components/ToastSystem';
import { useDispatch } from '_common/hooks';

import { setEditingAnnotation } from 'PDF/redux/PDFAnnotationsSlice';

import type { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';

type OptionsProps = {
  annotation: PDF.Annotation;
  editorRef?: React.Ref<RichTextEditorHandler>;
};

const Options = ({ annotation, editorRef }: OptionsProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const pdfManager = usePDFContext();

  const optionsPopper = usePopper({ placement: 'bottom-start', closeOnReferenceHidden: true });

  const { canEditAnnotation, canDeleteAnnotation, canCopyAnnotation } = usePDFPermissions();

  const handleCopyText = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    //@ts-expect-error
    const editor = editorRef?.current.getCurrent();

    const listener = (e: ClipboardEvent) => {
      if (e.clipboardData) {
        e.clipboardData.setData('text/plain', editor?.textContent);
        e.clipboardData.setData('text/html', editor?.outerHTML);
        e.clipboardData.setData('dodoc/richText', JSON.stringify(annotation.content?.content));
        e.preventDefault();
      }
    };

    if (editor) {
      try {
        window.getSelection()?.removeAllRanges();
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
        notify({
          type: 'success',
          title: intl.formatMessage({ id: 'TEXT_COPIED' }),
          message: intl.formatMessage({ id: 'THE_COMMENT_WAS_SUCCESSFULLY_COPIED' }),
        });
        editor.removeEventListener('copy', listener);
      } catch (e) {
        editor.removeEventListener('copy', listener);
        throw e;
      }
    }
  };

  const handleEditContent = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(setEditingAnnotation({ id: annotation.id, isTask: annotation.subtype === 'Task' }));
  };

  const handleDelete = async (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    try {
      await pdfManager.deleteAnnotation(annotation.pageNumber, annotation.id);
      notify({
        type: 'success',
        title: intl.formatMessage({ id: 'COMMENT_DELETED' }),
        message: intl.formatMessage({ id: 'THE_COMMENT_WAS_SUCCESSFULLY_DELETED' }),
      });
    } catch (e) {
      throw e;
    }
  };

  return (
    <>
      <Toggle
        {...optionsPopper.referenceProps}
        variant="link"
        size="medium"
        icon={optionsPopper.isOpen ? 'PDFOptionsBlue' : 'PDFOptionsGrey'}
        isToggled={optionsPopper.isOpen}
        testId="annotation-options-toggle"
        onClickCapture={(e) => {
          optionsPopper.referenceProps.onClickCapture();
        }}
      />
      <Dropdown {...optionsPopper.popperProps} width="30rem" testId="annotation-options-dropdown">
        <Dropdown.Item
          onClick={handleCopyText}
          disabled={
            !canCopyAnnotation || (annotation.content && annotation.content?.content.length < 1)
          }
          testId="annotation-options-copy-text-item"
        >
          <FormattedMessage id="COPY_TEXT" />
        </Dropdown.Item>

        <Dropdown.Item
          onClick={handleEditContent}
          disabled={!canEditAnnotation(annotation)}
          testId="annotation-options-edit-content-item"
        >
          <FormattedMessage id="global.edit" />
        </Dropdown.Item>

        <Dropdown.Item
          onClick={handleDelete}
          disabled={!canDeleteAnnotation(annotation)}
          testId="annotation-options-delete-item"
        >
          <FormattedMessage id="global.delete" />
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default Options;
