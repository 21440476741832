import { useState, useEffect, useRef, ChangeEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, TextArea } from 'dodoc-design-system';
import type { CardProps } from 'dodoc-design-system/build/types/Components/Card/Card';

import { toRoman } from 'utils';
import { usePublicProfile, useSelector, useDispatch } from '_common/hooks';
import { focusedTemporaryComment } from 'Editor/redux/CommentsSlice';

import { InteractionController, RichTextEditor } from '_common/components';
import EditorAvatar from '../EditorAvatar/EditorAvatar';
import { RichTextEditorProps } from '_common/components/RichTextEditor/RichTextEditor';

import styles from './EditableCard.module.scss';
import { selectCollaborators } from 'App/redux/appSlice';

export type EditableCardProps<
  IsNote extends boolean = boolean,
  EditMode extends boolean = boolean,
> = Partial<Pick<CardProps, 'onClick'>> & {
  name?: string;
  content?: string;
  user: UserId;
  subcomment?: boolean;
  panel?: boolean;
  isNote?: IsNote;
  editMode?: EditMode;
  //Card identifier
  id?: string;
  handleCancelClicked: (editMode?: boolean) => void;
} & (IsNote extends true
    ? { type: 'endnote' | 'footnote'; note: Editor.Note | null; placeholder?: undefined }
    : { type?: undefined; note?: undefined; placeholder: string }) &
  (EditMode extends true
    ? {
        handleSaveClicked: (content?: string) => void;
        handleCreateClicked?: (content?: string) => void;
      }
    : {
        handleSaveClicked?: undefined;
        handleCreateClicked: (content?: string) => void;
      });

const EditableCardContent = <IsNote extends boolean, EditMode extends boolean>({
  id,
  name,
  user,
  subcomment,
  content,
  panel,
  onClick,
  type,
  editMode,
  isNote,
  note,
  placeholder = '',
  handleCancelClicked,
  handleCreateClicked,
  handleSaveClicked,
}: EditableCardProps<IsNote, EditMode>) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const profile = usePublicProfile(user);
  const newCommentInputRef = useRef<HTMLTextAreaElement>(null);

  const shouldFocus = useSelector((state) => state.editor.comments.insert.shouldFocus);
  const collaborators = useSelector((state) =>
    selectCollaborators(state, state.editor.status.documentId),
  );
  const temporaryCommentId = useSelector((state) => state.editor.comments.insert.reference);
  const isTemporaryComment = id?.split('#')?.[1] === temporaryCommentId;

  const [comment, setComment] = useState(content);

  useEffect(() => {
    if (newCommentInputRef.current) {
      newCommentInputRef.current.focus();
    }
  }, []);

  const handleClick: CardProps['onClick'] = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  const handleRichEditorChanged: RichTextEditorProps['onChange'] = (commentValue) => {
    setComment(commentValue);
  };

  const handleNoteValueChanged: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setComment(e.target.value);
  };

  const handleTemporaryCommentFocused = () => {
    if (isTemporaryComment) {
      dispatch(focusedTemporaryComment());
    }
  };

  const renderHeader = () => {
    return (
      <div style={{ display: 'flex' }}>
        <EditorAvatar margin="0 1rem 0 0" userId={user} name={name} />
        <div className={styles.labels}>
          <div className={styles.author}>{profile.name}</div>
          {isNote && (
            <div className={styles.order}>
              {intl.formatMessage({ id: type?.toUpperCase() })}
              {note && ` #${type === 'footnote' ? note.order : toRoman(note.order)}`}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    if (editMode) {
      return (
        <>
          <Button
            variant="link"
            size="small"
            onClick={() => handleCancelClicked(editMode)}
            testId="temporary-card-cancel-button"
          >
            <FormattedMessage id="global.cancel" />
          </Button>
          <Button
            variant="primary"
            size="small"
            onClick={() => handleSaveClicked && handleSaveClicked(comment)}
            disabled={comment === '' || comment === content}
            testId="temporary-card-save-changes-button"
          >
            <FormattedMessage id="SAVE_CHANGES" />
          </Button>
        </>
      );
    }

    return (
      <>
        <Button
          variant="link"
          size="small"
          onClick={() => handleCancelClicked()}
          testId={`temporary-${isNote ? 'note' : 'comment'}-card-create-button`}
        >
          <FormattedMessage id="global.cancel" />
        </Button>
        <InteractionController
          environment="editor"
          rules={[
            {
              interaction: 'editor_sidepanel_newComment',
              actions: ['editor_comments_createComment'],
            },
          ]}
        >
          <Button
            variant="primary"
            size="small"
            onClick={() => handleCreateClicked && handleCreateClicked(comment)}
            disabled={comment === ''}
            testId={`temporary-${isNote ? 'note' : 'comment'}-card-create-button`}
          >
            <FormattedMessage id="global.create" />
          </Button>
        </InteractionController>
      </>
    );
  };

  const renderContent = () => {
    if (editMode && subcomment) {
      return (
        <div className={styles.editSubcomment}>
          <div style={{ display: 'flex' }}>
            <EditorAvatar margin="0 1rem 0 0" userId={user} />
            <div style={{ flex: 1 }}>
              <RichTextEditor
                initialValue={content}
                mentionableUsers={collaborators}
                placeholder={placeholder}
                onChange={handleRichEditorChanged}
                testId="temporary-card-rich-text-editor"
              />
            </div>
          </div>
          <div className={styles.editButtons}>
            <Button
              variant="link"
              size="small"
              onClick={() => handleCancelClicked(editMode)}
              testId="temporary-card-cancel-button"
            >
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button
              variant="primary"
              size="small"
              onClick={() => handleSaveClicked && handleSaveClicked(comment)}
              disabled={comment === '' || comment === content}
              testId="temporary-card-save-changes-button"
            >
              <FormattedMessage id="SAVE_CHANGES" />
            </Button>
          </div>
        </div>
      );
    }

    return (
      <Card
        id={id ?? `${type}-card`}
        testId={id ?? `${type}-card`}
        sidebar={panel}
        selected
        width={panel ? '100%' : '43rem'}
        onClick={handleClick}
      >
        {!subcomment && <Card.Header size="medium">{renderHeader()}</Card.Header>}
        <Card.Body>
          {isNote ? (
            <TextArea
              size="medium"
              name="comment"
              placeholder={intl.formatMessage({
                id:
                  type === 'footnote'
                    ? 'NEW_FOOTNOTE_INPUT_PLACEHOLDER'
                    : 'NEW_ENDNOTE_INPUT_PLACEHOLDER',
              })}
              onChange={handleNoteValueChanged}
              value={comment}
              ref={newCommentInputRef}
              onKeyDown={(e) => {
                if (e.key === 'Escape' || e.code === 'Escape') {
                  handleCancelClicked();
                }
              }}
              testId="temporary-note-card-textarea"
            />
          ) : (
            <InteractionController
              environment="editor"
              rules={[
                {
                  interaction: 'editor_sidepanel_newComment',
                  actions: ['editor_comments_createComment'],
                },
              ]}
            >
              <RichTextEditor
                initialValue={content}
                mentionableUsers={collaborators}
                placeholder={placeholder}
                onChange={handleRichEditorChanged}
                onFocus={handleTemporaryCommentFocused}
                skipFocus={isTemporaryComment && !shouldFocus}
                testId="temporary-comment-card-textarea"
              />
            </InteractionController>
          )}
        </Card.Body>
        <Card.Footer size="large">{renderFooter()}</Card.Footer>
      </Card>
    );
  };

  return renderContent();
};

export default EditableCardContent;
