import { ELEMENTS } from 'Editor/services/consts';
import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import { SelectionUtils } from '_common/DoDOCSelection';
import { EditorDOMElements, EditorDOMUtils } from '../../DOM';
import { EditorRange } from '../EditorRange';
import { JsonRange } from '../JsonRange';

export class EditorSelectionUtils extends SelectionUtils {
  static getJsonRange(): Editor.Selection.JsonRange | null {
    const range = EditorSelectionUtils.getRange();
    if (range) {
      return JsonRange.buildFromDOMRange(range);
    }
    return null;
  }

  static getRange(): Editor.Selection.EditorRange | undefined {
    const selection = SelectionUtils.getSelection();
    if (selection && selection.type !== 'None' && selection.rangeCount > 0) {
      return EditorRange.fromNativeRange(selection.getRangeAt(0));
    }
    return undefined;
  }

  static createNewRange(): Editor.Selection.EditorRange {
    return new EditorRange();
  }

  static setCaret(
    node: Node,
    position: DoDOCSelection.CaretPosition = 'START',
    offset: number = 0,
  ) {
    const range = EditorSelectionUtils.getRange();
    if (range) {
      range.setCaret(node, position, offset);
      EditorSelectionUtils.applyRangeToSelection(range);
    }
  }

  static setSelection(startNode: Node, startOffset: number, endNode: Node, endOffset: number) {
    const range = EditorSelectionUtils.getRange();
    if (range) {
      range.setStart(startNode, startOffset);
      range.setEnd(endNode, endOffset);
      EditorSelectionUtils.applyRangeToSelection(range);
    }
  }

  static getRangeBasedOnSavedMarkers(
    savedRange: DoDOCSelection.DoDOCRange.BoundaryMarkers,
    setCaretOnText: boolean = false,
  ) {
    if (savedRange) {
      const range = EditorSelectionUtils.createNewRange();

      if (savedRange.collapsed && savedRange.markerId) {
        const marker = document.getElementById(savedRange.markerId);
        if (marker) {
          range.setStartAfter(marker);
          range.setEndAfter(marker);
        }
      } else if (savedRange.startMarkerId && savedRange.endMarkerId) {
        const startMarker = document.getElementById(savedRange.startMarkerId);
        const endMarker = document.getElementById(savedRange.endMarkerId);

        if (startMarker) {
          if (startMarker.nextSibling && setCaretOnText) {
            range.setRangeStart(startMarker.nextSibling, 'INSIDE_START');
          } else {
            range.setStartAfter(startMarker);
          }
        }

        if (endMarker) {
          if (endMarker.previousSibling && setCaretOnText) {
            range.setRangeEnd(endMarker.previousSibling, 'INSIDE_END');
          } else {
            range.setEndBefore(endMarker);
          }
        }
      }
      return range;
    }
    return undefined;
  }

  static splitInlineTextElements(
    baseNode: Node,
    container: Node,
    offset: number,
    range: Editor.Selection.EditorRange | undefined = EditorSelectionUtils.getRange(),
  ) {
    if (
      range &&
      baseNode != null &&
      container != null &&
      offset != null &&
      EditorDOMUtils.isClosestTextElementEditable(baseNode) &&
      baseNode.contains(container) &&
      !EditorDOMUtils.closest(container, EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS) &&
      container !== baseNode
    ) {
      let closestText;
      let anchorNode = container;
      let anchorOffset = offset;

      while (
        (closestText = EditorDOMUtils.closest(
          anchorNode,
          EditorDOMElements.INLINE_TEXT_ELEMENTS,
        )) &&
        EditorDOMUtils.parentContainsNode(baseNode, closestText)
      ) {
        if (range.isAtNodeStart(closestText, 'end')) {
          if (closestText.parentNode) {
            anchorNode = closestText.parentNode;
            anchorOffset = Array.from(
              closestText.parentNode.childNodes as NodeListOf<Node>,
            ).indexOf(closestText);
          }
        } else if (range.isAtNodeEnd(closestText, 'start')) {
          if (closestText.parentNode) {
            anchorNode = closestText.parentNode;
            anchorOffset =
              Array.from(closestText.parentNode.childNodes as NodeListOf<Node>).indexOf(
                closestText,
              ) + 1;
          }
        } else {
          const copyingRange = EditorSelectionUtils.createNewRange();
          copyingRange.setStart(anchorNode, anchorOffset);
          copyingRange.setEndAfter(closestText);

          const contents = copyingRange.extractContents();
          const nodes = contents.childNodes;

          if (closestText.parentNode instanceof Element) {
            let i;
            for (i = 0; i < nodes.length; i += 1) {
              const node = nodes[i];
              if (node instanceof Element) {
                node.removeAttribute('id');
                node.removeAttribute('parent_id');
                DOMNormalizer.normalizeTree(node, closestText.parentNode.id);
                EditorDOMUtils.insertNodeAfter(closestText.parentNode, node, closestText);
              }
            }

            anchorNode = closestText.parentNode;
            anchorOffset =
              Array.from(closestText.parentNode.childNodes as NodeListOf<Node>).indexOf(
                closestText,
              ) + 1;
          }
        }
      }

      return {
        anchorNode,
        anchorOffset,
      };
    }

    return {};
  }

  static getElementsFromRange(
    range: Editor.Selection.EditorRange | undefined = EditorSelectionUtils.getRange(),
    elementTAGs = EditorDOMElements.BLOCK_TEXT_ELEMENTS,
    aditionalValidator?: (node: Node, isAncestor: boolean) => boolean,
  ) {
    let nodes: Node[] = [];
    if (range) {
      nodes = range.getNodes([Node.ELEMENT_NODE], (node) => {
        if (aditionalValidator && typeof aditionalValidator === 'function') {
          return elementTAGs.includes(node.nodeName) && !!aditionalValidator(node, false);
        }
        return elementTAGs.includes(node.nodeName);
      });

      const closestElement = EditorDOMUtils.closest(range.commonAncestorContainer, elementTAGs);

      if (closestElement && !nodes.includes(closestElement)) {
        if (aditionalValidator && typeof aditionalValidator === 'function') {
          if (aditionalValidator(closestElement, true)) {
            nodes.push(closestElement);
          }
        } else {
          nodes.push(closestElement);
        }
      }
    }
    return nodes;
  }

  static getSelectedTableElements(
    range: Editor.Selection.EditorRange | undefined = EditorSelectionUtils.getRange(),
  ) {
    if (range) {
      const elements: {
        selectedTable: Editor.Elements.TableElement | null;
        selectedRows: HTMLTableRowElement[];
        selectedRowsIds: string[];
        rowsIndex: number[];
        columnsIndex: number[];
        selectedCells: Editor.Elements.TableCellElement[];
        selectedCellsIds: string[];
      } = {
        selectedTable: null,
        selectedRows: [],
        selectedRowsIds: [],
        rowsIndex: [],
        columnsIndex: [],
        selectedCells: [],
        selectedCellsIds: [],
      };
      // Get TD, TR and TABLE from the anchor node of the current selection
      const startContainer = range.startContainer;
      const td = EditorDOMUtils.closest(
        startContainer,
        ELEMENTS.TableCellElement.TAG,
      ) as Editor.Elements.TableCellElement;
      if (td) {
        const row = td.parentNode as HTMLTableRowElement;
        const table = EditorDOMUtils.closest(
          row,
          ELEMENTS.TableElement.TAG,
        ) as Editor.Elements.TableElement;
        if (table) {
          elements.selectedTable = table;
          // Query the table for the selected TDs
          const cells = table.getSelectedCells();
          // If none, it means only one cell is selected and use the original TD
          if (cells.length === 0) {
            elements.selectedRows = [row];
            elements.selectedRowsIds = [row.id];
            elements.rowsIndex = [row.sectionRowIndex];
            elements.columnsIndex = [td.cellIndex];
            elements.selectedCells = [td];
            elements.selectedCellsIds = [td.id];
            return elements;
          }

          // Use the TDs selected from the data-selected attribute
          cells.forEach((cell) => {
            elements.selectedCells.push(cell);
            elements.selectedCellsIds.push(cell.id);
            if (
              cell.parentNode instanceof HTMLTableRowElement &&
              !elements.selectedRows.includes(cell.parentNode)
            ) {
              elements.selectedRows.push(cell.parentNode);
              elements.selectedRowsIds.push(cell.parentNode.id);
              elements.rowsIndex.push(cell.parentNode.sectionRowIndex);
            }
            if (!elements.columnsIndex.includes(cell.cellIndex)) {
              elements.columnsIndex.push(cell.cellIndex);
            }
          });
          return elements;
        }
      }
    }
    // If no TD and TABLE was found, return null
    return null;
  }
}
