import { useState, useEffect, useRef } from 'react';

import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';
import { useSelector, useOverflowCheck, useDispatch } from '_common/hooks';

import VersionCard from 'Editor/pages/EditorPage/SidebarComponent/VersionsTab/VersionsPanel/VersionCard/VersionCard';

import styles from './VersionsPanel.module.scss';
import { Button } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

const VersionsPanel = () => {
  const loadedVersion = useSelector((state) => state.editor.status.loadedVersion);
  const versions = useSelector(getDocumentObject).lifecycle.versions;
  const objDocument = useSelector(getDocumentObject);
  const dispatch = useDispatch();
  const isOwner =
    objDocument.user_permissions.includes('owner') ||
    objDocument.user_permissions.includes('admin');

  const containerRef = useRef(null);
  const [activeCard, setActiveCard] = useState<number | undefined>(undefined);

  const newVersions = [...versions].reverse();
  const isOverflowed = useOverflowCheck(containerRef, [versions]);

  useEffect(() => {
    if (loadedVersion === undefined || loadedVersion === null || Number.isNaN(loadedVersion)) {
      setActiveCard(undefined);
      const element = document.getElementById('currentVersion');
      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    } else {
      const ind = versions.length - loadedVersion - 1;
      setActiveCard(ind);
      const element = document.getElementById(`${ind}`);
      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }, [loadedVersion]);

  const handleDownloadOriginalFile = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'DOWNLOAD_ORIGINAL_FILE',
          message: 'DOWNLOAD_ORIGINAL_DOCUMENT_FILE_CONFIRMATION',
          confirmButtonTextId: 'DOWNLOAD_ORIGINAL_FILE',
          confirmButtonType: 'primary',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'downloadOriginalFile',
          actionValue: {
            objectId: objDocument.id,
            filename: objDocument.name,
            objectType: objDocument.type,
          },
          headerType: 'information',
          cancelButtonShow: true,
        },
      }),
    );
  };

  return (
    <>
      {objDocument.has_source && isOwner && (
        <Button
          fullWidth
          size="small"
          onClick={handleDownloadOriginalFile}
          testId="version-history-panel-download-original-file-button"
          margin="0 0 3rem 0"
        >
          <FormattedMessage id="DOWNLOAD_ORIGINAL_FILE" />
        </Button>
      )}
      <div className={`${styles.root} ${isOverflowed && styles.resizePanel}`} ref={containerRef}>
        <div className={styles.wrapper}>
          <div className={styles.separatorContainer}>
            <div className={styles.separator} />
          </div>

          <div className={styles.cardsContainer}>
            <VersionCard
              currentVersion
              versions={versions}
              activeCard={activeCard}
              setActiveCard={setActiveCard}
              isOverflowed={isOverflowed}
            />
            {newVersions.map((version, index) => {
              return (
                <VersionCard
                  key={version.creation}
                  versions={versions}
                  index={index}
                  activeCard={activeCard}
                  version={version}
                  setActiveCard={setActiveCard}
                  isOverflowed={isOverflowed}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default VersionsPanel;
