/* eslint-disable class-methods-use-this */
import { mix } from 'mixwith';
import EditorManager from 'Editor/services/EditorManager';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { ELEMENTS } from 'Editor/services/consts';
import EditionMode from '../EditionMode';
import { CommentsEditionHandler } from '../_Common';
export default class DisabledMode extends mix(EditionMode).with(CommentsEditionHandler) {
  constructor(...args) {
    super(...args);

    this.keyDownHandlers = {
      ArrowLeft: this.handleLeftArrow.bind(this),
      ArrowUp: this.handleUpArrow.bind(this),
      ArrowRight: this.handleRightArrow.bind(this),
      ArrowDown: this.handleDownArrow.bind(this),
    };
  }

  /**
   *
   * @param {Event} event
   */
  handleKeyDown(event) {
    if (this.keyDownHandlers[event.key]) {
      this.keyDownHandlers[event.key](event);
    } else {
      // override default mode
      event.preventDefault();
      event.stopPropagation();
    }

    // TODO:
    // handle shortcuts
  }

  /**
   *
   * @param {Event} event
   */
  handleKeyPress(event) {
    // override default mode
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   *
   * @param {Event} event
   */
  handleMouseUp({ event }) {
    // override default mode

    // handle shortcuts
    if ((!this.platform.os.mac && event.ctrlKey) || (this.platform.os.mac && event.metaKey)) {
      const link = DOMUtils.closest(event.target, ELEMENTS.HyperlinkElement.TAG);
      if (link) {
        EditorManager.getInstance().openHyperlink();
      }
    }
  }

  /**
   *
   * @param {Event} event
   */
  handleMouseDown() {
    // override default mode
  }

  /**
   * @description insert node on level 0
   * @param {Node} blockNode
   */
  handleInsertBlockNode() {
    // override default
  }

  /**
   * handle cut event
   * @param {Event} event
   */
  handleCutEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    this.handleCopyEvent(event);
  }

  /**
   * handle paste event
   * @param {Event} event
   */
  handlePasteEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * handle drop event
   * @param {Event} event
   */
  handleDropEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  handleBeforeInputEvent() {
    // override default
  }

  handleInput() {
    // override default
  }

  handleCompositionStart() {
    // override default
  }

  handleCompositionEnd() {
    // override default
  }

  /**
   *
   */
  handleRemoveSelection() {
    // override default
  }
}
