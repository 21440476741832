import { useState, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, Button, DatePicker, InputField } from 'dodoc-design-system';
import { uniq } from 'lodash';

import { useDispatch, useSelector, usePublicProfile } from '_common/hooks';

import { getDocumentObject, selectDocumentUsers } from 'Editor/redux/EditorStatusSlice';
import { selectCollaborators } from 'App/redux/appSlice';

import { RichTextEditor, SearchUser } from '_common/components';

import {
  RichTextEditorHandler,
  RichTextEditorProps,
} from '_common/components/RichTextEditor/RichTextEditor';
import type { UserOption } from '_common/components/SearchUser/SearchUser';

import styles from './EditableTaskCard.module.scss';
import { setSidebarView } from 'Editor/redux/SidebarSlice';
import { setPulseData } from 'App/redux/onboardingSlice';
import { GHOST_USERS } from '_common/services/api/publicProfilesApi';
import { selectTask } from 'Editor/redux/TasksSlice';

export type EditableTaskCardProps = {
  handleCreateTask: (value: {
    description: string;
    dueDate: string | null;
    assignee: string | null;
  }) => void;
  handleCancelClicked: () => void;
  description: string;
  assigneeValue: string;
  dueDateValue: string;
  operation: string;
  isSidebarOpened: boolean;
};

const EditableTaskCardContent = ({
  handleCreateTask,
  handleCancelClicked,
  description,
  assigneeValue,
  dueDateValue,
  operation,
  isSidebarOpened,
}: EditableTaskCardProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const descriptionRef = useRef<RichTextEditorHandler>(null);
  const assignedRef = useRef<HTMLDivElement>(null);
  const dueDateRef = useRef<HTMLDivElement>(null);
  const ctaRef = useRef<HTMLButtonElement>(null);

  const taskSelected = useSelector(selectTask);
  const document = useSelector(getDocumentObject);
  const usersList = useSelector(selectDocumentUsers);
  const initialAssigneeProfile = usePublicProfile(assigneeValue);
  const [loading, setLoading] = useState(false);
  const [taskDescription, setTaskDescription] = useState(description);
  const collaborators = useSelector((state) => selectCollaborators(state, document.id));

  const assigneeInitialValue: UserOption = {
    value: assigneeValue,
    label: assigneeValue ? initialAssigneeProfile.name : '',
    avatar: '',
    description: '',
    username: '',
    name: '',
    is_superuser: false,
    type: 'user',
  };
  const [assignee, setAssignee] = useState<UserOption>(assigneeInitialValue);
  const [dueDate, setDueDate] = useState<string | null>(dueDateValue);
  const [onboardingAssigneeFilled, setOnboardingAssigneeFilled] = useState<boolean>(false);
  const [onboardingDueDateFilled, setOnboardingDueDateFilled] = useState<boolean>(false);

  const [newValue, setNewValue] = useState('');
  useEffect(() => {
    if (isSidebarOpened) {
      dispatch(setSidebarView(null));
    }
  }, []);

  useEffect(() => {
    if (assignee?.value) {
      setOnboardingAssigneeFilled(true);
    }
    if (dueDate) {
      setOnboardingDueDateFilled(true);
    }
    if (!taskDescription) {
      const current = descriptionRef?.current?.getCurrent();
      if (current) {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: {
              top: current.offsetTop,
              left: current.offsetLeft,
              height: current.offsetHeight,
              width: current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: undefined,
          }),
        );
      }
    } else if (!onboardingAssigneeFilled) {
      if (assignedRef?.current) {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: {
              top: assignedRef.current.offsetTop,
              left: assignedRef.current.offsetLeft,
              height: assignedRef.current.offsetHeight,
              width: assignedRef.current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: undefined,
          }),
        );
      }
    } else if (!onboardingDueDateFilled) {
      if (dueDateRef?.current) {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: {
              top: dueDateRef.current.offsetTop,
              left: dueDateRef.current.offsetLeft,
              height: dueDateRef.current.offsetHeight,
              width: dueDateRef.current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: undefined,
          }),
        );
      }
    } else {
      if (ctaRef?.current)
        dispatch(
          setPulseData({
            taskButtonRect: {
              top: ctaRef.current.offsetTop,
              left: ctaRef.current.offsetLeft,
              height: ctaRef.current.offsetHeight,
              width: ctaRef.current.offsetWidth,
            },
            taskInputRect: undefined,
          }),
        );
    }

    return () => {
      dispatch(
        setPulseData({
          taskInputRect: undefined,
        }),
      );
    };
  }, [taskDescription, assignee, dueDate, onboardingAssigneeFilled, onboardingDueDateFilled]);

  const handleTextAreaChanged: RichTextEditorProps['onChange'] = (description) => {
    setTaskDescription(description);
  };

  const handleDueDate = (values: Date | null) => {
    if (values) {
      setDueDate(values.toISOString());
    } else {
      setDueDate(values);
    }
  };

  const handleCreateClicked = () => {
    setLoading(true);
    handleCreateTask({
      description: taskDescription,
      dueDate,
      assignee: assignee ? assignee.value : null,
    });
  };

  const handleInputChanged = () => {
    setNewValue(newValue);
  };

  const users = useMemo(() => {
    if (usersList) {
      if (usersList.find((userId) => userId === GHOST_USERS.davidBean.id)) {
        return uniq([...usersList]).map((id) => ({
          id: id,
          type: 'user',
        }));
      } else {
        return uniq([...document.owners, ...usersList]).map((id) => ({
          id: id,
          type: 'user',
        }));
      }
    }
  }, [document.owners, usersList]);

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        {operation === 'edit'
          ? intl.formatMessage({
              id: 'EDIT_TASK',
            })
          : intl.formatMessage({
              id: 'CREATE_NEW_TASK',
            })}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className={styles.body}>
        <InputField
          required={intl.formatMessage({ id: 'REQUIRED' })}
          label={intl.formatMessage({ id: 'TASK_DESCRIPTION' })}
          testId="create-task-description-field"
        >
          <RichTextEditor
            initialValue={taskDescription}
            placeholder={intl.formatMessage({
              id: 'INSERT_TASK_DESCRIPTION',
            })}
            onChange={handleTextAreaChanged}
            mentionableUsers={collaborators}
            testId="create-task-description-textarea"
            ref={descriptionRef}
          />
        </InputField>
        <InputField label={intl.formatMessage({ id: 'ASSIGNEE' })} testId="assignee-field">
          <div ref={assignedRef}>
            <SearchUser
              value={assignee && assignee.value ? assignee : null}
              onChange={setAssignee}
              width="100%"
              size="medium"
              placeholder={intl.formatMessage({
                id: 'TASK_SELECT_USER',
              })}
              options={users}
              inputValue={newValue}
              onInputChange={handleInputChanged}
              testId="create-task"
            />
          </div>
        </InputField>
        <InputField
          label={intl.formatMessage({ id: 'settings.general.dueDate' })}
          testId="dueDate-field"
        >
          <div ref={dueDateRef}>
            <DatePicker
              filterDate={[
                {
                  filterType: 'after',
                  filterDate: new Date(),
                },
              ]}
              onChange={handleDueDate}
              size="medium"
              placeholder={intl.formatMessage({ id: 'SELECT_DATE' })}
              selectedDate={dueDate ? new Date(dueDate) : undefined}
              testId="create-task-due-date-datepicker"
            />
          </div>
        </InputField>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          size="small"
          onClick={handleCancelClicked}
          margin="0 1rem 0 0"
          testId="create-task-cancel-button"
        >
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          variant="primary"
          size="small"
          onClick={handleCreateClicked}
          disabled={!taskDescription}
          loading={loading}
          testId={`create-task-${operation === 'edit' ? 'save-changes' : 'create'}-button`}
          ref={ctaRef}
        >
          <FormattedMessage id={operation === 'edit' ? 'SAVE_CHANGES' : 'global.create'} />
        </Button>
      </>
    );
  };

  return (
    <Card
      id={operation === 'edit' ? undefined : 'temporaryTaskCard'}
      testId={operation === 'edit' ? `task-${taskSelected?.id}-card` : 'temporaryTaskCard'}
    >
      <Card.Header size="small">{renderHeader()}</Card.Header>
      <Card.Body>{renderBody()}</Card.Body>
      <Card.Footer size="large">{renderFooter()}</Card.Footer>
    </Card>
  );
};

export default EditableTaskCardContent;
