import { Mixin } from 'mixwith';
import { Logger } from '_common/services';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class EnterHandler extends superclass {
      /**
       * selects a function to handle enter based on baseNode
       * @param {ActionContext} actionContext
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleEnterOnCollapsedSelection(actionContext, baseNode, anchorNode, anchorOffset) {
        if (this.selectionManager.isSelectionCollapsed()) {
          // selection collapsed
          if (!baseNode || DOMUtils.isNodeAContainerElement(anchorNode)) {
            // if baseNode is undefined try to fix selection
            if (this.selectionManager.fixSelection()) {
              const selection = EditorSelectionUtils.getSelection();
              baseNode = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);
              anchorNode = selection.anchorNode;
              anchorOffset = selection.anchorOffset;
            }
          }

          const level0Node = baseNode;
          let level0Child = level0Node;
          // if (level0Node.tagName === 'TRACK-INS-ELEMENT') {
          //   level0Child = level0Node.firstChild;
          //   if (anchorNode === level0Node) {
          //     if (anchorOffset === 0) {
          //       this.selectionManager.setCaret(level0Child, 'START');
          //     } else {
          //       this.selectionManager.setCaret(level0Node.lastChild, 'END');
          //     }
          //     anchorNode = EditorSelectionUtils.getSelection().anchorNode;
          //     anchorOffset = EditorSelectionUtils.getSelection().anchorOffset;
          //   }
          // }
          if (anchorNode) {
            if (
              baseNode.tagName === ELEMENTS.ParagraphElement.TAG &&
              this.dataManager.numbering.isListElement(level0Child.id)
            ) {
              // SELECTION IS A LIST and we want to handle a specific case before handling on text element
              this.handleTrackedEnterOnListElement(
                actionContext,
                level0Node,
                level0Child,
                anchorNode,
              );
              // this.handleEnterOnListElement(actionContext, baseNode);
            } else if (DOMUtils.isNodeEditableTextElement(level0Child)) {
              // SELECTION IS A DEFAULT TEXT ELEMENT
              this.handleTrackedEnterOnText(actionContext, level0Node, level0Child, anchorNode);
            } else if (!DOMUtils.isBlockNodeEditable(level0Child)) {
              // SELECTION IS A NON-EDITABLE ELEMENT
              this.handleTrackedEnterOnNonEditableElement(actionContext, level0Node, level0Child);
            } else if (level0Child.tagName === ELEMENTS.FigureElement.TAG) {
              // SELECTION IS A FIGURE
              this.handleTrackedEnterOnFigureElement(
                actionContext,
                level0Node,
                level0Child,
                anchorNode,
                anchorOffset,
              );
            } else if (level0Child.tagName === ELEMENTS.TableElement.TAG) {
              // SELECTION IS A TABLE
              this.handleTrackedEnterOnTableElement(
                actionContext,
                level0Node,
                level0Child,
                anchorNode,
                anchorOffset,
              );
            } else if (DOMUtils.isNodeAContainerElement(baseNode)) {
              this.handleTrackedEnterOnContainerElement(
                actionContext,
                level0Node,
                anchorNode,
                anchorOffset,
              );
            }
          }
        }
        return null;
      }

      /**
       *
       * @param {ActionContext} actionContext
       */
      handleEnterOnMultiSelection(actionContext) {
        actionContext.selectionType = ActionContext.SELECTION.RANGE;
        actionContext.selectionBackwards = this.selectionManager.isSelectionBackwards();

        this.selectionManager.fixNonCollapsedTextSelection({
          suggestionMode: true,
          forceTextAsWrap: true,
        });

        if (this.removeSelectionContent(actionContext)) {
          const selection = EditorSelectionUtils.getSelection();
          const anchorNode = selection.anchorNode;
          const anchorOffset = selection.anchorOffset;
          const level0Node = DOMUtils.findNodeLevel0(this.page, anchorNode);
          this.handleEnterOnCollapsedSelection(actionContext, level0Node, anchorNode, anchorOffset);
        }
      }

      handleTrackedEnterOnContainerElement(actionContext, baseNode, anchorNode, anchorOffset) {
        if (this.selectionManager.isSelectionAtStart(baseNode)) {
          const data = NodeDataBuilder.buildParagraph({
            data: {
              parent_id: baseNode.parentNode.id,
            },
          });
          this.inserBlockNodeOperation(actionContext, data, baseNode, 'BEFORE');
          return;
        } else if (this.selectionManager.isSelectionAtEnd(baseNode)) {
          const data = NodeDataBuilder.buildParagraph({
            data: {
              parent_id: baseNode.parentNode.id,
            },
          });
          this.inserBlockNodeOperation(actionContext, data, baseNode, 'AFTER');

          this.selectionManager.setCaret(DOMUtils.getBlockNode(data.id));
          return;
        } else {
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        }

        const subLevel0Node = DOMUtils.findNodeLevel0(baseNode, anchorNode);
        if (subLevel0Node) {
          this.handleEnterOnCollapsedSelection(
            actionContext,
            subLevel0Node,
            anchorNode,
            anchorOffset,
          );
        }
      }

      /**
       *
       * @param {ActionContext} actionContext
       */
      handleTrackedEnterOnText(
        actionContext,
        level0Node,
        level0Child,
        anchorNode,
        insertAfterNode = true,
      ) {
        this.selectionManager.fixCollapsedTextSelection({ suggestionMode: true });

        // eslint-disable-next-line prefer-const
        let splitData;

        if (level0Node.tagName === ELEMENTS.TrackInsertElement.TAG) {
          splitData = this.splitCollapsedSelection(
            actionContext,
            [ELEMENTS.TrackInsertElement.TAG],
            true,
          );
        } else {
          splitData = this.splitCollapsedSelection(actionContext);
        }

        const { before, after } = splitData;

        if (before) {
          DOMNormalizer.normalizeTree(before, before.parentNode.id);

          // eslint-disable-next-line default-case
          if (EditorDOMUtils.isEmptyElement(before)) {
            // inserts <br /> if the elements are empty after split
            if (
              (!before.previousSibling ||
                before.previousSibling.getAttribute('task') !== before.getAttribute('task')) &&
              after &&
              !EditorDOMUtils.isEmptyElement(after)
            ) {
              before.removeAttribute('task');
            }
          }
        }

        if (after) {
          let nonRepeatable = false;
          if (
            EditorDOMUtils.isEmptyElement(after) ||
            (after.firstChild === after.lastChild &&
              (this.isAddParagraphMarker(after.lastChild) ||
                this.isDeleteParagraphMarker(after.firstChild)))
          ) {
            const baseStyleId = this.dataManager.styles.getBaseStyleId(after.styleId);

            // check for non repeatable paragraph types
            if (
              after.tagName === ELEMENTS.ParagraphElement.TAG &&
              !ELEMENTS.ParagraphElement.REPEATABLE_STYLES.includes(baseStyleId)
            ) {
              nonRepeatable = true;
              after.dataset.styleId = ELEMENTS.ParagraphElement.ELEMENT_TYPE;

              after.clearFormatting();

              const afterChildNodes = after.childNodes;
              let i;
              for (i = 0; i < afterChildNodes.length; i++) {
                if (
                  !this.isAddParagraphMarker(afterChildNodes[i]) &&
                  !this.isDeleteParagraphMarker(afterChildNodes[i])
                ) {
                  after.removeChild(afterChildNodes[i]);
                }
              }
            }

            if (
              !before.nextSibling ||
              before.nextSibling.getAttribute('task') !== after.getAttribute('task')
            ) {
              after.removeAttribute('task');
            }
          }

          if (insertAfterNode) {
            // generate id
            DOMNormalizer.normalizeTree(after, before.parentNode.id);

            if (
              this.dataManager.numbering.isListElement(before.id) &&
              !this.dataManager.numbering.isBlockInOutlineList(before.id) &&
              !nonRepeatable
            ) {
              const listId = this.dataManager.numbering.getListIdFromBlock(before.id);
              const listLevel = this.dataManager.numbering.getListLevelFromBlock(before.id);
              this.dataManager.numbering.addBlocksToList(
                actionContext,
                [after.id],
                listId,
                listLevel,
                before.id,
              );
            }

            const closestContainer = DOMUtils.closest(
              before,
              DOMUtils.MULTI_BLOCK_CONTAINER_ELEMENTS,
            );
            if (closestContainer) {
              DOMUtils.insertNodeAfter(before.parentNode, after, before);
              actionContext.addChangeAddedNode(after);

              const closestTracked = DOMUtils.closest(closestContainer, [
                ELEMENTS.TrackInsertElement.TAG,
                ELEMENTS.TrackDeleteElement.TAG,
              ]);
              if (after.tagName !== ELEMENTS.TrackInsertElement.TAG && !closestTracked) {
                this.insertAddParagraphMarker(actionContext, before, after.id);
              }

              this.selectionManager.setCaret(after, 'INSIDE_START');
            } else {
              const data = NodeDataBuilder.buildNodeData({
                data: {
                  ...this.documentParser.parse(after),
                  parent_id: level0Node.parentNode.id,
                },
              });

              this.inserBlockNodeOperation(actionContext, data, level0Node, 'AFTER');

              if (after.tagName !== ELEMENTS.TrackInsertElement.TAG) {
                this.insertAddParagraphMarker(actionContext, before, data.id);
              }

              this.selectionManager.setCaret(DOMUtils.getBlockNode(data.id), 'INSIDE_START');
            }
          }
        }

        return { before, after };
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} level0Node
       */
      handleTrackedEnterOnNonEditableElement(actionContext, level0Node, level0Child) {
        try {
          if (this.hasEditPermissions()) {
            const contentEditable = level0Child && level0Child.selectableContent;

            if (
              (contentEditable &&
                this.selectionManager.isSelectionAtEnd(contentEditable.lastChild)) ||
              this.selectionManager.isSelectionAtEnd(level0Child)
            ) {
              const data = NodeDataBuilder.buildParagraph({
                data: {
                  parent_id: level0Node.parentNode.id,
                },
              });
              this.inserBlockNodeOperation(actionContext, data, level0Node, 'AFTER');

              this.selectionManager.setCaret(DOMUtils.getBlockNode(data.id));
              return null;
            }

            if (
              (contentEditable &&
                this.selectionManager.isSelectionAtStart(contentEditable.firstChild)) ||
              this.selectionManager.isSelectionAtStart(level0Child)
            ) {
              const data = NodeDataBuilder.buildParagraph({
                data: {
                  parent_id: level0Node.parentNode.id,
                },
              });
              this.inserBlockNodeOperation(actionContext, data, level0Node, 'BEFORE');

              // this.selectionManager.setCaret(p);
              return null;
            }
          }
        } catch (e) {
          Logger.captureException(e);
        }
        return null;
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} level0Node
       * @param {*} anchorNode
       * @param {*} anchorOffset
       */
      handleTrackedEnterOnFigureElement(
        actionContext,
        level0Node,
        level0Child,
        anchorNode,
        anchorOffset,
      ) {
        const closest = DOMUtils.closest(anchorNode, ['IMAGE-ELEMENT', ELEMENTS.FigureElement.TAG]);
        if (closest) {
          if (
            closest?.tagName === 'IMAGE-ELEMENT' ||
            closest?.tagName === ELEMENTS.FigureElement.TAG
          ) {
            let parentNode = level0Node.parentNode;
            let refNode = level0Node;
            if (
              parentNode.tagName === ELEMENTS.TrackDeleteElement.TAG ||
              parentNode.tagName === ELEMENTS.TrackInsertElement.TAG
            ) {
              refNode = parentNode;
              parentNode = this.page;
            }

            if (
              (closest === anchorNode && anchorOffset === 0) ||
              (closest !== anchorNode && !anchorNode.previousSibling)
            ) {
              const closestContainer = DOMUtils.closest(
                refNode,
                DOMUtils.MULTI_BLOCK_CONTAINER_ELEMENTS,
              );
              if (closestContainer) {
                const p = DOMElementFactory.createNewParagraphElement();
                DOMUtils.insertNodeAfter(refNode.parentNode, p, refNode);
                actionContext.addChangeAddedNode(p);
                this.selectionManager.setCaret(p, 'INSIDE_START');
              } else {
                const data = NodeDataBuilder.buildParagraph({
                  data: {
                    parent_id: parentNode.id,
                  },
                });
                this.inserBlockNodeOperation(actionContext, data, refNode, 'BEFORE');
              }
            } else if (
              (closest === anchorNode && anchorOffset === closest.childNodes.length) ||
              (closest !== anchorNode && !anchorNode.nextSibling)
            ) {
              const closestContainer = DOMUtils.closest(
                refNode,
                DOMUtils.MULTI_BLOCK_CONTAINER_ELEMENTS,
              );
              if (closestContainer) {
                const p = DOMElementFactory.createNewParagraphElement();
                DOMUtils.insertNodeAfter(refNode.parentNode, p, refNode);
                actionContext.addChangeAddedNode(p);
                this.selectionManager.setCaret(p, 'INSIDE_START');
              } else {
                const data = NodeDataBuilder.buildParagraph({
                  data: {
                    parent_id: parentNode.id,
                  },
                });
                this.inserBlockNodeOperation(actionContext, data, refNode, 'AFTER');
                this.selectionManager.setCaret(DOMUtils.getBlockNode(data.id));
              }
            }
          }
        }
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} level0Node
       * @param {*} anchorNode
       * @param {*} anchorOffset
       */
      handleTrackedEnterOnTableElement(
        actionContext,
        level0Node,
        level0Child,
        anchorNode,
        anchorOffset,
      ) {
        actionContext.nodeContext = ActionContext.NODE_CONTEXT.TABLE;

        let parentNode = level0Child.parentNode;
        let refNode = level0Child;
        if (
          parentNode.tagName === ELEMENTS.TrackDeleteElement.TAG ||
          parentNode.tagName === ELEMENTS.TrackInsertElement.TAG
        ) {
          refNode = parentNode;
          parentNode = parentNode.parentNode;
        }

        const closest = DOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);
        if (closest && !closest.hasAttribute('lock')) {
          if (closest.tagName === ELEMENTS.TableCellElement.TAG) {
            const row = closest.parentNode;
            const body = row.parentNode;
            const table = body.parentNode;

            if (
              !table.caption &&
              Array.from(row.childNodes).indexOf(closest) === 0 &&
              Array.from(body.childNodes).indexOf(row) === 0 &&
              this.selectionManager.isSelectionAtStart(closest) &&
              (refNode.previousSibling == null ||
                refNode.previousSibling.tagName !== ELEMENTS.ParagraphElement.TAG)
            ) {
              // if no caption and selection it's at start of the first cell and there is no previous sibling
              const data = NodeDataBuilder.buildParagraph({
                data: {
                  parent_id: parentNode.id,
                },
              });
              this.inserBlockNodeOperation(actionContext, data, refNode, 'BEFORE');

              // TODO: temp
              // it will insert an untracked paragraph
              // accept and reject needs to be fixed first
              // this.insertAddParagraphMarker(actionContext, p, refNode.id);
            } else {
              const tdLevel0Node = DOMUtils.findNodeLevel0(closest, anchorNode);
              if (tdLevel0Node && tdLevel0Node !== level0Child) {
                this.handleEnterOnCollapsedSelection(
                  actionContext,
                  tdLevel0Node,
                  anchorNode,
                  anchorOffset,
                );
              }
            }
          }
        } else if (anchorNode.tagName === ELEMENTS.TableElement.TAG) {
          const paragraphData = NodeDataBuilder.buildNodeData({
            data: {
              type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
              parent_id: parentNode.id,
            },
          });
          if (anchorOffset === 0) {
            this.inserBlockNodeOperation(actionContext, paragraphData, refNode, 'BEFORE');
          } else if (anchorOffset === anchorNode.childNodes.length) {
            this.inserBlockNodeOperation(actionContext, paragraphData, refNode, 'AFTER');
            const node = DOMUtils.getNode(paragraphData.id);
            this.selectionManager.setCaret(node, 'INSIDE_START');
          }
        }
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} level0Node
       * @param {*} anchorNode
       * @param {*} anchorOffset
       */
      handleTrackedEnterOnListElement(actionContext, level0Node, level0Child, anchorNode) {
        if (
          level0Node.firstChild === level0Node.lastChild &&
          (this.isAddParagraphMarker(level0Node.firstChild) ||
            this.isDeleteParagraphMarker(level0Node.firstChild)) &&
          !this.dataManager.numbering.isBlockInOutlineList(level0Node.id)
        ) {
          const listElements = [];
          if (level0Node.parentNode !== this.page) {
            const level0 = DOMUtils.findNodeLevel0(this.page, level0Node);

            listElements.push({
              blockId: level0.id,
              id: level0Node.id,
            });
          } else {
            listElements.push({
              blockId: level0Node.id,
              id: level0Node.id,
            });
          }
          this.dataManager.numbering.outdentNodes(actionContext, listElements, true);
          if (
            level0Node.nextSibling &&
            this.dataManager.numbering.isBulletListElement(level0Node.nextSibling.id)
          ) {
            this.dataManager.numbering.restartListNumbering(
              actionContext,
              level0Node.nextSibling.id,
            );
          }
        } else if (
          EditorDOMUtils.isEmptyElement(level0Node) &&
          !this.dataManager.numbering.isBlockInOutlineList(level0Node.id)
        ) {
          this.dataManager.numbering.removeBlocksFromList([level0Node.id]);
          actionContext.jsonChanges = true;
        } else {
          this.handleTrackedEnterOnText(actionContext, level0Node, level0Child, anchorNode);

          // if (DOMUtils.parentContainsNode(this.page, after)) {
          //   const listId = this.persistenceManager.getListIdFromBlock(before.id);
          //   const listLevel = this.persistenceManager.getListLevelFromBlock(before.id);
          //   this.persistenceManager.addBlocksToList(
          //     actionContext,
          //     [after.id],
          //     listId,
          //     listLevel,
          //     before.id,
          //   );
          //   this.selectionManager.setCaret(after, 'START');
          // }
        }
      }
    },
);
