import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import ToolbarDropdown from 'Editor/components/ToolbarDropdown/ToolbarDropdown';

type FontFamilyProps = {
  isReadOnlyMode: boolean;
};

const FontFamily = ({ isReadOnlyMode }: FontFamilyProps) => {
  const intl = useIntl();

  const fontFamily = useSelector((state) => state.editor.toolbar.fontFamily);
  const externalFonts = useSelector((state) => state.editor.fonts.external);
  const defaultFonts = useSelector((state) => state.editor.fonts.default);

  const parseOptions = (options: typeof defaultFonts) => {
    return options.map((option) => {
      if (!option.supported) {
        const newOption = { ...option };
        newOption.suffixIcon = 'MissingFont';
        newOption.fontFamily = option.label as string;
        return newOption;
      }
      return { ...option, fontFamily: option.label as string };
    });
  };

  const options = useMemo(() => {
    return [
      {
        label: intl.formatMessage({ id: 'EXTERNAL_FONTS' }),
        options: parseOptions(externalFonts),
      },
      {
        label: intl.formatMessage({ id: 'DEFAULT_FONTS' }),
        options: parseOptions(defaultFonts),
      },
    ];
  }, [externalFonts, defaultFonts]);

  return (
    <ToolbarDropdown
      isDisabled={isReadOnlyMode}
      options={options}
      onChange={(option: (typeof defaultFonts)[number]) => {
        EditorManager.getInstance().toggleSelectionStyle(
          StylesUtils.STYLES.FONTFAMILY,
          option.label,
        );
      }}
      value={
        fontFamily
          ? options[0].options.find((font) => font.value === fontFamily.toLowerCase()) ||
            options[1].options.find((font) => font.value === fontFamily.toLowerCase())
          : undefined
      }
      width={'21'}
      creatable
      testId="font-family"
    />
  );
};

export default FontFamily;
