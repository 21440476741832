import { Mixin } from 'mixwith';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { notify } from '_common/components/ToastSystem';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class InsertHandler extends superclass {
      // ##########################################################
      //               handle insert block element
      // ##########################################################
      /**
       * @param {ActionContext} actionContext
       * @param {*} blockNode
       */
      handleInsertBlockNodeOnMultiSelection(actionContext, blockNode, level0Node, anchorNode) {
        actionContext.selectionType = ActionContext.SELECTION.RANGE;
        if (!level0Node || DOMUtils.isNodeAContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            level0Node = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
          }
        }

        if (level0Node && this.isBlockNodeInsertionAllowed(blockNode, level0Node, anchorNode)) {
          actionContext.selectionBackwards = this.selectionManager.isSelectionBackwards();

          this.selectionManager.fixNonCollapsedTextSelection({
            suggestionMode: true,
            forceTextAsWrap: true,
          });

          if (this.removeSelectionContent(actionContext)) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
            level0Node = DOMUtils.findNodeLevel0(this.page, anchorNode);
            this.handleInsertBlockNodeOnCollapsedSelection(
              actionContext,
              blockNode,
              level0Node,
              anchorNode,
            );
          }
        } else {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR.ERROR_INSERTING_ELEMENT',
          });
        }
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} blockNode
       * @param {*} level0Node
       * @param {*} anchorNode
       */
      handleInsertBlockNodeOnCollapsedSelection(
        actionContext,
        blockNode,
        level0Node,
        anchorNode,
        reference,
      ) {
        if (!level0Node || DOMUtils.isNodeAContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            level0Node = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
          }
        }

        if (level0Node && this.isBlockNodeInsertionAllowed(blockNode, level0Node, anchorNode)) {
          const selection = EditorSelectionUtils.getSelection();
          const anchorOffset = selection.anchorOffset;

          if (!reference) {
            reference = level0Node;
          }

          if (this.selectionManager.isSelectionCollapsed()) {
            // selection collapsed
            if (selection.anchorNode === this.page) {
              // IF SELECTION IS IN THE PAGE
              // FIX SELECTION
              const anchorNode = selection.anchorNode.childNodes[selection.anchorOffset];
              this.selectionManager.setCaret(anchorNode, 'INSIDE_START');
            }

            if (anchorNode) {
              if (DOMUtils.isNodeEditableTextElement(reference)) {
                // SELECTION IS A DEFAULT TEXT ELEMENT
                return this.insertRootNodeOnText(actionContext, blockNode, level0Node);
              }
              if (!DOMUtils.isBlockNodeEditable(reference)) {
                // SELECTION IS A NON-EDITABLE ELEMENT
                return this.insertRootNodeOnNonEditableElement(
                  actionContext,
                  blockNode,
                  level0Node,
                  reference,
                );
              }
              if (reference.tagName === ELEMENTS.FigureElement.TAG) {
                // SELECTION IS A FIGURE
                return this.insertRootNodeOnFigureElement(
                  actionContext,
                  blockNode,
                  level0Node,
                  reference,
                  anchorNode,
                  anchorOffset,
                );
              }
              if (reference.tagName === ELEMENTS.TableElement.TAG) {
                // SELECTION IS A TABLE
                return this.insertRootNodeOnTableElement(
                  actionContext,
                  blockNode,
                  level0Node,
                  reference,
                  anchorNode,
                  anchorOffset,
                );
              }
              if (DOMUtils.isNodeAContainerElement(reference)) {
                return this.insertRootNodeOnContainerElement(
                  actionContext,
                  blockNode,
                  level0Node,
                  reference,
                  anchorNode,
                  anchorOffset,
                );
              }
            }
          }
        } else {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR.ERROR_INSERTING_ELEMENT',
          });
        }

        return null;
      }

      /**
       *
       * @param {ActionContext} actionContext
       * @param {*} node
       * @param {*} level0Node
       * @param {*} referenceNode
       */
      insertRootNodeOnText(
        actionContext,
        newNode,
        level0Node,
        insertAfterValidator = (before, after) => {
          return (
            after &&
            (before === before.parentNode.lastChild || !EditorDOMUtils.isEmptyElement(after))
          );
        },
      ) {
        let extraBlocksToSplit = [];
        let onlyBaseLevel = false;

        if (level0Node.parentNode.tagName === ELEMENTS.TrackInsertElement.TAG) {
          extraBlocksToSplit = [ELEMENTS.TrackInsertElement.TAG];
          onlyBaseLevel = true;
        }

        const { before, after } = this.splitCollapsedSelection(
          actionContext,
          extraBlocksToSplit,
          onlyBaseLevel,
        );

        const parentNode = before.parentNode;
        if (before) {
          if (insertAfterValidator(before, after)) {
            let afterId = after.id;

            const closestContainer = DOMUtils.closest(
              before,
              DOMUtils.MULTI_BLOCK_CONTAINER_ELEMENTS,
            );
            if (closestContainer) {
              DOMUtils.insertNodeAfter(before.parentNode, after, before);
              actionContext.addChangeAddedNode(after);
            } else {
              const data = NodeDataBuilder.buildNodeData({
                data: {
                  ...this.documentParser.parse(after),
                  parent_id: parentNode.id,
                },
              });
              this.inserBlockNodeOperation(actionContext, data, before, 'AFTER');
              afterId = data.id;
            }

            if (this.dataManager.numbering.isListElement(before.id)) {
              const listId = this.dataManager.numbering.getListIdFromBlock(before.id);
              const listLevel = this.dataManager.numbering.getListLevelFromBlock(before.id);
              this.dataManager.numbering.addBlocksToList(
                actionContext,
                [afterId],
                listId,
                listLevel,
                before.id,
              );
            }
          }

          let appendedBlock;
          if (newNode) {
            appendedBlock = this.insertBlockNodeAfterNode(actionContext, newNode, before);
          }

          let caretPosition = 'END';
          let nodeToSetCaret = appendedBlock;

          if (actionContext.caretPosition) {
            if (actionContext.caretPosition.nextSibling && appendedBlock.nextSibling) {
              nodeToSetCaret = appendedBlock.nextSibling;
            } else if (
              actionContext.caretPosition.previousSibling &&
              appendedBlock.previousSibling
            ) {
              nodeToSetCaret = appendedBlock.previousSibling;
            }
            caretPosition = actionContext.caretPosition.position;
          } else if (
            appendedBlock &&
            (appendedBlock.tagName === ELEMENTS.ParagraphElement.TAG ||
              appendedBlock.tagName === ELEMENTS.TableElement.TAG ||
              appendedBlock.tagName === ELEMENTS.FigureElement.TAG ||
              appendedBlock.tagName === ELEMENTS.TrackInsertElement.TAG)
          ) {
            nodeToSetCaret = appendedBlock;
            caretPosition = 'END';
          } else {
            caretPosition = 'INSIDE_START';
            nodeToSetCaret = appendedBlock.nextSibling;
          }

          this.selectionManager.setCaret(nodeToSetCaret, caretPosition);

          return nodeToSetCaret;
        }
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} node
       * @param {*} level0Node
       * @param {*} referenceNode
       */
      insertRootNodeOnNonEditableElement(actionContext, node, level0Node, referenceNode) {
        if (this.hasEditPermissions()) {
          const contentEditable = referenceNode && referenceNode.selectableContent;

          if (
            contentEditable &&
            this.selectionManager.isSelectionAtEnd(contentEditable.lastChild)
          ) {
            return this.insertBlockNodeAfterNode(actionContext, node, level0Node);
          }

          if (
            contentEditable &&
            this.selectionManager.isSelectionAtStart(contentEditable.firstChild)
          ) {
            return this.insertBlockNodeBeforeNode(actionContext, node, level0Node);
          }
        }
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} node
       * @param {*} level0Node
       * @param {*} referenceNode
       * @param {*} anchorNode
       * @param {*} anchorOffset
       */
      insertRootNodeOnFigureElement(
        actionContext,
        node,
        level0Node,
        referenceNode,
        anchorNode,
        anchorOffset,
      ) {
        if (
          anchorNode.tagName === ELEMENTS.FigureElement.TAG ||
          anchorNode.tagName === 'IMAGE-ELEMENT' ||
          anchorNode.tagName === 'IMG'
        ) {
          if (anchorOffset === 0) {
            return this.insertBlockNodeBeforeNode(actionContext, node, referenceNode);
          }
          if (anchorOffset === anchorNode.childNodes.length) {
            return this.insertBlockNodeAfterNode(actionContext, node, referenceNode);
          }
        }
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} node
       * @param {*} level0Node
       * @param {*} referenceNode
       * @param {*} anchorNode
       * @param {*} anchorOffset
       */
      insertRootNodeOnTableElement(
        actionContext,
        node,
        level0Node,
        referenceNode,
        anchorNode,
        anchorOffset,
      ) {
        const closest = DOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);
        if (closest) {
          if (!closest.hasAttribute('lock')) {
            let baseNode = DOMUtils.findNodeLevel0(closest, anchorNode);
            if (baseNode) {
              if (baseNode === level0Node) {
                return this.insertBlockNodeAfterNode(actionContext, node, level0Node);
              } else {
                return this.handleInsertBlockNodeOnCollapsedSelection(
                  actionContext,
                  node,
                  level0Node,
                  anchorNode,
                  baseNode,
                );
              }
            }
          }
        } else {
          return this.insertBlockNodeAfterNode(actionContext, node, level0Node);
        }
      }

      insertRootNodeOnContainerElement(actionContext, node, level0Node, referenceNode, anchorNode) {
        if (DOMUtils.BLOCK_CONTAINER_ELEMENTS.includes(anchorNode.tagName)) {
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
          }
        }
        if (
          referenceNode.tagName === ELEMENTS.TrackInsertElement.TAG ||
          referenceNode.tagName === ELEMENTS.TrackDeleteElement.TAG
        ) {
          return this.insertBlockNodeAfterNode(actionContext, node, referenceNode);
        } else {
          const subLevel0Node = DOMUtils.findNodeLevel0(referenceNode, anchorNode);
          if (subLevel0Node) {
            return this.handleInsertBlockNodeOnCollapsedSelection(
              actionContext,
              node,
              level0Node,
              anchorNode,
              subLevel0Node,
            );
          }
        }
      }
    },
);
