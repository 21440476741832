import { MouseEventHandler, useEffect, useState } from 'react';
import type { CellMeasurerChildProps } from 'react-virtualized/dist/es/CellMeasurer';

import { useDispatch, useSelector } from '_common/hooks';
import { InstanceService } from '_common/services';
import EditorManager from 'Editor/services/EditorManager';
import {
  navigateToObject,
  navigateToEditor,
  navigateToMyFiles,
  navigateToSpaces,
  navigateToShared,
  navigateToPDF,
  refreshPage,
} from 'router/history';
import { notify } from '_common/components/ToastSystem';

import { addToSelectionQueue } from '_common/components/Table/TableSlice';
import { setInfoPanelOpenValue as setStorageInfoPanelOpen } from 'Storage/pages/StoragePage/StoragePageSlice';
import { setInfoPanelOpenValue as setSpaceInfoPanelOpen } from 'Storage/pages/SpacesListPage/redux/spacesListPageSlice';
import { setInfoPanelOpenValue as setSharedInfoPanelOpen } from 'Shared/redux/SharedListPageSlice';
import { editNotification } from '../NotificationsSlice';
import { getDocumentObject, setNodeToFocus } from 'Editor/redux/EditorStatusSlice';
import { setAnnotationToFocus } from 'PDF/redux/PDFGeneralSlice';

import { IntlErrorBoundary } from '_common/components';
import Header from './Header/Header';
import Body from './Body/Body';

import styles from './NotificationRow.module.scss';

type NotificationRowProps = {
  notificationId: string;
  closePopover: () => void;
  testId: string;
} & Pick<CellMeasurerChildProps, 'measure'>;

const NotificationRow = ({
  notificationId,
  closePopover,
  measure,
  testId,
}: NotificationRowProps) => {
  const dispatch = useDispatch();

  const notification = useSelector((state) => state.notifications.dict[notificationId]);
  const selectionQueue = useSelector((state) => state.table.selectionQueue);
  const isEditor = useSelector((state) => state.editor.status.visible);
  const appData = useSelector((state) => state.app.data);
  const document = useSelector(getDocumentObject);
  const comments = useSelector((state) => state.editor.comments.order);
  const trackedActions = useSelector((state) => state.editor.trackedActions.order);
  const tasks = useSelector((state) => state.editor.tasks.order);
  const pdf = useSelector((state) => state.pdf);
  const pdfId = pdf?.general.pdfId ?? '';

  const [targetObject, setTargetObject] = useState<ObjectParams>();

  const [navigateTo, setNavigateTo] = useState<
    | {
        location: 'myFiles' | 'spaces' | 'shared';
        objectId?: undefined;
      }
    | {
        location: 'folder' | 'space' | 'editor' | 'pdf';
        objectId: ObjectId;
      }
    | null
  >(null);

  useEffect(() => {
    const { target, extra, action } = notification.action;
    let type: ObjectType | null = null;

    if (target && !appData[target]) {
      if (notification.action.collection === 'spaces') {
        type = 'space';
      } else {
        switch (notification.event) {
          case 'deadline_reminder':
          case 'deadline_24_reminder':
          case 'deadline_expired': {
            type = (extra as ApiSchemas['EditActionExtra']).type;
            break;
          }
          case 'status_changed': {
            type = (extra as ApiSchemas['StatusChangeActionExtra']).type;
            break;
          }
          case 'permissions_updated':
          case 'shared': {
            type = (extra as ApiSchemas['ObjectPermissionActionExtra']).type;
            break;
          }
          case 'added_to_container':
          case 'removed_from_container': {
            if (action === 'imported') {
              type = 'document';
            } else {
              type = (extra as ApiSchemas['CreateActionExtra']).type;
            }
            break;
          }
          default: {
            type = 'document';
            break;
          }
        }
      }

      if (type) {
        setTargetObject({ objectId: target, objectType: type });
      }
    }
  }, [notification]);

  useEffect(() => {
    if (navigateTo) {
      switch (navigateTo.location) {
        case 'myFiles': {
          navigateToMyFiles(isEditor);
          break;
        }
        case 'shared': {
          navigateToShared(isEditor);
          break;
        }
        case 'spaces': {
          navigateToSpaces(isEditor);
          break;
        }
        case 'editor': {
          navigateToEditor(navigateTo.objectId);
          break;
        }
        case 'folder':
        case 'space': {
          navigateToObject(navigateTo.location, navigateTo.objectId, isEditor);
          break;
        }
        case 'pdf': {
          navigateToPDF(navigateTo.objectId);
          break;
        }
        default: {
          break;
        }
      }

      closePopover();
    }
  }, [selectionQueue, navigateTo, isEditor]);

  const markNotificationAsRead = () => {
    dispatch(editNotification({ id: notificationId, parameters: { read: true } }));
  };

  const navigateToObjectLocation = async ({
    objectType,
    objectId,
    documentId,
  }:
    | {
        objectType: 'file' | 'folder' | 'document' | 'space' | 'dopdf';
        objectId: ObjectId;
        documentId?: undefined;
      }
    | {
        objectType: 'comment' | 'suggestion' | 'task' | 'node' | 'annotation';
        objectId: ObjectId;
        documentId: ObjectId;
      }) => {
    const objectToGetPath = documentId || objectId;

    try {
      switch (objectType) {
        case 'dopdf':
        case 'folder':
        case 'file':
        case 'document': {
          const response = await getObjectPath(objectToGetPath);
          const { data } = response;

          //@ts-expect-error Missing endpoint type "/api/object/${id}/path"
          const totalParents = data.parents.length;

          //Shared element
          if (totalParents === 0) {
            dispatch(addToSelectionQueue({ identity: 'shared', objectId }));
            dispatch(setSharedInfoPanelOpen(true));
            setNavigateTo({ location: 'shared' });
          } else {
            if (totalParents === 1) {
              //My files element
              //@ts-expect-error Missing endpoint type "/api/object/${id}/path"
              if (data.parents[totalParents - 1].personal) {
                dispatch(addToSelectionQueue({ identity: 'storage', objectId }));

                setNavigateTo({ location: 'myFiles' });
              } else {
                dispatch(addToSelectionQueue({ identity: 'storage', objectId }));
                //@ts-expect-error Missing endpoint type "/api/object/${id}/path"
                //Shared element
                if (data.parents[totalParents - 1].type === 'folder') {
                  dispatch(addToSelectionQueue({ identity: 'storage', objectId }));
                  dispatch(setSharedInfoPanelOpen(true));
                  setNavigateTo({
                    location: 'folder',
                    //@ts-expect-error Missing endpoint type "/api/object/${id}/path"
                    objectId: data.parents[totalParents - 1].id,
                  });
                }
                //Space element
                else {
                  setNavigateTo({
                    location: 'space',
                    //@ts-expect-error Missing endpoint type "/api/object/${id}/path"
                    objectId: data.parents[totalParents - 1].id,
                  });
                }
              }
            }
            //Storage element
            else {
              dispatch(addToSelectionQueue({ identity: 'storage', objectId }));
              //@ts-expect-error Missing endpoint type "/api/object/${id}/path"
              setNavigateTo({ location: 'folder', objectId: data.parents[totalParents - 1].id });
            }
            dispatch(setStorageInfoPanelOpen(true));
          }

          break;
        }
        case 'space': {
          await getObjectData(objectId, 'space');
          dispatch(addToSelectionQueue({ identity: 'spaces', objectId }));
          dispatch(setSpaceInfoPanelOpen(true));
          setNavigateTo({ location: 'spaces' });
          break;
        }
        case 'annotation':
        case 'comment':
        case 'suggestion':
        case 'task':
        case 'node': {
          await getObjectPath(documentId);
          if (isEditor && documentId === document?.id) {
            let objectNotFound = false;
            if (objectType === 'comment') {
              if (comments.includes(objectId)) {
                EditorManager.getInstance().focusComment(objectId);
              } else {
                objectNotFound = true;
              }
            } else if (objectType === 'suggestion') {
              if (trackedActions.includes(objectId)) {
                EditorManager.getInstance().focusTrackedAction(objectId);
              } else {
                objectNotFound = true;
              }
            } else if (objectType === 'task') {
              if (tasks.includes(objectId)) {
                EditorManager.getInstance().focusTask(objectId);
              } else {
                objectNotFound = true;
              }
            } else if (objectType === 'node') {
              EditorManager.getInstance()
                .scrollIntoView(objectId)
                .catch((_) => {
                  notify({
                    type: 'error',
                    title: 'ELEMENT_NOT_ACCESSIBLE',
                    message: 'LINK_CANNOT_BE_OPENED_BECAUSE_ELEMENT_DOESNT_EXIST',
                  });
                });
            }
            if (objectNotFound) {
              notify({
                type: 'error',
                title: 'CARD_TYPE_X_NOT_ACCESSIBLE',
                titleValues: { cardType: objectType.charAt(0).toUpperCase() + objectType.slice(1) },
                message: 'LINK_CANNOT_BE_OPENED_BECAUSE_CARD_TYPE_X_DOESNT_EXIST',
                messageValues: { cardType: objectType },
              });
            }
            closePopover();
          } else if (pdf && pdfId) {
            let objectNotFound = false;
            if (pdf.annotations.ids.includes(objectId)) {
              if (
                (objectType === 'comment' || objectType === 'annotation') &&
                (pdf.annotations.byId[objectId].state === 'Completed' ||
                  pdf.annotations.byId[objectId].state === 'Cancelled')
              ) {
                objectNotFound = true;
              } else {
                pdfManager?.selectAnnotation(pdf.annotations.byId[objectId]);
              }
            } else {
              objectNotFound = true;
            }

            if (objectNotFound) {
              notify({
                type: 'error',
                title: 'CARD_TYPE_X_NOT_ACCESSIBLE',
                titleValues: { cardType: objectType.charAt(0).toUpperCase() + objectType.slice(1) },
                message: 'LINK_CANNOT_BE_OPENED_BECAUSE_CARD_TYPE_X_DOESNT_EXIST',
                messageValues: { cardType: objectType },
              });
            }
            closePopover();
          } else {
            if (
              //@ts-expect-error extra missing type
              notification.action?.extra?.type !== 'dopdf' ||
              appData[documentId]?.type === 'document'
            ) {
              //@ts-expect-error can have type annotation
              dispatch(setNodeToFocus({ objectType, objectId, documentId }));
              navigateToEditor(documentId);
            } else if (
              //@ts-expect-error extra missing type
              notification.action?.extra?.type === 'dopdf' ||
              appData[documentId].type === 'dopdf'
            ) {
              dispatch(setAnnotationToFocus({ objectId, documentId }));
              navigateToPDF(documentId);
            }
          }
          break;
        }

        default: {
          break;
        }
      }
    } catch (_) {
      closePopover();
    }
  };

  const getObjectPath = async (objectId: ObjectId) => {
    try {
      const data = await new InstanceService({
        errorsExpected: [404, 403],
      }).listObjectPath(objectId);

      return data;
    } catch (e) {
      notify({
        type: 'error',
        title: 'ELEMENT_NOT_ACCESSIBLE',
        message: 'LINK_CANNOT_BE_OPENED_BECAUSE_ELEMENT_NOT_ACCESSIBLE',
      });

      throw e;
    }
  };

  const getObjectData = async (objectId: ObjectId, objectType: 'space') => {
    try {
      const data = await new InstanceService({ errorsExpected: [403, 404] }).getObjectData({
        objectId,
        objectType,
      });

      return data;
    } catch (e) {
      notify({
        type: 'error',
        title: 'ELEMENT_NOT_ACCESSIBLE',
        message: 'LINK_CANNOT_BE_OPENED_BECAUSE_ELEMENT_NOT_ACCESSIBLE',
      });

      throw e;
    }
  };

  const handleNotificationClick: MouseEventHandler<HTMLDivElement> = async () => {
    const { action, extra, target } = notification.action;

    if (target) {
      switch (notification.event) {
        //#region Element deadline
        case 'deadline_reminder':
        case 'deadline_24_reminder':
        case 'deadline_expired': {
          const type = (notification.action.extra as ApiSchemas['EditActionExtra'])?.type;
          navigateToObjectLocation({ objectType: type, objectId: target });
          break;
        }
        //#endregion

        //#region Element status
        case 'status_changed': {
          const type = (extra as ApiSchemas['StatusChangeActionExtra']).type;
          navigateToObjectLocation({ objectType: type, objectId: target });

          break;
        }
        //#endregion

        //#region Element permissions
        case 'shared':
        case 'permissions_updated': {
          const type = (extra as ApiSchemas['ObjectPermissionActionExtra']).type;

          switch (type) {
            case 'folder': {
              try {
                await getObjectPath(target);
                navigateToObject(type, target);
              } catch (_) {}

              closePopover();
              break;
            }
            case 'file': {
              navigateToObjectLocation({ objectType: type, objectId: target });
              break;
            }
            case 'document': {
              if (target === document?.id) {
                refreshPage();
              } else {
                try {
                  await getObjectPath(target);
                  navigateToEditor(target);
                } catch (_) {}
              }
              closePopover();
              break;
            }
            case 'dopdf': {
              if (target === pdfId) {
                refreshPage();
              } else {
                try {
                  await getObjectPath(target);
                  navigateToPDF(target);
                } catch (_) {}
              }
              closePopover();
              break;
            }
            default: {
              if (notification.action.collection === 'spaces') {
                try {
                  await getObjectData(target, 'space');
                  navigateToObject('space', target);
                } catch (_) {}
                closePopover();
              }
              break;
            }
          }
          break;
        }
        //#endregion

        //#region Element updates
        case 'added_to_container':
        case 'removed_from_container': {
          switch (action) {
            case 'created': {
              const type = (extra as ApiSchemas['CreateActionExtra']).type;
              navigateToObjectLocation({ objectType: type, objectId: target });
              break;
            }
            case 'copied':
            case 'moved':
            case 'restored_object': {
              const type = (extra as ApiSchemas['CopyActionExtra']).type;

              navigateToObjectLocation({ objectType: type, objectId: target });
              break;
            }
            case 'imported': {
              navigateToObjectLocation({ objectType: 'document', objectId: target });
              break;
            }
            case 'deleted': {
              const type = notification.action.collection === 'spaces' ? 'space' : 'folder';
              navigateToObjectLocation({ objectType: type, objectId: target });
            }
          }
          break;
        }
        //#endregion

        //#region User mention
        case 'user_mentioned': {
          navigateToObjectLocation({
            objectType: (notification.action.extra as ApiSchemas['UserMentionActionExtra'])
              .card_type,
            documentId: target,
            objectId: (notification.action.extra as ApiSchemas['UserMentionActionExtra']).card,
          });
          break;
        }
        //#endregion

        //#region Document content
        case 'node_status_updated': {
          navigateToObjectLocation({
            objectType: 'node',
            documentId: target,
            objectId: (extra as ApiSchemas['NodeStatusActionExtra']).nodes[0],
          });
          break;
        }
        //#endregion

        //#region Document comments
        case 'comment_created':
        case 'comment_status_updated':
        case 'comment_reply':
        case 'comment_liked':
        case 'comment_priority_changed': {
          navigateToObjectLocation({
            objectType: 'comment',
            documentId: target,
            objectId: (extra as ApiSchemas['CommentActionExtra']).comment,
          });

          break;
        }
        //#endregion

        //#region Document annotations
        case 'annotation_created':
        case 'annotation_status_changed':
        case 'annotation_replied':
        case 'annotation_content_edited':
        case 'annotation_priority_changed': {
          navigateToObjectLocation({
            objectType: 'comment',
            documentId: target,
            objectId: (extra as ApiSchemas['AnnotationActionExtra']).annotation,
          });

          break;
        }
        //#endregion

        //#region Document suggestions
        case 'suggestion_status_updated':
        case 'suggestion_replied':
        case 'suggestion_liked': {
          navigateToObjectLocation({
            objectType: 'suggestion',
            documentId: target,
            objectId: (extra as ApiSchemas['SuggestionActionExtra']).suggestion,
          });
          break;
        }
        //#endregion

        //#region Document tasks
        case 'task_add_watcher':
        case 'task_remove_watcher':
        case 'task_status_changed':
        case 'task_reply_liked':
        case 'task_replied':
        case 'task_assignee_updated':
        case 'task_deadline_reminder':
        case 'task_deadline': {
          navigateToObjectLocation({
            objectType: 'task',
            documentId: target,
            objectId: (extra as ApiSchemas['TaskActionExtra']).task,
          });
          break;
        }
        //#endregion

        default: {
          break;
        }
      }
    }

    markNotificationAsRead();
  };

  return (
    <IntlErrorBoundary>
      <div
        className={styles.root}
        onClick={handleNotificationClick}
        data-testid={`notificationRow_${testId}`}
      >
        <Header notificationId={notificationId} targetObject={targetObject} measure={measure} />
        <Body notificationId={notificationId} targetObject={targetObject} measure={measure} />
      </div>
    </IntlErrorBoundary>
  );
};

export default NotificationRow;
