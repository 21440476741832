export abstract class WidgetViewModel<T extends Editor.Visualizer.WidgetTypes>
  implements Editor.Visualizer.IWidgetViewModel
{
  protected Visualizer: Editor.Visualizer.State;
  protected refView: Editor.Visualizer.WidgetData[T]['view'];
  protected props: Editor.Visualizer.WidgetData[T]['props'];
  protected container: HTMLElement;

  constructor(
    Visualizer: Editor.Visualizer.State,
    refView: Editor.Visualizer.WidgetData[T]['view'],
    props: Editor.Visualizer.WidgetData[T]['props'],
  ) {
    this.Visualizer = Visualizer;
    this.refView = refView;
    this.props = props;
    this.container = document.createElement('div');
    this.container.dataset.viewId = this.refView.id;
  }

  abstract render(): void;

  abstract dispose(): void;

  updateProps(props: Editor.Visualizer.WidgetData[T]['props']) {
    this.props = props;
  }

  getContainer(): HTMLElement {
    return this.container;
  }

  getRefView(): Editor.Visualizer.WidgetData[T]['view'] {
    return this.refView;
  }
}
