import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Table as BaseTable } from 'dodoc-design-system';

import { useSelector, useDispatch, usePublicProfiles } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import {
  useAddPermissionMutation,
  useGetObjectQuery,
  useGetUserGroupsQuery,
  useRemovePermissionMutation,
} from 'App/redux/objectApi';

import Cell from '_common/components/Table2/Cells';
import { PermissionColumn, CollaboratorColumn, RolesColumn, RemoveColumn } from './Columns';

import type { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';

export type TableProps = {
  objectId: ObjectId;
  objectType: ObjectType;
  recursive: boolean;
  showGranular?: boolean;
  maxHeight?: string;
  testId: string;
};

const Table = ({
  objectId,
  objectType,
  recursive,
  showGranular = true,
  maxHeight,
  testId,
}: TableProps) => {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.userId);
  const { data } = useGetObjectQuery(
    { objectId, objectType },
    { skip: !objectId || !objectType, refetchOnMountOrArgChange: true },
  );
  const { userGroups } = useGetUserGroupsQuery(data ? Object.keys(data.permissions['users']) : [], {
    skip: !data || Object.keys(data.permissions['users']).length === 0,
    selectFromResult: (res) => ({ ...res, userGroups: res.data ?? {} }),
  });

  const { profiles } = usePublicProfiles(
    Object.keys(data?.subject_permissions ?? {})?.filter(
      (id) => data?.subject_permissions[id].subject_type === 'user',
    ),
  );
  const publicGroups = useSelector((state) => state.public.groups.profiles);

  const [addPermission] = useAddPermissionMutation();
  const [removePermission] = useRemovePermissionMutation();

  const BASE_COLUMNS = [
    {
      id: 'name',
      header: 'Name',
      width: 264,
      frozen: true,
      flex: true,
    },
    { id: 'roles', header: 'Roles', width: 216, frozen: true, flex: true },
  ];

  const [columns, setColumns] = useState<ColumnProps[]>([]);

  useEffect(() => {
    if (showGranular) {
      setColumns([
        ...BASE_COLUMNS,
        {
          id: 'co_owner',
          header: 'Co-owner',
          width: 96,
        },
        { id: 'access', header: 'Access', width: 80 },
        { id: 'approve', header: 'Approve', width: 80 },
        { id: 'delete', header: 'Delete', width: 64 },
        { id: 'edit', header: 'Edit', width: 48 },
        { id: 'comment', header: 'Comment', width: 88 },
        { id: 'add_permissions', header: 'Add Permissions', width: 128 },
        { id: 'remove_permissions', header: 'Remove Permissions', width: 152 },
      ]);
    } else {
      setColumns([...BASE_COLUMNS]);
    }
  }, [showGranular]);

  const collaborators: { [collaboratorId in string]: Permission.Collaborator } = useMemo(() => {
    const collaborators: { [collaboratorId in string]: Permission.Collaborator } = {};

    const hasPermission = (permission: Permission.SubjectPermission | undefined) => {
      if (permission) {
        const hasGranularPermission = permission.granular;
        const hasRolesPermission = permission.roles && permission.roles?.length > 0;
        const hasGroupsPermission = permission.groups
          ? Object.keys(permission.groups).length > 0
          : false;
        return hasGranularPermission || hasRolesPermission || hasGroupsPermission;
      }
      return false;
    };

    if (data?.subject_permissions) {
      const subject_permissions: Permission.SubjectPermissions = data.subject_permissions;
      const objectCollaborators = [data.owner, ...Object.keys(subject_permissions)];

      objectCollaborators.forEach((collaboratorId, i) => {
        const isOwner = data.owner === collaboratorId;
        const collaborator = isOwner ? undefined : subject_permissions[collaboratorId];
        const collaboratorPermissions: Permission.Collaborator['permissions'] = {
          owner: { code: 'owner', active: isOwner, locked: false, disabled: isOwner },
          access: { code: 'access', active: isOwner, locked: false, disabled: isOwner },
          approve: { code: 'approve', active: isOwner, locked: false, disabled: isOwner },
          delete: { code: 'delete', active: isOwner, locked: false, disabled: isOwner },
          edit: { code: 'edit', active: isOwner, locked: false, disabled: isOwner },
          comment: { code: 'comment', active: isOwner, locked: false, disabled: isOwner },
          add_permission: {
            code: 'add_permission',
            active: isOwner,
            locked: false,
            disabled: isOwner,
          },
          remove_permission: {
            code: 'remove_permission',
            active: isOwner,
            locked: false,
            disabled: isOwner,
          },
        };
        if (collaborator) {
          (
            Object.keys(collaboratorPermissions) as Array<keyof typeof collaboratorPermissions>
          ).forEach((permissionId) => {
            const permission = collaborator.permissions[permissionId];

            if (permission) {
              const hasRolesPermission = permission.roles && permission.roles?.length > 0;
              const hasGroupsPermission = permission.groups
                ? Object.keys(permission.groups).length > 0
                : false;

              if (hasPermission(permission)) {
                collaboratorPermissions[permissionId].active = true;
              }

              if (hasRolesPermission || hasGroupsPermission) {
                collaboratorPermissions[permissionId].locked = true;
              }
            }

            if (permissionId !== 'owner' && hasPermission(collaborator.permissions.owner)) {
              collaboratorPermissions[permissionId].active = true;
              collaboratorPermissions[permissionId].locked = false;
              collaboratorPermissions[permissionId].disabled = true;
            }
          });
          collaborators[collaboratorId] = {
            collaboratorId,
            collaboratorType: collaborator.subject_type,
            isOwner,
            name:
              collaborator.subject_type === 'user'
                ? profiles[collaboratorId]?.name
                : publicGroups[collaboratorId]?.name,
            permissions: collaboratorPermissions,
            order: i,
          };
        } else {
          if (isOwner) {
            collaborators[collaboratorId] = {
              collaboratorId,
              collaboratorType: 'user',
              isOwner,
              name: profiles[collaboratorId]?.name,
              permissions: collaboratorPermissions,
              order: objectCollaborators.length + 1,
            };
          }
        }
      });
    }
    return collaborators;
  }, [data, profiles, publicGroups]);

  //#region Modals
  const handleOpenDeleteLastPermissionModal = (
    collaboratorId: Permission.Collaborator['collaboratorId'],
    collaboratorType: Permission.Collaborator['collaboratorType'],
    code?: Permission.Code,
  ) => {
    const elementName = collaborators?.[collaboratorId]?.name;

    const getDescription = () => {
      if (collaboratorType === 'group') {
        return (
          <FormattedMessage
            values={{
              name: elementName,
              type: data?.type,
            }}
            id="global.modals.delete.lastPermission"
          />
        );
      }

      return (
        <>
          <div>
            <FormattedMessage
              values={{
                name: elementName,
                type: data?.type,
              }}
              id="global.modals.delete.lastPermission"
            />
          </div>
          <div style={{ fontSize: '1.5rem', marginTop: '2.5rem' }}>
            <b>
              <FormattedMessage id="global.modals.delete.note" />
            </b>
            <FormattedMessage
              values={{
                name: elementName,
                type: data?.type,
              }}
              id="global.modals.delete.noteText"
            />
          </div>
        </>
      );
    };

    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'global.modals.delete.title',
          titleValues: {
            name: elementName,
          },
          messageChildren: getDescription(),
          confirmButtonTextId: 'global.delete',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'removeLastPermission',
          actionValue: {
            object: data,
            data,
            element: collaboratorType === 'user' ? 'users' : 'groups',
            permission: code,
            formKey: collaboratorType,
            selectedRow: collaborators[collaboratorId],
            recursive,
          },
          headerType: 'information',
          cancelButtonShow: true,
        },
      }),
    );
  };

  const handleOpenRemoveRemoveModal = (
    collaboratorType: Permission.Collaborator['collaboratorType'],
    code: Permission.Code,
    id?: string,
  ) => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title:
            collaboratorType === 'user'
              ? 'REMOVE_CO_OWNER_PERMISSIONS'
              : 'REMOVE_GROUP_CO_OWNER_PERMISSION',
          titleValues: { groupName: id && publicGroups[id] && publicGroups[id].name },
          message:
            collaboratorType === 'user'
              ? 'REMOVING_CO_OWNER_WILL_NO_LONGER_BE_ABLE_TO_MANAGE_CONFIRM'
              : 'REMOVING_CO_OWNER_FROM_GROUP_WILL_NO_LONGER_BE_ABLE_TO_MANAGE_CONFIRM',
          messageValues: { groupName: id && publicGroups[id] && publicGroups[id].name },
          confirmButtonTextId: 'global.modals.removeButton',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'removePermission',
          actionValue: {
            permission: code,
            userId,
            element: collaboratorType === 'user' ? 'users' : 'groups',
            groupId: id,
            recursive,
          },
          headerType: 'information',
          cancelButtonShow: true,
        },
      }),
    );
  };

  const handleOpenRemoveOwnerModal = (
    collaboratorType: Permission.Collaborator['collaboratorType'],
    code: Permission.CollaboratorPermission,
    collaboratorId: Permission.Collaborator['collaboratorId'],
  ) => {
    const type = collaboratorType === 'user' ? { user: collaboratorId } : { group: collaboratorId };

    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title:
            collaboratorType === 'user'
              ? 'REMOVE_CO_OWNER_PERMISSIONS'
              : 'REMOVE_GROUP_CO_OWNER_PERMISSION',
          titleValues: {
            groupName: collaborators?.[collaboratorId]?.name,
          },
          message:
            collaboratorType === 'user'
              ? 'REMOVING_CO_OWNER_WILL_NO_LONGER_BE_ABLE_TO_MANAGE_CONFIRM'
              : 'REMOVING_CO_OWNER_FROM_GROUP_WILL_NO_LONGER_BE_ABLE_TO_MANAGE_CONFIRM',
          messageValues: {
            groupName: collaborators?.[collaboratorId]?.name,
          },
          confirmButtonTextId: 'global.modals.removeButton',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'removePermission',
          actionValue: {
            userId,
            objectType: data?.type,
            objectId: objectId,
            ...type,
            code,
            recursive,
          },
          headerType: 'error',
          cancelButtonShow: true,
        },
      }),
    );
  };
  //#endregion

  const isLastPermission = (
    collaboratorId: Permission.Collaborator['collaboratorId'],
    collaboratorType: Permission.Collaborator['collaboratorType'],
    clearRoles?: boolean,
  ) => {
    const permissions = Object.keys(data?.subject_permissions[collaboratorId]?.permissions);
    const roles =
      data?.permissions.roles[collaboratorType === 'user' ? 'users' : 'groups']?.[collaboratorId];

    if (permissions.length === 1) {
      let isLastPermission = false;

      permissions.forEach((permissionId) => {
        const permission = data?.subject_permissions[collaboratorId].permissions[permissionId];

        if (permission.granular && !permission.roles && !permission.groups) {
          isLastPermission = true;
        } else if (!permission.granular && permission.roles && !permission.groups) {
          isLastPermission = true;
        } else if (!permission.granular && !permission.roles && permission.groups) {
          isLastPermission = true;
        } else {
          isLastPermission = false;
        }
      });

      return isLastPermission;
    } else {
      let isLastPermission = true;

      permissions.forEach((permissionId) => {
        const permission = data?.subject_permissions[collaboratorId].permissions[permissionId];

        if (
          permission.granular ||
          (permission.roles && permission.roles.length > 1 && !clearRoles) ||
          permission.groups
        ) {
          isLastPermission = false;
          return;
        }
      });

      if (!isLastPermission) {
        return false;
      } else {
        if ((roles && roles.length <= 1) || clearRoles) {
          return true;
        }
      }
    }

    return false;
  };

  const isLastRemovePermission = (collaboratorId: Permission.Collaborator['collaboratorId']) => {
    const groupPermissions = data?.permissions['groups'];

    if (!userGroups[collaboratorId]) {
      return true;
    }

    let hasRemove = true;
    userGroups[collaboratorId].forEach((key) => {
      if (groupPermissions[key] && groupPermissions[key].includes('remove_permission')) {
        hasRemove = false;
      }
    });

    return hasRemove;
  };

  const isMyLastGroupWithPermission = (code: string) => {
    const groupPermissions = data?.permissions['groups'];

    let count = 0;
    if (userGroups) {
      Object.entries(userGroups).forEach(([key, value]) => {
        if (key === userId) {
          value.forEach((groupId) => {
            if (groupPermissions[groupId] && groupPermissions[groupId].includes(code)) {
              count += 1;
            }
          });
        }
      });
    }

    return count === 1;
  };

  const handlePermissionEdit = (
    code: Permission.CollaboratorPermission,
    collaboratorId: Permission.Collaborator['collaboratorId'],
    collaboratorType: Permission.Collaborator['collaboratorType'],
  ) => {
    if (data) {
      const permissionsAux = { ...collaborators[collaboratorId].permissions };

      if (collaboratorType === 'user') {
        if (permissionsAux[code].active) {
          if (isLastPermission(collaboratorId, collaboratorType)) {
            handleOpenDeleteLastPermissionModal(collaboratorId, collaboratorType, code);
          } else if (
            profiles[userId] &&
            //@ts-expect-error Authority Schema issue "UserPublic": doesn't have 'is_admin' (not sure if should)
            (profiles[userId].is_superuser || profiles[userId].is_admin)
          ) {
            removePermission({
              code,
              user: collaboratorId,
              recursive,
              objectId,
              objectType: data.type,
            });
          } else if (
            code === 'remove_permission' &&
            collaboratorId === userId &&
            isLastRemovePermission(collaboratorId)
          ) {
            handleOpenRemoveRemoveModal(collaboratorType, code);
          } else if (
            code === 'owner' &&
            collaboratorId === userId &&
            !permissionsAux['owner'].locked
          ) {
            handleOpenRemoveOwnerModal(collaboratorType, code, collaboratorId);
          } else {
            removePermission({
              code,
              user: collaboratorId,
              recursive,
              objectId,
              objectType: data.type,
            });
          }
        } else {
          addPermission({
            code,
            user: collaboratorId,
            recursive,
            objectId,
            objectType: data.type,
          });
        }
      } else {
        if (permissionsAux[code].active) {
          if (isLastPermission(collaboratorId, collaboratorType)) {
            handleOpenDeleteLastPermissionModal(collaboratorId, collaboratorType, code);
          } else if (
            profiles[userId] &&
            //@ts-expect-error Authority Schema issue "UserPublic": doesn't have 'is_admin' (not sure if should)
            (profiles[userId].is_superuser || profiles[userId].is_admin)
          ) {
            removePermission({
              code,
              group: collaboratorId,
              recursive,
              objectId,
              objectType: data.type,
            });
          } else if (
            code === 'remove_permission' &&
            userGroups[userId] &&
            userGroups[userId].includes(collaboratorId) &&
            isMyLastGroupWithPermission('remove_permission') &&
            (!data.permissions.users[userId] ||
              (data.permissions.users[userId] &&
                !data.permissions.users[userId].includes('remove_permission')))
          ) {
            handleOpenRemoveRemoveModal(collaboratorType, code, collaboratorId);
          } else if (
            code !== 'owner' ||
            (data.permissions.users[userId] && data.permissions.users[userId].includes('owner')) ||
            !isMyLastGroupWithPermission('owner')
          ) {
            removePermission({
              code,
              group: collaboratorId,
              recursive,
              objectId,
              objectType: data.type,
            });
          } else if (userGroups[userId] && userGroups[userId].includes(collaboratorId)) {
            handleOpenRemoveOwnerModal(collaboratorType, code, collaboratorId);
          }
        } else {
          addPermission({
            code,
            group: collaboratorId,
            recursive,
            objectId,
            objectType: data.type,
          });
        }
      }
    }
  };

  //Value of the table
  const value = useMemo(() => {
    return (Object.keys(collaborators) as Array<keyof typeof collaborators>)
      .sort((a, b) => collaborators[b].order - collaborators[a].order)
      .map((collaboratorId) => {
        const collaborator = collaborators[collaboratorId];
        const permissions = collaborator.permissions;

        return {
          id: collaboratorId,
          name: (
            <Cell ellipsis testId={`${objectId}-name-column`}>
              <CollaboratorColumn
                collaboratorId={`${collaboratorId}`}
                collaboratorType={collaborator.collaboratorType}
              />
            </Cell>
          ),
          roles: (
            <Cell testId={`${objectId}-roles-column`}>
              <RolesColumn
                objectId={objectId}
                collaborator={collaborator}
                openDeleteLastPermissionModal={handleOpenDeleteLastPermissionModal}
                isLastPermission={isLastPermission}
                recursive={recursive}
              />
            </Cell>
          ),
          co_owner: (
            <Cell testId={`${objectId}-co_Owner-column`}>
              <PermissionColumn
                objectId={objectId}
                permission={permissions.owner}
                collaborator={collaborator}
                recursive={recursive}
                onChange={handlePermissionEdit}
              />
            </Cell>
          ),
          access: (
            <Cell testId={`${objectId}-access-column`}>
              <PermissionColumn
                objectId={objectId}
                permission={permissions.access}
                collaborator={collaborator}
                recursive={recursive}
                onChange={handlePermissionEdit}
              />
            </Cell>
          ),
          approve: (
            <Cell testId={`${objectId}-approve-column`}>
              <PermissionColumn
                objectId={objectId}
                permission={permissions.approve}
                collaborator={collaborator}
                recursive={recursive}
                onChange={handlePermissionEdit}
              />
            </Cell>
          ),
          delete: (
            <Cell testId={`${objectId}-delete-column`}>
              <PermissionColumn
                objectId={objectId}
                permission={permissions.delete}
                collaborator={collaborator}
                recursive={recursive}
                onChange={handlePermissionEdit}
              />
            </Cell>
          ),
          edit: (
            <Cell testId={`${objectId}-edit-column`}>
              <PermissionColumn
                objectId={objectId}
                permission={permissions.edit}
                collaborator={collaborator}
                recursive={recursive}
                onChange={handlePermissionEdit}
              />
            </Cell>
          ),
          comment: (
            <Cell testId={`${objectId}-comment-column`}>
              <PermissionColumn
                objectId={objectId}
                permission={permissions.comment}
                collaborator={collaborator}
                recursive={recursive}
                onChange={handlePermissionEdit}
              />
            </Cell>
          ),
          add_permissions: (
            <Cell testId={`${objectId}-addPermissions-column`}>
              <PermissionColumn
                objectId={objectId}
                permission={permissions.add_permission}
                collaborator={collaborator}
                recursive={recursive}
                onChange={handlePermissionEdit}
              />
            </Cell>
          ),
          remove_permissions: (
            <Cell testId={`${objectId}-removePermissions-column`}>
              <PermissionColumn
                objectId={objectId}
                permission={permissions.remove_permission}
                collaborator={collaborator}
                recursive={recursive}
                onChange={handlePermissionEdit}
              />
            </Cell>
          ),
          suffix: (
            <RemoveColumn collaborator={collaborator} objectId={objectId} recursive={recursive} />
          ),
        };
      });
  }, [collaborators, objectId]);
  if (!data || value.length <= 0) {
    return null;
  }

  return (
    <BaseTable
      id="PermissionsTable"
      margin="2rem 0 0 0"
      columns={columns}
      value={value}
      flex={false}
      maxHeight={maxHeight}
      testId={testId}
    />
  );
};

export default Table;
