import { useGetObjectQuery } from 'App/redux/objectApi';
import { useParams } from 'react-router';
import { navigateToError403, navigateToError404 } from 'router/history';

const usePDFData = (props?: { open?: boolean }) => {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useGetObjectQuery(
    { objectId: id, objectType: 'dopdf', open: props?.open },
    { skip: !id },
  );
  if (error) {
    // @ts-expect-error error type doesn't seem right
    if (error.status === 404) {
      navigateToError404();
      // @ts-expect-error error type doesn't seem right
    } else if (error.status === 403) {
      navigateToError403();
    }
  }
  if (data) {
    return data;
  }
  return null;
};

export default usePDFData;
