// @ts-expect-error
import { Parser } from 'cssparser/lib/cssparser.js';

export class CSSParser {
  public rules: Editor.Clipboard.Rule[];

  constructor() {
    this.rules = [];
  }

  static parseSimpleAST(ast: Editor.Clipboard.AST): Editor.Clipboard.Rule[] {
    const json = ast.toJSON('simple');

    const rules: Editor.Clipboard.Rule[] = [];
    for (let i = 0; i < json.value.length; i++) {
      const rule: Editor.Clipboard.AstRule = json.value[i];

      let styleName: string | undefined;
      const ruleDeclaration: string | undefined = rule.declarations?.['mso-style-name'];

      if (ruleDeclaration) {
        styleName = ruleDeclaration;
        styleName = styleName.replace(/"/g, '');
        styleName = styleName.replace(/\\/g, '');
      }

      const ruleSelectors = rule.selectors;

      if (ruleSelectors instanceof Array<string>) {
        let newRuleObject: Editor.Clipboard.Rule = {
          styleName,
          selectors: ruleSelectors,
          style: Object.keys(rule.declarations).reduce((declarations, property) => {
            if (Array.isArray(rule.declarations[property])) {
              rule.declarations[property] = rule.declarations[property][0];
            }
            return declarations;
          }, rule.declarations),
        };
        rules.push(newRuleObject);
      }
    }
    return rules;
  }

  parseHTMLStyles(html: HTMLHtmlElement): void {
    const style = html.querySelector('style');
    if (style) {
      this.parseStyleElement(style);
    }
  }

  parseStyleElement(element: HTMLStyleElement): void {
    if (element) {
      const css = element.innerHTML
        .replace('<!--', '')
        .replace('-->', '')
        .replace(/(@.+?})/gs, '');

      const cssParser = new Parser();
      const ast: Editor.Clipboard.AST = cssParser.parse(css);
      const rules = CSSParser.parseSimpleAST(ast);
      this.rules = rules;
    }
  }

  getNodeStyle(node: HTMLElement) {
    let styles = {};
    if (this.rules) {
      for (let i = 0; i < this.rules.length; i++) {
        const rule = this.rules[i];
        if (rule.selectors.some((selector) => node.matches(selector))) {
          styles = { ...styles, ...rule.style };
        }
      }
      return styles;
    }
    return null;
  }
}
