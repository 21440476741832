import { ProgressBar, Icon } from 'dodoc-design-system';
import { IconSizes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

import { FormattedMessage } from 'react-intl';

import styles from './Loading.module.scss';

type LoadingProps = IconSizes & {
  message: TranslationMessage;
};

const Loading = ({ message, ...iconProps }: LoadingProps) => {
  const renderIcon = () => {};

  return (
    <div className={styles.loading}>
      {iconProps.icon && (
        <span className={styles.icon}>
          {renderIcon()}
          <Icon {...iconProps} />
        </span>
      )}

      <ProgressBar
        color="primaryLight"
        size="medium"
        testId="import-new-version-importing-progressBar"
      />

      <span className={styles.message}>
        <FormattedMessage id={message.id} values={message.values} />
      </span>
    </div>
  );
};

Loading.defaultProps = {
  size: 96,
};

export default Loading;
