import BaseController from '../BaseController';

export class HistoryController extends BaseController {
  start(documentId: string): void {}

  applyChangeAction(action: { patch: { updatedOps: any } }) {
    try {
      const nodeUpdateOps = action.patch.updatedOps;
      const nodeUpdateKeys = Object.keys(nodeUpdateOps);
      if (nodeUpdateKeys.length > 0) {
        for (let i = 0; i < nodeUpdateKeys.length; i++) {
          const nodeId = nodeUpdateKeys[i];
          this.Data.nodes?.applyOpsToNode(nodeId, nodeUpdateOps[nodeId]);
        }
      }
    } catch (error) {
      logger.captureException(error);
    }
  }

  get hooks() {
    return this.Data.models?.undoManager?.hooks;
  }

  get manager() {
    return this.Data.models?.undoManager;
  }

  undo() {
    return this.Data.models?.undoManager?.undo();
  }

  redo() {
    return this.Data.models?.undoManager?.redo();
  }

  canUndo() {
    return this.Data.models?.undoManager?.canUndo();
  }

  canRedo() {
    return this.Data.models?.undoManager?.canRedo();
  }

  createPatch() {
    return this.Data.models?.undoManager?.createPatch();
  }

  stop(): void {}

  destroy(): void {}
}
