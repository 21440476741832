import { FormattedMessage, useIntl } from 'react-intl';
import { DatePicker, RadioButton } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { CreationDate, setAnnotationsFilter } from 'PDF/redux/PDFAnnotationsSlice';

import styles from '../Filters.module.scss';

const CreationDateFilter = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.pdf.annotations.filters);

  const CREATION_DATE_OPTIONS: { label: string; id: CreationDate }[] = [
    { label: intl.formatMessage({ id: 'LAST_WEEK' }), id: 'last_week' },
    { label: intl.formatMessage({ id: 'LAST_MONTH' }), id: 'last_month' },
    { label: intl.formatMessage({ id: 'NUMBER_MONTH_AGO' }, { number: 3 }), id: '3_month_ago' },
    { label: intl.formatMessage({ id: 'NUMBER_MONTH_AGO' }, { number: 6 }), id: '6_month_ago' },
    { label: intl.formatMessage({ id: 'SPECIFIC_DATE_RANGE' }), id: 'specific_date_range' },
  ];

  return (
    <>
      <div style={{ padding: '1rem 0 0 3rem' }}>
        {CREATION_DATE_OPTIONS.map(({ id, label }) => (
          <RadioButton
            key={id}
            onChange={() => {
              dispatch(setAnnotationsFilter({ filter: 'creationDate', value: id }));
            }}
            checked={filters['creationDate'] === id}
            size="x-small"
            margin="0 0 1rem 0"
            testId={`sidebar-comments-filters-creation-date-${id}-radio`}
          >
            {label}
          </RadioButton>
        ))}
      </div>
      <div className={styles.labelContainer}>
        <div
          className={`${styles.label} ${
            filters['creationDate'] !== 'specific_date_range' && styles.disabled
          }`}
          style={{ marginRight: '1.5rem' }}
        >
          <FormattedMessage id="START_DATE" />
        </div>
        <div
          className={`${styles.label} ${
            filters['creationDate'] !== 'specific_date_range' && styles.disabled
          }`}
        >
          <FormattedMessage id="END_DATE" />
        </div>
      </div>
      <div style={{ paddingBottom: '1rem', margin: '0 3rem' }}>
        <DatePicker
          direction="horizontal"
          onStartChange={(date) => {
            dispatch(
              setAnnotationsFilter({
                filter: 'specificDateValues',
                value: {
                  startDate: date && date.toString(),
                  endDate:
                    filters['specificDateValues'] && filters['specificDateValues'].endDate
                      ? filters['specificDateValues'].endDate
                      : null,
                },
              }),
            );
          }}
          onEndChange={(date) => {
            dispatch(
              setAnnotationsFilter({
                filter: 'specificDateValues',
                value: {
                  startDate:
                    filters['specificDateValues'] && filters['specificDateValues'].startDate
                      ? filters['specificDateValues'] && filters['specificDateValues'].startDate
                      : null,
                  endDate: date && date.toString(),
                },
              }),
            );
          }}
          dateRange
          placeholderStart={intl.formatMessage({ id: 'START_DATE' })}
          placeholderEnd={intl.formatMessage({ id: 'END_DATE' })}
          testId="date-picker"
          startDate={
            filters['specificDateValues'] && filters['specificDateValues'].startDate
              ? new Date(filters['specificDateValues']?.startDate)
              : undefined
          }
          endDate={
            filters['specificDateValues'] && filters['specificDateValues'].endDate
              ? new Date(filters['specificDateValues']?.endDate)
              : undefined
          }
          disabled={filters['creationDate'] !== 'specific_date_range'}
        />
      </div>
    </>
  );
};

export default CreationDateFilter;
