import { useSelector } from '_common/hooks';
import { selectIsIEnvision } from 'App/redux/appSlice';

import File from './Dropdowns/File';
import Edit from './Dropdowns/Edit';
import Insert from './Dropdowns/Insert';
import Review from './Dropdowns/Review';
import Format from './Dropdowns/Format';
import Table from './Dropdowns/Table';
import Help from './Dropdowns/Help';
import View from './Dropdowns/View';

import MissingFontsLabel from './MissingFontsLabel/MissingFontsLabel';
import IntegrationiEnvisionLogo from './IntegrationiEnvisionLogo/IntegrationiEnvisionLogo';

import styles from './Menu.module.scss';

type MenuProps = {
  insertComment: () => void;
};

const Menu = ({ insertComment }: MenuProps) => {
  const isiEnvision = useSelector(selectIsIEnvision);

  return (
    <div className={styles.root}>
      <File />
      <Edit />
      <Insert insertComment={insertComment} />
      <Review insertComment={insertComment} />
      <Format />
      <Table />
      <View />
      <Help />
      {!isiEnvision && <MissingFontsLabel />}
      <IntegrationiEnvisionLogo />
    </div>
  );
};

export default Menu;
