import { FormattedMessage } from 'react-intl';
import { Tooltip, Popover, Icon, Toggle, usePopper } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import { selectAccounts } from 'Auth/redux/localStorageSlice';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

import styles from './ChangeTenantDropdown.module.scss';
import { LocalStorage } from '_common/utils';

const ChangeTenantDropdown = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const expanded = useSelector((state) => state.sidebar.expanded);
  const accounts = useSelector(selectAccounts);
  const { isOpen, popperProps, referenceProps } = usePopper({ placement: 'right-start' });

  const name = currentUser?.other.tenants?.find((tenant) => tenant.current)?.name ?? '';

  const handleChangeTenant = (tenant: NonNullable<UserProfile['other']['tenants']>[number]) => {
    if (tenant.current || !currentUser) {
      return;
    }
    if (tenant.name && tenant.url?.startsWith(window.location.origin)) {
      LocalStorage.setTenant(tenant.name);
      window.location.href = tenant.url;
    } else {
      const device = accounts[currentUser.profile.id].device;
      const token = accounts[currentUser.profile.id].token;
      window.location.href = `${tenant.url}auth/redirect/ok/${token}/${device}`;
    }
  };

  if (!currentUser?.other.tenants) {
    return null;
  }
  if (currentUser?.other.tenants.length <= 0) {
    return null;
  }
  return (
    <>
      <Tooltip content={name} disabled={expanded} placement="right" testId="toggle-change-tooltip">
        <div>
          <Toggle
            variant="monochrome"
            theme="primary"
            size="large"
            expanded={expanded}
            suffix={expanded ? 'TenantUpDown' : 'Tenant'}
            id="ChangeTenantDropdown"
            isToggled={isOpen}
            margin="0 0 1rem 0"
            testId="toggle-change-tenant"
            {...referenceProps}
          >
            <div className={styles.name} title={name}>
              {name}
            </div>
          </Toggle>
        </div>
      </Tooltip>
      <Popover {...popperProps} testId="change-tenant-popper">
        <div className={styles.menuContainer}>
          <div className={`${styles.header} ${styles.item}`}>
            <FormattedMessage id="templates.changeTenant" />
          </div>
          <div className={styles.optionsContainer}>
            {currentUser.other.tenants.map((tenant) => (
              <div
                className={styles.item}
                key={tenant.url}
                onClick={() => handleChangeTenant(tenant)}
                data-testid={`${tenant.name}-dropdown-item`}
              >
                <div className={styles.label}>{tenant.name}</div>
                {tenant.current && <Icon icon="TenantLocation" size={24} />}
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default ChangeTenantDropdown;
