import { FormattedMessage } from 'react-intl';
import { Checkbox } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { selectPDFAnnotations, setAnnotationsFilter } from 'PDF/redux/PDFAnnotationsSlice';

import styles from '../Filters.module.scss';

const PriorityFilter = () => {
  const dispatch = useDispatch();

  const annotationList = useSelector(selectPDFAnnotations);
  const filters = useSelector((state) => state.pdf.annotations.filters);

  const handlePriorityFilter = (value: PDF.Annotation.Priority) => {
    const newValue = [...filters['priority']];

    if (!newValue?.includes(value)) {
      newValue?.push(value);
    } else {
      newValue.splice(newValue.indexOf(value), 1);
    }
    dispatch(setAnnotationsFilter({ filter: 'priority', value: [...newValue] }));
  };

  const handleAnnotationsByPriority = (priority: string) => {
    return annotationList.filter((annotation) => annotation.priority === priority).length;
  };
  return (
    <div style={{ paddingLeft: '3rem' }}>
      <Checkbox
        checked={filters['priority']?.includes('High') ? 'checked' : 'unchecked'}
        margin="1rem 0 2rem 0"
        onChange={() => {
          handlePriorityFilter('High');
        }}
        testId="sidebar-comments-filters-priority-high-checkbox"
      >
        <FormattedMessage id="HIGH" />
        {handleAnnotationsByPriority('High') > 0 && (
          <span
            data-testid="sidebar-comments-filters-priority-high-counter"
            className={styles.checkboxText}
          >{`(${handleAnnotationsByPriority('High')})`}</span>
        )}
      </Checkbox>
      <Checkbox
        checked={filters['priority']?.includes('Medium') ? 'checked' : 'unchecked'}
        margin="0 0 2rem 0"
        onChange={() => handlePriorityFilter('Medium')}
        testId="sidebar-comments-filters-priority-medium-checkbox"
      >
        <FormattedMessage id="MEDIUM" />
        {handleAnnotationsByPriority('Medium') > 0 && (
          <span
            data-testid="sidebar-comments-filters-priority-medium-counter"
            className={styles.checkboxText}
          >{`(${handleAnnotationsByPriority('Medium')})`}</span>
        )}
      </Checkbox>
      <Checkbox
        checked={filters['priority']?.includes('Low') ? 'checked' : 'unchecked'}
        margin="0 0 2rem 0"
        onChange={() => handlePriorityFilter('Low')}
        testId="sidebar-comments-filters-priority-low-checkbox"
      >
        <FormattedMessage id="LOW" />
        {handleAnnotationsByPriority('Low') > 0 && (
          <span
            data-testid="sidebar-comments-filters-priority-low-counter"
            className={styles.checkboxText}
          >{`(${handleAnnotationsByPriority('Low')})`}</span>
        )}
      </Checkbox>
    </div>
  );
};

export default PriorityFilter;
