import { useEffect, useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card, DatePicker, InputField } from 'dodoc-design-system';

import { notify } from '_common/components/ToastSystem';
import { useDispatch, usePublicProfile, useSelector } from '_common/hooks';
import { usePDFContext } from 'PDF/PDFContext';

import {
  setEditingAnnotation,
  setCreating,
  DEFAULT_CARD_STYLES_PANEL,
  selectAnnotation,
} from 'PDF/redux/PDFAnnotationsSlice';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';

import {
  InteractionController,
  RichTextEditor,
  SearchUser,
  UserPresentation,
} from '_common/components';
import {
  RichTextEditorHandler,
  RichTextEditorProps,
} from '_common/components/RichTextEditor/RichTextEditor';
import { UserOption } from '_common/components/SearchUser/SearchUser';

import styles from './EditableTaskCard.module.scss';
import usePDFCollaborators from 'PDF/hooks/usePDFCollaborators';

export type EditableTaskCardProps = {
  sidebar: boolean;
  testId: string;
} & (
  | {
      mode: 'create';
      task?: undefined;
      creating: Omit<PDF.TaskCreationData, 'task'>;
    }
  | {
      mode: 'edit';
      task: PDF.Annotation.Task;
      creating?: undefined;
    }
);

const EditableTaskCardContent = ({
  mode,
  task,
  creating,
  sidebar,
  testId,
}: EditableTaskCardProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();

  const assigneeProfile = usePublicProfile(task?.assignee ?? '');
  const collaborators = usePDFCollaborators();
  const currentUserId = useSelector((state) => state.auth.userId);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);

  const cardRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<RichTextEditorHandler>(null);
  const assignedRef = useRef<HTMLDivElement>(null);
  const dueDateRef = useRef<HTMLDivElement>(null);
  const ctaRef = useRef<HTMLButtonElement>(null);

  const [localDescription, setDescription] = useState<string>();
  const [localAssignee, setAssignee] = useState<UserOption>();
  const [localDueDate, setDueDate] = useState<Date>();

  const currentDate = new Date();

  useEffect(() => {
    if (sidebar && cardRef.current) {
      cardRef.current.scrollIntoView({ block: 'center' });
    }
  }, [sidebar]);

  useEffect(() => {
    if (localAssignee) {
      dispatch(completeAction('pdf_tasks_assignee'));
    }
    if (localDueDate) {
      dispatch(completeAction('pdf_tasks_dueDate'));
    }
    if (!localDescription) {
      const current = descriptionRef?.current?.getCurrent();
      if (current) {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: {
              top: current.offsetTop,
              left: current.offsetLeft,
              height: current.offsetHeight,
              width: current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: undefined,
          }),
        );
      }
    } else if (!actionsCompleted.pdf_tasks_assignee) {
      if (assignedRef?.current) {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: {
              top: assignedRef.current.offsetTop,
              left: assignedRef.current.offsetLeft,
              height: assignedRef.current.offsetHeight,
              width: assignedRef.current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: undefined,
          }),
        );
      }
    } else if (!actionsCompleted.pdf_tasks_dueDate) {
      if (dueDateRef?.current) {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: {
              top: dueDateRef.current.offsetTop,
              left: dueDateRef.current.offsetLeft,
              height: dueDateRef.current.offsetHeight,
              width: dueDateRef.current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: undefined,
          }),
        );
      }
    } else {
      if (ctaRef?.current)
        dispatch(
          setPulseData({
            taskButtonRect: {
              top: ctaRef.current.offsetTop,
              left: ctaRef.current.offsetLeft,
              height: ctaRef.current.offsetHeight,
              width: ctaRef.current.offsetWidth,
            },
            taskInputRect: undefined,
          }),
        );
    }

    return () => {
      dispatch(
        setPulseData({
          taskInputRect: undefined,
        }),
      );
    };
  }, [localDueDate, localAssignee, localDescription, actionsCompleted]);

  useEffect(() => {
    if (mode === 'edit' && task.content?.content) {
      setDescription(
        task.content?.content.length > 0 ? JSON.stringify(task.content?.content) : undefined,
      );
    }
  }, [task?.content?.content]);

  useEffect(() => {
    if (mode === 'edit' && assigneeProfile.id) {
      const assigneeValue: UserOption = {
        value: task.assignee,
        label: task.assignee ? assigneeProfile.name : '',
        avatar: '',
        description: '',
        username: '',
        name: '',
        is_superuser: false,
        type: 'user',
      };
      setAssignee(assigneeValue);
    } else {
      setAssignee(undefined);
    }
  }, [assigneeProfile.id]);

  useEffect(() => {
    if (mode === 'edit') {
      setDueDate(task.dueDate ? new Date(task.dueDate) : undefined);
    }
  }, [task?.dueDate]);

  const usersList: UserOption[] = collaborators.map(
    (id) =>
      ({
        id: id,
        type: 'user',
      } as UserOption),
  );

  const handleDescriptionChange: RichTextEditorProps['onChange'] = (description) => {
    setDescription(description);
  };

  const handleDueDateChange = (newDueDate: Date) => {
    setDueDate(newDueDate);
  };

  const handleCancelClicked = () => {
    if (mode === 'create') {
      dispatch(setCreating());
    } else {
      dispatch(setEditingAnnotation({ id: null, isTask: false }));
    }
  };

  const handleApply = () => {
    if (!localDescription) {
      return;
    }

    if (mode === 'edit') {
      pdfManager.editTask(task.pageNumber, task.id, {
        content: JSON.parse(localDescription),
        assignee: localAssignee?.value ?? '',
        dueDate: localDueDate?.toISOString() ?? '',
      });
      dispatch(setEditingAnnotation({ id: null, isTask: false }));
      notify({
        type: 'success',
        title: 'TASK_EDITED',
        message: 'THE_TASK_WAS_SUCCESSFULLY_EDITED',
      });
    } else {
      const taskId = pdfManager.createAnnotation({
        ...creating,
        content: JSON.parse(localDescription),
        task: {
          assignee: localAssignee?.value ?? '',
          dueDate: localDueDate?.toISOString() ?? '',
          status: 'td',
          watchers: [],
        },
      });

      dispatch(setCreating());
      dispatch(completeAction('pdf_tasks_createTask'));
      if (taskId) {
        dispatch(selectAnnotation(taskId));
      }
    }
  };

  return (
    <InteractionController environment="dopdf">
      <span data-testid={`${testId}-editable`}>
        <Card
          sidebar={sidebar}
          width={sidebar ? '100%' : undefined}
          ref={cardRef}
          id={task?.id ? undefined : 'temporaryTaskCard'}
          testId={task?.id ? `task-${task?.id}-card` : 'temporaryTask-card'}
        >
          <Card.Header>
            <div className={styles.header}>
              <UserPresentation userId={task?.authorId || currentUserId} />
            </div>
          </Card.Header>
          <Card.Body>
            <InteractionController
              environment="dopdf"
              rules={[{ interaction: 'pdf_task_fill' }]}
              style={{ flexDirection: 'column' }}
            >
              <InputField
                size="medium"
                label={intl.formatMessage({ id: 'TASK_DESCRIPTION' })}
                required={intl.formatMessage({ id: 'REQUIRED' })}
                feedback={false}
                testId={`${testId}-description`}
              >
                <RichTextEditor
                  initialValue={localDescription}
                  mentionableUsers={collaborators}
                  placeholder={intl.formatMessage({
                    id: 'INSERT_TASK_DESCRIPTION',
                  })}
                  overwrittenStyles={DEFAULT_CARD_STYLES_PANEL}
                  onChange={handleDescriptionChange}
                  ref={descriptionRef}
                  testId={`${testId}-rich-text-editor`}
                />
              </InputField>
              <InputField
                size="medium"
                label={intl.formatMessage({ id: 'ASSIGNED_TO' })}
                feedback={false}
                margin="2rem 0 0 0"
                testId={`${testId}-assgined-to`}
              >
                <div ref={assignedRef}>
                  <SearchUser
                    width="100%"
                    placeholder={intl.formatMessage({
                      id: 'TASK_SELECT_USER',
                    })}
                    options={usersList}
                    value={localAssignee && localAssignee.value ? localAssignee : null}
                    onChange={setAssignee}
                    searchable
                    // @ts-expect-error Fix type in the DS
                    noOptionsMessage={() => intl.formatMessage({ id: 'NO_RESULTS_FOUND' })}
                    testId={`${testId}-assigned-to`}
                  />
                </div>
              </InputField>
              <InputField
                size="medium"
                label={intl.formatMessage({ id: 'settings.general.dueDate' })}
                feedback={false}
                margin="2rem 0 0 0"
                testId={`${testId}-due-date`}
              >
                <div ref={dueDateRef}>
                  <DatePicker
                    filterDate={[
                      {
                        filterType: 'after',
                        filterDate: new Date(currentDate.setDate(currentDate.getDate() - 1)),
                      },
                    ]}
                    placeholder="dd/mm/yy"
                    selectedDate={localDueDate ?? null}
                    onChange={handleDueDateChange}
                    testId="a"
                  />
                </div>
              </InputField>
            </InteractionController>
          </Card.Body>
          <Card.Footer size="large">
            <Button
              size="small"
              variant="link"
              onClick={handleCancelClicked}
              testId={`${testId}-cancel-button`}
            >
              <FormattedMessage id="global.cancel" />
            </Button>
            <InteractionController environment="dopdf" rules={[{ interaction: 'pdf_task_fill' }]}>
              <Button
                size="small"
                variant="primary"
                margin="0 0 0 1rem"
                disabled={!localDescription}
                onClick={handleApply}
                ref={ctaRef}
                testId={`${testId}-submit-button`}
              >
                {mode === 'create' ? (
                  <FormattedMessage id="global.create" />
                ) : (
                  <FormattedMessage id="SAVE_CHANGES" />
                )}
              </Button>
            </InteractionController>
          </Card.Footer>
        </Card>
      </span>
    </InteractionController>
  );
};

export default EditableTaskCardContent;
