import { FormattedMessage } from 'react-intl';
import { Dropdown, usePopper, Toggle } from 'dodoc-design-system';
import { ChangeEventHandler } from 'react';

type NewElementDropdownProps = {
  disabled: boolean;
  openNewFolderModal: () => void;
  openNewDocumentModal: () => void;
  handleImportContentFromFile: ChangeEventHandler<HTMLInputElement>;
  handleFileUploadChange: ChangeEventHandler<HTMLInputElement>;
  handleImportPDFDocument: ChangeEventHandler<HTMLInputElement>;
};

const NewElementDropdown = ({
  disabled,
  openNewFolderModal,
  openNewDocumentModal,
  handleImportContentFromFile,
  handleFileUploadChange,
  handleImportPDFDocument,
}: NewElementDropdownProps) => {
  const { isOpen, popperProps, referenceProps } = usePopper({
    disabled,
    placement: 'bottom-start',
  });
  return (
    <>
      <Toggle
        size="medium"
        variant="standard"
        icon="New"
        isToggled={isOpen}
        testId="new-dropdown-toggle"
        disabled={disabled}
        {...referenceProps}
      >
        <FormattedMessage id="storage.actionBar.new.new" />
      </Toggle>
      <Dropdown {...popperProps} testId="new-dropdown">
        <Dropdown.Item
          size="large"
          prefixIcon="Folder"
          onClick={openNewFolderModal}
          disabled={disabled}
          testId="new-folder-dropdown-item"
        >
          <FormattedMessage id="storage.actionBar.new.newFolder" />
        </Dropdown.Item>
        <Dropdown.SubMenu
          size="large"
          prefixIcon="Document"
          itemContent={<FormattedMessage id="storage.actionBar.new.newDocument" />}
          testId="new-document-dropdown-submenu"
        >
          <Dropdown.Item
            size="large"
            onClick={openNewDocumentModal}
            testId="blank-document-dropdown-item"
          >
            <FormattedMessage id="storage.actionBar.new.blankDocument" />
          </Dropdown.Item>
          <Dropdown.Item
            size="large"
            upload
            onChange={handleImportContentFromFile}
            accept=".docx, .html, .htm"
            testId="import-document-dropdown-item"
          >
            <FormattedMessage id="storage.actionBar.new.importDocument" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        <Dropdown.Item
          size="large"
          upload
          onChange={handleImportPDFDocument}
          accept=".pdf"
          prefixIcon="FilePDFRed"
          testId="import-pdf-document-dropdown-item"
        >
          <FormattedMessage id="PDF_IMPORT" />
          ...
        </Dropdown.Item>
        <Dropdown.Item
          size="large"
          prefixIcon="File"
          onChange={handleFileUploadChange}
          upload
          multiple
          accept=".rtf, .html, .xhtml, .css, .pdf, .ppt, .pptx, .xls,
        .xlsx, .csv, .txt, .jpg, .jpeg, .png, .bmp, .tiff, .gif, .svg, .dwg,
        .dxf, .dgn, .stl, .mkv, .mov, .avi, .mp4, .m4v, .ogv, .webm, .qt, .mpg,
        .wmv, .asf, .flv, .aac, .flac, .wav, .mp3, .zip, .rar, .cls, .sty, .bst,
        .bib, .tex, .aux, .log, .docx"
          testId="new-file-dropdown-item"
        >
          <FormattedMessage id="FILE_UPLOAD" />
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default NewElementDropdown;
