import { ReactNode, useMemo, CSSProperties } from 'react';
import cx from 'classnames';

import {
  OnboardingEnvironments,
  ExplorerActions,
  EditorActions,
  PDFActions,
  ExplorerInteractions,
  EditorInteractions,
  PDFInteractions,
} from 'App/redux/onboardingSlice';

import styles from './InteractionController.module.scss';
import { useSelector } from '_common/hooks';
import { selectIsIEnvision } from 'App/redux/appSlice';

export type InteractionRule = {
  /** Interactions are enabled when permitted through redux state */
  interaction: ExplorerInteractions | EditorInteractions | PDFInteractions;
  /**
   * Interactions will be enabled as long as any of the actions aren't completed
   *
   * If no actions given, remains enabled as long as interaction is permitted
   */
  actions?: (ExplorerActions | EditorActions | PDFActions)[];
};

type InteractionControllerProps = {
  environment: OnboardingEnvironments;
  /**
   * Set multiple rules for when the interaction should be enabled
   *
   * If no rules given and an onboarding is active, all interactions will be disabled
   */
  rules?: InteractionRule[];
  children: ReactNode;
  style?: CSSProperties;
};

const InteractionController = ({
  environment,
  rules,
  children,
  style,
}: InteractionControllerProps) => {
  const isOnboardingActive = useSelector(
    (state) => state.onboarding.active[environment] || state.onboarding.started[environment],
  );
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const interactions = useSelector((state) => state.onboarding.interactions);
  const isIEnvision = useSelector(selectIsIEnvision);

  const areAllActionsCompleted = (
    actions: NonNullable<InteractionControllerProps['rules']>[number]['actions'],
  ) => {
    if (!actions) {
      return false;
    }

    return !actions.some((action) => !actionsCompleted[action]);
  };

  const isEnabled = useMemo(() => {
    if (!rules) {
      return false;
    }

    return rules.some(
      (rule) => interactions[rule.interaction] && !areAllActionsCompleted(rule.actions),
    );
  }, [rules, interactions]);

  if (!isOnboardingActive || !isIEnvision) {
    return <>{children}</>;
  }

  if (!rules) {
    return (
      <span className={`${styles.root} ${styles.disabledInteraction}`} style={style}>
        {children}
      </span>
    );
  }

  return (
    <span
      className={cx(styles.root, {
        [styles.enabledInteraction]: isEnabled,
        [styles.disabledInteraction]: !isEnabled,
      })}
      style={style}
    >
      {children}
    </span>
  );
};

export default InteractionController;
