import { useSelector } from '_common/hooks';
import { selectIsIEnvision } from 'App/redux/appSlice';
import { GHOST_USERS } from '_common/services/api/publicProfilesApi';
import usePDFData from './usePDFData';

const usePDFCollaborators = () => {
  const pdf = usePDFData();

  const isIEnvision = useSelector(selectIsIEnvision);
  const onboardingIsActive = useSelector((state) => state.onboarding.active.dopdf);
  const onboardingHasStarted = useSelector((state) => state.onboarding.started.dopdf);
  if (pdf) {
    const collaborators: string[] =
      (onboardingIsActive || onboardingHasStarted) && isIEnvision
        ? [GHOST_USERS.davidBean['id']]
        : [...pdf.owners, ...pdf.shared_with];

    return collaborators;
  }

  return [];
};

export default usePDFCollaborators;
