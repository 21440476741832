import ListsManager, {
  MergedListsData,
} from 'Editor/services/DataManager/controllers/Numbering/ListsManager';
import { ReadyState, ViewModelChildren } from '../../utils';
import { ListViewModel } from '../../ViewModels';

export class ListStylesManager {
  private readyState?: ReadyState | null;
  private Data: Editor.Data.API;
  private Visualizer: Editor.Visualizer.State;
  private lists?: ListsManager;
  private children: ViewModelChildren<ListViewModel>;
  private view: HTMLElement;
  private reference: HTMLElement | null;

  constructor(dataManager: Editor.Data.API, Visualizer: Editor.Visualizer.State) {
    this.Data = dataManager;
    this.Visualizer = Visualizer;
    this.children = new ViewModelChildren<ListViewModel>();
    this.handleListsLoaded = this.handleListsLoaded.bind(this);
    this.handleListInserted = this.handleListInserted.bind(this);
    this.handleListRemoved = this.handleListRemoved.bind(this);

    this.view = document.head;
    this.reference = document.getElementById('listStyles');
  }

  start(readyState: ReadyState) {
    this.readyState = readyState;
    this.lists = this.Data?.numbering.lists;

    if (this.lists) {
      // this.lists.on('LOAD_LISTS', this.handleListsLoaded);
      this.lists.on('LOAD_LIST', this.handleListInserted);
      this.lists.on('LIST_REMOVE', this.handleListRemoved);
      this.handleListsLoaded(this.lists.lists());
    }
  }

  private handleListsLoaded(lists: MergedListsData) {
    this.readyState?.setReady(ListStylesManager.name);
    this.readyState = null;
    let listIds = Object.keys(lists);
    let listId;
    for (let index = 0; index < listIds.length; index++) {
      listId = listIds[index];
      if (this.children.getById(listId)) {
        this.children.getById(listId).render();
      } else {
        this.handleListInserted(listId);
      }
    }
    this.readyState = null;
  }

  private handleListInserted(listId: string) {
    let model = this.Visualizer.viewModelFactory?.getList(listId);
    this.appendChild(model);
  }

  private handleListRemoved(listId: string) {
    let index;
    let removed = this.children.removeById(listId);
    for (index = 0; index < removed.length; index++) {
      removed[index].dispose();
    }
  }

  private appendChild(child?: ListViewModel) {
    if (child) {
      this.children.push(child);

      if (child.view?.parentNode == null) {
        child.render();
      }

      if (child.view) {
        if (this.reference) {
          this.view.insertBefore(child.view, this.reference);
        } else {
          this.view.appendChild(child.view);
        }
      }
    }
  }

  destroy() {
    if (this.lists) {
      this.lists.off('LOAD_LIST', this.handleListInserted);
      this.lists.off('LIST_REMOVE', this.handleListRemoved);
    }
    let children = this.children.removeAll();
    for (let index = 0; index < children.length; index++) {
      children[index].dispose();
    }
  }
}
