import { Divider } from 'dodoc-design-system';
import styles from './AuthFooter.module.scss';

import LanguageMenu from 'Auth/components/LanguageMenu/LanguageMenu';
import FooterLinks from 'Auth/components/FooterLinks/FooterLinks';
import Copyright from 'Auth/components/Copyright/Copyright';
import Version from 'Auth/components/Version/Version';

export type AuthFooterProps = {
  hero?: boolean;
  white?: boolean;
};

const AuthFooter = ({ hero = false, white = false }: AuthFooterProps) => (
  <div className={`${styles.root} ${hero && styles.hero}`}>
    <LanguageMenu hero={hero} white={white} />
    <Divider margin="1rem 0" />
    <FooterLinks hero={hero} white={white} />
    <div className={styles.copyrightAndVersion}>
      <Version hero={hero} white={white} />
      <span style={{ margin: '0 1rem' }}>•</span>
      <Copyright hero={hero} white={white} />
    </div>
  </div>
);

export default AuthFooter;
