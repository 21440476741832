import { useEffect, useRef } from 'react';
import DocumentTitle from 'react-document-title';

import { useDispatch, useSelector } from '_common/hooks';
import { InstanceService } from '_common/services';
import PDFContextProvider from './PDFContext';
import PDFPermisionsContextProvider from './PDFPermissionsContext';
import usePDFData from './hooks/usePDFData';

import { selectIsIEnvision, setAppTheme } from 'App/redux/appSlice';
import { setCurrentDocument } from 'App/redux/onboardingSlice';

import Sidebar from './Sidebar';
import Title from './Title';
import Menu from './Menu';
import Footer from './Footer';
import Content from './Content/Content';
import Navigation from './Navigation';
import RightSidePanel from './RightSidePanel/RightSidePanel';
import OnboardingPDF from './components/OnboardingPDF/OnboardingPDF';
import OnboardingPDFIntegration from './components/OnboardingPDF/OnboardingPDFIntegration';
import usePDFExport from './hooks/usePDFExport';
import usePDFPersist from './hooks/usePDFPersist';

import styles from './PDF.module.scss';
import PDFModalConductor from './PDFModalConductor';

const PDF = () => {
  const dispatch = useDispatch();
  const pdfData = usePDFData({ open: true });
  usePDFExport();
  usePDFPersist();

  const timeoutRef = useRef<number>();

  const active = useSelector((state) => state.onboarding.active);
  const started = useSelector((state) => state.onboarding.started);
  const leftPanel = useSelector((state) => state.pdf.general.leftPanel);
  const rightPanel = useSelector((state) => state.pdf.general.rightPanel);
  const zoom = useSelector((state) => state.pdf.general.zoom);
  const isEnvision = useSelector(selectIsIEnvision);

  useEffect(() => {
    dispatch(setAppTheme('envision'));

    return () => {
      dispatch(setAppTheme('dodoc'));
    };
  }, []);

  const handleObjectProcessing = ({
    objectId,
    objectType,
  }: {
    objectId: ObjectId;
    objectType: ObjectType;
  }) => {
    timeoutRef.current = window.setTimeout(() => {
      new InstanceService().getObjectStatus(objectType, objectId).then(({ data }) => {
        if (data.status !== 'processing') {
          if (pdfData && data.id !== pdfData.id) {
            dispatch(setCurrentDocument({ target: 'dopdf', id: pdfData?.id, zoom }));
            window.open(`/pdf/${data.id}`, '_self');
          }
        } else {
          handleObjectProcessing({ objectId: data.id, objectType: objectType });
        }
      });
    }, 2500);
  };

  useEffect(() => {
    if ((active.dopdf || started.dopdf) && pdfData && isEnvision) {
      new InstanceService().getPDFOnboarding().then(({ data }) => {
        if (data.status === 'processing') {
          handleObjectProcessing({ objectId: data.id, objectType: 'dopdf' });
        }
        if (data.status === 'active') {
          if (data.id !== pdfData.id) {
            dispatch(setCurrentDocument({ target: 'dopdf', id: pdfData?.id, zoom }));
            window.open(`/pdf/${data.id}`, '_self');
          }
        }
      });
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [active, started, isEnvision, pdfData]);

  useEffect(() => {
    const faviconLink = document.getElementById('favicon') as HTMLLinkElement;
    if (faviconLink) {
      faviconLink.href = '/favicon-red.ico';
    }
    return () => {
      if (faviconLink) {
        faviconLink.href = '/favicon.ico';
      }
    };
  }, [pdfData]);

  return (
    <div className={styles.container}>
      <DocumentTitle title={pdfData?.name ?? 'doDOC'}>
        <>
          <PDFPermisionsContextProvider>
            <PDFContextProvider>
              <Title />
              <Menu />
              <Content />
              {leftPanel !== '' && <Navigation />}
              {rightPanel && <RightSidePanel />}
              <Sidebar />
              <Footer />
              {isEnvision ? <OnboardingPDFIntegration /> : <OnboardingPDF />}
              <PDFModalConductor />
            </PDFContextProvider>
          </PDFPermisionsContextProvider>
          <div id="pdf-overlay" />
        </>
      </DocumentTitle>
    </div>
  );
};

export default PDF;
