import { forwardRef, Ref } from 'react';
import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import EditableAnnotationCardContent, {
  EditableAnnotationCardProps,
} from './EditableAnnotationCardContent';

const EditableAnnotationCard = forwardRef(
  (props: EditableAnnotationCardProps, ref: Ref<HTMLDivElement>) => {
    return (
      <IntlErrorBoundary
        fallbackType="card"
        mockProps={{ sidebar: !!props.sidebar, width: props.sidebar ? '100%' : '43rem' }}
      >
        <EditableAnnotationCardContent {...props} ref={ref} />
      </IntlErrorBoundary>
    );
  },
);

export default EditableAnnotationCard;
