import { useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { containsMention, stringToRichText } from 'utils';
import { useDispatch, useSelector } from '_common/hooks';
import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { completeAction } from 'App/redux/onboardingSlice';

import { UserAvatar, RichTextEditor, InteractionController } from '_common/components';
import { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';

import ReplyCard from './ReplyCard/ReplyCard';

import styles from './ReplyList.module.scss';
import usePDFCollaborators from 'PDF/hooks/usePDFCollaborators';

type ReplyListProps = {
  replies: PDF.Annotation.Reply[];
  pageNumber: PDF.Annotation['pageNumber'];
  annotationId: PDF.Annotation['id'];
  /** ID to be used in unit or automated tests.
   * Will result in 4 different attributes:
   * * ${testId}-card-${index}-root
   * * ${testId}-card-${index}-editable
   * * ${testId}-card-${index}-view
   * * ${testId}-new-reply-container
   */
  testId: string;
};

const ReplyList = ({ replies, pageNumber, annotationId, testId }: ReplyListProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const pdfManager = usePDFContext();

  const currentUserId = useSelector((state) => state.auth.userId);
  const annotationsById = useSelector((state) => state.pdf.annotations.byId);
  const collaborators = usePDFCollaborators();

  const replyRef = useRef<RichTextEditorHandler>(null);
  const [reply, setReply] = useState('');
  const [showFooter, setShowFooter] = useState(false);

  const mutableReplies = useMemo(() => [...replies].reverse(), [replies]);

  const { canComment } = usePDFPermissions();

  const handleCancelReply = () => {
    replyRef.current?.clear();
    setShowFooter(false);
  };

  const handleCreateReply = () => {
    pdfManager.replyToAnnotation(pageNumber, annotationId, JSON.parse(reply));
    if (annotationsById[annotationId]?.subtype === 'Note') {
      if (containsMention(stringToRichText(reply))) {
        dispatch(completeAction('pdf_comments_mentionInCommentReply'));
      }
    }

    replyRef.current?.clear();
    setShowFooter(false);
  };

  const handleFocus = () => {
    setShowFooter(true);
  };

  return (
    <div className={styles.root}>
      {mutableReplies.length > 0 && (
        <div className={styles.list}>
          {mutableReplies.map((reply, index) => (
            <ReplyCard
              key={`replycard-${reply.id}-of-${annotationId}`}
              reply={reply}
              pageNumber={pageNumber}
              annotationId={annotationId}
              testId={`${testId}-card-${index}`}
            />
          ))}
        </div>
      )}
      {canComment && (
        <InteractionController
          environment="dopdf"
          rules={[{ interaction: 'pdf_comment_reply' }]}
          style={{ flexDirection: 'column' }}
        >
          <div className={styles.newReply} data-testid={`${testId}-new-reply-container`}>
            <div className={styles.body}>
              <UserAvatar userId={currentUserId} />
              <RichTextEditor
                ref={replyRef}
                placeholder={intl.formatMessage({ id: 'REPLY_PLACEHOLDER' })}
                onChange={setReply}
                onFocus={handleFocus}
                mentionableUsers={collaborators}
                skipFocus
                singleLine
                testId={`${testId}-rich-text-editor`}
              />
            </div>
            {showFooter && (
              <div className={styles.footer}>
                <Button variant="link" size="small" onClick={handleCancelReply} testId="cancel-button">
                  <FormattedMessage id="global.cancel" />
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  onClick={handleCreateReply}
                  disabled={reply === ''}
                  testId="reply-button"
                >
                  <FormattedMessage id="editor.comments.reply" />
                </Button>
              </div>
            )}
          </div>
        </InteractionController>
      )}
    </div>
  );
};

export default ReplyList;
