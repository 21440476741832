import { FormattedMessage } from 'react-intl';
import { Tab } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';

import { setSidebarPanelTab } from 'Editor/redux/SidebarSlice';

import SidebarHeader from 'Editor/pages/EditorPage/SidebarComponent/SidebarHeader';
import SidebarPanel from 'Editor/pages/EditorPage/SidebarComponent/SidebarPanel';
import SidebarContent from 'Editor/pages/EditorPage/SidebarComponent/SidebarContent';

import styles from './ReviewTab.module.scss';
import CommentTab from './CommentTab/CommentTab';
import TrackedActionTab from './TrackedActionTab/TrackedActionTab';
import { InteractionController } from '_common/components';

const ReviewTab = () => {
  const dispatch = useDispatch();

  const tab = useSelector((state) => state.editor.sidebar.tabs.review);

  const onCommentsClick = () => {
    dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
  };
  const onSuggestionsClick = () => {
    dispatch(setSidebarPanelTab({ view: 'review', tab: 'changes' }));
  };

  return (
    <SidebarPanel testId="review-panel">
      <SidebarHeader>
        <FormattedMessage id="editor.menu.review.label" />
      </SidebarHeader>
      <SidebarContent className={styles.sidebar}>
        <div className={styles.tabs}>
          <InteractionController
            environment="editor"
            rules={[
              {
                interaction: 'editor_tabmenu_comments',
                actions: ['editor_comments_openSidePanel'],
              },
            ]}
          >
            <Tab
              fullWidth
              selected={tab === 'comments'}
              onClick={onCommentsClick}
              testId="comments-tab"
            >
              <FormattedMessage id="COMMENTS" />
            </Tab>
          </InteractionController>
          <Tab
            fullWidth
            selected={tab === 'changes'}
            onClick={onSuggestionsClick}
            testId="track-changes-tab"
          >
            <FormattedMessage id="TRACK_CHANGES" />
          </Tab>
        </div>
        {tab === 'comments' ? <CommentTab /> : <TrackedActionTab />}
      </SidebarContent>
    </SidebarPanel>
  );
};

export default ReviewTab;
