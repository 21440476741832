//@ts-expect-error needs mixins refactor
import { Mixin } from 'mixwith';
import { NodeModel } from 'Editor/services/DataManager';
import { BaseViewBuilder, TableElement } from 'Editor/services/VisualizerManager';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { RealtimeOpsBuilder } from '_common/services/Realtime';

const DEFAULT_ROW_HEIGHT = 36;
const MIN_DOUBLE_BORDER = 2.75;

export default Mixin(
  (superclass: any) =>
    class TableStylesOperations extends superclass {
      destroy() {
        super.destroy();
      }

      private getOpsUpdateTableWidth(
        level0Model: NodeModel,
        tableId: string,
        width: Editor.Data.Node.TableWidth,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        const tableData = level0Model.getChildDataById(tableId);
        const tablePath = level0Model.findPathToChild(tableId);

        const propPath = [...tablePath, level0Model.KEYS.PROPERTIES, 'w'];

        let newWidth: Editor.Data.Node.TableWidth | undefined = undefined;

        switch (width.t) {
          case 'abs':
          case 'pct': {
            newWidth = {
              t: width.t,
              v: width.v,
            };
            break;
          }
          case 'nil':
          case 'auto': {
            newWidth = {
              t: 'auto',
              v: 0,
            };
            break;
          }
        }

        const op = this.getObjectOperationforPathValue(tableData.properties?.w, newWidth, propPath);
        if (op) {
          ops.push(op);
        }

        return ops;
      }

      private getOpsUpdateCellsWidth(
        level0Model: NodeModel,
        tableId: string,
        cellIds: string[],
        width: Editor.Data.Node.TableWidth,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        const tableData = level0Model.getChildDataById(tableId);
        const tablePath = level0Model.findPathToChild(tableId);

        const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
        const rowsData = tableData?.childNodes?.[0].childNodes;

        let newWidth: Editor.Data.Node.TableWidth | undefined = undefined;

        switch (width.t) {
          case 'abs':
          case 'pct': {
            newWidth = {
              t: width.t,
              v: width.v,
            };
            break;
          }
          case 'nil':
          case 'auto': {
            newWidth = {
              t: 'auto',
              v: 0,
            };
            break;
          }
        }

        if (rowsData) {
          for (let r = 0; r < rowsData.length; r++) {
            const row = rowsData[r];

            if (row.childNodes) {
              for (let c = 0; c < row.childNodes.length; c++) {
                const cell = row.childNodes[c];

                if (cell.childNodes && cell.id && cellIds.includes(cell.id)) {
                  const propPath = [
                    ...rowsPath,
                    r,
                    'childNodes',
                    c,
                    level0Model.KEYS.PROPERTIES,
                    'w',
                  ];

                  const op = this.getObjectOperationforPathValue(
                    cell.properties?.w,
                    newWidth,
                    propPath,
                  );
                  if (op) {
                    ops.push(op);
                  }
                }
              }
            }
          }
        }

        return ops;
      }

      private getOpsUpdateTableHeight(
        level0Model: NodeModel,
        tableId: string,
        height: number | null,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        if (height != null) {
          const tableData = level0Model.getChildDataById(tableId);
          const tablePath = level0Model.findPathToChild(tableId);

          const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
          const rowsData = tableData?.childNodes?.[0].childNodes;

          if (rowsData) {
            let oldHeight: number = 0;

            for (let r = 0; r < rowsData.length; r++) {
              oldHeight += +(rowsData[r].properties?.rh || DEFAULT_ROW_HEIGHT);
            }

            const diffRatio = height / oldHeight;

            for (let r = 0; r < rowsData.length; r++) {
              const propPath = [...rowsPath, r, level0Model.KEYS.PROPERTIES, 'a'];

              const newHeight = +(
                +(rowsData[r].properties?.rh || DEFAULT_ROW_HEIGHT) * diffRatio
              ).toFixed(2);

              const op = this.getObjectOperationforPathValue(
                rowsData[r].properties?.rh,
                newHeight,
                propPath,
              );
              if (op) {
                ops.push(op);
              }
            }
          }
        }

        return ops;
      }

      private getOpsUpdateRowsHeight(
        level0Model: NodeModel,
        rowIds: string[],
        height: number | null,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        for (let i = 0; i < rowIds.length; i++) {
          const rowData = level0Model.getChildDataById(rowIds[i]);
          const rowPath = level0Model.findPathToChild(rowIds[i]);

          const propPath = [...rowPath, level0Model.KEYS.PROPERTIES, 'rh'];

          const op = this.getObjectOperationforPathValue(rowData.properties?.rh, height, propPath);
          if (op) {
            ops.push(op);
          }
        }
        return ops;
      }

      private getOpsUpdateTableIndentation(
        level0Model: NodeModel,
        tableId: string,
        indentation: number | null,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        const tableData = level0Model.getChildDataById(tableId);
        const tablePath = level0Model.findPathToChild(tableId);

        const indPath = [...tablePath, level0Model.KEYS.PROPERTIES, 'ind'];
        const leftIndPath = [...tablePath, level0Model.KEYS.PROPERTIES, 'ind', 'l'];

        if (indentation != null) {
          if (tableData.properties?.ind !== undefined) {
            if (tableData.properties.ind.l !== undefined) {
              ops.push(
                RealtimeOpsBuilder.objectReplace(
                  tableData.properties.ind.l,
                  indentation,
                  leftIndPath,
                ),
              );
            } else {
              ops.push(RealtimeOpsBuilder.objectInsert(indentation, leftIndPath));
            }
          } else {
            ops.push(RealtimeOpsBuilder.objectInsert({ l: indentation }, indPath));
          }
        } else if (tableData.properties?.ind?.l !== undefined) {
          ops.push(RealtimeOpsBuilder.objectDelete(tableData.properties.ind.l, leftIndPath));
        }

        return ops;
      }

      private getOpsUpdateTableAlignment(
        level0Model: NodeModel,
        tableId: string,
        alignment: Editor.Data.Node.Alignment | null,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        const tableData = level0Model.getChildDataById(tableId);
        const tablePath = level0Model.findPathToChild(tableId);

        const propPath = [...tablePath, level0Model.KEYS.PROPERTIES, 'a'];

        const op = this.getObjectOperationforPathValue(
          tableData.properties?.a,
          alignment,
          propPath,
        );
        if (op) {
          ops.push(op);
        }

        return ops;
      }

      private getOpsUpdateTableTextAlignment(
        level0Model: NodeModel,
        tableId: string,
        textAlignment: Editor.Data.Node.Alignment | null,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        const tableData = level0Model.getChildDataById(tableId);
        const tablePath = level0Model.findPathToChild(tableId);

        const propPath = [...tablePath, level0Model.KEYS.PROPERTIES, 'ca'];

        const op = this.getObjectOperationforPathValue(
          tableData.properties?.ca,
          textAlignment,
          propPath,
        );
        if (op) {
          ops.push(op);
        }

        // remove all cell borders
        const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
        const rowsData = tableData?.childNodes?.[0].childNodes;

        if (rowsData) {
          for (let r = 0; r < rowsData.length; r++) {
            const row = rowsData[r];

            if (row.childNodes) {
              for (let c = 0; c < row.childNodes.length; c++) {
                const cell = row.childNodes[c];

                if (cell && cell.properties?.a !== undefined) {
                  const propPath = [
                    ...rowsPath,
                    r,
                    'childNodes',
                    c,
                    level0Model.KEYS.PROPERTIES,
                    'a',
                  ];

                  const op = this.getObjectOperationforPathValue(
                    cell.properties?.a,
                    null,
                    propPath,
                  );
                  if (op) {
                    ops.push(op);
                  }
                }
              }
            }
          }
        }

        return ops;
      }

      private getOpsUpdateTextAlignment(
        level0Model: NodeModel,
        tableId: string,
        cellIds: string[],
        alignment: Editor.Data.Node.Alignment | null,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        const tableData = level0Model.getChildDataById(tableId);
        const tablePath = level0Model.findPathToChild(tableId);

        const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
        const rowsData = tableData?.childNodes?.[0].childNodes;

        if (rowsData) {
          for (let r = 0; r < rowsData.length; r++) {
            const row = rowsData[r];

            if (row.childNodes) {
              for (let c = 0; c < row.childNodes.length; c++) {
                const cell = row.childNodes[c];

                if (cell.id && cellIds.includes(cell.id)) {
                  const propPath = [
                    ...rowsPath,
                    r,
                    'childNodes',
                    c,
                    level0Model.KEYS.PROPERTIES,
                    'a',
                  ];

                  const op = this.getObjectOperationforPathValue(
                    cell.properties?.a,
                    alignment,
                    propPath,
                  );
                  if (op) {
                    ops.push(op);
                  }
                }
              }
            }
          }
        }

        return ops;
      }

      private getOpsUpdateTableCellVerticalAlignment(
        level0Model: NodeModel,
        verticalAlignment: Editor.Data.Node.VerticalAlignment | null,
        tableId: string,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        const tableData = level0Model.getChildDataById(tableId);
        const tablePath = level0Model.findPathToChild(tableId);

        const propPath = [...tablePath, level0Model.KEYS.PROPERTIES, 'cva'];

        const op = this.getObjectOperationforPathValue(
          tableData?.properties?.cva,
          verticalAlignment,
          propPath,
        );
        if (op) {
          ops.push(op);
        }

        // remove all cell borders
        const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
        const rowsData = tableData?.childNodes?.[0].childNodes;

        if (rowsData) {
          for (let r = 0; r < rowsData.length; r++) {
            const row = rowsData[r];

            if (row.childNodes) {
              for (let c = 0; c < row.childNodes.length; c++) {
                const cell = row.childNodes[c];

                if (cell && cell.properties?.va !== undefined) {
                  const propPath = [
                    ...rowsPath,
                    r,
                    'childNodes',
                    c,
                    level0Model.KEYS.PROPERTIES,
                    'va',
                  ];

                  const op = this.getObjectOperationforPathValue(
                    cell.properties?.va,
                    null,
                    propPath,
                  );
                  if (op) {
                    ops.push(op);
                  }
                }
              }
            }
          }
        }

        return ops;
      }

      private getOpsUpdateCellVerticalAlignment(
        level0Model: NodeModel,
        verticalAlignment: Editor.Data.Node.VerticalAlignment | null,
        tableId: string,
        cellIds: string[],
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        const tableData = level0Model.getChildDataById(tableId);
        const tablePath = level0Model.findPathToChild(tableId);

        const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
        const rowsData = tableData?.childNodes?.[0].childNodes;

        if (rowsData) {
          for (let r = 0; r < rowsData.length; r++) {
            const row = rowsData[r];

            if (row.childNodes) {
              for (let c = 0; c < row.childNodes.length; c++) {
                const cell = row.childNodes[c];

                if (cell.childNodes && cell.id && cellIds.includes(cell.id)) {
                  const propPath = [
                    ...rowsPath,
                    r,
                    'childNodes',
                    c,
                    level0Model.KEYS.PROPERTIES,
                    'va',
                  ];

                  const op = this.getObjectOperationforPathValue(
                    cell.properties?.va,
                    verticalAlignment,
                    propPath,
                  );
                  if (op) {
                    ops.push(op);
                  }
                }
              }
            }
          }
        }

        return ops;
      }

      private getOpsUpdateTableCellBackground(
        level0Model: NodeModel,
        tableId: string,
        background: string | null,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        const tableData = level0Model.getChildDataById(tableId);
        const tablePath = level0Model.findPathToChild(tableId);

        const propPath = [...tablePath, level0Model.KEYS.PROPERTIES, 'bg'];

        const op = this.getObjectOperationforPathValue(
          tableData?.properties?.bg,
          background,
          propPath,
        );
        if (op) {
          ops.push(op);
        }

        // remove all cells background
        const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
        const rowsData = tableData?.childNodes?.[0].childNodes;

        if (rowsData) {
          for (let r = 0; r < rowsData.length; r++) {
            const row = rowsData[r];

            if (row.childNodes) {
              for (let c = 0; c < row.childNodes.length; c++) {
                const cell = row.childNodes[c];

                if (cell && cell.properties?.bg !== undefined) {
                  const propPath = [
                    ...rowsPath,
                    r,
                    'childNodes',
                    c,
                    level0Model.KEYS.PROPERTIES,
                    'bg',
                  ];

                  const op = this.getObjectOperationforPathValue(
                    cell.properties?.bg,
                    null,
                    propPath,
                  );
                  if (op) {
                    ops.push(op);
                  }
                }
              }
            }
          }
        }

        return ops;
      }

      private getOpsUpdateCellBackground(
        level0Model: NodeModel,
        tableId: string,
        cellIds: string[],
        background: string | null,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        const tableData = level0Model.getChildDataById(tableId);
        const tablePath = level0Model.findPathToChild(tableId);

        const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
        const rowsData = tableData?.childNodes?.[0].childNodes;

        if (rowsData) {
          for (let r = 0; r < rowsData.length; r++) {
            const row = rowsData[r];

            if (row.childNodes) {
              for (let c = 0; c < row.childNodes.length; c++) {
                const cell = row.childNodes[c];

                if (cell.childNodes && cell.id && cellIds.includes(cell.id)) {
                  const propPath = [
                    ...rowsPath,
                    r,
                    'childNodes',
                    c,
                    level0Model.KEYS.PROPERTIES,
                    'bg',
                  ];

                  const op = this.getObjectOperationforPathValue(
                    cell.properties?.bg,
                    background,
                    propPath,
                  );
                  if (op) {
                    ops.push(op);
                  }
                }
              }
            }
          }
        }

        return ops;
      }

      private getOpsUpdateTableCellPading(
        level0Model: NodeModel,
        tableId: string,
        padding: Editor.Styles.PaddingProp,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        const tableData = level0Model.getChildDataById(tableId);
        const tablePath = level0Model.findPathToChild(tableId);

        let propPath = [...tablePath, level0Model.KEYS.PROPERTIES, 'cp'];
        let oldValue = undefined;

        let newValue: Editor.Data.Node.Padding = {};
        if (tableData.properties?.cp) {
          newValue = { ...tableData.properties?.cp };
        }

        if (padding.top != null) {
          newValue.t = padding.top.value;
        }

        if (padding.bottom != null) {
          newValue.b = padding.bottom.value;
        }

        if (padding.left != null) {
          newValue.l = padding.left.value;
        }

        if (padding.right != null) {
          newValue.r = padding.right.value;
        }

        const op = this.getObjectOperationforPathValue(oldValue, newValue, propPath);
        if (op) {
          ops.push(op);
        }

        // remove all cell borders
        const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
        const rowsData = tableData?.childNodes?.[0].childNodes;

        if (rowsData) {
          for (let r = 0; r < rowsData.length; r++) {
            const row = rowsData[r];

            if (row.childNodes) {
              for (let c = 0; c < row.childNodes.length; c++) {
                const cell = row.childNodes[c];

                if (cell && cell.properties?.p !== undefined) {
                  const propPath = [
                    ...rowsPath,
                    r,
                    'childNodes',
                    c,
                    level0Model.KEYS.PROPERTIES,
                    'p',
                  ];

                  const op = this.getObjectOperationforPathValue(
                    cell.properties?.p,
                    null,
                    propPath,
                  );
                  if (op) {
                    ops.push(op);
                  }
                }
              }
            }
          }
        }

        return ops;
      }

      private getOpsUpdateCellPading(
        level0Model: NodeModel,
        tableId: string,
        cellIds: string[],
        padding: Editor.Styles.PaddingProp,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        const tableData = level0Model.getChildDataById(tableId);
        const tablePath = level0Model.findPathToChild(tableId);

        const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
        const rowsData = tableData?.childNodes?.[0].childNodes;

        if (rowsData) {
          for (let r = 0; r < rowsData.length; r++) {
            const row = rowsData[r];

            if (row.childNodes) {
              for (let c = 0; c < row.childNodes.length; c++) {
                const cell = row.childNodes[c];

                if (cell.id && cellIds.includes(cell.id)) {
                  let propPath = [
                    ...rowsPath,
                    r,
                    'childNodes',
                    c,
                    level0Model.KEYS.PROPERTIES,
                    'p',
                  ];
                  let oldValue = undefined;

                  let newValue: Editor.Data.Node.Padding = {};
                  if (cell.properties?.p) {
                    newValue = { ...cell.properties?.p };
                  }

                  if (padding.top != null) {
                    newValue.t = padding.top.value;
                  }

                  if (padding.bottom != null) {
                    newValue.b = padding.bottom.value;
                  }

                  if (padding.left != null) {
                    newValue.l = padding.left.value;
                  }

                  if (padding.right != null) {
                    newValue.r = padding.right.value;
                  }

                  const op = this.getObjectOperationforPathValue(oldValue, newValue, propPath);
                  if (op) {
                    ops.push(op);
                  }
                }
              }
            }
          }
        }

        return ops;
      }

      private getOpsUpdateTableCellBorder(
        level0Model: NodeModel,
        tableId: string,
        border: Editor.Styles.BorderProp,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        if (
          border.color !== undefined ||
          border.style !== undefined ||
          border.width !== undefined
        ) {
          const tableData = level0Model.getChildDataById(tableId);
          const tablePath = level0Model.findPathToChild(tableId);

          // update table default borders
          const borderPropPath = [...tablePath, level0Model.KEYS.PROPERTIES, 'cb'];

          let newBorderValue: Editor.Data.Node.Borders | null = null;
          if (tableData.properties?.cb) {
            newBorderValue = JSON.parse(JSON.stringify(tableData.properties.cb));
          }

          if (!newBorderValue) {
            newBorderValue = {};
          }

          this.updateBorderObject(newBorderValue, border, 'all');

          const op = this.getObjectOperationforPathValue(
            tableData.properties?.cb,
            newBorderValue,
            borderPropPath,
          );
          if (op) {
            ops.push(op);
          }

          // remove all cell borders
          const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
          const rowsData = tableData?.childNodes?.[0].childNodes;

          if (rowsData) {
            for (let r = 0; r < rowsData.length; r++) {
              const row = rowsData[r];

              if (row.childNodes) {
                for (let c = 0; c < row.childNodes.length; c++) {
                  const cell = row.childNodes[c];

                  if (cell && cell.properties?.b !== undefined) {
                    const propPath = [
                      ...rowsPath,
                      r,
                      'childNodes',
                      c,
                      level0Model.KEYS.PROPERTIES,
                      'b',
                    ];

                    const op = this.getObjectOperationforPathValue(
                      cell.properties?.b,
                      null,
                      propPath,
                    );
                    if (op) {
                      ops.push(op);
                    }
                  }
                }
              }
            }
          }
        }

        return ops;
      }

      private updateBorderObject(
        newBorder: Editor.Data.Node.Borders,
        borderProp: Editor.Styles.BorderProp,
        borderChosen: Extract<
          Editor.Styles.BorderChosen,
          'all' | 'bottom' | 'top' | 'left' | 'right' | 'none'
        >,
      ) {
        let width =
          borderProp.width?.value !== undefined
            ? EditorDOMUtils.convertUnitTo(borderProp.width.value, null, 'pt', 3)
            : undefined;

        switch (borderChosen) {
          case 'all': {
            // color
            if (borderProp.color !== undefined) {
              const color = BaseViewBuilder.colorMapper.parse(borderProp.color.value);
              if (newBorder.t) {
                newBorder.t.c = color;
              } else {
                newBorder.t = {
                  c: color,
                };
              }
              if (newBorder.b) {
                newBorder.b.c = color;
              } else {
                newBorder.b = {
                  c: color,
                };
              }
              if (newBorder.l) {
                newBorder.l.c = color;
              } else {
                newBorder.l = {
                  c: color,
                };
              }
              if (newBorder.r) {
                newBorder.r.c = color;
              } else {
                newBorder.r = {
                  c: color,
                };
              }
            }

            // style
            if (borderProp.style !== undefined) {
              const style = BaseViewBuilder.borderStyleMapper.parse(borderProp.style.value);
              if (style === 'd' && width != null && width !== 0 && width < MIN_DOUBLE_BORDER) {
                width = MIN_DOUBLE_BORDER;
              }

              if (newBorder.t) {
                newBorder.t.s = style;
              } else {
                newBorder.t = {
                  s: style,
                };
              }
              if (newBorder.b) {
                newBorder.b.s = style;
              } else {
                newBorder.b = {
                  s: style,
                };
              }
              if (newBorder.l) {
                newBorder.l.s = style;
              } else {
                newBorder.l = {
                  s: style,
                };
              }
              if (newBorder.r) {
                newBorder.r.s = style;
              } else {
                newBorder.r = {
                  s: style,
                };
              }
            }

            //width
            if (width !== undefined && !isNaN(width)) {
              if (newBorder.t) {
                newBorder.t.w = width;
              } else {
                newBorder.t = {
                  w: width,
                };
              }
              if (newBorder.b) {
                newBorder.b.w = width;
              } else {
                newBorder.b = {
                  w: width,
                };
              }
              if (newBorder.l) {
                newBorder.l.w = width;
              } else {
                newBorder.l = {
                  w: width,
                };
              }
              if (newBorder.r) {
                newBorder.r.w = width;
              } else {
                newBorder.r = {
                  w: width,
                };
              }
            }
            return newBorder;
          }
          case 'bottom': {
            if (borderProp.color !== undefined) {
              const color = BaseViewBuilder.colorMapper.parse(borderProp.color.value);
              if (newBorder.b) {
                newBorder.b.c = color;
              } else {
                newBorder.b = {
                  c: color,
                };
              }
            }

            // style
            if (borderProp.style !== undefined) {
              const style = BaseViewBuilder.borderStyleMapper.parse(borderProp.style.value);
              if (newBorder.b) {
                newBorder.b.s = style;
              } else {
                newBorder.b = {
                  s: style,
                };
              }
            }

            //width
            if (width !== undefined && !isNaN(width)) {
              if (newBorder.b) {
                newBorder.b.w = width;
              } else {
                newBorder.b = {
                  w: width,
                };
              }
            }
            return newBorder;
          }
          case 'left': {
            if (borderProp.color !== undefined) {
              const color = BaseViewBuilder.colorMapper.parse(borderProp.color.value);
              if (newBorder.l) {
                newBorder.l.c = color;
              } else {
                newBorder.l = {
                  c: color,
                };
              }
            }

            // style
            if (borderProp.style !== undefined) {
              const style = BaseViewBuilder.borderStyleMapper.parse(borderProp.style.value);
              if (newBorder.l) {
                newBorder.l.s = style;
              } else {
                newBorder.l = {
                  s: style,
                };
              }
            }

            //width
            if (width !== undefined && !isNaN(width)) {
              if (newBorder.l) {
                newBorder.l.w = width;
              } else {
                newBorder.l = {
                  w: width,
                };
              }
            }
            return newBorder;
          }
          case 'right': {
            if (borderProp.color !== undefined) {
              const color = BaseViewBuilder.colorMapper.parse(borderProp.color.value);
              if (newBorder.r) {
                newBorder.r.c = color;
              } else {
                newBorder.r = {
                  c: color,
                };
              }
            }

            // style
            if (borderProp.style !== undefined) {
              const style = BaseViewBuilder.borderStyleMapper.parse(borderProp.style.value);
              if (newBorder.r) {
                newBorder.r.s = style;
              } else {
                newBorder.r = {
                  s: style,
                };
              }
            }

            //width
            if (width !== undefined && !isNaN(width)) {
              if (newBorder.r) {
                newBorder.r.w = width;
              } else {
                newBorder.r = {
                  w: width,
                };
              }
            }
            return newBorder;
          }
          case 'top': {
            if (borderProp.color !== undefined) {
              const color = BaseViewBuilder.colorMapper.parse(borderProp.color.value);
              if (newBorder.t) {
                newBorder.t.c = color;
              } else {
                newBorder.t = {
                  c: color,
                };
              }
            }

            // style
            if (borderProp.style !== undefined) {
              const style = BaseViewBuilder.borderStyleMapper.parse(borderProp.style.value);
              if (newBorder.t) {
                newBorder.t.s = style;
              } else {
                newBorder.t = {
                  s: style,
                };
              }
            }

            //width
            if (width !== undefined && !isNaN(width)) {
              if (newBorder.t) {
                newBorder.t.w = width;
              } else {
                newBorder.t = {
                  w: width,
                };
              }
            }
            return newBorder;
          }
          case 'none':
            return null;
        }
      }

      private getOpsUpdateCellBorder(
        level0Model: NodeModel,
        tableId: string,
        cellIds: string[],
        rowsIndex: number[],
        columnsIndex: number[],
        border: Editor.Styles.BorderProp,
        fullTable: boolean = false,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        if (
          border.color !== undefined ||
          border.style !== undefined ||
          border.width !== undefined
        ) {
          const tableData = level0Model.getChildDataById(tableId);
          const tablePath = level0Model.findPathToChild(tableId);

          const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
          const rowsData = tableData?.childNodes?.[0].childNodes;

          const borderChosen = border.chosen?.value || 'all';

          if (rowsData) {
            for (let r = 0; r < rowsData.length; r++) {
              const row = rowsData[r];

              if (row.childNodes) {
                const cellsData = row.childNodes;
                for (let c = 0; c < cellsData.length; c++) {
                  const cell = cellsData[c];

                  const propPath = [
                    ...rowsPath,
                    r,
                    'childNodes',
                    c,
                    level0Model.KEYS.PROPERTIES,
                    'b',
                  ];
                  let newBorderValue: Editor.Data.Node.Borders | null = null;
                  if (
                    cell.properties?.b?.t ||
                    cell.properties?.b?.b ||
                    cell.properties?.b?.l ||
                    cell.properties?.b?.r
                  ) {
                    newBorderValue = JSON.parse(JSON.stringify(cell.properties.b));
                  }

                  if (!newBorderValue) {
                    newBorderValue = {};
                  }

                  if (cell && cell.id && (cellIds.includes(cell.id) || fullTable)) {
                    // cells within selection
                    switch (borderChosen) {
                      case 'all':
                        this.updateBorderObject(newBorderValue, border, 'all');
                        break;
                      case 'top':
                        if (r === rowsIndex[0] || (fullTable && r === 0)) {
                          this.updateBorderObject(newBorderValue, border, 'top');
                        }
                        break;
                      case 'bottom':
                        if (
                          r === rowsIndex[rowsIndex.length - 1] ||
                          (fullTable && r === rowsData.length - 1)
                        ) {
                          this.updateBorderObject(newBorderValue, border, 'bottom');
                        }
                        break;

                      case 'left':
                        if (c === columnsIndex[0] || (fullTable && c === 0)) {
                          this.updateBorderObject(newBorderValue, border, 'left');
                        }
                        break;
                      case 'right':
                        if (
                          c === columnsIndex[columnsIndex.length - 1] ||
                          (fullTable && c === cellsData.length - 1)
                        ) {
                          this.updateBorderObject(newBorderValue, border, 'right');
                        }
                        break;

                      case 'outside':
                        if (r === rowsIndex[0] || (fullTable && r === 0)) {
                          this.updateBorderObject(newBorderValue, border, 'top');
                        }
                        if (
                          r === rowsIndex[rowsIndex.length - 1] ||
                          (fullTable && r === rowsData.length - 1)
                        ) {
                          this.updateBorderObject(newBorderValue, border, 'bottom');
                        }
                        if (c === columnsIndex[0] || (fullTable && c === 0)) {
                          this.updateBorderObject(newBorderValue, border, 'left');
                        }
                        if (
                          c === columnsIndex[columnsIndex.length - 1] ||
                          (fullTable && c === cellsData.length - 1)
                        ) {
                          this.updateBorderObject(newBorderValue, border, 'right');
                        }
                        break;
                      case 'inside':
                        if (fullTable) {
                          if (r !== 0) {
                            this.updateBorderObject(newBorderValue, border, 'top');
                          }
                          if (r !== rowsData.length - 1) {
                            this.updateBorderObject(newBorderValue, border, 'bottom');
                          }
                        } else if (rowsIndex.length > 1) {
                          if (r !== rowsIndex[0]) {
                            this.updateBorderObject(newBorderValue, border, 'top');
                          }
                          if (r !== rowsIndex[rowsIndex.length - 1]) {
                            this.updateBorderObject(newBorderValue, border, 'bottom');
                          }
                        }

                        if (fullTable) {
                          if (c !== 0) {
                            this.updateBorderObject(newBorderValue, border, 'left');
                          }
                          if (c !== cellsData.length - 1) {
                            this.updateBorderObject(newBorderValue, border, 'right');
                          }
                        } else if (columnsIndex.length > 1) {
                          if (c !== columnsIndex[0]) {
                            this.updateBorderObject(newBorderValue, border, 'left');
                          }
                          if (c !== columnsIndex[columnsIndex.length - 1]) {
                            this.updateBorderObject(newBorderValue, border, 'right');
                          }
                        }
                        break;
                      case 'none':
                        newBorderValue = null;
                        break;
                    }

                    const op = this.getObjectOperationforPathValue(
                      cell.properties?.b,
                      newBorderValue,
                      propPath,
                    );
                    if (op) {
                      ops.push(op);
                    }
                  } else if (
                    r === rowsIndex[0] - 1 &&
                    columnsIndex.includes(c) &&
                    (borderChosen === 'all' || borderChosen === 'top' || borderChosen === 'outside')
                  ) {
                    // ROW ABOVE
                    this.updateBorderObject(newBorderValue, border, 'bottom');
                    const op = this.getObjectOperationforPathValue(
                      cell.properties?.b,
                      newBorderValue,
                      propPath,
                    );
                    if (op) {
                      ops.push(op);
                    }
                  } else if (
                    r === rowsIndex[rowsIndex.length - 1] + 1 &&
                    columnsIndex.includes(c) &&
                    (borderChosen === 'all' ||
                      borderChosen === 'bottom' ||
                      borderChosen === 'outside')
                  ) {
                    // ROW BELOW
                    this.updateBorderObject(newBorderValue, border, 'top');
                    const op = this.getObjectOperationforPathValue(
                      cell.properties?.b,
                      newBorderValue,
                      propPath,
                    );
                    if (op) {
                      ops.push(op);
                    }
                  } else if (
                    c === columnsIndex[0] - 1 &&
                    rowsIndex.includes(r) &&
                    (borderChosen === 'all' ||
                      borderChosen === 'left' ||
                      borderChosen === 'outside')
                  ) {
                    // COLUMN BEFORE
                    this.updateBorderObject(newBorderValue, border, 'right');
                    const op = this.getObjectOperationforPathValue(
                      cell.properties?.b,
                      newBorderValue,
                      propPath,
                    );
                    if (op) {
                      ops.push(op);
                    }
                  } else if (
                    c === columnsIndex[columnsIndex.length - 1] + 1 &&
                    rowsIndex.includes(r) &&
                    (borderChosen === 'all' ||
                      borderChosen === 'right' ||
                      borderChosen === 'outside')
                  ) {
                    // COLUMN AFTER
                    this.updateBorderObject(newBorderValue, border, 'left');
                    const op = this.getObjectOperationforPathValue(
                      cell.properties?.b,
                      newBorderValue,
                      propPath,
                    );
                    if (op) {
                      ops.push(op);
                    }
                  }
                }
              }
            }
          }
        }

        return ops;
      }

      private getOpsUpdateHeaderRows(
        level0Model: NodeModel,
        rowIds: string[],
        headerRow: boolean | null,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        for (let i = 0; i < rowIds.length; i++) {
          const rowData = level0Model.getChildDataById(rowIds[i]);
          const rowPath = level0Model.findPathToChild(rowIds[i]);

          const propPath = [...rowPath, level0Model.KEYS.PROPERTIES, 'hr'];

          const op = this.getObjectOperationforPathValue(
            rowData.properties?.hr,
            headerRow,
            propPath,
          );
          if (op) {
            ops.push(op);
          }
        }

        return ops;
      }

      private getOpsUpdateTableAutoResize(
        level0Model: NodeModel,
        tableId: string,
        autoResize: boolean,
      ) {
        const ops: Realtime.Core.RealtimeOps = [];

        const { data, path } = level0Model.getChildInfoById(tableId);

        const propPath = [...path, level0Model.KEYS.PROPERTIES, 'ar'];

        if (level0Model.isTableData(data)) {
          const op = this.getObjectOperationforPathValue(data.properties?.ar, autoResize, propPath);
          if (op) {
            ops.push(op);
          }
        }

        return ops;
      }

      private getCellIdsPerRowColumn(
        level0Model: NodeModel,
        tableId: string,
        rowsIndex: number[],
        columnsIndex: number[],
      ) {
        const cellsPerColumn: string[] = [];
        const cellsPerRow: string[] = [];
        const rowIds: string[] = [];
        const allRowIndex: number[] = [];
        const allColumnIndex: number[] = [];

        const tableData = level0Model.getChildDataById(tableId);

        const rowsData = tableData?.childNodes?.[0].childNodes;

        if (rowsData) {
          for (let r = 0; r < rowsData.length; r++) {
            const row = rowsData[r];

            if (!allRowIndex.includes(r)) {
              allRowIndex.push(r);
            }

            if (row.id && row.childNodes) {
              for (let c = 0; c < row.childNodes.length; c++) {
                const cell = row.childNodes[c];

                if (!allColumnIndex.includes(c)) {
                  allColumnIndex.push(c);
                }

                if (cell.id) {
                  if (rowsIndex.includes(r)) {
                    cellsPerRow.push(cell.id);
                    if (!rowIds.includes(row.id)) {
                      rowIds.push(row.id);
                    }
                  }

                  if (columnsIndex.includes(c)) {
                    cellsPerColumn.push(cell.id);
                  }
                }
              }
            }
          }
        }

        return { cellsPerRow, cellsPerColumn, rowIds, allRowIndex, allColumnIndex };
      }

      updateTablePropertiesOperation(
        level0Node: HTMLElement,
        table: TableElement,
        propertiesData: Editor.Styles.TablePropertiesData,
        selectedCellsIds: string[],
        rowsIndex: number[],
        columnsIndex: number[],
      ) {
        if (
          !level0Node ||
          !table ||
          !propertiesData ||
          !selectedCellsIds?.length ||
          !rowsIndex?.length ||
          !columnsIndex?.length
        ) {
          return;
        }

        const level0Model = this.dataManager.nodes.getNodeModelById(level0Node.id) as NodeModel;

        const { cellsPerRow, cellsPerColumn, rowIds, allRowIndex, allColumnIndex } =
          this.getCellIdsPerRowColumn(level0Model, table.id, rowsIndex, columnsIndex);

        const ops: Realtime.Core.RealtimeOps = [];

        const elementKeys = Object.keys(propertiesData);
        for (let e = 0; e < elementKeys.length; e++) {
          const elementType = elementKeys[e] as Editor.Styles.TablePropertiesElementKey;

          if (elementType !== 'PAGE') {
            const elementData = propertiesData[elementType];
            if (elementData) {
              const propKeys = Object.keys(elementData);
              for (let k = 0; k < propKeys.length; k++) {
                const property = propKeys[k] as keyof Editor.Styles.TableProperties;

                switch (property) {
                  case 'width': {
                    const value = elementData[property]?.value;
                    if (value !== undefined) {
                      if (elementType === 'TABLE') {
                        ops.push(...this.getOpsUpdateTableWidth(level0Model, table.id, value));
                      } else if (elementType === 'ROW') {
                        ops.push(...this.getOpsUpdateTableWidth(level0Model, table.id, value));
                      } else if (elementType === 'COLUMN') {
                        ops.push(
                          ...this.getOpsUpdateCellsWidth(
                            level0Model,
                            table.id,
                            cellsPerColumn,
                            value,
                          ),
                        );
                      } else if (elementType === 'CELL') {
                        ops.push(
                          ...this.getOpsUpdateCellsWidth(
                            level0Model,
                            table.id,
                            cellsPerColumn,
                            value,
                          ),
                        );
                      }
                    }
                    break;
                  }
                  case 'height': {
                    const value = elementData[property]?.value;
                    if (value !== undefined) {
                      if (elementType === 'TABLE') {
                        ops.push(...this.getOpsUpdateTableHeight(level0Model, table.id, value));
                      } else if (elementType === 'ROW') {
                        ops.push(...this.getOpsUpdateRowsHeight(level0Model, rowIds, value));
                      } else if (elementType === 'COLUMN') {
                        ops.push(...this.getOpsUpdateTableHeight(level0Model, table.id, value));
                      } else if (elementType === 'CELL') {
                        ops.push(...this.getOpsUpdateRowsHeight(level0Model, rowIds, value));
                      }
                    }
                    break;
                  }
                  case 'leftIndentation': {
                    const value = elementData[property];
                    if (value !== undefined) {
                      ops.push(...this.getOpsUpdateTableIndentation(level0Model, table.id, value));
                    }
                    break;
                  }
                  case 'alignment': {
                    const value = elementData[property];
                    if (elementType === 'TABLE' && value !== undefined) {
                      ops.push(
                        ...this.getOpsUpdateTableAlignment(
                          level0Model,
                          table.id,
                          BaseViewBuilder.alignmentMapper.parse(value),
                        ),
                      );
                    }
                    break;
                  }
                  case 'textAlignment': {
                    const value = elementData[property];
                    if (value !== undefined) {
                      if (elementType === 'TABLE') {
                        ops.push(
                          ...this.getOpsUpdateTableTextAlignment(level0Model, table.id, value),
                        );
                      } else if (elementType === 'ROW') {
                        ops.push(
                          ...this.getOpsUpdateTextAlignment(
                            level0Model,
                            table.id,
                            cellsPerRow,
                            value,
                          ),
                        );
                      } else if (elementType === 'COLUMN') {
                        ops.push(
                          ...this.getOpsUpdateTextAlignment(
                            level0Model,
                            table.id,
                            cellsPerColumn,
                            value,
                          ),
                        );
                      } else if (elementType === 'CELL') {
                        ops.push(
                          ...this.getOpsUpdateTextAlignment(
                            level0Model,
                            table.id,
                            selectedCellsIds,
                            value,
                          ),
                        );
                      }
                    }
                    break;
                  }
                  case 'verticalAlignment': {
                    const value = elementData[property];
                    if (value !== undefined) {
                      if (elementType === 'TABLE') {
                        ops.push(
                          ...this.getOpsUpdateTableCellVerticalAlignment(
                            level0Model,
                            value,
                            table.id,
                          ),
                        );
                      } else if (elementType === 'ROW') {
                        ops.push(
                          ...this.getOpsUpdateCellVerticalAlignment(
                            level0Model,
                            value,
                            table.id,
                            cellsPerRow,
                          ),
                        );
                      } else if (elementType === 'COLUMN') {
                        ops.push(
                          ...this.getOpsUpdateCellVerticalAlignment(
                            level0Model,
                            value,
                            table.id,
                            cellsPerColumn,
                          ),
                        );
                      } else if (elementType === 'CELL') {
                        ops.push(
                          ...this.getOpsUpdateCellVerticalAlignment(
                            level0Model,
                            value,
                            table.id,
                            selectedCellsIds,
                          ),
                        );
                      }
                    }
                    break;
                  }
                  case 'background': {
                    const value = elementData[property];
                    if (value !== undefined) {
                      if (elementType === 'TABLE') {
                        ops.push(
                          ...this.getOpsUpdateTableCellBackground(level0Model, table.id, value),
                        );
                      } else if (elementType === 'ROW') {
                        ops.push(
                          ...this.getOpsUpdateCellBackground(
                            level0Model,
                            table.id,
                            cellsPerRow,
                            value,
                          ),
                        );
                      } else if (elementType === 'COLUMN') {
                        ops.push(
                          ...this.getOpsUpdateCellBackground(
                            level0Model,
                            table.id,
                            cellsPerColumn,
                            value,
                          ),
                        );
                      } else if (elementType === 'CELL') {
                        ops.push(
                          ...this.getOpsUpdateCellBackground(
                            level0Model,
                            table.id,
                            selectedCellsIds,
                            value,
                          ),
                        );
                      }
                    }
                    break;
                  }
                  case 'padding': {
                    const value = elementData[property];
                    if (value !== undefined) {
                      if (elementType === 'TABLE') {
                        ops.push(...this.getOpsUpdateTableCellPading(level0Model, table.id, value));
                      } else if (elementType === 'ROW') {
                        ops.push(
                          ...this.getOpsUpdateCellPading(level0Model, table.id, cellsPerRow, value),
                        );
                      } else if (elementType === 'COLUMN') {
                        ops.push(
                          ...this.getOpsUpdateCellPading(
                            level0Model,
                            table.id,
                            cellsPerColumn,
                            value,
                          ),
                        );
                      } else if (elementType === 'CELL') {
                        ops.push(
                          ...this.getOpsUpdateCellPading(
                            level0Model,
                            table.id,
                            selectedCellsIds,
                            value,
                          ),
                        );
                      }
                    }
                    break;
                  }
                  case 'border': {
                    const value = elementData[property];
                    if (value !== undefined) {
                      if (elementType === 'TABLE') {
                        if (value.chosen?.value != null && value.chosen?.value !== 'all') {
                          ops.push(
                            ...this.getOpsUpdateCellBorder(
                              level0Model,
                              table.id,
                              [],
                              [],
                              [],
                              value,
                              true,
                            ),
                          );
                        } else {
                          ops.push(
                            ...this.getOpsUpdateTableCellBorder(level0Model, table.id, value),
                          );
                        }
                      } else if (elementType === 'ROW') {
                        ops.push(
                          ...this.getOpsUpdateCellBorder(
                            level0Model,
                            table.id,
                            cellsPerRow,
                            rowsIndex,
                            allColumnIndex,
                            value,
                          ),
                        );
                      } else if (elementType === 'COLUMN') {
                        ops.push(
                          ...this.getOpsUpdateCellBorder(
                            level0Model,
                            table.id,
                            cellsPerColumn,
                            allRowIndex,
                            columnsIndex,
                            value,
                          ),
                        );
                      } else if (elementType === 'CELL') {
                        ops.push(
                          ...this.getOpsUpdateCellBorder(
                            level0Model,
                            table.id,
                            selectedCellsIds,
                            rowsIndex,
                            columnsIndex,
                            value,
                          ),
                        );
                      }
                    }
                    break;
                  }
                  case 'headerRow': {
                    const value = elementData[property]?.value;
                    if (elementType === 'ROW' && value !== undefined) {
                      ops.push(...this.getOpsUpdateHeaderRows(level0Model, rowIds, value));
                    }
                    break;
                  }
                  case 'autoResize': {
                    const value = elementData[property];
                    if (elementType === 'TABLE' && value !== undefined) {
                      ops.push(...this.getOpsUpdateTableAutoResize(level0Model, table.id, value));
                    }
                  }
                }
              }
            }
          }
        }

        // apply ops
        if (ops.length > 0) {
          level0Model.apply(ops, { source: 'LOCAL_RENDER' });
        }
      }
    },
);
