import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { detectOverflow } from '@popperjs/core';
import { Button, Popover, Toggle, usePopper, Accordion } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { selectTotalAnnotationsFilters, setClearAllFilters } from 'PDF/redux/PDFAnnotationsSlice';

import Type from './FilterSections/TypeFilter';
import Status from './FilterSections/StatusFilter';
import Priority from './FilterSections/PriorityFilter';
import Users from './FilterSections/UsersFilter';
import CreationDate from './FilterSections/CreationDateFilter';

import type { PopperProps } from 'dodoc-design-system/build/types/Components/Popper/Popper';

import styles from './Filters.module.scss';

const Filters = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { canAccess } = usePDFPermissions();

  const filters = useSelector((state) => state.pdf.annotations.filters);
  const totalAnnotationsFilters = useSelector(selectTotalAnnotationsFilters);

  const [popperMaxHeight, setPopperMaxHeight] = useState(0);

  //@ts-expect-error
  const modifiers: PopperProps['modifiers'] = useMemo(() => {
    return [
      {
        name: 'resizePopper',
        enabled: true,
        phase: 'beforeWrite',
        requiresIfExists: ['offset'],

        fn({ state }) {
          const padding = 40; //px
          const maxHeight = 736; //px

          const overflow = detectOverflow(state, {
            padding: { top: padding, bottom: padding },
          });

          let newHeight = 0;

          if (overflow.bottom > 0) {
            newHeight = state.rects.popper.height - overflow.bottom;

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
            }

            state.styles.popper.maxHeight = `${newHeight}px`;
          } else {
            newHeight = state.rects.popper.height + Math.abs(overflow.bottom);

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
            }

            state.styles.popper.maxHeight = `${newHeight}px`;
          }

          setPopperMaxHeight(newHeight);
        },
        effect(meta) {
          meta.instance.forceUpdate(); //force update to update popper position when open popover
        },
      },
    ];
  }, []);

  const { isOpen, popperProps, referenceProps } = usePopper({
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers,
  });

  const handleClearAllFilters = () => {
    dispatch(setClearAllFilters());
  };

  return (
    <>
      <Toggle
        variant="ghost"
        icon={isOpen ? 'FilterBlue' : 'FilterGrey'}
        size="medium"
        isToggled={isOpen}
        {...referenceProps}
        iconBadge={
          totalAnnotationsFilters > 0
            ? {
                icon: 'Badge',
                size: 12,
                number: totalAnnotationsFilters,
                position: 'top-right',
              }
            : undefined
        }
        disabled={!canAccess}
        testId="sidebar-comments-filters-toggle"
      />
      <Popover {...popperProps} testId="sidebar-comments-filters-popper">
        <div className={styles.popover} style={{ maxHeight: `${popperMaxHeight - 2}px` }}>
          <div className={styles.header}>
            <div className={styles.text}>
              <FormattedMessage id="FILTERS" />
            </div>
            <Button
              variant="link"
              size="small"
              onClick={handleClearAllFilters}
              disabled={totalAnnotationsFilters === 0}
              testId="sidebar-comments-filters-clear-button"
            >
              <FormattedMessage id="CLEAR_ALL" />
            </Button>
          </div>
          <div className={styles.container}>
            <Accordion
              variant="ghost"
              title={intl.formatMessage({ id: 'STATUS' })}
              size="large"
              initialCollapsed={filters['status']?.length > 0 ? false : true}
              testId="sidebar-comments-filters-status"
            >
              <Status />
            </Accordion>
            <Accordion
              variant="ghost"
              title={intl.formatMessage({ id: 'editor.sidebar.review.filter.byType' })}
              size="large"
              initialCollapsed={filters['type']?.length > 0 ? false : true}
              testId="sidebar-comments-filters-type"
            >
              <Type />
            </Accordion>
            <Accordion
              variant="ghost"
              title={intl.formatMessage({ id: 'global.priority' })}
              size="large"
              initialCollapsed={filters['priority']?.length > 0 ? false : true}
              testId="sidebar-comments-filters-priority"
            >
              <Priority />
            </Accordion>
            <Accordion
              variant="ghost"
              title={intl.formatMessage({ id: 'USERS' })}
              size="large"
              initialCollapsed={!(filters && filters['users'] && filters['users']?.length > 0)}
              testId="sidebar-comments-filters-users"
            >
              <Users />
            </Accordion>
            <Accordion
              variant="ghost"
              title={intl.formatMessage({ id: 'CREATION_DATE' })}
              size="large"
              initialCollapsed={!filters['creationDate']}
              testId="sidebar-comments-filters-creation-date"
            >
              <CreationDate />
            </Accordion>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default Filters;
