import {
  MouseEvent,
  useLayoutEffect,
  useRef,
  useState,
  forwardRef,
  Ref,
  MouseEventHandler,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, Divider, Dropdown, Label, Toggle, usePopper } from 'dodoc-design-system';
import { LabelProps } from 'dodoc-design-system/build/types/Components/Label/Label';

import { useDispatch, useIsNodeClamped, useSelector } from '_common/hooks';
import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';

import { DEFAULT_CARD_STYLES_PANEL, setEditingAnnotation } from 'PDF/redux/PDFAnnotationsSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { RichTextEditor, UserPresentation } from '_common/components';
import { ReplyList, ReplyToggle } from 'PDF/components/Cards';
import { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';

import DueDateBanner from './DueDateBanner/DueDateBanner';
import NoAssignee from './NoAssignee/NoAssignee';

import styles from './ViewTaskCard.module.scss';

export type ViewTaskCardProps = {
  task: PDF.Annotation.Task;
  sidebar: boolean;
  index?: number;
  order?: number;
  testId: string;
};

const STATUS: {
  [key in PDF.Annotation.TaskStatus]: { label: string; color: LabelProps['color'] };
} = {
  td: { label: 'TODO', color: 'neutral' },
  pr: { label: 'IN_PROGRESS', color: 'blue' },
  d: { label: 'auth.login.done', color: 'green' },
};

const ViewTaskCardContent = forwardRef(
  ({ task, sidebar, index, order, testId }: ViewTaskCardProps, ref: Ref<HTMLDivElement>) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const pdfManager = usePDFContext();

    const { referenceProps, popperProps } = usePopper({ closeOnReferenceHidden: true });
    const mainEditorRef = useRef<RichTextEditorHandler>(null);
    const isClamped = useIsNodeClamped({
      ref: mainEditorRef.current?.editorRef,
      clamp: 3,
      dependencies: [task, mainEditorRef.current],
    });

    const currentUserId = useSelector((state) => state.auth.userId);
    const isSelected = useSelector((state) => state.pdf.annotations.selected === task.id);
    const isListMode = useSelector((state) => state.pdf.annotations.tasksListMode);

    const {
      canEditAnnotation,
      canDeleteAnnotation,
      canChangeAnnotationStatus,
      canWatchAnnotation,
    } = usePDFPermissions();

    const isAssignee = task.assignee === currentUserId;

    const handleTaskStatus = () => {
      if (task.state === 'Cancelled') {
        return { label: 'editor.sidebar.review.filter.status.deleted', color: 'red' } as {
          label: string;
          color: LabelProps['color'];
        };
      } else if (task.status) {
        return STATUS[task.status as keyof typeof STATUS];
      } else {
        return undefined;
      }
    };

    const getCardHeaderColor = () => {
      if (task.state === 'Cancelled') {
        return 'red';
      } else if (task.status === 'd') {
        return 'green';
      }
    };

    const [readMore, setReadMore] = useState(true);
    const [showReplies, setShowReplies] = useState(false);

    useLayoutEffect(() => {
      if (!isClamped) {
        setReadMore(false);
      }
    }, [isClamped]);

    const handleSetNewStatus = async (newStatus: keyof typeof STATUS) => {
      try {
        await pdfManager.changeTaskStatus(task.pageNumber, task.id, newStatus);
      } catch (error) {
        throw error;
      }
    };

    const handleEditTask = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      dispatch(setEditingAnnotation({ id: task.id, isTask: true }));
    };

    const handleDeleteTask = () => {
      dispatch(
        openAndUpdateModal({
          modal: 'PDFConfirmationModal',
          data: {
            title: 'DELETE_TASK',
            message: 'DELETING_THIS_TASK_WILL_PERMANENTLY_REMOVE_IT_CONFIRM',
            messageValues: { taskNumber: (index ?? 0) + 1 },
            cancelButtonTextId: 'global.cancel',
            confirmButtonType: 'danger',
            confirmButtonTextId: 'global.delete',
            headerType: 'error',
            actionCode: 'deleteTask',
            actionValue: {
              taskId: task.id,
              pdf: true,
              pageNumber: task.pageNumber,
            },
            cancelButtonShow: true,
          },
        }),
      );
    };

    const handleOpenWatchModal = () => {
      dispatch(openAndUpdateModal({ modal: 'PDFTaskWatchModal', data: { taskId: task.id } }));
    };

    const handleWatchTask = () => {
      if (!task.watchers?.includes(currentUserId)) {
        pdfManager.watchTask(task.pageNumber, task.id, currentUserId);
      } else {
        pdfManager.removeWatchFromTask(task.pageNumber, task.id, currentUserId);
      }
    };

    const handleToggleReplies = () => {
      setShowReplies(!showReplies);
    };
    const handleScrollToAnnotation = () => {
      if (sidebar) {
        pdfManager.selectAnnotation(task);
      }
    };

    const handleToggleReadMore: MouseEventHandler<HTMLDivElement> = (e) => {
      /**
       * Stop propagation to avoid card selection
       * Card selection will invoke scrollIntoView and app might pause to load new content
       */
      e.stopPropagation();

      setReadMore(!readMore);
    };

    return (
      <span data-testid={testId ? `${testId}-view` : undefined}>
        {sidebar && isListMode && !isSelected ? (
          <div
            onClick={handleScrollToAnnotation}
            className={`${styles.minifiedCard} ${task.status === 'd' && styles.done} ${
              task.state === 'Cancelled' && styles.deleted
            }`}
          >
            <div className={styles.order}>{`#${order}`}</div>
            <div className={styles.description}>
              <RichTextEditor
                initialValue={task.content?.content ? JSON.stringify(task.content.content) : ''}
                readOnly
                expanded={false}
                maxLines={1}
                testId={`${testId}-rich-text-editor`}
              />
            </div>
          </div>
        ) : (
          <Card
            sidebar={sidebar}
            selected={isSelected}
            width={sidebar ? '100%' : undefined}
            onClick={handleScrollToAnnotation}
            ref={ref}
            testId="task-card"
          >
            <Card.Header background={getCardHeaderColor()} size="large">
              <div className={styles.header}>
                {task.assignee ? (
                  <UserPresentation
                    userId={task.assignee}
                    title={intl.formatMessage({ id: 'ASSIGNED_TO' })}
                    fixedName={isAssignee ? intl.formatMessage({ id: 'YOU' }) : undefined}
                  />
                ) : (
                  <NoAssignee />
                )}
                {handleTaskStatus() && (
                  <Label
                    size="small"
                    margin="0 0 0 auto"
                    color={handleTaskStatus()?.color}
                    testId="task-status-label"
                  >
                    {intl.formatMessage({ id: handleTaskStatus()?.label })}
                  </Label>
                )}
              </div>
            </Card.Header>
            {/* Add divider when card header has a non-default color */}
            {getCardHeaderColor() && !task.dueDate && <Divider margin="0" />}
            {task.dueDate && <DueDateBanner dueDate={task.dueDate} />}
            <Card.Body>
              <div className={styles.body}>
                <div className={styles.header}>
                  <UserPresentation
                    userId={task.authorId ?? ''}
                    title={intl.formatMessage({ id: 'REPORTER' })}
                  />
                  <Toggle
                    size="medium"
                    variant="link"
                    margin="0 0 0 auto"
                    icon={popperProps.isOpen ? 'PDFOptionsBlue' : 'PDFOptionsGrey'}
                    isToggled={popperProps.isOpen}
                    testId={`${testId}-moreOptions`}
                    {...referenceProps}
                  />
                  <Dropdown {...popperProps} testId={`${testId}-dropdown`}>
                    <Dropdown.Item
                      onClick={handleEditTask}
                      disabled={!canEditAnnotation(task)}
                      testId={`${testId}-moreOptions-edit`}
                    >
                      <FormattedMessage id="EDIT_TASK" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleDeleteTask}
                      disabled={!canDeleteAnnotation(task)}
                      testId={`${testId}-moreOptions-delete`}
                    >
                      <FormattedMessage id="DELETE_TASK" />
                    </Dropdown.Item>
                    <Dropdown.SubMenu
                      itemContent={<FormattedMessage id="CHANGE_STATUS_TASK" />}
                      placement="left"
                      disabled={!canChangeAnnotationStatus(task)}
                      testId={`${testId}-moreOptions-changeStatus`}
                    >
                      {(Object.keys(STATUS) as Array<keyof typeof STATUS>).map((key) => (
                        <Dropdown.Item
                          key={STATUS[key].label}
                          onClick={() => handleSetNewStatus(key)}
                          testId={`${testId}-moreOptions-changeStatus-${key}`}
                        >
                          <FormattedMessage id={STATUS[key].label} />
                        </Dropdown.Item>
                      ))}
                    </Dropdown.SubMenu>
                    <Dropdown.Item
                      onClick={handleOpenWatchModal}
                      testId={`${testId}-moreOptions-watch`}
                    >
                      <FormattedMessage id="WATCH_OPTION" />
                    </Dropdown.Item>
                  </Dropdown>
                </div>
                <RichTextEditor
                  readOnly
                  ref={mainEditorRef}
                  initialValue={task.content?.content && JSON.stringify(task.content.content)}
                  expanded={readMore}
                  overwrittenStyles={DEFAULT_CARD_STYLES_PANEL}
                  testId={`${testId}-rich-text-editor`}
                />

                {isClamped && (
                  <div className={styles.readMore} onClick={handleToggleReadMore}>
                    <FormattedMessage
                      id={readMore ? 'editor.comments.readLess' : 'editor.comments.readMore'}
                    />
                  </div>
                )}
              </div>
            </Card.Body>
            <Card.Footer size="large">
              <div className={styles.footer}>
                <Toggle
                  size="medium"
                  variant="link"
                  icon={task.watchers?.includes(currentUserId) ? 'WatcherBlue' : 'WatcherGrey'}
                  isToggled={task.watchers?.includes(currentUserId)}
                  onClick={handleWatchTask}
                  disabled={!canWatchAnnotation}
                  testId={`${testId}-watch-toggle`}
                />
                <ReplyToggle
                  repliesCount={task.replies?.length ?? 0}
                  isToggled={showReplies}
                  onToggleReplies={handleToggleReplies}
                />
              </div>
            </Card.Footer>
            {showReplies && (
              <ReplyList
                replies={task.replies ?? []}
                pageNumber={task.pageNumber}
                annotationId={task.id}
                testId={`${testId}-replyList`}
              />
            )}
          </Card>
        )}
      </span>
    );
  },
);

export default ViewTaskCardContent;
