import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { createContext, ReactNode, useContext, useMemo } from 'react';

/**
 * Assumes that an admin/owner can do everything, the remaining restrictions are:
 * @property canAddPermissions - user can add permissions to other collaborators
 * @property canRemovePermissions - user can remove permissions from other collaborators
 * @property canEditPermissions - user can add or remove permissions from other collaborators
 * @property canEditPermission - user can't edit own permissions; can only remove if the permission is active; can only add if the permission isn't active
 * @property canEditRoles - user can't edit own roles; can only remove if there are active roles; can add roles if has add permissions
 */
const PERMISSIONS = {
  canAddPermissions: false,
  canRemovePermissions: false,
  canEditPermissions: false,
  canEditPermission: (
    collaboratorId: Permission.Collaborator['collaboratorId'],
    hasPermission: boolean,
  ) => false,
  canEditRoles: (collaboratorId: Permission.Collaborator['collaboratorId'], hasRoles: boolean) =>
    false,
};

const PermissionsContext = createContext<typeof PERMISSIONS>({ ...PERMISSIONS });

const DEFAULT_PERMISSIONS: { [Property in ApiSchemas['PermissionsValues']]: boolean } = {
  admin: false,
  owner: false,
  access: false,
  edit: false,
  delete: false,
  create: false,
  approve: false,
  comment: false,
  add_permission: false,
  remove_permission: false,
  public_link: false,
};

const PermisionsContextProvider = ({
  children,
  object,
}: {
  children: ReactNode;
  object: Objekt | FileObject;
}) => {
  const { data: user } = useGetCurrentUserQuery();

  const permissions = useMemo(() => {
    const permissions = { ...PERMISSIONS };

    if (object && user) {
      const userPermissions = (
        object.user_permissions as (keyof typeof DEFAULT_PERMISSIONS)[]
      ).reduce(
        (permissions, permission) => {
          permissions[permission] = true;
          return permissions;
        },
        { ...DEFAULT_PERMISSIONS },
      );

      userPermissions.admin = user.is_admin || user.is_superuser;
      // There is nothing that an admin can do that an owner cannot
      const isBossMan = userPermissions.admin || userPermissions.owner;

      permissions.canAddPermissions = isBossMan || userPermissions.add_permission;
      permissions.canRemovePermissions = isBossMan || userPermissions.remove_permission;
      permissions.canEditPermissions =
        isBossMan || userPermissions.add_permission || userPermissions.remove_permission;
      permissions.canEditPermission = (collaboratorId, hasPermission) => {
        return (
          isBossMan ||
          (collaboratorId !== user.profile.id &&
            ((hasPermission && userPermissions.remove_permission) ||
              (!hasPermission && userPermissions.add_permission)))
        );
      };
      permissions.canEditRoles = (collaboratorId, hasRoles) => {
        return (
          isBossMan ||
          (collaboratorId !== user.profile.id &&
            ((hasRoles && userPermissions.remove_permission) || userPermissions.add_permission))
        );
      };
    }

    return permissions;
  }, [object, user]);

  return <PermissionsContext.Provider value={permissions}>{children}</PermissionsContext.Provider>;
};

export const usePermissions = () => {
  return useContext(PermissionsContext);
};

export default PermisionsContextProvider;
