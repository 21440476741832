import { FormattedMessage } from 'react-intl';

import styles from './NoAssignee.module.scss';

const NoAssignee = () => {
  return (
    <>
      <div className={styles.avatarPlaceholder} />
      <div className={styles.noAssignee}>
        <FormattedMessage id="NO_USER_ASSIGNED" />
      </div>
    </>
  );
};

export default NoAssignee;
