import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Helper } from 'dodoc-design-system';
import { AxiosResponse } from 'axios';

import { useDispatch, usePublicGroup, usePublicProfiles } from '_common/hooks';

import { giveGroupPermission } from 'App/redux/appSlice';
import { listObjects } from '_common/components/Table/TableSlice';

import { Table, SearchUser } from '_common/components';
import type { UserOption } from '_common/components/SearchUser/SearchUser';
import { SettingGroupHeader } from 'Settings/components';

import GroupSettingsContext from '../GroupSettingsContext';
import AdminsViewRow from './AdminsViewRow';

import styles from '../GroupSettingsPage.module.scss';

const PAGE_IDENTITY = 'groups_admins';
const COLUMNS_ARRAY = [
  {
    id: 'avatar',
    labelId: '',
    size: 6.5,
  },
  {
    id: 'name',
    labelId: 'groups.header.name',
  },
  {
    id: 'email',
    labelId: 'storage.modals.user.email',
    size: 40,
  },
  {
    id: 'permissions',
    labelId: 'groups.header.permissions',
    orderable: true,
    size: 20,
  },
  {
    id: 'settings',
    labelId: '',
    size: 8,
  },
];

const AdminsView = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { groupId } = useContext(GroupSettingsContext);

  const { group } = usePublicGroup(groupId ?? '');
  const usersList = useMemo(() => [group.owner, ...Object.keys(group.permissions.users)], [group]);
  const { profiles } = usePublicProfiles(usersList);

  const [adminSelected, setAdminSelected] = useState<UserOption | null>(null);
  const userIsOwner =
    group.user_permissions.includes('admin') || group.user_permissions.includes('owner');

  //#region Table object listing handlers
  const fetchObjects = useCallback(async () => {
    return new Promise<AxiosResponse<unknown>>((resolve) => {
      resolve({
        data: { nodes: Object.values(profiles) },
        status: 200,
        statusText: '',
        headers: { placeholder: '' },
        config: {},
      });
    });
  }, [profiles]);

  useEffect(() => {
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, [fetchObjects]);
  //#endregion

  const handleOnClickAddAdmin = () => {
    if (adminSelected) {
      dispatch(giveGroupPermission({ groupId: group.id, userId: adminSelected.value }));
      setAdminSelected(null);
    }
  };

  return (
    <>
      {/* Admins */}
      <SettingGroupHeader
        style={{ padding: '16px', marginBottom: '2rem' }}
        actions={
          userIsOwner && (
            <div className={styles.autocompleteContainer}>
              <SearchUser
                value={adminSelected}
                valuesToFilter={[group.owner, ...Object.keys(group.permissions.users)]}
                onChange={setAdminSelected}
                placeholder={intl.formatMessage({
                  id: 'settings.group.placeholderAutocomplete',
                })}
                escapeClearsValue
                width="37rem"
                testId="admins"
              />
              <Button
                size="medium"
                variant="primary"
                style={{ marginLeft: '2rem' }}
                onClick={handleOnClickAddAdmin}
                disabled={!adminSelected}
                testId="admins-add-button"
              >
                <FormattedMessage id="global.add" />
              </Button>
            </div>
          )
        }
      >
        <div className={styles.userPermissionsText}>
          <span className={styles.title}>
            {intl.formatMessage({
              id: 'settings.group.groupAdministratorsTitle',
            })}
          </span>
          <span className={styles.subtitle}>
            {intl.formatMessage({
              id: 'settings.group.groupAdministratorsSubtitle',
            })}
          </span>
        </div>
      </SettingGroupHeader>

      {usersList.length === 0 ? (
        <Helper margin="2rem 0 0 0" testId="group-settings-page-no-admins-helper">
          <FormattedMessage id="groups.emptyList" />
        </Helper>
      ) : (
        <Table
          identity={PAGE_IDENTITY}
          columns={COLUMNS_ARRAY}
          RowComponent={AdminsViewRow}
          fetchObjects={fetchObjects}
          selectable={false}
        />
      )}
    </>
  );
};

export default AdminsView;
