import { useState, useRef, useLayoutEffect, MouseEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Card,
  Dropdown,
  Tooltip,
  usePopper,
  Toggle,
  Button,
  Icon,
  Label,
} from 'dodoc-design-system';
import type { LabelProps } from 'dodoc-design-system/build/types/Components/Label/Label';
import dayjs from 'dayjs';
import { uniq } from 'lodash';

import { stringToRichText } from 'utils';
import { useDispatch, useSelector, useIsNodeClamped, usePublicProfile } from '_common/hooks';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectCollaborators } from 'App/redux/appSlice';
import { setTaskOverlayData } from 'Editor/redux/TasksSlice';
import {
  getDocumentObject,
  selectReadOnlyMode,
  selectUser,
  selectIsPageLayout,
} from 'Editor/redux/EditorStatusSlice';

import { RichTextEditor, UsernameLabel } from '_common/components';
import { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';
import { EditorAvatar, CommentCard, PageLayoutTooltip } from 'Editor/components';

import styles from '../TasksTab.module.scss';
import { setSidebarView } from 'Editor/redux/SidebarSlice';
import EditorManager from 'Editor/services/EditorManager';
import { StatusValue } from 'Editor/services/DataManager';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';

const STATUS: { [key in Editor.Task['s'] | 'dlt']: { label: string; color: LabelProps['color'] } } =
  {
    td: { label: 'TODO', color: 'neutral' },
    pr: { label: 'IN_PROGRESS', color: 'blue' },
    d: { label: 'auth.login.done', color: 'green' },
    dlt: { label: 'editor.sidebar.review.filter.status.deleted', color: 'red' },
  };
type AppendedHeaderProps = {
  task: Editor.Task;
  user: UserId;
};
const AppendedHeader = ({ task, user }: AppendedHeaderProps) => {
  const intl = useIntl();

  const isOverdue = () => {
    if (!task.t.d) {
      return false;
    }

    const dueDate = dayjs(task.t.d);
    if (!dueDate.isValid()) {
      return false;
    }
    if (dayjs().isAfter(dueDate)) {
      return true;
    }

    return false;
  };

  const isMissingThreeDays = () => {
    if (!task.t.d) {
      return false;
    }
    const dueDate = dayjs(task.t.d);
    if (!dueDate.isValid()) {
      return false;
    }

    if (dayjs().isBefore(task.t.d) && Math.abs(dayjs().diff(task.t.d, 'days')) < 4) {
      return true;
    }

    return false;
  };

  const getAssignedName = () => {
    let name: JSX.Element;

    if (task.asg === user) {
      name = <FormattedMessage id="YOU" />;
    } else {
      name = <UsernameLabel userId={task.asg} />;
    }

    return name;
  };

  const getDueDateContent = () => {
    let html: JSX.Element = <span />;
    const redClock = isOverdue();
    const yellowClock = isMissingThreeDays();

    if (task.t && task.t.d) {
      if (redClock) {
        const delay = dayjs().diff(task.t.d, 'days');
        if (delay === 0) {
          html = <FormattedMessage id="TASK_EXPIRE_TODAY" />;
        } else {
          html = <FormattedMessage id="TASK_OVERDUE" values={{ delay }} />;
        }
      } else if (yellowClock) {
        const delay = Math.abs(dayjs().diff(task.t.d, 'days')) + 1;
        html = <FormattedMessage id="TASK_ALMOST_EXPIRE" values={{ delay }} />;
      } else {
        const date = dayjs(task.t.d).format('DD MMM, YYYY');
        html = <FormattedMessage id="TASK_DUEDATE" values={{ date }} />;
      }
    }

    return (
      <Tooltip content={html} testId="due-date-tooltip">
        <Icon icon="VHSidebar" size={24} />
      </Tooltip>
    );
  };

  return (
    <div
      className={`${styles.appendHeader} ${task.s === 'd' && styles.done} ${
        task.t && task.t.dlt && styles.deleted
      }`}
    >
      <div style={{ display: 'flex' }}>
        {task.asg ? (
          <>
            <div>
              <EditorAvatar margin="0 1rem 0 0" userId={task.asg} />
            </div>
            <div className={styles.labels}>
              <div className={styles.assignedTo}>{intl.formatMessage({ id: 'ASSIGNED_TO' })}</div>
              <div className={styles.author}>{getAssignedName()}</div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.noAvatar} />
            <div className={styles.noAssigned}>
              <FormattedMessage id="NO_USER_ASSIGNED" />
            </div>
          </>
        )}

        <div style={{ display: 'flex' }}>
          {task.t && task.t.d && (
            <div className={styles.moreContent} style={{ marginRight: '1rem' }}>
              {getDueDateContent()}
            </div>
          )}
          <div className={styles.moreContent}>
            {(task.s || task.t?.dlt) && (
              <Label
                size="medium"
                color={STATUS[task.t?.dlt ? 'dlt' : task.s].color}
                testId="task-status-label"
              >
                {intl.formatMessage({ id: STATUS[task.t?.dlt ? 'dlt' : task.s].label })}
              </Label>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export type TasksCardProps = {
  task: Editor.Task;
  panel?: boolean;
};
const TasksCardContent = ({ task, panel = false }: TasksCardProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const doc = useSelector(getDocumentObject);
  const user = useSelector(selectUser);
  const isListMode = useSelector((state) => state.editor.tasks.isListMode);
  const selected = useSelector((state) => state.editor.status.selection.TASK);
  const isPageLayout = useSelector(selectIsPageLayout);
  const isReadOnlyMode = useSelector(selectReadOnlyMode) || isPageLayout;
  const collaborators = useSelector((state) => selectCollaborators(state, doc.id));

  const mainEditorRef = useRef<RichTextEditorHandler>(null);
  const replyEditorRef = useRef<RichTextEditorHandler>(null);

  const profile = usePublicProfile(task?.u);
  const { referenceProps, popperProps } = usePopper();
  const isClamped = useIsNodeClamped({
    ref: mainEditorRef.current?.editorRef,
    clamp: 3,
    dependencies: [task?.d, mainEditorRef.current],
  });

  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const [readMore, setReadMore] = useState(true);
  const [showReplyButtons, setShowReplyButtons] = useState(false);
  const [reply, setReply] = useState('');

  useLayoutEffect(() => {
    if (!isClamped) {
      setReadMore(false);
    }
  }, [isClamped]);

  const handleReplyClicked = () => {
    EditorManager.getInstance().replyTask(task.id, stringToRichText(reply));
    setReply('');
    setShowReplyButtons(false);
    replyEditorRef.current?.clear();
  };

  const handleReplyFocused = () => {
    setShowReplyButtons(true);
  };

  const handleReplyChanged = (commentValue: string) => {
    setReply(commentValue);
  };

  const handleCancelReply = () => {
    setReply('');
    setShowReplyButtons(false);
  };

  const handleEditTask = () => {
    const node = document.querySelector(`*[task="${task.id}"]`);
    const offsets = DOMUtils.getOffsets(node);
    dispatch(setTaskOverlayData({ selected: task.id, offsets, operation: 'edit' }));
    if (panel) {
      dispatch(setSidebarView(null));
    }
  };

  const handleDeleteTask = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'DELETE_TASK',
          message: 'DELETING_THIS_TASK_WILL_PERMANENTLY_REMOVE_IT_CONFIRM',
          messageValues: { taskNumber: task.i },
          cancelButtonTextId: 'global.cancel',
          confirmButtonType: 'danger',
          confirmButtonTextId: 'global.delete',
          headerType: 'error',
          actionCode: 'deleteTask',
          actionValue: {
            taskId: task.id,
          },
          cancelButtonShow: true,
        },
      }),
    );
  };

  const handleWatchOptions = () => {
    dispatch(openAndUpdateModal({ modal: 'TaskWatchModal', data: { taskId: task.id } }));
  };

  const changeTaskStatus = (newStatus: StatusValue) => {
    EditorManager.getInstance().changeStatus(task.id, newStatus);
  };

  const handleToggleReadMore: MouseEventHandler<HTMLDivElement> = (e) => {
    /**
     * Stop propagation to avoid card selection
     * Card selection will invoke scrollIntoView and app might pause to load new content
     */
    e.stopPropagation();

    setReadMore(!readMore);
  };

  const handleReplyOpen = () => {
    setIsReplyOpen(!isReplyOpen);
  };

  const handleTasksSelected = () => {
    if (!selected || !selected.tasks.includes(task.id)) {
      dispatch(setTaskOverlayData({ selected: task.id }));
      EditorManager.getInstance().focusTask(task.id);
    }
  };

  const clickWatchOption = () => {
    if (task.u !== user.id && task.asg !== user.id) {
      if (task.w.includes(user.id)) {
        EditorManager.getInstance().removeWatchFromTask(task.id, user.id);
      } else {
        EditorManager.getInstance().watchTask(task.id, user.id);
      }
    }
  };

  const renderSubComments = () => {
    if (!task.r || (task.r && task.r.length === 0)) {
      return null;
    }

    return task.r.map((comment) => (
      <CommentCard
        subcomment
        isTask
        panel={panel}
        user={user.id}
        key={comment.id}
        document={doc}
        isReadOnlyMode={isReadOnlyMode}
        // @ts-expect-error gotta streamline these related types
        comment={comment}
        mainComment={task}
      />
    ));
  };

  const renderHeader = () => {
    const isCreator = task.u === user.id;
    const isAssignee = task.asg === user.id;
    const isOwner =
      doc.user_permissions.includes('owner') || doc.user_permissions.includes('admin');

    // Edit a task: Task Creator or user with Owner permission
    const canEdit = !isReadOnlyMode && (isCreator || isOwner);
    // Delete a task: Task Creator or user with Owner permission
    const canDelete = !isReadOnlyMode && (isCreator || isOwner);
    // Change status of a task: Task Creator, Task assignee or user with Owner permission
    const canChangeStatus = !isReadOnlyMode && (isCreator || isAssignee || isOwner);

    return (
      <div style={{ display: 'flex' }}>
        <EditorAvatar margin="0 1rem 0 0" userId={task.u} />

        <div className={styles.labels}>
          <div className={styles.author}>
            <UsernameLabel userId={task.u} />
          </div>
          <div className={styles.taskNumber}>
            <FormattedMessage id="NUMBER_OF_TASKS" values={{ index: task.i }} />
          </div>
        </div>

        {/* Link Button with 3 vertical dots */}
        {task.t && !task.t.dlt && (
          <div style={{ marginTop: '0.5rem' }}>
            <PageLayoutTooltip
              type="task"
              content={intl.formatMessage({ id: 'global.moreActions' })}
              testId={`task-card-${task.id}-more-options-button-tooltip`}
            >
              <span>
                <Button
                  size="medium"
                  variant="link"
                  style={{ padding: 0 }}
                  margin="0 0 0 1rem"
                  icon="Options"
                  testId={`task-card-${task.id}-more-options-button`}
                  {...referenceProps}
                />
              </span>
            </PageLayoutTooltip>
            <Dropdown {...popperProps} testId={`task-card-${task.id}-more-options-dropdown`}>
              {canEdit && (
                <Dropdown.Item
                  onClick={handleEditTask}
                  testId={`task-card-${task.id}-more-options-edit-task-dropdown-item`}
                >
                  <FormattedMessage id="EDIT_TASK" />
                </Dropdown.Item>
              )}
              {canDelete && (
                <Dropdown.Item
                  onClick={handleDeleteTask}
                  testId={`task-card-${task.id}-more-options-delete-task-dropdown-item`}
                >
                  <FormattedMessage id="DELETE_TASK" />
                </Dropdown.Item>
              )}
              {canChangeStatus && (
                <Dropdown.SubMenu
                  itemContent={<FormattedMessage id="CHANGE_STATUS_TASK" />}
                  placement="left"
                  testId={`task-card-${task.id}-more-options-change-task-status-dropdown-submenu`}
                >
                  {(Object.keys(STATUS) as Array<keyof typeof STATUS>).map((key) =>
                    key === 'dlt' ? null : (
                      <Dropdown.Item
                        key={STATUS[key].label}
                        onClick={() => changeTaskStatus(key)}
                        testId={`task-card-${task.id}-change-task-status-${STATUS[key].label}-dropdown-item`}
                      >
                        <FormattedMessage id={STATUS[key].label} />
                      </Dropdown.Item>
                    ),
                  )}
                </Dropdown.SubMenu>
              )}
              <Dropdown.Item
                onClick={handleWatchOptions}
                testId={`task-card-${task.id}-more-options-watch-options-dropdown-item`}
              >
                <FormattedMessage id="WATCH_OPTION" />
              </Dropdown.Item>
            </Dropdown>
          </div>
        )}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <>
        <RichTextEditor
          readOnly
          ref={mainEditorRef}
          initialValue={task.d}
          expanded={readMore}
          testId={`task-${task.id}-rich-text-editor`}
        />

        {isClamped && (
          <div className={styles.readMore} onClick={handleToggleReadMore}>
            <FormattedMessage
              id={readMore ? 'editor.comments.readLess' : 'editor.comments.readMore'}
            />
          </div>
        )}
      </>
    );
  };

  const renderFooter = () => {
    const isCreator = task.u === user.id;
    const isAssignee = task.asg === user.id;
    const isOwner =
      doc.user_permissions.includes('owner') || doc.user_permissions.includes('admin');

    // Reply to a task: Task Creator, Task assignee Comment permissions, or user with Owner permission
    const canReply = isCreator || isAssignee || isOwner || doc.user_permissions.includes('comment');

    const getWatchTooltipContent = (watchers: UserId[]) => {
      if (watchers.length === 1) {
        return intl.formatMessage({ id: 'TASK_ONE_WATCHER' }, { data: profile.name });
      } else if (watchers.includes(user.id)) {
        return intl.formatMessage(
          { id: 'TASK_YOU_AND_OTHER_WATCHERS' },
          { data: watchers.length - 1 },
        );
      } else {
        return intl.formatMessage({ id: 'TASK_MANY_WATCHERS' }, { data: watchers.length });
      }
    };

    const watchers = uniq([task.u, task.asg, ...task.w].filter((w) => w));
    const tooltipMsg = getWatchTooltipContent(watchers);
    return (
      <>
        <div className={styles.leftPanel}>
          <PageLayoutTooltip
            type="task"
            content={tooltipMsg}
            testId={`task-card-${task.id}-watcher-tooltip`}
          >
            <Toggle
              size="medium"
              variant="link"
              style={{ padding: 0 }}
              icon="Watcher"
              isToggled={watchers.includes(user.id)}
              onClick={clickWatchOption}
              testId={`task-card-${task.id}-watcher-toggle`}
            />
          </PageLayoutTooltip>
          <PageLayoutTooltip
            type="task"
            disabled={task.r?.length > 0 || undefined}
            content={intl.formatMessage({
              id: isReplyOpen ? 'editor.comments.hideReplies' : 'editor.comments.showReplies',
            })}
            testId={`task-card-${task.id}-reply-tooltip`}
          >
            <Toggle
              size="medium"
              variant="link"
              disabled={isReadOnlyMode ? (task.r ? task.r.length < 1 : true) : !canReply}
              style={{ marginLeft: '1.5rem', padding: 0 }}
              // size="small"
              icon="Replies"
              isToggled={isReplyOpen}
              onClick={handleReplyOpen}
              testId={`task-card-${task.id}-reply-toggle`}
            />
          </PageLayoutTooltip>
          {task.r && task.r.length > 0 && (
            <div className={styles.numberOfReplies}>{task.r.length}</div>
          )}
        </div>
      </>
    );
  };

  const renderReply = () => {
    const hasCommentPermission =
      doc.user_permissions.includes('admin') ||
      doc.user_permissions.includes('owner') ||
      doc.user_permissions.includes('comment');

    if (hasCommentPermission) {
      return (
        <div className={styles.replyContainer}>
          <div className={styles.reply}>
            <EditorAvatar margin="0 1rem 0 0" userId={user.id} />
            <div style={{ flex: 1 }}>
              <RichTextEditor
                onFocus={handleReplyFocused}
                onChange={handleReplyChanged}
                placeholder={intl.formatMessage({ id: 'REPLY_PLACEHOLDER' })}
                ref={replyEditorRef}
                skipFocus
                mentionableUsers={collaborators}
                testId={`task-card-${task.id}-reply-textarea`}
              />
            </div>
          </div>
          {showReplyButtons && (
            <div className={styles.replyButtons}>
              <Button
                variant="link"
                size="small"
                onClick={handleCancelReply}
                testId={`task-card-${task.id}-reply-cancel-button`}
              >
                <FormattedMessage id="global.cancel" />
              </Button>
              <Button
                variant="primary"
                size="small"
                onClick={handleReplyClicked}
                disabled={reply === ''}
                testId={`task-card-${task.id}-reply-create-button`}
              >
                <FormattedMessage id="global.create" />
              </Button>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  if (!task) {
    return null;
  }

  return !panel || !isListMode || (selected && selected.tasks.includes(task.id)) ? (
    <Card
      selected={selected && selected.tasks.includes(task.id)}
      onClick={panel ? handleTasksSelected : () => {}}
      sidebar={panel}
      width={panel ? '100%' : '43rem'}
      testId={`Task#${task.id}`}
    >
      <AppendedHeader user={user.id} task={task} />
      <Card.Header size="medium">{renderHeader()}</Card.Header>
      <Card.Body>{renderBody()}</Card.Body>
      <Card.Footer size="large">{task.t && !task.t.dlt && renderFooter()}</Card.Footer>
      {isReplyOpen && renderSubComments()}
      {isReplyOpen && !isReadOnlyMode && renderReply()}
    </Card>
  ) : (
    <div
      onClick={handleTasksSelected}
      className={`${styles.minifiedCard} ${task.s === 'd' && styles.done} ${
        task.t && task.t.dlt && styles.deleted
      } ${selected && selected.tasks.includes(task.id) && styles.selected}`}
      data-testid={`Task#${task.id}`}
    >
      <div className={styles.order}>#{task.i}</div>
      <div className={styles.description}>
        <RichTextEditor
          initialValue={task.d}
          readOnly
          expanded={false}
          maxLines={1}
          testId={`task-${task.id}-rich-text-editor`}
        />
      </div>
      {task.asg && <EditorAvatar size="small" margin="0 0 0 2rem" userId={task.asg} />}
    </div>
  );
};

export default TasksCardContent;
