import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import {
  getDocumentObject,
  selectIsPageLayout,
  selectReadOnlyMode,
} from 'Editor/redux/EditorStatusSlice';
import { selectFilteredTrackedActions } from 'Editor/redux/TrackedActionsSlice';

import styles from './TrackedActionButtons.module.scss';

const TrackedActionButtons = () => {
  const dispatch = useDispatch();

  const selection = useSelector((state) => state.editor.status.selection);
  const document = useSelector(getDocumentObject);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const trackedActions = useSelector(selectFilteredTrackedActions);
  const isOwner =
    document.user_permissions.includes('owner') || document.user_permissions.includes('admin');

  const openConfirmationModal = (action: 'accept' | 'reject', mode: 'selection' | 'document') => {
    let message: TranslationId;

    if (isOwner) {
      if (mode === 'document') {
        message = action === 'accept' ? 'ACCEPT_ALL_CHANGES_MESSAGE' : 'REJECT_ALL_CHANGES_MESSAGE';
      } else {
        message =
          action === 'accept'
            ? 'ACCEPT_ALL_CHANGES_IN_THE_SELECTION'
            : 'REJECT_ALL_CHANGES_IN_THE_SELECTION';
      }
    } else {
      if (mode === 'document') {
        message =
          action === 'accept'
            ? 'ACCEPT_ALL_YOUR_CHANGES_IN_THE_DOCUMENT'
            : 'REJECT_ALL_YOUR_CHANGES_IN_THE_DOCUMENT';
      } else {
        message =
          action === 'accept'
            ? 'ACCEPT_ALL_YOUR_CHANGES_IN_THE_SELECTION'
            : 'REJECT_ALL_YOUR_CHANGES_IN_THE_SELECTION';
      }
    }

    const title = action === 'accept' ? 'ACCEPT_ALL_CHANGES' : 'REJECT_ALL_CHANGES';
    const actionCode = action === 'accept' ? 'acceptAllTrackedActions' : 'rejectAllTrackedActions';
    const actionValue = {
      selectedActions: selection.TRACKED,
      ontoAll: isOwner,
      forceAll: mode === 'document',
    };
    const confirmButtonTextId = action === 'accept' ? 'ACCEPT_CHANGES' : 'REJECT_CHANGES';

    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title,
          message,
          confirmButtonTextId,
          confirmButtonTextValues: { total: 2 },
          actionCode,
          actionValue,
          confirmButtonType: 'primary',
          cancelButtonTextId: 'global.cancel',
          headerType: 'information',
          cancelButtonShow: true,
          width: '56rem',
        },
      }),
    );
  };

  return (
    <div className={styles.trackedActionButtons}>
      <Button
        fullWidth
        size="small"
        disabled={trackedActions.order.length === 0 || isReadOnlyMode || isPageLayout || !isOwner}
        onClick={() =>
          openConfirmationModal('accept', selection.TRACKED.length > 0 ? 'selection' : 'document')
        }
        testId={'accept-all-button'}
      >
        <FormattedMessage id="editor.turnOffTrackOfChanges.acceptAll" />
      </Button>
      <Button
        fullWidth
        margin="0 0 0 2rem"
        size="small"
        disabled={trackedActions.order.length === 0 || isReadOnlyMode || isPageLayout}
        onClick={() =>
          openConfirmationModal('reject', selection.TRACKED.length > 0 ? 'selection' : 'document')
        }
        testId={'reject-all-button'}
      >
        <FormattedMessage id="editor.turnOffTrackOfChanges.rejectAll" />
      </Button>
    </div>
  );
};

export default TrackedActionButtons;
