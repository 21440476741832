import ReactDOM from 'react-dom';
import { IntlProvider } from 'Intl/Intl';
import { useIntl } from 'react-intl';

import { ReduxInterface } from 'Editor/services';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { DISPLAY_TYPES, ELEMENTS } from 'Editor/services/consts';
import { BaseBlockElement } from '../..';

import styles from './SectionBreakElement.module.scss';

const ATTRIBUTES = {
  SECTION_TYPE: 'data-sect',
};

type SectionBreakContentArgs = { onlyChild: boolean; typeTranslationId: string };

const SectionBreakContent = ({ onlyChild, typeTranslationId }: SectionBreakContentArgs) => {
  const intl = useIntl();

  if (onlyChild) {
    return (
      <>
        {`-------------------------------------------------  ${intl.formatMessage({
          id: 'EDITOR_SECTION_BREAK',
        })} (${intl.formatMessage({
          id: typeTranslationId,
        })})  ------------------------------------------------`}
      </>
    );
  }

  return (
    <>
      {` --  ${intl.formatMessage({ id: 'EDITOR_SECTION_BREAK' })} (${intl.formatMessage({
        id: typeTranslationId,
      })})  -- `}
    </>
  );
};

export class SectionBreakElement extends BaseBlockElement {
  static get observedAttributes() {
    return [ATTRIBUTES.SECTION_TYPE];
  }

  constructor() {
    super();

    this.validateOnlyChildState = this.validateOnlyChildState.bind(this);
    this.removeOnlyChildState = this.removeOnlyChildState.bind(this);
    this.buildElement = this.buildElement.bind(this);
  }

  attributeChangedCallback(attribute: string, oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
      switch (attribute) {
        case ATTRIBUTES.SECTION_TYPE:
          this.buildElement();
          break;
        default:
          break;
      }
    }
  }

  get displayType() {
    // return INLINE / BLOCK
    const parentNode = this.parentNode as HTMLElement;
    return parentNode === DOMUtils.getPageNode(this) ||
      DOMUtils.MULTI_BLOCK_CONTAINER_ELEMENTS.includes(parentNode?.tagName)
      ? DISPLAY_TYPES.BLOCK
      : DISPLAY_TYPES.INLINE;
  }

  get selectableContent() {
    return this;
  }

  get ALLOWED_STYLE_ATTRIBUTES() {
    return StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[this.tag] || [];
  }

  get isEditable() {
    return false;
  }

  get isDeletable() {
    return !this.isLocked;
  }

  get isSplitable() {
    return false;
  }

  get isSelectable() {
    return true;
  }

  get nextSection() {
    return this.getAttribute('n_sct') || undefined;
  }

  get sectionType() {
    return this.dataset.sect;
  }

  setSectionType(type?: Editor.Elements.SectionBreakType) {
    if (type && Object.values(ELEMENTS.SectionBreakElement.TYPES).includes(type)) {
      this.dataset.sect = type;
    } else {
      this.dataset.sect = ELEMENTS.SectionBreakElement.TYPES.CONTINUOUS;
    }
  }

  removeOnlyChildState() {
    this.removeAttribute('onlychild');
    this.buildElement();
  }

  validateOnlyChildState() {
    const paragraph = DOMUtils.closest(this, DOMUtils.BLOCK_TEXT_ELEMENTS)?.cloneNode(
      true,
    ) as HTMLElement;

    if (paragraph) {
      const queryElements = [...DOMUtils.INLINE_FRONTEND_ONLY_ELEMENTS, this.tagName];
      const elements = paragraph.querySelectorAll(queryElements.join(','));

      for (let i = 0; i < elements.length; i++) {
        elements[i].remove();
      }

      if (paragraph.textContent === '') {
        this.setAttribute('onlychild', 'true');
      } else {
        this.removeAttribute('onlychild');
      }
    }

    this.buildElement();
  }

  _getTranslationForType() {
    switch (this.dataset.sect) {
      case ELEMENTS.SectionBreakElement.TYPES.CONTINUOUS:
        return 'EDITOR_CONTINUOUS';
      case ELEMENTS.SectionBreakElement.TYPES.NEXT_PAGE:
        return 'EDITOR_NEXT_PAGE';
      case ELEMENTS.SectionBreakElement.TYPES.ODD_PAGE:
        return 'EDITOR_ODD_PAGE';
      case ELEMENTS.SectionBreakElement.TYPES.EVEN_PAGE:
        return 'EDITOR_EVEN_PAGE';
      default:
        return 'EDITOR_CONTINUOUS';
    }
  }

  buildElement() {
    ReactDOM.unmountComponentAtNode(this);
    ReactDOM.render(
      <IntlProvider locale={ReduxInterface.getLocale()}>
        <SectionBreakContent
          onlyChild={this.isBlock || this.hasAttribute('onlychild')}
          typeTranslationId={this._getTranslationForType()}
        ></SectionBreakContent>
      </IntlProvider>,
      this,
    );
  }

  connectedCallback() {
    super.connectedCallback();

    this.setAttribute('spellcheck', 'false');

    if (this.isBlock) {
      this.setAttribute('class', styles.block);
    } else {
      this.setAttribute('class', styles.inline);
    }

    setTimeout(this.validateOnlyChildState, 0);
  }
}

// register element
if (!window.customElements.get(ELEMENTS.SectionBreakElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.SectionBreakElement.IDENTIFIER, SectionBreakElement);
}
