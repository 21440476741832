import { Label } from 'dodoc-design-system';
import type { LabelProps } from 'dodoc-design-system/build/types/Components/Label/Label';
import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';

type ColorsProps = {
  colors: {
    [key: string]:
      | 'neutral'
      | 'yellow'
      | 'red'
      | 'orange'
      | 'pink'
      | 'blue'
      | 'green'
      | 'purple'
      | undefined;
  };
};

const COLORS: ColorsProps['colors'] = {
  F5F5F6: 'neutral',
  FED608: 'yellow',
  '49BC5C': 'green',
  '4AA9F5': 'blue',
  F15546: 'red',
  '7B4DFD': 'purple',
  E49F63: 'orange',
  FB8BB1: 'pink',
};

type StatusProps = {
  id: ObjectId;
  fullWidth?: boolean;
  size?: LabelProps['size'];
  testId: string;
};

/**
 * Renders the status badge
 */
const Status = ({ id, fullWidth = false, size = 'medium', testId }: StatusProps) => {
  const { status } = useGetElementStatusListQuery(undefined, {
    selectFromResult: ({ data }) => ({ status: data?.entities[id] }),
  });

  if (status) {
    return (
      <Label
        title={status.name}
        color={COLORS[status.color]}
        fullWidth={fullWidth}
        size={size}
        testId={testId}
      >
        {status.name}
      </Label>
    );
  }

  return null;
};

export default Status;
