import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, Icon, SectionHeading } from 'dodoc-design-system';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { ColumnGroup } from 'primereact/components/columngroup/ColumnGroup';
import { Row } from 'primereact/components/row/Row';

import colors from 'assets/colors';

import {
  useAddRolePermissionMutation,
  useRemoveRolePermissionMutation,
} from 'Settings/pages/TenantSettingsPage/Roles/RolesApi';

import styles from './RoleObjectPermissionsTab.module.scss';

export type RoleObjectPermissionsTabProps = {
  role: Role;
};

const RoleObjectPermissionsTab = ({ role }: RoleObjectPermissionsTabProps) => {
  const intl = useIntl();

  const [mutAddRolePermission] = useAddRolePermissionMutation();
  const [mutRemoveRolePermission] = useRemoveRolePermissionMutation();

  const value = [
    { code: '' },
    {
      code: 'documents',
      access: true,
      owner: true,
      approve: true,
      delete: true,
      edit: true,
      comment: true,
      add_permission: true,
      remove_permission: true,
    },
    {
      code: 'files',
      access: true,
      owner: true,
      approve: true,
      delete: true,
      edit: true,
      add_permission: true,
      remove_permission: true,
    },
    {
      code: 'folders',
      access: true,
      owner: true,
      approve: true,
      delete: true,
      edit: true,
      add_permission: true,
      remove_permission: true,
    },
    {
      code: 'spaces',
      access: true,
      owner: true,
      delete: true,
      edit: true,
      add_permission: true,
      remove_permission: true,
    },
    {
      code: 'templates',
      owner: true,
      import: true,
      export: true,
      delete: true,
      add_permission: true,
      remove_permission: true,
    },
  ];
  const headerStyle = {
    textAlign: 'center',
    padding: 0,
    color: '#585870',
    fontSize: '1.5rem',
    fontWeight: 600,
    backgroundColor: '#f2f7fc',
    borderTop: '1px solid #d5d5db',
    borderBottom: '1px solid #d5d5db',
    borderLeft: '1px solid #d5d5db',
    height: '4rem',
  };

  const toggleObjectPermission = (permissions: Permission.Code) => {
    if (role.granted.includes(permissions)) {
      mutRemoveRolePermission({ id: role.id, permissions });
    } else {
      mutAddRolePermission({ id: role.id, permissions });
    }
  };

  const tableCellTemplate = (
    rowData: { [permission in Permission.Code]: string } & { code?: string },
    column: { field: Permission.Code & 'code' },
  ) => {
    const hasOwner = role.granted.includes('owner');

    if (rowData.code === '') {
      if (column.field === 'code') {
        return <div />;
      }
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              cursor: hasOwner && column.field !== 'owner' ? 'auto' : 'pointer',
              height: '32px',
              width: '32px',
            }}
          >
            <Checkbox
              checked={role.granted.includes(column.field) || hasOwner ? 'checked' : 'unchecked'}
              size="small"
              onChange={
                !hasOwner || (hasOwner && column.field === 'owner')
                  ? () => toggleObjectPermission(column.field)
                  : () => {}
              }
              testId={`${column.field}-checkbox`}
            />
          </div>
        </div>
      );
    }
    if (column.field === 'code') {
      return (
        <div data-testid={`${rowData[column.field]}-row`}>
          <FormattedMessage id={`object.type.${rowData[column.field]}Plural`} />
        </div>
      );
    }
    if (!rowData[column.field]) {
      return <div />;
    }
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center' }}
        data-testid={`${rowData['code']}-row-${column.field}-column-icon`}
      >
        <Icon
          icon={role.granted.includes(column.field) || hasOwner ? 'Check' : 'CheckEmpty'}
          size={32}
        />
      </div>
    );
  };

  const headerGroups = (
    <ColumnGroup>
      <Row>
        <Column colSpan={7} headerStyle={{ height: '4rem' }} />
        <Column
          headerStyle={{
            ...headerStyle,
            borderRight: '0',
            fontSize: '14px',
          }}
          header={intl.formatMessage({ id: 'global.content' })}
          colSpan={2}
        />
        <Column
          headerStyle={{
            ...headerStyle,
            borderRight: '0',
            fontSize: '14px',
          }}
          header={intl.formatMessage({ id: 'global.permissions' })}
          colSpan={2}
        />
      </Row>
      <Row>
        <Column
          headerStyle={{
            ...headerStyle,
            borderLeft: '0',
          }}
        />
        <Column
          header={intl.formatMessage({ id: 'global.access' })}
          headerStyle={headerStyle}
          style={{ padding: 0 }}
        />
        <Column
          header={intl.formatMessage({ id: 'global.owner' })}
          headerStyle={headerStyle}
          style={{ padding: 0 }}
        />
        <Column
          header={intl.formatMessage({ id: 'global.import' })}
          headerStyle={headerStyle}
          style={{ padding: 0 }}
        />
        <Column
          header={intl.formatMessage({ id: 'global.export' })}
          headerStyle={headerStyle}
          style={{ padding: 0 }}
        />
        <Column
          header={intl.formatMessage({ id: 'global.approve' })}
          headerStyle={headerStyle}
          style={{ padding: 0 }}
        />
        <Column
          header={intl.formatMessage({ id: 'global.delete' })}
          headerStyle={headerStyle}
          style={{ padding: 0 }}
        />
        <Column
          header={intl.formatMessage({ id: 'global.edit' })}
          headerStyle={headerStyle}
          style={{ padding: 0 }}
        />
        <Column
          header={intl.formatMessage({ id: 'global.comment' })}
          headerStyle={headerStyle}
          style={{ padding: 0 }}
        />
        <Column
          header={intl.formatMessage({ id: 'global.add' })}
          headerStyle={headerStyle}
          style={{ padding: 0 }}
        />
        <Column
          header={intl.formatMessage({ id: 'global.remove' })}
          headerStyle={headerStyle}
          style={{ padding: 0 }}
        />
      </Row>
    </ColumnGroup>
  );
  return (
    <div className={styles.root}>
      <SectionHeading
        sticky
        margin="0 0 2rem 0"
        title={intl.formatMessage({ id: 'settings.roles.objectPermissions' })}
        testId="object-permissions-heading"
      />

      <DataTable tableClassName={styles.table} value={value} headerColumnGroup={headerGroups}>
        <Column
          field="code"
          body={tableCellTemplate}
          style={{ height: '6rem', borderBottom: `1px solid ${colors['color-text-tint-80']}` }}
        />
        <Column
          field="access"
          body={tableCellTemplate}
          style={{ height: '6rem', borderBottom: `1px solid ${colors['color-text-tint-80']}` }}
        />
        <Column
          field="owner"
          body={tableCellTemplate}
          style={{ height: '6rem', borderBottom: `1px solid ${colors['color-text-tint-80']}` }}
        />
        <Column
          field="import"
          body={tableCellTemplate}
          style={{ height: '6rem', borderBottom: `1px solid ${colors['color-text-tint-80']}` }}
        />
        <Column
          field="export"
          body={tableCellTemplate}
          style={{ height: '6rem', borderBottom: `1px solid ${colors['color-text-tint-80']}` }}
        />
        <Column
          field="approve"
          body={tableCellTemplate}
          style={{ height: '6rem', borderBottom: `1px solid ${colors['color-text-tint-80']}` }}
        />
        <Column
          field="delete"
          body={tableCellTemplate}
          style={{ height: '6rem', borderBottom: `1px solid ${colors['color-text-tint-80']}` }}
        />
        <Column
          field="edit"
          body={tableCellTemplate}
          style={{ height: '6rem', borderBottom: `1px solid ${colors['color-text-tint-80']}` }}
        />
        <Column
          field="comment"
          body={tableCellTemplate}
          style={{ height: '6rem', borderBottom: `1px solid ${colors['color-text-tint-80']}` }}
        />
        <Column
          field="add_permission"
          body={tableCellTemplate}
          style={{ height: '6rem', borderBottom: `1px solid ${colors['color-text-tint-80']}` }}
        />
        <Column
          field="remove_permission"
          body={tableCellTemplate}
          style={{ height: '6rem', borderBottom: `1px solid ${colors['color-text-tint-80']}` }}
        />
      </DataTable>
    </div>
  );
};

export default RoleObjectPermissionsTab;
