import { Logger } from '_common/services';
import { ReduxInterface } from 'Editor/services';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import SelectionManager from 'Editor/services/SelectionManager';
import { StylesHandler } from 'Editor/services/Styles';
import { ParserFactory, Parser, PlainTextParser, DodocParser } from '.';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorDOMElements, EditorDOMUtils } from '../../_Common/DOM';
import { ErrorPasteParserNotFound } from 'Editor/services/_CustomErrors';
import {
  FormatElement,
  ImageElement,
  TableCellElement,
  TableElement,
} from 'Editor/services/VisualizerManager';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';

type pasteOptions = {
  startMarker: HTMLElement | null;
  endMarker: HTMLElement | null;
  isOpen: boolean;
  updateTimeout: NodeJS.Timeout | null;
};

const LIST_DEFINITIONS_TO_IGNORE: Editor.Clipboard.ListDefinitionTypeToIgnore = {
  o1: true,
  o2: true,
  o3: true,
  o4: true,
  u1: true,
  u2: true,
  u3: true,
  u4: true,
  out1: true,
  out2: true,
  out3: true,
};
export class ClipboardManager {
  static IDENTIFIER = 'CLIPBOARD_MANAGER';

  public page: HTMLElement;
  public selectionManager: SelectionManager;
  public stylesHandler: StylesHandler;
  public dataManager: Editor.Data.API;
  visualizerManager: Editor.Visualizer.API;

  protected parsers: Editor.Clipboard.ParserTypes = {};
  public workingParser:
    | Editor.Clipboard.DodocParser
    | Editor.Clipboard.OfficeParser
    | Editor.Clipboard.GenericParser
    | Editor.Clipboard.FilesParser
    | Editor.Clipboard.PlainTextParser
    | null = null;
  public rowsToPaste: HTMLTableRowElement[];
  public pasteOptions: pasteOptions;

  constructor(
    page: HTMLElement,
    selectionManager: SelectionManager,
    stylesHandler: StylesHandler,
    dataManager: Editor.Data.API,
    visualizerManager: Editor.Visualizer.API,
  ) {
    this.page = page;
    this.selectionManager = selectionManager;
    this.stylesHandler = stylesHandler;
    this.dataManager = dataManager;
    this.visualizerManager = visualizerManager;

    this.pasteOptions = {
      startMarker: null,
      endMarker: null,
      isOpen: false,
      updateTimeout: null,
    };

    this.rowsToPaste = [];
  }

  destroy() {
    this.removePasteOptions();
  }

  static getNativeHtml(html: HTMLHtmlElement) {
    const nativeHtml = html.cloneNode(true) as HTMLHtmlElement;
    const customElements = Array.from(
      nativeHtml.querySelectorAll(
        'style,format-element,paragraph-element,equation-element,image-element,citations-group-element,page-break-element,section-break-element,table-of-contents-element,keywords-element,authors-element,list-of-figures-element,list-of-tables-element',
      ),
    );

    customElements.forEach((node) => {
      if (node instanceof FormatElement) {
        const span = node.asSpan();
        let pointer: Node = span;
        while (pointer.firstChild) {
          pointer = pointer.firstChild;
        }
        while (node.firstChild) {
          pointer.appendChild(node.firstChild);
        }

        if (node.parentNode) {
          node.parentNode.insertBefore(span, node);
        }

        node.remove();
      } else if (node.tagName === ELEMENTS.ParagraphElement.TAG) {
        const p = document.createElement('p');
        let nodeAttributes = node.attributes as NamedNodeMap;
        Array.from(nodeAttributes).forEach((attr) => {
          if (attr.nodeValue) {
            p.setAttribute(attr.nodeName, attr.nodeValue);
          }
        });
        while (node.firstChild) {
          p.appendChild(node.firstChild);
        }

        if (node.parentNode) {
          node.parentNode.replaceChild(p, node);
        }
      } else if (node.tagName === 'IMAGE-ELEMENT') {
        if (node.parentNode && node.firstChild) {
          node.parentNode.replaceChild(node.firstChild, node);
        }
      } else {
        node.remove();
      }
    });
    return nativeHtml;
  }

  getNormalizedClipboardHtml(
    range: Editor.Selection.EditorRange | undefined = EditorSelectionUtils.getRange(),
  ): HTMLHtmlElement | null {
    const workingRange = this.getWorkingRange(range);
    let fragment: DocumentFragment;
    let nodes: Node[];
    let styleIdsToCopy: string[] = [];
    const listDefinitions: Editor.Clipboard.ListDefinitions = {};

    if (!workingRange) {
      return null;
    }

    fragment = workingRange.cloneContents();
    nodes = workingRange.getNodes();

    let aux = nodes[0];
    let highestLevelClone = null;
    let lowestLevelClone = null;
    let inlineOnly = true;

    for (let i = 0; i < nodes.length; i += 1) {
      if (!EditorDOMElements.isInlineNode(nodes[i])) {
        inlineOnly = false;
        break;
      }
    }

    while (
      aux.parentNode && inlineOnly
        ? EditorDOMElements.isInlineNode(aux.parentNode)
        : aux.parentNode !== this.page && aux.parentNode instanceof TableCellElement
    ) {
      aux = aux.parentNode as Node;
      if (!nodes.includes(aux)) {
        const clone = aux.cloneNode();

        if (!lowestLevelClone) {
          lowestLevelClone = clone;
        }
        if (highestLevelClone) {
          clone.appendChild(highestLevelClone);
        }
        highestLevelClone = clone;
      }
    }

    if (highestLevelClone && lowestLevelClone !== null) {
      while (fragment.firstChild) {
        lowestLevelClone.appendChild(fragment.firstChild);
      }
      fragment.appendChild(highestLevelClone);
    }

    nodes = Array.from(fragment.querySelectorAll('*'));

    let index;
    const nodesLength = nodes.length;
    for (index = 0; index < nodesLength; index += 1) {
      const node = nodes[index];
      if (fragment.contains(node) && node instanceof HTMLElement) {
        // remove attributes

        node.removeAttribute('task');
        node.removeAttribute('sct');
        node.removeAttribute('section');

        switch (node.nodeName) {
          case 'NOTE-ELEMENT':
            {
              let noteId = node.getAttribute('element_reference');
              const note = noteId ? this.dataManager.notes.getNote(noteId) : null;

              if (note) {
                node.dataset.tempId = note.id;
                node.dataset.tempContent = note.content;
                node.dataset.tempType = note.type;
                node.removeAttribute('element_reference');
                node.removeAttribute('number');
              }
            }
            break;
          case ELEMENTS.ParagraphElement.TAG: {
            const isList = this.dataManager.numbering.isListElement(node.id);

            if (isList) {
              const listId = this.dataManager.numbering.getListIdFromBlock(node.id);

              if (listId) {
                const listStyleId = this.dataManager.numbering.getStyleIdForList(listId);
                node.setAttribute('cp_list_id', listId);

                if (listStyleId) {
                  node.setAttribute('cp_list_style', listStyleId);
                  const listLevel = this.dataManager.numbering.getListLevelFromBlock(node.id);

                  if (listLevel !== null) {
                    node.setAttribute('cp_list_level', String(listLevel));
                  }

                  let keyListStyleId: keyof Editor.Clipboard.ListDefinitionTypeToIgnore =
                    listStyleId;
                  let keyListDefinitions: keyof Editor.Clipboard.ListDefinitions = listStyleId;

                  if (
                    !LIST_DEFINITIONS_TO_IGNORE[keyListStyleId] &&
                    !listDefinitions[keyListDefinitions]
                  ) {
                    listDefinitions[keyListDefinitions] =
                      this.dataManager.numbering.getDefinitionForList(listId);
                  }
                }
              }
            }

            if (node.dataset.styleId && !styleIdsToCopy.includes(node.dataset.styleId)) {
              styleIdsToCopy.push(node.dataset.styleId);
            }

            break;
          }
          case 'EQUATION-ELEMENT':
            while (node.firstChild) {
              node.firstChild.remove();
            }
            break;
          // remove all redacted elements
          case 'CITATIONS-GROUP-ELEMENT': {
            const citations = node.querySelectorAll('citation-element') as NodeListOf<HTMLElement>;
            for (let i = 0; i < citations.length; i++) {
              const info = this.dataManager.citations.getCitationFromLibrary(
                citations[i].getAttribute('element_reference') as string,
              );
              citations[i].dataset.tempCitationInfo = JSON.stringify(info);
            }
            break;
          }
          case 'STYLE':
          case 'REDACTED-ELEMENT':
          case 'TRACK-DEL-ELEMENT':
          case 'SPAN':
            // BUG-1719
            // case 'BR':
            node.remove();
            break;
          case 'DIV':
          case 'COMMENT-ELEMENT':
          case 'TEMP-COMMENT-ELEMENT':
            // or simply unwrap all the children. (Ex: comment-element)
            while (node.firstChild && node.parentNode !== null) {
              node.parentNode.insertBefore(node.firstChild, node);
            }
            node.remove();
            break;
          case 'TRACK-INS-ELEMENT':
            // BUG-2075 Unwrap divs and spans from the custom elements
            if (node.getAttribute('replacewith')) {
              node.remove();
            } else {
              while (node.firstChild && node.parentNode !== null) {
                node.parentNode.insertBefore(node.firstChild, node);
              }
              node.remove();
            }
            break;
          case 'IMAGE-ELEMENT':
          case 'IMG': {
            let workingImage: ImageElement | undefined;
            let childImage: HTMLImageElement | undefined;
            let originalImage: Node | null | undefined;

            if (node instanceof HTMLImageElement && !(node.parentNode instanceof ImageElement)) {
              originalImage = document.querySelector(`[id="${node.getAttribute('parent_id')}"]`);
              if (originalImage instanceof ImageElement) {
                workingImage = originalImage.cloneNode(true) as ImageElement;
                childImage = workingImage.firstChild as HTMLImageElement;
              }

              if (workingImage && !nodes.includes(workingImage)) {
                node.parentNode?.replaceChild(workingImage, node);
              }
            } else if (node instanceof ImageElement) {
              originalImage = document.querySelector(`[id="${node.id}"]`);
              workingImage = node;
              if (node.firstChild instanceof HTMLImageElement) {
                childImage = node.firstChild;
              }
            }

            if (originalImage instanceof ImageElement && childImage && originalImage.imageBase64) {
              childImage.setAttribute('src', originalImage.imageBase64);
            }
            break;
          }
          case ELEMENTS.TableElement.TAG: {
            const style = node.querySelector('style');
            if (style) {
              style.remove();
            }
            const tds = node.querySelectorAll(ELEMENTS.TableCellElement.TAG);
            if (tds.length === 0) {
              node.remove();
            } else if (tds.length > 1) {
              const actualTable = this.page.querySelector(
                `${ELEMENTS.TableElement.TAG}[id="${node.id}"]`,
              ) as TableElement;
              if (actualTable) {
                if (actualTable.cellBorders) {
                  node.setAttribute('cp_cell_borders', JSON.stringify(actualTable.cellBorders));
                }
                if (actualTable.cellPaddings) {
                  node.setAttribute('cp_cell_paddings', JSON.stringify(actualTable.cellPaddings));
                }

                if (node instanceof TableElement) {
                  node.deselectAllCells();
                }
              }
            }
            break;
          }
          case ELEMENTS.TableCellElement.TAG:
            if (node instanceof TableCellElement) {
              node.deselectCell();
            }
            break;
          default:
            if (node.hasAttribute('enclosed_element')) {
              // unwrap if node is enclosing something (Ex: approve-element)
              const enclosed = node.querySelector(
                `*[id="${node.getAttribute('enclosed_element')}"]`,
              ) as Node;
              if (node.parentNode) {
                node.parentNode.insertBefore(enclosed, node.nextSibling);
              }

              node.remove();
            }
            break;
        }
      }
    }

    // Keep previous clipboard data if nothing was eligible for copy
    if (fragment.childNodes.length === 0) {
      return null;
    }

    const html = document.createElement('html');
    const head = document.createElement('head');
    const body = document.createElement('body');
    html.appendChild(head);
    html.appendChild(body);

    const styles = this.dataManager.styles.documentStyles.stylesData();
    const length = styleIdsToCopy.length;

    for (let i = 0; i < length; i++) {
      const style = styles[styleIdsToCopy[i]];

      if (style) {
        if (style.p.lst?.lId != null) {
          const listStyleId = this.dataManager.numbering.getStyleIdForList(style.p.lst.lId);
          style.p.lst.lStId = listStyleId;
          if (style.extendP?.lst) {
            style.extendP.lst.lStId = listStyleId;
          }

          if (
            listStyleId &&
            !LIST_DEFINITIONS_TO_IGNORE[listStyleId] &&
            !listDefinitions[listStyleId]
          ) {
            listDefinitions[listStyleId] = this.dataManager.numbering.getDefinitionForList(
              style.p.lst.lId,
            );
          }
        }

        const styleElement = document.createElement('meta');
        styleElement.id = style.id;
        styleElement.dataset.type = 'style';
        styleElement.dataset.info = JSON.stringify(style);
        head.appendChild(styleElement);
      }
    }

    // stringify necessary list definitions and add to the metadata
    const listDefinitionIds = Object.keys(listDefinitions);
    for (let i = 0; i < listDefinitionIds.length; i++) {
      const listDefinition = listDefinitions[listDefinitionIds[i]];
      if (listDefinition) {
        const listDefinitionElement = document.createElement('meta');
        listDefinitionElement.id = listDefinitionIds[i];
        listDefinitionElement.dataset.listDefinition = JSON.stringify(listDefinition.parseFront());
        head.appendChild(listDefinitionElement);
      }
    }

    // Keep the current document id to keep track of the origin
    body.setAttribute('id', this.page.id);
    while (fragment.firstChild) {
      body.appendChild(fragment.firstChild);
    }

    return html;
  }

  getWorkingRange(
    workingRange: Editor.Selection.EditorRange | undefined,
  ): Editor.Selection.EditorRange | undefined {
    if (workingRange === undefined) {
      return undefined;
    }

    let closestTable: TableElement;
    let selectedTDs;

    closestTable = EditorDOMUtils.closest(workingRange.commonAncestorContainer, [
      ELEMENTS.TableElement.TAG,
    ]) as TableElement;

    if (closestTable) {
      selectedTDs = closestTable.selectedCells;
    }

    if (selectedTDs && selectedTDs.length > 0) {
      // TODO: refactor copy selected cells, don't forget column widths

      // Fixed range for selected tables
      const newRange = workingRange?.cloneRange();
      newRange?.setStartBefore(selectedTDs[0]);
      newRange?.setEndAfter(selectedTDs[selectedTDs.length - 1]);
      workingRange = newRange as Editor.Selection.EditorRange;
    } else {
      // Fixed range when selection is at the end and at start of paragraph.
      const closestAncestor = EditorDOMUtils.closest(
        workingRange.commonAncestorContainer,
        EditorDOMElements.BLOCK_TEXT_ELEMENTS,
      );
      if (
        closestAncestor &&
        this.selectionManager.isSelectionAtStart(closestAncestor) &&
        this.selectionManager.isSelectionAtEnd(closestAncestor)
      ) {
        const newRange = workingRange.cloneRange();
        newRange.setStartBefore(closestAncestor);
        newRange.setEndAfter(closestAncestor);
        workingRange = newRange as Editor.Selection.EditorRange;
      }
    }

    return workingRange;
  }

  /**
   * handle copy to clipborad
   * @param {*} clipboardData
   */
  handleCopy(clipboardData: DataTransfer) {
    this.copySelectionToClipboard(clipboardData);
  }

  copySelectionToClipboard(
    clipboardData: DataTransfer,
    range: Editor.Selection.EditorRange | undefined = EditorSelectionUtils.getRange(),
  ) {
    const html = this.getNormalizedClipboardHtml(range);

    if (!html) {
      // Keep current clipboard data and don't override it with an empty copy
      return;
    }

    const nativeHtml = ClipboardManager.getNativeHtml(html);
    // appending and then removing so .innerText gets us the line breaks in the result
    document.body.appendChild(html);

    let body = html.querySelector('body');
    if (body?.innerText) {
      clipboardData.setData('text/plain', body.innerText);
    }

    document.body.removeChild(html);
    clipboardData.setData('text/html', nativeHtml.outerHTML);
    clipboardData.setData('dodoc/html', html.outerHTML);
  }

  /**
   * handle cut
   */
  handleCut(
    event: ClipboardEvent,
    range: Editor.Selection.EditorRange | undefined = EditorSelectionUtils.getRange(),
  ) {
    if (event.clipboardData) {
      this.copySelectionToClipboard(event.clipboardData);
    }
  }

  /**
   * handle paste data
   * @param {DataTransfer} dataTransfer
   */
  handlePaste(dataTransfer: DataTransfer) {
    this.parsers = {};
    this.workingParser = null;
    if (dataTransfer.types.length > 0) {
      const modes = ParserFactory.getAvailableParsers();
      for (let index = 0; index < modes.length; index += 1) {
        const type = modes[index];
        if (dataTransfer.types.includes(type)) {
          this.parsers[type] = ParserFactory.getParser(
            type,
            dataTransfer,
            this.dataManager,
            this.stylesHandler,
            this.visualizerManager,
          );
          if (type === ParserFactory.DODOC) {
            break;
          }
        }
      }
    }
  }

  prepareForPaste(actionContext: ActionContext, element: HTMLElement) {
    if (this.workingParser) {
      this.workingParser.prepareForPaste(actionContext, element);
    }
  }

  prepareForInlinePaste(actionContext: ActionContext, element: HTMLElement) {
    if (this.workingParser) {
      this.workingParser.prepareForInlinePaste(actionContext, element);
    }
  }

  afterCompletePaste() {
    if (this.workingParser) {
      this.workingParser.afterCompletePaste();
    }
  }

  setEndMarker(endMarker: HTMLElement) {
    if (this.pasteOptions.endMarker && endMarker && this.pasteOptions.endMarker !== endMarker) {
      this.pasteOptions.endMarker.remove();
    }

    if (endMarker && endMarker.parentNode) {
      this.pasteOptions.endMarker = endMarker;
    } else {
      this.pasteOptions.endMarker = null;
    }

    if (!this.pasteOptions.endMarker) {
      this.closePasteOptionsElement();
    }
  }

  closePasteOptionsElement() {
    if (this.pasteOptions.isOpen) {
      this.pasteOptions.isOpen = false;
      setTimeout(() => {
        ReduxInterface.setPasteOptionsData(null);
      }, 0);
    }
  }

  updatePasteOptions() {
    if (this.pasteOptions.endMarker != null) {
      const closest = EditorDOMUtils.closest(this.pasteOptions.endMarker, [
        ELEMENTS.TableElement.TAG,
        ELEMENTS.TableCellElement.TAG,
      ]);
      const offsets = EditorDOMUtils.getOffsets(this.pasteOptions.endMarker);

      if (offsets && closest instanceof TableElement) {
        offsets.width = closest.offsetWidth;
      }

      this.pasteOptions.isOpen = true;
      if (this.pasteOptions.updateTimeout) {
        clearTimeout(this.pasteOptions.updateTimeout);
      }
      this.pasteOptions.updateTimeout = setTimeout(() => {
        ReduxInterface.setPasteOptionsData(offsets);
      }, 0);
    }
  }

  openPasteOptions() {
    // handle paste options element
    if (this.pasteOptions.endMarker) {
      this.updatePasteOptions();
    }
  }

  removePasteOptions() {
    if (this.pasteOptions.endMarker) {
      this.pasteOptions.endMarker.remove();
      this.pasteOptions.endMarker = null;
    }
    this.closePasteOptionsElement();
  }

  hasParsersAvailable() {
    return Object.keys(this.parsers).length > 0;
  }

  async getParsedData(parsedStyle: Editor.Clipboard.PasteOptions = Parser.ORIGINAL_STYLES) {
    const modes = ParserFactory.getAvailableParsers();
    if (!this.workingParser) {
      for (let index = 0; index < modes.length; index += 1) {
        try {
          const type = modes[index];
          const parser = this.parsers[type];
          if (parser) {
            // eslint-disable-next-line no-await-in-loop
            await parser.parse();
            if (parser.isValid) {
              this.workingParser = parser;
              break;
            }
          }
        } catch (error) {
          Logger.captureException(error);
        }
      }
    }

    if (this.workingParser) {
      return await this.workingParser.getContainer(parsedStyle);
    }
    throw new ErrorPasteParserNotFound();
  }

  getPasteOptions() {
    return this.pasteOptions;
  }

  get isPasteOptionsOpen() {
    return this.pasteOptions.isOpen;
  }

  get allowOpenPasteOptions() {
    if (this.workingParser) {
      return this.workingParser.openPasteOptions;
    }
    return null;
  }

  countAvailableContent() {
    let parser = this.parsers[ParserFactory.PLAIN_TEXT];
    if (parser instanceof PlainTextParser) {
      return parser.countLines();
    }
    parser = this.parsers[ParserFactory.DODOC];
    if (parser instanceof DodocParser) {
      return parser.countBlocks();
    }
    return null;
  }
}
