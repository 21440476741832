import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { uniq } from 'lodash';
import { SectionHeading } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import { useAddPermissionMutation } from 'App/redux/objectApi';

import SearchUser, { UserOption } from '_common/components/SearchUser/SearchUser';

export type HeaderProps = {
  objectId: ObjectId;
  recursive?: boolean;
};

const HeaderContent = ({ objectId, recursive = false }: HeaderProps) => {
  const intl = useIntl();

  const [addPermission] = useAddPermissionMutation();

  const data = useSelector((state) => state.app.data[objectId]);

  const [collaboratorToAdd, setCollaboratorToAdd] = useState<UserOption>();

  const canAddCollaborator =
    data?.user_permissions.includes('admin') ||
    data?.user_permissions.includes('owner') ||
    data?.user_permissions.includes('add_permission');

  const getValuesToFilter = () => {
    let usersList = [];
    let groupsList = [];

    usersList =
      data &&
      data.permissions &&
      [...Object.keys(data.permissions.users), ...Object.keys(data.permissions.roles.users)].sort();
    groupsList =
      data &&
      data.permissions &&
      [
        ...Object.keys(data.permissions.groups),
        ...Object.keys(data.permissions.roles.groups),
      ].sort();

    usersList = uniq([`${data.owner}`, ...usersList]);
    groupsList = uniq(groupsList);

    return [...usersList, ...groupsList];
  };

  const handleAddTableRow = () => {
    const id: string | undefined = collaboratorToAdd?.value;

    if (!id) {
      return;
    }

    if (collaboratorToAdd?.type === 'user') {
      addPermission({
        code: 'access',
        user: id,
        recursive,
        objectId,
        objectType: data.type,
      });
    } else {
      addPermission({
        code: 'access',
        group: id,
        recursive,
        objectId,
        objectType: data.type,
      });
    }

    setCollaboratorToAdd(undefined);
  };

  return (
    <>
      <SectionHeading
        margin="3rem 0 2rem 0"
        title={intl.formatMessage({
          id: `COLLABORATORS`,
        })}
        buttonRightProps={
          canAddCollaborator && {
            size: 'medium',
            variant: 'primary',
            children: <FormattedMessage id={'global.add'} />,
            onClick: handleAddTableRow,
            disabled: !collaboratorToAdd,
          }
        }
        search={
          canAddCollaborator && (
            <SearchUser
              both
              clearable
              escapeClearsValue
              value={collaboratorToAdd}
              searchable
              valuesToFilter={getValuesToFilter()}
              onChange={setCollaboratorToAdd}
              width="31rem"
              menuPosition="fixed"
              placeholder={intl.formatMessage({ id: 'NAME_EMAIL_OR_GROUP' })}
              testId="add-collaborator"
            />
          )
        }
        testId="collaborators-heading"
      />
    </>
  );
};
export default HeaderContent;
