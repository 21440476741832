import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { BaseViewElement, TableElement } from 'Editor/services/VisualizerManager';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';

type AttributeNameType = Editor.Styles.Styles;

type ElementTagNameType = string;

type AttributeApplierDependencies = {
  pageContext: any;
  selectionManager: any;
  visualizerManager: Editor.Visualizer.API;
};

type AttributeApplierParams = {
  attributeName: AttributeNameType;
  attributeValue: boolean | string;
  elementTagName: Editor.Elements.SupportedTagNames;
  useExistingElements: boolean;
  attributeGroup: AttributeNameType[];
  stylableElements: ElementTagNameType[];
};

type MergeDescriptorType = {
  mergeNodes: any[];
};

export default class AttributeApplier {
  private elementTagName: Editor.Elements.SupportedTagNames;
  private useExistingElements: boolean;
  private attributeGroup: AttributeNameType[];
  private stylableElements: ElementTagNameType[];

  attributeName: AttributeNameType;
  attributeValue: boolean | string;
  pageNode: any;
  selectionManager: any;

  visualizerManager: Editor.Visualizer.API;

  constructor(deps: AttributeApplierDependencies, params: AttributeApplierParams) {
    // dependencies
    this.pageNode = deps.pageContext;
    this.selectionManager = deps.selectionManager;
    this.visualizerManager = deps.visualizerManager;

    // options
    this.elementTagName = params.elementTagName;
    this.useExistingElements = params.useExistingElements;
    this.attributeGroup = params.attributeGroup;
    this.stylableElements = params.stylableElements;
    this.attributeName = params.attributeName;

    this.attributeValue = params.attributeValue;
  }

  private createElementContainer() {
    if (this.attributeName) {
      const viewFactory = this.visualizerManager.getViewFactory();
      if (viewFactory) {
        const view = viewFactory.getViewByTag(this.elementTagName);
        if (view instanceof HTMLElement) {
          view.setAttribute(this.attributeName.toLowerCase(), String(this.attributeValue));
        }
        return view;
      }
    }
  }

  private getClosestWithAttribute(node: Node, checkValue: boolean = true) {
    if (node && this.attributeName) {
      let iterator = node;

      while (iterator && iterator.parentNode !== this.pageNode) {
        if (
          iterator instanceof Element &&
          iterator.tagName.toLowerCase() === this.elementTagName.toLowerCase() &&
          iterator.hasAttribute(this.attributeName)
        ) {
          if (checkValue) {
            if (iterator.getAttribute(this.attributeName) === this.attributeValue.toString()) {
              return iterator;
            }
          } else {
            return iterator;
          }
        }
        iterator = iterator.parentNode as Node;
      }
    }

    return null;
  }

  private isStylableNode(node: Node) {
    if (
      node &&
      (node.nodeType === Node.TEXT_NODE ||
        (node.nodeType === Node.ELEMENT_NODE &&
          this.stylableElements.includes(node.nodeName) &&
          (node instanceof BaseViewElement || node instanceof TableElement) &&
          !node?.isEditable))
    ) {
      return true;
    }
    return false;
  }

  private getEmptyElements(range: Editor.Selection.EditorRange): Element[] {
    return range.getNodes([Node.ELEMENT_NODE], (node: Node) => {
      return this.canBeAppliedToElement(node) && node.childNodes.length === 0;
    }) as Element[];
  }

  private getStylableNodes(range: Editor.Selection.EditorRange) {
    let nodes: Node[] = [];
    if (range && range.isValid()) {
      // validate non-splitable nodes citation-groups, etc

      nodes = range.getNodes([Node.TEXT_NODE, Node.ELEMENT_NODE], (node) => {
        return (
          (node.nodeType === Node.TEXT_NODE && !DOMUtils.closest(node, this.stylableElements)) ||
          (node.nodeType === Node.ELEMENT_NODE &&
            this.stylableElements.includes(node.nodeName) &&
            (node instanceof BaseViewElement || node instanceof TableElement) &&
            !node?.isEditable)
        );
      });
    }

    return nodes;
  }

  //* THIS SHOULD BE EXTRACTED, OR MAYBE NOT
  private canBeRemoved(element: Node) {
    if (
      element &&
      element instanceof Element &&
      element.nodeName.toLowerCase() === this.elementTagName.toLowerCase()
    ) {
      for (let i = 0; i < this.attributeGroup.length; i++) {
        const attribute = this.attributeGroup[i];
        if (attribute !== this.attributeName && element.hasAttribute(attribute)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  //* THIS SHOULD BE EXTRACTED, OR MAYBE NOT
  private canBeMerged(firstNode: Node, secondNode: Node) {
    if (firstNode && secondNode) {
      if (firstNode.nodeType === Node.TEXT_NODE && secondNode.nodeType === Node.TEXT_NODE) {
        return true;
      } else if (
        firstNode instanceof Element &&
        secondNode instanceof Element &&
        firstNode.tagName.toLowerCase() === this.elementTagName.toLowerCase() &&
        firstNode.tagName.toLowerCase() === secondNode.tagName.toLowerCase()
      ) {
        for (let i = 0; i < this.attributeGroup.length; i++) {
          const attribute = this.attributeGroup[i];
          if (firstNode.getAttribute(attribute) !== secondNode.getAttribute(attribute)) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  }

  private getPreviousMergeableNode(stylableNode: Node, checkParent: boolean = true) {
    let previousSibling = stylableNode.previousSibling;

    if (previousSibling && this.canBeMerged(previousSibling, stylableNode)) {
      return previousSibling;
    } else if (checkParent) {
      const parentNode = this.getClosestWithAttribute(stylableNode);

      if (parentNode && parentNode.previousSibling) {
        previousSibling = parentNode.previousSibling;
        if (this.canBeMerged(previousSibling, parentNode)) {
          if (previousSibling.lastChild && this.isStylableNode(previousSibling.lastChild)) {
            return previousSibling.lastChild;
          }
        }
      }
    }
  }

  private applyMerge(merge: MergeDescriptorType) {
    if (merge && merge.mergeNodes.length > 0) {
      const parentNode = merge.mergeNodes[0].parentNode;
      for (let i = 1; i < merge.mergeNodes.length; i++) {
        const node = merge.mergeNodes[i];

        const nextParent = node.parentNode;

        parentNode.appendChild(node);

        if (nextParent.childNodes.length === 0) {
          nextParent.remove();
        }
      }

      DOMNormalizer.normalizeTree(parentNode);
    }
  }

  private mergeElements(
    range: Editor.Selection.EditorRange,
    stylableNodes: any[] = [],
    isUndo: boolean = false,
  ) {
    if (range && range.isValid()) {
      const mergesToApplly: MergeDescriptorType[] = [];
      let currentMerge: MergeDescriptorType | null = null;
      for (let i = 0; i < stylableNodes.length; i++) {
        const stylableNode = stylableNodes[i];
        let previousNode = this.getPreviousMergeableNode(stylableNode, !isUndo);

        if (previousNode) {
          if (!currentMerge) {
            currentMerge = {
              mergeNodes: [previousNode],
            };
            mergesToApplly.push(currentMerge);
          }

          let nodeToMerge;

          const closest = this.getClosestWithAttribute(stylableNode);
          if (closest === stylableNode.parentNode) {
            nodeToMerge = stylableNode;
          } else {
            nodeToMerge = DOMUtils.findNodeLevel0(closest, stylableNode);
          }

          if (nodeToMerge != null && !currentMerge.mergeNodes.includes(nodeToMerge)) {
            currentMerge.mergeNodes.push(nodeToMerge);
          }
        } else {
          currentMerge = null;
        }
      }

      // TODO: check and improve merges
      // 2) elements that have only one child with attributes

      if (mergesToApplly.length) {
        for (let i = 0; i < mergesToApplly.length; i++) {
          this.applyMerge(mergesToApplly[i]);
        }
      }
    }
  }

  private undoToClosestWithAttribute(closestWithAttribute?: Node) {
    if (closestWithAttribute && closestWithAttribute instanceof Element && this.attributeName) {
      if (this.canBeRemoved(closestWithAttribute)) {
        // unwrap childreen
        const parent = closestWithAttribute.parentNode;
        while (closestWithAttribute.firstChild) {
          DOMUtils.insertNodeBefore(parent, closestWithAttribute.firstChild, closestWithAttribute);
        }
        closestWithAttribute.remove();
      } else {
        // remove attribute
        closestWithAttribute.removeAttribute(this.attributeName);
      }
    }
  }

  canBeAppliedToElement(element: Node) {
    if (
      element &&
      element.nodeType === Node.ELEMENT_NODE &&
      element.nodeName.toLowerCase() === this.elementTagName.toLowerCase()
    ) {
      return true;
    }
    return false;
  }

  isAppliedToRange(range: Editor.Selection.EditorRange) {
    return false;
  }

  applyToRange(range: Editor.Selection.EditorRange) {
    if (range && range.isValid() && this.attributeName) {
      // save range
      const savedRange = range.saveRange();

      let workingRange = this.selectionManager.getRangeBasedOnSavedMarkers(savedRange, {
        setCaretInside: false,
      });

      const stylableNodes = this.getStylableNodes(workingRange);

      for (let i = 0; i < stylableNodes.length; i++) {
        const stylableNode = stylableNodes[i];

        const closestWithAttribute = this.getClosestWithAttribute(stylableNode, false);
        const closestWithAttributeValue = this.getClosestWithAttribute(stylableNode);

        if (!closestWithAttributeValue || closestWithAttribute !== closestWithAttributeValue) {
          const textNodeParent = stylableNode.parentNode;
          if (
            textNodeParent &&
            textNodeParent instanceof Element &&
            textNodeParent.childNodes.length === 1 &&
            this.useExistingElements &&
            this.canBeAppliedToElement(textNodeParent)
          ) {
            textNodeParent.setAttribute(this.attributeName, `${this.attributeValue}`);
          } else {
            const container = this.createElementContainer();
            if (container instanceof BaseViewElement) {
              container.preRender();
            }
            DOMUtils.insertNodeBefore(textNodeParent, container, stylableNode);
            DOMUtils.appendNode(container, stylableNode);
          }
        }
      }

      workingRange = this.selectionManager.getRangeBasedOnSavedMarkers(savedRange, {
        setCaretInside: false,
      });

      // merge mergable nodes
      this.mergeElements(workingRange, stylableNodes);

      // restore range
      range.restoreRange(savedRange);

      EditorSelectionUtils.applyRangeToSelection(range);
    }
    return range;
  }

  undoToRange(range: Editor.Selection.EditorRange) {
    if (range && range.isValid()) {
      // save range
      const savedRange = range.saveRange();

      let workingRange = this.selectionManager.getRangeBasedOnSavedMarkers(savedRange, {
        setCaretInside: false,
      });

      const stylableNodes = this.getStylableNodes(workingRange);

      let parentNode;
      if (stylableNodes.length > 0) {
        parentNode = this.getClosestWithAttribute(workingRange.startContainer)?.parentNode;
        if (parentNode instanceof Element) {
          // split start container
          EditorSelectionUtils.splitInlineTextElements(
            parentNode,
            workingRange.startContainer,
            workingRange.startOffset,
          );
        }

        workingRange = this.selectionManager.getRangeBasedOnSavedMarkers(savedRange, {
          setCaretInside: false,
        });

        parentNode = this.getClosestWithAttribute(workingRange.endContainer)?.parentNode;
        if (parentNode instanceof Element) {
          // split end container
          EditorSelectionUtils.splitInlineTextElements(
            parentNode,
            workingRange.endContainer,
            workingRange.endOffset,
          );
        }

        workingRange = this.selectionManager.getRangeBasedOnSavedMarkers(savedRange, {
          setCaretInside: false,
        });

        for (let i = 0; i < stylableNodes.length; i++) {
          const node = stylableNodes[i];

          const closestWithAttribute = this.getClosestWithAttribute(node);
          if (closestWithAttribute) {
            this.undoToClosestWithAttribute(closestWithAttribute);
          }
        }

        workingRange = this.selectionManager.getRangeBasedOnSavedMarkers(savedRange, {
          setCaretInside: false,
        });

        // merge mergable nodes
        this.mergeElements(workingRange, stylableNodes, true);
      }

      workingRange = this.selectionManager.getRangeBasedOnSavedMarkers(savedRange, {
        setCaretInside: false,
      });

      // remove empty elements
      const emptyElements = this.getEmptyElements(workingRange);

      for (let j = 0; j < emptyElements.length; j++) {
        emptyElements[j].remove();
      }

      // restore range
      range.restoreRange(savedRange);

      EditorSelectionUtils.applyRangeToSelection(range);
    }

    return range;
  }

  toggleRange(range: Editor.Selection.EditorRange) {
    if (this.isAppliedToRange(range)) {
      return this.undoToRange(range);
    } else {
      return this.applyToRange(range);
    }
  }
}
