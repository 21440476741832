import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';
import { WidgetViewModel } from './WidgetViewModel';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { ResizableWidgetView } from '../../Views';

export class ResizableWidgetModel extends WidgetViewModel<'resizable'> {
  constructor(
    Visualizer: Editor.Visualizer.State,
    refView: Editor.Visualizer.WidgetData['resizable']['view'],
    props: Editor.Visualizer.WidgetData['resizable']['props'],
  ) {
    super(Visualizer, refView, props);

    this.container.dataset.type = 'resizable';
  }

  render() {
    let offsets = EditorDOMUtils.getOffsets(this.refView);

    if (offsets) {
      this.container.style.position = 'absolute';
      this.container.style.top = `${offsets.top}px`;

      if (this.Visualizer.getLayoutType?.() === 'PAGE') {
        this.container.style.left = `calc(50% - ${offsets.width / 2}px)`;
      } else {
        this.container.style.left = `${offsets.left}px`;
      }

      this.container.style.width = `0px`;
      this.container.style.height = `${offsets.height}px`;

      ReactDOM.unmountComponentAtNode(this.container);

      let corners;
      let sides;
      let aspectRatio = true;
      let max: { width?: number; height?: number } | undefined;

      if (EditorDOMElements.isTableElement(this.refView)) {
        aspectRatio = false;
        corners = {
          ne: true,
          se: true,
          sw: true,
        };
        sides = {};
        max = { width: 2111.622 };
      }

      // mount react component
      ReactDOM.render(
        <Provider store={ReduxInterface.getStore()}>
          <ResizableWidgetView
            rect={offsets}
            corners={corners}
            sides={sides}
            resize={this.refView.resizeElement}
            keepAspectRatioByDefault={aspectRatio}
            max={max}
          />
        </Provider>,
        this.container,
      );
    }
  }

  dispose(): void {
    ReactDOM.unmountComponentAtNode(this.container);
    this.container.remove();
  }
}
