import {
  Path,
  ObjectInsertOp,
  ObjectReplaceOp,
  ObjectDeleteOp,
  ListInsertOp,
  ListDeleteOp,
  ListReplaceOp,
} from 'sharedb';

export class RealtimeOpsBuilder {
  static objectInsert(content: any, path: Path): ObjectInsertOp {
    return {
      p: [...path],
      oi: content,
    };
  }

  static objectReplace(oldObj: any, newObj: any, path: Path): ObjectReplaceOp {
    return {
      p: [...path],
      od: oldObj,
      oi: newObj,
    };
  }

  static objectDelete(oldObj: any, path: Path): ObjectDeleteOp {
    return {
      p: [...path],
      od: oldObj,
    };
  }

  static listInsert(element: any, path: Path): ListInsertOp {
    return {
      p: [...path],
      li: element,
    };
  }

  static listDelete(element: any, path: Path): ListDeleteOp {
    return {
      p: [...path],
      ld: element,
    };
  }

  static listReplace(oldObj: any, newObj: any, path: Path): ListReplaceOp {
    return {
      p: [...path],
      ld: oldObj,
      li: newObj,
    };
  }
}
