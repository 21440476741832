import { useDispatch, useSelector } from '_common/hooks';
import { Status } from '_common/components';
import { BaseRow, RowCell, CellObjectName } from '_common/components';
import { updateModal } from '_common/modals/ModalsSlice';
import { Tooltip, Icon } from 'dodoc-design-system';
import { useIntl } from 'react-intl';
import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';
import { BaseRowProps } from '_common/components/Table/BaseRow/BaseRow';
import { singleSelection } from '_common/components/Table/TableSlice';

type TableRowProps = Pick<
  BaseRowProps,
  'style' | 'objectId' | 'selected' | 'clickHandles' | 'selectable'
>;

const TableRow = ({ style, objectId, selected, clickHandles, selectable }: TableRowProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const data = useSelector((state) => state.app.data);
  const object = data[objectId];
  const { status } = useGetElementStatusListQuery(undefined, {
    selectFromResult: ({ data }) => ({ status: data?.entities[object.status] }),
  });
  const identity = useSelector((state) => state.modals.CitationsModal.navigation?.identity);
  const handleRowDoubleClick = (e: React.MouseEvent<HTMLDivElement>, objectId: ObjectId) => {
    const currentObject = data[objectId];

    if (currentObject.status === 'processing' || currentObject.status === 'broken') {
      return;
    }

    let type = currentObject.type;
    let id = currentObject.id;
    if (currentObject.type === 'virtualobject') {
      type = currentObject.source.type;
      id = currentObject.source.id;
    }
    switch (type) {
      case 'space':
      case 'folder':
        dispatch(
          updateModal({
            modal: 'CitationsModal',
            data: {
              navigation: { current: id, identity: 'storage' },
            },
          }),
        );
        break;
      default:
        break;
    }
  };

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement>, objectId: ObjectId) => {
    dispatch(singleSelection({ identity, objectId }));
  };

  clickHandles.handleRowDoubleClick = handleRowDoubleClick;
  clickHandles.handleRowClick = handleRowClick;
  const disabled =
    object.type !== 'folder' &&
    object.type !== 'space' &&
    object.mime?.type !== 'text/xml' &&
    object.mime?.type !== 'text/plain';
  return (
    <>
      <BaseRow
        style={style}
        objectId={objectId}
        selected={selected}
        selectable={selectable}
        clickHandles={clickHandles}
        disabled={disabled}
      >
        <RowCell size="78" disabled={disabled}>
          <CellObjectName object={object} />
        </RowCell>
        <RowCell size="16" disabled={disabled}>
          {status?.visible && (
            <Status id={object.status} fullWidth={false} testId="object-status-label" />
          )}
        </RowCell>
        <RowCell size="4.5">
          {disabled && (
            <Tooltip
              content={intl.formatMessage({ id: 'INVALID_FILE_FORMAT' })}
              testId={`object-${object.i}-invalid-file-format-tooltip`}
            >
              <Icon icon="InformationBlue" size={16} />
            </Tooltip>
          )}
        </RowCell>
      </BaseRow>
    </>
  );
};

export default TableRow;
