import { Link, Toggle } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { LikesTooltip } from 'Editor/components';
import { LikeParent } from '_common/modals/LikesModal/LikesModal';

import styles from './LikeToggle.module.scss';

type LikeToggleProps = {
  votes: PDF.Annotation.Vote[];
  pageNumber: PDF.Annotation['pageNumber'];
  annotationId: PDF.Annotation['id'];
  replyId?: PDF.Annotation.Reply['id'];
  target: LikeParent;
  testId: string;
};

const LikeToggle = ({
  votes,
  pageNumber,
  annotationId,
  replyId,
  target,
  testId,
}: LikeToggleProps) => {
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();

  const currentUserId = useSelector((state) => state.auth.userId);
  const likeVotes: Editor.Comment.Vote[] = votes.map((vote) => ({
    time: '',
    user: vote.user,
    value: vote.value,
  }));
  const usersLiked = votes.filter((vote) => vote.value !== 0).map((vote) => vote.user);
  const currentUserLiked = usersLiked.includes(currentUserId);

  const { canComment } = usePDFPermissions();

  const handleLikeReply = () => {
    if (replyId) {
      pdfManager.voteReplyToAnnotation(pageNumber, annotationId, replyId, currentUserLiked ? 0 : 1);
    } else {
      pdfManager.voteAnnotation(pageNumber, annotationId, currentUserLiked ? 0 : 1);
    }
  };

  return (
    <div className={styles.likeCounter}>
      <Toggle
        size="medium"
        variant="link"
        icon={currentUserLiked ? 'LikeBlue' : 'LikeGrey'}
        isToggled={currentUserLiked}
        testId={`${testId}-like-toggle`}
        onClick={handleLikeReply}
        disabled={!canComment}
      />
      {usersLiked.length > 0 && (
        <LikesTooltip votes={likeVotes} testId={`${testId}-likes-tooltip`}>
          <span className={styles.counter}>
            <Link
              onClick={() =>
                dispatch(
                  openAndUpdateModal({
                    modal: 'LikesModal',
                    data: { votes: likeVotes, type: target },
                  }),
                )
              }
              variant="neutral"
              testId="annotation-like-link"
            >
              {usersLiked.length}
            </Link>
          </span>
        </LikesTooltip>
      )}
    </div>
  );
};

export default LikeToggle;
