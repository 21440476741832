import { Card } from 'dodoc-design-system';

import { usePDFContext } from 'PDF/PDFContext';
import { useSelector } from '_common/hooks';

import Header from './Header/Header';
import Body from './Body/Body';

import styles from './VersionCard.module.scss';
import { useEffect, useRef, useState } from 'react';

type VersionCardProps = {
  versions: FileObject['lifecycle']['versions'];
} & (
  | { currentVersion: true; index?: undefined; version?: undefined }
  | {
      currentVersion?: false;
      index: number;
      version: Exclude<FileObject['lifecycle']['versions'], undefined>[number];
    }
);

const VersionCard = ({ versions, index, currentVersion, version }: VersionCardProps) => {
  const pdfManager = usePDFContext();

  const loadedVersion = useSelector((state) => state.pdf.general.loadedVersion);

  const [isActive, setIsActive] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //If there is a loaded version, we are not in the current version
    if (loadedVersion != null && versions) {
      const activeIndex = versions.length - loadedVersion - 1;
      if (activeIndex === index) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
    //If there isn't a loadedVersion, we are on the current version
    else {
      //If the card doesnt have index, its the "Current Version" card
      if (index === undefined) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
  }, [loadedVersion, versions, index]);

  useEffect(() => {
    if (ref.current) {
      if (isActive) {
        ref.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
    }
  }, [isActive]);

  const handleActiveVersion = () => {
    const ind = index != null && versions ? versions.length - index - 1 : null;
    if (loadedVersion !== ind) {
      pdfManager.loadVersion(ind);
    }
  };

  return (
    <div onClick={handleActiveVersion} className={styles.root} ref={ref}>
      <Card
        sidebar
        width="auto"
        selected={isActive}
        testId={`version${index ? `-${index}` : '-current'}-card`}
      >
        <Card.Header>
          <Header index={index} currentVersion={!!currentVersion} versions={versions} />
        </Card.Header>
        {version && (
          <Card.Body>
            <Body version={version} />
          </Card.Body>
        )}
      </Card>
      <div
        className={`${styles.indicator} ${isActive && styles.active}`}
        data-testid={`version-${index ?? 'current'}-indicator`}
      />
    </div>
  );
};

export default VersionCard;
