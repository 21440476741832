import { FormattedDate, FormattedTime, UsernameLabel } from '_common/components';

import styles from './Body.module.scss';
import { FormattedMessage } from 'react-intl';

type BodyProps = {
  version?: Exclude<FileObject['lifecycle']['versions'], undefined>[number];
};

const Body = ({ version }: BodyProps) => {
  if (!version) {
    return null;
  }
  return (
    <div>
      <div className={styles.user}>{<UsernameLabel userId={version?.creator} />}</div>
      {version && (
        <div className={styles.date}>
          <FormattedDate date={version?.creation} />{' '}
          <FormattedTime time={version?.creation} type="meridiem" />
        </div>
      )}
      {version && (
        <div
          className={`${styles.description} ${!version?.description && styles.empty}`}
          title={version?.description}
        >
          {version?.description || <FormattedMessage id="NO_DESCRIPTION" />}
        </div>
      )}
    </div>
  );
};

export default Body;
