import { useState, useEffect, useRef, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import {
  Checkbox,
  Button,
  ToggleGroup,
  Toggle,
  Modal,
  RadioButton,
  Tooltip,
  Icon,
  Select,
  SectionHeader,
} from 'dodoc-design-system';

import { EditorService, InstanceService, Logger } from '_common/services';
import { notify } from '_common/components/ToastSystem';

import {
  closeAndResetModal,
  closeModal,
  openAndUpdateModal,
  initialState,
  updateModal,
} from '_common/modals/ModalsSlice';
import { selectTocItemsParents } from 'Editor/redux/TocSlice';
import { setAppLoading } from 'App/redux/appSlice';
import { setLoadingValue } from 'Editor/redux/EditorStatusSlice';

import DocumentContent from './DocumentContent/DocumentContent';
import TemplateDetails from './TemplateDetails/TemplateDetails';
import TemplateOptions from './TemplateOptions/TemplateOptions';
import LoadingExport from './LoadingExport/LoadingExport';
import type { ElementProps } from './DocumentContent/Element/Element';
import type { TemplateOptionsProps } from './TemplateOptions/TemplateOptions';
import useConditionalData from './useConditionalData';

import styles from './ExportDocumentModal.module.scss';
import { useGetTenantSettingsQuery } from '_common/services/api/authority';

const LABELS = {
  netdocuments: 'netDocuments',
  box: 'Box',
} as const;

const ExportDocumentModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { data: tenantSettings } = useGetTenantSettingsQuery();
  const pdfToggle = useMemo(() => {
    //@ts-expect-error missing update from API
    return tenantSettings?.['feature.export.pdf'];
  }, [tenantSettings]);
  const isOpen = useSelector((state) => state.modals.open.ExportDocumentModal);
  const exportType = useSelector(
    (state) =>
      state.modals.ExportDocumentModal.exportType || initialState.ExportDocumentModal.exportType,
  );
  const initialExportFormat = useSelector(
    (state) =>
      state.modals.ExportDocumentModal.initialExportFormat ||
      initialState.ExportDocumentModal.initialExportFormat,
  );
  const exporting = useSelector(
    (state) =>
      state.modals.ExportDocumentModal.exporting || initialState.ExportDocumentModal.exporting,
  );
  const elementsParentsMapper = useSelector(selectTocItemsParents);

  const isEditor = useSelector((state) => state.editor.status.visible);
  const { navigationList, navigationData, template, object } = useConditionalData();

  const [exported, setExported] = useState(false);
  const [messageExported, setMessageExported] = useState('');
  const [exportError, setExportError] = useState(false);
  const [exportFormat, setExportFormat] = useState(initialExportFormat);
  const [exportComments, setExportComments] = useState(true);
  const [exportSuggestions, setExportSuggestions] = useState(true);
  const [exportTasks, setExportTasks] = useState(true);
  const [customizedContent, setCustomizedContent] = useState('all');
  const [selectedItems, setSelectedItems] = useState<ElementProps['selectedItems']>({});
  const [templateOptions, setTemplateOptions] = useState<TemplateOptionsProps['options']>({
    show: false,
  });
  const [referenceFormat, setReferenceFormat] = useState('original');
  const [convertToOption, setConvertToOption] = useState({
    value: 'docx',
    label: 'Word',
  });
  const [modalIsHidden, setModalIsHidden] = useState(false);
  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (!isEditor) {
      return;
    }

    const manager = EditorManager.getInstance();
    if (manager) {
      if (manager.structureHasAutomaticUpdates()) {
        setTemplateOptions({
          show: true,
          update: true,
          template: manager.getWordTemplate(),
        });
      }
      manager.initializeNavigation();
    }
    return () => {
      EditorManager.getInstance().destroyNavigation();
    };
  }, [isOpen]);

  useEffect(() => {
    const newSelectedItems = { ...selectedItems };
    const elementIds = Object.keys(selectedItems);
    if (elementIds.length > 0) {
      const flatElements: typeof newSelectedItems = {};
      const elementsToSearch = [...navigationList];
      while (elementsToSearch.length > 0) {
        const elementId = elementsToSearch.shift();
        if (elementId) {
          const element = navigationData[elementId];
          flatElements[element.id] = true;
          if (element.childNodes.length > 0) {
            elementsToSearch.unshift(...element.childNodes);
          }
        }
      }
      elementIds.forEach((id) => {
        newSelectedItems[id] = flatElements[id];
      });
      setSelectedItems(newSelectedItems);
    } else {
      setCustomizedContent('all');
    }
  }, [navigationList, navigationData]);

  useEffect(() => {
    if (exporting) {
      checkStatus();
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [exporting]);

  const handleSelectedItem = (id: Editor.ToCItem['id']) => {
    const selected = selectedItems[id] === 'indeterminate' ? true : !selectedItems[id];
    const selectedItem = navigationData[id];
    const newSelections = { ...selectedItems, [id]: selected };
    // Check if the parent has to be set to indeterminate
    // Might not have a parent
    let parentId = elementsParentsMapper[id];
    while (parentId) {
      let status: (typeof newSelections)[keyof typeof newSelections] | null = null;
      const parent = navigationData[parentId];
      if (!newSelections[parent.id]) {
        break;
      }
      for (let i = 0; i < parent.childNodes.length; i++) {
        const childId = parent.childNodes[i];
        const child = navigationData[childId];
        if (!newSelections.hasOwnProperty(child.id)) {
          newSelections[child.id] = false;
        }
        if (status === null && newSelections[child.id]) {
          status = newSelections[child.id];
        } else if (status !== newSelections[child.id]) {
          status = 'indeterminate';
          break;
        }
      }
      if (status !== null) {
        newSelections[parent.id] = status;
      }
      parentId = elementsParentsMapper[parent.id];
    }

    // Set the same state for every children of every level
    const items = [...selectedItem.childNodes];
    while (items.length > 0) {
      const itemId = items.shift();
      if (itemId) {
        const item = navigationData[itemId];
        items.push(...item.childNodes);
        newSelections[item.id] = selected;
      }
    }

    setSelectedItems(newSelections);
  };

  const toggleExportFormat = (value: typeof exportFormat) => {
    setExportFormat(value);
  };
  const toggleExportComments = () => {
    setExportComments((value) => !value);
  };
  const toggleExportSuggestions = () => {
    setExportSuggestions((value) => !value);
  };

  const toggleExportTasks = () => {
    setExportTasks((value) => !value);
  };

  const handleDocumentContent = (id: 'all' | 'customized') => {
    setCustomizedContent(id);
  };

  const download = () => {
    new EditorService()
      .download(object.id)
      .then((response) => {
        const name = decodeURIComponent(response.headers['x-download-filename']);
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => Logger.captureException(error));
  };

  const handleLoadingState = (value: boolean) => {
    if (!isEditor) {
      dispatch(setAppLoading({ isOpen: value }));
    } else {
      dispatch(setLoadingValue(value));
    }
  };

  const checkStatus = () => {
    timeoutRef.current = window.setTimeout(() => {
      new InstanceService()
        .checkJobStatus({ jobs: [exporting] })
        .then(({ data }) => {
          //@ts-expect-error APISpec: endpoint response type its not defined
          switch (data[exporting].status) {
            case 'queued':
            case 'started':
              checkStatus();
              return;
            case 'finished':
              handleLoadingState(false);
              if (
                //@ts-expect-error APISpec: endpoint response type its not defined
                data[exporting].result[0] === 403 ||
                //@ts-expect-error APISpec: endpoint response type its not defined
                data[exporting].result[0] === 400 ||
                //@ts-expect-error APISpec: endpoint response type its not defined
                data[exporting].result[0] === 500
              ) {
                dispatch(closeModal('ExportDocumentModal'));

                // 403: without permissions
                //@ts-expect-error APISpec: endpoint response type its not defined
                if (data[exporting].result[0] === 403) {
                  dispatch(
                    openAndUpdateModal({
                      modal: 'ConfirmationModal',
                      data: {
                        message: 'EXPORT_PERMISSIONS_ERROR',
                        title: 'EXPORT_INTEGRATION',
                        titleValues: {
                          name: object.name,
                          //@ts-expect-error export type is any because modals.ExportDocumentModal its not typed
                          integrationName: exportType !== 'simple' && LABELS[exportType],
                        },
                        cancelButtonShow: false,
                        confirmButtonTextId: 'GO_BACK',
                        confirmButtonType: 'default',
                        headerType: 'error',
                        width: '85rem',
                        cancelButtonTextId: 'global.cancel',
                      },
                    }),
                  );
                }
                //@ts-expect-error APISpec: endpoint response type its not defined
                else if (data[exporting].result[0] === 400) {
                  // 400: Destination has a file with the same name.
                  dispatch(
                    openAndUpdateModal({
                      modal: 'ConfirmationModal',
                      data: {
                        message: 'EXPORT_FILE_ALREADY_EXISTS',
                        title: 'EXPORT_INTEGRATION',
                        titleValues: {
                          name: object.name,
                          //@ts-expect-error export type is any because modals.ExportDocumentModal its not typed
                          integrationName: exportType !== 'simple' && LABELS[exportType],
                        },
                        cancelButtonTextId: 'GO_BACK',
                        confirmButtonTextId: 'EXPORT_NEW_VERSION',
                        confirmButtonType: 'primary',
                        actionCode: 'exportToIntegration',
                        actionValue: {
                          id: object.id,
                          //@ts-expect-error APISpec: endpoint response type its not defined
                          destination: data[exporting].result[1],
                          type: exportType === 'netdocuments' ? 'document' : 'file',
                          exportTemplate: {
                            template: template ? template.id : '_blank',
                            format: exportFormat,
                            keep_comments: exportComments,
                            keep_suggestions: exportSuggestions,
                            keep_tasks: exportTasks,
                          },
                          integrationType: exportType,
                        },
                        width: '85rem',
                        headerType: 'information',
                        cancelButtonShow: true,
                      },
                    }),
                  );
                }
                //@ts-expect-error APISpec: endpoint response type its not defined
                else if (data[exporting].result[0] === 500) {
                  dispatch(
                    openAndUpdateModal({
                      modal: 'ConfirmationModal',
                      data: {
                        message: 'ERROR_WHILE_EXPORTING',
                        title: 'EXPORT_INTEGRATION',
                        titleValues: {
                          name: object.name,
                          //@ts-expect-error export type is any because modals.ExportDocumentModal its not typed
                          integrationName: exportType !== 'simple' && LABELS[exportType],
                        },
                        cancelButtonShow: false,
                        confirmButtonTextId: 'GO_BACK',
                        confirmButtonType: 'default',
                        headerType: 'error',
                        width: '85rem',
                        cancelButtonTextId: 'global.cancel',
                      },
                    }),
                  );
                }
              } else {
                setExported(true);
                if (exportType !== 'simple') {
                  setMessageExported(
                    intl.formatMessage(
                      { id: 'NET_DOCUMENTS_EXPORT_SUCCESSFUL' },
                      //@ts-expect-error export type is any because modals.ExportDocumentModal its not typed
                      { integrationName: LABELS[exportType] },
                    ),
                  );
                }
              }

              if (exportType === 'simple') {
                download();
                notify({
                  type: 'success',
                  title: 'DODOC_DOCUMENT_EXPORTED',
                  message: 'DODOC_DOCUMENT_EXPORTED_MESSAGE',
                  messageValues: {
                    name: object.name,
                  },
                  persist: true,
                  footerContent: (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        size="small"
                        margin="0 2rem 2rem 2rem"
                        onClick={download}
                        testId="download-manually-button"
                      >
                        <FormattedMessage id="DOWNLOAD_MANUALLY" />
                      </Button>
                    </div>
                  ),
                });
              }
              close();
              return;
            case 'failed':
              setExportError(true);
              handleLoadingState(false);
              setModalIsHidden(false);
              return;
            default:
              handleLoadingState(false);
              close();
          }
        })
        .catch(() => {
          handleLoadingState(false);
          close();
        });
    }, 2500);
  };

  const exportDocument = () => {
    const params: {
      template?: typeof template.id;
      format: typeof exportFormat;
      keep_comments?: typeof exportComments;
      keep_suggestions?: typeof exportSuggestions;
      nodes?: string[];
      keep_tasks?: typeof exportTasks;
      update_styles?: typeof templateOptions.update;
      citations?: typeof referenceFormat;
    } =
      exportFormat === 'pdf'
        ? {
            format: exportFormat,
          }
        : {
            template: template ? template.id : '_blank',
            format: exportFormat,
            keep_comments: exportComments,
            keep_suggestions: exportSuggestions,
            nodes: Object.keys(selectedItems)
              .filter((id) => selectedItems[id])
              .map((id) => navigationData[id].target.split(':')[0]),
          };
    if (exportFormat === 'docx') {
      params.keep_tasks = exportTasks;
      params.update_styles = templateOptions.update;
      params.citations = referenceFormat === 'convertTo' ? convertToOption.value : referenceFormat;
    }
    if (exportType === 'simple') {
      if (!isEditor) {
        dispatch(
          setAppLoading({
            isOpen: true,
            message: 'EXPORTING_DOCUMENT',
          }),
        );
      } else {
        dispatch(setLoadingValue('EXPORTING_DOCUMENT'));
      }
      setModalIsHidden(true);
      new EditorService()
        .exportDocument(object.id, params)
        .then(({ data }) => {
          dispatch(
            updateModal({
              modal: 'ExportDocumentModal',
              data: { exporting: data.id },
            }),
          );
        })
        .catch(() => {
          handleLoadingState(false);
          dispatch(closeAndResetModal('ExportDocumentModal'));
          notify({
            type: 'error',
            title: 'global.error',
            message: 'auth.errors.error',
          });
        });
    } else if (exportType === 'netdocuments') {
      dispatch(
        openAndUpdateModal({
          modal: 'ExportIntegrationModal',
          data: {
            exportTemplate: params,
            waitingValidation: false,
            needLogin: true,
          },
        }),
      );
      closeModal('ExportDocumentModal');
    } else if (exportType === 'box') {
      dispatch(
        openAndUpdateModal({
          modal: 'ExportIntegrationModal',
          data: {
            exportTemplate: params,
            waitingValidation: true,
          },
        }),
      );
    }
  };

  const close = () => {
    setExported(false);
    setExportError(false);
    setExportFormat('docx');
    setExportComments(true);
    setExportSuggestions(true);
    setExportTasks(false);
    setTemplateOptions({ show: false });
    setSelectedItems({});
    dispatch(closeAndResetModal('ExportDocumentModal'));
  };

  const modalType = exportType === 'simple' ? 'export-document' : 'export-integration';

  return (
    <Modal width="75rem" open={!!isOpen && !modalIsHidden} onClose={close} testId={modalType}>
      <Modal.Header onClose={close}>
        <FormattedMessage
          id={exportType === 'simple' ? 'EXPORT_DOCUMENT_MODAL_HEADER' : 'EXPORT_INTEGRATION'}
          values={
            exportType === 'simple'
              ? { name: object?.name }
              : //@ts-expect-error export type is any because modals.ExportDocumentModal its not typed
                { name: object?.name, integrationName: LABELS[exportType] }
          }
        />
      </Modal.Header>
      <Modal.Body>
        {exporting ? (
          <LoadingExport
            exported={exported}
            messageExported={messageExported}
            download={download}
            error={exportError}
            testId={`${modalType}-exporting`}
          />
        ) : (
          <>
            <ToggleGroup>
              <Toggle
                size="medium"
                variant="group"
                isToggled={exportFormat === 'docx'}
                onClick={() => toggleExportFormat('docx')}
                fullWidth
                testId={`${modalType}-docx-toggle`}
              >
                MS Word
              </Toggle>
              {pdfToggle && (
                <Toggle
                  size="medium"
                  variant="group"
                  isToggled={exportFormat === 'pdf'}
                  onClick={() => toggleExportFormat('pdf')}
                  fullWidth
                  testId={`${modalType}-pdf-toggle`}
                >
                  PDF
                </Toggle>
              )}
              <Toggle
                size="medium"
                variant="group"
                isToggled={exportFormat === 'html'}
                onClick={() => toggleExportFormat('html')}
                fullWidth
                testId={`${modalType}-html-toggle`}
              >
                HTML
              </Toggle>
            </ToggleGroup>
            <SectionHeader margin="3rem 0 2rem 0">
              <FormattedMessage id="DOCUMENT_TEMPLATE" />
            </SectionHeader>
            <TemplateDetails objectId={object.id} />
            {exportFormat !== 'html' && templateOptions.show && (
              <TemplateOptions options={templateOptions} setOptions={setTemplateOptions} />
            )}
            {exportFormat !== 'pdf' && (
              <>
                <SectionHeader margin="3rem 0 2rem 0">
                  <FormattedMessage id="DOCUMENT_CONTENT" />
                </SectionHeader>
                <RadioButton
                  checked={customizedContent === 'all'}
                  onChange={() => handleDocumentContent('all')}
                  size="small"
                  testId={`${modalType}-document-content-all-radio-button`}
                >
                  {intl.formatMessage({ id: 'ALL' })}
                </RadioButton>
                <RadioButton
                  checked={customizedContent === 'customized'}
                  onChange={() => handleDocumentContent('customized')}
                  disabled={navigationList.length === 0}
                  size="small"
                  margin="1rem 0 0 0"
                  testId={`${modalType}-document-content-customized-radio-button`}
                >
                  {intl.formatMessage({ id: 'CUSTOMIZED' })}
                </RadioButton>
              </>
            )}
            {customizedContent === 'customized' && (
              <DocumentContent
                selectedItems={selectedItems}
                setSelectedItems={handleSelectedItem}
              />
            )}
            {exportFormat !== 'pdf' && (
              <SectionHeader margin="3rem 0 2rem 0">
                <FormattedMessage id="REFERENCE_FORMAT" />
              </SectionHeader>
            )}
            {exportFormat === 'docx' && (
              <div>
                <RadioButton
                  checked={referenceFormat === 'original'}
                  onChange={() => setReferenceFormat('original')}
                  size="small"
                  testId={`${modalType}-reference-format-keep-original-format-radio-button`}
                >
                  <div className={styles.referenceKeepOriginal}>
                    {intl.formatMessage({ id: 'KEEP_ORIGINAL_FORMAT' })}
                    <div style={{ marginLeft: '1rem' }}>
                      <Tooltip
                        placement="bottom"
                        content={intl.formatMessage({
                          id: 'KEEP_ORIGINAL_FORMAT_TOOLTIP',
                        })}
                        testId={`${modalType}-keep-original-format-information-tooltip`}
                      >
                        <Icon icon="InformationBlue" size={16} />
                      </Tooltip>
                    </div>
                  </div>
                </RadioButton>
                <div className={styles.convert_container}>
                  <RadioButton
                    checked={referenceFormat === 'convertTo'}
                    onChange={() => setReferenceFormat('convertTo')}
                    size="small"
                    margin="0 3rem 0 0"
                    testId={`${modalType}-reference-format-convert-to-radio-button`}
                  >
                    {intl.formatMessage({ id: 'CONVERT_TO' })}
                  </RadioButton>
                  <Select
                    disabled={referenceFormat !== 'convertTo'}
                    options={[
                      { value: 'docx', label: 'Word' },
                      { value: 'mendeley', label: 'Mendeley' },
                      { value: 'endnote', label: 'EndNote' },
                    ]}
                    onChange={setConvertToOption}
                    value={convertToOption}
                    width="28rem"
                    menuPosition="fixed"
                    size="medium"
                    clearable={false}
                    testId={`${modalType}-convert-to`}
                  />
                </div>
              </div>
            )}
            {exportFormat !== 'pdf' && (
              <>
                <Checkbox
                  size="x-small"
                  checked={exportComments ? 'checked' : 'unchecked'}
                  onChange={toggleExportComments}
                  margin="0 0 2rem 0"
                  testId={`${modalType}-include-comments-checkbox`}
                >
                  <FormattedMessage id="EXPORT_ALL_COMMENTS" />
                </Checkbox>
                <Checkbox
                  size="x-small"
                  checked={exportSuggestions ? 'checked' : 'unchecked'}
                  onChange={toggleExportSuggestions}
                  margin="0 0 2rem 0"
                  testId={`${modalType}-include-track-changes-checkbox`}
                >
                  <FormattedMessage id="EXPORT_ALL_SUGGESTIONS" />
                </Checkbox>
                <Tooltip
                  content={intl.formatMessage({ id: 'TASKS_CANNOT_BE_EXPORTED_TO_HTML' })}
                  disabled={exportFormat === 'docx'}
                  placement="bottom"
                  testId={`${modalType}-include-tasks-as-comments-tooltip`}
                >
                  <span>
                    <Checkbox
                      size="x-small"
                      checked={exportFormat === 'html' || !exportTasks ? 'unchecked' : 'checked'}
                      onChange={toggleExportTasks}
                      disabled={exportFormat === 'html'}
                      margin="0 0 1rem 0"
                      testId={`${modalType}-include-tasks-as-comments-checkbox`}
                    >
                      <FormattedMessage id="INCLUDE_TASKS_AS_COMMENTS" />
                    </Checkbox>
                  </span>
                </Tooltip>
              </>
            )}
          </>
        )}
      </Modal.Body>
      {!exporting && (
        <Modal.Footer>
          <Button size="medium" onClick={close} testId={`${modalType}-cancel-button`}>
            <FormattedMessage id="global.close" />
          </Button>
          <Button
            size="medium"
            variant="primary"
            onClick={exportDocument}
            testId={`${modalType}-submit-button`}
          >
            <FormattedMessage id="global.export" />
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ExportDocumentModal;
