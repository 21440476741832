import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axios/axios';

// Define a service using a base URL and expected endpoints
const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    app: 'api',
    apiRoute: '/api',
  }),
  tagTypes: [
    'ElementStatus',
    'Role',
    'Affiliation',
    'PublicLinkSetting',
    'Tag',
    'Template',
    'InstalledTemplate',
    'Object',
    'PublicLink',
    'Metadata',
    'ReferenceStyle',
    'InstalledReferenceStyle',
    'Settings',
    'Link',
    'document',
    'file',
    'folder',
    'dopdf',
    'extension',
    'group',
    'link',
    'metadata',
    'notification',
    'recycle',
    'role',
    'searchfilter',
    'space',
    'status',
    'download',
    'path',
  ],
  endpoints: () => ({}),
});

export default api;
