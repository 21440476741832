import { useSelector } from '_common/hooks';

import DetailsPanel from './DetailsPanel';
import TasksPanel from './TasksPanel';
import AnnotationsPanel from './AnnotationsPanel';
import VersionHistoryPanel from './VersionHistoryPanel';

import styles from './RightSidePanel.module.scss';

const RightSidePanel = () => {
  const rightPanel = useSelector((state) => state.pdf.general.rightPanel);

  const renderPanel = () => {
    switch (rightPanel) {
      case 'details':
        return <DetailsPanel />;
      case 'tasks':
        return <TasksPanel testId="tasks-panel" />;
      case 'annotations':
        return <AnnotationsPanel />;
      case 'versionhistory':
        return <VersionHistoryPanel />;

      default: {
        return rightPanel;
      }
    }
  };

  return <div className={styles.panel}>{renderPanel()}</div>;
};

export default RightSidePanel;
