import { mix } from 'mixwith';
import { HistoryManager, OperationDebouncer } from 'Editor/services';

import { Logger } from '_common/services';
import { BaseTypedEmitter } from '_common/services/Realtime';
import DiffManager from 'Editor/services/ChangeTracker/DiffManager';
import ActionContext from '../EditionManager/EditionModes/_Common/models/ActionContext';

const FLUSHER_DEBOUNCER_CONFIG = {
  enabled: true,
  flushDebounceTime: 200, //* A.K.A. SAVE RATE (ms)
  forceFlushInterval: 500, //* FORCES A SAVE AT THEN END OF AN INTERVAL WHEN A DEBOUNCE CYCLE STARTS(ms)
};

const MAIN_DEBOUNCER = 'MAIN_DEBOUNCER';
const FORCE_FLUSH_DEBOUNCER = 'FORCE_FLUSH_DEBOUNCER';

class ChangeTracker extends BaseTypedEmitter {
  constructor(editorContext) {
    super();

    this.editorContext = editorContext;

    this.diffManager = new DiffManager(editorContext);

    this.subscriptions = [];
    this.paused = true;
    this.isStopped = true;
    this.addedNodes = [];
    this.removedNodes = [];
    this.changedNodes = [];
    this.createSuggestions = {};
    this.updateSuggestions = [];
    this.__isUpdating = 0;
    this.jsonChanges = false;
  }

  destroy() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    super.destroy();
  }

  debouncerActionSaveChange() {
    this.clearDebouncer(MAIN_DEBOUNCER);
    this.clearDebouncer(FORCE_FLUSH_DEBOUNCER);
    this.evaluateChanges();
  }

  /**
   * add changes for evaluation from action context object
   * @param {ActionContext} actionContext
   */
  saveActionChanges(actionContext) {
    if (actionContext && (actionContext.changes || actionContext.jsonChanges)) {
      const changes = actionContext.changes;
      this.jsonChanges = this.jsonChanges || actionContext.jsonChanges;

      let _added = false;
      let _removed = false;
      let _updated = false;
      let index;
      let nodeId;
      const force =
        actionContext.action === ActionContext.ACTION.PASTE || actionContext.jsonChanges;
      const added = changes[ActionContext.CHANGE_TYPE.ADDED];
      const removed = changes[ActionContext.CHANGE_TYPE.REMOVED];
      const updated = changes[ActionContext.CHANGE_TYPE.UPDATED];

      if (Array.isArray(added)) {
        for (index = 0; index < added.length; index++) {
          nodeId = added[index];
          if (!this.addedNodes.includes(nodeId)) {
            this.addedNodes.push(nodeId);
            _added = true;
          }
        }
      }

      if (Array.isArray(removed)) {
        for (index = 0; index < removed.length; index++) {
          nodeId = removed[index];
          if (!this.removedNodes.includes(nodeId)) {
            this.removedNodes.push(nodeId);
            _removed = true;
          }
        }
      }

      if (Array.isArray(updated)) {
        for (index = 0; index < updated.length; index++) {
          nodeId = updated[index];

          let blockId;
          if (nodeId.includes(':')) {
            blockId = nodeId.split(':')[0];
          } else {
            blockId = nodeId;
          }

          if (
            !this.changedNodes.includes(blockId) &&
            !this.changedNodes.includes(nodeId) &&
            !this.removedNodes.includes(blockId) &&
            !this.removedNodes.includes(nodeId) &&
            !this.addedNodes.includes(blockId) &&
            !this.addedNodes.includes(nodeId)
          ) {
            this.changedNodes.push(nodeId);
            _updated = true;
          }
        }
      }

      if (!actionContext.handled) {
        if (actionContext.needsCreation) {
          this.createSuggestions[actionContext.id] = {
            type: actionContext.type,
            inserted: actionContext.insertedContent,
            removed: actionContext.deletedContent,
            locations: actionContext.locations,
            id: actionContext.id,
          };
        }
        const references = actionContext.refreshReferences;
        for (let index = 0; index < references.length; index++) {
          const ref = references[index];
          if (!this.createSuggestions[ref] && !this.updateSuggestions.includes(ref)) {
            this.updateSuggestions.push(ref);
          }
        }
        actionContext.handled = true;
      }

      if (_added === true || _removed === true || _updated === true || this.jsonChanges === true) {
        this.saveChange(_added === true || _removed === true || force === true);
      }

      actionContext.flushChanges();
    }
  }

  saveChange(force = false) {
    if (force || !FLUSHER_DEBOUNCER_CONFIG.enabled) {
      if (!this.hasDebouncer(MAIN_DEBOUNCER)) {
        this.editorContext.DataManager.selection.history.flag('debounce', 1);
      }
      this.debouncerActionSaveChange();
    } else {
      if (this.hasDebouncer(MAIN_DEBOUNCER)) {
        this.clearDebouncer(MAIN_DEBOUNCER);
      } else {
        this.editorContext.DataManager.selection.history.flag('debounce', 1);
        if (
          !this.hasDebouncer(FORCE_FLUSH_DEBOUNCER) &&
          FLUSHER_DEBOUNCER_CONFIG.forceFlushInterval
        ) {
          //* DEBOUNCE CYCLE START
          this.refreshDebouncer(
            FORCE_FLUSH_DEBOUNCER,
            this.debouncerActionSaveChange.bind(this),
            FLUSHER_DEBOUNCER_CONFIG.forceFlushInterval,
          );
        }
      }
      this.refreshDebouncer(
        MAIN_DEBOUNCER,
        this.debouncerActionSaveChange.bind(this),
        FLUSHER_DEBOUNCER_CONFIG.flushDebounceTime,
      );
    }
  }

  evaluateChanges() {
    try {
      this.__isSaving = true;
      // this is already calculated in the force flag, but to be safe
      const hasChanges = this.changedNodes && this.changedNodes.length > 0;
      const hasIndexChanges = this.addedNodes.length > 0 || this.removedNodes.length > 0;
      let action;
      if (hasChanges || hasIndexChanges) {
        action = this.diffManager.diffThis(
          this.editorContext.documentContainer,
          this.addedNodes,
          this.removedNodes,
          this.changedNodes,
        );
        this.addedNodes = [];
        this.removedNodes = [];
        this.changedNodes = [];
      }

      const creatingSuggs = Object.keys(this.createSuggestions);
      for (let index = 0; index < creatingSuggs.length; index++) {
        const sugg = this.createSuggestions[creatingSuggs[index]];
        this.editorContext.DataManager?.suggestions.addTrackedAction(
          sugg.type,
          ActionContext.suggestedInsertedContent(sugg.id),
          ActionContext.suggestedDeletedContent(sugg.id),
          sugg.locations,
          sugg.id,
        );
      }
      for (let index = 0; index < this.updateSuggestions.length; index++) {
        const ref = this.updateSuggestions[index];
        this.editorContext.DataManager?.suggestions.updateSuggestionContent(ref, {
          inserted: ActionContext.suggestedInsertedContent(ref),
          deleted: ActionContext.suggestedDeletedContent(ref),
        });
      }
      this.updateSuggestions = [];
      this.createSuggestions = {};

      if (action && action.patch.length) {
        try {
          this.editorContext.DataManager?.history.applyChangeAction(action);

          HistoryManager.getInstance().createPatch();
        } catch (error) {
          Logger.captureException(error);
        }
      } else if (this.jsonChanges === true) {
        HistoryManager.getInstance().createPatch();
      }

      this.jsonChanges = false;
      this.__isSaving = false;
    } catch (error) {
      Logger.captureException(error);
    }
  }
}

class ChangeTrackerMix extends mix(ChangeTracker).with(OperationDebouncer) {}

export default ChangeTrackerMix;
