import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { OnboardingCards } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { RightPanel, setRightPanel } from 'PDF/redux/PDFGeneralSlice';
import { selectIsIEnvision } from 'App/redux/appSlice';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';

type VisibleSpace = {
  top: string;
  bottom: string;
  height: string;
  width: string;
  right: string;
  left: string;
};

const OnboardingPDF = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { canAccessVersionHistory } = usePDFPermissions();
  const [sendOnboardingStatus] = useOnboardingStatusMutation();

  const active = useSelector((state) => state.onboarding.active.dopdf);
  const isIEnvision = useSelector(selectIsIEnvision);
  const versionWarning = useSelector((state) => state.app.versionWarning);
  const connectivityIssues = useSelector((state) => state.app.connectivityIssues);
  const pulseData = useSelector((state) => state.onboarding.pulseData);

  const hasBanner = versionWarning.isOpen || connectivityIssues;
  const topValue = hasBanner ? '160px' : '112px';
  const heightValue = hasBanner ? '208px' : '160px';
  const bannerSpacing = hasBanner ? 48 : 0;

  const toggleRightPanel = (panel: RightPanel | undefined) => {
    dispatch(setRightPanel(panel));
  };

  const visibleSpace: Record<string, VisibleSpace> = useMemo(
    () => ({
      toolbar: {
        top: '0px',
        bottom: `calc(100% - ${topValue})`,
        height: `${topValue}`,
        width: '100%',
        right: '0px',
        left: '0px',
      },
      sidebarClosed: {
        top: `${topValue}`,
        height: `calc(100% - ${heightValue})`,
        width: '100%',
        right: '0',
        left: 'calc(100% - 48px)',
        bottom: '48px',
      },
      sidebarOpen: {
        top: `${topValue}`,
        height: `calc(100% - ${heightValue})`,
        width: '100%',
        right: '0',
        left: 'calc(100% - 440px)',
        bottom: '48px',
      },
    }),
    [topValue, heightValue],
  );

  const cards = useMemo(() => {
    return [
      // Beginning
      {
        position: {
          right: '7rem',
          bottom: '7rem',
        },
        header: 'doPDF',
        content: intl.formatMessage(
          { id: 'ONBOARDING_CARD_PDF_1_CONTENT' },
          { p: (chunks) => <p>{chunks}</p>, d: (chunks) => <div>{chunks}</div> },
        ),
        href: '',
      },
      // Toolbar
      {
        position: {
          left: '43rem',
          top: `${140 + bannerSpacing}px`,
        },
        pulse: {
          bottom: '34rem',
          left: '-8.375rem',
        },
        visibleSpace: visibleSpace.toolbar,
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_2_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_2_CONTENT' }),
        href: '',
      },
      // Document management
      {
        position: {
          left: '20.625rem',
          top: `${140 + bannerSpacing}px`,
        },
        box: {
          bottom: '32.5rem',
          left: '-20.625rem',
          width: '370px',
          height: '48px',
        },
        visibleSpace: visibleSpace.toolbar,
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_3_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_3_CONTENT' }),
        href: '',
      },
      // Change Status
      {
        position: {
          left: `${(pulseData.statusLabelRect?.left ?? 0) + 68}px`,
          top: `${76 + bannerSpacing}px`,
        },
        pulse: {
          bottom: '31rem',
          left: '-7.175rem',
        },
        visibleSpace: visibleSpace.toolbar,
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_4_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_4_CONTENT' }),
        href: '',
      },
      // Annotations
      {
        position: {
          left: 'calc(50% - 41px)',
          top: `${140 + bannerSpacing}px`,
        },
        pulse: {
          bottom: '31rem',
          left: '-9.375rem',
        },
        visibleSpace: visibleSpace.toolbar,
        box: {
          width: '217px',
          height: '45px',
          left: '-176px',
          bottom: '32.75rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_5_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_5_CONTENT' }),
        href: '',
      },
      // Annotation Style
      {
        position: {
          left: 'calc(50% + 149px)',
          top: `${140 + bannerSpacing}px`,
        },
        pulse: {
          bottom: '31rem',
          left: '-7.375rem',
        },
        visibleSpace: visibleSpace.toolbar,
        box: {
          width: '208px',
          height: '45px',
          left: '-146px',
          bottom: '32.75rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_6_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_6_CONTENT' }),
        href: '',
      },
      // Freestyle comments
      {
        position: {
          left: 'calc(50% + 230px)',
          top: `${140 + bannerSpacing}px`,
        },
        pulse: {
          top: '-5rem',
          left: '0rem',
        },
        visibleSpace: visibleSpace.toolbar,
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_7_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_7_CONTENT' }),
        href: '',
      },
      // Freestyle tasks
      {
        position: {
          left: 'calc(50% + 275px)',
          top: `${140 + bannerSpacing}px`,
        },
        pulse: {
          top: '-4.5rem',
          left: '0rem',
        },
        visibleSpace: visibleSpace.toolbar,
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_8_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_8_CONTENT' }),
        href: '',
      },
      // Sidebar
      {
        position: {
          right: '9.5rem',
          top: `${123 + bannerSpacing}px`,
        },
        pulse: {
          top: '0rem',
          left: '46.125rem',
        },
        visibleSpace: visibleSpace.sidebarClosed,
        onNext: () => toggleRightPanel('details'),
        onPrevious: () => toggleRightPanel(undefined),
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_9_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_9_CONTENT' }),
        href: '',
      },
      // Document Details
      {
        position: {
          right: '59rem',
          top: `${134 + bannerSpacing}px`,
        },
        pulse: {
          top: '0rem',
          left: '46.125rem',
        },
        visibleSpace: visibleSpace.sidebarOpen,
        onNext: () => toggleRightPanel('annotations'),
        onPrevious: () => toggleRightPanel(undefined),
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_10_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_10_CONTENT' }),
        href: '',
      },
      // Comments 1
      {
        position: {
          right: '59rem',
          top: `${134 + bannerSpacing}px`,
        },
        pulse: {
          top: '0rem',
          left: '46.125rem',
        },
        visibleSpace: visibleSpace.sidebarOpen,
        onPrevious: () => toggleRightPanel('details'),
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_11_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_11_CONTENT' }),
        href: '',
      },
      // Comments 2
      {
        position: {
          right: '59rem',
          top: `${134 + bannerSpacing}px`,
        },
        pulse: {
          top: '0rem',
          left: '46.125rem',
        },
        visibleSpace: visibleSpace.sidebarOpen,
        onNext: () => toggleRightPanel('tasks'),
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_12_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_12_CONTENT' }),
        href: '',
      },
      // Tasks 1
      {
        position: {
          right: '59rem',
          top: `${134 + bannerSpacing}px`,
        },
        pulse: {
          top: '0rem',
          left: '46.125rem',
        },
        visibleSpace: visibleSpace.sidebarOpen,
        onPrevious: () => toggleRightPanel('annotations'),
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_13_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_13_CONTENT' }),
        href: '',
      },
      // Tasks 2
      {
        position: {
          right: '59rem',
          top: `${134 + bannerSpacing}px`,
        },
        pulse: {
          top: '0rem',
          left: '46.125rem',
        },
        visibleSpace: visibleSpace.sidebarOpen,
        onNext: () => toggleRightPanel('versionhistory'),
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_14_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_14_CONTENT' }),
        href: '',
      },
      //VersionHistory
      {
        position: {
          right: canAccessVersionHistory ? '59rem' : '10rem',
          top: canAccessVersionHistory ? `${134 + bannerSpacing}px` : `${252 + bannerSpacing}px`,
        },
        pulse: {
          top: '0rem',
          left: '46.125rem',
        },
        visibleSpace: canAccessVersionHistory
          ? visibleSpace.sidebarOpen
          : visibleSpace.sidebarClosed,
        onNext: () => toggleRightPanel(undefined),
        onPrevious: () => toggleRightPanel('tasks'),
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_15_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_15_CONTENT' }),
        href: '',
      },
      //End
      {
        position: {
          right: '7rem',
          bottom: '7rem',
        },
        onPrevious: () => toggleRightPanel('versionhistory'),
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_16_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_PDF_16_CONTENT' }),
        href: '',
      },
    ];
  }, [hasBanner, pulseData.statusLabelRect]);

  const skipProps = useMemo(() => {
    return {
      skipPreviousLabel: intl.formatMessage({ id: 'CONTINUE_ONBOARDING' }),
      skipFinishLabel: intl.formatMessage({ id: 'SKIP' }),
      skipHeader: intl.formatMessage({ id: 'ONBOARDING_SKIP_CARD_PDF_HEADER' }),
      skipContent: intl.formatMessage({ id: 'ONBOARDING_SKIP_CARD_PDF_CONTENT' }),
      skipPosition: {
        right: '7rem',
        bottom: '7rem',
      },
    };
  }, [intl]);

  const handleOnCloseOnboardingCard = async () => {
    sendOnboardingStatus({ target: 'dopdf', step: 'ending', skip: true });
  };

  const handleFinalExit = async () => {
    sendOnboardingStatus({ target: 'dopdf', step: 'ending' });
  };

  if (!active) {
    return null;
  }
  const envisionCards = cards.filter(
    (card) => card.header !== 'Change PDF status' && card.header !== 'Version history',
  );
  return (
    <OnboardingCards
      // @ts-expect-error card became a type other than a string because of the chunks
      cards={isIEnvision ? envisionCards : cards}
      finishLabel={intl.formatMessage({ id: 'FINISH' })}
      onExit={handleOnCloseOnboardingCard}
      onFinalExit={handleFinalExit}
      finalLinkLabel=""
      {...skipProps}
      width="44rem"
      startLabel={intl.formatMessage({ id: 'GET_STARTED' })}
      size="medium"
      onCloseTooltipContent={intl.formatMessage({ id: 'SKIP_ONBOARDING' })}
      skipOnCloseTooltipContent={intl.formatMessage({ id: 'CONTINUE_ONBOARDING' })}
      testId='pdf'
    />
  );
};

export default OnboardingPDF;
