import { Input, InputField, Select } from 'dodoc-design-system';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { InputSelectFieldValue } from '../PageSetupModal';
import styles from './InputSelectField.module.scss';

type InputSelectFielddProps = {
  values: InputSelectFieldValue;
  setValues: (v: InputSelectFieldValue) => void;
  label: string;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled: boolean;
};

const UNIT_SIZE_OPTIONS = [
  { value: 'cm', label: 'Centimeters (cm)' },
  { value: 'in', label: 'Inches (in)' },
  { value: 'pt', label: 'Points (pt)' },
  { value: 'px', label: 'Pixels (px)' },
];

const WIDTH_HEIGHT_MAX_MIN_VALUES: { [key: string]: { min: number; max: number } } = {
  cm: {
    min: 5.588,
    max: 55.88,
  },
  in: {
    min: 2.2,
    max: 22,
  },
  px: {
    min: 211.2,
    max: 2112,
  },
  pt: {
    min: 158.4,
    max: 1584,
  },
};

const MARGINS_MAX_MIN_VALUES: { [key: string]: { min: number; max: number } } = {
  cm: {
    min: 0,
    max: 55.88,
  },
  in: {
    min: 0,
    max: 22,
  },
  px: {
    min: 0,
    max: 2112,
  },
  pt: {
    min: 0,
    max: 1584,
  },
};

const InputSelectField = ({
  label,
  setDisabled,
  values,
  setValues,
  isDisabled,
}: InputSelectFielddProps) => {
  const intl = useIntl();
  const margins =
    label === 'Top' || label === 'Bottom' || label === 'Left' || label === 'Right' ? true : false;
  const unitSizeMinValue = margins
    ? MARGINS_MAX_MIN_VALUES[values.unit].min
    : WIDTH_HEIGHT_MAX_MIN_VALUES[values.unit].min;
  const unitSizeMaxValue = margins
    ? MARGINS_MAX_MIN_VALUES[values.unit].max
    : WIDTH_HEIGHT_MAX_MIN_VALUES[values.unit].max;

  const handleFeedbackError = () => {
    if (Number(values.value) > unitSizeMinValue && Number(values.value) < unitSizeMaxValue) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setDisabled(handleFeedbackError() ? true : false);
  }, [handleFeedbackError()]);

  const setValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, value: e.target.value });
  const setUnit = (value: { value: string; label: string }) =>
    setValues({ ...values, unit: value.value });

  return (
    <div className={styles.container}>
      <InputField
        testId={`${label.replace(/ /, '').toLowerCase()}-inputField`}
        label={label}
        margin="0 1rem 0 0"
        size="large"
        feedback={
          handleFeedbackError()
            ? intl.formatMessage(
                { id: 'CHOOSE_A_VALUE_BETWEEN' },
                { min: unitSizeMinValue, max: unitSizeMaxValue },
              )
            : ''
        }
        disabled={isDisabled}
      >
        <div style={{ display: 'flex' }}>
          <Input
            placeholder=""
            width="15rem"
            size="large"
            type="number"
            value={values.value}
            onChange={setValue}
            margin="0 1rem 0 0"
            error={handleFeedbackError()}
            testId={`${label.replace(/ /, '').toLowerCase()}-size-value`}
            disabled={isDisabled}
            allowDecimal
          />
          <Select
            clearable={false}
            options={UNIT_SIZE_OPTIONS}
            width="11rem"
            menuWidth="22.25rem"
            menuPosition="fixed"
            onChange={setUnit}
            value={{ value: values.unit, label: values.unit }}
            testId={`${label.replace(/ /, '').toLowerCase()}-unit-size`}
            disabled={isDisabled}
          />
        </div>
      </InputField>
    </div>
  );
};

export default InputSelectField;
