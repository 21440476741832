import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { Checkbox, Button, Modal } from 'dodoc-design-system';
import { openAndUpdateModal, closeAndResetModal } from '_common/modals/ModalsSlice';
import SearchUser from '_common/components/SearchUser/SearchUser';
import { changeObjectManager } from 'App/redux/appSlice';

import type { UserOption } from '_common/components/SearchUser/SearchUser';

const MODAL = 'ChangeManagerModal';

const ChangeManagerModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const data = useSelector((state) => state.modals[MODAL].data);
  const name = useSelector((state) => state.modals[MODAL].name);
  const userId = useSelector((state) => state.auth.userId);

  const [user, setUser] = useState<UserOption | null>(null);
  const [recursive, setRecursive] = useState(false);

  const getDescription = () => {
    return (
      <>
        <div>
          <FormattedMessage id="CHANGE_OWNER_WARNING" />
        </div>
        <div style={{ fontSize: '1.5rem', marginTop: '2.5rem' }}>
          <b>
            <FormattedMessage id="global.modals.delete.note" />
          </b>
          <FormattedMessage id="CHANGE_OWNER_NOTE" />
        </div>
      </>
    );
  };

  const onSubmitClick = () => {
    if (data.type === 'space' && +data.owner === +userId) {
      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            title: 'CHANGE_OWNER',
            messageChildren: getDescription(),
            confirmButtonTextId: 'global.change',
            confirmButtonType: 'danger',
            cancelButtonTextId: 'global.cancel',
            actionCode: 'changeManager',
            actionValue: {
              handleChangeManager,
            },
            headerType: 'information',
            cancelButtonShow: true,
          },
        }),
      );
    } else {
      handleChangeManager();
    }
    close();
  };

  const handleChangeManager = () => {
    if (user) {
      dispatch(
        changeObjectManager({
          objectType: data.type,
          objectId: data.id,
          params: {
            user: user.value,
            recursive,
          },
        }),
      );
    }

    close();
  };

  const close = () => {
    setUser(null);
    setRecursive(false);
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal width="75rem" open={!!isOpen} onClose={close} testId="change-manager">
      <Modal.Header onClose={close}>
        <FormattedMessage id="CHANGE_OWNER" />
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: '5rem', padding: '1rem 0' }}>
          <FormattedMessage id="global.owner" />
        </div>
        <SearchUser
          escapeClearsValue
          value={user}
          onChange={(user) => setUser(user)}
          width="100%"
          size="large"
          menuPosition="fixed"
          testId="change-manager"
        />
        {data.type === 'folder' && (
          <div style={{ width: '100%', height: '4rem', marginTop: '2rem' }}>
            <Checkbox
              checked={recursive ? 'checked' : 'unchecked'}
              onChange={() => setRecursive(!recursive)}
              testId="apply-to-descendants-checkbox"
            >
              <FormattedMessage id="storage.modals.share.applyToDescendants" values={{ name }} />
            </Checkbox>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="change-manager-modal-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={onSubmitClick}
          disabled={Array.isArray(user) && user.length === 0}
          testId="change-manager-modal-submit-button"
        >
          <FormattedMessage id="global.change" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeManagerModal;
