/* eslint-disable class-methods-use-this */
import { Logger } from '_common/services';
import { parseMeasurement } from 'utils';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { Parser, CSSParser } from '.';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { ELEMENTS, DEFAULT_TABLE_BORDERS } from 'Editor/services/consts';
import { EditorDOMElements, EditorDOMUtils } from '../../../_Common/DOM';
import {
  BaseBlockElement,
  HyperlinkElement,
  ImageElement,
  TableCellElement,
  TableElement,
} from 'Editor/services/VisualizerManager';
import { StylesHandler } from 'Editor/services/Styles';

const TEMPORARY_ID = 'data-cp-temp-id';

type TableCellStyles = {
  borderTopStyle?: string;
  borderTopWidth?: string;
  borderTopColor?: string;
  borderRightStyle?: string;
  borderRightWidth?: string;
  borderRightColor?: string;
  borderBottomStyle?: string;
  borderBottomWidth?: string;
  borderBottomColor?: string;
  borderLeftStyle?: string;
  borderLeftWidth?: string;
  borderLeftColor?: string;
  paddingTop?: string;
  paddingRight?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  backgroundColor?: string;
  verticalAlignment?: string;
};

type ParsedNodes = {
  [index: string]: Node;
};

type ParentsList = {
  [index: string]: string[];
};

type NodesToIgnore = {
  [index: string]: number;
};

type HtmlParsers = {
  [index: string]: Function;
};

type NodeTag = Extract<
  Editor.Elements.DOMElementTagNames,
  'P' | 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6' | 'PRE'
>;

type NodeTagMapping = {
  [index in NodeTag]: { tag: Editor.Elements.ElementTagsType; st: string };
};

type AllowedTag = 'P' | 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6' | 'SPAN' | 'TABLE' | 'TD';

type AllowedStylesForElement = {
  [index in AllowedTag]: string[];
};

type StyleParser = {
  [index: string]: Function;
};

export class HtmlParser extends Parser {
  static SYMBOLS_FONTS = ['symbol', 'wingdings', 'wingdings 2', 'wingdings 3'];
  static ALLOWED_STYLES_VALUES = {
    'font-weight': ['600', '700', '800', '900', 'bold'],
  };

  static NODE_TAG_MAPPING: NodeTagMapping = {
    P: { tag: ELEMENTS.ParagraphElement.TAG, st: ELEMENTS.ParagraphElement.ELEMENT_TYPE },
    H1: { tag: ELEMENTS.ParagraphElement.TAG, st: ELEMENTS.ParagraphElement.BASE_STYLES.HEADING1 },
    H2: { tag: ELEMENTS.ParagraphElement.TAG, st: ELEMENTS.ParagraphElement.BASE_STYLES.HEADING2 },
    H3: { tag: ELEMENTS.ParagraphElement.TAG, st: ELEMENTS.ParagraphElement.BASE_STYLES.HEADING3 },
    H4: { tag: ELEMENTS.ParagraphElement.TAG, st: ELEMENTS.ParagraphElement.BASE_STYLES.HEADING4 },
    H5: { tag: ELEMENTS.ParagraphElement.TAG, st: ELEMENTS.ParagraphElement.BASE_STYLES.HEADING5 },
    H6: { tag: ELEMENTS.ParagraphElement.TAG, st: ELEMENTS.ParagraphElement.BASE_STYLES.HEADING6 },
    PRE: { tag: ELEMENTS.ParagraphElement.TAG, st: ELEMENTS.ParagraphElement.ELEMENT_TYPE },
  };

  protected cssParser: CSSParser;
  private htmlParsers: HtmlParsers;
  private styleParsers: StyleParser;
  private mappedListId: Editor.Clipboard.MappedListId = {};
  public newDocumentStyles: Editor.Clipboard.ParsedDocumentStyles = {};

  // Temporary ID given to parsed nodes
  private tempId = 0;

  // Nodes style attributes parsed to json, saved in tempId -> json
  protected nodeStyleJson = {};

  // Map of tempId -> parsed node
  private parsedNodes: ParsedNodes = {};

  // Map of tempId => array of children tempId, will be used to build the tree
  private parentsList: ParentsList = {};

  // Object with keys of nodes to ignore
  protected nodesToIgnore: NodesToIgnore = {};
  protected listDefinitions: Editor.Clipboard.ParsedListDefenitions = {};

  // fixed the problem of repeated ids
  private workNodeIds: string[] = [];

  protected nodesToRemove: {
    [index: string]: number;
  } = {};

  // Which style property to parse for each node type
  ALLOWED_STYLES_FOR_ELEMENT: AllowedStylesForElement = {
    P: ['color', 'font-family', 'font-size', 'font-style', 'font-weight', 'text-decoration'],
    H1: ['color', 'font-family', 'font-size', 'font-style', 'font-weight', 'text-decoration'],
    H2: ['color', 'font-family', 'font-size', 'font-style', 'font-weight', 'text-decoration'],
    H3: ['color', 'font-family', 'font-size', 'font-style', 'font-weight', 'text-decoration'],
    H4: ['color', 'font-family', 'font-size', 'font-style', 'font-weight', 'text-decoration'],
    H5: ['color', 'font-family', 'font-size', 'font-style', 'font-weight', 'text-decoration'],
    H6: ['color', 'font-family', 'font-size', 'font-style', 'font-weight', 'text-decoration'],
    SPAN: [
      'color',
      'background-color',
      'font-style',
      'font-family',
      'font-size',
      'font-weight',
      'text-decoration',
      'text-decoration-line',
      'line-height',
      'text-align',
      'vertical-align',
    ],

    TABLE: [
      'background-color',
      'border-bottom-color',
      'border-bottom-style',
      'border-bottom-width',
      'border-left-color',
      'border-left-style',
      'border-left-width',
      'border-right-color',
      'border-right-style',
      'border-right-width',
      'border-top-color',
      'border-top-style',
      'border-top-width',
      'padding-bottom',
      'padding-left',
      'padding-right',
      'padding-top',
      'text-align',
    ],
    TD: ['color', 'font-style', 'font-family', 'font-size', 'font-weight', 'text-decoration'],
  };

  constructor(
    html: string,
    dataManager: Editor.Data.API,
    stylesHandler: StylesHandler,
    visualizerManager: Editor.Visualizer.API,
  ) {
    super(
      html.replace(/<span>\s+<\/span>/gm, '&nbsp;'),
      dataManager,
      stylesHandler,
      visualizerManager,
    );
    // New CSSParser instance, will use to get the node style declarations
    // Centralized for the office parsers to be able to merge properties from classnames
    this.cssParser = new CSSParser();
    // Parser for each type of HTML node
    this.htmlParsers = {
      '#text': this.handleTextNode.bind(this),
      A: this.handleAnchor.bind(this),
      B: this.handleBold.bind(this),
      BR: this.handleBreakline.bind(this),
      BUTTON: this.handleButton.bind(this),
      EM: this.handleItalic.bind(this),
      DIV: this.handleDiv.bind(this),
      H1: this.handleParagraph.bind(this),
      H2: this.handleParagraph.bind(this),
      H3: this.handleParagraph.bind(this),
      H4: this.handleParagraph.bind(this),
      H5: this.handleParagraph.bind(this),
      H6: this.handleParagraph.bind(this),
      I: this.handleItalic.bind(this),
      IMG: this.handleImage.bind(this),
      INS: this.handleInsertSuggestion.bind(this),
      OL: this.handleList.bind(this),
      P: this.handleParagraph.bind(this),
      PRE: this.handleParagraph.bind(this),
      S: this.handleStrikethrough.bind(this),
      SPAN: this.handleSpan.bind(this),
      STRONG: this.handleBold.bind(this),
      SUB: this.handleSubscript.bind(this),
      SUP: this.handleSuperscript.bind(this),
      TABLE: this.handleTable.bind(this),
      U: this.handleUnderline.bind(this),
      UL: this.handleList.bind(this),
      'V:IMAGEDATA': this.handleImage.bind(this),
    };
    // Parser for each style property
    this.styleParsers = {
      'background-color': this.handleBackgroundColor.bind(this),
      color: this.handleColor.bind(this),
      'font-family': this.handleFontFamily.bind(this),
      'font-size': this.handleFontSize.bind(this),
      'font-style': this.handleFontStyle.bind(this),
      'font-weight': this.handleFontWeight.bind(this),
      'padding-top': this.handlePadding.bind(this),
      'padding-right': this.handlePadding.bind(this),
      'padding-bottom': this.handlePadding.bind(this),
      'padding-left': this.handlePadding.bind(this),
      'text-decoration': this.handleTextDecoration.bind(this),
    };
  }

  /**
   * This method returns the generated temporary ID of the node, or generates a new one
   * in case the node still does not have one. It saves on the attribute TEMPORARY_ID
   * @param {Node} node Original node from the clipboard that we want the ID of
   */
  protected getNodeId(node: HTMLElement) {
    const id = `CP-${this.tempId}`;
    if (node.nodeType === Node.ELEMENT_NODE) {
      if (node.hasAttribute(TEMPORARY_ID)) {
        return node.getAttribute(TEMPORARY_ID);
      }
      node.setAttribute(TEMPORARY_ID, id);
    }
    this.tempId += 1;
    return id;
  }

  /**
   * This simply creates an entry for the parentId if needed and pushes the nodeId as its child
   * It also uses this.parsedNodes objecto to save the parsed node
   * @param {Node} node Parsed node to be kept in the this.parsedNodes object
   * @param {string} nodeId Generated internal node ID of the node to be set as a child
   * @param {string} parentId Generated internal node ID of the parent
   */
  private addToParentsList(node: Node, nodeId: string, parentId: string) {
    this.parsedNodes[nodeId] = node;
    if (!this.parentsList[parentId]) {
      this.parentsList[parentId] = [];
    }
    this.parentsList[parentId].push(nodeId);
  }

  /**
   * After the container has been completely parsed, this method will build the final html tree
   * using the data that has been saved along the process
   *
   * @memberof HtmlParser
   */
  private buildParsedTree() {
    Object.keys(this.parentsList).forEach((parentId) => {
      const childrenIds = this.parentsList[parentId];
      const parent = this.parsedNodes[parentId];
      childrenIds.forEach((childId) => {
        const child = this.parsedNodes[childId] as HTMLElement;
        if (child.tagName === 'SPAN') {
          while (child.firstChild) {
            parent.appendChild(child.firstChild);
          }
          child.remove();
        } else {
          parent.appendChild(child);
        }
        // discard temporary id used when parsing, might have to delay this
        if (child.nodeType === Node.ELEMENT_NODE) {
          child.removeAttribute(TEMPORARY_ID);
        }
      });
    });
  }

  /**
   * This method checks if the node passed should be parsed or skipped
   * @param {Node} node The reference of the node to be checked if it should be parsed,
   * It checks for the node type, node name and if it was already set to be ignored
   * @param {string} id Generated internal ID
   */
  private shouldParseNodeTree(node: Node, id: string) {
    // Previously set to ignore (e.g. ToF subsequent nodes)
    if (this.nodesToIgnore[id]) {
      return false;
    }
    // Is either an element or a text node
    if (node.nodeType !== Node.ELEMENT_NODE && node.nodeType !== Node.TEXT_NODE) {
      return false;
    }
    // Will not parse text nodes that are not children of the following elements
    if (node.nodeType === Node.TEXT_NODE && node.parentNode) {
      const parent = node.parentNode as HTMLElement;
      if (
        parent.tagName === 'TABLE' ||
        parent.tagName === 'TBODY' ||
        parent.tagName === 'TR' ||
        parent.tagName === 'TD'
      ) {
        return false;
      }
    }

    if (!this.htmlParsers[node.nodeName]) {
      // Has its own parser
      // Using nodeName due to text nodes not having a tag name
      // Text nodes have a nodeName === #text
      return false;
    }
    return true;
  }

  private canBeChildOf(workNode: HTMLElement, workParent: HTMLElement) {
    switch (workParent.tagName) {
      case 'TABLE':
        return workNode.tagName === 'TBODY';
      default:
        return true;
    }
  }

  private createParentNode(workNode: HTMLElement, workParent: HTMLElement) {
    let join: HTMLElement | null = null;
    let addJoin = true;
    let persistJoin = true;
    const closestTd = workNode.closest('TD');

    if (workParent.tagName === 'TABLE' && closestTd) {
      join = closestTd as HTMLElement;
      addJoin = false;
      persistJoin = false;
    } else {
      join = DOMElementFactory.buildElement(ELEMENTS.ParagraphElement.TAG) as HTMLElement;
    }

    const nodeId = this.getNodeId(join);

    if (nodeId) {
      join.id = nodeId;
    }

    return { join, addJoin, persistJoin };
  }

  public async parse() {
    this.debugMessage('HTMLParser Parsing...', this.data);
    this.container = document.createElement('div');
    this.beforeParse();
    try {
      await this.parseNodes();

      if (this.container.childNodes.length > 0) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
      this.debugMessage(
        'Parsed data',
        this.container,
        this.newDocumentStyles,
        this.listDefinitions,
      );
    } catch (e) {
      this.isValid = false;
      Logger.captureException(e);
      throw e;
    } finally {
      this.afterParse();
    }
  }

  protected beforeParse() {
    if (typeof this.data === 'string') {
      // Create an HTML element and use the html from the clipboard to set it as the inner html
      // This will always create the <head> and the <body> elements
      // Use this to retrieve any <style> from the <head> for further styles parsing
      // And retrieve every element from the <body> to also parse and insert in our document
      this.html = document.createElement('html');

      const cleanData = this.data
        // remove unwanted table cells
        // remove enters and whitespaces
        .replace(/>\s*?(\n\r|\r\n|\n|\r)\s+</gm, '><')
        .replace(/(\n\r|\r\n|\n|\r)/gm, ' ')
        // Treat unreachable local files
        .replace(/src=("|')file:\/\/\/.*?\1/gm, ' localfile ')
        .replace(/>\s+<(?!\/)/gm, '><')
        .replace(/(?<=<!\[if !supportMisalignedRows]>)(.*?)(?=<!\[endif]>)/gm, '')
        .trim();

      this.html.innerHTML = cleanData;

      // Handle body
      // Remove any unnecessary whitespace
      const body = this.html.querySelector('body');
      if (body) {
        const cleanHtml = body.innerHTML
          // remove unwanted table cells
          .replace(/(?<=<!--\[if !supportMisalignedRows]-->)(.*?)(?=<!--\[endif]-->)/gm, '')
          // remove enters and whitespaces
          .replace(/>\s*?(\n\r|\r\n|\n|\r)\s+</gm, '><')
          .replace(/(\n\r|\r\n|\n|\r)/gm, ' ')
          // Treat unreachable local files
          .replace(/src=("|')file:\/\/\/.*?\1/gm, ' localfile ')
          .replace(/>\s+<(?!\/)/gm, '><')
          .trim();
        body.innerHTML = `<div id="container" data-cp-temp-id="container">${cleanHtml}</div>`;
        // Add the container with the nodes to be parsed so they can be a match for the styles
        document.body.appendChild(this.html);
      }
    }
  }

  private afterParse() {
    // Remove the container from the document body
    this.existingCitations = [];
    if (this.html) {
      document.body.removeChild(this.html);
    }
  }

  /**
   * Responsible for getting all direct children of the container where the data
   * is currently stored and call this.parseNode for each one.
   *
   * After every node is parsed, it uses the helper maps to build the tree of parsed nodes
   */
  private async parseNodes() {
    if (this.html) {
      const container = this.html.querySelector('[id="container"]');
      if (container) {
        this.container = document.createElement('div');
        // Useful saving the container here for later when we are building the parsed tree
        this.parsedNodes.container = this.container;
        const nodes = Array.from(container.childNodes);
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];
          await this.parseNodeTree(node, container);
        }
        this.buildParsedTree();
      }
    }
  }

  /**
   * This method will generate a temporary id, handle the (probably) broken style attribute
   * and then parse the node. It iteratively parses nodes down the tree and keeps all the references
   * according to the temporary id generated
   * The maps will be:
   * - tempId -> Original nodes
   * - tempId -> parsed node
   * - tempId(parent) -> [tempId](children)
   * @param {Node} node The node to parse
   * @param {Node} parent The id of the parent of the node to be parsed
   */
  private async parseNodeTree(node: Node, parent: Node) {
    const work = [[node, parent]];
    let workNode;
    let workNodeId: string | null;
    let workParent;
    let workParentId: string | null;
    let childNode;
    let childNodeId;
    let join;

    // Give temporary ID if node does not have an id
    // used for mapping between node and styles
    // and some other checks
    const nodeId = this.getNodeId(node as HTMLElement);

    // Checks if node should be parsed due to node type, available parser, or if set to be ignored
    if (nodeId && this.shouldParseNodeTree(node, nodeId)) {
      while (work.length > 0) {
        try {
          //@ts-expect-error
          [workNode, workParent] = work.shift();
          workNodeId = this.getNodeId(workNode);
          workParentId = this.getNodeId(workParent);
          // eslint-disable-next-line no-await-in-loop
          const parseResult = await this.htmlParsers[workNode.nodeName](workNode, workParent);
          if (parseResult) {
            const { parsedWorkNode, parentForChildNodes } = parseResult;
            if (parsedWorkNode && workNodeId && workParentId) {
              this.addToParentsList(parsedWorkNode, workNodeId, workParentId);
            }

            if (parentForChildNodes) {
              join = null;
              const childNodes = workNode.childNodes;
              for (let index = childNodes.length - 1; index >= 0; index--) {
                childNode = childNodes[index];
                childNodeId = this.getNodeId(childNode);
                if (childNodeId && this.shouldParseNodeTree(childNode, childNodeId)) {
                  // TODO validate this check has to be on parentForChildNodes
                  if (this.canBeChildOf(childNode, workNode)) {
                    if (childNode instanceof HTMLElement) {
                      const tempId = childNode.getAttribute('data-cp-temp-id');
                      if (tempId && !this.workNodeIds.includes(tempId)) {
                        this.workNodeIds.push(tempId);
                        work.unshift([childNode, parentForChildNodes]);
                      }
                    } else {
                      work.unshift([childNode, parentForChildNodes]);
                    }
                    join = null;
                  } else if (!join) {
                    const result = this.createParentNode(childNode, workNode);
                    if (result.persistJoin) {
                      join = result.join;
                    }
                    if (result.addJoin && join && workParentId) {
                      this.addToParentsList(join, join.id, workParentId);
                    }
                    work.unshift([childNode, result.join]);
                  } else {
                    work.unshift([childNode, join]);
                  }
                }
              }
            }
          }
        } catch (e) {
          Logger.captureException(e);
        }
      }
    }
  }

  protected handleTextNode(node: Node) {
    return { parsedWorkNode: node.cloneNode() };
  }

  protected handleAnchor(node: HTMLElement, parent: HTMLElement) {
    if (node.childNodes.length === 0) {
      return null; // Ignore empty anchors
    }

    if (node.hasAttribute('href')) {
      const href = node.getAttribute('href');
      // Valid anchor element
      if (href) {
        const a = DOMElementFactory.buildElement(
          ELEMENTS.HyperlinkElement.IDENTIFIER,
        ) as HyperlinkElement;
        a.href = href;
        return { parsedWorkNode: a, parentForChildNodes: node };
      }
    }

    // Unwrap anchor childNodes to the parent
    return { parsedWorkNode: null, parentForChildNodes: parent };
  }

  private handleBold(node: HTMLElement, parent: HTMLElement) {
    // Ignore <b> and unwrap to parent
    if (node.style.fontWeight === 'normal') {
      // return this.unwrapTo(parent);
      return { parsedWorkNode: null, parentForChildNodes: parent };
    }
    const parsedWorkNode = this.createFormatElement({ bold: true });
    return {
      parsedWorkNode,
      parentForChildNodes: node,
    };
  }

  private handleBreakline(node: HTMLElement) {
    const span = document.createElement('span');
    span.appendChild(document.createTextNode('\n'));
    return {
      parsedWorkNode: span,
      parentForChildNodes: node,
    };
  }

  private handleButton() {
    return null;
  }

  private handleItalic(node: HTMLElement) {
    const parsedWorkNode = this.createFormatElement({ italic: true });
    return {
      parsedWorkNode,
      parentForChildNodes: node,
    };
  }

  protected handleDiv(div: HTMLElement, parent: HTMLElement) {
    // has happened original content being a table centered due to the align property in a parent div
    if (div.hasAttribute('align')) {
      const align = div.getAttribute('align');
      const table = div.querySelector('table');
      if (table && align) {
        table.dataset.alignment = align;
      }
    }
    return {
      parsedWorkNode: null,
      parentForChildNodes: parent,
    };
  }

  protected async keepOriginalStyles(containerData: HTMLElement) {
    if (containerData) {
      this.mappedListId = {};

      if (this.newDocumentStyles) {
        const keys = Object.keys(this.newDocumentStyles);
        const length = keys.length;
        for (let i = 0; i < length; i++) {
          const styleName = keys[i] as keyof Editor.Clipboard.ParsedDocumentStyles;

          const elements = containerData.querySelectorAll(`*[data-temp-style-id="${styleName}"]`);
          if (elements.length > 0) {
            for (let j = 0; j < elements.length; j++) {
              const element = elements[j] as HTMLElement;
              element.dataset.styleId = ELEMENTS.ParagraphElement.ELEMENT_TYPE;
              element.removeAttribute('data-temp-style-id');

              // Put all document styles as inline inside the paragraph that uses the styles
              const formatElementStyles: Editor.Clipboard.FormatStyleAttributes = {};

              if (this.newDocumentStyles[styleName]?.p) {
                const styleKeys = Object.keys(this.newDocumentStyles[styleName]?.p);
                for (let x = 0; x < styleKeys.length; x++) {
                  const st = styleKeys[x];
                  const value = this.newDocumentStyles[styleName].p[st];

                  switch (st) {
                    case 'a':
                      if (element.dataset.alignment == null) {
                        element.dataset.alignment = value as string;
                      }
                      break;
                    case 'lh':
                      if (element.dataset.lineHeight == null) {
                        element.dataset.lineHeight = value as string;
                      }
                      break;
                    case 'sb':
                      if (element.dataset.spaceBefore == null) {
                        element.dataset.spaceBefore = value as string;
                      }
                      break;
                    case 'sa':
                      if (element.dataset.spaceAfter == null) {
                        element.dataset.spaceAfter = value as string;
                      }
                      break;
                    case 'bg':
                      if (element.dataset.backgroundColor == null) {
                        element.dataset.backgroundColor = value as string;
                      }
                      break;
                    case 'fontsize': // to work with line height
                      if (element.dataset.fontSize == null) {
                        element.dataset.fontSize = value as string;
                      }
                      formatElementStyles.fontsize = value as number;
                      break;
                    case 'color':
                      const color = value as string;
                      if (element.dataset.color == null) {
                        element.dataset.color = color;
                      }
                      if (
                        color.includes('rgb') ||
                        color.includes('#') ||
                        typeof color === 'boolean' ||
                        color === 'false' ||
                        color === 'true'
                      ) {
                        formatElementStyles.color = color;
                      } else if (typeof value === 'string') {
                        formatElementStyles.color = `#${color}`;
                      }
                      break;
                    case 'fontfamily':
                      if (element.dataset.fontFamily == null) {
                        element.dataset.fontFamily = value as string;
                      }
                      formatElementStyles[st] = value as string;
                      break;
                    case 'italic':
                      if (element.dataset.italic == null) {
                        element.dataset.italic = value as string;
                      }
                      formatElementStyles[st] = value as boolean;
                      break;
                    case 'underline':
                      if (element.dataset.underline == null) {
                        element.dataset.underline = value as string;
                      }
                      formatElementStyles[st] = value as boolean;
                      break;
                    case 'bold':
                      if (element.dataset.bold == null) {
                        element.dataset.bold = value as string;
                      }
                      formatElementStyles[st] = value as boolean;
                      break;
                    case 'v':
                      if (element.dataset.vanish == null) {
                        element.dataset.vanish = value as string;
                      }
                      formatElementStyles.vanish = value as boolean;
                      break;
                    case 'ind':
                      const ind = value as Editor.Data.Structure.DocumentStyleProperties;
                      if (ind?.l != null && element.dataset.leftIndentation == null) {
                        element.dataset.leftIndentation = ind.l as string;
                      }
                      if (ind?.r != null && element.dataset.rightIndentation == null) {
                        element.dataset.rightIndentation = ind.r as string;
                      }
                      break;
                    case 'sp_ind':
                      const spInd = value as Editor.Data.Structure.DocumentStyleProperties;
                      if (spInd?.t != null && element.dataset.specialIndent == null) {
                        element.dataset.specialIndent = spInd.t as string;
                      }
                      if (spInd?.v != null && element.dataset.specialIndentValue == null) {
                        element.dataset.specialIndentValue = String(spInd.v);
                      }
                      break;
                    default:
                      if (
                        StylesUtils.ALLOWED_INLINE_ATTRIBUTES_BY_ELEMENT[element.tagName]?.includes(
                          st as Editor.Styles.Styles,
                        )
                      ) {
                        //@ts-expect-error
                        formatElementStyles[st] = value;
                      }
                      const blockElement = element as BaseBlockElement;
                      if (
                        StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[element.tagName]?.includes(
                          st as Editor.Styles.Styles,
                        ) &&
                        !blockElement.hasStyleAttribute?.(st as Editor.Styles.Styles)
                      ) {
                        const attributeValue =
                          typeof value === 'number' ? String(value) : Boolean(value);
                        blockElement.addStyleAttribute(st as Editor.Styles.Styles, attributeValue);
                      }
                      break;
                  }
                }
              }

              const formatElement = this.createFormatElement(formatElementStyles);
              if (formatElement) {
                while (element.firstChild) {
                  formatElement.appendChild(element.firstChild);
                }
                element.appendChild(formatElement);
              }
            }
          }
        }
      }

      // handle list styles
      if (this.listDefinitions) {
        const listIds = Object.keys(this.listDefinitions);
        for (let i = 0; i < listIds.length; i++) {
          const id = listIds[i];
          const listStyleId = await this.dataManager.styles.listStyles.createListStyle(
            Object.values(this.listDefinitions[id]),
          );
          this.mappedListId[id] = { listStyleId };
        }

        // handle paragraphs with list style id
        const ids = Object.keys(this.mappedListId);
        for (let i = 0; i < ids.length; i++) {
          const listId = ids[i];

          const elements = containerData.querySelectorAll(`*[cp_list_style="${listId}"]`);

          let newListId;
          const listStyleId = this.mappedListId[listId].listStyleId;

          if (elements.length > 0 && listStyleId) {
            newListId = await this.dataManager.numbering.createNewList(listStyleId);
            this.mappedListId[listId].listId = newListId;
          }

          for (let j = 0; j < elements.length; j++) {
            if (elements[j]) {
              const element = elements[j] as HTMLElement;
              const cpListLevel = element.getAttribute('cp_list_level');
              const listLevel = cpListLevel != null ? Number(cpListLevel) + 1 : null;

              if (listLevel && !isNaN(listLevel)) {
                const levelData = this.listDefinitions[listId][listLevel];

                // handle indentations from list
                if (levelData.indentation_left != null) {
                  element.dataset.leftIndentation = String(
                    EditorDOMUtils.convertUnitTo(levelData.indentation_left),
                  );
                }
                if (levelData.indentation_right != null) {
                  element.dataset.rightIndentation = String(
                    EditorDOMUtils.convertUnitTo(levelData.indentation_right),
                  );
                }
                if (levelData.special_indent != null && levelData.special_indent_value != null) {
                  element.dataset.specialIndent = levelData.special_indent;
                  element.dataset.specialIndentValue = String(
                    EditorDOMUtils.convertUnitTo(levelData.special_indent_value),
                  );
                }
              }

              const mappedListStyleId = this.mappedListId[listId].listStyleId;
              if (mappedListStyleId) {
                element.setAttribute('cp_list_style', mappedListStyleId);
              }

              const mappedListId = this.mappedListId[listId].listId;
              if (mappedListId) {
                element.setAttribute('cp_list_id', mappedListId);
              }

              element.removeAttribute('cp_default_list_style');
            }
          }
        }
      }

      // handle default lists
      const listParagraphs = containerData.querySelectorAll(`*[cp_default_list_style]`);
      for (let l = 0; l < listParagraphs.length; l++) {
        const defaultListStyle = listParagraphs[l].getAttribute('cp_default_list_style');
        const oldListId = listParagraphs[l].getAttribute('cp_list_style');

        if (oldListId && defaultListStyle) {
          if (!this.mappedListId[oldListId]) {
            const newListId = await this.dataManager.numbering.createNewList(defaultListStyle);
            this.mappedListId[oldListId] = { listId: newListId };
          }

          listParagraphs[l].setAttribute('cp_list_style', defaultListStyle);
          const listId = this.mappedListId[oldListId].listId;

          if (listId) {
            listParagraphs[l].setAttribute('cp_list_id', listId);
          }

          listParagraphs[l].removeAttribute('cp_default_list_style');
        }
      }
    }
  }

  protected async matchDestinationStyles(containerData: HTMLElement) {
    if (containerData) {
      this.mappedListId = {};

      const hasTemplateHeaderRow = this.dataManager.templates.getHeaderRow();
      if (hasTemplateHeaderRow) {
        let tableBodies = containerData.querySelectorAll('tbody');

        for (let i = 0; i < tableBodies.length; i++) {
          let firstChild = tableBodies[i].firstChild;
          if (firstChild instanceof HTMLElement) {
            firstChild.dataset.hr = 'true';
          }
        }
      }

      // handle list styles
      if (this.listDefinitions) {
        const listIds = Object.keys(this.listDefinitions);
        for (let i = 0; i < listIds.length; i++) {
          const id = listIds[i];
          const listStyleId = await this.dataManager.styles.listStyles.createListStyle(
            Object.values(this.listDefinitions[id]),
          );
          this.mappedListId[id] = { listStyleId };
        }

        // handle paragraphs with list style id
        const ids = Object.keys(this.mappedListId);
        for (let i = 0; i < ids.length; i++) {
          const listId = ids[i];

          const elements = containerData.querySelectorAll(`*[cp_list_style="${listId}"]`);

          let newListId;
          const listStyleId = this.mappedListId[listId].listStyleId;

          if (elements.length > 0 && listStyleId) {
            newListId = await this.dataManager.numbering.createNewList(listStyleId);
            this.mappedListId[listId].listId = newListId;
          }

          for (let j = 0; j < elements.length; j++) {
            if (elements[j] && listStyleId && newListId) {
              elements[j].setAttribute('cp_list_style', listStyleId);
              elements[j].setAttribute('cp_list_id', newListId);

              elements[j].removeAttribute('cp_default_list_style');
            }
          }
        }
      }

      // handle default lists
      const listParagraphs = containerData.querySelectorAll(`*[cp_default_list_style]`);
      for (let l = 0; l < listParagraphs.length; l++) {
        const defaultListStyle = listParagraphs[l].getAttribute('cp_default_list_style');
        const oldListId = listParagraphs[l].getAttribute('cp_list_style');

        if (oldListId && defaultListStyle) {
          if (!this.mappedListId[oldListId]) {
            const newListId = await this.dataManager.numbering.createNewList(defaultListStyle);
            this.mappedListId[oldListId] = { listId: newListId, listStyleId: defaultListStyle };
          }

          if (this.mappedListId[oldListId].listStyleId === defaultListStyle) {
            const listId = this.mappedListId[oldListId].listId;
            listParagraphs[l].setAttribute('cp_list_style', defaultListStyle);
            if (listId) {
              listParagraphs[l].setAttribute('cp_list_id', listId);
            }

            listParagraphs[l].removeAttribute('cp_default_list_style');
          }
        }
      }

      let newStyle;

      // handle document styles
      if (this.newDocumentStyles) {
        const keys = Object.keys(this.newDocumentStyles);
        const length = keys.length;
        for (let i = 0; i < length; i++) {
          const styleName = keys[i];

          let existingStyle = this.stylesHandler.checkIfStyleNameExist(styleName);

          const elements = containerData.querySelectorAll(`*[data-temp-style-id="${styleName}"]`);

          if (!existingStyle) {
            newStyle = await this.stylesHandler.createNewDocumentStyleFromWord(
              this.newDocumentStyles[styleName],
            );

            const listStyleId = this.newDocumentStyles[styleName]?.p?.lst?.lStId;
            if (listStyleId) {
              const mappedListStyleId = this.mappedListId[listStyleId]?.listStyleId;

              if (
                mappedListStyleId &&
                this.dataManager.styles.listStyles.listStyleExists(mappedListStyleId)
              ) {
                const style = this.dataManager.styles.listStyles.style(mappedListStyleId);

                if (style) {
                  const styleParsedFront = style.parseFront();

                  const level: number | string | undefined = newStyle?.p?.lst?.lLv;

                  if (level && styleParsedFront[+level].paragraph_style) {
                    const paragraphStyle = styleParsedFront[+level].paragraph_style;
                    if (paragraphStyle && newStyle) {
                      paragraphStyle.value = newStyle.id;

                      await this.dataManager.styles.listStyles.updateListStyle(
                        mappedListStyleId,
                        styleParsedFront,
                      );
                    }
                  } else {
                    if (level && newStyle) {
                      styleParsedFront[+level].paragraph_style = {
                        value: newStyle.id,
                      };
                    }

                    await this.dataManager.styles.listStyles.updateListStyle(
                      mappedListStyleId,
                      styleParsedFront,
                    );
                  }
                }
              }
            }
          } else {
            // TODO: update existing styles?
          }

          for (let j = 0; j < elements.length; j++) {
            const element = elements[j];
            const styleId = existingStyle?.id ? existingStyle?.id : newStyle?.id;
            if (element instanceof HTMLElement && styleId) {
              element.removeAttribute('data-temp-style-id');
              element.dataset.styleId = styleId;
            }
          }
        }
      }
    }
  }

  public async handleContainerWithPasteOption(
    pasteOption: Editor.Clipboard.PasteOptions,
    containerData: HTMLElement,
  ) {
    switch (pasteOption) {
      case Parser.ORIGINAL_STYLES:
        await this.keepOriginalStyles(containerData);
        break;
      case Parser.MATCH_DESTINATION:
        await this.matchDestinationStyles(containerData);
        break;
      case Parser.PLAIN_TEXT:
        break;
      default:
        break;
    }
  }

  protected handleParagraph(node: HTMLElement) {
    const tagName = node.tagName as keyof NodeTagMapping;
    const parsedNode = DOMElementFactory.buildElement(HtmlParser.NODE_TAG_MAPPING[tagName].tag);
    parsedNode.setAttribute('element_type', ELEMENTS.ParagraphElement.ELEMENT_TYPE);
    const st = HtmlParser.NODE_TAG_MAPPING[tagName].st;
    parsedNode.dataset.styleId = st;

    // check previous list parsing
    const cpListLevel = node.getAttribute('cp_list_level');

    if (cpListLevel) {
      parsedNode.setAttribute('cp_list_level', cpListLevel);
    }

    const cpListStyle = node.getAttribute('cp_list_style');
    if (cpListStyle) {
      parsedNode.setAttribute('cp_list_style', cpListStyle);
    }

    const cpDefaultListStyle = node.getAttribute('cp_default_list_style');
    if (cpDefaultListStyle) {
      parsedNode.setAttribute('cp_default_list_style', cpDefaultListStyle);
    }

    return this.handleParagraphStyles(node, parsedNode);
  }

  protected handleParagraphStyles(node: HTMLElement, parsedNode: HTMLElement) {
    const computedStyle = window.getComputedStyle(node);

    /* Handle indentation */
    if (node.style.marginLeft) {
      const left = parseMeasurement(computedStyle.marginLeft, 'pt', { defaultUnit: 'px' });
      if (left != null) {
        parsedNode.dataset.leftIndentation = String(left);
      }
    }

    if (node.style.marginRight) {
      const right = parseMeasurement(computedStyle.marginRight, 'pt', { defaultUnit: 'px' });
      if (right != null) {
        parsedNode.dataset.rightIndentation = String(right);
      }
    }

    if (node.style.textIndent && parsedNode.dataset.specialIndentValue == null) {
      const specialValue = parseMeasurement(computedStyle.textIndent, 'pt', {
        defaultUnit: 'px',
      });
      if (specialValue != null) {
        let special;
        if (specialValue > 0) {
          special = 'f';
        } else if (specialValue < 0) {
          special = 'h';
        }
        parsedNode.dataset.specialIndent = special;
        parsedNode.dataset.specialIndentValue = String(Math.abs(specialValue));
      }
    }

    /* Handle spacing */
    if (node.style.marginTop) {
      const spaceBefore = parseMeasurement(computedStyle.marginTop, 'pt');
      if (spaceBefore != null) {
        parsedNode.dataset.spaceBefore = String(spaceBefore);
      }
    }

    if (node.style.marginBottom) {
      const spaceAfter = parseMeasurement(computedStyle.marginBottom, 'pt');
      if (spaceAfter != null) {
        parsedNode.dataset.spaceAfter = String(spaceAfter);
      }
    }

    if (node.style.lineHeight) {
      parsedNode.dataset.lineHeight = String(
        this.handleLineHeight(computedStyle.lineHeight, computedStyle.fontSize),
      );
    }

    /* Handle alignment */
    if (node.style.textAlign) {
      parsedNode.dataset.alignment = computedStyle.textAlign;
    }

    if (node.style.backgroundColor && computedStyle.backgroundColor !== 'rgba(0, 0, 0, 0)') {
      const hex = EditorDOMUtils.rgbToHex(computedStyle.backgroundColor);
      if (hex) {
        parsedNode.dataset.backgroundColor = hex.substring(1);
      }
    }

    let styles;
    if (node.nodeType === Node.TEXT_NODE) {
      const td = EditorDOMUtils.closest(node, ELEMENTS.TableCellElement.TAG);
      if (td && node.parentNode === td) {
        styles = this.parseStyles(td as HTMLElement);
      }
    } else {
      styles = this.parseStyles(node);
    }

    const formatElement = this.createFormatElement(
      styles as Editor.Clipboard.FormatStyleAttributes,
    );

    if (formatElement) {
      const formatElementId = this.getNodeId(formatElement);
      const nodeId = this.getNodeId(node);
      // Wrap format element around the paragraph content
      if (formatElementId && nodeId) {
        this.addToParentsList(formatElement, formatElementId, nodeId);
        return {
          parsedWorkNode: parsedNode,
          parentForChildNodes: formatElement,
        };
      }
    }

    return {
      parsedWorkNode: parsedNode,
      parentForChildNodes: node,
    };
  }

  protected handleLineHeight(lineHeight: string, fontSize: string): number | null {
    let value: number | null = null;
    if (lineHeight === 'normal') {
      value = 1;
    } else if (lineHeight.includes('%')) {
      const length = lineHeight.length - 1;
      const number = parseInt(lineHeight.substr(0, length), 10);
      value = number / 100;
      // eslint-disable-next-line
    } else if (!isNaN(parseFloat(lineHeight))) {
      value = +parseFloat(lineHeight).toFixed(2);
    } else {
      const parsed = parseMeasurement(lineHeight, 'px', { defaultUnit: 'px' });
      if (parsed && fontSize) {
        const parsedFontSize = parseMeasurement(fontSize, 'px');
        if (parsedFontSize) {
          value = parsed / parsedFontSize;
        }
      } else {
        value = 1;
      }
    }
    return value;
  }

  private async handleImage(node: ImageElement) {
    const image = DOMElementFactory.buildElement('image-element') as ImageElement;

    let width = 0;
    if (node.width) {
      width = node.width;
    } else if (node.style.width) {
      width = Number(node.style.width);
    }

    let height = 0;
    if (node.height) {
      height = node.height;
    } else if (node.style.height) {
      height = Number(node.style.height);
    }

    image.setImageDimensions(width, height);

    if (node.hasAttribute('localfile')) {
      image.setAttribute('localfile', 'true');
    } else {
      // TODO Maybe opt for optimistic approach and use local blob
      // while uploading goes off in the background
      const src = node.getAttribute('src');
      if (src) {
        await this.uploadImageFromSource(image, src);
      }
    }
    // let climber = node.parentNode;
    // while (
    //   climber &&
    //   climber.id !== 'container' &&
    //   climber.tagName !== ELEMENTS.TableCellElement.TAG
    // ) {
    //   climber = climber.parentNode;
    // }
    // this.addToParentsList(image, this.getNodeId(node), this.getNodeId(climber));
    return {
      parsedWorkNode: image,
    };
  }

  private handleInsertSuggestion(node: Node, parent: Node) {
    return {
      parentForChildNodes: parent,
    };
  }

  private async handleList(node: HTMLElement, parent: HTMLElement) {
    // TODO First version only, must be updated
    // This makes it that lists copied from anywhere will use the first list style available.
    const listStyle = node.tagName === 'OL' ? 'o1' : 'u1';
    // const listId = await this.dataManager.numbering.createNewList(listStyle);
    await this.handleListGroup(node, parent, 0, listStyle);

    return {};
  }

  private async handleListGroup(node: Node, parent: Node, listLevel: number, listStyle: string) {
    for (let i = 0, length = node.childNodes.length; i < length; i++) {
      const listChild = node.childNodes[i] as HTMLElement;
      if (listChild.tagName === 'OL' || listChild.tagName === 'UL') {
        this.handleListGroup(
          listChild,
          parent,
          listLevel + 1,
          listChild.tagName === 'OL' ? 'o1' : 'u1',
        );
      } else if (listChild.tagName === 'LI') {
        const child = listChild.childNodes[0] as HTMLElement;

        let elementToParse;
        if (child.tagName === 'P') {
          elementToParse = child;
        } else {
          elementToParse = document.createElement('P');
          while (listChild.firstChild) {
            elementToParse.appendChild(listChild.firstChild);
          }
        }

        // copy margins and paddings from list to paragraph
        elementToParse.style.marginLeft = listChild.style.marginLeft;
        elementToParse.style.marginRight = listChild.style.marginRight;
        elementToParse.style.marginTop = listChild.style.marginTop;
        elementToParse.style.marginBottom = listChild.style.marginBottom;

        elementToParse.style.paddingLeft = listChild.style.paddingLeft;
        elementToParse.style.paddingRight = listChild.style.paddingRight;
        elementToParse.style.paddingTop = listChild.style.paddingTop;
        elementToParse.style.paddingBottom = listChild.style.paddingBottom;

        elementToParse.setAttribute('cp_list_level', String(listLevel));
        elementToParse.setAttribute('cp_list_style', listStyle);
        elementToParse.setAttribute('cp_default_list_style', listStyle);

        await this.parseNodeTree(elementToParse, parent);
      } else {
        // Invalid node to parse as children of UL or OL
        listChild.remove();
      }
    }
  }

  private handleStrikethrough(node: Node) {
    const parsedWorkNode = this.createFormatElement({ strikethrough: true });
    return {
      parsedWorkNode,
      parentForChildNodes: node,
    };
  }

  protected handleSpan(node: HTMLElement, parent: HTMLElement) {
    let parsedNode = null;
    const brs = Array.from(node.querySelectorAll('br')) as HTMLElement[];
    const brStyle = brs.some(
      (br: HTMLElement) =>
        br.hasAttribute('style') && br.getAttribute('style')?.includes('page-break'),
    );
    if (brStyle) {
      const pageBreak = DOMElementFactory.buildElement('page-break-element');
      const pageBreakId = this.getNodeId(pageBreak);
      if (pageBreakId) {
        // add page break to root
        this.addToParentsList(pageBreak, pageBreakId, 'container');
        // climb this span tree to remove
        let climber = node;

        while (
          climber.parentNode &&
          climber.parentNode instanceof HTMLElement &&
          climber.parentNode.id !== 'container'
        ) {
          if (climber.childNodes.length === 1) {
            const climberId = this.getNodeId(climber);
            if (climberId) {
              this.nodesToRemove[climberId] = 1;
            }
          } else {
            break;
          }
          climber = climber.parentNode;
        }
        return null;
      }
    }
    if (
      this.ALLOWED_STYLES_FOR_ELEMENT.SPAN.some(
        (property: string) => node.style[property as keyof CSSStyleDeclaration],
      )
    ) {
      // If the span has any allowed style then create a format for it, otherwise ignore it and unwrap
      parsedNode = this.createFormatElement(this.parseStyles(node));
    }
    return {
      parsedWorkNode: parsedNode,
      parentForChildNodes: parsedNode ? node : parent,
    };
  }

  private handleSubscript(node: Node) {
    const parsedWorkNode = this.createFormatElement({ subscript: true });
    return {
      parsedWorkNode,
      parentForChildNodes: node,
    };
  }

  private handleSuperscript(node: Node) {
    const parsedWorkNode = this.createFormatElement({ superscript: true });
    return {
      parsedWorkNode,
      parentForChildNodes: node,
    };
  }

  protected shouldParseTableCell(td: HTMLElement) {
    return true;
  }

  protected async handleTable(table: TableElement, parsedTable: TableElement | null) {
    if (parsedTable == null) {
      parsedTable = DOMElementFactory.createNewTableElement();
    }

    const tableId = this.getNodeId(table);

    if (table.dataset.alignment) {
      parsedTable.dataset.alignment = table.dataset.alignment;
    }

    // Check if table has no table cells
    // I know.. but it has happened before ¯\_(ツ)_/¯
    const td = table.querySelector(ELEMENTS.TableCellElement.TAG);
    if (!td) {
      return null;
    }

    if (
      parsedTable &&
      !parsedTable.hasAttribute('cp_cell_borders') &&
      ((table.hasAttribute('border') && table.getAttribute('border') === '0') ||
        (table.hasAttribute('style') && table.style.border === 'none'))
    ) {
      const tableborders = JSON.parse(JSON.stringify(DEFAULT_TABLE_BORDERS));
      tableborders.t.w = 0;
      tableborders.b.w = 0;
      tableborders.l.w = 0;
      tableborders.r.w = 0;

      parsedTable.setAttribute('cp_cell_borders', JSON.stringify(tableborders));
    }

    // Handle tbody
    const tbody = DOMElementFactory.buildElement(ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG);
    const tbodyId = this.getNodeId(tbody);

    // Handle TR and TD
    const trs = table.rows;
    const rowSpans: {
      [index: number]: {
        [index: number]: {
          remaining: number;
          tempId: string;
          styles: {
            [index: string]: string;
          };
        };
      };
    } = {};
    for (let i = 0; i < trs.length; i++) {
      const tr = trs[i];
      const trId = this.getNodeId(tr);
      let height;

      const parsedTr = DOMElementFactory.buildElement(ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG);

      if (tr.parentNode && tr.parentNode.nodeName === 'THEAD') {
        parsedTr.dataset.hr = 'true';
      }

      const attibuteHeight = tr.getAttribute('height');
      if (attibuteHeight) {
        height = EditorDOMUtils.convertUnitTo(attibuteHeight, null, 'px', 3);
      } else if (tr.style.height != null) {
        height = EditorDOMUtils.convertUnitTo(tr.style.height, null, 'px', 3);
      }

      if (height != null) {
        parsedTr.style.height = `${height}px`;
      }

      if (trId && tbodyId) {
        this.addToParentsList(parsedTr, trId, tbodyId);
      }

      let tdIndex = 0;
      const tds = Array.from(tr.childNodes) as HTMLElement[];
      const filteredTds = tds.filter((td: HTMLElement) => td.tagName === 'TD');

      const length = filteredTds.length + Object.keys(rowSpans[i] || {}).length;
      for (let j = 0; j < length; j++) {
        const td = filteredTds[tdIndex] as TableCellElement;

        // Is there any pending row span in this cellIndex?
        // We add the pending hidden cells now so in the next step the current td is in the correct cellIndex
        if (rowSpans?.[i]?.[j] && rowSpans[i][j].remaining > 0) {
          // Decrement remaining row spawns
          rowSpans[i][j].remaining -= 1;
          // Add hidden cell here
          const hiddenCell = DOMElementFactory.createNewHiddenTableCell(rowSpans[i][j].tempId);
          const hiddenCellId = this.getNodeId(hiddenCell);

          if (hiddenCellId && trId) {
            this.addToParentsList(hiddenCell, hiddenCellId, trId);
          }

          hiddenCell.setTableCellStyles(rowSpans[i][j].styles);
        } else if (td && this.shouldParseTableCell(td)) {
          // Create our TD and add it to the TR
          const parsedTd = DOMElementFactory.createNewTableCellElement();
          const tdId = this.getNodeId(td);
          if (tdId && trId) {
            this.addToParentsList(parsedTd, tdId, trId);
          }

          this.fixTableCellInlineChildren(td);

          // Handle the cell styles
          const tableStyles = this.getTableCellStyles(td);

          parsedTd.setTableCellStyles(tableStyles);

          // Handle merged cells
          let rowspan = 1;
          let colspan = 1;
          if (td.hasAttribute('colspan')) {
            colspan = Number(td.getAttribute('colspan'));
          }
          if (td.hasAttribute('rowspan')) {
            rowspan = Number(td.getAttribute('rowspan'));
          }
          parsedTd.setAttribute('colspan', String(colspan));
          parsedTd.setAttribute('rowspan', String(rowspan));

          if (rowspan > 1 || colspan > 1) {
            const row = td.parentNode as HTMLTableRowElement;
            const tempId = `${row.sectionRowIndex}-${td.cellIndex}`;
            parsedTd.setAttribute('head-id-reference', tempId);

            if (rowspan > 1) {
              for (let c = 0; c < colspan; c++) {
                for (let row = 1; row < rowspan; row++) {
                  // Save row span for every col it spans for as well
                  if (!rowSpans[i + row]) {
                    rowSpans[i + row] = {};
                  }
                  if (!rowSpans?.[i + row]?.[j + c]) {
                    rowSpans[i + row][j + c] = {
                      remaining: rowspan - 1,
                      tempId,
                      styles: tableStyles,
                    };
                  }
                }
              }
            }

            if (colspan > 1) {
              for (let c = 1; c < colspan; c++) {
                const hiddenCell = DOMElementFactory.createNewHiddenTableCell(tempId);
                const hiddenCellId = this.getNodeId(hiddenCell);
                if (hiddenCellId && trId) {
                  this.addToParentsList(hiddenCell, hiddenCellId, trId);
                }

                hiddenCell.setTableCellStyles(tableStyles);
              }
            }
          }

          // handle cell width
          let widthToCheck;
          if (td.style.width != null && td.style.width !== '') {
            widthToCheck = td.style.width;
          } else if (td.width != null && td.width !== '') {
            widthToCheck = td.width.includes('%') ? td.width : `${td.width}px`;
          }

          let width;
          if (widthToCheck?.includes('%')) {
            width = +widthToCheck.slice(0, widthToCheck.indexOf('%')) / 100;
            parsedTd.dataset.width = `pct,${width}`;
          } else if (widthToCheck != null && widthToCheck !== 'auto') {
            width = parseMeasurement(widthToCheck, 'pt', {
              min: undefined,
              max: undefined,
            });
            parsedTd.dataset.width = `abs,${width}`;
          } else {
            parsedTd.dataset.width = `auto,0`;
          }

          const childPromises: Promise<void>[] = [];

          // Parse TD children
          td.childNodes.forEach((child) => {
            if (child.parentNode !== td) {
              return;
            }
            childPromises.push(this.parseNodeTree(child, td));
          });

          // eslint-disable-next-line no-await-in-loop
          await Promise.all(childPromises);

          // Increment cell index
          tdIndex += 1;
        }
      }
    }

    // handle table width
    let widthToCheck;
    if (table.style.width != null && table.style.width !== '') {
      widthToCheck = table.style.width;
    } else if (table.width != null && table.width !== '') {
      widthToCheck = table.width.includes('%') ? table.width : `${table.width}px`;
    }

    let width;
    if (widthToCheck?.includes('%')) {
      width = +widthToCheck.slice(0, widthToCheck.indexOf('%')) / 100;
      parsedTable.dataset.width = `pct,${width}`;
    } else if (widthToCheck != null && widthToCheck !== 'auto') {
      width = parseMeasurement(widthToCheck, 'pt', {
        min: undefined,
        max: undefined,
      });
      parsedTable.dataset.width = `abs,${width}`;
    } else {
      parsedTable.dataset.width = `auto,0`;
    }

    if (tbodyId && tableId) {
      this.addToParentsList(tbody, tbodyId, tableId);
    }

    // Check if table has a legit parent, and finds it if it does not
    let climber = table.parentNode as HTMLElement;

    while (
      climber &&
      climber.id !== 'container' &&
      climber.tagName !== ELEMENTS.TableCellElement.TAG
    ) {
      if (climber.parentNode instanceof HTMLElement) {
        climber = climber.parentNode;
      }
    }

    if (climber) {
      const climberId = this.getNodeId(climber);

      if (tableId && climberId) {
        this.addToParentsList(parsedTable, tableId, climberId);
      }
    }

    return {
      parentForChildNodes: table,
    };
  }

  private fixTableCellInlineChildren(td: HTMLTableCellElement) {
    let child = td.firstChild;
    while (child) {
      if (child.nodeType === 3 || EditorDOMElements.isInlineNode(child)) {
        // TextNode or Inline
        if (child.previousSibling && !EditorDOMElements.isInlineNode(child.previousSibling)) {
          child.previousSibling.appendChild(child);
        } else {
          const p = document.createElement('p');
          p.dataset.spaceBefore = '0';
          p.dataset.spaceAfter = '0';
          td.insertBefore(p, child);
          p.appendChild(child);
        }

        if (child.parentNode) {
          child = child.parentNode.nextSibling;
        }
      } else if (child.nodeType !== 1) {
        // Start disposing of trash
        const trash = child;
        child = child.nextSibling;
        trash.remove();
      } else {
        // child is block
        child = child.nextSibling;
      }
    }
  }

  protected getTableCellStyles(td: HTMLTableCellElement) {
    const parsedTableCellStyles: TableCellStyles = {};
    const computedStyles = window.getComputedStyle(td);
    parsedTableCellStyles.borderTopStyle = computedStyles.borderTopStyle;
    if (computedStyles.borderTopStyle !== 'none') {
      parsedTableCellStyles.borderTopWidth = computedStyles.borderTopWidth;
      parsedTableCellStyles.borderTopColor = computedStyles.borderTopColor;
    }
    parsedTableCellStyles.borderRightStyle = computedStyles.borderRightStyle;
    if (computedStyles.borderRightStyle !== 'none') {
      parsedTableCellStyles.borderRightWidth = computedStyles.borderRightWidth;
      parsedTableCellStyles.borderRightColor = computedStyles.borderRightColor;
    }
    parsedTableCellStyles.borderBottomStyle = computedStyles.borderBottomStyle;
    if (computedStyles.borderBottomStyle !== 'none') {
      parsedTableCellStyles.borderBottomWidth = computedStyles.borderBottomWidth;
      parsedTableCellStyles.borderBottomColor = computedStyles.borderBottomColor;
    }
    parsedTableCellStyles.borderLeftStyle = computedStyles.borderLeftStyle;
    if (computedStyles.borderLeftStyle !== 'none') {
      parsedTableCellStyles.borderLeftWidth = computedStyles.borderLeftWidth;
      parsedTableCellStyles.borderLeftColor = computedStyles.borderLeftColor;
    }

    // parsedTableCellStyles.border = computedStyles.border;
    parsedTableCellStyles.paddingTop = computedStyles.paddingTop;
    parsedTableCellStyles.paddingLeft = computedStyles.paddingLeft;
    parsedTableCellStyles.paddingRight = computedStyles.paddingRight;
    parsedTableCellStyles.paddingBottom = computedStyles.paddingBottom;
    parsedTableCellStyles.backgroundColor = computedStyles.backgroundColor;

    if (td.hasAttribute('valign') && td.getAttribute('valign')) {
      const valign = td.getAttribute('valign');
      if (valign) {
        parsedTableCellStyles.verticalAlignment = valign;
      }
    }

    return parsedTableCellStyles;
  }

  private handleUnderline(node: Node) {
    const parsedWorkNode = this.createFormatElement({ underline: true });
    return {
      parsedWorkNode,
      parentForChildNodes: node,
    };
  }

  protected parseStyles(node: HTMLElement) {
    // Might have to handle an invalid style object before
    // Maybe not
    const tagName = node.tagName as keyof AllowedStylesForElement;
    // Get the node computed style
    const computedStyle = window.getComputedStyle(node as Element);
    // Only go through if the node has allowed style properties
    if (this.ALLOWED_STYLES_FOR_ELEMENT[tagName]) {
      // For each of the allowed style properties, parse the computed value
      return this.ALLOWED_STYLES_FOR_ELEMENT[tagName].reduce((attributes, attribute) => {
        // But only parse if it has the inline style property and a respective parser
        if (
          (node.style[attribute as keyof CSSStyleDeclaration] || node.tagName === 'TD') &&
          this.styleParsers[attribute]
        ) {
          // Parse the value through an helper function
          const parsedStyle = this.styleParsers[attribute](
            computedStyle[attribute as keyof CSSStyleDeclaration],
          );
          if (parsedStyle) {
            attributes = { ...attributes, ...parsedStyle };
          }
        }
        return attributes;
      }, {});
    }
    return {};
  }

  private handleBackgroundColor(value: string) {
    if (value !== 'rgba(0, 0, 0, 0)') {
      return { highlightcolor: value };
    }
    return null;
  }

  private handleColor(value: string) {
    if (value !== 'rgba(0, 0, 0, 0)') {
      return { color: value };
    }
    return null;
  }

  private handleFontFamily(value: string) {
    const fonts = value.split(',').map((font) => font.trim().toLowerCase().replace(/"/g, ''));

    return { fontfamily: fonts[0] };
  }

  private handleFontSize(value: string) {
    return { fontsize: parseMeasurement(value, 'pt', { defaultUnit: 'px', toFixed: 0 }) };
  }

  private handleFontStyle(value: string) {
    return { italic: value === 'italic' };
  }

  private handleFontWeight(value: string) {
    return { bold: value === 'bold' || +value > 400 };
  }

  private handlePadding(value: string) {
    return `${parseMeasurement(value, 'pt')}pt`;
  }

  private handleTextDecoration(value: string) {
    return {
      underline: value.includes('underline'),
      strikethrough: value.includes('line-through'),
    };
  }
}
