import { FormattedMessage } from 'react-intl';
import SidebarContent from 'Editor/pages/EditorPage/SidebarComponent/SidebarContent';
import SidebarHeader from 'Editor/pages/EditorPage/SidebarComponent/SidebarHeader';
import SidebarPanel from 'Editor/pages/EditorPage/SidebarComponent/SidebarPanel';
import VersionsPanel from './VersionsPanel/VersionsPanel';

const VersionsTab = () => (
  <SidebarPanel testId="versions-panel">
    <SidebarHeader>
      <FormattedMessage id="VERSIONS_PANEL_TITLE" />
    </SidebarHeader>
    <SidebarContent>
      <VersionsPanel />
    </SidebarContent>
  </SidebarPanel>
);

export default VersionsTab;
