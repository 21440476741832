import { Mixin } from 'mixwith';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import { ELEMENTS } from 'Editor/services/consts';
import { notify } from '_common/components/ToastSystem';
import { Logger } from '_common/services';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class InsertBlockHandler extends superclass {
      // ##########################################################
      //               handle insert block element
      // ##########################################################

      /**
       * split content and insert new element
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       */
      _splitContentAndInsertNode(actionContext, newNode) {
        const { before } = this.splitSelectionContent(actionContext, [], true, (before, after) => {
          return (
            after != null &&
            (before === before.parentNode.lastChild || !EditorDOMUtils.isEmptyElement(after))
          );
        });

        if (before) {
          // insert new node
          return this._insertNewNodeAfter(actionContext, before.parentNode, newNode, before);
        }

        return null;
      }

      /**
       * insert new node after before elment and set caret
       * @param {ActionContext} actionContext
       * @param {Node} parentNode
       * @param {Node} newNode
       * @param {Node} beforeNode
       */
      _insertNewNodeAfter(actionContext, parentNode, newNode, beforeNode) {
        if (newNode && DOMNormalizer.isElementAllowedUnderPage(newNode.tagName)) {
          // copy task attribute
          if (
            beforeNode.nextSibling &&
            beforeNode.hasAttribute('task') &&
            beforeNode.getAttribute('task') === beforeNode.nextSibling.getAttribute('task')
          ) {
            newNode.setAttribute('task', beforeNode.getAttribute('task'));
          }

          let refNode = beforeNode;

          // validations for table elements
          if (newNode.tagName === ELEMENTS.TableElement.TAG) {
            if (beforeNode.tagName === ELEMENTS.TableElement.TAG) {
              const data = NodeDataBuilder.buildParagraph({
                data: {
                  parent_id: parentNode.id,
                },
              });

              this.inserBlockNodeOperation(actionContext, data, refNode, 'AFTER');
              refNode = DOMUtils.getBlockNode(data.id);
            } else if (
              beforeNode.nextSibling != null &&
              beforeNode.nextSibling.tagName === ELEMENTS.TableElement.TAG
            ) {
              const data = NodeDataBuilder.buildParagraph({
                data: {
                  parent_id: parentNode.id,
                },
              });
              this.inserBlockNodeOperation(actionContext, data, refNode, 'AFTER');
            }
          }

          // validations for container elements
          if (DOMUtils.isNodeAContainerElement(newNode) && beforeNode.nextSibling == null) {
            const data = NodeDataBuilder.buildParagraph({
              data: {
                parent_id: parentNode.id,
              },
            });
            this.inserBlockNodeOperation(actionContext, data, refNode, 'AFTER');
          }

          const closestContainer = DOMUtils.closest(
            refNode,
            DOMUtils.MULTI_BLOCK_CONTAINER_ELEMENTS,
          );
          if (closestContainer) {
            DOMUtils.insertNodeAfter(parentNode, newNode, refNode);
            actionContext.addChangeAddedNode(newNode);
          } else {
            const data = NodeDataBuilder.buildNodeData({
              data: {
                ...this.documentParser.parse(newNode),
                parent_id: parentNode.id,
              },
            });
            this.inserBlockNodeOperation(actionContext, data, refNode, 'AFTER');

            newNode = DOMUtils.getBlockNode(data.id);
          }

          // check if before is a list
          // let elementId = beforeNode.id;

          // if (DOMUtils.WRAPPER_LEVEL0_ELEMENTS.includes(beforeNode.tagName)) {
          //   elementId = beforeNode?.selectableContent?.id;
          // }

          // if (this.persistenceManager.isListElement(elementId)) {
          //   const listId = this.persistenceManager.getListIdFromBlock(elementId);
          //   const listLevel = this.persistenceManager.getListLevelFromBlock(elementId);
          //   this.persistenceManager.addBlocksToList(actionContext, [newNode.id], listId, listLevel, elementId);
          // }

          let caretPosition = 'INSIDE_END';
          let nodeToSetCaret = newNode;

          if (actionContext.caretPosition) {
            if (actionContext.caretPosition.nextSibling && newNode.nextSibling) {
              nodeToSetCaret = newNode.nextSibling;
            } else if (actionContext.caretPosition.previousSibling && newNode.previousSibling) {
              nodeToSetCaret = newNode.previousSibling;
            }
            caretPosition = actionContext.caretPosition.position;
          } else if (newNode.tagName === ELEMENTS.TableElement.TAG) {
            caretPosition = 'END';
          } else if (
            EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS.includes(newNode.lastChild.nodeName)
          ) {
            caretPosition = 'END';
          }
          this.selectionManager.setCaret(nodeToSetCaret, caretPosition);

          return nodeToSetCaret;
        }
      }

      _insertNewNodeBefore(actionContext, parentNode, newNode, afterNode) {
        if (DOMNormalizer.isElementAllowedUnderPage(newNode.tagName)) {
          // copy task attribute
          if (
            afterNode.previousSibling &&
            afterNode.hasAttribute('task') &&
            afterNode.getAttribute('task') === afterNode.previousSibling.getAttribute('task')
          ) {
            newNode.setAttribute('task', afterNode.getAttribute('task'));
          }

          let refNode = afterNode;

          // validations for table elements
          if (newNode.tagName === ELEMENTS.TableElement.TAG) {
            if (afterNode.tagName === ELEMENTS.TableElement.TAG) {
              const data = NodeDataBuilder.buildParagraph({
                data: {
                  parent_id: parentNode.id,
                },
              });

              this.inserBlockNodeOperation(actionContext, data, refNode, 'BEFORE');

              refNode = DOMUtils.getBlockNode(data.id);
            } else if (
              afterNode.previousSibling != null &&
              afterNode.previousSibling.tagName === ELEMENTS.TableElement.TAG
            ) {
              const data = NodeDataBuilder.buildParagraph({
                data: {
                  parent_id: parentNode.id,
                },
              });

              this.inserBlockNodeOperation(actionContext, data, refNode, 'BEFORE');
            }
          }

          const closestContainer = DOMUtils.closest(
            refNode,
            DOMUtils.MULTI_BLOCK_CONTAINER_ELEMENTS,
          );
          if (closestContainer) {
            DOMUtils.insertNodeBefore(parentNode, newNode, refNode);
            actionContext.addChangeAddedNode(newNode);
          } else {
            const data = NodeDataBuilder.buildNodeData({
              data: {
                ...this.documentParser.parse(newNode),
                parent_id: parentNode.id,
              },
            });
            this.inserBlockNodeOperation(actionContext, data, refNode, 'BEFORE');

            newNode = DOMUtils.getBlockNode(data.id);
          }

          let caretPosition = 'INSIDE_END';
          let nodeToSetCaret = newNode;

          if (actionContext.caretPosition) {
            if (actionContext.caretPosition.nextSibling && newNode.nextSibling) {
              nodeToSetCaret = newNode.nextSibling;
            } else if (actionContext.caretPosition.previousSibling && newNode.previousSibling) {
              nodeToSetCaret = newNode.previousSibling;
            }
            caretPosition = actionContext.caretPosition.position;
          } else if (newNode.tagName === ELEMENTS.TableElement.TAG) {
            caretPosition = 'END';
          }
          this.selectionManager.setCaret(nodeToSetCaret, caretPosition);

          return nodeToSetCaret;
        }
      }

      /**
       * handle insert in a table
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       * @param {Node} baseNode
       * @param {Node} anchorNode
       */
      _handleInsertBlockNodeOnTable(actionContext, newNode, baseNode, anchorNode) {
        // TODO: check for non-editable elements, page-break, etc
        const closest = DOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);
        if (closest) {
          if (!closest.hasAttribute('lock')) {
            const tdLevel0 = DOMUtils.findNodeLevel0(closest, anchorNode);
            if (tdLevel0) {
              if (tdLevel0 === baseNode) {
                return this._insertNewNodeAfter(
                  actionContext,
                  baseNode.parentNode,
                  newNode,
                  baseNode,
                );
              } else {
                return this.handleInsertBlockNodeOnCollapsedSelection(
                  actionContext,
                  newNode,
                  tdLevel0,
                  anchorNode,
                );
              }
            }
          }
        } else {
          this._insertNewNodeAfter(actionContext, baseNode.parentNode, newNode, baseNode);
        }
      }

      _handleInsertBlockOnContainerElement(actionContext, newNode, baseNode, anchorNode) {
        if (DOMUtils.BLOCK_CONTAINER_ELEMENTS.includes(anchorNode.tagName)) {
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
          }
        }

        if (
          anchorNode.tagName === ELEMENTS.TrackInsertElement.TAG ||
          anchorNode.tagName === ELEMENTS.TrackDeleteElement.TAG
        ) {
          this._insertNewNodeAfter(actionContext, baseNode.parentNode, newNode, baseNode);
        } else {
          const subLevel0Node = DOMUtils.findNodeLevel0(baseNode, anchorNode);
          if (subLevel0Node) {
            return this.handleInsertBlockNodeOnCollapsedSelection(
              actionContext,
              newNode,
              subLevel0Node,
              anchorNode,
            );
          }
        }
      }

      /**
       * handle insert node on collapse selection
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       * @param {Node} baseNode
       * @param {Node} anchorNode
       */
      handleInsertBlockNodeOnCollapsedSelection(actionContext, newNode, baseNode, anchorNode) {
        if (!baseNode || DOMUtils.isNodeAContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
          }
        }

        if (baseNode && this.isBlockNodeInsertionAllowed(newNode, baseNode, anchorNode)) {
          if (anchorNode) {
            if (DOMUtils.isNodeEditableTextElement(baseNode)) {
              // SELECTION IS A DEFAULT TEXT ELEMENT
              return this._splitContentAndInsertNode(actionContext, newNode);
            } else if (!DOMUtils.isBlockNodeEditable(baseNode)) {
              // SELECTION IS A NON-EDITABLE ELEMENT
              return this._insertNewNodeAfter(
                actionContext,
                baseNode.parentNode,
                newNode,
                baseNode,
              );
            } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
              // SELECTION IS A FIGURE
              return this._insertNewNodeAfter(
                actionContext,
                baseNode.parentNode,
                newNode,
                baseNode,
              );
            } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
              // SELECTION IS A TABLE
              return this._handleInsertBlockNodeOnTable(
                actionContext,
                newNode,
                baseNode,
                anchorNode,
              );
            } else if (DOMUtils.isNodeAContainerElement(baseNode)) {
              return this._handleInsertBlockOnContainerElement(
                actionContext,
                newNode,
                baseNode,
                anchorNode,
              );
            }
          }
        } else {
          Logger.warn(`Node "${newNode.tagName}" is not allowed!`);
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR.ERROR_INSERTING_ELEMENT',
          });
        }

        return null;
      }

      /**
       * handle insert node on multi selection
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       */
      handleInsertBlockNodeOnMultiSelection(actionContext, newNode, baseNode, anchorNode) {
        if (!baseNode || DOMUtils.isNodeAContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (this.selectionManager.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
          }
        }

        if (this.isBlockNodeInsertionAllowed(newNode, baseNode, anchorNode)) {
          if (this.joinSelectionContent(actionContext)) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = DOMUtils.findNodeLevel0(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
            return this.handleInsertBlockNodeOnCollapsedSelection(
              actionContext,
              newNode,
              baseNode,
              anchorNode,
            );
          }
        } else {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR.ERROR_INSERTING_ELEMENT',
          });
        }
      }
    },
);
