import { ReduxInterface, ExtensionHelper } from 'Editor/services';

const _testFontFamilyOnBrowser = (font: string) => {
  let result;
  if (!FontFamilyHelper.PROVIDED_FONTS.includes(font)) {
    const testContainer = document.createElement('div');
    testContainer.setAttribute('id', 'container-test-font');
    testContainer.style.position = 'fixed';
    testContainer.style.top = '100%';
    testContainer.style.visibility = 'collapse';
    document.body.appendChild(testContainer);

    const spanDefault = document.createElement('span');
    spanDefault.style.position = 'absolute';
    spanDefault.style.fontSize = '50px';
    spanDefault.style.width = 'auto';
    spanDefault.style.fontFamily = '"Inter"';
    spanDefault.style.whiteSpace = 'nowrap';
    spanDefault.appendChild(document.createTextNode('The quick brown fox jumps over the lazy dog'));

    testContainer.appendChild(spanDefault);

    const spanTest = spanDefault.cloneNode(true) as HTMLElement;
    spanTest.style.fontFamily = `"${font}", "Inter"`;

    testContainer.appendChild(spanTest);

    result = spanDefault.offsetWidth !== spanTest.offsetWidth;

    testContainer.remove();
  } else {
    result = true;
  }
  return result;
};

export class FontFamilyHelper {
  private _reduxTimeout: NodeJS.Timeout | null = null;

  private validatedFonts: string[] = [];

  private externalFonts: Editor.FontFamily.FontSupported[] = [];
  private missingFonts: Editor.FontFamily.FontSupported[] = [];
  private defaultFonts: Editor.FontFamily.FontSupported[] = [];
  private fontFamilyList: Editor.FontFamily.FontList[] = [];

  private fontFamilyListPromise: any;

  private canvas: HTMLCanvasElement;

  // fonts supported by the platform
  static FONTS = [
    { label: 'Arial', value: 'arial' },
    { label: 'Arial Black', value: 'arial black' },
    { label: 'Calibri', value: 'calibri' },
    { label: 'Comic Sans MS', value: 'comic sans ms' },
    { label: 'Courier New', value: 'courier new' },
    { label: 'Georgia', value: 'georgia' },
    { label: 'Impact', value: 'impact' },
    { label: 'Palatino', value: 'palatino' },
    { label: 'Tahoma', value: 'tahoma' },
    { label: 'Times New Roman', value: 'times new roman' },
    { label: 'Verdana', value: 'verdana' },
  ];

  static PROVIDED_FONTS = ['Inter', 'Open Sans', 'Calibri', 'Symbola'];

  constructor(platform: Platform) {
    this._validateFontFamily = this._validateFontFamily.bind(this);
    this.scheduleUpdateReduxData = this.scheduleUpdateReduxData.bind(this);
    this.isFontFamilySupportedByBrowser = this.isFontFamilySupportedByBrowser.bind(this);
    this.isFontFamilySupportedByPlatform = this.isFontFamilySupportedByPlatform.bind(this);
    this.validateFontFamily = this.validateFontFamily.bind(this);

    this.canvas = document.createElement('canvas');

    this.fontFamilyListPromise = ExtensionHelper.getFontFamilyList(platform)
      .then((data) => {
        this.fontFamilyListPromise.promiseStatus = 'resolved';
        this.fontFamilyList = Array.from(data.fonts);
        this.validateDefaultFonts();
      })
      .catch(() => {
        this.fontFamilyListPromise.promiseStatus = 'rejected';
        this.validateDefaultFonts();
        // console.warn(e);
      });
  }

  // eslint-disable-next-line
  destroy() {}

  isFontFamilySupportedByBrowser(font: string) {
    if (font) {
      let i = 0;
      const length = this.fontFamilyList.length;
      const f: string = font.toLowerCase();

      const browserTestResult = _testFontFamilyOnBrowser(font);

      if (length) {
        let fontObject;

        for (i = 0; i < length; i++) {
          if (f.includes(this.fontFamilyList[i].fontId.toLowerCase())) {
            fontObject = this.fontFamilyList[i];
            break;
          }
        }
        if (fontObject && browserTestResult) {
          return !!fontObject;
        }
      }
      return browserTestResult;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  isFontFamilySupportedByPlatform(font: string) {
    if (font) {
      const length = FontFamilyHelper.FONTS.length;
      const f = font.toLowerCase();

      if (
        FontFamilyHelper.PROVIDED_FONTS.includes(font) ||
        FontFamilyHelper.PROVIDED_FONTS.includes(f)
      ) {
        return true;
      }

      let i = 0;
      for (i = 0; i < length; i++) {
        if (f === FontFamilyHelper.FONTS[i].value) {
          return FontFamilyHelper.FONTS[i];
        }
      }
    }

    return false;
  }

  private scheduleUpdateReduxData() {
    if (this._reduxTimeout) {
      clearTimeout(this._reduxTimeout);
    }

    this._reduxTimeout = setTimeout(() => {
      // send data to redux
      ReduxInterface.setFontValidationData({
        external: JSON.parse(JSON.stringify(this.externalFonts)),
        missing: JSON.parse(JSON.stringify(this.missingFonts)),
        default: JSON.parse(JSON.stringify(this.defaultFonts)),
      });
    }, 50);
  }

  private _validateFontFamily(font: string) {
    if (font) {
      if (!this.validatedFonts.includes(font.toLowerCase())) {
        this.validatedFonts.push(font.toLowerCase());

        const isDefault = this.isFontFamilySupportedByPlatform(font);
        const isBrowserSuported = this.isFontFamilySupportedByBrowser(font);

        if (isDefault) {
          this.defaultFonts.push({
            label: font,
            value: font.toLowerCase(),
            supported: isBrowserSuported,
          });
        } else {
          this.externalFonts.push({
            label: font,
            value: font.toLowerCase(),
            supported: isBrowserSuported,
          });
        }

        if (!isBrowserSuported) {
          this.missingFonts.push({
            label: font,
            value: font.toLowerCase(),
            supported: isBrowserSuported,
          });
        }

        this.scheduleUpdateReduxData();
      }
    }
  }

  private validateDefaultFonts() {
    FontFamilyHelper.FONTS.forEach((fontObject) => {
      this.validateFontFamily(fontObject.label);
    });
  }

  public validateFontFamily(font: string) {
    if (font) {
      if (
        this.fontFamilyListPromise.promiseStatus !== 'resolved' &&
        this.fontFamilyListPromise.promiseStatus !== 'rejected'
      ) {
        this.fontFamilyListPromise
          .then(() => {
            this._validateFontFamily(font);
          })
          .catch(() => {
            this._validateFontFamily(font);
          });
      } else {
        this._validateFontFamily(font);
      }
    }
  }

  getTextMetrics(font: string, size: string, text: string = 'AbcdefghijklmnopqrstuvxyZ') {
    const ctx = this.canvas.getContext('2d');

    if (ctx) {
      ctx.font = `${size} ${font}`;
      const metrics = ctx.measureText(text);

      if (metrics) {
        if (metrics.fontBoundingBoxAscent == null) {
          //@ts-expect-error firefox shit
          metrics.fontBoundingBoxAscent = metrics.actualBoundingBoxAscent;
        }

        if (metrics.fontBoundingBoxDescent == null) {
          //@ts-expect-error firefox shit
          metrics.fontBoundingBoxDescent = metrics.actualBoundingBoxDescent;
        }
      }

      return metrics;
    }

    return null;
  }
}
