import { BaseTypedEmitter } from '_common/services/Realtime';
import { ELEMENTS } from 'Editor/services/consts';
import { Transport } from '_common/services/Realtime/Transport';
import { NodeModel, Cmapps } from '../../models';
import { NodeUtils } from '../../models/Node/NodeUtils';
import { CitationsController } from '../Citations/CitationsController';
import { ModelsController } from '../Models/ModelsController';
import { NumberingController } from '../Numbering/NumberingController';
import DocumentStylesManager from '../Styles/DocumentStylesManager';
import { TableOfContentsList } from './TableOfContentsList';
import { ProofingController } from '../Proofing/ProofingController';

const LEVEL_MAPPING: { [index: string]: number } = {
  [ELEMENTS.ParagraphElement.BASE_STYLES.MAIN_TITLE]: -1,
  [ELEMENTS.ParagraphElement.BASE_STYLES.HEADING1]: 0,
  [ELEMENTS.ParagraphElement.BASE_STYLES.HEADING2]: 1,
  [ELEMENTS.ParagraphElement.BASE_STYLES.HEADING3]: 2,
  [ELEMENTS.ParagraphElement.BASE_STYLES.HEADING4]: 3,
  [ELEMENTS.ParagraphElement.BASE_STYLES.HEADING5]: 4,
  [ELEMENTS.ParagraphElement.BASE_STYLES.HEADING6]: 5,
};

const DEFAULT_LABEL_ITEMS = ['Table', 'Figure', 'Equation'];

function getTargetParent(target: TableOfContents.TOCElementTypeFocusType<string>) {
  return target.split(':')[0];
}

export class TableOfContents extends BaseTypedEmitter<{
  UPDATED: (data: TableOfContents.TOCStructureDataType) => void;
}> {
  private transport: Transport;
  private loaded: boolean = false;
  private version:
    | ({
        version: ApiSchemas['VersionsSchema'];
        shouldRestart: boolean;
      } & TableOfContents.TOCStructureDataType)
    | null = null;
  private documentId: string;
  private timer: any;
  private tocList: TableOfContentsList;
  private stylesMap: TableOfContents.TOCStylesMapType = {};
  private cachedTOCElements: TableOfContents.TOCElementTypeByBlockType = {};
  private tocData: TableOfContents.TOCStructureDataType = {
    list: [],
    data: {},
  };
  private models: ModelsController;
  private cmapps: Cmapps;
  private documentStyles?: DocumentStylesManager;
  private citations?: CitationsController;
  private numbering?: NumberingController;
  private proofingData?: { [index: string]: Editor.Data.Proofing.Summary };
  private proofing?: ProofingController;

  constructor(transport: Transport, models: ModelsController, documentId: string) {
    super();
    this.transport = transport;
    this.documentId = documentId;
    this.models = models;
    this.handleTocListElementsInserted = this.handleTocListElementsInserted.bind(this);
    this.handleTocListElementsRemoved = this.handleTocListElementsRemoved.bind(this);
    this.handleTocListElementsChanged = this.handleTocListElementsChanged.bind(this);
    this.handleTocListElementsLoaded = this.handleTocListElementsLoaded.bind(this);
    this.handleDocumentStylesChange = this.handleDocumentStylesChange.bind(this);
    this.handleNumberingUpdate = this.handleNumberingUpdate.bind(this);
    this.handleBlockUpdated = this.handleBlockUpdated.bind(this);
    this.buildNavigation = this.buildNavigation.bind(this);
    this.tocList = new TableOfContentsList(transport, models);
    this.tocList.on('INSERTED', this.handleTocListElementsInserted);
    this.tocList.on('REMOVED', this.handleTocListElementsRemoved);
    this.tocList.on('LOADED', this.handleTocListElementsLoaded);
    this.tocList.on('CHANGED', this.handleTocListElementsChanged);
    this.cmapps = this.models.get('CMAPPS', `CMI${this.documentId}`);
    this.proofingData = {};
  }

  get list() {
    return this.version?.list || this.tocData.list;
  }

  get data() {
    return this.version?.data || this.tocData.data;
  }

  async load(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'GET:TOC',
        {},
        (response: Realtime.Transport.RealtimeResponse<TableOfContents.TOCStructureDataType>) => {
          if (response.success) {
            this.tocData.list = response.payload?.list || [];
            this.tocData.data = response.payload?.data || {};
            this.loaded = true;
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  async requestProofing() {
    this.proofingData = await this.proofing?.getCountOutlineWords();
    this.updateNavigableBlocks(Object.keys(this.proofingData || {}));
  }

  async start(documentId: string) {
    if (this.version) {
      this.version.shouldRestart = true;
      this.emit('UPDATED', {
        list: this.version.list,
        data: this.version.data,
      });
      return;
    }
    if (!this.tocList.ready) {
      this.buildStylesMap();
      this.tocList.start(documentId, this.stylesMap);
      this.requestProofing();
      this.documentStyles?.on('LOADED', this.handleDocumentStylesChange);
      this.numbering?.lists.on('LIST_UPDATE_ELEMENTS', this.handleNumberingUpdate);
    }
  }

  setVersionData(
    version: ApiSchemas['VersionsSchema'] | null,
    nav: TableOfContents.TOCStructureDataType,
  ) {
    if (version) {
      this.version = {
        version,
        list: nav?.list,
        data: nav?.data,
        shouldRestart: this.tocList.ready,
      };
      this.stop();
    } else {
      this.version = null;
    }
    this.emit('UPDATED', this.tocData);
  }

  stop() {
    if (this.version) {
      this.version.shouldRestart = false;
      return;
    }
    this.tocList.stop();
    this.documentStyles?.off('LOADED', this.handleDocumentStylesChange);
    this.numbering?.lists.off('LIST_UPDATE_ELEMENTS', this.handleNumberingUpdate);
    this.loaded = false;
  }

  bindToStyles(documentStyles?: DocumentStylesManager) {
    this.documentStyles = documentStyles;
    return this;
  }

  bindToCitations(citations?: CitationsController) {
    this.citations = citations;
    return this;
  }

  bindToNumbering(numbering?: NumberingController) {
    this.numbering = numbering;
    return this;
  }

  bindToProofing(proofing?: ProofingController) {
    this.proofing = proofing;
    return this;
  }

  private getElementLeveling(data: Editor.Data.Node.Data) {
    let elementStyle = data.properties?.s || data.st;
    if (data.type === 'redacted') {
      elementStyle = data.properties?.s || data.st || data.or_type;
    }
    if (elementStyle && this.stylesMap[elementStyle] !== undefined) {
      return this.stylesMap[elementStyle];
    }
    if (data.properties?.otl !== undefined && data.properties?.otl !== null) {
      return data.properties.otl;
    }
    return 9;
  }

  private handleNumberingUpdate(data: string[]) {
    let properBlocks = new Set<string>();
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const tocElement: TableOfContents.TOCElementType = this.tocData.data[element];
      if (tocElement) {
        properBlocks.add(getTargetParent(tocElement.target));
      }
    }
    if (properBlocks.size) {
      this.updateNavigableBlocks(Array.from(properBlocks));
    }
  }

  private handleDocumentStylesChange() {
    let oldStyles = Object.keys(this.stylesMap).sort();
    this.buildStylesMap();
    let newStyles = Object.keys(this.stylesMap).sort();
    if (
      oldStyles.length !== newStyles.length ||
      !oldStyles.every((value, index) => value === newStyles[index])
    ) {
      this.tocList.updateStyleMap(this.stylesMap);
    }
  }

  private handleTocListElementsInserted(blocks: NodeModel[]) {
    let block;
    for (let index = 0; index < blocks.length; index++) {
      block = blocks[index];
      block.on('LOADED', this.handleBlockUpdated);
      block.on('UPDATED', this.handleBlockUpdated);
    }
    this.updateNavigableBlocks(blocks.map((b) => b.id));
  }

  private handleTocListElementsRemoved(blocks: NodeModel[]) {
    let block;
    for (let index = 0; index < blocks.length; index++) {
      block = blocks[index];
      block.off('LOADED', this.handleBlockUpdated);
      block.off('UPDATED', this.handleBlockUpdated);
    }
  }

  private handleTocListElementsLoaded() {
    this.postponeBuildIfNeeded(true);
  }

  private handleTocListElementsChanged() {
    this.postponeBuildIfNeeded();
  }

  private handleBlockUpdated(data: Editor.Data.Node.Data | null) {
    if (data?.id) {
      this.updateNavigableBlocks([data.id]);
    }
  }

  private buildStylesMap() {
    this.stylesMap = this.documentStyles?.getStylesOnOutline() || {};
    if (Object.keys(this.stylesMap).length === 0) {
      this.stylesMap = JSON.parse(JSON.stringify(LEVEL_MAPPING));
      let baseStyle;
      let descendents: string[] = [];
      const baseStyles = Object.keys(LEVEL_MAPPING);
      for (let index = 0; index < baseStyles.length; index++) {
        baseStyle = baseStyles[index];
        descendents = this.documentStyles?.getDescendentStylesOfStyle(baseStyle) || [];
        for (let j = 0; j < descendents.length; j++) {
          this.stylesMap[descendents[j]] = LEVEL_MAPPING[baseStyle];
        }
      }
    }
  }

  private buildParagraphTOCItem(data: Editor.Data.Node.Data, parent?: Editor.Data.Node.Data) {
    let target: TableOfContents.TOCElementTypeFocusType = `${parent?.id || data.id}:${data.id}`;
    let elementStyle = data.properties?.s || data.st;
    let elementNumbering = this.numbering?.getListElementNumbering(data.id as string) || '';
    let content: string = `${elementNumbering ? elementNumbering + ' ' : ''}${NodeUtils.getContent(
      data,
    )}`;
    //! TEST
    if (!content.length) {
      content = ' ';
    }
    let item: TableOfContents.TOCElementType = {
      type: data.type,
      st: elementStyle || data.type,
      content,
      id: data.id || '',
      focus: data.id || null,
      target,
      index: null,
      childNodes: [],
      ref: data.properties?.element_reference || null,
      p_content: {
        ln: null,
        hn: elementNumbering,
        t: NodeUtils.getContent(data),
      },
      wc: this.getWordCountFromOutLine(data.id || ''),
      level: this.getElementLeveling(data),
    };
    return item;
  }

  private buildCitationTOCItem(data: Editor.Data.Node.Data, parent?: Editor.Data.Node.Data) {
    let target: TableOfContents.TOCElementTypeFocusType = `${parent?.id || data.id}:${data.id}`;
    let content: string | null = null;
    if (data.properties?.element_reference) {
      let citation = this.citations?.citation(data.properties?.element_reference);
      if (citation) {
        content = `${citation.serial} - ${citation.title}`;
      }
    }
    let item: TableOfContents.TOCElementType = {
      type: data.type,
      st: data.st || '',
      content,
      id: data.id || '',
      focus: data.id || null,
      target,
      label: data.type,
      index: null,
      childNodes: [],
      ref: data.properties?.element_reference || null,
      p_content: {
        ln: null,
        hn: null,
        t: null,
      },
      level: 9,
    };
    return item;
  }

  private buildCaptionFieldTOCItem(data: Editor.Data.Node.Data, parent?: Editor.Data.Node.Data) {
    let target: TableOfContents.TOCElementTypeFocusType = `${parent?.id || data.id}:${data.id}`;
    let item: TableOfContents.TOCElementType = {
      type: 'f',
      st: 'f',
      content: parent ? NodeUtils.getContent(parent, data.id) : null,
      id: data.id || '',
      focus: data.id || null,
      target,
      index: 0,
      childNodes: [],
      ref: null,
      label: data.properties?.cpt.toLowerCase(),
      p_content: {
        hn: null,
        ln: parent ? NodeUtils.getNodeContentsBeforeField(parent, data.id) : null,
        t: parent ? NodeUtils.getNodeContentsAfterField(parent, data.id) : null,
      },
      level: 9,
    };
    return item;
  }

  private buildRedactedTOCItem(
    data: Editor.Data.Node.Data,
    parent?: Editor.Data.Node.Data,
  ): TableOfContents.TOCElementType {
    let target: TableOfContents.TOCElementTypeFocusType = `${parent?.id || data.id}:${data.id}`;
    return {
      type: data.or_type,
      st: data.properties?.s || data.st || data.or_type,
      content: null,
      id: data.id || '',
      focus: data.id || '',
      target,
      index: 0,
      childNodes: [],
      ref: null,
      p_content: {
        ln: null,
        hn: null,
        t: null,
      },
      level: this.getElementLeveling(data),
    };
  }

  private isEligibleNode(data: Editor.Data.Node.Data) {
    let elementStyle = data.properties?.s || data.st;
    if (elementStyle && this.stylesMap[elementStyle] !== undefined) {
      return true;
    }
    if (data.properties?.otl !== undefined && data.properties?.otl !== null) {
      return true;
    }
    return false;
  }

  private buildNavigationItem(parent: Editor.Data.Node.Data, element: Editor.Data.Node.Data) {
    const items: TableOfContents.TOCElementType[] = [];
    if (element.properties?.v) {
      return items;
    }
    if (this.isEligibleNode(element)) {
      items.push(this.buildParagraphTOCItem(element, parent));
    }
    const queue = [element];
    let child;
    while ((child = queue.shift())) {
      if (child.type === 'citation') {
        items.push(this.buildCitationTOCItem(child, parent));
      }
      if (
        child.type === 'f' &&
        child.properties?.t === 'cpt' &&
        child.properties?.cpt &&
        DEFAULT_LABEL_ITEMS.includes(child.properties?.cpt)
      ) {
        items.push(this.buildCaptionFieldTOCItem(child, parent));
      }
      if (child.childNodes) {
        queue.unshift(...child.childNodes);
      }
    }
    return items;
  }

  // eslint-disable-next-line class-methods-use-this
  getTOCElements(node: NodeModel) {
    const items: TableOfContents.TOCElementType[] = [];
    const element = node.get([]);
    if (element?.type === 'redacted') {
      items.push(this.buildRedactedTOCItem(element));
    }
    //! ------------------------------- WARN -------------------------------
    //! build table of contents was breaking documents with really, really long tables ( don't know who builds these tables -.- )
    //! we need to find another way to have toc information inside tables if we want to show it, like having it pre-compiled in cmapps or similar
    //! I guess now we know why word does not show toc information inside tables
    //! --------------------------------------------------------------------
    // if (element.type === ELEMENTS.TableElement.ELEMENT_TYPE) {
    //   const cmapps = this.cmapps.hasNode(element.id);
    //   if (cmapps) {
    //     const order = cmapps.o;
    //     for (let index = 0; index < order.length; index++) {
    //       const _dataId = order[index];
    //       const _data = node.get(cmapps.d[_dataId]);
    //       items.push(...this.buildNavigationItem(element, _data));
    //     }
    //   }
    // } else
    if (element?.type === 'p') {
      items.push(...this.buildNavigationItem(element, element));
    }
    return items;
  }

  private updateNavigableBlocks(blockIds: string[]) {
    let blockModel: NodeModel;
    for (let index = 0; index < blockIds.length; index++) {
      blockModel = this.models.get('NODE', blockIds[index]);
      this.cachedTOCElements[blockIds[index]] = [...this.getTOCElements(blockModel)];
    }
    this.postponeBuildIfNeeded();
  }

  postponeBuildIfNeeded(forceReload: boolean = false) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (forceReload) {
      this.buildNavigation(forceReload);
    } else {
      this.timer = setTimeout(this.buildNavigation, 250, forceReload);
    }
  }

  private buildNavigation(forceReload: boolean = false) {
    this.timer = null;
    const navigablesList = this.tocList.list;
    const tempList: string[] = [];
    const tempData: TableOfContents.TOCElementDataById = {};
    let section = null;
    let lastOccurences: { [index: number]: TableOfContents.TOCElementType } = {};
    const tempNavObjects: TableOfContents.TOCElementTypeByBlockType = {};
    let workingNode: NodeModel;
    for (let index = 0, length = navigablesList.length; index < length; index++) {
      workingNode = navigablesList[index];
      tempNavObjects[workingNode.id] = [];
      if (!this.cachedTOCElements[workingNode.id] || forceReload) {
        this.cachedTOCElements[workingNode.id] = [...this.getTOCElements(workingNode)];
      }
      let items = [...this.cachedTOCElements[workingNode.id]];
      let level: number;
      for (let j = 0; j < items.length; j++) {
        const item = { ...items[j] };
        item.childNodes = [];
        level = item.level;
        if (level === -1) {
          tempList.push(item.id);
          section = null;
          lastOccurences = {};
        } else if (level === 0) {
          section = item;
          lastOccurences = {};
          lastOccurences[level] = item;
          tempList.push(item.id);
        } else if (level) {
          section = item;
          let foundPreviousOccurence = false;
          lastOccurences[level] = item;
          for (let levelIndex = level - 1; levelIndex >= 0; levelIndex--) {
            if (lastOccurences[levelIndex]) {
              lastOccurences[levelIndex].childNodes.push(item.id);
              foundPreviousOccurence = true;
              break;
            }
          }
          if (!foundPreviousOccurence) {
            tempList.push(item.id);
          }
        } else if (section) {
          section.childNodes.push(item.id);
        } else {
          tempList.push(item.id);
        }
        tempNavObjects[workingNode.id].push(item);
        tempData[item.id] = item;
      }
    }
    this.cachedTOCElements = tempNavObjects;
    this.tocData.list = tempList;
    this.tocData.data = tempData;
    this.loaded = true;
    this.emit('UPDATED', this.tocData);
  }

  private getWordCountFromOutLine(nodeId: string) {
    return this.proofingData?.[nodeId]?.WORDS;
  }

  async getDocumentMainTitle(force: boolean) {
    if (!this.loaded || force) {
      await this.load();
    }
    for (let index = 0; index < this.tocData.list.length; index++) {
      const nodeId = this.tocData.list[index];
      const data = this.tocData.data[nodeId];
      if (data && data.level === -1) {
        return data;
      }
    }
  }
}
