import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { Button, Divider } from 'dodoc-design-system';

import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { useDispatch } from '_common/hooks';
import { setVersionHistory } from 'PDF/redux/PDFGeneralSlice';

import Header from '../Header/Header';
import VersionCard from './VersionCard/VersionCard';

import styles from './VersionHistoryPanel.module.scss';
import usePDFData from 'PDF/hooks/usePDFData';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

const VersionHistoryPanel = () => {
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();
  const object = usePDFData();
  const isOwner =
    object?.user_permissions.includes('owner') || object?.user_permissions.includes('admin');

  const ref = useRef<HTMLDivElement>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);
  const { canAccessVersionHistory } = usePDFPermissions();

  const versions = usePDFData()?.lifecycle.versions;

  const sortedVersions = useMemo(() => {
    if (versions) {
      return [...versions].reverse();
    }
  }, [versions]);

  useEffect(() => {
    if (canAccessVersionHistory) {
      dispatch(setVersionHistory(true));
    }

    return () => {
      //Load current version and exit version history mode
      pdfManager.loadVersion(null);
      dispatch(setVersionHistory(false));
    };
  }, [canAccessVersionHistory]);

  useEffect(() => {
    if (ref.current) {
      setIsOverflowed(ref.current.clientHeight < ref.current.scrollHeight);
    }
  }, [versions]);

  const handleDownloadOriginalFile = () => {
    if (object) {
      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            title: 'DOWNLOAD_ORIGINAL_FILE',
            message: 'DOWNLOAD_ORIGINAL_DOPDF_FILE_CONFIRMATION',
            confirmButtonTextId: 'DOWNLOAD_ORIGINAL_FILE',
            confirmButtonType: 'primary',
            cancelButtonTextId: 'global.cancel',
            actionCode: 'downloadOriginalFile',
            actionValue: {
              objectId: object.id,
              filename: object.name,
              objectType: object.type,
            },
            headerType: 'information',
            cancelButtonShow: true,
          },
        }),
      );
    }
  };

  if (!canAccessVersionHistory) {
    return null;
  }

  return (
    <>
      <Header>
        <FormattedMessage id="VERSIONS_PANEL_TITLE" />
      </Header>
      {isOwner && (
        <div className={styles.downloadOriginal}>
          <Button
            size="small"
            fullWidth
            onClick={handleDownloadOriginalFile}
            testId="version-history-panel-download-original-file-button"
          >
            <FormattedMessage id="DOWNLOAD_ORIGINAL_FILE" />
          </Button>
        </div>
      )}
      <div className={cx({ [styles.root]: true, [styles.isOwner]: isOwner })} ref={ref}>
        <div className={styles.history} style={{ height: isOverflowed ? 'auto' : '100%' }}>
          <div>
            <Divider vertical margin="0 3rem 0 2rem" />
          </div>
          <div className={styles.list}>
            <VersionCard versions={versions} currentVersion />
            {sortedVersions?.map((version, i) => {
              return <VersionCard versions={versions} version={version} index={i} key={i} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default VersionHistoryPanel;
