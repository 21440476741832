import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Checkbox } from 'dodoc-design-system';
import { IntlProvider } from 'Intl/Intl';

import { store } from '_common/redux';
import { ReduxInterface } from 'Editor/services';
import EventsManager from 'Editor/services/EventsManager';
import EditorAvatar from 'Editor/components/EditorAvatar/EditorAvatar';
import { ELEMENTS } from 'Editor/services/consts';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';
import EditorManager from 'Editor/services/EditorManager';
import { BaseBlockElement } from '../../..';
import styles from './PermissionViewElement.module.scss';

export class PermissionViewElement extends BaseBlockElement {
  static SELECTION = {};
  static SELECTING = false;

  static deselectAllElements() {
    const elements = Array.prototype.slice.call(
      document.querySelectorAll(`${ELEMENTS.PermissionViewElement.TAG}[selected="true"]`),
    );

    if (elements) {
      for (let i = 0; i < elements.length; i += 1) {
        elements[i].selected = false;
        PermissionViewElement.SELECTION[elements[i].enclosed_element] = false;
      }
    }
  }

  static selectElements(startElement, endElement) {
    if (!startElement && !endElement) {
      return;
    }

    const elements = Array.from(startElement.parentNode.childNodes);
    let startIndex = elements.indexOf(startElement);
    let endIndex = elements.indexOf(endElement);

    if (endIndex < startIndex) {
      const aux = endIndex;
      endIndex = startIndex;
      startIndex = aux;
    }

    if (startIndex !== endIndex) {
      PermissionViewElement.SELECTING = true;
      for (let i = 0; i < elements.length; i += 1) {
        if (i < startIndex || i > endIndex) {
          if (elements[i].getAttribute('selected')) {
            // deselect
            elements[i].selected = false;
          }
        } else if (!elements[i].getAttribute('selected')) {
          // select
          elements[i].selected = true;
        }
      }
    }
  }

  static handleMouseDown() {
    PermissionViewElement.SELECTING = false;
    EventsManager.getInstance().on(
      'DOCUMENT_SELECTION_CHANGE',
      PermissionViewElement.handleSelectionChange,
    );
    EventsManager.getInstance().on('DOCUMENT_MOUSE_UP', PermissionViewElement.handleMouseUp);
  }

  static handleMouseUp(e) {
    let element = e.target;

    if (element.tagName !== ELEMENTS.PermissionViewElement.TAG) {
      element = DOMUtils.closest(element, ELEMENTS.PermissionViewElement.TAG);
    }

    if (!PermissionViewElement.SELECTING) {
      if (
        document
          .getElementById(`PermissionIconWrapper-${element.getAttribute('enclosed_element')}`)
          .contains(e.target)
      ) {
        element.toggleSelected();
      } else {
        PermissionViewElement.deselectAllElements();
        element.selected = true;
      }
    }

    // publish select bulk action
    const keys = Object.keys(PermissionViewElement.SELECTION);
    if (keys.length > 0) {
      const selectedElements = [];
      for (let i = 0; i < keys.length; i += 1) {
        if (PermissionViewElement.SELECTION[keys[i]]) {
          selectedElements.push(keys[i]);
        }
      }
      EditorManager.getInstance().selectNodesForPermissions(selectedElements);
    }

    PermissionViewElement.SELECTING = false;
    EventsManager.getInstance().removeListener(
      'DOCUMENT_SELECTION_CHANGE',
      PermissionViewElement.handleSelectionChange,
    );
    EventsManager.getInstance().removeListener(
      'DOCUMENT_MOUSE_UP',
      PermissionViewElement.handleMouseUp,
    );
  }

  static handleSelectionChange() {
    const selection = window.getSelection();
    const anchorNode = selection.anchorNode;
    const focusNode = selection.focusNode;
    const editorRoot = document.getElementById('EditorRoot');

    if (!editorRoot.contains(focusNode)) {
      return;
    }

    const startElement = DOMUtils.closest(anchorNode, ELEMENTS.PermissionViewElement.TAG);
    const endElement = DOMUtils.closest(focusNode, ELEMENTS.PermissionViewElement.TAG);

    PermissionViewElement.selectElements(startElement, endElement);
  }

  static get observedAttributes() {
    return ['selected'];
  }

  constructor() {
    super();

    this.nodeContent = null;
    this.usersContent = null;

    this.handleSelectedAttributeChange = this.handleSelectedAttributeChange.bind(this);
    this.setIcon = this.setIcon.bind(this);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('mousedown', PermissionViewElement.handleMouseDown);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.isConnected) {
      if (name === 'selected' && oldValue !== newValue) {
        this.handleSelectedAttributeChange(newValue);
      } else {
        super.attributeChangedCallback(name, oldValue, newValue);
      }
    }
  }

  handleSelectedAttributeChange(newValue) {
    if (newValue) {
      this.setIcon('CHECKED');
      this.setSelectedState();
      PermissionViewElement.SELECTION[this.getAttribute('enclosed_element')] = true;
    } else {
      this.setIcon('UNCHECKED');
      this.setDefaultState();
      PermissionViewElement.SELECTION[this.getAttribute('enclosed_element')] = false;
    }
  }

  get selected() {
    return this.hasAttribute('selected');
  }

  set selected(val) {
    if (val) {
      this.setAttribute('selected', true);
    } else {
      this.removeAttribute('selected');
    }
  }

  deselect() {
    this.removeAttribute('selected');
  }

  setSelectedState() {
    this.content.style.backgroundColor = '#e8f4fe';
    this.users.style.backgroundColor = '#e8f4fe';
  }

  setDefaultState() {
    this.content.style.backgroundColor = 'transparent';
    this.users.style.backgroundColor = 'transparent';
  }

  toggleSelected() {
    this.selected = !this.selected;
  }

  setIcon(status) {
    ReactDOM.unmountComponentAtNode(this.iconWrapper);
    ReactDOM.render(
      status === 'CHECKED' ? (
        <Checkbox checked="checked" size="small" testId="checkbox-column" />
      ) : (
        <Checkbox checked="unchecked" size="small" testId="checkbox-column" />
      ),
      this.iconWrapper,
    );
  }

  setContent(child) {
    this.nodeContent = child;
  }

  setUsers(users, publicProfiles) {
    this.usersContent = {
      users,
      publicProfiles,
    };
  }

  addUserElement(user) {
    const userElement = DOMElementFactory.buildElement('span');
    userElement.setAttribute('class', styles.userElement);
    ReactDOM.render(
      <Provider store={store}>
        <IntlProvider locale={ReduxInterface.getLocale()}>
          <EditorAvatar size="small" userId={user} showTooltip />
        </IntlProvider>
      </Provider>,
      userElement,
    );
    this.users.appendChild(userElement);
  }

  connectedCallback() {
    super.connectedCallback();

    // clean childreen
    while (this.firstChild) {
      this.removeChild(this.firstChild);
    }

    this.addEventListener('mousedown', PermissionViewElement.handleMouseDown);

    this.container = DOMElementFactory.buildElement('div');
    this.container.setAttribute('class', styles.container);
    this.iconWrapper = DOMElementFactory.buildElement('div');
    this.iconWrapper.id = `PermissionIconWrapper-${this.getAttribute('enclosed_element')}`;
    this.iconWrapper.setAttribute('class', styles.iconWrapper);

    this.contentWrapper = DOMElementFactory.buildElement('div');
    this.contentWrapper.setAttribute('class', styles.contentWrapper);

    this.content = DOMElementFactory.buildElement('span');
    this.content.setAttribute('class', styles.content);
    this.users = DOMElementFactory.buildElement('span');
    this.users.setAttribute('class', styles.users);

    this.appendChild(this.container);
    this.container.appendChild(this.iconWrapper);
    this.container.appendChild(this.contentWrapper);
    this.contentWrapper.appendChild(this.content);
    this.contentWrapper.appendChild(this.users);

    if (this.nodeContent) {
      this.content.appendChild(this.nodeContent);
    }
    if (this.usersContent) {
      this.usersContent.users.forEach((user) => {
        this.addUserElement(user, this.usersContent.publicProfiles);
      });
    }
    if (this.selected) {
      this.setIcon('CHECKED');
      this.content.style.backgroundColor = '#e8f4fe';
      this.users.style.backgroundColor = '#e8f4fe';
    } else {
      this.setIcon('UNCHECKED');
      this.content.style.backgroundColor = 'transparent';
    }
  }
}

if (!window.customElements.get(ELEMENTS.PermissionViewElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.PermissionViewElement.IDENTIFIER, PermissionViewElement);
}
