import { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Toggle, usePopper } from 'dodoc-design-system';

import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import styles from './Header.module.scss';

type HeaderProps = {
  versions?: FileObject['lifecycle']['versions'];
  index?: number;
  currentVersion: boolean;
};

const Header = ({ versions, index, currentVersion }: HeaderProps) => {
  const dispatch = useDispatch();
  const optionsPopper = usePopper({ placement: 'bottom-start', closeOnReferenceHidden: true });

  const versionNumber = `V.${(versions?.length || 0) - (index ?? -1)}`;

  const { canRestoreVersion } = usePDFPermissions();

  const handleRestoreVersion = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!currentVersion && index != null && versions) {
      const ind = versions.length - index - 1;
      dispatch(
        openAndUpdateModal({
          modal: 'PDFConfirmationModal',
          data: {
            title: 'RESTORE_DOCUMENT_VERSION',
            message: 'ARE_YOU_SURE_YOU_WANT_TO_RESTORE_THIS_PDF_DOCUMENT_VERSION',
            messageValues: { versionNumber: ind + 1 },
            confirmButtonTextId: 'RESTORE_VERSION',
            confirmButtonType: 'primary',
            cancelButtonTextId: 'global.cancel',
            actionCode: 'restorePDFDocumentVersion',
            actionValue: { loadedVersion: ind },
            headerType: 'information',
            cancelButtonShow: true,
            width: '60rem',
          },
        }),
      );
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {currentVersion ? (
          <>
            <FormattedMessage id="CURRENT_VERSION" />
            {` (${versionNumber})`}
          </>
        ) : (
          versionNumber
        )}
      </div>

      {!currentVersion && (
        <div className={styles.actions}>
          <Toggle
            {...optionsPopper.referenceProps}
            onClick={(e) => {
              e.stopPropagation();
              optionsPopper.referenceProps.onClickCapture();
            }}
            variant="link"
            size="medium"
            icon={optionsPopper.isOpen ? 'PDFOptionsBlue' : 'PDFOptionsGrey'}
            isToggled={optionsPopper.isOpen}
            testId={`version-${index}-options-toggle`}
          />
          <Dropdown
            {...optionsPopper.popperProps}
            width="30rem"
            testId="annotation-options-dropdown"
          >
            <Dropdown.Item
              testId={`version-${index}-options-restore-item`}
              onClick={handleRestoreVersion}
              disabled={!canRestoreVersion}
            >
              <FormattedMessage id="RESTORE_VERSION" />
            </Dropdown.Item>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default Header;
