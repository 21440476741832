import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import EditableTaskCardContent, { EditableTaskCardProps } from './EditableTaskCardContent';

const EditableTaskCard = (props: EditableTaskCardProps) => {
  return (
    <IntlErrorBoundary fallbackType="card" mockProps={{ sidebar: !!props.sidebar }}>
      <EditableTaskCardContent {...props} />
    </IntlErrorBoundary>
  );
};

export default EditableTaskCard;
