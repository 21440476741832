import { ElementNodeBuilder } from 'Editor/services/Model';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';

export abstract class BaseViewBuilder {
  protected Data: Editor.Data.API;
  protected Visualizer: Editor.Visualizer.State;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State) {
    this.Data = Data;
    this.Visualizer = Visualizer;
  }

  static alignmentMapper = {
    parse: (alignment: string) => {
      switch (alignment) {
        case 'l':
        case 'left':
          return 'l';
        case 'r':
        case 'right':
          return 'r';
        case 'c':
        case 'center':
          return 'c';
        case 'j':
        case 'justify':
          return 'j';
        default:
          return 'l';
      }
    },
    render: (a: string) => {
      switch (a) {
        case 'l':
        case 'left':
          return 'left';
        case 'r':
        case 'right':
          return 'right';
        case 'c':
        case 'center':
          return 'center';
        case 'j':
        case 'justify':
          return 'justify';
        default:
          return 'left';
      }
    },
  };

  static verticalAlignmentMapper = {
    parse: (va: string) => {
      switch (va) {
        case 't':
        case 'top':
          return 't';
        case 'b':
        case 'bottom':
          return 'b';
        case 'c':
        case 'center':
        default:
          return 'c';
      }
    },
    render: (va: string) => {
      switch (va) {
        case 't':
        case 'top':
          return 'top';
        case 'b':
        case 'bottom':
          return 'bottom';
        case 'c':
        case 'center':
          return 'center';
        default:
          return '';
      }
    },
  };

  static borderStyleMapper = {
    parse: (bs: string) => {
      switch (bs) {
        case 'd':
        case 'double':
          return 'd';
        case 'das':
        case 'dashed':
          return 'das';
        case 'dot':
        case 'dotted':
          return 'dot';
        case 's':
        case 'solid':
          return 's';
        case 'n':
        case 'none':
          return 'n';
        default:
          return 's';
      }
    },
    render: (bs: string) => {
      switch (bs) {
        case 'd':
        case 'double':
          return 'double';
        case 'das':
        case 'dashed':
          return 'dashed';
        case 'dot':
          return 'dotted';
        case 's':
        case 'solid':
          return 'solid';
        case 'n':
        case 'none':
          return 'none';
        default:
          return '';
      }
    },
  };

  static colorMapper = {
    parse: (color: string | undefined) => {
      if (color != null) {
        if (color === 'rgba(0, 0, 0, 0)' || color === 'transparent' || color === 'false') {
          return 'false';
        } else if (color.includes('rgb')) {
          const hex = EditorDOMUtils.rgbToHex(color);
          return hex?.substring(1, color.length).toUpperCase?.();
        } else if (color.includes('#')) {
          return color.substring(1, color.length).toUpperCase?.();
        } else if (color.length === 6) {
          return color;
        }
      }

      return undefined;
    },
    render: (color: string | undefined) => {
      if (color != null) {
        if (color === 'false' || color === 'transparent') {
          return 'transparent';
        } else if (color.includes('rgb') || color.includes('#')) {
          return color;
        } else if (color.length === 6) {
          return `#${color}`;
        }
      }

      return undefined;
    },
  };

  protected GENERIC_ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE = {
    // id
    id: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('id')) {
          builder.addKeyValue('id', node.getAttribute('id'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.id != null) {
          node.setAttribute('id', json.id);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('id');
      },
    },
    // parent id
    parent_id: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('parent_id')) {
          builder.addKeyValue('parent_id', node.getAttribute('parent_id'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.parent_id != null) {
          node.setAttribute('parent_id', json.parent_id);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('parent_id');
      },
    },
    // element_type
    element_type: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('element_type')) {
          builder.addKeyValue('type', node.getAttribute('element_type'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.type != null) {
          node.setAttribute('element_type', json.type);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('element_type');
      },
    },
    // element reference
    element_reference: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('element_reference')) {
          builder.addProperty('element_reference', node.getAttribute('element_reference'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.element_reference != null) {
          node.setAttribute('element_reference', json.properties.element_reference);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('element_reference');
      },
    },
    // width
    w: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.style.width) {
          builder.addProperty('w', DOMUtils.convertUnitTo(node.style.width, null, 'pt', 3));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.w != null) {
          node.style.width = `${DOMUtils.convertUnitTo(json.properties.w, 'pt', 'px', 3)}px`;
        }
      },
      remove: (node: HTMLElement) => {
        node.style.removeProperty('width');
      },
    },
    // heigth
    h: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.style.height) {
          builder.addProperty('h', DOMUtils.convertUnitTo(node.style.height, null, 'pt', 3));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.h != null) {
          node.style.height = `${DOMUtils.convertUnitTo(json.properties.h, 'pt', 'px', 3)}px`;
        }
      },
      remove: (node: HTMLElement) => {
        node.style.removeProperty('height');
      },
    },
    // alignment
    a: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.alignment != null) {
          builder.addProperty('a', BaseViewBuilder.alignmentMapper.parse(node.dataset.alignment));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.a != null) {
          node.dataset.alignment = BaseViewBuilder.alignmentMapper.render(json.properties.a);
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.alignment;
      },
    },
    // line heigth
    lh: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.lineHeight != null) {
          builder.addProperty('lh', node.dataset.lineHeight);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.lh != null) {
          node.dataset.lineHeight = `${json.properties.lh}`;
          // node.style.lineHeight = `${json.properties.lh}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.lineHeight;
        node.style.removeProperty('lineHeight');
      },
    },
    // space before
    sb: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.spaceBefore != null) {
          builder.addProperty('sb', node.dataset.spaceBefore);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.sb != null) {
          node.style.marginTop = `${json.properties.sb}pt`;
          node.dataset.spaceBefore = `${json.properties.sb}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.spaceBefore;
        node.style.removeProperty('marginTop');
      },
    },
    // space after
    sa: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.spaceAfter != null) {
          builder.addProperty('sa', node.dataset.spaceAfter);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.sa != null) {
          node.style.marginBottom = `${json.properties.sa}pt`;
          node.dataset.spaceAfter = `${json.properties.sa}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.spaceAfter;
        node.style.removeProperty('marginBottom');
      },
    },
    // background from style
    bg: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder, attributeName?: string | null) => {
        if (attributeName == null) {
          attributeName = 'bg';
        }

        const bg: any = node.style.backgroundColor;
        if (bg != null) {
          if (bg === 'rgba(0, 0, 0, 0)' || bg === 'transparent' || bg === false || bg === 'false') {
            builder.addProperty(attributeName, false);
          } else if (bg.includes('rgb')) {
            const hex = DOMUtils.rgbToHex(bg);
            builder.addProperty(attributeName, hex?.substring(1).toUpperCase?.());
          } else if (bg.includes('#')) {
            builder.addProperty(attributeName, bg.substring(1).toUpperCase?.());
          }
        }
      },
      render: (
        json: Editor.Data.Node.Data,
        node: HTMLElement,
        baseModelData?: Editor.Data.Node.Data,
        attributeName?: string | null,
      ) => {
        if (attributeName == null) {
          attributeName = 'bg';
        }

        if (json.properties?.[attributeName] != null) {
          let bg = json.properties[attributeName];
          if (bg === false || bg === 'false') {
            bg = 'rgba(0,0,0,0)';
            node.style.backgroundColor = bg;
          } else if (bg.includes('rgb') || bg.includes('#')) {
            node.style.backgroundColor = bg;
          } else {
            bg = `#${bg}`;
            node.style.backgroundColor = bg;
          }

          return bg;
        }
        return '';
      },
      remove: (node: HTMLElement) => {
        node.style.removeProperty('backgroundColor');
      },
    },
    // padding
    p: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder, attributeName?: string | null) => {
        if (attributeName == null) {
          attributeName = 'p';
        }

        const p: any = {};
        if (node.style.paddingTop) {
          p.t = DOMUtils.convertUnitTo(node.style.paddingTop, null, 'pt', 3);
        }
        if (node.style.paddingBottom) {
          p.b = DOMUtils.convertUnitTo(node.style.paddingBottom, null, 'pt', 3);
        }
        if (node.style.paddingLeft) {
          p.l = DOMUtils.convertUnitTo(node.style.paddingLeft, null, 'pt', 3);
        }
        if (node.style.paddingRight) {
          p.r = DOMUtils.convertUnitTo(node.style.paddingRight, null, 'pt', 3);
        }
        if (Object.keys(p).length > 0) {
          builder.addProperty(attributeName, p);
        }
      },
      render: (
        json: Editor.Data.Node.Data,
        node: HTMLElement,
        baseModelData?: Editor.Data.Node.Data,
        attributeName?: string | null,
      ) => {
        if (attributeName == null) {
          attributeName = 'p';
        }

        if (json.properties?.[attributeName] != null) {
          const p = json.properties[attributeName];
          if (p.t != null) {
            node.style.paddingTop = `${DOMUtils.convertUnitTo(p.t, 'pt', 'px', 3)}px`;
          }
          if (p.b != null) {
            node.style.paddingBottom = `${DOMUtils.convertUnitTo(p.b, 'pt', 'px', 3)}px`;
          }
          if (p.l != null) {
            node.style.paddingLeft = `${DOMUtils.convertUnitTo(p.l, 'pt', 'px', 3)}px`;
          }
          if (p.r != null) {
            node.style.paddingRight = `${DOMUtils.convertUnitTo(p.r, 'pt', 'px', 3)}px`;
          }
        }
      },
      remove: (node: HTMLElement) => {
        node.style.removeProperty('padding');
        node.style.removeProperty('paddingTop');
        node.style.removeProperty('paddingBottom');
        node.style.removeProperty('paddingLeft');
        node.style.removeProperty('paddingRight');
      },
    },
    // border
    b: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder, attributeName?: string | null) => {
        if (attributeName == null) {
          attributeName = 'b';
        }

        const b: any = {};
        // border top
        const bt: any = {};
        if (node.style.borderTopWidth) {
          const width = DOMUtils.convertUnitTo(node.style.borderTopWidth, null, 'pt', 3);
          if (width != null) {
            bt.w = width;
          }
        }
        if (node.style.borderTopStyle) {
          bt.s = BaseViewBuilder.borderStyleMapper.parse(node.style.borderTopStyle);
        }
        if (node.style.borderTopColor) {
          const color = node.style.borderTopColor;
          if (color.includes('rgb')) {
            const hex = DOMUtils.rgbToHex(color);
            bt.c = hex?.substring(1, color.length).toUpperCase?.();
          } else if (color.includes('#')) {
            bt.c = color.substring(1, color.length).toUpperCase?.();
          }
        }
        if (Object.keys(bt).length > 0) {
          b.t = bt;
        }
        // border bottom
        const bb: any = {};
        if (node.style.borderBottomWidth) {
          const width = DOMUtils.convertUnitTo(node.style.borderBottomWidth, null, 'pt', 3);
          if (width != null) {
            bb.w = width;
          }
        }
        if (node.style.borderBottomStyle) {
          bb.s = BaseViewBuilder.borderStyleMapper.parse(node.style.borderBottomStyle);
        }
        if (node.style.borderBottomColor) {
          const color = node.style.borderBottomColor;
          if (color.includes('rgb')) {
            const hex = DOMUtils.rgbToHex(color);
            bb.c = hex?.substring(1, color.length).toUpperCase?.();
          } else if (color.includes('#')) {
            bb.c = color.substring(1, color.length).toUpperCase?.();
          }
        }
        if (Object.keys(bb).length > 0) {
          b.b = bb;
        }
        // border left
        const bl: any = {};
        if (node.style.borderLeftWidth) {
          const width = DOMUtils.convertUnitTo(node.style.borderLeftWidth, null, 'pt', 3);
          if (width != null) {
            bl.w = width;
          }
        }
        if (node.style.borderLeftStyle) {
          bl.s = BaseViewBuilder.borderStyleMapper.parse(node.style.borderLeftStyle);
        }
        if (node.style.borderLeftColor) {
          const color = node.style.borderLeftColor;
          if (color.includes('rgb')) {
            const hex = DOMUtils.rgbToHex(color);
            bl.c = hex?.substring(1, color.length).toUpperCase?.();
          } else if (color.includes('#')) {
            bl.c = color.substring(1, color.length).toUpperCase?.();
          }
        }
        if (Object.keys(bl).length > 0) {
          b.l = bl;
        }
        // border right
        const br: any = {};
        if (node.style.borderRightWidth) {
          const width = DOMUtils.convertUnitTo(node.style.borderRightWidth, null, 'pt', 3);
          if (width != null) {
            br.w = width;
          }
        }
        if (node.style.borderRightStyle) {
          br.s = BaseViewBuilder.borderStyleMapper.parse(node.style.borderRightStyle);
        }
        if (node.style.borderRightColor) {
          const color = node.style.borderRightColor;
          if (color.includes('rgb')) {
            const hex = DOMUtils.rgbToHex(color);
            br.c = hex?.substring(1, color.length).toUpperCase?.();
          } else if (color.includes('#')) {
            br.c = color.substring(1, color.length).toUpperCase?.();
          }
        }
        if (Object.keys(br).length > 0) {
          b.r = br;
        }

        if (Object.keys(b).length > 0) {
          builder.addProperty(attributeName, b);
        }
      },
      render: (
        json: Editor.Data.Node.Data,
        node: HTMLElement,
        baseModelData?: Editor.Data.Node.Data,
        attributeName?: string | null,
      ) => {
        if (attributeName == null) {
          attributeName = 'b';
        }

        if (json.properties?.[attributeName] != null) {
          const b = json.properties[attributeName];
          // border top
          if (b.t != null) {
            if (b.t.w != null) {
              node.style.borderTopWidth = `${DOMUtils.convertUnitTo(b.t.w, 'pt', 'px', 3)}px`;
            }
            if (b.t.s != null) {
              node.style.borderTopStyle = BaseViewBuilder.borderStyleMapper.render(b.t.s);
            }
            if (b.t.c != null) {
              const color = b.t.c;
              if (color.includes('#')) {
                node.style.borderTopColor = color;
              } else {
                node.style.borderTopColor = `#${color}`;
              }
            }
          }
          // border bottom
          if (b.b != null) {
            if (b.b.w != null) {
              node.style.borderBottomWidth = `${DOMUtils.convertUnitTo(b.b.w, 'pt', 'px', 3)}px`;
            }
            if (b.b.s != null) {
              node.style.borderBottomStyle = BaseViewBuilder.borderStyleMapper.render(b.b.s);
            }
            if (b.b.c != null) {
              const color = b.b.c;
              if (color.includes('#')) {
                node.style.borderBottomColor = color;
              } else {
                node.style.borderBottomColor = `#${color}`;
              }
            }
          }
          // border left
          if (b.l != null) {
            if (b.l.w != null) {
              node.style.borderLeftWidth = `${DOMUtils.convertUnitTo(b.l.w, 'pt', 'px', 3)}px`;
            }
            if (b.l.s != null) {
              node.style.borderLeftStyle = BaseViewBuilder.borderStyleMapper.render(b.l.s);
            }
            if (b.l.c != null) {
              const color = b.l.c;
              if (color.includes('#')) {
                node.style.borderLeftColor = color;
              } else {
                node.style.borderLeftColor = `#${color}`;
              }
            }
          }
          // border right
          if (b.r != null) {
            if (b.r.w != null) {
              node.style.borderRightWidth = `${DOMUtils.convertUnitTo(b.r.w, 'pt', 'px', 3)}px`;
            }
            if (b.r.s != null) {
              node.style.borderRightStyle = BaseViewBuilder.borderStyleMapper.render(b.r.s);
            }
            if (b.r.c != null) {
              const color = b.r.c;
              if (color.includes('#')) {
                node.style.borderRightColor = color;
              } else {
                node.style.borderRightColor = `#${color}`;
              }
            }
          }
        }
      },
      remove: (node: HTMLElement) => {
        node.style.removeProperty('border');
        node.style.removeProperty('borderWidth');
        node.style.removeProperty('borderStyle');
        node.style.removeProperty('borderColor');
        node.style.removeProperty('borderTopWidth');
        node.style.removeProperty('borderTopStyle');
        node.style.removeProperty('borderTopColor');
        node.style.removeProperty('borderBottomWidth');
        node.style.removeProperty('borderBottomStyle');
        node.style.removeProperty('borderBottomColor');
        node.style.removeProperty('borderLeftWidth');
        node.style.removeProperty('borderLeftStyle');
        node.style.removeProperty('borderLeftColor');
        node.style.removeProperty('borderRightWidth');
        node.style.removeProperty('borderRightStyle');
        node.style.removeProperty('borderRightColor');
      },
    },
    // generic attribute
    genericAttribute: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder, attributeName?: string | null) => {
        if (attributeName && node.hasAttribute(attributeName)) {
          builder.addProperty(attributeName, node.getAttribute(attributeName));
        }
      },
      render: (
        json: Editor.Data.Node.Data,
        node: HTMLElement,
        baseModelData?: Editor.Data.Node.Data,
        attributeName?: string | null,
      ) => {
        if (attributeName && json.properties?.[attributeName] != null) {
          node.setAttribute(attributeName, json.properties[attributeName]);
        }
      },
      remove: (node: HTMLElement, attributeName?: string | null) => {
        if (attributeName) {
          node.removeAttribute(attributeName);
        }
      },
    },
    // status
    status: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('status')) {
          builder.addKeyValue('status', node.getAttribute('status'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.status != null) {
          node.setAttribute('status', json.status);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('status');
      },
    },
    // tasks
    tasks: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        const tasks = node.getAttribute('task');
        if (tasks != null) {
          builder.addKeyValue('tasks', tasks.split(','));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.tasks && json.tasks.length) {
          node.setAttribute('task', json.tasks.join(','));
        } else if (node.hasAttribute('task')) {
          node.removeAttribute('task');
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('task');
      },
    },
    // indentation
    ind: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        const ind: any = {};
        if (node.dataset.leftIndentation != null) {
          ind.l = node.dataset.leftIndentation;
        }

        if (node.dataset.rightIndentation != null) {
          ind.r = node.dataset.rightIndentation;
        }

        if (Object.keys(ind).length > 0) {
          builder.addProperty('ind', ind);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.ind != null) {
          if (json.properties.ind.l != null) {
            node.dataset.leftIndentation = `${json.properties.ind.l}`;
          }

          if (json.properties?.ind.r != null) {
            node.dataset.rightIndentation = `${json.properties.ind.r}`;
          }
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.leftIndentation;
        delete node.dataset.rightIndentation;
      },
    },
    // special indentation
    sp_ind: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        const spInd: any = {};
        if (node.dataset.specialIndent) {
          spInd.t = node.dataset.specialIndent;
        }

        if (node.dataset.specialIndentValue) {
          spInd.v = node.dataset.specialIndentValue;
        }

        if (Object.keys(spInd).length > 0) {
          builder.addProperty('sp_ind', spInd);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.sp_ind != null) {
          if (json.properties.sp_ind.t != null) {
            node.dataset.specialIndent = json.properties.sp_ind.t;
          }
          if (json.properties?.sp_ind.v != null) {
            node.dataset.specialIndentValue = `${json.properties.sp_ind.v}`;
          }
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.specialIndent;
        delete node.dataset.specialIndentValue;
      },
    },
    // vanish
    v: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset[StylesUtils.STYLES.VANISH] != null) {
          builder.addProperty('v', node.dataset[StylesUtils.STYLES.VANISH]);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.v != null) {
          node.dataset[StylesUtils.STYLES.VANISH] = `${json.properties.v}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset[StylesUtils.STYLES.VANISH];
      },
    },
    // lock status
    lock: {
      parse: () => {},
      render: (
        json: Editor.Data.Node.Data,
        node: HTMLElement,
        baseModelData?: Editor.Data.Node.Data,
        attributeName?: string,
      ) => {
        if (!this.Visualizer.isReadOnly?.()) {
          const loggedUserId = this.Data?.users?.loggedUserId;
          if (json.readonly === true) {
            node.setAttribute('lock', 'true');
          } else if (json.lock != null && json.lock !== false) {
            if (typeof json.lock === 'string') {
              const lockData = json.lock.split('|');
              const lockuser = lockData[0];
              const expDate = Date.parse(lockData[1]);
              if (loggedUserId != null && +lockuser !== +loggedUserId && Date.now() <= expDate) {
                node.setAttribute('lock', lockuser);
              }
            }
          } else {
            node.removeAttribute('lock');
          }
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('lock');
      },
    },
    // section
    section: {
      parse: () => {},
      render: (
        json: Editor.Data.Node.Data,
        node: HTMLElement,
        baseModelData?: Editor.Data.Node.Data,
        attributeName?: string,
      ) => {
        if (json.id && json.id === baseModelData?.id) {
          const sectionId = this.Data.sections?.getSectionOfBlock(json.id);
          if (sectionId != null) {
            node.setAttribute('section', sectionId);
          }
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('section');
      },
    },
    // enclosed_element
    enclosed_element: {
      parse: () => {},
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.id != null) {
          node.setAttribute('enclosed_element', json.id);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('enclosed_element');
      },
    },
  };
}
