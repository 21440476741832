import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, Upload } from 'dodoc-design-system';

import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector } from '_common/hooks';
import { EditorService, InstanceService } from '_common/services';

import { closeModal, openAndUpdateModal } from '_common/modals/ModalsSlice';
import { setLoadingValue } from 'Editor/redux/EditorStatusSlice';

import Loading from './Loading/Loading';

import styles from './ImportNewVersionModal.module.scss';

// Current state of the modal. The modal can only be in one of these states simultaneously.
const CURRENT_STATE = {
  INITIAL: 1, // Upload a file state
  LOADING: 2, // Loading state
  ERROR: 3, // Error state
  UPLOADED: 4, // Post uploaded file state
};

const ImportNewVersionModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.ImportNewVersionModal);
  const document = useSelector((state) => state.editor.status.documentId);

  const [currentState, setCurrentState] = useState(CURRENT_STATE.INITIAL);
  const [file, setFile] = useState<File>();

  useEffect(() => {
    if (file?.name) {
      setCurrentState(CURRENT_STATE.UPLOADED);
    }
  }, [file]);

  const importNewVersion = useCallback(async (documentId, file) => {
    const { data } = await new EditorService().importDocument(documentId, file);

    if (data?.id) {
      const jobId = data.id;
      let timeout: NodeJS.Timeout | null = null;

      dispatch(setLoadingValue('EDITOR_PROCESSING_STATE'));

      timeout = setInterval(async () => {
        try {
          const { data } = await new InstanceService().checkJobStatus({ jobs: [jobId] });
          // @ts-expect-error Missing endpoint type "/api/jobs/get"
          switch (data[jobId].status) {
            case 'queued':
            case 'started':
              return;
            case 'finished':
              // @ts-expect-error Missing endpoint type "/api/jobs/get"
              if (data[jobId]?.result[1] === 'broken') {
                dispatch(
                  openAndUpdateModal({
                    modal: 'ConfirmationModal',
                    data: {
                      headerType: 'error',
                      title: 'EDITOR_BROKEN_STATE_TITLE',
                      message: 'EDITOR_BROKEN_STATE_MESSAGE',
                      confirmButtonTextId: 'editor.menu.file.backToExplorer',
                      confirmButtonType: '',
                      actionCode: 'backToExplorer',
                      cancelButtonShow: false,
                      persistent: true,
                    },
                  }),
                );
              } else {
                notify({
                  type: 'success',
                  title: 'NEW_DOCUMENT_VERSION_IMPORTED',
                  message: 'DOCX_SUCCESSFULLY_IMPORTED_AS_NEW_VERSION',
                });
              }

              if (timeout) {
                dispatch(setLoadingValue(false));
                clearInterval(timeout);
              }

              return;
            case 'failed':
              if (timeout) {
                dispatch(setLoadingValue(false));
                clearInterval(timeout);
              }

              break;

            default:
          }
        } catch (error) {
          if (timeout) {
            dispatch(setLoadingValue(false));
            clearInterval(timeout);
          }
        }
      }, 2500);
    }
  }, []);

  // Reset the modal the the initial state
  const resetState = () => {
    setCurrentState(CURRENT_STATE.INITIAL);
    setFile(undefined);
  };

  // Proccess the file upload
  const handleUpload = (e: React.DragEvent<HTMLDivElement>, acceptedFiles: File[]) => {
    if (
      !acceptedFiles[0] ||
      (acceptedFiles[0].type && acceptedFiles[0].name.indexOf('docx')) === -1
    ) {
      setCurrentState(CURRENT_STATE.ERROR);
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('progress', () => {
      setCurrentState(CURRENT_STATE.LOADING);
    });

    reader.addEventListener('load', () => {
      setFile(acceptedFiles[0]);
    });

    reader.readAsDataURL(acceptedFiles[0]);
  };

  const importDocument = async () => {
    importNewVersion(document, file);
    close();
  };

  const close = () => {
    resetState();
    dispatch(closeModal('ImportNewVersionModal'));
  };

  return (
    <Modal open={!!isOpen} width="60rem" onClose={close} testId="import-new-version">
      <Modal.Header onClose={close}>
        <FormattedMessage id="IMPORT_NEW_VERSION" />
      </Modal.Header>
      <Modal.Body overflow="visible">
        {currentState === CURRENT_STATE.INITIAL && (
          <Upload
            data-testid="inputUpload"
            accept=".docx"
            label={intl.formatMessage({
              id: 'BROWSE_YOUR_COMPUTER',
            })}
            onDrop={handleUpload}
            text={intl.formatMessage({
              id: 'DRAG_AND_DROP_DOCX_FILE',
            })}
            testId="upload-new-version-input"
          />
        )}
        {currentState === CURRENT_STATE.LOADING && (
          <Loading message={{ id: 'storage.actionBar.new.importingDocument' }} icon="Document" />
        )}
        {currentState === CURRENT_STATE.ERROR && (
          <div className={styles.error}>
            <div className={styles.message}>
              <FormattedMessage id="INVALID_FORMAT_IMPORT_ONLY_DOCX" />
            </div>

            <div className={styles.button}>
              <Button
                size="medium"
                variant="primary"
                onClick={resetState}
                fullWidth
                margin="4rem 0 0 0"
                testId="import-new-version-import-diferent-file-button"
              >
                <FormattedMessage id="IMPORT_DIFFERENT_FILE" />
              </Button>
            </div>
          </div>
        )}
        {currentState === CURRENT_STATE.UPLOADED && (
          <div className={styles.confirmation}>
            <FormattedMessage
              id="YOU_ARE_IMPORTING_WORD_TO_BE_CURRENT_VERSION"
              values={{ name: file?.name }}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="import-new-version-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        {currentState === CURRENT_STATE.UPLOADED && (
          <Button
            size="medium"
            variant="primary"
            onClick={importDocument}
            testId="import-new-version-submit-button"
          >
            <FormattedMessage id="IMPORT_DOCX_AS_A_NEW_VERSION" />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ImportNewVersionModal;
