//TODO: REDO

import { Logger } from '_common/services';
import { ELEMENTS } from 'Editor/services/consts';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import EditorManager from 'Editor/services/EditorManager';
import DOMUtils from 'Editor/services/DOMUtilities/DOMUtils/DOMUtils';

export class ListStyles {
  constructor(editorContext, stylesContext) {
    this.editorContext = editorContext;
    this.stylesContext = stylesContext;
  }

  start() {
    this._initialize();
  }

  _initialize() {}

  destroy() {}

  removeListStyleFromBlock(actionContext, blockElement) {
    if (this.editorContext.DataManager.numbering.isListElement(blockElement.id)) {
      // if (this.editorContext.DataManager.numbering.isBlockInOutlineList(blockElement.id)) {
      //   this.editorContext.DataManager.numbering.removeNodeFromOutline(
      //     actionContext,
      //     blockElement.id,
      //   );
      //   actionContext.jsonChanges = true;
      // } else {
      this.editorContext.DataManager.numbering.removeBlocksFromList([blockElement.id]);
      actionContext.jsonChanges = true;
      // }
    }
  }

  async _applyListStyleToBlock(actionContext, styleId, blockElement, blockRange) {
    if (styleId) {
      let listId;
      const previousSibling = blockElement.previousSibling;
      let previousSiblingId;
      let previousListStyleId;

      const style = this.editorContext.DataManager.styles.listStyles.style(styleId);

      // check previous sibling list
      if (
        previousSibling &&
        previousSibling.tagName === ELEMENTS.ParagraphElement.TAG &&
        this.editorContext.DataManager.numbering.isListElement(previousSibling.id)
      ) {
        previousSiblingId = previousSibling.id;
        listId = this.editorContext.DataManager.numbering.getListIdFromBlock(previousSibling.id);
        previousListStyleId = this.editorContext.DataManager.numbering.getStyleIdForList(listId);
      }

      if (style.isMultiLevelList()) {
        // MULTI-LEVEL LISTS WITH STYLES
        // remove from list first if is a list element
        if (
          this.editorContext.DataManager?.numbering.isListElement(blockElement.id) &&
          !this.editorContext.DataManager?.numbering.isBlockInOutlineList(blockElement.id)
        ) {
          this.removeListStyleFromBlock(actionContext, blockElement, blockRange);
        }

        // search for document outline list
        //TODO: TempFix rethink about this
        const listsWithStyles =
          this.editorContext.DataManager.styles.documentStyles.getListsWithStyles();
        // for (let i = 0; i < listsWithStyles.length; i++) {
        listId = listsWithStyles[0];
        //     break;

        // }

        if (listId) {
          // there's already an outline from document
          const outlineListStyleId =
            this.editorContext.DataManager.numbering.getStyleIdForList(listId);

          if (!this.editorContext.DataManager.numbering.isListInDocument(listId)) {
            // create a new outline in the document
            listId = this.editorContext.DataManager.numbering.createNewList(styleId, listId);
          }

          if (!outlineListStyleId || styleId !== outlineListStyleId) {
            // update outline list with new style
            this.editorContext.DataManager.numbering.setListStyle(actionContext, listId, styleId);
            actionContext.jsonChanges = true;
          }

          // update document styles
          this.editorContext.DataManager.styles.updateDocumentStylesForList(listId, styleId);
          actionContext.jsonChanges = true;

          // apply document style to block element
          await this.stylesContext.documentStyles.applyDocumentStyleToBlock(
            actionContext,
            style.level(style.getLevelForType(blockElement.styleId)).et,
            blockElement,
            blockRange,
          );
        } else {
          // create a new outline
          listId = this.editorContext.DataManager.numbering.createNewList(styleId);

          // update document styles
          this.editorContext.DataManager.styles.updateDocumentStylesForList(listId, styleId);
          actionContext.jsonChanges = true;

          await this.stylesContext.documentStyles.applyDocumentStyleToBlock(
            actionContext,
            style.level(style.getLevelForType(blockElement.styleId)).et,
            blockElement,
            blockRange,
          );
        }
      } else {
        // SIMPLE LISTS
        // if theres no previous list or style is diferent creates a new list id
        if (!listId || styleId !== previousListStyleId) {
          listId = this.editorContext.DataManager.numbering.createNewList(styleId);
        }

        // get level if element is already a list
        const level = this.editorContext.DataManager.numbering.getListLevelFromBlock(
          blockElement.id,
        );

        // remove from list first if is a list element
        this.removeListStyleFromBlock(actionContext, blockElement, blockRange);

        this.editorContext.DataManager.numbering.addBlocksToList(
          actionContext,
          [blockElement.id],
          listId,
          level || 0,
          previousSiblingId,
        );
      }
    }
  }

  /**
   *
   * @param {string|boolean=} styleId
   */
  async toggleListStyleToSelection(styleId) {
    if (this.editorContext.navigationManager.isMarkerRendered()) {
      this.editorContext.visualizerManager?.selection.stopSelectionTracker();

      let actionContext;
      try {
        this.editorContext.navigationManager.scrollIntoSelection();

        EditorManager.getInstance().removePasteOptions();

        actionContext = new ActionContext();

        const stylableElements = this.editorContext.selectionManager.splitRangeByBlocks();

        let i;
        for (i = 0; i < stylableElements.length; i++) {
          if (styleId != null && styleId !== '' && styleId !== false) {
            const level0 = DOMUtils.findNodeLevel0(
              this.editorContext.documentContainer,
              stylableElements[i].block,
            );
            if (DOMUtils.isBlockNodeEditable(level0)) {
              await this._applyListStyleToBlock(
                actionContext,
                styleId,
                stylableElements[i].block,
                stylableElements[i].range,
              );
            }
          } else {
            this.removeListStyleFromBlock(
              actionContext,
              stylableElements[i].block,
              stylableElements[i].range,
            );
          }
        }

        this.editorContext.visualizerManager?.selection.triggerSelectionChanged();

        // save changes to dom
        this.editorContext.changeTracker.saveActionChanges(actionContext);
      } catch (error) {
        Logger.captureException(error);
      } finally {
        this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
      }
    }
  }

  async indentList(actionContext, blockElement, blockRange, outdent = false) {
    if (blockElement) {
      let level0 = blockElement;
      if (blockElement.parentNode !== this.editorContext.documentContainer) {
        // paragraphs inside tables
        level0 = DOMUtils.findNodeLevel0(this.editorContext.documentContainer, blockElement);
      }

      const listType = this.editorContext.DataManager.numbering.getListType(blockElement.id);

      if (listType === 'simple') {
        const listElements = [];

        listElements.push({
          blockId: level0.id,
          id: blockElement.id,
        });

        if (outdent) {
          // outdent
          await this.editorContext.DataManager.numbering.outdentNodes(listElements, false);
          actionContext.jsonChanges = true;
        } else {
          // indent
          await this.editorContext.DataManager.numbering.indentNodes(listElements);
          actionContext.jsonChanges = true;
        }
      } else if (listType === 'outline') {
        const outlineList = this.editorContext.DataManager.numbering.hasBlockADocumentStyleWithList(
          level0.id,
          blockElement.id,
        );

        const outlineListStyleId =
          this.editorContext.DataManager.numbering.getStyleIdForList(outlineList);
        const outlineListStyle =
          this.editorContext.DataManager.styles.listStyles.style(outlineListStyleId);

        if (outlineList && outlineListStyle) {
          // if outline list change element type

          let elementLevel;

          if (outdent) {
            // outdent
            elementLevel = outlineListStyle.getPreviousLevelForStyleId(blockElement.styleId);
          } else {
            // indent
            elementLevel = outlineListStyle.getNextLevelForStyleId(blockElement.styleId);
          }

          if (elementLevel) {
            await this.stylesContext.documentStyles.applyDocumentStyleToBlock(
              actionContext,
              outlineListStyle.level(elementLevel).et,
              blockElement,
              blockRange,
            );
          } else if (outdent) {
            blockElement.outdent();
            actionContext.addChangeUpdatedNode(blockElement);
          } else {
            blockElement.indent();
            actionContext.addChangeUpdatedNode(blockElement);
          }
        }
      }
    }
  }
}
