import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Divider, Dropdown, Select, Slider, Toggle, Tooltip, usePopper } from 'dodoc-design-system';

import { useDispatch, useSelector, useDebounce } from '_common/hooks';
import { openModal } from '_common/modals/ModalsSlice';
import { setZoomValue } from 'PDF/redux/PDFGeneralSlice';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';
import { InteractionController } from '_common/components';

const ZOOM_SELECT_OPTIONS = [
  { label: '10%', value: '0.1' },
  { label: '25%', value: '0.25' },
  { label: '50%', value: '0.5' },
  { label: '75%', value: '0.75' },
  { label: '100%', value: '1' },
  { label: '125%', value: '1.25' },
  { label: '150%', value: '1.5' },
  { label: '200%', value: '2' },
  { label: '400%', value: '4' },
  { label: '800%', value: '8' },
  { label: '1600%', value: '16' },
  { label: '2400%', value: '24' },
  { label: '3200%', value: '32' },
  { label: '6400%', value: '64' },
];

const Footer = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isOpen, popperProps, referenceProps } = usePopper();

  const [sendOnboardingStatus] = useOnboardingStatusMutation();
  const zoom = useSelector((state) => state.pdf.general.zoom);
  const [localZoomValue, setLocalZoomValue] = useState(1);
  const [newValue, setNewValue] = useState('');

  useEffect(() => {
    setLocalZoomValue(zoom);
  }, [zoom]);

  useDebounce(localZoomValue, 500, (value) => {
    dispatch(setZoomValue(value));
  });

  const zoomSelectValue = useMemo(() => {
    return { label: `${(zoom * 100).toFixed(0)}%`, value: `${zoom}` };
  }, [zoom]);

  const handleOpenAboutModal = () => {
    dispatch(openModal('AboutModal'));
  };

  const handleResetOnboarding = async () => {
    sendOnboardingStatus({ target: 'dopdf', step: 'beginning' });
  };

  return (
    <InteractionController environment="dopdf" style={{ alignItems: 'center', height: '100%' }}>
      <InteractionController
        environment="dopdf"
        rules={[{ interaction: 'pdf_allow_zoom' }]}
        style={{ alignItems: 'center', height: '100%' }}
      >
        <Slider
          variant="discrete"
          min={10}
          middle={100}
          max={6400}
          step={5.5555}
          value={Number((zoom * 100).toFixed(0))}
          onChange={(value) => {
            setLocalZoomValue(value / 100);
          }}
          rangeValues={false}
          testId="footer-zoom"
        />
        <div style={{ marginLeft: '2rem' }}>
          <Select
            clearable={false}
            creatable
            size="medium"
            options={ZOOM_SELECT_OPTIONS}
            onChange={({ value }, meta) => {
              if (meta?.action === 'create-option') {
                const zoom = +value / 100;
                dispatch(setZoomValue(+zoom));
              } else {
                dispatch(setZoomValue(+value));
              }
            }}
            value={
              ZOOM_SELECT_OPTIONS.find(
                (defualtValue) => defualtValue.value === zoomSelectValue.value,
              ) || zoomSelectValue
            }
            menuPlacement="top"
            menuPosition="fixed"
            onInputChange={(e: string) => {
              setNewValue(e);
            }}
            inputValue={newValue}
            formatCreateLabel={(label) => (label.endsWith('%') ? label : `${label}%`)}
            isValidNewOption={(option) =>
              !!option &&
              ZOOM_SELECT_OPTIONS.every((zoomOption) => `${+zoomOption.value * 100}` !== option)
            }
            customStyles={{
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 1052,
              }),
            }}
            testId="pdf-zoom"
          />
        </div>
      </InteractionController>
      <Divider vertical margin="0 1rem" />

      <Tooltip
        content={intl.formatMessage({ id: 'HELP_AND_SUPPORT' })}
        placement="left"
        disabled={popperProps.isOpen}
        testId="pdf-help-tooltip"
      >
        <Toggle
          size="medium"
          variant="ghost"
          icon={isOpen ? 'HelpBlue' : 'HelpGrey'}
          isToggled={isOpen}
          testId="pdf-help-toggle"
          {...referenceProps}
        />
      </Tooltip>
      <Dropdown {...popperProps} testId="help-support-dropdown">
        <Dropdown.Item
          size="medium"
          onClick={handleOpenAboutModal}
          testId="pdf-help-open-about-modal"
        >
          <FormattedMessage id="ABOUT_DODOC" />
        </Dropdown.Item>
        <Dropdown.Item
          size="medium"
          onClick={handleResetOnboarding}
          testId="pdf-help-reset-onboarding"
        >
          <FormattedMessage id="RESET_ONBOARDING" />
        </Dropdown.Item>
      </Dropdown>
    </InteractionController>
  );
};

export default Footer;
