import ViewModelValidations from 'Editor/services/VisualizerManager/ViewModels/ViewModelValidations';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { CharacterPositionIterator } from '../../../Iterators';
import { JsonRange } from '../../../JsonRange';
import { BaseModifier } from '../BaseModifier';

export class MoveCharacterModifier extends BaseModifier {
  protected incrementeOffset?: number;

  constructor(
    Data: Editor.Data.API,
    direction: Editor.Selection.ModifierDirection,
    incrementOffset?: number,
  ) {
    super(Data, 'move', 'character', direction);

    this.incrementeOffset = incrementOffset;
  }

  private moveCharacterForward(range: Editor.Selection.EditorRange) {
    this.collapseRange(range);

    const node = range.startContainer;
    const blockNode = EditorDOMUtils.findFirstLevelChildNode(
      EditorDOMUtils.getContentContainer(node),
      node,
    ) as Editor.Visualizer.BaseView;

    const blockViewModel = blockNode.vm;

    const textElement = EditorDOMUtils.getSelectableElementFromBlock(blockNode, node);

    if (blockNode && range.isAtNodeEnd(blockNode)) {
      const nextSibling = EditorDOMUtils.getNextSelectableElement(blockNode, textElement, node);
      if (nextSibling) {
        range.setStart(nextSibling, 0);
        range.setEnd(nextSibling, 0);
      }
    } else if (ViewModelValidations.isBlockViewModel(blockViewModel)) {
      const jsonRange = JsonRange.buildFromDOMRange(range);
      const nodeData = this.Data.nodes.getNodeModelById(jsonRange.start.b);
      if (nodeData?.navigationData) {
        const iterator = new CharacterPositionIterator(
          nodeData.navigationData,
          jsonRange.start.p,
          this.incrementeOffset,
        );
        let nextPath = iterator.next();

        if (nextPath) {
          jsonRange.start.p = nextPath;
          jsonRange.end.p = nextPath;

          range.updateFromJsonRange(jsonRange);
        } else {
          throw new Error('Invalid position!');
        }
      }
    }

    // update range modifiers
    const clientRects = range.getClientRects();
    if (clientRects.length > 0) {
      this.Data.selection?.updateModifiers({
        px: clientRects[0].left,
      });
    }
  }

  private moveCharacterBackward(range: Editor.Selection.EditorRange) {
    this.collapseRange(range);

    const node = range.startContainer;
    const blockNode = EditorDOMUtils.findFirstLevelChildNode(
      EditorDOMUtils.getContentContainer(node),
      node,
    ) as Editor.Visualizer.BaseView;

    const textElement = EditorDOMUtils.getSelectableElementFromBlock(blockNode, node);

    if (blockNode && range.isAtNodeStart(blockNode)) {
      const previousSibling = EditorDOMUtils.getPreviousSelectableElement(
        blockNode,
        textElement,
        node,
      );
      if (previousSibling) {
        range.setStart(previousSibling, previousSibling.childNodes.length);
        range.setEnd(previousSibling, previousSibling.childNodes.length);
      }
    } else {
      const jsonRange = JsonRange.buildFromDOMRange(range);
      const nodeData = this.Data.nodes.getNodeModelById(jsonRange.start.b);

      if (nodeData?.navigationData) {
        const iterator = new CharacterPositionIterator(
          nodeData.navigationData,
          jsonRange.start.p,
          this.incrementeOffset,
        );
        const previousPosition = iterator.previous();
        if (previousPosition) {
          jsonRange.start.p = previousPosition;
          jsonRange.end.p = previousPosition;

          range.updateFromJsonRange(jsonRange);
        } else {
          throw new Error('Invalid position!');
        }
      }
    }

    // update range modifiers
    const clientRects = range.getClientRects();
    if (clientRects.length > 0) {
      this.Data.selection?.updateModifiers({
        px: clientRects[0].left,
      });
    }
  }

  visitDoDOCRange(range: Editor.Selection.EditorRange): void {
    switch (this.direction) {
      case 'forward':
        return this.moveCharacterForward(range);
      case 'backward':
        return this.moveCharacterBackward(range);
      default:
        return;
    }
  }

  visitJsonRange(range: Editor.Selection.JsonRange): void {
    const editorRange = range.serializeToDOMRange();
    this.visitDoDOCRange(editorRange);
    range.updateFromDOMRange(editorRange);
  }
}
