/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { PDFDocument } from '../../models/';
import { DoPDFDocument } from '_common/services/Realtime';
import { BaseController } from '../BaseController';

export class DocumentController extends BaseController {
  doc?: DoPDFDocument;
  pdfDocument?: PDFDocument;

  async start(documentId: string) {
    this.doc = this.Data.models?.get(
      this.Data?.models.TYPE_NAME.DOCUMENT,
      documentId,
      {} as Realtime.Core.Document.Data,
    );
    this.pdfDocument = this.Data.models?.get('PDFDOCUMENT', documentId);
    this.doc?.on('UPDATED', (newObj) => {
      this.Data.events?.emit('DOCUMENT_UPDATED', newObj);
    });
    this.doc?.on(
      'UPDATE_OBJECT_STATUS',
      (
        id: Realtime.Core.Document.Data['id'],
        status: Realtime.Core.Document.Data['status'],
        statusInfo: Realtime.Core.Document.Data['statusInfo'],
      ) => {
        this.Data.events?.emit(
          'UPDATE_OBJECT_STATUS',
          Object.assign({}, this.doc?.get(), { status, statusInfo }),
          status,
          statusInfo,
        );
      },
    );
    await this.doc?.fetch();
    await this.pdfDocument?.fetch();
  }

  stop(): void {}

  destroy(): void {
    if (this.doc) {
      // this.Data.models?.disposeModel(this.Data?.models.TYPE_NAME.DOCUMENT, this.doc.id);
      delete this.doc;
    }
  }

  getNumPages() {
    return this.pdfDocument?.numPages || 0;
  }

  getPage(page: number) {
    return this.Data.models?.getPage(page);
    //return this.pdfDocument?.getPage(page);
  }
}
