import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Popover, Toggle, usePopper, Accordion } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { selectTotalTasksFilters, clearAllTasksFilters } from 'PDF/redux/PDFAnnotationsSlice';

import StatusFilter from './FilterSections/StatusFilter';
import AssignedUserFilter from './FilterSections/AssignedUserFilter';

import styles from './Filters.module.scss';

const Filters = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { canAccess } = usePDFPermissions();
  const { isOpen, popperProps, referenceProps } = usePopper({
    placement: 'bottom-end',
  });

  const filters = useSelector((state) => state.pdf.annotations.tasksFilters);
  const totalTasksFilters = useSelector(selectTotalTasksFilters);

  const handleClearAllFilters = () => {
    dispatch(clearAllTasksFilters());
  };

  return (
    <>
      <Toggle
        variant="ghost"
        icon={isOpen ? 'FilterBlue' : 'FilterGrey'}
        size="medium"
        isToggled={isOpen}
        {...referenceProps}
        iconBadge={
          totalTasksFilters > 0
            ? {
                icon: 'Badge',
                size: 12,
                number: totalTasksFilters,
                position: 'top-right',
              }
            : undefined
        }
        disabled={!canAccess}
        testId="sidebar-tasks-filters-toggle"
      />

      <Popover {...popperProps} testId="sidebar-tasks-filters-popper">
        <div className={styles.popover}>
          <div className={styles.header}>
            <div className={styles.text}>
              <FormattedMessage id="FILTERS" />
            </div>
            <Button
              variant="link"
              size="small"
              onClick={handleClearAllFilters}
              disabled={totalTasksFilters === 0}
              testId="sidebar-tasks-filters-clear-button"
            >
              <FormattedMessage id="CLEAR_ALL" />
            </Button>
          </div>
          <div className={styles.container}>
            <Accordion
              variant="ghost"
              title={intl.formatMessage({ id: 'STATUS' })}
              size="large"
              initialCollapsed={filters['status']?.length > 0 ? false : true}
              testId="sidebar-tasks-filters-status"
            >
              <StatusFilter />
            </Accordion>
            <Accordion
              variant="ghost"
              title={intl.formatMessage({ id: 'ASSIGNED_USER' })}
              size="large"
              initialCollapsed={!filters['assignedUser']}
              testId="sidebar-tasks-filters-assigned-user"
            >
              <AssignedUserFilter />
            </Accordion>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default Filters;
