import { Tooltip } from 'dodoc-design-system';

import { useEllipsisCheck } from '_common/hooks';

import EditorAvatar from 'Editor/components/EditorAvatar/EditorAvatar';
import FormattedDate from '../FormattedDate/FormattedDate';
import FormattedTime from '../FormattedTime/FormattedTime';
import UsernameLabel from '../Labels/UsernameLabel/UsernameLabel';

import styles from './UserPresentation.module.scss';

type UserPresentationProps = {
  userId?: UserId;
  fixedName?: string;
  displayEmail?: boolean;
} & (
  | {
      title?: string;
      creationDate?: never;
    }
  | {
      title?: never;
      creationDate?: ISODate;
    }
);

const UserPresentation = ({
  userId,
  title,
  fixedName,
  creationDate,
  displayEmail,
}: UserPresentationProps) => {
  const { ref, hasEllipsis } = useEllipsisCheck<HTMLDivElement>();

  return (
    <div className={styles.root}>
      <EditorAvatar userId={userId || 'IMPORTED_USER'} />
      <div className={styles.labelsWrap}>
        {title && <div className={styles.title}>{title}</div>}
        <Tooltip
          content={fixedName || <UsernameLabel userId={userId} />}
          disabled={!hasEllipsis}
          testId={`${userId || 'imported-user'}-username-tooltip`}
        >
          <div className={styles.userName} ref={ref}>
            {fixedName || <UsernameLabel userId={userId} displayEmail={displayEmail} />}
          </div>
        </Tooltip>
        {creationDate && (
          <div className={styles.date}>
            <FormattedTime time={creationDate} type="meridiem" />{' '}
            <FormattedDate date={creationDate} type="noyear" />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserPresentation;
