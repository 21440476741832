import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { OnboardingCards } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { setSidebarView } from 'Editor/redux/SidebarSlice';
import { setVersionHistoryValue } from 'Editor/redux/EditorStatusSlice';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';

const OnboardingEditor = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sendOnboardingStatus] = useOnboardingStatusMutation();
  const active = useSelector((state) => state.onboarding.active.editor);

  useEffect(() => {
    if (active) {
      dispatch(setSidebarView(null));
      dispatch(setVersionHistoryValue(false));
    }
  }, [active]);

  const cards = useMemo(
    () => [
      {
        position: {
          right: '11.125rem',

          bottom: '10rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_1_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_1_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          left: '13rem',
          top: '170px',
        },
        pulse: {
          bottom: '30.5rem',
          right: '49.125rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_2_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_2_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '11.125rem',
          top: '11rem',
        },
        pulse: {
          top: '1rem',
          left: '43.125rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_3_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_3_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '11.125rem',
          top: '133px',
        },
        pulse: {
          top: '1rem',
          left: '43.125rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_4_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_4_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '11.125rem',
          top: '172px',
        },
        pulse: {
          top: '1rem',
          left: '43.125rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_5_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_5_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '11.125rem',
          top: '212px',
        },
        pulse: {
          top: '1rem',
          left: '43.125rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_6_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_6_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '11.125rem',
          top: '252px',
        },
        pulse: {
          top: '1rem',
          left: '43.125rem',
        },
        header: intl.formatMessage({ id: 'FIND_AND_REPLACE' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_FIND_AND_REPLACE_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '11.125rem',
          top: '292px',
        },
        pulse: {
          top: '1rem',
          left: '43.125rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_7_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_7_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '11.125rem',
          top: '332px',
        },
        pulse: {
          top: '1rem',
          left: '43.125rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_8_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_8_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '11.125rem',
          top: '372px',
        },
        pulse: {
          top: '1rem',
          left: '43.125rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_9_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_9_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '11.125rem',
          top: '412px',
        },
        pulse: {
          top: '1rem',
          left: '43.125rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_10_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_10_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '11.125rem',
          top: '452px',
        },
        pulse: {
          top: '1rem',
          left: '43.125rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_11_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_11_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '11.125rem',
          top: '492px',
        },
        pulse: {
          top: '1rem',
          left: '43.125rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_12_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_12_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '11.125rem',
          top: '532px',
        },
        pulse: {
          top: '1rem',
          left: '43.125rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_13_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_13_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
      {
        position: {
          right: '11.125rem',
          bottom: '10rem',
        },
        header: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_14_HEADER' }),
        content: intl.formatMessage({ id: 'ONBOARDING_CARD_EDITOR_14_CONTENT' }),
        href: 'https://dodoc.zendesk.com/hc/en-us',
      },
    ],
    [],
  );

  const skipProps = useMemo(() => {
    return {
      skipPreviousLabel: intl.formatMessage({ id: 'global.cancel' }),
      skipFinishLabel: intl.formatMessage({ id: 'SKIP' }),
      skipHeader: intl.formatMessage({ id: 'ONBOARDING_SKIP_CARD_HEADER' }),
      skipContent: intl.formatMessage({ id: 'ONBOARDING_SKIP_CARD_CONTENT' }),
      skipPosition: {
        right: '11.125rem',
        bottom: '10rem',
      },
    };
  }, [intl]);

  const handleOnCloseOnboardingCard = () => {
    sendOnboardingStatus({ target: 'editor', step: 'ending', skip: true });
  };

  const handleFinalExit = () => {
    sendOnboardingStatus({ target: 'editor', step: 'ending' });
  };

  if (!active) {
    return null;
  }

  return (
    <OnboardingCards
      cards={cards}
      finishLabel={intl.formatMessage({ id: 'FINISH' })}
      onExit={handleOnCloseOnboardingCard}
      onFinalExit={handleFinalExit}
      onCloseTooltipContent={intl.formatMessage({ id: 'SKIP_ONBOARDING' })}
      skipOnCloseTooltipContent={intl.formatMessage({ id: 'CONTINUE_ONBOARDING' })}
      {...skipProps}
      testId="editor"
    />
  );
};

export default OnboardingEditor;
