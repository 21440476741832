import { DEFAULT_CARD_STYLES_PANEL } from 'PDF/redux/PDFAnnotationsSlice';
import { isContentEmpty } from 'utils';

import { RichTextEditor } from '_common/components';
import type { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';
import AnnotationType from './AnnotationType';

import styles from './Body.module.scss';

type BodyProps = {
  annotation: PDF.Annotation;
  editorRef: React.Ref<RichTextEditorHandler>;
  testId: string;
};

const Body = ({ annotation, editorRef, testId }: BodyProps) => {
  return (
    <div className={styles.root}>
      <AnnotationType type={annotation.subtype} />
      {!isContentEmpty(annotation.content) && (
        <RichTextEditor
          ref={editorRef}
          initialValue={JSON.stringify(annotation.content.content)}
          readOnly
          expanded
          overwrittenStyles={DEFAULT_CARD_STYLES_PANEL}
          testId={`${testId}-rich-text-editor`}
        />
      )}
    </div>
  );
};

export default Body;
