import { ReactNode } from 'react';

import styles from './Row.module.scss';

type RowProps = {
  title: string;
  empty?: string;
  children?: ReactNode;
  testId: string;
};

const Row = ({ title, empty, children, testId }: RowProps) => {
  const renderContent = () => {
    if (!children) {
      if (empty) {
        return (
          <div className={styles.empty} data-testid={`${testId}-empty`}>
            {empty}
          </div>
        );
      }

      return null;
    }

    return children;
  };

  return (
    <div className={styles.root} data-testid={testId}>
      <div className={styles.title} data-testid={`${testId}-title`}>
        {title}
      </div>
      <div className={styles.content} data-testid={`${testId}-content`}>
        {renderContent()}
      </div>
    </div>
  );
};

export default Row;
