import DoDOCRange from '_common/DoDOCSelection/DoDOCRange/DoDOCRange';
import { EditorDOMUtils } from '../../DOM';

export class EditorRange extends DoDOCRange {
  accept(visitor: DoDOCSelection.DoDOCRange.IRangeVisitor) {
    visitor.visitDoDOCRange(this);
  }

  static fromNativeRange(nativeRange: Range) {
    let range = new EditorRange();
    range.setStart(nativeRange.startContainer, nativeRange.startOffset);
    range.setEnd(nativeRange.endContainer, nativeRange.endOffset);
    return range;
  }

  getClientRects(): DOMRectList {
    return super.getClientRects();
  }

  updateFromJsonRange(jsonRange: Editor.Selection.JsonRange) {
    let start = jsonRange.serializeStartToNodeOffset();
    let end = jsonRange.serializeEndToNodeOffset();

    if (start.node != null && start.offset != null && end.node != null && end.offset != null) {
      this.setStart(start.node, start.offset);
      this.setEnd(start.node, start.offset);

      if (this.comparePoint(end.node, end.offset) >= 0) {
        this.setEnd(end.node, end.offset);
      } else {
        this.setStart(end.node, end.offset);
      }
    } else {
      throw new Error('Node not found!');
    }
  }

  isAtNodeStart(node: Node, container: 'start' | 'end' = 'start'): boolean {
    if (node) {
      let anchorNode;
      let anchorOffset;
      if (container === 'start') {
        anchorNode = this.startContainer;
        anchorOffset = this.startOffset;
      } else {
        anchorNode = this.endContainer;
        anchorOffset = this.endOffset;
      }

      return EditorDOMUtils.isAtStartOfNode(node, anchorNode, anchorOffset);
    }
    return false;
  }

  isAtNodeEnd(node: Node, container: 'start' | 'end' = 'start'): boolean {
    if (node) {
      let focusNode;
      let focusOffset;
      if (container === 'end') {
        focusNode = this.endContainer;
        focusOffset = this.endOffset;
      } else {
        focusNode = this.startContainer;
        focusOffset = this.startOffset;
      }

      return EditorDOMUtils.isAtEndOfNode(node, focusNode, focusOffset);
    }
    return false;
  }

  setRangeEnd(node: Node, position: DoDOCSelection.CaretPosition = 'START', offset: number = 0) {
    if (!node) {
      return false;
    }

    let workingNode = node;
    switch (position) {
      case 'PRE': {
        this.setEndBefore(workingNode);
        break;
      }
      case 'START': {
        this.setEnd(node, 0);
        break;
      }
      case 'INDEX': {
        this.setEnd(node, offset);
        break;
      }
      case 'INSIDE_START': {
        workingNode = node;
        while (
          workingNode.firstChild &&
          !EditorDOMUtils.isNodeNonSelectable(workingNode.firstChild)
        ) {
          workingNode = workingNode.firstChild;
        }
        this.setEnd(workingNode, 0);
        break;
      }
      case 'END': {
        let length = 0;
        if (node.nodeType === Node.TEXT_NODE) {
          length = (node as Text).length;
        }
        if (node.nodeType === Node.ELEMENT_NODE) {
          length = node.childNodes.length;
        }
        this.setEnd(node, length);
        break;
      }
      case 'INSIDE_END': {
        workingNode = node;
        while (
          workingNode.lastChild &&
          !EditorDOMUtils.isNodeNonSelectable(workingNode.lastChild)
        ) {
          workingNode = workingNode.lastChild;
        }
        let length = 0;
        if (workingNode.nodeType === Node.TEXT_NODE) {
          length = (workingNode as Text).length;
        }
        if (workingNode.nodeType === Node.ELEMENT_NODE) {
          length = workingNode.childNodes.length;
        }
        this.setEnd(workingNode, length);
        break;
      }
      case 'POST': {
        this.setEndAfter(workingNode);
        break;
      }
      default:
        return false;
    }
    return true;
  }

  setRangeStart(node: Node, position: DoDOCSelection.CaretPosition = 'START', offset: number = 0) {
    if (!node) {
      return false;
    }

    let workingNode = node;
    switch (position) {
      case 'PRE': {
        this.setStartBefore(workingNode);
        break;
      }
      case 'START': {
        this.setStart(node, 0);
        break;
      }
      case 'INDEX': {
        this.setStart(node, offset);
        break;
      }
      case 'INSIDE_START': {
        workingNode = node;
        while (
          workingNode.firstChild &&
          !EditorDOMUtils.isNodeNonSelectable(workingNode.firstChild)
        ) {
          workingNode = workingNode.firstChild;
        }
        this.setStart(workingNode, 0);
        break;
      }
      case 'END': {
        let length = 0;
        if (node.nodeType === Node.TEXT_NODE) {
          length = (node as Text).length;
        }
        if (node.nodeType === Node.ELEMENT_NODE) {
          length = node.childNodes.length;
        }
        this.setStart(node, length);
        break;
      }
      case 'INSIDE_END': {
        workingNode = node;
        while (
          workingNode.lastChild &&
          !EditorDOMUtils.isNodeNonSelectable(workingNode.lastChild)
        ) {
          workingNode = workingNode.lastChild;
        }
        let length = 0;
        if (workingNode.nodeType === Node.TEXT_NODE) {
          length = (workingNode as Text).length;
        }
        if (workingNode.nodeType === Node.ELEMENT_NODE) {
          length = workingNode.childNodes.length;
        }
        this.setStart(workingNode, length);
        break;
      }
      case 'POST': {
        this.setStartAfter(workingNode);
        break;
      }
      default:
        return false;
    }
    return true;
  }

  setCaret(node: Node, position: DoDOCSelection.CaretPosition = 'START', offset: number = 0) {
    return this.setRangeStart(node, position, offset) && this.setRangeEnd(node, position, offset);
  }

  private createMarker(): Element {
    const marker = document.createElement('span');
    marker.id = `DoDOCBoundaryMarker_${EditorDOMUtils.generateUUID()}`;
    marker.style.display = 'none';
    marker.style.lineHeight = '0';
    return marker;
  }

  saveRange(): DoDOCSelection.DoDOCRange.BoundaryMarkers {
    const markers: DoDOCSelection.DoDOCRange.BoundaryMarkers = {
      collapsed: this.collapsed,
    };

    if (this.collapsed) {
      const marker = this.createMarker();
      markers.markerId = marker.id;
      this.insertNode(marker);

      this.setStartAfter(marker);
      this.setEndAfter(marker);
    } else {
      const startMarker = this.createMarker();
      markers.startMarkerId = startMarker.id;
      this.insertNode(startMarker);

      const endMarker = this.createMarker();
      markers.endMarkerId = endMarker.id;

      const range = this.cloneRange();
      range.collapse(); // collapse to end;
      range.insertNode(endMarker);

      this.setStartAfter(startMarker);
      this.setEndBefore(endMarker);
    }

    return markers;
  }

  restoreRange(savedMarkers: DoDOCSelection.DoDOCRange.BoundaryMarkers) {
    if (savedMarkers.markerId) {
      const marker = document.getElementById(savedMarkers.markerId);

      if (marker) {
        let parentNode = marker.parentNode;
        if (parentNode) {
          parentNode.normalize();
        }

        if (marker.previousSibling instanceof Text && marker.nextSibling instanceof Text) {
          const node = marker.previousSibling;
          const offset = marker.previousSibling.length;

          if (parentNode) {
            parentNode.removeChild(marker);
            parentNode.normalize();
          }

          this.setStart(node, offset);
          this.setEnd(node, offset);
        }
        // else if (marker.previousSibling instanceof Element) {
        //   this.setCaret(marker.previousSibling, 'INSIDE_END');
        //   parentNode?.removeChild(marker);
        // }
        else {
          this.setStartBefore(marker);
          this.setEndBefore(marker);
          if (parentNode) {
            parentNode.removeChild(marker);
            parentNode.normalize();
          }
        }
      } else {
        throw new Error('Invalid marker to restore!');
      }
    } else if (savedMarkers.startMarkerId && savedMarkers.endMarkerId) {
      const startMarker = document.getElementById(savedMarkers.startMarkerId);
      const endMarker = document.getElementById(savedMarkers.endMarkerId);

      if (startMarker && endMarker) {
        // startMarker and endMarker are siblings, it means a collapsed position
        if (startMarker.nextSibling === endMarker) {
          let parentNode = startMarker.parentNode;
          if (parentNode) {
            parentNode.normalize();
          }

          if (startMarker.previousSibling instanceof Text) {
            const node = startMarker.previousSibling;
            const offset = startMarker.previousSibling.length;

            this.setStart(node, offset);
            this.setEnd(node, offset);
          } else if (endMarker.nextSibling instanceof Text) {
            const node = endMarker.nextSibling;
            const offset = 0;

            this.setStart(node, offset);
            this.setEnd(node, offset);
          }
          // else if (
          //   startMarker.previousSibling instanceof Element &&
          //   startMarker.previousSibling.nodeName !== 'SPAN'
          // ) {
          //   this.setCaret(startMarker.previousSibling, 'INSIDE_END');
          // } else if (
          //   endMarker.nextSibling instanceof Element &&
          //   endMarker.nextSibling.nodeName !== 'SPAN'
          // ) {
          //   this.setCaret(endMarker.nextSibling, 'INSIDE_START');
          // }
          else {
            this.setStartBefore(startMarker);
            this.setEndBefore(startMarker);
          }

          if (parentNode) {
            parentNode.removeChild(startMarker);
            parentNode.removeChild(endMarker);
            parentNode.normalize();
          }
        } else {
          // startMarker and endMarker are not siblings, it means a non-collapsed position
          let startParentNode = startMarker.parentNode;
          if (startParentNode) {
            startParentNode.normalize();
          }

          if (
            startMarker.previousSibling instanceof Text &&
            startMarker.nextSibling instanceof Text
          ) {
            const node = startMarker.previousSibling;
            const offset = startMarker.previousSibling.length;

            if (startParentNode) {
              startParentNode.removeChild(startMarker);
              startParentNode.normalize();
            }

            this.setStart(node, offset);
          }
          // else if (
          //   startMarker.nextSibling instanceof Element &&
          //   startMarker.nextSibling.nodeName !== 'SPAN'
          // ) {
          //   this.setRangeStart(startMarker.nextSibling, 'INSIDE_START');
          //   startParentNode?.removeChild(startMarker);
          // }
          else {
            this.setStartBefore(startMarker);
            startParentNode?.removeChild(startMarker);
          }

          let endParentNode = endMarker.parentNode;
          if (endParentNode) {
            endParentNode.normalize();
          }

          if (endMarker.previousSibling instanceof Text && endMarker.nextSibling instanceof Text) {
            const node = endMarker.previousSibling;
            const offset = endMarker.previousSibling.length;

            if (endParentNode) {
              endParentNode.removeChild(endMarker);
              endParentNode.normalize();
            }

            this.setEnd(node, offset);
          }
          // else if (
          //   endMarker.previousSibling instanceof Element &&
          //   endMarker.previousSibling.nodeName !== 'SPAN'
          // ) {
          //   this.setRangeEnd(endMarker.previousSibling, 'INSIDE_END');
          //   endParentNode?.removeChild(endMarker);
          // }
          else {
            this.setEndBefore(endMarker);
            endParentNode?.removeChild(endMarker);
          }
        }
      } else {
        throw new Error('Invalid marker to restore!');
      }
    }
  }
}
