import { FormattedMessage } from 'react-intl';

import SidebarPanel from 'Editor/pages/EditorPage/SidebarComponent/SidebarPanel';
import SidebarHeader from 'Editor/pages/EditorPage/SidebarComponent/SidebarHeader';
import SidebarContent from 'Editor/pages/EditorPage/SidebarComponent/SidebarContent';

import ContentPanel from './ContentPanel/ContentPanel';

import styles from './SpellCheckTab.module.scss';

const SpellCheckTab = () => {
  return (
    <SidebarPanel testId="spellcheck-panel">
      <SidebarHeader>
        <FormattedMessage id="SPELL_CHECK" />
      </SidebarHeader>
      <SidebarContent className={styles.content}>
        <ContentPanel />
      </SidebarContent>
    </SidebarPanel>
  );
};

export default SpellCheckTab;
