import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { navigateToSettings } from 'router/history';

import { closeModal } from '_common/modals/ModalsSlice';
import { useGetRolesListQuery } from 'Settings/pages/TenantSettingsPage/Roles/RolesApi';
import { selectUserIsAdmin } from '_common/services/api/authority';

import NoRolesMessage from './NoRolesMessage';
import ApplyToDescendants from './ApplyToDescendants';
import ShareContainer from './ShareContainer';
import SharedListContainer from './SharedListContainer';

import { useGetObjectQuery } from 'App/redux/objectApi';

const MODAL = 'ShareModal';

const ShareModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const userIsAdmin = useSelector(selectUserIsAdmin);
  const userId = useSelector((state) => state.auth.userId);
  const actions = useSelector((state) => state.app.information.actions);
  const objectId = useSelector((state) => state.modals.ShareModal.objectId);
  const objectType = useSelector((state) => state.modals.ShareModal.objectType);
  const { data: object } = useGetObjectQuery(
    { objectId, objectType },
    { skip: !objectId && !objectType },
  );
  const editor = useSelector((state) => state.modals.ShareModal.editor);

  const { data: rolesRedux } = useGetRolesListQuery(undefined, { skip: !isOpen });

  const [applyToDescendants, setApplyToDescendants] = useState(true);
  const [showGranularPermissions, setShowGranularPermissions] = useState(false);

  const isDisabled =
    //@ts-expect-error Missing Schema for App Information Actions
    actions?.editor.share.edit === false && !userIsAdmin && +object.owner !== +userId;

  const roles = useMemo(() => {
    const rolesWithAccessPermission: Role['id'][] = [];

    if (rolesRedux) {
      rolesRedux.list.forEach((id) => {
        const hasAccessPermission =
          rolesRedux.dict[id].granted.includes('access') ||
          rolesRedux.dict[id].granted.includes('owner');
        if (hasAccessPermission) {
          rolesWithAccessPermission.push(id);
        }
      });
      const array = rolesWithAccessPermission.map((id) => ({
        value: id,
        label: rolesRedux.dict[id].name,
        description:
          rolesRedux.dict[id].description || intl.formatMessage({ id: 'NO_DESCRIPTION' }),
      }));
      return array;
    }
    return rolesWithAccessPermission;
  }, [rolesRedux]);

  const close = () => {
    dispatch(closeModal(MODAL));
  };

  const goToPermissions = () => {
    navigateToSettings(object?.type ?? objectType, object?.id ?? objectId, 'permissions', editor);

    close();
  };

  const handleToggleGranularPermissions = () => {
    setShowGranularPermissions(!showGranularPermissions);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      width={showGranularPermissions ? '136rem' : '85rem'}
      open={isOpen}
      onClose={close}
      testId="share"
    >
      <Modal.Header onClose={close}>
        {object && (
          <FormattedMessage id="storage.modals.share.header" values={{ name: object.name }} />
        )}
      </Modal.Header>
      <Modal.Body>
        {rolesRedux?.list && rolesRedux.list.length === 0 ? (
          <NoRolesMessage type={object?.type ?? objectType} />
        ) : (
          <>
            {object?.type === 'folder' && (
              <ApplyToDescendants
                name={object?.name}
                applyToDescendants={applyToDescendants}
                onChange={setApplyToDescendants}
              />
            )}
            <ShareContainer
              applyToDescendants={applyToDescendants}
              isDisabled={isDisabled}
              roles={roles}
            />
            <SharedListContainer
              applyToDescendants={applyToDescendants}
              showGranularPermissions={showGranularPermissions}
              toggleGranularPermissions={handleToggleGranularPermissions}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          size="medium"
          disabled={isDisabled}
          onClick={goToPermissions}
          testId="share-modal-advanced-permissions-link"
        >
          <FormattedMessage id="ADVANCED" />
        </Button>

        <div style={{ flex: 1 }} />
        <Button size="medium" variant="neutral" onClick={close} testId="share-modal-close-button">
          <FormattedMessage id="DONE" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareModal;
