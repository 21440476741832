import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storageSession from 'redux-persist/lib/storage/session';
import authApi from '_common/services/api/authority';
import onboardingApi from './onboardingApi';

export type OnboardingEnvironments = 'explorer' | 'editor' | 'dopdf';

export type ExplorerActions = '';
export type EditorActions =
  | 'editor_suggestions_addText'
  | 'editor_suggestions_deleteText'
  | 'editor_suggestions_openSidePanel'
  | 'editor_comments_openSidePanel'
  | 'editor_comments_startCommentCreation'
  | 'editor_comments_createComment'
  | 'editor_comments_openReplies'
  | 'editor_comments_mentionInCommentReply'
  | 'editor_tasks_openSidePanel'
  | 'editor_tasks_startTaskCreation'
  | 'editor_tasks_createTask';
export type PDFActions =
  | 'pdf_annotations_highlight'
  | 'pdf_annotations_commentHighlight'
  | 'pdf_comments_createComment'
  | 'pdf_comments_mentionInCommentReply'
  | 'pdf_tasks_assignee'
  | 'pdf_tasks_dueDate'
  | 'pdf_tasks_createTask';

export type ExplorerInteractions = '';
export type EditorInteractions =
  | 'editor_sidepanel_review_suggestions'
  | 'editor_sidepanel_review_comments'
  | 'editor_sidepanel_newComment'
  | 'editor_sidepanel_mentionComment'
  | 'editor_sidepanel_tasks'
  | 'editor_contextMenu_createComment'
  | 'editor_contextMenu_createTask'
  | 'editor_tabmenu_comments'
  | 'editor_mainComponent_addText'
  | 'editor_mainComponent_deleteText'
  | 'editor_mainComponent_addComment'
  | 'editor_mainComponent_addTask'
  | 'editor_tasks_tempCard';
export type PDFInteractions =
  | 'pdf_toolbar_highlight'
  | 'pdf_annotation_edit'
  | 'pdf_toolbar_comment'
  | 'pdf_comment_reply'
  | 'pdf_toolbar_task'
  | 'pdf_task_fill'
  | 'pdf_annotation_save'
  | 'pdf_editable_content'
  | 'pdf_allow_zoom';

type Rect = {
  top: number;
  left: number;
  height: number;
  width: number;
};

type OnboardingSliceState = {
  active: { [key in OnboardingEnvironments]: boolean };
  started: { [key in OnboardingEnvironments]: boolean };
  actionsCompleted: { [key in ExplorerActions | EditorActions | PDFActions]?: boolean };
  interactions: { [key in ExplorerInteractions | EditorInteractions | PDFInteractions]?: boolean };
  initialPhase: {
    [key in OnboardingEnvironments]?: OnboardingPhase;
  };
  currentDocument: { [key in OnboardingEnvironments]?: { id: ObjectId; zoom: number } };
  pulseData: {
    annotationId?: PDF.Annotation['id'];
    commentId?: PDF.Annotation['id'];
    taskId?: string;
    contextMenuTaskItemRect?: Rect;
    contextMenuCommentItemRect?: Rect;
    annotationCardCtaRect?: Rect;
    annotationCardReplyRect?: Rect;
    taskInputRect?: Rect;
    taskButtonRect?: Rect;
    statusLabelRect?: Rect;
  };
};

const SLICE_NAME = 'ONBOARDING';
const INITIAL_STATE: OnboardingSliceState = {
  active: {
    explorer: false,
    editor: false,
    dopdf: false,
  },
  started: {
    explorer: false,
    editor: false,
    dopdf: false,
  },
  actionsCompleted: {},
  interactions: {},
  initialPhase: {},
  currentDocument: {
    editor: { id: '', zoom: 1 },
    dopdf: { id: '', zoom: 1 },
  },
  pulseData: {},
};

// #endregion

// #region Slice
const OnboardingSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    activateOnboarding: (state, action: PayloadAction<OnboardingEnvironments>) => {
      state.active[action.payload] = true;
    },
    startOnboarding: (state, action: PayloadAction<OnboardingEnvironments>) => {
      state.started[action.payload] = true;
    },
    stopOnboarding: (state, action: PayloadAction<OnboardingEnvironments>) => {
      state.started[action.payload] = false;
    },
    pauseOnboarding: (state, action: PayloadAction<OnboardingEnvironments>) => {
      state.started[action.payload] = false;
    },
    completeAction: (
      state,
      action: PayloadAction<keyof OnboardingSliceState['actionsCompleted']>,
    ) => {
      state.actionsCompleted[action.payload] = true;
    },
    completeActionList: (
      state,
      action: PayloadAction<(keyof OnboardingSliceState['actionsCompleted'])[]>,
    ) => {
      action.payload.forEach((action) => {
        state.actionsCompleted[action] = true;
      });
    },
    setInteractions: (
      state,
      action: PayloadAction<(keyof OnboardingSliceState['interactions'])[]>,
    ) => {
      const newInteractions: OnboardingSliceState['interactions'] = {};

      action.payload.forEach((interaction) => {
        newInteractions[interaction] = true;
      });

      state.interactions = newInteractions;
    },
    setCurrentDocument: (
      state,
      action: PayloadAction<{ target: OnboardingEnvironments; id: ObjectId; zoom: number }>,
    ) => {
      state.currentDocument = {
        [action.payload.target]: {
          id: action.payload.id,
          zoom: action.payload.zoom,
        },
      };
    },
    setPulseData: (state, action: PayloadAction<OnboardingSliceState['pulseData']>) => {
      state.pulseData = { ...state.pulseData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(authApi.endpoints.getCurrentUser.matchFulfilled),
      (state, action) => {
        const inactiveStates = ['beginning', 'ending'];

        state.active = {
          explorer: action.payload.onboarding.explorer === 'beginning',
          editor: action.payload.onboarding.editor === 'beginning',
          dopdf: action.payload.onboarding.dopdf === 'beginning',
        };
        state.started = {
          explorer: !inactiveStates.includes(action.payload.onboarding.explorer),
          editor: !inactiveStates.includes(action.payload.onboarding.editor),
          dopdf: !inactiveStates.includes(action.payload.onboarding.dopdf),
        };
        state.initialPhase = {
          explorer: !inactiveStates.includes(action.payload.onboarding.explorer)
            ? action.payload.onboarding.explorer
            : undefined,
          editor: !inactiveStates.includes(action.payload.onboarding.editor)
            ? action.payload.onboarding.editor
            : undefined,
          dopdf: !inactiveStates.includes(action.payload.onboarding.dopdf)
            ? action.payload.onboarding.dopdf
            : undefined,
        };
      },
    );
    //Setup state for onboarding reset
    builder.addMatcher(
      isAnyOf(onboardingApi.endpoints.onboardingStatus.matchFulfilled),
      (state, action) => {
        if (action.meta.arg.originalArgs.step === 'beginning') {
          state.actionsCompleted = {};
          state.interactions = {};
          state.initialPhase[action.meta.arg.originalArgs.target] = undefined;
        }
      },
    );
  },
});

const persistConfig = {
  key: 'onboarding',
  storage: storageSession,
  whitelist: ['currentDocument'],
};

const onboardingReducer = persistReducer(persistConfig, OnboardingSlice.reducer);

export default onboardingReducer;
// #endregion

// #region Actions
export const {
  activateOnboarding,
  startOnboarding,
  stopOnboarding,
  completeAction,
  completeActionList,
  setInteractions,
  setCurrentDocument,
  setPulseData,
} = OnboardingSlice.actions;
// #endregion
