import { Checkbox } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from '_common/hooks';
import { setAnnotationsFilter, selectAllPDFAnnotations } from 'PDF/redux/PDFAnnotationsSlice';
import styles from '../Filters.module.scss';

const StatusFilter = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.pdf.annotations.filters);
  const annotationList = useSelector(selectAllPDFAnnotations);

  const handleStatusFilter = (value: typeof filters['status'][number]) => {
    const newValue = [...filters['status']];

    if (!newValue?.includes(value)) {
      newValue?.push(value);
    } else {
      newValue.splice(newValue.indexOf(value), 1);
    }
    dispatch(setAnnotationsFilter({ filter: 'status', value: [...newValue] }));
  };

  const handleAnnotationsByStatus = (status: typeof filters['status'][number]) => {
    return annotationList.filter((annotation) => annotation.state === status).length;
  };
  return (
    <div style={{ paddingLeft: '3rem' }}>
      <Checkbox
        checked={filters['status']?.includes('Completed') ? 'checked' : 'unchecked'}
        margin="1rem 0 2rem 0"
        onChange={() => handleStatusFilter('Completed')}
        testId="sidebar-comments-filters-status-resolved-checkbox"
      >
        <FormattedMessage id="editor.sidebar.review.filter.status.resolved" />
        <span
          data-testid="sidebar-comments-filters-status-resolved-counter"
          className={styles.checkboxText}
        >{`(${handleAnnotationsByStatus('Completed')})`}</span>
      </Checkbox>
      <Checkbox
        checked={filters['status']?.includes('Cancelled') ? 'checked' : 'unchecked'}
        margin="1rem 0 2rem 0"
        onChange={() => handleStatusFilter('Cancelled')}
        testId="sidebar-comments-filters-status-deleted-checkbox"
      >
        <FormattedMessage id="editor.sidebar.review.filter.status.deleted" />
        <span
          data-testid="sidebar-comments-filters-status-deleted-counter"
          className={styles.checkboxText}
        >{`(${handleAnnotationsByStatus('Cancelled')})`}</span>
      </Checkbox>
    </div>
  );
};

export default StatusFilter;
