import {
  Popover,
  Select,
  SplitToggle,
  Toggle,
  ToggleGroup,
  Tooltip,
  usePopper,
} from 'dodoc-design-system';
import cx from 'classnames';

import { useDispatch, useSelector } from '_common/hooks';
import { setCursorMode } from 'PDF/redux/PDFGeneralSlice';
import { setTextboxPresetStyle, setTextboxSelectedStyle } from 'PDF/redux/PDFAnnotationsSlice';

import styles from './Toggles.module.scss';
import { useIntl } from 'react-intl';
import { usePDFContext } from 'PDF/PDFContext';

const FONTS = [
  { value: 'Arial', label: 'Arial' },
  { value: 'Arial Black', label: 'Arial Black' },
  { value: 'Calibri', label: 'Calibri' },
  { value: 'Comic Sans MS', label: 'Comic Sans MS' },
  { value: 'Courier New', label: 'Courier New' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Impact', label: 'Impact' },
  { value: 'Palatino', label: 'Palatino' },
  { value: 'Tahoma', label: 'Tahoma' },
  { value: 'Times New Roman', label: 'Times New Roman' },
  { value: 'Verdana', label: 'Verdana' },
] as const;

const SIZES = [
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '14', label: '14' },
  { value: '18', label: '18' },
  { value: '24', label: '24' },
  { value: '30', label: '30' },
  { value: '36', label: '36' },
  { value: '48', label: '48' },
  { value: '60', label: '60' },
  { value: '72', label: '72' },
  { value: '96', label: '96' },
] as const;

//black red green blue
const COLORS = ['#000000', '#FF0000', '#008000', '#0000FF'] as const;

const TextboxToggle = ({ disabled }: { disabled: boolean }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();
  const { referenceProps, popperProps, isOpen } = usePopper();
  const cursorMode = useSelector((state) => state.pdf.general.cursorMode);

  //TextboxToggle controls either selected&editing textbox styles or preset styles, if not editing
  const hasEditingTextBox = useSelector(
    (state) =>
      state.pdf.annotations.editing &&
      state.pdf.annotations.byId[state.pdf.annotations.editing]?.subtype === 'FreeText',
  );
  const textboxStyles = useSelector((state) =>
    hasEditingTextBox
      ? state.pdf.annotations.textbox.selected
      : state.pdf.annotations.textbox.preset,
  );

  const isToggled = cursorMode === 'FreeText';

  const handleCursorMode = () => {
    dispatch(setCursorMode(isToggled ? 'normal' : 'FreeText'));
  };

  const handlePropertyChange = (payload: Parameters<typeof setTextboxPresetStyle>[0]) => {
    if (hasEditingTextBox) {
      dispatch(setTextboxSelectedStyle(payload));
      pdfManager.emit('EDIT_TEXTBOX', payload);
    } else {
      dispatch(setTextboxPresetStyle(payload));
    }
  };

  return (
    <>
      <Tooltip
        disabled={isOpen}
        content={
          disabled
            ? intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })
            : intl.formatMessage({ id: 'TEXT-BOX' })
        }
        placement="bottom"
        testId="textbox-split-tooltip"
      >
        <SplitToggle
          margin="0 0.5rem 0 0"
          isToggled={isToggled}
          onClick={handleCursorMode}
          icon={isToggled ? 'TextboxBlue' : 'TextboxGrey'}
          disabled={disabled}
          testId="textbox-split"
          {...referenceProps}
          isOpen={isOpen}
          onPopperClick={referenceProps.onClickCapture}
        />
      </Tooltip>
      <Popover {...popperProps} testId="text-box-popper">
        <div className={styles.textbox}>
          <div className={styles.row}>
            <Select
              clearable={false}
              size="medium"
              options={FONTS}
              value={{ value: textboxStyles.fontfamily, label: textboxStyles.fontfamily }}
              width="16rem"
              menuWidth="23.5rem"
              onChange={({ value }) => {
                handlePropertyChange({ property: 'fontfamily', value });
              }}
              testId="textbox-fontFamily"
            />
            <ColorPopover
              currentColor={textboxStyles.color}
              onPropertyChange={handlePropertyChange}
            />
          </div>
          <div className={styles.row}>
            <Select
              clearable={false}
              size="medium"
              options={SIZES}
              value={{ value: textboxStyles.fontsize, label: textboxStyles.fontsize }}
              width="9rem"
              menuWidth="23.5rem"
              onChange={({ value }) => {
                handlePropertyChange({ property: 'fontsize', value });
              }}
              testId="textbox-fontSize"
            />
            <Toggle
              margin="0 0 0 2rem"
              variant="standard"
              icon="Bold"
              size="medium"
              isToggled={textboxStyles.bold}
              onClick={() => {
                handlePropertyChange({ property: 'bold', value: !textboxStyles.bold });
              }}
              testId="textbox-bold-toggle"
            />
            <Toggle
              margin="0 0 0 0.5rem"
              variant="standard"
              icon="Italic"
              size="medium"
              isToggled={textboxStyles.italic}
              onClick={() => {
                handlePropertyChange({ property: 'italic', value: !textboxStyles.italic });
              }}
              testId="textbox-italic-toggle"
            />
            <Toggle
              margin="0 0 0 0.5rem"
              variant="standard"
              icon="Underline"
              size="medium"
              isToggled={textboxStyles.underline}
              onClick={() => {
                handlePropertyChange({ property: 'underline', value: !textboxStyles.underline });
              }}
              testId="textbox-underline-toggle"
            />
          </div>
          <div className={styles.row}>
            <ToggleGroup fullWidth>
              <Toggle
                fullWidth
                size="medium"
                variant="group"
                icon={textboxStyles.align === 'left' ? 'LeftWhite' : 'LeftGrey'}
                isToggled={textboxStyles.align === 'left'}
                onClick={() => {
                  handlePropertyChange({ property: 'align', value: 'left' });
                }}
                testId="textbox-left-align-toggle"
              />
              <Toggle
                fullWidth
                size="medium"
                variant="group"
                icon={textboxStyles.align === 'center' ? 'CenterWhite' : 'CenterGrey'}
                isToggled={textboxStyles.align === 'center'}
                onClick={() => {
                  handlePropertyChange({ property: 'align', value: 'center' });
                }}
                testId="textbox-center-align-toggle"
              />
              <Toggle
                fullWidth
                size="medium"
                variant="group"
                icon={textboxStyles.align === 'right' ? 'RightWhite' : 'RightGrey'}
                isToggled={textboxStyles.align === 'right'}
                onClick={() => {
                  handlePropertyChange({ property: 'align', value: 'right' });
                }}
                testId="textbox-right-align-toggle"
              />
              <Toggle
                fullWidth
                size="medium"
                variant="group"
                icon={textboxStyles.align === 'justify' ? 'JustifyWhite' : 'JustifyGrey'}
                isToggled={textboxStyles.align === 'justify'}
                onClick={() => {
                  handlePropertyChange({ property: 'align', value: 'justify' });
                }}
                testId="textbox-justify-align-toggle"
              />
            </ToggleGroup>
          </div>
        </div>
      </Popover>
    </>
  );
};

type ColorPopoverProps = {
  onPropertyChange: (payload: Parameters<typeof setTextboxPresetStyle>[0]) => void;
  currentColor: (typeof COLORS)[number];
};

const ColorPopover = ({ onPropertyChange, currentColor }: ColorPopoverProps) => {
  const { referenceProps, popperProps, close } = usePopper();
  return (
    <>
      <div className={styles.container} {...referenceProps}>
        <div style={{ backgroundColor: currentColor }} className={styles.color} />
      </div>
      <Popover {...popperProps} testId="text-box-color-popper">
        <div className={styles.colors}>
          {COLORS.map((color) => {
            return (
              <div
                className={cx(styles.color, { [styles.selected]: color === currentColor })}
                style={{ backgroundColor: color }}
                onClick={() => {
                  onPropertyChange({ property: 'color', value: color });
                  close();
                }}
              />
            );
          })}
        </div>
      </Popover>
    </>
  );
};

export default TextboxToggle;
