import { useState, useRef, useEffect, memo, MouseEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, Tooltip, Dropdown, usePopper, Button, Toggle, Link } from 'dodoc-design-system';

import { useDispatch, useSelector, useIsNodeClamped } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import { stringToRichText } from 'utils';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import {
  selectReadOnlyMode,
  getDocumentObject,
  selectIsPageLayout,
} from 'Editor/redux/EditorStatusSlice';
import { selectCollaborators } from 'App/redux/appSlice';

import { UsernameLabel, FormattedDate, FormattedTime, RichTextEditor } from '_common/components';
import { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';

import EditorAvatar from '../EditorAvatar/EditorAvatar';
import EditableCard from '../EditableCard/EditableCard';
import LikesTooltip from '../LikesTooltip/LikesTooltip';
import PageLayoutTooltip from '../PageLayoutTooltip/PageLayoutTooltip';

import TrackedActionBody, { getContentValue, CONTENT_TEXT_LABELS } from './TrackedActionBody';

import styles from './TrackedActionCard.module.scss';

type PriorityIconProps = {
  HIGH: {
    icon: 'High';
  };
  MEDIUM: {
    icon: 'Medium';
  };
  LOW: {
    icon: 'Low';
  };
};

const BUTTON_PRIORITY_ICONS: PriorityIconProps = {
  HIGH: { icon: 'High' },
  MEDIUM: { icon: 'Medium' },
  LOW: { icon: 'Low' },
};
const PRIORITY: PriorityIconProps = {
  HIGH: { icon: 'High' },
  MEDIUM: { icon: 'Medium' },
  LOW: {
    icon: 'Low',
  },
};

const NO_CONTENT_ACTIONS = [
  'ADD_PAGEBREAK',
  'ADD_TABLE',
  'ADD_IMAGE',
  'ADD_TABLE_CONTENTS',
  'ADD_LIST_FIGURES',
  'ADD_LIST_TABLES',
  'DELETE_FIGURE',
];

type PriorityProps = 'MEDIUM' | 'LOW' | 'HIGH';

type TrackedActionProps = {
  author: string;
  user?: string;
  comments: {
    author: string;
    content: string;
    id: string;
    time: ISODate;
    votes: {
      user: string;
      value: number;
      time: string;
    }[];
  }[];
  content:
    | {
        [key: string]: {
          type: string;
          value: string;
        };
      }
    | string;
  id: string;
  location?: string[];
  nodesPermissions?: {
    users: {
      [id: string]: string[];
    };
  };
  priority: PriorityProps;
  status: string;
  time: ISODate;
  type: Editor.TrackedAction['type'];
  votes: {
    user: string;
    value: number;
    time: string;
  }[];
  number?: number;
  deleted_replies?: string[];
  application?: string;
};

export type TrackedActionCardProps =
  | {
      className?: string;
      selected?: boolean;
      panel?: boolean;
      trackedAction: TrackedActionProps;
    } & (
      | { comment?: false | undefined }
      | {
          comment: true;
          mainTrackedAction: TrackedActionProps;
        }
    );

const TrackedActionCardContent = memo<TrackedActionCardProps>(
  ({ className, selected, panel = false, trackedAction, ...props }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const mainEditorRef = useRef<RichTextEditorHandler>(null);
    const replyEditorRef = useRef<RichTextEditorHandler>(null);
    const contentRef = useRef(null);

    const isPageLayout = useSelector(selectIsPageLayout);
    const isReadOnlyMode = useSelector(selectReadOnlyMode) || isPageLayout;
    const userId = useSelector((state) => state.auth.userId);
    const doc = useSelector(getDocumentObject);
    const collaborators = useSelector((state) => selectCollaborators(state, doc.id));

    const [editMode, setEditMode] = useState(false);
    const [readMore, setReadMore] = useState(true);
    const [showReplies, setShowReplies] = useState(false);
    const [showReplyButtons, setShowReplyButtons] = useState(false);

    const [reply, setReply] = useState('');
    const [contentValue, setContentValue] = useState<
      {
        type: string;
        value: string;
      }[]
    >([]);

    const isClamped = useIsNodeClamped({
      ref: contentRef,
      clamp: 3,
      dependencies: [
        trackedAction.content,
        typeof trackedAction?.content === 'object' && trackedAction?.content?.inserted?.value,
        typeof trackedAction?.content === 'object' && trackedAction?.content?.deleted?.value,
        contentValue,
      ],
    });
    const mainEditorIsClamped = useIsNodeClamped({
      ref: mainEditorRef.current?.editorRef,
      clamp: 3,
      dependencies: [
        trackedAction.content,
        typeof trackedAction?.content === 'object' && trackedAction?.content?.inserted?.value,
        typeof trackedAction?.content === 'object' && trackedAction?.content?.deleted?.value,
        contentValue,
      ],
    });

    const isAuthor = trackedAction.author === userId;
    const isOwner =
      doc.user_permissions.includes('owner') || doc.user_permissions.includes('admin');
    const isCommentator =
      doc.user_permissions.includes('comment') ||
      (trackedAction.nodesPermissions &&
        trackedAction.nodesPermissions.users[userId] &&
        trackedAction.nodesPermissions.users[userId].includes('comment'));
    const hasPermissions = (!isAuthor && isOwner) || (isAuthor && (isOwner || isCommentator));
    const canChangePriority = isAuthor || isOwner;

    const priorityPopper = usePopper({
      disabled: isReadOnlyMode || !canChangePriority,
      placement: 'bottom-end',
    });
    const moreActionsPopper = usePopper({
      disabled: isReadOnlyMode,
    });

    useEffect(() => {
      if (!isClamped) {
        setReadMore(false);
      }
    }, [isClamped]);

    useEffect(() => {
      setShowReplyButtons(false);
      setReply('');
      setEditMode(false);
      setShowReplies(!props.comment && trackedAction.comments?.length > 0);
    }, [trackedAction.id]);

    useEffect(() => {
      setContentValue(
        getContentValue({ content: trackedAction.content, type: trackedAction.type }),
      );
    }, [trackedAction.id, trackedAction.content]);

    const handleCancelReply = () => {
      setReply('');
      setShowReplyButtons(false);
    };

    const handleSaveClicked = (textValue?: string) => {
      if (props.comment && textValue) {
        EditorManager.getInstance().editTrackedActionReply(
          props.mainTrackedAction.id,
          trackedAction.id,
          stringToRichText(textValue),
        );
      }
      setEditMode(false);
    };

    const handleReplyClicked = () => {
      EditorManager.getInstance().replyTrackedAction(trackedAction.id, stringToRichText(reply));
      setReply('');
      setShowReplyButtons(false);
      replyEditorRef.current?.clear();
    };

    const handleReplyChanged = (newReply: string) => {
      setReply(newReply);
    };

    const handleSetEditMode = () => {
      setEditMode(true);
    };

    const handleCancelEditMode = () => {
      setEditMode(false);
    };

    const handleToggleReadMore: MouseEventHandler<HTMLDivElement> = (e) => {
      /**
       * Stop propagation to avoid card selection
       * Card selection will invoke scrollIntoView and app might pause to load new content
       */
      e.stopPropagation();

      setReadMore(!readMore);
    };

    const handleReplyFocused = () => {
      setShowReplyButtons(true);
    };

    const handleToggleShowReplies = () => {
      setShowReplies(!showReplies);
    };

    const handleVoteTrackedAction = (vote: boolean) => {
      if (isReadOnlyMode) {
        return;
      }
      if (props.comment) {
        EditorManager.getInstance().voteTrackedActionReply(
          props.mainTrackedAction.id,
          trackedAction.id,
          vote,
        );
      } else {
        EditorManager.getInstance().voteTrackedAction(trackedAction.id, vote);
      }
    };

    const handleDeleteClicked = () => {
      if (props.comment) {
        EditorManager.getInstance().deleteTrackedActionReply(
          props.mainTrackedAction.id,
          trackedAction.id,
        );
      }
    };

    const handleAcceptClicked = () => {
      EditorManager.getInstance().focusTrackedAction(trackedAction.id, () => {
        EditorManager.getInstance().acceptTrackedAction(trackedAction.id);
      });
    };

    const handleTrackedActionSelected = () => {
      if (!selected) {
        EditorManager.getInstance().focusTrackedAction(trackedAction.id);
      }
    };

    const handleRejectClicked = () => {
      EditorManager.getInstance().focusTrackedAction(trackedAction.id, () => {
        EditorManager.getInstance().rejectTrackedAction(trackedAction.id);
      });
    };

    const handleChangePriority = (priority: PriorityProps) => {
      if (!props.comment && trackedAction.status === 'OPEN') {
        EditorManager.getInstance().changeTrackedActionPriority(trackedAction.id, priority);
      }
    };

    const renderComments = () => {
      const { comments } = trackedAction;
      return comments.map((comment) => {
        const trackedActionValues: TrackedActionProps = {
          ...comment,
          comments: [],
          priority: 'MEDIUM',
          status: '',
          type: 'INSERT',
        };
        return (
          <TrackedActionCardContent
            key={comment.id}
            selected={selected}
            panel={panel}
            trackedAction={trackedActionValues}
            mainTrackedAction={trackedAction}
            comment
          />
        );
      });
    };

    const renderReply = () => {
      if (trackedAction.status !== 'OPEN') {
        return null;
      }

      const hasCommentPermission =
        doc.user_permissions.includes('admin') ||
        doc.user_permissions.includes('owner') ||
        doc.user_permissions.includes('comment');

      if (hasCommentPermission) {
        return (
          <div className={styles.replyContainer}>
            <div className={styles.reply}>
              <EditorAvatar margin="0 1rem 0 0" userId={userId} />
              <div style={{ flex: 1 }}>
                <RichTextEditor
                  ref={replyEditorRef}
                  mentionableUsers={collaborators}
                  placeholder={intl.formatMessage({
                    id: 'REPLY_PLACEHOLDER',
                  })}
                  onFocus={handleReplyFocused}
                  onChange={handleReplyChanged}
                  skipFocus
                  testId={`tracked-action-card-${trackedAction.id}-rich-text-editor`}
                />
              </div>
            </div>
            {showReplyButtons && (
              <div className={styles.replyButtons}>
                <Button
                  variant="link"
                  size="small"
                  onClick={handleCancelReply}
                  testId={`tracked-action-card-${trackedAction.id}-cancel-button`}
                >
                  <FormattedMessage id="global.cancel" />
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  onClick={handleReplyClicked}
                  disabled={reply === ''}
                  testId={`tracked-action-card-${trackedAction.id}-create-button`}
                >
                  <FormattedMessage id="global.create" />
                </Button>
              </div>
            )}
          </div>
        );
      }

      return null;
    };

    const renderHeader = () => {
      return (
        <div style={{ display: 'flex' }}>
          <EditorAvatar
            margin="0 1rem 0 0"
            userId={trackedAction.author || 'IMPORTED_USER'}
            name={trackedAction.user}
          />
          <div className={styles.labels}>
            <div className={styles.author}>
              <UsernameLabel userId={trackedAction.author} name={trackedAction.user} />
              {!props.comment && (
                <>
                  <PageLayoutTooltip
                    type="trackedAction"
                    content={intl.formatMessage({
                      id:
                        isReadOnlyMode || !canChangePriority
                          ? 'CHANGE_PRIORITY_CARD'
                          : 'global.priority',
                    })}
                    testId={`tracked-action-card-${trackedAction.id}-priority-tooltip`}
                  >
                    <span>
                      <Toggle
                        variant="link"
                        disabled={isReadOnlyMode || !canChangePriority}
                        style={{ marginLeft: '1rem', padding: 0 }}
                        size="small"
                        isToggled={priorityPopper.isOpen}
                        {...BUTTON_PRIORITY_ICONS[trackedAction.priority]}
                        {...priorityPopper.referenceProps}
                        testId={`tracked-action-card-${trackedAction.id}-priority-toggle`}
                      />
                    </span>
                  </PageLayoutTooltip>
                  <Dropdown
                    {...priorityPopper.popperProps}
                    testId={`tracked-action-card-${trackedAction.id}-priority-dropdown`}
                  >
                    <Dropdown.Item
                      prefixIcon={PRIORITY.HIGH.icon}
                      onClick={() => handleChangePriority('HIGH')}
                      testId={`tracked-action-card-${trackedAction.id}-high-dropdown-item`}
                    >
                      <FormattedMessage id="editor.sidebar.review.filter.priority.high" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      prefixIcon={PRIORITY.MEDIUM.icon}
                      onClick={() => handleChangePriority('MEDIUM')}
                      testId={`tracked-action-card-${trackedAction.id}-medium-dropdown-item`}
                    >
                      <FormattedMessage id="editor.sidebar.review.filter.priority.medium" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      prefixIcon={PRIORITY.LOW.icon}
                      onClick={() => handleChangePriority('LOW')}
                      testId={`tracked-action-card-${trackedAction.id}-low-dropdown-item`}
                    >
                      <FormattedMessage id="editor.sidebar.review.filter.priority.low" />
                    </Dropdown.Item>
                  </Dropdown>
                </>
              )}
            </div>
            <div className={styles.time}>
              <FormattedDate date={trackedAction.time} type="short" />
              &nbsp;
              <FormattedTime time={trackedAction.time} type="meridiem" />
            </div>
          </div>

          {/* Link Button with 3 vertical dots */}
          {hasPermissions && props.comment && (
            <div style={{ marginTop: '0.5rem' }}>
              <PageLayoutTooltip
                type="trackedAction"
                content={intl.formatMessage({ id: 'global.moreActions' })}
                testId={`tracked-action-card-${trackedAction.id}-options-tooltip`}
              >
                <span>
                  <Button
                    size="medium"
                    variant="link"
                    disabled={isReadOnlyMode}
                    style={{ marginLeft: '1rem', padding: 0 }}
                    icon="Options"
                    {...moreActionsPopper.referenceProps}
                    testId={`tracked-action-card-${trackedAction.id}-options-button`}
                  />
                </span>
              </PageLayoutTooltip>
              <Dropdown
                {...moreActionsPopper.popperProps}
                testId={`tracked-action-card-${trackedAction.id}-options-dropdown`}
              >
                <Dropdown.Item
                  onClick={handleDeleteClicked}
                  testId={`tracked-action-card-${trackedAction.id}-delete-dropdown-item`}
                >
                  <FormattedMessage id="global.delete" />
                </Dropdown.Item>
                <Tooltip
                  content={intl.formatMessage({ id: 'CANNOT_EDIT_REPLY_CREATED_BY_OTHERS' })}
                  placement="bottom"
                  disabled={isAuthor}
                  testId={`tracked-action-card-${trackedAction.id}-edit-dropdown-item-tooltip`}
                >
                  <div>
                    <Dropdown.Item
                      onClick={handleSetEditMode}
                      disabled={!isAuthor}
                      testId={`tracked-action-card-${trackedAction.id}-edit-dropdown-item`}
                    >
                      <FormattedMessage id="global.edit" />
                    </Dropdown.Item>
                  </div>
                </Tooltip>
              </Dropdown>
            </div>
          )}
        </div>
      );
    };

    const renderReadMore = () => {
      return (
        <div className={styles.readMore} onClick={handleToggleReadMore}>
          <FormattedMessage
            id={readMore ? 'editor.comments.readLess' : 'editor.comments.readMore'}
          />
        </div>
      );
    };

    const renderBody = () => {
      if (props.comment) {
        return (
          <>
            {trackedAction.user === null && trackedAction.user && (
              <div className={styles.imported}>
                <FormattedMessage id="IMPORTED_FROM_WORD_DOCUMENT" />
              </div>
            )}

            <RichTextEditor
              readOnly
              ref={mainEditorRef}
              initialValue={typeof trackedAction.content === 'string' ? trackedAction.content : ''}
              expanded={readMore}
              testId={`tracked-action-card-${trackedAction.id}-rich-text-editor`}
            />
            {mainEditorIsClamped && renderReadMore()}
          </>
        );
      }

      if (NO_CONTENT_ACTIONS.includes(trackedAction.type)) {
        return (
          <>
            {trackedAction.application && (
              <div className={styles.imported}>
                <FormattedMessage id="IMPORTED_FROM_WORD_DOCUMENT" />
              </div>
            )}
            <div className={`${styles.text} ${styles.bold}`}>
              {trackedAction.type ? (
                <FormattedMessage id={CONTENT_TEXT_LABELS[trackedAction.type]} />
              ) : (
                <FormattedMessage id="global.change" />
              )}
            </div>
          </>
        );
      }

      return (
        <>
          {trackedAction.application && (
            <div className={styles.imported}>
              <FormattedMessage id="IMPORTED_FROM_WORD_DOCUMENT" />
            </div>
          )}
          <span
            ref={contentRef}
            style={{ lineHeight: '2rem' }}
            className={` ${!readMore && styles.readLess}`}
          >
            <TrackedActionBody
              type={trackedAction.type}
              content={contentValue}
              contentRef={contentRef}
            />
          </span>

          {isClamped && renderReadMore()}
        </>
      );
    };

    const renderFooter = () => {
      const voted = trackedAction.votes && trackedAction.votes.some((vote) => vote.user === userId);
      const isAuthor = userId === trackedAction.author;
      const isOwner =
        doc.user_permissions.includes('owner') || doc.user_permissions.includes('admin');

      // Accept a suggestion: User with owner permission.
      const canAccept = isOwner;
      // Reject a suggestion: Suggestion Creator or user with Owner permission
      const canReject = isAuthor || isOwner;
      // Reply to a suggestion: Suggestion Creator, Comment permissions, or user with owner permission
      const canReply = isAuthor || doc.user_permissions.includes('comment') || isOwner;

      return (
        <>
          <div className={styles.leftPanel}>
            <span className={styles.likes}>
              <PageLayoutTooltip
                type="trackedAction"
                testId={`tracked-action-card-${trackedAction.id}-like-tooltip`}
              >
                <Toggle
                  size="medium"
                  variant="link"
                  style={{ padding: 0 }}
                  icon="Like"
                  isToggled={voted}
                  onClick={() => handleVoteTrackedAction(true)}
                  disabled={isReadOnlyMode}
                  testId={`tracked-action-card-${trackedAction.id}-like-toggle`}
                />
              </PageLayoutTooltip>
              {trackedAction.votes?.length > 0 && (
                <LikesTooltip
                  votes={trackedAction.votes}
                  testId={`tracked-action-card-${trackedAction.id}-likes-tooltip`}
                >
                  <span className={styles.counter}>
                    <Link
                      onClick={() =>
                        dispatch(
                          openAndUpdateModal({
                            modal: 'LikesModal',
                            data: { votes: trackedAction.votes, type: 'suggestion' },
                          }),
                        )
                      }
                      variant="neutral"
                      testId={`tracked-action-card-${trackedAction.id}-likes-link`}
                    >
                      {trackedAction.votes.length}
                    </Link>
                  </span>
                </LikesTooltip>
              )}
            </span>

            {!props.comment && (
              <PageLayoutTooltip
                type="trackedAction"
                disabled={trackedAction.comments?.length > 0 || undefined}
                testId={`tracked-action-card-${trackedAction.id}-reply-tooltip`}
              >
                <Toggle
                  size="medium"
                  variant="link"
                  disabled={isReadOnlyMode ? trackedAction.comments?.length < 1 : !canReply}
                  style={{ marginLeft: '1.5rem', padding: 0 }}
                  icon="Replies"
                  isToggled={showReplies}
                  onClick={handleToggleShowReplies}
                  testId={`tracked-action-card-${trackedAction.id}-reply-toggle`}
                />
              </PageLayoutTooltip>
            )}
            {!props.comment && trackedAction.comments && trackedAction.comments.length > 0 && (
              <div className={styles.numberOfReplies}>{trackedAction.comments.length}</div>
            )}
          </div>
          {!props.comment && (
            <>
              <PageLayoutTooltip
                type="trackedAction"
                disabled={isPageLayout ? undefined : isOwner}
                content={intl.formatMessage({ id: 'ACCEPT_CHANGE' })}
                testId={`tracked-action-card-${trackedAction.id}-accept-button-tooltip`}
              >
                <div>
                  <Button
                    variant="link"
                    disabled={!canAccept || isReadOnlyMode}
                    size="small"
                    onClick={handleAcceptClicked}
                    testId={`tracked-action-card-${trackedAction.id}-accept-button`}
                  >
                    <FormattedMessage id="ACCEPT" />
                  </Button>
                </div>
              </PageLayoutTooltip>
              <PageLayoutTooltip
                type="trackedAction"
                disabled={isPageLayout ? undefined : isAuthor || isOwner}
                content={intl.formatMessage({ id: 'REJECT_CHANGE' })}
                testId={`tracked-action-card-${trackedAction.id}-reject-button-tooltip`}
              >
                <div>
                  <Button
                    variant="link"
                    disabled={!canReject || isReadOnlyMode}
                    size="small"
                    onClick={handleRejectClicked}
                    testId={`tracked-action-card-${trackedAction.id}-reject-button`}
                  >
                    <FormattedMessage id="REJECT" />
                  </Button>
                </div>
              </PageLayoutTooltip>
            </>
          )}
        </>
      );
    };

    if (props.comment && editMode) {
      const text = typeof trackedAction.content === 'string' ? trackedAction.content : '';
      return (
        <EditableCard
          editMode
          user={userId}
          subcomment={props.comment}
          handleSaveClicked={handleSaveClicked}
          handleCancelClicked={handleCancelEditMode}
          content={text}
          placeholder={intl.formatMessage({ id: 'INSERT_YOUR_COMMENT_HERE' })}
        />
      );
    }

    if (trackedAction.type === null) {
      return (
        <UnknownChangeCard
          className={className}
          sidebar={panel}
          isOwner={isOwner}
          isReadOnlyMode={isReadOnlyMode}
          handleAcceptClicked={handleAcceptClicked}
          handleRejectClicked={handleRejectClicked}
          isPageLayout={isPageLayout}
          testId={`tracked-action-card-${trackedAction.id}`}
        />
      );
    }

    return !props.comment ? (
      <Card
        testId={`TrackedAction#${trackedAction.id}`}
        sidebar={panel}
        selected={selected}
        onClick={panel ? handleTrackedActionSelected : () => {}}
        width={panel ? '100%' : '43rem'}
      >
        <Card.Header size="medium">{renderHeader()}</Card.Header>
        <Card.Body>{renderBody()}</Card.Body>
        <Card.Footer size="large">{renderFooter()}</Card.Footer>
        {showReplies && !props.comment && (
          <>
            {renderComments()}
            {!isReadOnlyMode && renderReply()}
          </>
        )}
      </Card>
    ) : (
      <div className={styles.subComment}>
        <Card.Header size="medium" background="grey">
          {renderHeader()}
        </Card.Header>
        <Card.Body>{renderBody()}</Card.Body>
        <Card.Footer size="large">{renderFooter()}</Card.Footer>
      </div>
    );
  },
);

type FooterProps = {
  isOwner: boolean;
  handleAcceptClicked: () => void;
  handleRejectClicked: () => void;
  isReadOnlyMode: boolean;
  isPageLayout: boolean;
  testId: string;
};

const Footer = ({
  isOwner,
  handleAcceptClicked,
  handleRejectClicked,
  isReadOnlyMode,
  isPageLayout,
  testId,
}: FooterProps) => {
  const intl = useIntl();
  return (
    <>
      <div style={{ marginRight: 'auto' }}>
        <Toggle
          size="medium"
          variant="link"
          disabled
          style={{ padding: 0 }}
          icon="Replies"
          testId={`${testId}-replies-toggle`}
        />
      </div>
      <PageLayoutTooltip
        type="trackedAction"
        disabled={isPageLayout ? undefined : isOwner}
        content={intl.formatMessage({ id: 'ACCEPT_REJECT_CHANGE' })}
        testId={`tracked-action-card-accept-tooltip`}
      >
        <div>
          <Button
            variant="link"
            disabled={!isOwner || isReadOnlyMode}
            size="small"
            onClick={handleAcceptClicked}
            testId={`tracked-action-card-accept-button`}
          >
            <FormattedMessage id="ACCEPT" />
          </Button>
        </div>
      </PageLayoutTooltip>
      <PageLayoutTooltip
        type="trackedAction"
        disabled={isPageLayout ? undefined : isOwner}
        content={intl.formatMessage({ id: 'ACCEPT_REJECT_CHANGE' })}
        testId={`tracked-action-card-reject-tooltip`}
      >
        <div>
          <Button
            variant="link"
            disabled={!isOwner || isReadOnlyMode}
            size="small"
            onClick={handleRejectClicked}
            testId={`tracked-action-card-reject-button`}
          >
            <FormattedMessage id="REJECT" />
          </Button>
        </div>
      </PageLayoutTooltip>
    </>
  );
};

type UnknownChangeCardProps = {
  isOwner: boolean;
  className?: string;
  handleAcceptClicked: () => void;
  handleRejectClicked: () => void;
  isReadOnlyMode: boolean;
  sidebar: boolean;
  isPageLayout: boolean;
  testId: string;
};

const UnknownChangeCard = ({
  isOwner,
  handleAcceptClicked,
  handleRejectClicked,
  isReadOnlyMode,
  sidebar,
  isPageLayout,
  testId,
}: UnknownChangeCardProps) => {
  return (
    <Card sidebar={sidebar} testId={`${testId}-unknown-change-card`}>
      <Card.Header size="medium">
        <EditorAvatar />
        <div className={styles.unknown_user}>
          <FormattedMessage id="UNKNOWN_USER" />
        </div>
      </Card.Header>
      <Card.Body>
        <div className={styles.unknown_user_message}>
          <FormattedMessage id="SUGGESTION_NO_INFO" />
        </div>
      </Card.Body>
      <Card.Footer size="large">
        <Footer
          isOwner={isOwner}
          handleAcceptClicked={handleAcceptClicked}
          handleRejectClicked={handleRejectClicked}
          isReadOnlyMode={isReadOnlyMode}
          isPageLayout={isPageLayout}
          testId={testId}
        />
      </Card.Footer>
    </Card>
  );
};

export default TrackedActionCardContent;
