import PropTypes from 'prop-types';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import './DataTable.module.scss';

const DodocDataTable = ({ columns, ...props }) => (
  // eslint-disable-next-line
  <DataTable {...props}>
    {columns.map(({ field, ...props }) => (
      // eslint-disable-next-line
      <Column key={field} field={field} {...props} />
    ))}
  </DataTable>
);

DodocDataTable.propTypes = {
  autoLayout: PropTypes.bool,
};

DodocDataTable.defaultProps = {
  autoLayout: true,
};

export default DodocDataTable;
