import { useMemo } from 'react';
import { Label } from 'dodoc-design-system';

import Priority from './Priority/Priority';
import Options from './Options/Options';

import { useSelector } from '_common/hooks';
import { UserPresentation } from '_common/components';
import styles from './Header.module.scss';

import type { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';
import { FormattedMessage } from 'react-intl';

type HeaderProps = {
  annotation: PDF.Annotation;
  editorRef?: React.Ref<RichTextEditorHandler>;
  editMode?: boolean;
  testId: string;
};

const STATUS = {
  resolved: { labelId: 'editor.sidebar.review.filter.status.resolved', color: 'green' },
  deleted: { labelId: 'editor.sidebar.review.filter.status.deleted', color: 'red' },
} as const;

const Header = ({ annotation, editorRef, editMode, testId }: HeaderProps) => {
  const editingAnnotation = useSelector((state) => state.pdf.annotations.editing);
  const editing = editingAnnotation === annotation?.id || editMode;

  const cardState = useMemo<keyof typeof STATUS | undefined>(() => {
    switch (annotation.state) {
      case 'Cancelled':
        return 'deleted';
      case 'Completed':
        return 'resolved';
      default:
        return undefined;
    }
  }, [annotation]);

  return (
    <div className={styles.root}>
      <UserPresentation
        userId={annotation.authorId}
        fixedName={!annotation.authorId ? annotation.title : undefined}
        //@ts-expect-error PDF.Annotation.Reply.creationDate is typed as Date but is string
        creationDate={editing ? undefined : annotation.creationDate}
      />
      {!editing && (
        <div className={styles.actions}>
          <Priority annotation={annotation} testId={testId} />
          {cardState ? (
            <Label
              size="small"
              color={STATUS[cardState].color}
              margin={'0 0 0 0.5rem'}
              testId={`${testId}-status-label`}
            >
              <FormattedMessage id={STATUS[cardState].labelId} />
            </Label>
          ) : (
            <Options annotation={annotation} editorRef={editorRef} />
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
