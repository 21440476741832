import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import Menu from './Menu';
import styles from './Menu.module.scss';

const MenuBoundary = () => (
  <div className={styles.menu}>
    <IntlErrorBoundary size="small">
      <Menu />
    </IntlErrorBoundary>
  </div>
);

export default MenuBoundary;
