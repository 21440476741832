import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, Button } from 'dodoc-design-system';
import type { ButtonProps } from 'dodoc-design-system/build/types/Components/Button/Button';

import { toRoman } from 'utils';
import { useIsNodeClamped, usePublicProfile, useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import EditorManager from 'Editor/services/EditorManager';
import { setNoteOverlayData } from 'Editor/redux/NotesSlice';
import { selectUser } from 'Editor/redux/EditorStatusSlice';

import type { EditableCardProps } from 'Editor/components/EditableCard/EditableCardContent';

import EditableCard from '../EditableCard/EditableCard';
import PageLayoutTooltip from '../PageLayoutTooltip/PageLayoutTooltip';

import styles from './ViewNoteCard.module.scss';

export type ViewNoteCardProps = {
  note: Editor.Note;
  selected: boolean;
  sidebar: boolean;
  isReadOnlyMode: boolean;
  doc: Objekt;
};

const ViewNoteCardContent = ({
  note,
  selected,
  sidebar,
  isReadOnlyMode,
  doc,
}: ViewNoteCardProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const contentRef = useRef<HTMLSpanElement>(null);

  const type = useSelector((state) => state.editor.notes.overlay.type);
  const operation = useSelector((state) => state.editor.notes.overlay.operation);
  const user = useSelector(selectUser);
  const author = usePublicProfile(note.author);

  const [readMore, setReadMore] = useState(true);
  const cardRef = useRef<HTMLDivElement>(null);

  const isClamped = useIsNodeClamped({
    ref: contentRef,
    clamp: 3,
    dependencies: [note.content],
  });

  const manager = EditorManager.getInstance();

  useEffect(() => {
    if (sidebar) {
      return undefined;
    }
    const handleClickOutside = (e: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(e.target as HTMLElement)) {
        dispatch(setNoteOverlayData({ offsets: null, selected: null, type: undefined }));
      }
    };
    const editorContainer = document.getElementById('EditorContainer');
    editorContainer?.addEventListener('click', handleClickOutside);
    // Specify how to clean up after this effect:
    return () => {
      editorContainer?.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useLayoutEffect(() => {
    if (!isClamped) {
      setReadMore(false);
    }
  }, [isClamped]);

  const select = () => {
    if (selected) {
      return;
    }

    manager.viewNoteCard(note.id, note.type);
  };
  const remove: ButtonProps<'small'>['onClick'] = (e) => {
    e.stopPropagation();
    if (selected) {
      dispatch(setNoteOverlayData({ offsets: null, selected: null }));
    }
    manager.deleteNote(note.id);
  };
  const edit: ButtonProps<'small'>['onClick'] = (e) => {
    e.stopPropagation();
    manager.viewNoteCard(note.id, note.type, true);
  };

  const handleInsertNote: EditableCardProps['handleCreateClicked'] = async (text) => {
    if (text) {
      const id = await manager.createNote(null, type, text);
      if (id) {
        manager.insertNote(type, id);
      }
    }
    handleCancelEdition();
  };

  const handleSaveClicked: EditableCardProps['handleSaveClicked'] = async (text) => {
    if (text) {
      await manager.editNote(note.id, text).then(() => {
        switch (type) {
          case 'endnote':
            notify({
              type: 'success',
              title: 'ENDNOTES_EDITED',
              message: 'THE_NUMBERING_OF_ENDNOTES_WAS_EDITED',
            });
            break;
          case 'footnote':
            notify({
              type: 'success',
              title: 'FOOTNOTES_EDITED',
              message: 'THE_NUMBERING_OF_FOOTNOTES_WAS_EDITED',
            });
            break;
        }
      });
    }
    handleCancelEdition();
  };

  const handleCancelEdition = () => {
    dispatch(
      setNoteOverlayData(operation === 'create' ? { offsets: null } : { operation: 'view' }),
    );
  };

  const renderHeader = () => (
    <div style={{ display: 'flex' }}>
      <div className={styles.labels}>
        <div className={styles.order}>
          {`#${note.type === 'footnote' ? note.order : toRoman(note.order)}`}
        </div>
        <div className={styles.label}>
          {`${intl.formatMessage({ id: note.type.toUpperCase() })} `}
        </div>
      </div>
    </div>
  );

  const renderBody = () => (
    <>
      {/* ~TODO: talk with Filipe to send a flag like has been done in Comments (application) */}
      {/* {note.application && (
          <div className={styles.imported}>
            <FormattedMessage id="IMPORTED_FROM_WORD_DOCUMENT" />
          </div>
        )} */}

      <span
        ref={contentRef}
        style={{ lineHeight: '2rem' }}
        className={`${styles.text} ${!readMore && styles.readLess}`}
      >
        {note.content}
      </span>

      {isClamped && (
        <div
          className={styles.readMore}
          onClick={(e) => {
            /**
             * Stop propagation to avoid card selection
             * Card selection will invoke scrollIntoView and app might pause to load new content
             */
            e.stopPropagation();

            setReadMore(!readMore);
          }}
        >
          <FormattedMessage
            id={readMore ? 'editor.comments.readLess' : 'editor.comments.readMore'}
          />
        </div>
      )}
    </>
  );

  const renderFooter = () => {
    const canEdit = doc.user_permissions.includes('owner') || doc.user_permissions.includes('edit');
    return (
      <>
        <PageLayoutTooltip type="note" testId={`note-card-${note.id}-remove-tooltip`}>
          <Button
            variant="link"
            disabled={isReadOnlyMode}
            size="small"
            onClick={remove}
            testId={`note-card-${note.id}-remove-button`}
          >
            <FormattedMessage id="global.remove" />
          </Button>
        </PageLayoutTooltip>
        <PageLayoutTooltip type="note" testId={`note-card-${note.id}-edit-tooltip`}>
          <Button
            variant="link"
            disabled={isReadOnlyMode || !canEdit}
            size="small"
            onClick={edit}
            testId={`note-card-${note.id}-edit-button`}
          >
            <FormattedMessage id="global.edit" />
          </Button>
        </PageLayoutTooltip>
      </>
    );
  };

  if (selected && operation === 'edit') {
    return (
      <EditableCard
        isNote
        type={type}
        note={note}
        user={user.id}
        name={operation === 'edit' ? author.name : undefined}
        editMode={operation === 'edit'}
        content={operation === 'edit' ? note.content : ''}
        handleCreateClicked={handleInsertNote}
        handleSaveClicked={handleSaveClicked}
        handleCancelClicked={handleCancelEdition}
      />
    );
  }

  return (
    <Card
      ref={cardRef}
      sidebar={sidebar}
      selected={selected}
      width={sidebar ? '100%' : '43rem'}
      onClick={sidebar ? select : () => {}}
      testId={`note-${note.id}-card`}
    >
      <Card.Header size="small">{renderHeader()}</Card.Header>
      <Card.Body>{renderBody()}</Card.Body>
      <Card.Footer size="large">{renderFooter()}</Card.Footer>
    </Card>
  );
};

export default ViewNoteCardContent;
